import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { getOtherInformationPayload, saveOtherInformationPayload } from '../types/otherInformation';

const OtherInformationService = {
  getOtherInformation: async (data: getOtherInformationPayload) => {
    return await axios.post('/otherInformation', data);
  },
  saveOtherInformation: async (data: saveOtherInformationPayload) => {
    return await axios.post('/otherInformation/save', data);
  },
};

export default OtherInformationService;

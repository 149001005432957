import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import DateTimeField from './DateTimeField';

const DeathReportForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <FormField
            control={control}
            name="date_time_of_death"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.deathReportLicensee.dateTimeOfDeath')}
                </FormLabel>
                <FormControl>
                  <DateTimeField
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="place_of_death"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.deathReportLicensee.placeOfDeath')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className='mb-4'>
        <FormField
          control={control}
          name="immediate_cause_of_death"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.immediateCauseOfDeath')}
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  rows={3}
                  maxLength={650}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='mb-4'>
        <FormField
          control={control}
          name="conditions_prior_to_death"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.conditionsPriorDeath')}
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  rows={3}
                  maxLength={650}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='mb-4'>
        <FormField
          control={control}
          name="immediate_action_taken"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.immediateActionTaken')}
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  rows={3}
                  maxLength={650}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
        <div>
          <FormLabel className="font-normal">
            {t('residents.forms.deathReportLicensee.medicalTreatment')}
          </FormLabel>
          <div className="flex items-center space-x-4 mt-2">
            <FormField
              control={control}
              name="medical_treatment"
              render={({ field }) => (
                <div className="flex items-center space-x-4">
                  <label className="flex items-center space-x-2">
                    <Checkbox
                      checked={field.value === "yes"}
                      onCheckedChange={() => field.onChange("yes")}
                    />
                    <span>{t('common.yes')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <Checkbox
                      checked={field.value === "no"}
                      onCheckedChange={() => field.onChange("no")}
                    />
                    <span>{t('common.no')}</span>
                  </label>
                </div>
              )}
            />

          </div>
        </div>

        <div className='col-span-1 sm:col-span-2'>
          <FormField
            control={control}
            name="nature_of_treatment"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.deathReportLicensee.natureOfTreatment')}:
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={300}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default DeathReportForm;

import { ResidentI } from "@/common/domain/Residents";
import { defaultValuesRegistry } from "./defaultValuesRegistry";
import { AllContactsI } from "../../models/Contacts";

export const getDefaultValues = (documentKey: string, resident: ResidentI, contacts: AllContactsI[]): Record<string, any> => {
  const getDefaultValuesFn = defaultValuesRegistry[documentKey];
  if (!getDefaultValuesFn) {
    throw new Error(`No default values found for documentKey: ${documentKey}`);
  }
  return getDefaultValuesFn(resident, contacts);
};

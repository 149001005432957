export const ambulatoryStatusOptions = [
  { name: 'ambulatory' },
  { name: 'non_ambulatory' },
];

export const ambulatoryCriteria = [
  { name: 'walk_assistance' },
  { name: 'follow_signals' },
  { name: 'use_routes' },
  { name: 'evacuate_quickly' },
];

export const functionalCapabilities = [
  { name: 'no_personal_help' },
  { name: 'difficulty_climbing' },
  { name: 'uses_brace_or_crutch' },
  { name: 'frail_or_slow' },
  { name: 'uses_walker', extraField: 'uses_walker_unassisted'},
  { name: 'uses_wheelchair', extraField: 'uses_wheelchair_unassisted'},
  { name: 'requires_grab_bars' },
  { name: 'other', extraDescription: 'other_description' },
];

export const healthFields = [
  {
    name: "health",
    description: "healthDescription",
    maxLength: 300
  },
  {
    name: "physical_disabilities",
    description: "physicalDescription",
    maxLength: 800
  },
  {
    name: "mental_condition",
    description: "mentalDescription",
    maxLength: 600
  },
  {
    name: "health_history",
    description: "healthHistoryDescription",
    maxLength: 800
  },
  {
    name: "social_factors",
    description: "socialDescription",
    maxLength: 500
  },
];

export const servicesNeeded = [
  { name: 'help_transferring'},
  { name: 'help_bathing'},
  { name: 'laundry_and_house_hold_question'},
  { name: 'help_moving'},
  { name: 'help_eating'},
  { name: 'special_diet'},
  { name: 'toileting_assistance'},
  { name: 'continence_assistance'},
  { name: 'help_medication'},
  { name: 'special_supervision'},
  { name: 'help_cash_management'},
  { name: 'help_activity_programs'},
  { name: 'special_medical_attention'},
  { name: 'health_medical_assistance'},
  { name: 'other_services_needed'}
];
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Switch } from '@/common/presentation/components/ui/switch';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export const getDementiaStages = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('residents.mentalCondition.confusedDisoriented'),
      value: 'confused_disoriented',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.inappropriateBehavior'),
      value: 'inappropiate_behavior',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.aggressiveBehavior'),
      value: 'aggresive_behavior',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.wanderingBehavior'),
      value: 'wandering_behavior',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.sundowningBehavior'),
      value: 'sundowning_behavior',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.ableToFollowInstructions'),
      value: 'able_to_follow_instructions',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.depressed'),
      value: 'depressed',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.suicidalSelfAbuse'),
      value: 'suicidal_self_abuse',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.ableToCommunicateNeeds'),
      value: 'able_to_communicate_needs',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.atRiskGroomingHygieneItems'),
      value: 'at_risk_if_allowed_direct_access_to_personal_grooming_and_hygiene_items',
      option: null,
      detail: ''
    },
    {
      label: t('residents.mentalCondition.ableToLeaveFacilityUnassisted'),
      value: 'able_to_leave_facility_unassisted',
      option: null,
      detail: ''
    }
  ];
};

export const getMentalConditionFormSchema = () => {
  const { t } = useTranslation();

  const dementiaStages = getDementiaStages();

  const dementiaStageSchema = dementiaStages.reduce((acc: any, stage: any) => {
    acc[stage.value] = z.object({
      option: z.string().refine((value) => value === 'yes' || value === 'no', {
        message: 'Option must be either yes or no'
      }),
      detail: z.string().nullable().optional()
    });
    return acc;
  }, {});

  return z
    .object({
      mental_condition_description: z
        .string({
          required_error: 'General description is required'
        })
        .min(1, {
          message: t('residents.mentalCondition.generalDescription')
        }),
      mental_condition_dementia: z.boolean().optional(),
      mental_condition_dementia_type: z
        .string({
          required_error: 'Dementia type is required'
        })
        .optional(),
      mental_condition_evaluation: z.object(dementiaStageSchema)
    })
    .refine(
      (data) => {
        if (data.mental_condition_dementia) {
          return data.mental_condition_dementia_type !== '';
        }
        return true;
      },
      {
        message: 'Dementia type is required if the patient has been diagnosed with dementia',
        path: ['dementia_type']
      }
    );
};

type FormValues = z.infer<ReturnType<typeof getMentalConditionFormSchema>>;

export const MentalConditionForm = () => {
  const {
    resident
  }: {
    resident: Resident | null;
    status: string;
  } = useSelector((state: RootState) => state.residents.resident);
  const { t } = useTranslation();
  const dementiaStages = getDementiaStages();

  const defaultDementiaStageValues = dementiaStages.reduce((acc: any, stage: any) => {
    acc[stage.value] = { option: '', detail: '' };
    return acc;
  }, {});

  const [defaultValues, setDefaultValues] = useState(
    resident?.health_information || {
      mental_condition_description: '',
      mental_condition_dementia: false,
      mental_condition_dementia_type: '',
      mental_condition_evaluation: defaultDementiaStageValues
    }
  );

  const dementiaOptions = [
    {
      label: t('residents.mentalCondition.alert'),
      value: 'alert'
    },
    {
      label: t('residents.mentalCondition.mildCognitiveImpairment'),
      value: 'mild_cognitive'
    },
    {
      label: t('residents.mentalCondition.mildDementia'),
      value: 'mild_dementia'
    },
    {
      label: t('residents.mentalCondition.moderateDementia'),
      value: 'moderate_dementia'
    },
    {
      label: t('residents.mentalCondition.advancedDementia'),
      value: 'advanced_dementia'
    }
  ];

  const formSchema = getMentalConditionFormSchema();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const { uppsertMentalConditionInformation, status: medicalInformationFormStatus } = useMedicalInformationStore();

  const handleSubmit = async (values: FormValues) => {
    if (!values.mental_condition_dementia) {
      values.mental_condition_dementia_type = '';
    }

    const payload = {
      resident_id: resident?.id,
      ...values
    };

    try {
      await uppsertMentalConditionInformation(payload);

      form.reset({
        ...values
      });

      setDefaultValues({
        ...values
      });

      toast({
        description: t('residents.mentalCondition.updatedMentalCondition'),
      });
    } catch (error) {
      toast({
        description: 'There was an error updating the mental condition',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div className="">
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">{t('residents.mentalCondition.mentalCondition')}</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 mt-4">
              <FormField
                control={form.control}
                name="mental_condition_description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">
                    {t('residents.mentalCondition.mentalDescription')}
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder=""
                        {...field}
                        value={field.value || ''}
                        disabled={medicalInformationFormStatus === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Separator className="col-span-4" />

              <h1 className="text-lg font-semibold">{t('residents.mentalCondition.mentalStatus')}</h1>

              <FormField
                control={form.control}
                name="mental_condition_dementia"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-4">
                    <FormLabel className="mt-1.5">{t('residents.mentalCondition.diagnosed')}</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value as boolean}
                        onCheckedChange={field.onChange}
                        disabled={medicalInformationFormStatus === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="mental_condition_dementia_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">
                    {t('residents.mentalCondition.yesDiagnosed')}
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="w-full flex gap-4 justify-between items-center"
                        disabled={medicalInformationFormStatus === 'loading'}
                      >
                        {dementiaOptions.map((option) => (
                          <FormItem key={option.value} className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={option.value} />
                            </FormControl>
                            <FormLabel className="font-normal">{option.label}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Separator className="col-span-4" />

              <p className="text-muted-foreground">
                {t('residents.mentalCondition.moreInfo')}
              </p>

              <div>
                <Table>
                  <TableHeader className="text-lg font-bold bg-primary/80">
                    <TableRow>
                      <TableHead className="text-white">{t('residents.mentalCondition.option')}</TableHead>
                      <TableHead className="flex gap-16 items-center text-white">
                        <div>{t('trackers.trackerFall.yesOption')}</div>
                        <div>{t('trackers.trackerFall.noOption')}</div>
                      </TableHead>
                      <TableHead className="text-white">{t('calendar.detail')}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dementiaStages.map((stage) => (
                      <TableRow>
                        <TableCell className="font-medium">{stage.label}</TableCell>
                        <TableCell>
                          <FormField
                            control={form.control}
                            name={`mental_condition_evaluation.${stage.value}.option`}
                            render={({ field }) => (
                              <FormItem>
                                <FormControl>
                                  <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    value={field.value}
                                    className="w-full flex gap-20 items-center"
                                    disabled={medicalInformationFormStatus === 'loading'}
                                  >
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                      <FormControl>
                                        <RadioGroupItem value="yes" />
                                      </FormControl>
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                      <FormControl>
                                        <RadioGroupItem value="no" />
                                      </FormControl>
                                    </FormItem>
                                  </RadioGroup>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <FormField
                            control={form.control}
                            name={`mental_condition_evaluation.${stage.value}.detail`}
                            render={({ field }) => (
                              <FormItem className="col-span-5">
                                <FormControl>
                                  <Textarea
                                    placeholder=""
                                    {...field}
                                    value={field.value || ''}
                                    disabled={medicalInformationFormStatus === 'loading'}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                <div className="col-span-2 space-y-4">
                  <Separator />

                  <div className="flex items-end gap-2 col-span-full xl:col-span-1">
                    <Button
                      className="flex gap-2"
                      variant={'default'}
                      type="submit"
                      disabled={medicalInformationFormStatus === 'loading'}
                    >
                      {medicalInformationFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      {t('common.update')}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </Form>
        </CustomAccordion>
      </div>
    </>
  );
};

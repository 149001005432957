import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { useTranslation } from 'react-i18next';

interface Type {
    id: string;
    type_name: string;
}


interface ISelectFieldProps {
    dataType: Type[];
    typeId: string;
    handleFieldChange: (id: string) => void;
}

export function SelectType({ typeId, handleFieldChange, dataType }: ISelectFieldProps) {
    const { t } = useTranslation();
    const types = dataType.find((type) => type.id === typeId)
    return (

        <div className="w-1/4 flex items-center justify-between">
            <Select value={typeId} onValueChange={handleFieldChange}>
                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    {types ? (<SelectValue>{types.type_name}</SelectValue>) :
                        (typeId == 'all' ? 'All' : t('trackers.Field'))}
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    {dataType
                        .slice()
                        .sort((a, b) => {
                            return a.type_name.localeCompare(b.type_name);
                        })
                        .map((type) => (
                            <SelectItem key={type.id} value={type.id}>
                                {type.type_name}
                            </SelectItem>
                        ))}
                </SelectContent>
            </Select>
        </div>
    )
}
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { Select, SelectContent } from '@/common/presentation/components/ui/select';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { SelectItem, SelectTrigger } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch } from '@/store/store';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectValue } from '@radix-ui/react-select';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { createMultipleCertificate, fetchTrainingCategories } from '../slices/TrainingSlice';
import { useTranslation } from 'react-i18next';
import { es } from 'date-fns/locale';
import { ACCEPTED_FILE_TYPES, COMMON_INPUT_CLASSES, MAX_FILE_SIZE } from '../../domain/constants/training';
import { useToast } from '@/common/presentation/components/ui/use-toast';
interface CategoryI {
  id: string;
  name: string;
}

const AddMultipleCertificate = () => {
  const { formatDate } = useDateHelpers();
  const dispatch = useDispatch<AppDispatch>();
  const { locationSelectedId } = useLocation();
  const [staff, setStaff] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<CategoryI[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const locale = i18n.language === 'es' ? es : undefined;
  const { toast } = useToast();

  const formSchema = z.object({
    description: z
      .string({
        required_error: t('training.error_description_required')
      })
      .max(50, t('training.error_description_length')),
    date: z.date({
      required_error: t('training.error_date_required'),
      invalid_type_error: t('training.error_invalid_date_format')
    }),
    hours: z
      .number({
        required_error: t('training.error_hours_required')
      })
      .min(0.5, t('training.error_hours_min')),
    file: z
      .any()
      .refine((file) => file?.length > 0, t('training.error_file_required')),
    category: z
      .string({
        required_error: t('training.error_category_required')
      }),
    users: z
      .array(
        z.object({
          label: z.string().nonempty(t('training.error_user_label_required')),
          value: z.string().uuid(t('training.error_user_value_invalid')),
          imageUrl: z.string().optional()
        })
      )
      .min(1, t('training.error_user_required'))
      .default([])
  });
  

  useEffect(() => {
    getUsers();
  }, [locationSelectedId]);

  useEffect(() => {
    getTrainingCertificateCategories();
  }, []);

  const getTrainingCertificateCategories = async () => {
    try {
      const { data } = await dispatch(fetchTrainingCategories()).unwrap();
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getUsers = useCallback(async () => {
    const response = await dispatch(
      getStaffByLocation(!locationSelectedId || locationSelectedId == 'all-locations' ? undefined : locationSelectedId)
    ).unwrap();
    setStaff(
      response.map((user: any) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
        ...(user.profile_url && { imageUrl: user.profile_url ?? '' })
      }))
    );
  }, [locationSelectedId]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      users: []
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsSubmitting(true);
      const response = await dispatch(
        createMultipleCertificate({
          category_id: values.category,
          description: values.description,
          users: values.users?.map((user: any) => user.value) ?? [],
          hours: values.hours,
          type: 'In-service',
          file: values.file[0],
          date: values.date.toISOString()
        })
      ).unwrap();
      if (response.message == 'ok') {
        toast({
          description: t('training.success_certificate_added'),
          className: 'bg-green-500 text-white'
        });
        form.reset();
      }
    } catch (error) {
      toast({
        description: t('training.error_generic'),
        variant: 'destructive'
      });
      console.log(error);
    }
    setIsSubmitting(false);
  };

  const formatters = useMemo(
    () => ({
      formatMonthCaption: (date: Date) => t(`calendar.month_${date.getMonth() + 1}`)
    }),
    [t]
  );

  return (
    <div className="pt-10">
      <CustomCard title={t(`training.add_multiple_in_service_certificates`)} description="">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <FormField
                control={form.control}
                name="users"
                disabled={isSubmitting}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('training.staff')}</FormLabel>
                    <FormControl>
                      <MultiSelect
                        name={field.name}
                        options={staff}
                        selectedOptions={field.value || []}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        placeHolder={t('training.select_staff')}
                        className={COMMON_INPUT_CLASSES}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="category"
                disabled={isLoading || isSubmitting}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('training.category')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <SelectTrigger className={cn(COMMON_INPUT_CLASSES, 'w-full')}>
                          <SelectValue placeholder={t('training.select_category')} />
                        </SelectTrigger>
                        <SelectContent>
                          {categories.map((category: CategoryI) => (
                            <SelectItem key={category.id} value={category.id}>
                              {t(`training.${category.name}`)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="date"
                disabled={isSubmitting}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild className={COMMON_INPUT_CLASSES}>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                          >
                            {field.value ? (
                              formatDate(field.value.toISOString(), false, false)
                            ) : (
                              <span>{t('training.pick_a_date')}</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          formatters={formatters}
                          locale={locale}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="hours"
                disabled={isSubmitting}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('training.hours')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        step="0.5"
                        className={COMMON_INPUT_CLASSES}
                        onChange={(e) => {
                          const value = e.target.value ? parseFloat(e.target.value) : undefined;
                          if (value && value > 0) {
                            field.onChange(value);
                          } else {
                            field.onChange(0);
                          }
                        }}
                        value={field.value ?? 0}
                        placeholder="Enter hours"
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
              <div>
                <FormField
                  control={form.control}
                  name="file"
                  disabled={isSubmitting}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('training.file')}</FormLabel>
                      <FormControl>
                        <MultiFileDropzone
                          value={field.value}
                          onChange={field.onChange}
                          dropzoneOptions={{
                            accept: ACCEPTED_FILE_TYPES,
                            maxSize: MAX_FILE_SIZE,
                            maxFiles: 1
                          }}
                          className="!min-h-[40px]"
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormField
              control={form.control}
              name="description"
              disabled={isSubmitting}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('training.description')}</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder={t('training.enter_description')}
                      rows={3}
                      className={COMMON_INPUT_CLASSES}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <div className="flex justify-end mt-9">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {t('training.save')}
              </Button>
            </div>
          </form>
        </Form>
      </CustomCard>
    </div>
  );
};

export default AddMultipleCertificate;

import { useTranslation } from 'react-i18next';
import AlertsSkeleton from '@/modules/dashboard/presentation/pages/components/skeleton';
import AlertsList from './AlertList';
import { AlertI } from '@/common/domain/Alerts';
import { useStaffAlerts } from '../../../hooks/useStaffAlerts';
import { useDateHelpers } from '@/utils/helpers/dates.helper';


type AlertCardProps = {
  licenseAlerts: { status: string; data: AlertI[] },
  staffCustomAlerts: { status: string; data: AlertI[] },
};

const AlertCard: React.FC<AlertCardProps> = ({
  licenseAlerts,
  staffCustomAlerts
}) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  const { alerts, hiddenAlerts, setHiddenAlerts } = useStaffAlerts(
    licenseAlerts,
    staffCustomAlerts
  );

  return (
    <>
     {licenseAlerts.status === 'loading' || staffCustomAlerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : alerts.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <AlertsList
              alerts={alerts}
              hiddenAlerts={hiddenAlerts}
              setHiddenAlerts={setHiddenAlerts}
              formatDate={formatDate}
              t={t}
            />
          </div>
        )}
    </>
  )
};

export default AlertCard;

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Users } from 'lucide-react';
import { RootState } from '@/store/store';
import { useResidentAlerts } from '@/common/hooks/Alerts/useResidentAlerts';
import ResidentAlert from '@/common/presentation/components/Alert/Resident/ResidentAlert';
import { AlertCard } from '../alert-card';


export const ResidentAlerts = () => {
  const residentAlerts = useSelector((state: RootState) => state.dashboard.residentAlerts);
  const residentCustomAlerts = useSelector((state: RootState) => state.dashboard.residentCustomAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { t } = useTranslation();

  const { totalAlertsCount } = useResidentAlerts(
    residentAlerts,
    residentCustomAlerts
  );

  return (
    <>
      <AlertCard
        title={t('dashboard.residentAlerts')}
        alerts={Array.from({ length: totalAlertsCount })}
        icon={Users}
        custom={true}
      >
       <ResidentAlert
          residents={residents}
          residentAlerts={residentAlerts}
          residentCustomAlerts={residentCustomAlerts}
        />
      </AlertCard>
    </>
  );
};

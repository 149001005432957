import { ResidentI } from "@/common/domain/Residents";
import { FACILITY_TYPES } from "@/modules/residents/presentation/components/Forms/Licensing/constants/facilityTypes.constants";

export const telecommunicationsDefaults = (resident: ResidentI) => ({
    basic_information: {
      facility_name_signature: resident?.locations?.[0]?.name || '',
      facility_name_address: `${resident?.locations?.[0]?.address ?? ''} ${resident?.locations?.[0]?.city ?? ''} ${resident?.locations?.[0]?.state ?? ''} ${resident?.locations?.[0]?.zip ?? ''}`,
    },
    facility_types: FACILITY_TYPES,
    types_facilities_selected: resident?.locations?.[0]?.category_name ? [resident.locations[0].category_name] : []
});

import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import * as LucideIcons from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/common/presentation/components/ui/accordion';
import { RootState } from '@/store/store';
import Title from '@/common/presentation/components/Title/Title';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { MainMenu } from '@/common/domain/MainMenu';
import MenuCard from '@/common/presentation/components/Card/MenuCard';
import { Card, CardContent} from '@/components/ui/card';
import { accordionSections } from "../../domain/config/accordionSections";


function ReportsPage() {
  const { t } = useTranslation();
  const { configurations, isLoading } = useSelector((state: RootState) => state.configurations);
  const menus: MainMenu[] = configurations?.menus || [];

const filterSubmenusByKey = (menus: MainMenu[], sectionSubmenuIds: string[]): MainMenu[] => {
  return menus.flatMap((menu) =>
    (menu.submenus || []).filter((submenu) =>
      sectionSubmenuIds.includes(submenu.key) && submenu.visible
    )
  );
};


  const DynamicIcon = ({ iconName, className }: { iconName: string; className?: string }) => {
    const IconComponent = LucideIcons[iconName as keyof typeof LucideIcons] as React.ElementType;
    return IconComponent ? <IconComponent className={className} /> : null;
  };

  return (
    <>
      {isLoading &&
        <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
          <Spinner size={'lg'} />
        </div>
      }

      <div className='pb-6 px-4 md:px-8 flex flex-col h-[90vh]'>
        <div className='flex justify-between mt-10 mb-1 md:mb-0'>
         <Title className='text-primary'>
            {t('menu.reports.name')}
          </Title>
        </div>
        <div className='flex flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 text-left
        md:justify-between md:items-center text-zinc-600 text-sm font-medium leading-5 mb-6 py-2'>
          <p className='md:mb-0 dark:text-white'>
            {t('trackers.searchTitle')}
          </p>
        </div>
        <div className="flex flex-col">
          <Card
            className={'w-full p-6 border-t-4 border-t-primary/80 dark:bg-zinc-950 col-span-4 h-full'}
          >
           <CardContent className='p-0 flex-1'>
              <Accordion type="single" collapsible>
                {accordionSections
                  .filter((section) => section.visible)
                  .map((section) => {
                    const filteredSubmenus = filterSubmenusByKey(
                      menus,
                      section.submenu
                        .map((submenu) => submenu.id)
                    );

                  return (
                    <AccordionItem key={section.id} value={section.id} className="w-full p-4 border border-gray-300 transition-all bg-white
                    dark:bg-zinc-900 dark:border-zinc-600 rounded-md">
                      <AccordionTrigger className="p-0 text-primary no-underline focus:outline-none focus:ring-0">
                        <div className="flex items-center gap-4">
                          {section.icon &&(
                            <DynamicIcon
                              iconName={section.icon}
                              className="h-6 w-6"
                            />
                          )}
                          <span className="text-base font-semibold leading-6">
                            {section.title}
                          </span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="flex flex-col items-start mt-4 p-0">
                          <MenuCard
                            submenu={filteredSubmenus}
                            translationContext={`menu.reports.submenus`}
                            isLoading={isLoading}
                          />
                      </AccordionContent>
                    </AccordionItem>
                  )
                })}
              </Accordion>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ReportsPage
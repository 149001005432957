import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from '@/lib/utils';
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';

interface DateTimeFieldProps {
  value?: Date;
  onChange: (value: Date | null) => void;
}

const DateTimeField: React.FC<DateTimeFieldProps> = ({ value, onChange }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-full dark:bg-zinc-800 justify-start text-left font-normal",
            !value && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? format(value, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0">
        <CustomCalendar
          selectedDate={value}
          timePicker={true}
          onChange={(selectedDate) => onChange(selectedDate)}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DateTimeField;

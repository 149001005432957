import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import facesheetService from "../../infrastructure/services/FaceSheetService";

interface IState { 
    registersvitals: Array<any>;
    registers: Array<any>;
    isLoading: boolean;
    categories: Array<{ label: string; value: string | number }>; // Expected format for the MultiSelect
    categoriesvitals: Array<{ label: string; value: string | number }>;
}
  
const initialState: IState = {
    registersvitals: [],
    registers: [],
    isLoading: false,
    categories: [],
    categoriesvitals: [],
};

// Action to export the PDF report
export const exportVitalsReport = createAsyncThunk(
    'facesheet/exportReport',
    async ({ residentId, locationId}: { residentId: string; locationId: string; }) => {
      try {
        const response = await facesheetService.exportReport({ residentId, locationId });
  
        // Create a blob from the response
        const blob = new Blob([response], { type: 'application/pdf' });
  
        // Create URL from blob
        const url = window.URL.createObjectURL(blob);
  
        // Open the PDF in a new tab
        const newTab = window.open();
        if (newTab) {
          newTab.location.href = url; // Set the location of the PDF
        } else {
          //console.error('El navegador bloqueó la ventana emergente.');
        }
  
        return response;
      } catch (error) {
         //console.error('Error al exportar el PDF:', error);
        throw error;
      }
    }
  );

export const FaceSheetSlice = createSlice({
    name: 'facesheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(exportVitalsReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportVitalsReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(exportVitalsReport.rejected, (state) => {
        state.isLoading = false;
      })
    }
});

export default FaceSheetSlice.reducer;

import { CustomAlertsParams } from '@/common/domain/Alerts';
import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { FormCards } from '@/modules/staff/domain/models/profile/formCards';

export const GetPicklist = async () => {
  const response = await axios.get('/staff/get-picklist');
  return response;
};
const StaffService = {
  getGroupsTraining: async (staff_member_id: string) => {
    try {
      const response = await axios.get(`integrations/training/groups?user_id=${staff_member_id}`);
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error list groups training: No data returned from server';
      }
    } catch (error) {
      throw new Error('Error fetching groups: ' + error);
    }
  },
  getAdditionalCourses: async (staff_member_id: any) => {
    try {
      const response = await axios.get(`integrations/training/additional?user_id=${staff_member_id}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching course additional');
    }
  },

  getCertificatesTrainingOnSite: async (type: string | null, category: string, user_id: string) => {
    try {
      const response = await axios.get(
        `integrations/training/certificates?type=${type}&user_id=${user_id}&category=${category}`
      );
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error fetching certificates';
      }
    } catch (error) {
      throw new Error('Error fetching certificates');
    }
  },

  getDigitalTraining: async (user_id: string) => {
    try {
      const response = await axios.get(`integrations/training/digital-training?user_id=${user_id}`);
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error fetching digital';
      }
    } catch (error) {
      throw new Error('Error fetching digitalTraining');
    }
  },

  createTrainingOnSite: async (data: any) => {
    const formData = new FormData();
    formData.append('data', data.data);
    data.files.forEach((file: File, index: number) => {
      if (file instanceof File) {
        formData.append(`file_${index}`, file);
      } else {
        console.error(`file_${index} it is not an object file`, file);
      }
    });
    try {
      const response = await axios.post('integrations/training/single-training', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error creating training';
      }
    } catch (error) {
      throw new Error('Error creating Training on Site');
    }
  },

  getCategoriesTrainingOnSite: async (type?: string) => {
    try {
      const response = await axios.get(`integrations/training/categories?type=${type}`);
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error fetching trainingOnSite';
      }
    } catch (error) {
      throw new Error('Error fetching categories: ' + error);
    }
  },

  createEnrollGroup: async (data: any) => {
    try {
      const response = await axios.post('/staff/createEnrollGroup', data);
      if (response && response.data) {
        return response.data;
      } else {
        return 'Error creating enroll group';
      }
    } catch (error) {
      throw new Error('Error creating enroll group ' + error);
    }
  },

  createStaffMember: async (data: any) => {
    const response = await axios.post('/staff', { ...data });
    return response;
  },
  getStaffById: async (id: string) => {
    const response = await axios.get(`/residents/${id}`);
    return response;
  },
  getStaff: async (locationId?: string) => {
    const response = await axios.get(`/staff${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  },
  getStaffSelector: async (locationId: string = '', roles: string = '') => {
    const response = await axios.get(`/staff/get-staff-selector?locationId=${locationId}&roles=${roles}`);
    return response;
  },
  getStaffMemberById: async (id: string) => {
    const response = await axios.get(`/staff/${id}`, {
      params: {
        include_user_notes: true // Indicar al backend que incluya los datos de las notas del usuario
      }
    });
    return response;
  },

  getStaffAlerts: async (location_id?: string | null, user_id?: string[] | null) => {
    let url = `/alerts/staff?`;

    try {
      const params = new URLSearchParams();

      if (location_id) params.append('location_id', location_id);
      if (user_id && Array.isArray(user_id)) {
        user_id.forEach((id) => {
          params.append('user_id[]', id);
        });
      }

      const config = { params };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      throw new Error('Error: ' + error.message);
    }
  },

  getCustomAlerts: async (params: CustomAlertsParams) => {
    const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

    try {
      const response = await axios.get('/custom-alerts', { params: filteredParams });
      return response.data;
    } catch (error: any) {
      throw new Error('Error: ' + error.message);
    }
  },

  updateStaffPersonalInformation: async (data: any) => {
    const response = await axios.put(`/staff/personal-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffMemberTerminationForm: async (data: any) => {
    const response = await axios.put(`/staff/${data.staff_member_id}/termination`, { ...data });
    return response;
  },
  updateStaffcompanyForm: async (data: any) => {
    const response = await axios.put(`/staff/company-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffTrainingForm: async (data: any) => {
    let url = `integrations/training/group-enroll`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error creating training information';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  updateStaffnotesForm: async (data: any) => {
    const response = await axios.put(`/staff/notes-staff/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffloginForm: async (data: any) => {
    const response = await axios.put(`/staff/login-staff/${data.staff_id}`, { ...data });
    return response;
  },
  getStaffConfigurations: async () => {
    const response = await axios.get('/staff/configurations');
    return response;
  },
  postStaffConfigurations: async (data: any) => {
    const response = await axios.post('/staff/configurations', { ...data });
    return response;
  },

  getFormCardsToShow: async () => {
    const response = await axios.get<FormCards>(`/staff/configurations/profile-forms`);
    return response;
  },

  setFormCardsToShow: async (data: any) => {
    const response = await axios.post(`/staff/configurations/profile-forms`, { ...data });
    return response;
  },

  changeStaffStatus: async (staff_member_id: string, status: string) => {
    const response = await axios.post(`/staff/status/${staff_member_id}`, { status });
    return response;
  },

  getInactiveStaff: async (locationId: string | undefined) => {
    const response = await axios.get(`/staff/inactive${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  },

  updateOtherForm: async (data: any, staff_id: string) => {
    const response = await axios.put(`/staff/${staff_id}/other`, { ...data });
    return response;
  }
};

export default StaffService;

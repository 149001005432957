import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, FormItem, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export const AuthorizationDetailsForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <p className="text-sm text-zinc-700 dark:text-zinc-200 mb-4">
        {t('residents.forms.releaseMedicalInfo.authorizationTitle')}:
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <FormField
            control={control}
            name="expiration_date"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    type="date"
                    className="w-full rounded-md dark:bg-zinc-800"
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('common.date')})
                </p>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="client_representative"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full rounded-md dark:bg-zinc-800"
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.authorizedRepresentative')})
                </p>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="relationship_to_person"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full rounded-md dark:bg-zinc-800"
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.relationshipToSubject')})
                </p>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full rounded-md dark:bg-zinc-800"
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('contacts.address')})
                </p>
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
};

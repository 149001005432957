import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { useProfileImage } from '@/modules/residents/infrastructure/hooks/use-profile-image';
import { AppDispatch, RootState } from '@/store/store';
import { ImageIcon, Loader, Trash } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { ResidentAvatar } from '../ResidentAvatar';
import { ProfileImageDialog } from '../profile-image-dialog';
import * as residentsFormActions from '@/modules/residents/presentation/slices/residentsForm';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

export const ProfilePicture = () => {
  const { onOpen } = useProfileImage();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { status } = useSelector((state: RootState) => state.residents.residentForm);
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const removeImage = async () => {
    const response = await dispatch(residentsFormActions.deleteProfileImage(resident.id));

    if (response.payload) {
      dispatch(residentActions.setResident(response.payload.resident));

      toast({
        description: 'Profile picture uploaded successfully'
      });
    }
  };

  return (
    <>
      <div className="h-full flex flex-col items-center justify-center gap-4">
        <div className="overflow-hidden rounded-md relative group">
          <Avatar
            className={cn(
              'rounded-md min-h-auto max-h-[450px] h-full w-auto transition-all group-hover:scale-105 group-hover:cursor-pointer aspect-[3/4]'
            )}
          >
            <AvatarImage src={resident?.image_url || undefined} alt="User Image" className="object-cover" />
            <AvatarFallback className="bg-[#FFFFFF] dark:bg-[#09090B] text-sm rounded-md">
              <div className="h-full w-full flex flex-col items-center justify-center gap-4 px-16">
                <ResidentAvatar className="h-auto w-full" />
                <p>{t('residents.uploadPicture')}</p>
              </div>
            </AvatarFallback>
          </Avatar>
          <div className="absolute bottom-5 opacity-0 group-hover:opacity-100 flex w-full justify-evenly">
            <Button
              className="text-xs text-muted-foreground flex flex-row gap-2"
              variant={'outline'}
              size={'sm'}
              onClick={onOpen}
              disabled={status === 'loading'}
            >
              <ImageIcon />
              {t('residents.changeImage')}
            </Button>
            {resident?.image_url && (
              <Button
                className="text-xs text-muted-foreground flex flex-row gap-2"
                variant={'outline'}
                size={'sm'}
                onClick={removeImage}
                disabled={status === 'loading'}
              >
                {status === 'loading' ? <Loader className="animate-spin" /> : <Trash />}
                {t('residents.deleteImage')}
              </Button>
            )}
          </div>
        </div>
      </div>

      <ProfileImageDialog />
    </>
  );
};

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { CircleAlert, ClipboardCheck } from 'lucide-react';
import React, { useState } from 'react';
import CustomAlertsStaff from '../components/alerts/CustomAlertsStaff';
import LicensesAlerts from '../components/alerts/LicensesAlerts';
import { useTranslation } from 'react-i18next';
import KeepInEye from '@/common/presentation/components/KeepInEye/KeepInEye';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

interface IProps {
  staffId: string;
}

type alertsTypes = 'license' | 'custom';

const StaffAlertsPage: React.FC<IProps> = ({ staffId }) => {
  const [alertsType, setAlertsType] = useState<alertsTypes>('license');
  const users = useSelector((state: RootState) => state.staff.allStaff.staff);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3">
      <Tabs
        defaultValue={'license'}
        onValueChange={(e) => {
          setAlertsType(e as alertsTypes);
        }}
        className="w-auto items-end flex flex-row-reverse"
      >
        <TabsList>
          <TabsTrigger
            value="license"
            className="data-[state=active]:bg-primary data-[state=active]:text-white flex gap-1"
          >
            <ClipboardCheck className="h-4 w-4 mr-1" />
            {t('alerts.licenses')}
          </TabsTrigger>
          <TabsTrigger
            value="custom"
            className="data-[state=active]:bg-primary data-[state=active]:text-white flex gap-1"
          >
            <CircleAlert className="w-4 h-4" />
            {t('alerts.custom')}
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {alertsType === 'license' ? <LicensesAlerts staffId={staffId} /> : <CustomAlertsStaff staffId={staffId} />}
      <KeepInEye text={`${UserHelper.getStaffName(staffId, users)}`} image={UserHelper.getStaffInformation(staffId, users)?.profile_url ?? ''} showAlways={true} />
    </div>
  );
};

export default StaffAlertsPage;

import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { CategoryAccumulator } from '../note';
import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { fetchRefreshNotes, setNoteFilters, setNoteId, setReaderNote } from '../../slices/NoteSlice';
import { Loader2, Image, MessageSquareText } from 'lucide-react';
import { NoteI } from '../../../domain/note.domain';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';

interface NoteBeetweenStaffListProps {
  items: NoteI[] | [];
  setCrudState: (crudState: CrudState) => void;
  setSize?: (sizes: number[]) => void;
  scroll?: boolean;
  categoriesNames: CategoryAccumulator;
  loadingNotes: boolean;
}

export function NoteBeetweenStaffList({ items, setCrudState, scroll = true, setSize, categoriesNames, loadingNotes }: NoteBeetweenStaffListProps) {
  // Initial
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const metaNote = useSelector((state: RootState) => state.notes.notes?.meta);
  const departments = useSelector((state: RootState) => state.departments.departments);

  // Hooks
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (metaNote && metaNote.page >= metaNote.lastPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [metaNote]);

  //Behaviors
  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const unreadNote = async (noteId: any) => {
    let noteFindIndex = items.findIndex(note => note.id == noteId);

    if (noteFindIndex != -1) {
      let dataReader = {
        id: `temp${items[noteFindIndex].readers.length + 1}`,
        status: 'read',
        userId: authUserId,
        index: noteFindIndex
      }
      dispatch(setReaderNote(dataReader));

      await NoteService.unreadNote({
        note_id: noteId,
        status: 'read'
      });
    }
    await dispatch<any>(fetchRefreshNotes());
  };

  const fectchMoreNotes = async () => {
    setLoading(true);
    dispatch(setNoteFilters(['nextPage', Number(metaNote.page || 0) + 1]));
    setLoading(false);
  };

  if (loadingNotes) {
    return <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>
  }

  if (metaNote.page == 1 && !items.length) {
    return <div className='w-full flex justify-center'>{t("notes.notesNotFound")}</div>
  }

  if (!items) {
    return;
  }

  return (
    <ScrollArea className='h-[25vh] md:h-[calc(100vh-340px)]'>
      <div className="flex flex-col gap-3">
        {items?.map((item) => (
          <div
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all group hover:bg-accent cursor-pointer',
              noteSelected === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(NoteHelperService.defineState({ showingBeetweenStaffNotes: true }));
              setNote(item.id);
              if (setSize) {
                setSize([0, 655]);
              }
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex">
                <div className="gap-2">
                  <div className="font-semibold text-primary">
                    {item.scope_location_names && item.scope_location_names.length > 0
                      ? item.scope_location_names.map((item) => <div key={item}>{item}</div>)
                      : t('notes.allHousesLocationSelector')}
                  </div>
                  <div className="text-xs text-muted-foreground">{`${t('notes.by') + ' '}${item.author}`}</div>
                  {item.category_id && (
                    <div className="text-xs text-muted-foreground ">{t('notes.category') + ': '} {categoriesNames[item.category_id]}</div>
                  )}
                </div>
                <div className='ml-auto text-xs'>
                  <div
                    className={cn(
                      'ml-auto text-xs',
                      noteSelected === item.id ? 'text-foreground' : 'text-muted-foreground'
                    )}
                  >
                    {formatDate(item.format_created_at, false)} - {formatDate(item.format_created_at, true, true)}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-xs text-muted-foreground text-ellipsis line-clamp-3" title={item.content} dangerouslySetInnerHTML={{ __html: item.html_content }}></div>
            <div className="flex justify-between w-full flex-wrap">
              <div className="flex items-center">
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="text-xs p-1 bg-white dark:bg-zinc-800 shadow" variant={'secondary'}>
                      {item.visibility == 'department' ? (
                        item.department_ids.map(dptId => {
                          return departments.find(dpt => dpt.id === dptId)?.department
                        }).join(' - ')
                      ) : (
                        t('notes.' + item.visibility)
                      )}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent className='bg-white dark:bg-zinc-800 border-t-4 border-t-primary/80 text-dark shadow'>
                    {item.noteTo == '' ? t('notes.allStaff') : item.noteTo}
                  </TooltipContent>
                </Tooltip>
                {item.answers && item.answers?.length > 0 && (
                  <Badge className={`text-xs p-1 bg-primary/10 text-primary shadow ml-2`} variant={'secondary'}>
                    {item.answers.length}<MessageSquareText className="w-4 h-4 ml-1" />
                  </Badge>
                )}
                {item.images && item.images?.length > 0 && (
                  <Badge className="text-xs p-1 bg-primary/10 text-primary shadow ml-2" variant={'secondary'}>
                    <Image className="w-4 h-4" />
                  </Badge>
                )}
              </div>
              {item.created_by == authUserId ? (
                <Badge variant="default">{t('notes.author')}</Badge>
              ) : item.readers.filter((item: any) => item.user_id == authUserId).length > 0 &&
                item.readers.filter((item: any) => item.user_id == authUserId)[0].status == 'read' ? (
                <Badge variant="outline" className='bg-green-100 text-green-600'>{t('notes.listReadinsStatus.read')}</Badge>
              ) : (
                <Badge variant="outline" className="bg-red-100 text-red-600" onClick={() => unreadNote(item.id)}>
                  {t('notes.listReadinsStatus.unread')}
                </Badge>
              )}
            </div>
          </div>
        ))}

        {scroll && (
          <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreNotes} threshold={0.5}>
            {hasMore && <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>}
          </InfiniteScroll>
        )}
      </div>
    </ScrollArea>
  );
}

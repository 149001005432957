import { ResidentI } from "@/common/domain/Residents";
import { AllContactsI } from "@/modules/residents/domain/models/Contacts";
import { filterContactsByCategory } from "@/modules/residents/domain/utils/filterContactsByCategory";

export const emergencyMedicalConsentDefaults = (resident: ResidentI, contacts: AllContactsI[]) => {
  const responsiblePerson = filterContactsByCategory(contacts, "Responsible Person");

  return {
    facility_name: resident?.locations?.[0]?.name || '',
    resident_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
    medication_allergies: '',
    date: new Date().toISOString().split('T')[0],
    authorized_signature: '',
    home_address: responsiblePerson?.address || '',
    home_phone: responsiblePerson?.homePhone || '',
    work_phone: responsiblePerson?.workPhone || '',
  };
};

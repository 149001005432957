import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { agenciesNotified } from '../../constants/IncidentReport.constants';

const AgenciesNotifiedForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <h3 className="font-bold text-gray-900 dark:text-gray-100 text-base mb-4">
        {t('residents.forms.deathReportLicensee.agenciesIndividualsNotified')}
      </h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {agenciesNotified.map(({ name }) => (
            <div key={name}>
            <FormField
              control={control}
              name={`${name}.checkbox`}
              render={({ field: checkboxField }) => (
                <FormItem>
                  <div className="flex items-center space-x-4">
                    <Checkbox
                      checked={checkboxField.value}
                      onCheckedChange={(checked) => checkboxField.onChange(checked)}
                    />
                    <FormLabel className="font-normal">
                      {t(`residents.forms.incidentReport.AgenciesNotified.${name}`)}
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name={`${name}.value`}
              render={({ field: inputField }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...inputField}
                      className="w-full dark:bg-zinc-800 mt-2"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        ))}
      </div>
    </>
  )
};

export default AgenciesNotifiedForm;

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultValues, ExamOrProcedure } from '../components/ExamOrProcedureItem';
import { EmptyIcon } from '../../EmptyIcon';
import { useTranslation } from 'react-i18next';

export const ExamAndProcedure = () => {
  const { resident }: { resident: Resident | null; status: string } = useSelector(
    (state: RootState) => state.residents.resident
  );
  const { t } = useTranslation();
  const initialExamnAndProcedures = useMemo(
    () =>
      resident?.medical_information?.exam_and_procedures?.map((d) => ({
        id: d.id,
        exam_or_procedure: d.exam_or_procedure,
        description: d.description,
        date: d.date ? new Date(d.date) : undefined
      })) || [],
    [resident]
  );

  const [examnAndProcedures, setExamnAndProcedures] = useState<DefaultValues[]>(initialExamnAndProcedures);

  const {
    uppsertExamOrProcedureInformation,
    deleteExamOrProcedureInformation,
    status: medicalInformationFormStatus
  } = useMedicalInformationStore();

  const addExamOrProcedure = useCallback(() => {
    setExamnAndProcedures((prevExamnAndProcedures) => [
      { exam_or_procedure: '', description: '', date: new Date() },
      ...prevExamnAndProcedures
    ]);
  }, []);

  const createOrUpdateExamOrProcedure = useCallback(
    async (values: any) => {
      const payload = { resident_id: resident?.id, ...values };

      try {
        const response = await uppsertExamOrProcedureInformation(payload);

        setExamnAndProcedures((prevExamnAndProcedures) =>
          prevExamnAndProcedures.map((examnAndProcedure) =>
            examnAndProcedure.id === null ? { ...values, id: response.exam_or_procedure } : examnAndProcedure
          )
        );

        toast({ description: t('residents.medicalInformation.examProcedure.updatedExam') });
      } catch (error) {
        toast({ description: 'There was an error updating the exam or procedure', variant: 'destructive' });
      }
    },
    [uppsertExamOrProcedureInformation, resident]
  );

  const removeExamOrProcedure = useCallback(
    async (id: string | null) => {
      try {
        if (id !== null) {
          const payload = { id, resident_id: resident?.id };

          const response = await deleteExamOrProcedureInformation(payload);
          const newExamnAndProcedures =
            examnAndProcedures?.filter((examnAndProcedure) => examnAndProcedure.id !== id) || [];
          setExamnAndProcedures(newExamnAndProcedures);

          if (response) {
            toast({ description: t('residents.medicalInformation.examProcedure.removeExam') });
          } else {
            setExamnAndProcedures(
              examnAndProcedures?.filter((examnAndProcedure) => examnAndProcedure.id !== id) || null
            );
          }
        }
      } catch (error) {
        toast({ description: 'There was an error removing the exam or procedure', variant: 'destructive' });
      }
    },
    [deleteExamOrProcedureInformation, examnAndProcedures, resident]
  );

  return (
    <CustomAccordion
      openOption={true}
      titleComponent={<h1 className="text-xl font-bold">{t('residents.medicalInformation.examProcedure.examProcedure')}</h1>}
      defaultOpen={true}
    >
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex items-center justify-between">
          <p>{t('residents.medicalInformation.examProcedure.description')}</p>

          <Button className="flex gap-2" variant={'default'} onClick={() => addExamOrProcedure()}>
            <CircleFadingPlus className="h-4 w-4 flex-1" />
            {t('residents.medicalInformation.examProcedure.newExam')}
          </Button>
        </div>

        <Separator />

        {examnAndProcedures?.length === 0 && (
          <div className="w-full flex flex-col items-center py-12 gap-4">
            <EmptyIcon className="h-auto w-[8vw]" />
            <p>{t('residents.medicalInformation.examProcedure.noExams')}</p>
          </div>
        )}

        {examnAndProcedures?.map((d, index) => (
          <ExamOrProcedure
            key={d.id || index}
            defaultValues={d}
            onSubmit={createOrUpdateExamOrProcedure}
            onRemove={removeExamOrProcedure}
            disabled={medicalInformationFormStatus === 'loading'}
          />
        ))}

        <Separator />

        <p>
        {t('residents.medicalInformation.examProcedure.totalExams')} <span className="font-bold">{examnAndProcedures?.length}</span>
        </p>
      </div>
    </CustomAccordion>
  );
};

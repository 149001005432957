import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { CustomCard } from "../../shared/CustomCard";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from '@/common/presentation/components/ui/input';

const EmergencyHospitalizationForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <CustomCard>
      <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-10 mb-4">
        <h4 className="font-semibold text-base">
          9. {t('residents.forms.lic601EmergencyInfo.emergencyHospitalizationPlan')}
        </h4>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="space-y-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <FormField
            control={control}
            name="hospitalization_plan.hospital_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.nameHospital')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={100}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="hospitalization_plan.hospital_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.addressHospital')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={100}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="hospitalization_plan.medical_plan"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.medicalPlan')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={50}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="hospitalization_plan.medical_plan_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.medicalPlanIdentification')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={50}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="hospitalization_plan.dental_plan"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.nameDentalPlan')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={50}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="hospitalization_plan.dental_plan_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.lic601EmergencyInfo.dentalPlanNumber')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={50}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </CustomCard>
  );
};

export default EmergencyHospitalizationForm;

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Separator } from "@/components/ui/separator";

const TuberculosisForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="font-semibold text-base">
        {t('residents.forms.residentAppraisal.tuberculosisInformation')}
      </h4>
      
      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
        <FormField
          control={control}
          name="tb_history_family"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.residentAppraisal.historyTuberculosis')}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  value={field.value}
                  onValueChange={field.onChange}
                  className="flex space-x-4 mt-2"
                >
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="yes" />
                    <span className="text-sm">{t('common.yes')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="no" />
                    <span className="text-sm">{t('common.no')}</span>
                  </label>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="tb_test_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">{t('residents.forms.residentAppraisal.dateTbTest')}</FormLabel>
              <FormControl>
                <Input
                  type="date"
                  {...field}
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="tb_test_result"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <RadioGroup
                  value={field.value}
                  onValueChange={field.onChange}
                  className="flex space-x-4 mt-2"
                >
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="positive" />
                    <span className="text-sm">{t('residents.forms.residentAppraisal.positive')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="negative" />
                    <span className="text-sm">{t('residents.forms.residentAppraisal.negative')}</span>
                  </label>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
        <FormField
          control={control}
          name="recent_tb_exposure"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.residentAppraisal.recentTuberculosisExposure')}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  value={field.value}
                  onValueChange={field.onChange}
                  className="flex space-x-4 mt-2"
                >
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="yes" />
                    <span className="text-sm">{t('common.yes')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="no" />
                    <span className="text-sm">{t('common.no')}</span>
                  </label>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="tb_action_taken"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.residentAppraisal.actionTaken')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  maxLength={180}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="mt-4 lg:mt-0">
        <FormField
          control={control}
          name="tb_details"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal mt-4">{t('residents.forms.residentAppraisal.giveDetails')}</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  maxLength={350}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default TuberculosisForm;

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { FormField, FormItem, FormControl, FormLabel } from "@/components/ui/form";

const EmergencyInfoForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      <div>
        <FormField
          control={control}
          name="medication_allergies"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
              {t('residents.forms.emergencyMedicalConsentForm.medicationAllergies')}:
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  maxLength={1000}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start leading-tight">
        <FormField
          control={control}
          name="date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('common.date')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  type="date"
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="authorized_signature"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
              {t('residents.forms.emergencyMedicalConsentForm.authorizedSignature')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <FormField
          control={control}
          name="home_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Home Address
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="home_phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Home Phone
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="work_phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Work Phone
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default EmergencyInfoForm;

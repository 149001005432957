import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { agenciesNotified } from '../../constants/IncidentReport.constants';

const AgenciesNotifiedForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <h5 className="font-semibold text-lg">
        {t('residents.forms.incidentReport.agenciesIndividualsNotified')}{" "}
        <span className="text-sm font-normal">
          ({t('residents.forms.incidentReport.specifyNameAndTelephone')})
        </span>
      </h5>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {agenciesNotified.map((agency) => (
          <FormField
            key={agency.name}
            control={control}
            name={agency.name}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t(`residents.forms.incidentReport.AgenciesNotified.${agency.name}`)}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="dark:bg-zinc-800 w-full"
                    maxLength={60}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default AgenciesNotifiedForm;

import { useTranslation } from "react-i18next";
import { ResidentI } from "@/common/domain/Residents";
import { AlertI, AlertsResponseI, AlertType } from "@/common/domain/Alerts";
import { useDateHelpers } from "@/utils/helpers/dates.helper";
import { UserHelper } from '@/utils/helpers/user.helper';
import ButtonMarkAsDone from "@/modules/dashboard/presentation/pages/components/ButonMarkAsDone/ButtonMarkAsDone";
import AlertItemCard from "../../AlertItemCrad.tsx/AlertItemCard";

export const AlertRenderer: React.FC<{
  alert: AlertType;
  residents: ResidentI[];
  hiddenAlerts: any;
  setHiddenAlerts: any
}> = ({
  alert,
  residents,
  hiddenAlerts,
  setHiddenAlerts
}) => {
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  const valueIsDate = (alertKey: string) => {
    return ['no_bowel_movement_since'].includes(alertKey);
  };

  if (alert.type === 'default') {
    const defaultAlert = alert as AlertsResponseI;
    return (
      <AlertItemCard
        names={UserHelper.getResidentName(defaultAlert.resident_id, residents) ?? ''}
        title={t(`dashboard.${defaultAlert.alert_key}`, {
          value: valueIsDate(defaultAlert.alert_key) ? formatDate(`${defaultAlert.value}`, false, false) : defaultAlert.value,
          evaluator: defaultAlert.evaluator
        })}
        date={formatDate(defaultAlert.date, false, false, false)}
        image={UserHelper.getResidentInformation(defaultAlert.resident_id, residents)?.image_url ?? ''}
      />
    );
  }

  if (alert.type === 'custom') {
    const customAlert = alert as AlertI;
    return customAlert.residents.map((resident) => (
      <AlertItemCard
        key={resident.id}
        names={UserHelper.getResidentName(resident.id, residents) ?? ''}
        title={customAlert.title}
        date={formatDate(customAlert?.date ?? '', false, false, false)}
        image={UserHelper.getResidentInformation(resident.id, residents)?.image_url ?? ''}
        Button={
          <ButtonMarkAsDone
            alertId={customAlert.id}
            relatedId={resident.id}
            hiddenAlerts={hiddenAlerts}
            setHiddenAlerts={setHiddenAlerts}
          />
        }
      />
    ));
  }

  if (alert.type === "resident_reports") {
    const customizedReports = alert as AlertI;
    return (
      <AlertItemCard
        names={`${customizedReports.residents[0].first_name} ${customizedReports.residents[0].last_name}`}
        title={t(`alerts.${customizedReports.title}`, {
          date: formatDate(customizedReports?.date ?? '', false, false, false)
        })}
        date={customizedReports.date}
        image={UserHelper.getResidentInformation(customizedReports.residents[0].id, residents)?.image_url ?? ''}
      />
  );
  }
};

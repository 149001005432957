export const generatePDF = (base64Data: string) => {
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const uintArray = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    uintArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([uintArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
  setTimeout(() => URL.revokeObjectURL(url), 100);
};
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { CardContent} from '@/components/ui/card';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '@/common/presentation/components/ui/accordion';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import FormActionButtons from '../shared/FormActionButtons';
import { CustomCard } from '../shared/CustomCard';
import PropertyTablesAccordion from './components/PropertyTablesAccordion';
import ResidentialCareText from './components/ResidentialCareText';
import LicenseeDateForm from './components/LicenseeDateForm';
import ResidentDetailSection from './components/ResidentDetailSection';
import { residentPersonalPropertyDefaults } from '../formDefaults/residentPersonalPropertyDefaults';
import { filterEmptyRows } from '@/modules/residents/domain/utils/Forms/formUtils';

interface ValuableItem {
  number: string;
  description: string;
  date: string;
  location: string;
}

export const ResidentPersonalPropertyForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const methods = useForm({
    defaultValues: residentPersonalPropertyDefaults(resident),
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(residentPersonalPropertyDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic621_property_record',
  });

  const processValuablesEntrusted = (data: ValuableItem[]): ValuableItem[] => {
    return data.map((item) => ({
      ...item,
      date: item.date ? format(parseISO(item.date), 'MM/dd/yyyy') : ''
    }));
  };

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedValuablesEntrusted = processValuablesEntrusted(filterEmptyRows(rawData.valuables_entrusted || []))
    const cleanedValuablesRemoved = processValuablesEntrusted(filterEmptyRows(rawData.valuables_removed || []))

    const cleanedData = {
      ...rawData,
      date_completed: rawData.date_completed ? format(parseISO(rawData.date_completed), 'MM/dd/yyyy') : '',
      valuables_entrusted: cleanedValuablesEntrusted.length > 0 ? cleanedValuablesEntrusted : [],
      valuables_removed: cleanedValuablesRemoved.length > 0 ? cleanedValuablesRemoved : []
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
        <BackNavigationBar
          title={title}
          path={`/residents/forms?r=${params.r}`}
        />
        <FormProvider {...methods}>
          <form>
            <ResidentDetailSection />
            <PropertyTablesAccordion />
            <CustomCard>
              <CardContent className="p-0 flex-1">
                <Accordion type="single" collapsible>
                  <AccordionItem value="table1" className='border-b-0 w-full transition-all'>
                    <AccordionTrigger className='p-0'>
                      Residential Care Facilities for the Elderly Only
                    </AccordionTrigger>
                    <AccordionContent>
                      <hr className="w-full border-t-1 border-primary p-0 my-4" />
                      <ResidentialCareText />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardContent>
            </CustomCard>
            <CustomCard>
              <LicenseeDateForm />
              <FormActionButtons
                handleFormSubmit={handleFormSubmit}
                formsStatus={formsStatus}
              />
            </CustomCard>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

import { ResidentI } from "@/common/domain/Residents";

export const releaseMedicalInfoDefaults = (resident: ResidentI) => ({
    to_user: '',
    to_date: new Date().toISOString().split('T')[0],
    to_address: '',
    resident_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
    facility_name: resident?.locations?.[0]?.name || '',
    facility_address: `${resident?.locations?.[0]?.address ?? ''} ${resident?.locations?.[0]?.city ?? ''} ${resident?.locations?.[0]?.state ?? ''} ${resident?.locations?.[0]?.zip ?? ''}`,
    expiration_date: '',
    client_representative: '',
    relationship_to_person: '',
    address: '',
});

import { useTranslation } from 'react-i18next';
import { TriangleAlert } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { AlertI, CustomAlertResponse } from '@/common/domain/Alerts';
import { ResidentI } from '@/common/domain/Residents';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { useResidentAlerts } from '@/common/hooks/Alerts/useResidentAlerts';
import { InfoCard } from '@/common/presentation/components/Card/InfoCard';
import ResidentAlert from '@/common/presentation/components/Alert/Resident/ResidentAlert';

interface StaffAlertsProps {
  className?: string;
  residentId: string | null;
}

export const AlertsCard = ({ className, residentId }: StaffAlertsProps) => {
  const { vitals: residentAlerts, customizedReports } = useSelector((state: RootState) => state.residents.resident.alerts);
  const { customAlerts } = useSelector((state: RootState) => state.residents.resident.alerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { t } = useTranslation();

  const residentCustomAlerts: CustomAlertResponse = {
    status: customAlerts?.status ?? 'idle',
    data:
      customAlerts?.data
        ?.map((alert: AlertI) => {
          const filteredResidents = alert?.residents?.filter((resident: ResidentI) => resident.id === residentId);

          if (filteredResidents?.length > 0) {
            return {
              ...alert,
              residents: filteredResidents
            };
          }
          return null;
        })
        .filter(Boolean) || []
  };

  const { totalAlertsCount } = useResidentAlerts(
    residentAlerts,
    residentCustomAlerts,
    customizedReports
  );
  
  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px] flex flex-col"
        headerTitle={t('residents.alerts')}
        headerIconLeft={TriangleAlert}
        numberOfAlerts={totalAlertsCount}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 max-h-[145px] overflow-y-hidden">
          <div className="space-y-3.5">
            <ResidentAlert
              residents={residents}
              residentAlerts={residentAlerts}
              residentCustomAlerts={residentCustomAlerts}
              customizedReports={customizedReports}
            />
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};

import { EmarContent } from '@/modules/settings/presentation/components/menus/emar/EmarSettings';
import { AllowStaffPermissionsMedication } from '@/modules/settings/presentation/components/menus/medications/AllowStaffPermissionsMedication';
import { ResidentContent } from '@/modules/settings/presentation/components/menus/Residents/ResidentParameters/ResidentsSettings';
import { StaffContent, StaffTab } from '@/modules/settings/presentation/components/menus/Staff/StaffSettings';
import { EnabledTrackers } from '@/modules/settings/presentation/components/menus/trackers/presentation/components/EnabledTrackers';
import { TrackerCleaningAreaTask } from '@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaTask';
import * as LucideIcon from 'lucide-react';

import { Module } from './interfaceModules'
import { QuickNotes } from '@/modules/settings/presentation/components/menus/notes/presentation/components/QuickNotes/QuickNotes';
import { EMarXs } from '@/modules/settings/presentation/components/menus/medications/presentation/components/EMarXs';
import { Questions } from '../../presentation/components/menus/Questions/presentation/components/Questions';
import TrackerHygieneType from '@/modules/tracker/presentation/pages/TrackerHygieneType';
import TrackerVitalsType from '@/modules/tracker/presentation/pages/TrackerVitalsType';
import TrackerDirectType from '@/modules/tracker/presentation/pages/TrackerDirectType';
import { BedroomQrCategory } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/BedroomQrCategory';
import { VisitQuestion } from '@/modules/settings/presentation/components/menus/Staff/ScreeningQuestions/presentation/components/VisitQuestion';
import { NoteCategory } from '@/modules/settings/presentation/components/menus/notes/presentation/components/NoteCategory/NoteCategory';
import { SheetField } from '../../presentation/components/menus/Residents/SheetField/presentation/components/SheetField';
import MainMenu from '../../presentation/components/menus/MainMenu/presentation/components/MainMenu';
import EmailNotifications from '@/modules/pharmacy/presentation/pages/settings/EmailNotifications';
import { DocumentsCategory } from '../../presentation/components/menus/Residents/DocumentCategories/presentation/components/DocumentsCategory';
import { DocumentsCategory as StaffDocumentsCategory } from '../../presentation/components/menus/Staff/DocumentCategories/presentation/components/DocumentsCategory';
import { DocumentsFile } from '../../presentation/components/menus/Staff/DocumentFiles/presentation/components/DocumentsFile';
import { LocationDocumentsCategory } from '../../presentation/components/menus/Locations/LocationDocumentCategories/presentation/components/LocationDocumentsCategory';
import { LocationDocumentsFolder } from '../../presentation/components/menus/Locations/LocationDocumentFolder/presentation/components/LocationDocumentsFolder';

/*******************************************************************************
 * 🚨 IMPORTANT 🚨
 * 
 * The `key` must:
 * 1. Be the same as the key in the `settings` object to ensure the search 
 *    functionality works properly in the settings.
 * 2. Match the key in the translation file for seamless localization.
 * 
 * Example:
 *   key: 'adlArfOnly',
 *   label: t('settings.adlArfOnly'),
 * 
 * This ensures that:
 * - The search functionality in the settings works without issues.
 * - Translations are correctly mapped and displayed.
 ******************************************************************************/

export const modules = (t: (key: string) => string): Module[] => {
  return [
    // {
    //   key: 'adlArfOnly',
    //   label: t('settings.adlArfOnly'),
    //   icon: <LucideIcon.ClipboardCheck />,
    //   component: null
    // },
    // {
    //   key: 'addressbookParameters',
    //   label: t('settings.addressbookParameters'),
    //   icon: <LucideIcon.BookOpen />,
    //   component: null
    // },
    // {
    //   key: 'calendarParameters',
    //   label: t('settings.calendarParameters'),
    //   icon: <LucideIcon.Calendar />,
    //   component: null
    // },
    // {
    //   key: 'caseloadConfiguration',
    //   label: t('settings.caseloadConfiguration'),
    //   icon: <LucideIcon.FileStack />,
    //   component: null
    // },
    // {
    //   key: 'clientConfiguration',
    //   label: t('settings.clientConfiguration'),
    //   icon: <LucideIcon.Settings />,
    //   component: null
    // },
    {
      key: 'facilities',
      label: t('settings.facilities'),
      icon: <LucideIcon.Building2 />,
      subModules: [
        // {
        //   key: "facilitiesDocumentationParameters",
        //   label: t('settings.facilitiesDocumentationParameters'),
        //   icon: <LucideIcon.ClipboardList />,
        //   component: ''
        // },
        {
          key: "documentCategories",
          label: t('settings.documentCategories'),
          icon: <LucideIcon.FileArchive />,
          component: <LocationDocumentsCategory />
        },
        {
          key: "documentFiles",
          label: t('settings.documentFiles'),
          icon: <LucideIcon.File />,
          component: <LocationDocumentsFolder />
        },

      ]
    },
    // {
    //   key: 'libraryOfResourcesParameters',
    //   label: t('settings.libraryOfResourcesParameters'),
    //   icon: <LucideIcon.Library />,
    //   component: null
    // },
    {
      key: "mainMenu",
      label: t('settings.mainMenu'),
      component: <MainMenu />,
      icon: <LucideIcon.Layout />
    },
    {
      key: 'medications',
      label: t('settings.medications'),
      icon: <LucideIcon.Pill />,
      subModules: [
        {
          key: "staffPermissionsAssignments",
          label: t('settings.staffPermissionsAssignments'),
          icon: <LucideIcon.ClipboardList />,
          component: <AllowStaffPermissionsMedication />
        },
        {
          key: "eMarXs",
          label: t('settings.eMarXs'),
          icon: <LucideIcon.FileCheck />,
          component: <EMarXs />
        }
      ]
    },
    {
      key: 'notesParameters',
      label: t('settings.notesParameters'),
      icon: <LucideIcon.FileText />,
      subModules: [
        {
          key: "quickNotes",
          label: t('settings.quickNotes'),
          icon: <LucideIcon.FileEdit />,
          component: <QuickNotes />
        },
        {
          key: "noteCategories",
          label: t('settings.noteCategories'),
          icon: <LucideIcon.Files />,
          component: <NoteCategory />
        },
      ]
    },
    // {
    //   key: 'reports',
    //   label: t('settings.reports'),
    //   icon: <LucideIcon.FileBarChart />,
    //   component: null
    // },
    {
      key: 'residentsTitle',
      label: t('settings.residentsTitle'),
      icon: <LucideIcon.Users />,
      subModules: [
        {
          key: 'residentsParameters',
          label: t('settings.residentsParameters'),
          component: <ResidentContent />,
          icon: <LucideIcon.Users />,
        },
        {
          key: "sheetFields",
          label: t('settings.sheetFields'),
          icon: <LucideIcon.StickyNote />,
          component: <SheetField />,
        },
        {
          key: "documentCategories",
          label: t('settings.documentCategories'),
          icon: <LucideIcon.FileArchive />,
          component: <DocumentsCategory />
        },

      ]
    },
    // {
    //   key: 'responsiblePartyAccess',
    //   label: t('settings.responsiblePartyAccess'),
    //   icon: <LucideIcon.Users />,
    //   component: null
    // },
    // {
    //   key: 'shiftConfiguration',
    //   label: t('settings.shiftConfiguration'),
    //   icon: <LucideIcon.Clock />,
    //   component: null
    // },
    {
      key: 'staffParameters',
      label: t('settings.staffParameters'),
      icon: <LucideIcon.UserCog />,
      subModules: [
        {
          key: "staffFormCards",
          label: t('settings.staffFormCards'),
          icon: <LucideIcon.UserCheck />,
          component: <StaffContent />,
        },
        {
          key: "activateVisitQuestions",
          label: t('settings.activateVisitQuestions'),
          icon: <LucideIcon.ClipboardCheck />,
          component: <VisitQuestion />,
        },
        {
          key: "documentCategories",
          label: t('settings.documentCategories'),
          icon: <LucideIcon.FileArchive />,
          component: <StaffDocumentsCategory />
        },
        {
          key: "documentFiles",
          label: t('settings.documentFiles'),
          icon: <LucideIcon.File />,
          component: <DocumentsFile />
        }
      ]
    },
    // {
    //   key: 'mySubscription',
    //   label: t('settings.mySubscription'),
    //   icon: <LucideIcon.CreditCard />,
    //   component: null
    // },
    {
      key: 'trackerParameters',
      label: t('settings.trackerParameters'),
      icon: <LucideIcon.Activity />,
      subModules: [
        {
          key: "cleaning",
          label: t('settings.cleaning'),
          icon: <LucideIcon.SprayCan />,
          isRequiredLocation: true,
          component: <TrackerCleaningAreaTask
            hasModal={true}
          />
        },
        {
          key: "bedroomQrCategories",
          label: t('settings.bedroomQrCategories'),
          icon: <LucideIcon.QrCode />,
          component: <BedroomQrCategory />
        },
        {
          key: "vitals",
          label: t('settings.vitals'),
          icon: <LucideIcon.Heart />,
          component: <TrackerVitalsType />
        },
        {
          key: "direct",
          label: t('settings.direct'),
          icon: <LucideIcon.Target />,
          component: <TrackerDirectType />
        },
        {
          key: "hygiene",
          label: t('settings.hygiene'),
          icon: <LucideIcon.ShowerHead />,
          component: <TrackerHygieneType />
        },
        {
          key: "enabledTrackers",
          label: t('settings.enabledTrackers'),
          icon: <LucideIcon.ToggleRight />,
          isRequiredLocation: true,
          component: <EnabledTrackers />
        }
      ]
    },
    {
      key: 'visitorModule',
      label: t('settings.visitorModule'),
      icon: <LucideIcon.UserPlus />,
      subModules: [
        {
          key: "questions",
          label: t('settings.questions'),
          icon: <LucideIcon.HelpCircle />,
          component: <Questions />
        },
      ]
    }
  ] as Module[];
}

export const pharmacyModules = (t: (key: string) => string): Module[] => {
  return [
    {
      key: 'emailNotifications',
      label: t('settings.emailNotifications'),
      icon: <LucideIcon.Mail />,
      component: <EmailNotifications />
    },
  ] as Module[];
};
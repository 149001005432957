import TrackerVisitorsFormFrame from "./TrackerVisitorsFormFrame";
import { ReactNode } from "react";

interface TrackerVisitorsResultProps {
    title: string;
    description: string;
    body: string;
    children?: ReactNode;
    backgroundColor: string;
};

const TrackerVisitorsResult = ({ title, description, body, backgroundColor, children }: TrackerVisitorsResultProps) => {
    return (
        <TrackerVisitorsFormFrame 
            background={backgroundColor}
        >
            <div className="flex flex-col justify-center items-center" style={{ maxWidth: "28rem" }}>
                    {children}

                    <h1
                        style={
                            {
                                marginBottom: "2rem",
                                color: "#fff",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "40px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "28px"
                            }
                        }
                    >{title}</h1>

                    <h2
                        style={
                            {
                                marginBottom: "0.5rem",
                                color: "#fff",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "28px"
                            }
                        }
                    >{description}</h2>

                    <p
                        className="max-w-[12rem] text-white text-center text-base mb-5"
                        style={
                            {
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "20px",
                                justifySelf: "center"
                            }
                        }
                    >{body}</p>
                </div>
        </TrackerVisitorsFormFrame>
    );
};

export default TrackerVisitorsResult;

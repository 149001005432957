import { useEffect, useMemo, useState } from 'react';
import { TimerSelector } from './TimerSelector';
import { UserAccordion } from './UserAccordion';
import { EmarRoutineCard } from './EmarRoutineCard';
import { format } from 'date-fns';
import { useMedicationStore } from '../../domain/stores/useMedicationStore';
import { timeUtils } from '../../domain/timeUtils';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { Loader2 } from 'lucide-react';

interface Medication {
  id: string;
  resident_id: string;
  time?: string[];
  emar_routine_medications?: any[];
  emar_medication_exceptions?: any[];
}

interface Props {
  medications: Medication[];
  residentId: string;
  date: Date;
  emar: string;
}

export const EmarRoutineSection = ({ medications, residentId, date, emar }: Props) => {
  const { loadingStates } = useMedicationStore();
  const [processingStates, setProcessingStates] = useState({
    times: true,
    completedTimes: true,
    filteredMedications: true
  });

  const times = useMemo(() => {
    try {
      const allTimes = medications.flatMap((medication) => medication.time || []);
      let uniqueTimes = Array.from(new Set(allTimes.map(timeUtils.normalizeTime)));
          uniqueTimes = uniqueTimes.filter((time) => time && !time.includes(','));

      setProcessingStates(prev => ({ ...prev, times: false }));
      return uniqueTimes;
    } catch (error) {
      console.error(error);
      setProcessingStates(prev => ({ ...prev, times: false }));
      return [];
    }
  }, [medications]);

  const [selectedTime, setSelectedTime] = useState<string>(times[0] || '');

  const completedTimes = useMemo(() => {
    try {
      const groupedByDateTime = medications.reduce((acc: Record<string, Medication[]>, medication) => {
        const medicationTimes = (medication.time || []).map(timeUtils.normalizeTime);
        medicationTimes.forEach((time) => {
          if (!acc[time]) acc[time] = [];
          acc[time].push(medication);
        });
        return acc;
      }, {});
      
      const validTimes = times.filter((time) => {
        const medicationsAtTime = groupedByDateTime[time] || [];
        return medicationsAtTime.every((medication: any) => {
          const hasSpecialDay =
            useMedicationStore.getState().getCardState(medication.special_days, date).active ;  
  
          const routineMedication = medication.emar_routine_medications?.find(
            (m: any) =>{
             return m.resident_medication_id === medication.id &&
              m.time_to_be_witnessed === time &&
              m.date_to_be_witnessed?.split('T')[0] === format(date, 'yyyy-MM-dd') &&
              m.witnessed === 1
            }
          ) ?? false;
   
          
          const isRoutine = routineMedication || !hasSpecialDay;
  
          const exception = medication.emar_medication_exceptions?.find(
            (m: any) =>
              m.resident_medication_id === medication.id &&
              m.time_to_be_dispensed === time &&
              m.date_to_be_dispensed?.split('T')[0] === format(date, 'yyyy-MM-dd')
          );
       
          return isRoutine || exception;
        });
      });
      setProcessingStates(prev => ({ ...prev, completedTimes: false }));
      return validTimes;
    } catch (error) {
      console.error(error);
      setProcessingStates(prev => ({ ...prev, completedTimes: false }));
      return [];
    }

  }, [medications, times, date]);

  const filteredMedicationsByResident = useMemo(() => {
    const normalizedTime = timeUtils.normalizeTime(selectedTime);
    try {
      const residentMedications = medications.reduce((acc: Record<string, Medication[]>, medication) => {
        const medResidentId = medication.resident_id;
        if (!acc[medResidentId]) acc[medResidentId] = [];
        const medicationTimes = medication.time?.map(timeUtils.normalizeTime) || [];
        if (medicationTimes.includes(normalizedTime)) {
          acc[medResidentId].push(medication);
        }
        return acc;
      }, {});
      setProcessingStates(prev => ({ ...prev, filteredMedications: false }));
      return residentMedications;

    } catch (error) {
      console.error(error);
      setProcessingStates(prev => ({ ...prev, filteredMedications: false }));
    }
  }, [medications, selectedTime]);


  useEffect(() => {
    if (times.length > 0 && !selectedTime) {
      setSelectedTime(times[0]);
    }
  }, [times, selectedTime]);


  const isAnyProcessing = Object.values(processingStates).some(state => state == true);
  const isLoading = loadingStates.getMedicationsByResident || isAnyProcessing;
  
  return isLoading ? (
    <div className='flex justify-center items-center w-full h-full'>
      <SplashScreen className='h-12'/>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">
        Routine Medications
      </h2>

      <TimerSelector
        completedTimes={completedTimes}
        onSelect={setSelectedTime}
      />

      {residentId === 'all-residents' ? (
        <>
          {Object.keys(filteredMedicationsByResident)
            .filter((residentId) => filteredMedicationsByResident[residentId].length > 0)
            .map((residentId) => (
              <div key={residentId}>
                <UserAccordion
                  medications={filteredMedicationsByResident[residentId]}
                  selectedDate={date}
                  selectedTime={selectedTime}
                  emarType={emar}
                  residentId={residentId}
                />
              </div>
            ))}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredMedicationsByResident[residentId]?.map((medication) => (
            <EmarRoutineCard
              data={medications.find((med) => med.id === medication.id)}
              key={medication.id}
              selectedDate={date ?? new Date()}
              selectedTime={selectedTime ?? ''}
            />
          ))}
        </div>
      )}
    </div>
  );
};

import { Label } from '@/components/ui/label';
import CoursesFilter from './CoursesFilter';
import CourseCard from './CourseCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { courseStatus } from '../../domain/training.domain';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Grid3X3, List } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchEnrolledCourses } from '../slices/TrainingSlice';
import CourseCardSkeleton from './CourseCardSkeleton';
import { useTranslation } from 'react-i18next';

type viewModeType = 'list' | 'grid';

const CoursesList: React.FC = () => {
  const [currentFilter, setCurrentFilter] = useState<courseStatus>('all');
  const [viewMode, setViewMode] = useState<viewModeType>('grid');
  const dispatch = useDispatch<AppDispatch>();
  const trainingState = useSelector((state: RootState) => state.training.coursesState);
  const { t } = useTranslation();

  useEffect(() => {
    getEnrolledCourses();
  }, []);

  const getEnrolledCourses = useCallback(async () => {
    await dispatch(fetchEnrolledCourses());
  }, []);

  const courses =
    useMemo(() => {
      if (trainingState.status !== 'idle') {
        return [];
      }
      if (currentFilter === 'all') {
        return trainingState.data;
      }
      return trainingState.data.filter((course) => course.status === currentFilter);
    }, [currentFilter, trainingState]) ?? [];

  return (
    <div className="container mx-auto px-4 py-6 space-y-6">
      <div className="flex flex-col items-start">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col gap-5 w-full">
            <div className="flex items-center gap-4">
              <Label className="text-2xl font-bold dark:text-zinc-300">{t('training.courses')} ({courses.length})</Label>
            </div>
            <div className="flex items-center w-full">
              <CoursesFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
              <div className="ml-auto flex items-center gap-2">
                <List
                  onClick={() => setViewMode('list')}
                  className={`cursor-pointer ${
                    viewMode === 'list'
                      ? 'text-primary'
                      : 'text-black dark:text-white hover:text-gray-700 dark:hover:text-zinc-200'
                  }`}
                  size={20}
                />
                <Grid3X3
                  onClick={() => setViewMode('grid')}
                  className={`cursor-pointer ${
                    viewMode === 'grid'
                      ? 'text-primary'
                      : 'text-black dark:text-white hover:text-gray-700 dark:hover:text-zinc-200'
                  }`}
                  size={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-lg border p-2 bg-white dark:bg-zinc-800">
        {trainingState.status === 'loading' ? (
          <div
            className={
              viewMode === 'grid'
                ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'
                : 'grid grid-cols-1 sm:grid-cols-2 gap-4'
            }
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <CourseCardSkeleton key={index} />
            ))}
          </div>
        ) : courses.length === 0 ? (
          <div className="flex items-center justify-center h-96">
            <p className="text-gray-500 dark:text-zinc-400">{t('training.no_courses_found')}</p>
          </div>
        ) : (
          <ScrollArea className="h-96">
            <div
              className={
                viewMode === 'grid'
                  ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'
                  : 'grid grid-cols-1 sm:grid-cols-2 gap-4'
              }
            >
              {courses.map((course) => (
                <CourseCard key={course.id} course={course.course}  status={course.status} viewMode={viewMode} />
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </div>
  );
};

export default CoursesList;

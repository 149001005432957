import { useTranslation } from "react-i18next";

const UnusualIncidentReport = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className="text-xl font-bold uppercase">
          {t('residents.forms.incidentReport.title')}
        </h2>
      </div>

      <div className="p-4 bg-white dark:bg-zinc-700 rounded-md shadow-md mt-2">
        <h4 className="text-base font-semibold text-zinc-900 dark:text-zinc-100 mb-2 uppercase">
          {t('residents.forms.instructions.name')}
        </h4>
        <ul className="list-disc list-inside space-y-2 text-sm text-zinc-700 dark:text-zinc-200">
          <li> {t('residents.forms.incidentReport.instructions.instructions1')} </li>
          <li> {t('residents.forms.incidentReport.instructions.instructions2')} </li>
          <li> {t('residents.forms.incidentReport.instructions.instructions3')} </li>
        </ul>
      </div>
    </div>
  );
};

export default UnusualIncidentReport;

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const planOfCareTypes = [
  { value: 'custom_admission', label: 'Custom Admission' },
  { value: 'hospitalization', label: 'Hospitalization' },
  { value: 'change_of_condition', label: 'Change of Condition' },
  { value: 'annual_evaluation', label: 'Annual Evaluation' }
];

interface Props {
  className?: string;
  defaultValues: Partial<FormValues>;
}

const formSchema = z.object({
  plan_of_care_type: z
    .string({
      required_error: 'Type is required'
    })
    .min(1, {
      message: 'Type is required'
    })
});

type FormValues = z.infer<typeof formSchema>;

export const PlanOfCareTypeForm = forwardRef(({ className, defaultValues }: Props, ref) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues,
    values: { ...defaultValues } as FormValues
  });

  const { formState: { errors } } = form;

  useEffect(() => {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey) {
      const errorField = document.querySelector(`[name="${firstErrorKey}"]`);
      errorField?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      return new Promise((resolve) => {
        form.handleSubmit((data) => {
          resolve(data);
        })();
      });
    }
  }));

  return (
    <>
      <div className={cn(className, 'bg-primary/5 p-3 rounded-lg shadow-sm')}>
        <Form {...form}>
          <form>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4 px-1">
              <FormField
                name="plan_of_care_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Type Plan of Care <span className="text-primary">*</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value || undefined} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger className="focus:ring-primary " name="plan_of_care_type">
                          <SelectValue placeholder="Select a type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {planOfCareTypes.map((type) => (
                          <SelectItem key={type.value} value={type.value}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
    </>
  );
});

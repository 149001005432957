import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { ICalendarTableItem } from '@/modules/calendar/domain/calendar.domain';
import { ReactNode } from 'react';

interface TaskEventTableProps {
  taskEvents: ICalendarTableItem[];
  columnsCalendarResume: any[];
  loadMore: () => void;
  hasMore: boolean;
  handleSort: (columnKey: string, direction: string) => void;
  isLoading: boolean;
  filters: ReactNode;
  generateReport: () => Promise<string | boolean>;
}

const ResumeTable: React.FC<TaskEventTableProps> = ({
  taskEvents,
  columnsCalendarResume,
  loadMore,
  hasMore,
  handleSort,
  isLoading,
  filters,
  generateReport
}) => {
  return (
    <TableCustomVirtuoso
      showSearchInput={false}
      filters={filters}
      data={taskEvents}
      loadMore={loadMore}
      hasMore={hasMore}
      columns={columnsCalendarResume}
      renderCellContent={(index: number, column: any) =>
        column.render ? column.render(taskEvents[index]) : taskEvents[index][column.key]
      }
      additionalButtons={<></>}
      onSort={handleSort}
      isLoading={isLoading}
      generateReport={generateReport}
    />
  );
};

export default ResumeTable;

import { useTranslation } from "react-i18next";

const ResidentialCareText = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-zinc-50 border border-zinc-300 p-4 rounded-lg overflow-y-auto max-h-96 text-justify dark:bg-zinc-800">
      <div className=" text-sm leading-6 text-zinc-700 space-y-2 dark:text-white">
        <p>
         {t("residents.forms.residentPersonalProperty.section152.header")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section152.paragraph1")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section152.paragraph2")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section152.paragraph3")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.header")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.description")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphA")} <br />
          {t("residents.forms.residentPersonalProperty.section153.paragraphB")} <br />
          {t("residents.forms.residentPersonalProperty.section153.paragraphC")} <br />
        </p>
        <ol>
          <li>  {t("residents.forms.residentPersonalProperty.section153.paragraphList1")} </li>
          <li>  {t("residents.forms.residentPersonalProperty.section153.paragraphList2")} </li>
          <li>  {t("residents.forms.residentPersonalProperty.section153.paragraphList3")} </li>
          <li>  {t("residents.forms.residentPersonalProperty.section153.paragraphList4")} </li>
          <li>  {t("residents.forms.residentPersonalProperty.section153.paragraphList5")} </li>
        </ol>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphD")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphD_1")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphD_2")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphD_3")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphD_4")} {' '}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphE")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphF")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphF_1")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphG")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphH")}
        </p>
        <p>
         {t("residents.forms.residentPersonalProperty.section153.paragraphI")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphI_1")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphJ")} {' '}
          {t("residents.forms.residentPersonalProperty.section153.paragraphJ_1")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphK")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphL")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section153.paragraphM")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section154.header")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section154.paragraph1")}
        </p>
        <p>
          {t("residents.forms.residentPersonalProperty.section154.paragraph2")}
        </p>
      </div>
    </div>
  );
};

export default ResidentialCareText;

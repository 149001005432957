import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { DiagnosisCard } from '@/modules/residents/presentation/components/ProfileInformation/Diagnosis/DiagnosisCard';
import useRouteParams from '@/common/hooks/RouteParamsHook';
// import { FoodNutrition } from '@/modules/residents/presentation/components/ProfileInformation/FoodNutrition';
import { ResponsiblePartyContact } from '@/modules/residents/presentation/components/ProfileInformation/ResponsiblePartyContact';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { useResidentData } from '../../infrastructure/hooks/profileInformation/useResidentData';
import { useFetchResidentAlerts } from '../../infrastructure/hooks/profileInformation/useFetchResidentAlerts';
import ResidentAlerts from '../components/Alerts/ResidentAlerts';
import QuickLinks from '../components/ProfileInformation/QuickLinks/QuickLinks';
import { useFetchContacts } from '../../infrastructure/hooks/profileInformation/useFetchContacts';
import { PrimaryPhysicianContact } from '../components/ProfileInformation/PrimaryPhysicianContact';
import { ResidentNavigationMenu } from '../components/ProfileInformation/ResidentNavigationMenu';
import { ResidentInformation } from '../components/ProfileInformation/ResidentInformation';
import { AlertsCard } from '../components/ProfileInformation/AlertsCard';

export const DashboardProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { residentId, filteredDiagnoses, firstAllergy, mentalCondition } = useResidentData();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState<string>('');

  useFetchResidentAlerts(tabSelected);
  useFetchContacts();

 useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="flex justify-center pb-8">
        <div className="w-full flex flex-col">
          {(() => {
            switch (tabSelected) {
              case 'alerts':
                return (
                  <>
                    <BackNavigationBar
                      title={t('residents.alerts')}
                      path={`/residents/profile?r=${residentId}`}
                      onBack={() => setTabSelected('')}
                    />
                    <ResidentAlerts residentId={residentId ?? ''} resident={resident} />
                  </>
                )
              default:
                return (
                  <>
                    <BackNavigationBar
                      title={t('residents.profile.residentInformation')}
                      path={'/residents'}
                    />
                    <div className="grid grid-cols-1 gap-4 px-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-12 lg:gap-6">
                      <div className="col-span-1 md:col-span-2 lg:col-span-9 flex flex-col">
                        <ResidentInformation />
                      </div>
                      <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col h-full">
                        <QuickLinks
                          className="col-span-full xl:col-span-4 h-full flex flex-col"
                          residentId={residentId}
                          tabSelected={tabSelected}
                          setTabSelected={setTabSelected}
                        />
                      </div>
                    </div>
                    <div className="col-span-full px-4">
                      <div className="pt-4">
                        <ResidentNavigationMenu/>
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 px-4">
                      <div className="pt-4">
                        <ResponsiblePartyContact />
                      </div>
                      <div className="pt-4">
                        <PrimaryPhysicianContact />
                      </div>
                      <div className="pt-4 flex flex-col h-full">
                        <AlertsCard
                         residentId={residentId}
                        />
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 px-4">
                      <div className="pt-4 lg:col-span-1">
                        <DiagnosisCard
                          diagnoses={filteredDiagnoses}
                          allergy={firstAllergy}
                          mentalCondition={mentalCondition}
                        />
                      </div>
                      <div className="pt-4 lg:col-span-2">
                        {/* Pending: Integrate data with the Other RequiredInformation module. */}
                        {/* <FoodNutrition /> */}
                      </div>
                    </div>
                  </>
                );
            }
          })()}
        </div>
      </div>
    </>
  );
};

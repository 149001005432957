import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

export const GetPicklist = async (locationId: string) => {
  const response = await axios.get('/residents/get-picklist', {
    params: { locationId },
  });
  return response;
};

const ResidentService = {
  createResident: async (data: any) => {
    const response = await axios.post('/residents', { ...data });
    return response;
  },
  getResidents: async (locationId?: string) => {
    const response = await axios.get(`/residents${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  },
  getPublicResidents: async (token: string, locationId?: string) => {
    const response = await axios.post(`/publicResident`, {
      location_id: locationId ?? "all-locations",
      token
    });

    return response;
  },
  getResidentById: async (id: string) => {
    let response;

    response = await axios.get(`/residents/${id}`);

    return response;
  },
  getPublicResidentById: async (id: string, isPublic: boolean, token: string) => {
    let response;

    response = await axios.post(`/publicResident/getById`, { id, isPublic, token });

    return response;
  },
  updateDocument: async (documentId: string, data: any) => {
    const response = await axios.put(`/residents/documents/${documentId}`, { ...data });
    return response;
  },

  updatePersonalInformation: async (data: any) => {
    const response = await axios.put(`/residents/personal-information/${data.resident_id}`, { ...data });
    return response;
  },

  updateReligiousPreferences: async (data: any) => {
    const response = await axios.put(`/residents/religious-preferences/${data.resident_id}`, { ...data });
    return response;
  },

  updateResidenceLevelOfCare: async (data: any) => {
    const response = await axios.put(`/residents/levelofcare/${data.resident_id}`, { ...data });
    return response;
  },

  updateAdministrativeInformation: async (data: any) => {
    const response = await axios.put(`/residents/administrative-information/${data.resident_id}`, { ...data });
    return response;
  },

  updateCustomFieldsForm: async (data: any) => {
    const response = await axios.put(`/residents/custom-fields/${data.resident_id}`, { ...data });
    return response;
  },

  updateResidentNotes: async (data: any) => {
    const response = await axios.put(`/residents/notes-residents/${data.resident_id}`, { ...data });
    return response;
  },

  updateHardCopyStatus: async (documentId: string, hard_copy: boolean) => {
    const response = await axios.put(`documents/hard-copy-status/${documentId}`, { hard_copy });
    return response;
  },

  uploadProfileImage: async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);

    const response = await axios.post(`/residents/profile-image/${data.resident_id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  },

  deleteProfileImage: async (residentId: string) => {
    const response = await axios.delete(`/residents/profile-image/${residentId}`);
    return response;
  },

  getResidentsConfigurations: async () => {
    const response = await axios.get('/residents/configurations');
    return response;
  },

  postResidentsConfigurations: async (data: any) => {
    const response = await axios.post('/residents/configurations', { ...data });
    return response;
  },

  saveResidentDocument: async (data: any) => {
    const formData = new FormData();
    formData.append('data', data.data);
    data.files.forEach((file: File, index: number) => {
      if (file instanceof File) {
        formData.append(`file_${index}`, file);
      } else {
        console.error(`file_${index} it is not an object file`, file);
      }
    });

    const response = await axios.post(`/residents/documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  },

  deleteResidentDocument: async (documentId: string) => {
    try {
      const response = await axios.delete(`/residents/documents/${documentId}`);
      return response;
    } catch (error) {
      console.error('Error deleting document:');
      throw error;
    }
  },

  updateResidentStatus: async (residentId: string, status: string) => {
    const response = await axios.post(`/residents/change-status/${residentId}`, { status });
    return response;
  },

  getInactiveResidents: async (locationId?: string) => {
    const response = await axios.get(`/residents/inactive${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  },

  updateDischargeResident: async (data: any) => {
    const response = await axios.put(`/residents/discharge-residents/${data.resident_id}`, { ...data });
    return response;
  },

  updateRiskAssessment: async (data: {resident_id: string, elopement_risk: string, fall_risk: string, pressure_sore_risk: string}) => {
    const response = await axios.put(`/residents/risk-assessment/${data.resident_id}`, { ...data });
    return response;
  },

  updateSleepingHours: async (data: {resident_id: string, asleep: Date, awake: Date}) => {
    const response = await axios.put(`/residents/sleeping-hours/${data.resident_id}`, { ...data });
    return response;
  },

  saveSpecialInformation: async (data: { resident_id: string; special_information: string }) => {
    const response = await axios.post(`/residents/saveSpecialInformation`, data);
    return response;
  },
};

export default ResidentService;

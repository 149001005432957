import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CertificateAddingCreateI, TrainingSliceStateI } from '../../domain/training.domain';
import TrainingService from '../../infraestructure/services/TrainingService';

const initialState: TrainingSliceStateI = {
  coursesState: {
    status: 'loading',
    data: []
  }
};

export const fetchEnrolledCourses = createAsyncThunk('training/GetEnrolledCourses', async () => {
  try {
    const response = await TrainingService.getEnrolledCourses();
    return response;
  } catch (error) {
    throw error;
  }
});

export const goToTraining = createAsyncThunk('training/GoToTraining', async (course: string) => {
  try {
    const response = await TrainingService.goToCourse(course);
    return response;
  }catch (error){
    throw error;
  }
});

export const fetchTrainingCategories = createAsyncThunk('training/GetTrainingCategories', async () => {
  try {
    const response = await TrainingService.getCategoryTrainingOnSite();
    return response;
  } catch (error) {
    throw error;
  }
});

export const createMultipleCertificate = createAsyncThunk('training/CreateMultipleCertificate', async (data: CertificateAddingCreateI) => {
  try {
    const response = await TrainingService.createMultipleTrainingOnSite(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const TrainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEnrolledCourses.pending, (state) => {
      state.coursesState.status = 'loading';
      state.coursesState.data = [];
    });
    builder.addCase(fetchEnrolledCourses.fulfilled, (state, action) => {
      state.coursesState.status = 'idle';
      state.coursesState.data = action.payload.data;
    });
  }
});

export const {} = TrainingSlice.actions;
export default TrainingSlice.reducer;

import { NotebookTabs } from 'lucide-react';
import { AlertCard } from '../alert-card';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from '../skeleton';
import { useTranslation } from 'react-i18next';
import { AlertsResponseI, CustomAlertI, HiddenAlertsI } from '@/modules/dashboard/domain/dashboard.domain';
import moment from 'moment';
import AlertItemCard from '@/common/presentation/components/AlertItemCrad.tsx/AlertItemCard';
import ButtonMarkAsDone from '../ButonMarkAsDone/ButtonMarkAsDone';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { UserHelper } from '@/utils/helpers/user.helper';

interface Props {}

type IAlert = (CustomAlertI | AlertsResponseI) & { type: 'default' | 'custom' | 'license' | 'resident_reports' };

export const AdministrationAlerts: React.FC<Props> = () => {
  const userCustomAlerts = useSelector((state: RootState) => state.dashboard.userCustomAlerts);
  const userLicenseCustomAlerts = useSelector((state: RootState) => state.dashboard.userLicenseCustomAlerts);
  const residentReportsCustomAlerts = useSelector((state: RootState) => state.dashboard.residentReportsCustomAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);

  const users = useSelector((state: RootState) => state.staff.allStaff);
  const [hiddenAlerts, setHiddenAlerts] = useState<HiddenAlertsI>({});
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  
  const alerts: IAlert[] = useMemo(() => {
    if (userCustomAlerts.status === 'loading') {
      return [];
    }

    const currentDate = moment().format('YYYY-MM-DD');
    const customAlerts: IAlert[] = userCustomAlerts.data.map((alert) => ({
      ...alert,
      type: 'custom',
      date: currentDate,
      users: alert.users.filter((user) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));

    const customLicenseAlerts: IAlert[] = userLicenseCustomAlerts.data.map((alert) => ({
      ...alert,
      type: 'license',
      date: alert.start_date,
      users: alert.users.filter((user) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));

    const customResidentReportAlerts: IAlert[] = residentReportsCustomAlerts.data.map((alert) => ({
      ...alert,
      type: 'resident_reports',
      date: alert.start_date,
      users: alert.users.filter((user) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));

    return [...customAlerts, ...customLicenseAlerts, ...customResidentReportAlerts].sort((a, b) =>
      moment(a.date).isBefore(b.date) ? -1 : 1
    );
  }, [userCustomAlerts, userLicenseCustomAlerts, hiddenAlerts]);
  

  const totalAlertsCount = useMemo(() => {
    return alerts.reduce((total, alert) => {
      if (alert.type === 'default') {
        return total + 1;
      }
      const customAlert = alert as CustomAlertI;
      return total + (customAlert.users?.length || 0) + (customAlert.residents?.length || 0);
    }, 0);
  }, [alerts]);
  return (
    <>
      <AlertCard
        title={t('dashboard.administrationAlerts')}
        alerts={Array.from({ length: totalAlertsCount })}
        icon={NotebookTabs}
        custom={true}
      >
        {userCustomAlerts.status === 'loading' || users.status == 'loading' ? (
          <AlertsSkeleton />
        ) : (!alerts || alerts.length === 0) ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {alerts.map((alert: IAlert, index) => {
              const customAlert = alert as CustomAlertI & {
                type: 'default' | 'custom' | 'license' | 'resident_reports';
              };
              return (
                <div key={customAlert.id} className="flex flex-col gap-3">
                  {['resident_reports', 'license'].includes(customAlert.type)
                    ? customAlert.users.length > 0
                      ? customAlert.users.map((user) => (
                          <AlertItemCard
                            key={`${index}_${user.id}`}
                            names={`${user.first_name} ${user.last_name}`}
                            title={t(`alerts.${customAlert.title}`, {
                              date: formatDate(customAlert?.date ?? '', false, false)
                            })}
                            date={customAlert.date}
                            image={user?.profile_url ?? ''}
                          />
                        ))
                      : customAlert.residents.map((resident) => (
                          <AlertItemCard
                            key={`${index}_${resident.id}`}
                            names={`${resident.first_name} ${resident.last_name}`}
                            title={t(`alerts.${customAlert.title}`, {
                              date: formatDate(customAlert?.date ?? '', false, false, false)
                            })}
                            date={customAlert.date}
                            image={UserHelper.getResidentInformation(resident.id, residents)?.image_url ?? ''}
                          />
                        ))
                    : customAlert.users.map((user) => (
                        <AlertItemCard
                          key={`${index}_${user.id}`}
                          names={`${user.first_name} ${user.last_name}`}
                          title={customAlert.title}
                          date={customAlert.date}
                          image={user?.profile_url ?? ''}
                          Button={
                            <ButtonMarkAsDone
                              alertId={customAlert.id}
                              relatedId={user.id}
                              hiddenAlerts={hiddenAlerts}
                              setHiddenAlerts={setHiddenAlerts}
                            />
                          }
                        />
                      ))}
                </div>
              );
            })}
          </div>
        )}
      </AlertCard>
    </>
  );
};

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { FormFieldResponse } from '../../domain/models/FormField';

const PlanOfCareService = {
  getPlanOfCareHistory: async (residentId: string) => {
    const response = await axios.get(`/residents/forms/${residentId}/plan_of_care`);
    return response;
  },

  savePlanOfCare: async (data: any, pdf: boolean) => {
    const response = await axios.post('/residents/forms/pdf', data);
    return response;
  },

  // deletePlanOfCare: async (id: string) => {
  //   const response = await axios.delete(`/residents/forms/${id}`);
  //   return response;
  // },

  deleteForm: async (id: string) => {
    const response = await axios.delete(`/residents/forms/${id}`);
    return response;
  },

  deleteDocument: async (document_id: string) => {
    const response = await axios.delete(`/residents/documents/${document_id}`);
    return response;
  },

  generateHistoryPdf: async (id: string) => {
    const response = await axios.get(`/residents/forms/generate-history-pdf/${id}`, { responseType: 'blob' });
    return response;
  },

  addNewFieldForm: async (data: any) => {
    const response = await axios.post(`/residents/forms/${data.form_id}/add-field`, data);
    return response;
  },

  getFormFields: async (formId: string) => {
    const response = await axios.get<FormFieldResponse>(`/residents/forms/fields/${formId}`);
    return response;
  }
};

export default PlanOfCareService;

export const filterEmptyRows = (rows: Array<any>) => {
  return rows.filter(row =>
    Object.values(row).some(value => value !== '' && value !== null && value !== undefined)
  );
};

export const formatNumberWithPattern = (value: number | string): string => {
  const str = value.toString();
  return `${str.slice(0, 3)}-${str.slice(3, 5)}-${str.slice(5)}`;
};

export const calculateAge = (dob?: string): string => {
  if (!dob) return "";
  const dateOfBirth = new Date(dob);
  if (isNaN(dateOfBirth.getTime())) return "";
  const diff = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(diff);
  return String(Math.abs(ageDate.getUTCFullYear() - 1970));
};


export const preparePayload = ({
  formData,
  residentId,
  documentKey,
  defaultResourceParamValues,
}: {
  formData: any;
  residentId: string;
  documentKey: string;
  defaultResourceParamValues: any;
}) => ({
  documentKey,
  residentId,
  formData,
  defaultResourceParamValues,
});
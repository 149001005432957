type permissionskeyType = 'edit' | 'delete' | 'create' | 'duplicate'; //etc.. add missings
type permissionType = 'restriction' | 'permission';

interface IPermission {
    permission_id: string;
    permission_type: string;
    module: string;
    permission_key: permissionskeyType;
}
interface IPermissionByRol {
    role: string;
    role_id: string;
    permissions: IPermission[];
}
interface PermissionsData {
    byRol: IPermissionByRol[];
    byUser: IPermission[];
}

export const UserRolPermissions = (
    module: string, // Aplication module, ej: medication, residents, staff. etc...
    permissionKey: permissionskeyType[], // Permission name, ej: edit, delete
    permissions: PermissionsData
): boolean => {
    const permission: permissionType = 'permission';

    // Check permissions per user first
    if ('byUser' in permissions && permissions.byUser.length > 0) {

        let matches: IPermission[] = [];

        for (const permissKey of permissionKey) {
            const keyMatches: any = permissions.byUser.filter((up: IPermission) =>
                up.module === module && up.permission_key === permissKey
            );
            matches.push(...keyMatches);
        }

        if (matches.length > 0) {

            const matchesPermissionKey = matches.map(p => p.permission_key);
            const match = permissionKey.every((pk) => matchesPermissionKey.includes(pk));

            if (match) {
                return matches.every((p: IPermission) => p.permission_type === permission);
            }
        }
    }

    // After by rol
    if ('byRol' in permissions && permissions.byRol.length > 0) {
        let matches: IPermission[] = [];
        for (const permissKey of permissionKey) {
            for (const role of permissions.byRol) {
                if (role.permissions && role.permissions.length > 0) {
                    const keyMatches: any = role.permissions.filter((rP: IPermission) =>
                        rP.module === module && rP.permission_key === permissKey
                    )

                    matches.push(...keyMatches);
                }
            }
        }

        if (matches.length > 0) {
            const groupPermission = groupPermissionsByKey(matches);
            
            const matchesPermissionKey = Object.values(groupPermission).map((permissions: IPermission[]) =>
                (permissions.find((p) => p.permission_type == permission))?.permission_key
            );

            const match = permissionKey.every((pk) => matchesPermissionKey.includes(pk));
            return match;
        }

    }


    return false;
};

const groupPermissionsByKey = (permissions: IPermission[]): Record<string, IPermission[]> => {
    return permissions.reduce((acc: Record<string, IPermission[]>, item: IPermission) => {
        if (!acc[item.permission_key]) {
            acc[item.permission_key] = [];
        }
        acc[item.permission_key].push(item);
        return acc;
    }, {});
};
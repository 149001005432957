import { AllContactsI } from "../models/Contacts";

/**
 * Filters contacts by active category and returns the required fields.
 * @param contacts - List of contacts
 * @param categoryName - Name of the category to filter by
 * @returns List of contacts with an active category and their last phone number
 */

export function filterContactsByCategory(
  contacts: AllContactsI[],
  categoryName: string
): {
  contactId: string;
  name: string;
  email?: string;
  relationship?: string | null;
  lastPhone?: string;
  address?: string;
  zipCode?: string;
  state?: string;
  city?: string;
  homePhone?: string | null;
  workPhone?: string | null;
} | null {
  // Filter contacts by category name and active status
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.category.name === categoryName &&
      contact.category.status === "active"
  );

  // If no contacts match, return null
  if (filteredContacts.length === 0) {
    return null;
  }

  // Find the most recent contact
  const latestContact = filteredContacts.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

   // Consolidate all phones from filtered contacts
   const allPhones = filteredContacts.flatMap((contact) =>
    contact.phones.filter((phone) => phone.delete === 0) // Include only non-deleted phones
  );

   // Get the most recent phone overall
   const lastPhone = allPhones
   .sort(
     (a, b) =>
       new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
   )[0]?.number;

 // Get the most recent `home` phone
 const homePhone = allPhones
   .filter((phone) => phone.category === "home")
   .sort(
     (a, b) =>
       new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
   )[0]?.number || null;

 // Get the most recent `work` phone
 const workPhone = allPhones
   .filter((phone) => phone.category === "work")
   .sort(
     (a, b) =>
       new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
   )[0]?.number || null;

  // Return the relevant fields
  return {
    contactId: latestContact.id,
    name: latestContact.name,
    email: latestContact.email,
    relationship: latestContact.relationship,
    lastPhone: lastPhone || "No phone available",
    address: latestContact.address || "No address available",
    zipCode: latestContact.zipcode || "No zipCode available",
    state: latestContact.state || "No state available",
    city: latestContact.city || "No city available",
    homePhone,
    workPhone
  };
}

import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { CrudState, FormData as FormDataModel, NoteCategoryI, QuickNoteI } from '@/modules/notes/domain/note.domain';
import { Loader, Save } from 'lucide-react';
import { NoteTranslator } from '../common/noteTranslator';
import { QuillJS } from '../common/quilljs';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Select as ShadSelect, SelectContent, SelectTrigger, SelectValue, SelectItem } from '@/common/presentation/components/ui/select';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { SelectNoteTo } from './elements/SelectNoteTo';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { UrgentNoteCheckbox } from './elements/urgentNoteCeheckbox';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';
import React, { useCallback, useEffect, useState } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { InputsMedicalAppoinments } from './elements/inputsMedicalAppoinments';
import { format } from 'date-fns';

interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
}

export interface MedicalAppoinment {
  doctor: string;
  specialty: string;
  date: string;
}

export function CreateNote({ crudState, setCrudState }: CreateNoteProps) {
  // Redux
  const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);
  const drafts = useSelector((state: RootState) => state.notes.drafts.data);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { locationSelectedId } = useLocation();

  const [isPharmacySelected, setIsPharmacySelected] = useState<boolean>(false);

  // Tools
  const { params } = useRouteParams();

  // Init Values
  const { t } = useTranslation();
  const defaultFormData: FormDataModel = {
    content: '',
    html_content: '',
    status: 'active',
    user_id: [],
    category_id: '',
    resident_id: null,
    staff_id: [],
    location_id: [],
    department_id: [],
    reference: 'notePage',
    images: null,
    client_id: clientId,
    draft_id: null,
    urgent: false,
    specialty: null,
    doctor: null,
    medical_appoinment_date: null,
  };

  // Hooks
  const [isLoading, setIsLoading] = useState(false);
  const [draftContent, setDraftContent] = useState('');
  const [formData, setFormData] = useState<FormDataModel>(defaultFormData);
  const [dataMedicalAppoinment, setDataMedicalAppoinmentormData] = useState<MedicalAppoinment>({ date: '', doctor: '', specialty: '' });
  const [quickNotesT, setQuickNotesT] = useState<QuickNoteI[]>([]);
  const [isMedicalAppoinment, setIsMedicalAppoinment] = useState<boolean>(false);

  useEffect(() => {
    if (crudState.isFinishingDraft) {
      let draft = drafts.filter((item: any) => item.id == crudState.itemId);
      if (draft.length > 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['draft_id']: draft[0].id
        }));

        setDraftContent(draft[0].content);
      }
    }
  }, [crudState]);

  // Behaviors
  const onResidentSelect = (resident: any) => {
    setFormData({
      ...formData,
      ['resident_id']: resident
    });
  };

  const handleSelectNoteTo = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    const staffItems = recipients.filter((item) => item.endsWith('-staff')).map((item) => item.replace('-staff', ''));

    const departmentsItems = recipients
      .filter((item) => item.endsWith('-department'))
      .map((item) => item.replace('-department', ''));

    const locationItems = recipients
      .filter((item) => item.endsWith('-location') && item !== 'all-houses-location')
      .map((item) => item.replace('-location', ''));

    setFormData({
      ...formData,
      ['user_id']: staffItems,
      ['location_id']: locationItems,
      ['department_id']: departmentsItems
    });
  };

  const handleSelectCategory = (category: NoteCategoryI) => {
    setQuickNotesT(category.note_category_quick_note);

    let isMedicalAppoinment = category.category === 'Medical Appointments';

    setFormData({
      ...formData,
      category_id: category.id,
      doctor: isMedicalAppoinment ? formData.doctor : null,
      specialty: isMedicalAppoinment ? formData.specialty : null,
      medical_appoinment_date: isMedicalAppoinment ? formData.medical_appoinment_date : null,
    });

    setIsMedicalAppoinment(isMedicalAppoinment);
  };

  const handleSelectQuicknote = (value: any) => {
    let quinckNoteSelected = quickNotesT.find(note => note.id == value);
    if (quinckNoteSelected) {
      let content = quinckNoteSelected.content
        .split(/\r\n|\n/g)
        .map(paragraph => `<p>${paragraph}</p>`)
        .join('')
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
      setDraftContent(content);
    }
  };

  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));

    if (values.content.length > 0 && values.content != '\n') {
      if (crudState.isFinishingDraft) {
        setCrudState(NoteHelperService.defineState({ ...crudState, action: updateDraft }));
        return;
      }
      setCrudState(NoteHelperService.defineState({ ...crudState, action: saveDraft }));
      return;
    }
    setCrudState(NoteHelperService.defineState({ ...crudState, action: () => { } }));
  };

  const handleUrgentNoteCheckbox = (value: boolean) => {
    setFormData({
      ...formData,
      ['urgent']: value
    });
  };

  const saveDraft = async (state: CrudState) => {
    if (state.isCreating) {
      return;
    }

    await NoteService.createDraft(formData);

    toast({
      title: 'Draft saved',
      description: ``
    });
  };

  const updateDraft = async (state: CrudState) => {
    if (state.isFinishingDraft) {
      return;
    }

    await NoteService.updateDraft(formData);

    toast({
      title: 'Draft updated',
      description: ``
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.content || formData.content.trim().length < 2) {
      setFormData({
        ...formData,
        ['content']: "<br/>"
      })
    }

    setCrudState(NoteHelperService.defineState({ ...crudState, action: () => { } }));

    setIsLoading(true);
  };

  const useNotificationSound = () => {
    const audio = new Audio(`/sounds/message_sent.mp3`);

    const playSound = useCallback(() => {
      audio.currentTime = 0;
      audio.play().catch(error => console.log('Error reproduciendo sonido:', error));
    }, []);

    return playSound;
  };

  const playSound = useNotificationSound();

  useEffect(() => {
    if (isLoading) {
      const performSecondStateUpdate = async () => {

        let hasNoteTo = formData.location_id.length || formData.department_id.length || formData.user_id.length;

        if (!hasNoteTo && locationSelectedId !== 'all-locations') {
          toast({
            description: t('notes.noteToNotFound'),
          });
          setIsLoading(false);
          return
        }

        if (!formData.category_id) {
          toast({
            description: t('notes.categoryNotFound'),
          });
          setIsLoading(false);
          return

        }

        if ((!formData.content || formData.content.trim().length < 2) && !formData.images?.length && !isMedicalAppoinment) {
          toast({
            description: t('notes.contentNoteLength'),
          });
          setIsLoading(false);
          return;
        }

        if (isMedicalAppoinment) {
          let findResident = residents.find(resident => resident.id === formData.resident_id);

          const htmlString = `
          <p>
            Medical Appointment: ${findResident ? `${findResident.first_name} ${findResident.last_name}` : ''} <br/>
            Date: ${dataMedicalAppoinment?.date}<br/>
            ${t('notes.doctorNote')}: ${dataMedicalAppoinment?.doctor}<br/>
            ${t('notes.specialtyNote')}: ${dataMedicalAppoinment?.specialty}<br/>
            Comments: ${formData.content}
          </p>`;
          formData.html_content = htmlString;

          const tempElement = document.createElement('div');
          tempElement.innerHTML = htmlString;
          const plainText = tempElement.textContent || tempElement.innerText;
          formData.content = plainText;
        }

        let images = formData.images;
        try {
          formData.images = [];
          formData.location_selected = locationSelectedId;
          let response = await NoteService.createNote(formData);

          if (images?.length) {
            let form = new FormData();
            form.append('id', response.payload.id);
            form.append('type', 'note');
            images.forEach(img => form.append('images[]', img))
            await NoteService.uploadImges(form);
          }

          playSound();
          setCrudState(NoteHelperService.defineState({}));
          setIsLoading(false);
        } catch (error) {
          toast({
            description: t('trackers.trackerFall.error'),
            variant: 'destructive'
          });
          setIsLoading(false);
        }

      };

      performSecondStateUpdate();
    }
  }, [isLoading]);

  const closeForm = () => {
    setCrudState(NoteHelperService.defineState({}));
  }

  return (
    <Card className="col-span-12 md:col-span-7 border-t-4 border-t-primary/80">
      <CardContent>
        <ResidentSelector
          className="mt-3"
          locationId={params?.l}
          onSelect={onResidentSelect}
          setUrlParams={false}
          showInCard={false}
          closeButton={closeForm}
          title='residentSelector.regarding'
        />
        <ScrollArea className='h-[calc(100vh-450px)]'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-12 gap-4 py-4'>
              <div className='col-span-12 md:col-span-4'>
                <SelectNoteTo
                  handleSelectNoteTo={handleSelectNoteTo}
                  setIsPharmacySelected={setIsPharmacySelected}
                  isPharmacySelected={isPharmacySelected}
                  residentSelectedId={formData.resident_id}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <SelectNoteCategory
                  decodeHTMLEntities={NoteHelperService.decodeHTMLEntities}
                  handleSelectCategory={handleSelectCategory}
                  isPharmacySelected={isPharmacySelected}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <ShadSelect name="quickNote" onValueChange={handleSelectQuicknote}>
                  <SelectTrigger name="quickNote">
                    <SelectValue placeholder={t('notes.quickNote')} />
                  </SelectTrigger>
                  <SelectContent>
                    {quickNotesT?.map((item: any) => (
                      <SelectItem key={item.id} value={item.id}>
                        {NoteHelperService.decodeHTMLEntities(item.title)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </ShadSelect>
              </div>

              {isMedicalAppoinment && <InputsMedicalAppoinments setFormData={setFormData} formData={formData} setDataMedicalAppoinmentormData={setDataMedicalAppoinmentormData} dataMedicalAppoinment={dataMedicalAppoinment} />}
            </div>
            <Separator className='bg-primary mb-4' />
            <div className="w-full">
              <QuillJS onChange={handleQuillJs} value={draftContent} crudState={crudState} translator={true} />
            </div>
            <div className="pt-4 flex justify-between">
              <UrgentNoteCheckbox onCheck={handleUrgentNoteCheckbox} />
              <Button type="submit" disabled={isLoading} size="sm" className="ml-auto">
                {isLoading ? (
                  <Loader className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {t('notes.send')}
                  </>
                )}
              </Button>
            </div>
          </form>
          <div className="flex ml-auto">
            <NoteTranslator text={formData.content} setTranslation={setDraftContent} className="hidden" />
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}

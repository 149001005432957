import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '@/store/store';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useFetchStaffAlerts } from '../../infrastructure/hooks/profileInformation/useFetchStaffAlerts';
import { QuickLinks } from '../components/dashboard/QuickLinks';
import { ContactCard } from '../components/profile/ContactCard';
import { StaffInformation } from '../components/profile/StaffInformation';
import { EmployerInfo } from '../components/profile/EmployerInfo';
import { TrainingInfo } from '../components/profile/TrainingInfo';
import { StaffAlertsOverview } from '../components/profile/Alert/StaffAlertsOverview';
import StaffAlertsPage from './StaffAlertsPage';
import StaffTrainingPage from '@/modules/staff/presentation/pages/StaffTrainingPage.tsx';


const rolesToValidate = ['Super Administrator', 'Executive'];

export const DashboardProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);

  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const [tabSelected, setTabSelected] = useState<string>('dashboard');

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  useFetchStaffAlerts(tabSelected);

  useEffect(() => {
    if (params.s) {
      dispatch(staffMemberFormActions.getStaffMemberById(params.s));
    }
  }, [params.s]);

  useEffect(() => {
    if (params?.tab && params.tab == 'alerts') {
      setTabSelected('alerts');
      return;
    }

    if (params?.tab && params.tab == 'training') {
      setTabSelected('training');
      return;
    }

    setTabSelected('dashboard');
  }, [params])

  useEffect(() => {
    if (!user || !params.s) return;

    if (!isAdmin && params.s !== user.id) {
      toast({
        description: 'You are not allowed to view this page',
        variant: 'destructive'
      });
      navigate('/');
    }
  }, [user, params.s, isAdmin, navigate]);

  if (status === 'loading' && !staffMember) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="flex justify-center pb-8">
        <div className="w-full flex flex-col">
          {(() => {
            switch (tabSelected) {
              case 'alerts':
                return (
                  <>
                    <BackNavigationBar
                      title={t('residents.alerts')}
                      path={`/staff/profile?s=${params.s}`}
                      onBack={() => setTabSelected('')}
                    />
                    <StaffAlertsPage staffId={params.s} />
                  </>
                );
              case 'training':
                return (
                  <>
                    <BackNavigationBar
                      title='Training'
                      path={`/staff/profile?s=${params.s}`}
                      onBack={() => setTabSelected('')}
                    />
                    <StaffTrainingPage staffId={params.s} />
                  </>
                );
              default:
                return (
                  <>
                    <BackNavigationBar title={t('staff.profile.staffInformation')} path={'/staff'} />
                    <div className="grid grid-cols-1 gap-4 px-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-12 lg:gap-6">
                      <div className="col-span-1 md:col-span-2 lg:col-span-9 flex flex-col">
                        <StaffInformation />
                      </div>
                      <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col h-full">
                        <QuickLinks
                          className="col-span-full xl:col-span-4 h-full flex flex-col"
                          setTabSelected={setTabSelected}
                        />
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-2 lg:gap-4 px-4">
                      <div className="pt-4">
                        <ContactCard />
                      </div>
                      <div className="pt-4 flex flex-col h-full">
                        <StaffAlertsOverview />
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-12 grid grid-cols-1 lg:grid-cols-2 lg:gap-4 px-4">
                      <div className="pt-4">
                        <EmployerInfo />
                      </div>
                      <div className="pt-4 hidden">
                        <TrainingInfo />
                      </div>
                    </div>
                  </>
                );
            }
          })()}
        </div>
      </div>
    </>
  );
};

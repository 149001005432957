import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
// import { Input } from '@/components/ui/input';
import { Input } from '@/common/presentation/components/ui/input';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent.tsx';

const formSchema = z.object({
  date: z.date(),
  description: z.string(),
  hours: z.string(),
});

export type FormValues = z.infer<typeof formSchema>;

interface Props {
  status: string;
  onSubmit: (data: FormValues) => void;
  defaultValues?: Partial<FormValues>;
}

export const UploadCertificateShawdowingForm = ({ status, onSubmit, defaultValues }: Props) => {
  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
    mode: 'onChange'
  });

  const handleSubmit = (data: FormValues) => {
    onSubmit({ ...data });
    form.reset();
  };

  return (
    <>
      <ScrollArea className="h-full w-full m-0 p-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full">
                  <FormLabel>Date</FormLabel>
                  <FormControl >
                    <CalendarComponent
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hours"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Hours</FormLabel>
                  <FormControl>
                    <Input {...field} type="number" disabled={status === 'loading'} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="col-span-full w-full"
              disabled={!form.formState.isValid || status === 'loading'}
            >
              {status === 'loading' && <Loader className="size-4 animate-spin mr-2" />}
              Save Document
            </Button>
          </form>
        </Form>
      </ScrollArea>
    </>
  );
};

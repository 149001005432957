import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { timeUtils } from '@/modules/emar/domain/timeUtils';
import { PrnTableActions } from './PrnTableActions';

const resultLabels: Record<string, string> = {
  helpful: 'Helpful',
  somehow_helpful: 'Somehow Helpful',
  not_helpful: 'Not Helpful'
};

export const usePrnColumns = (): ColumnDef<any>[] => {
  const { t } = useTranslation();
  const displayFormat = t('dateFormats.dateTimeFormat');

  return [
    {
      header: t('emar-prn.column-prn.date'),
      accessorKey: 'dispensed_at',
      cell: ({ row }) => {
        return timeUtils.formatDateUTC(row.original.dispensed_at, displayFormat);
      }
    },
    {
      header: t('emar-prn.column-prn.reason'),
      accessorKey: 'reason'
    },
    {
      header: t('emar-prn.column-prn.result'),
      accessorKey: 'result',
      cell: ({ row }) => resultLabels[row.original.result] || row.original.result
    },
    {
      header: t('emar-prn.column-prn.staff'),
      accessorKey: 'created_by',
      cell: ({ row }) => {
        return `${row.original.created_by.first_name} ${row.original.created_by.last_name}`;
      }
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <PrnTableActions data={row.original} />;
      }
    }
  ];
};

import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Form, FormDescription, FormMessage } from '@/common/presentation/components/ui/form';
import { Button } from '@/components/ui/button';
import { FormField, FormItem, FormLabel } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { z } from 'zod';
import { AlertCreateFormI, AlertUpdateFormI } from '@/modules/alerts/domain/alerts.domain';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { createAlert, fetchAlertList, updateAlert } from '@/modules/alerts/presentation/slices/AlertSlice';
import { CustomAlertI } from '@/modules/dashboard/domain/dashboard.domain';
import { useTranslation } from 'react-i18next';
import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent';

const FormSchema = z.object({
  first_aid_due_date: z
    .date({
      required_error: 'Must be a valid date'
    })
    .optional(),
  cpr_due_date: z
    .date({
      required_error: 'Must be a valid date'
    })
    .optional()
});

type AlertType = 'first_aid_due_date' | 'cpr_due_date';

interface AlertHandlerParams {
  type: AlertType;
  date: string;
  existingId?: string | null;
}

interface IProps {
  staffId: string;
}

const LicensesAlerts: React.FC<IProps> = ({ staffId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstAidId, setFirstAidId] = useState<string | null>(null);
  const [cprId, setCprId] = useState<string | null>(null);
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await dispatch(
        fetchAlertList({
          user_id: [staffId ?? ''],
          category: 'staff_licenses'
        })
      ).unwrap();

      response.alerts.map((alert: CustomAlertI) => {
        if (alert.title === 'first_aid_due_date') {
          form.setValue('first_aid_due_date', alert?.start_date ? new Date(alert.start_date) : undefined);
          setFirstAidId(alert.id);
        } else if (alert.title === 'cpr_due_date') {
          form.setValue('cpr_due_date', alert?.start_date ? new Date(alert.start_date) : undefined);
          setCprId(alert.id);
        }
      });
    } catch (error) {
      console.error('Failed to fetch alerts:', error);
    }
    setIsLoading(false);
  };

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    storeAlert(data);
  };

  const storeAlert = async (formData: z.infer<typeof FormSchema>) => {
    try {
      await handleAlertOperation({
        type: 'first_aid_due_date',
        date: formData.first_aid_due_date?.toISOString() ?? '',
        existingId: formData.first_aid_due_date?.toISOString() ? firstAidId : null
      });

      await handleAlertOperation({
        type: 'cpr_due_date',
        date: formData.cpr_due_date?.toISOString() ?? '',
        existingId: formData.cpr_due_date?.toISOString() ? cprId : null
      });

      toast({
        title: t('customAlerts.toast.updateSuccess.title'),
        description: t('customAlerts.toast.updateSuccess.description'),
        className: 'bg-green-500 text-white'
      });
    } catch (error) {
      console.error('Error storing alerts:', error);
    }
  };

  const handleAlertOperation = async ({ type, date, existingId }: AlertHandlerParams) => {
    try {
      setIsLoading(true);
      if (!existingId) {
        if (date.length > 0) {
          const createData = buildAlertData(type, date);
          await dispatch(createAlert(createData)).unwrap();
        }
      } else {
        const updateData = buildAlertDataForUpdate(existingId, date);
        await dispatch(updateAlert(updateData)).unwrap();
      }
    } catch (error) {
      throw new Error(`Failed to ${existingId ? 'update' : 'create'} ${type} alert: ${error}`);
    }
    setIsLoading(false);
  };

  const buildAlertData = (title: AlertType, date: string): AlertCreateFormI => ({
    user_ids: [staffId],
    title,
    description: title,
    start_date: date,
    frequency: 'once',
    end_date: '',
    days_of_week: [],
    category: 'staff_licenses',
    resident_ids: [],
    related_to: 'user',
    location_id: '',
    reminder_months: 1
  });

  const buildAlertDataForUpdate = (id: string, date: string): AlertUpdateFormI => ({
    id,
    user_ids: [staffId],
    start_date: date
  });

  return (
    <CustomCard
      title={t('alerts.license_alerts')}
      description=""
      button={
        <Button onClick={form.handleSubmit(onSubmit)} disabled={isLoading}>
          {t('alerts.save')}
        </Button>
      }
    >
      <Form {...form}>
        <div className="flex flex-col md:flex-row gap-4">
          <FormField
            control={form.control}
            name="first_aid_due_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('alerts.first_aid_due')}</FormLabel>
                <CalendarComponent value={field.value} onChange={field.onChange} isDisabled={isLoading} />
                <FormDescription className="text-xs">{t('alerts.date_when_first_aid_due')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cpr_due_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('alerts.cpr_due')}</FormLabel>
                <CalendarComponent value={field.value} onChange={field.onChange} isDisabled={isLoading} />
                <FormDescription className="text-xs">{t('alerts.date_when_cpr_due')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </Form>
    </CustomCard>
  );
};

export default LicensesAlerts;

import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { AppDispatch, RootState } from '@/store/store';
import { Eye, Pencil, Trash } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevices, updateDeviceStatus, updateLocationRestrictByDevice } from '../slices/SecuritySlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import CurrentDevice from '../components/restrictByDevice/CurrentDevice';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { DeviceI } from '../../domain/device.domain';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import UsersPreview from '@/common/presentation/components/UsersPreview/UsersPreview';
import SelectStatus from '../components/restrictByDevice/SelectStatus';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { useNavigate } from 'react-router-dom';
import { updateLocationInfo } from '@/modules/locations/presentation/slices/locationsSlice';
import { useConfirm } from '@/common/hooks/useConfirm';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import FilterTable from '../components/restrictByDevice/FilterTable';
import { format } from 'date-fns';
import Banner from '@/common/presentation/components/Banner/Banner';
import ViewDeviceDialog from '../components/restrictByDevice/ViewDeviceDialog';
import LocationsBadge from '../components/restrictByDevice/LocationsBadge';
import { Badge } from '@/common/presentation/components/ui/badge';

const SecurityPage = () => {
  const [isRestrictByDeviceEnabled, setIsRestrictByDeviceEnabled] = useState<boolean>(false); // By location
  const [isOpenViewDevice, setIsOpenViewDevice] = useState<boolean>(false);
  const [devicesData, setDevicesData] = useState<any[]>([]);
  const [deviceView, setDeviceView] = useState<DeviceI | null>(null);
  const [sort, setSort] = useState<{ column: string; direction: string }>({ column: '', direction: '' });
  const devicesState = useSelector((state: RootState) => state.security.devices) ?? [];
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const dispatch = useDispatch<AppDispatch>();
  const { locationSelectedId } = useLocation();
  const [updatingDevices, setUpdatingDevices] = useState<Set<string>>(new Set());
  const { formatDate } = useDateHelpers();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ConfirmDialog, confirm] = useConfirm(t('security.areYouSure'), t('security.YouAreDeleteTheDevice'));
  const [filters, setFilters] = useState<{ status: string; deviceType: string }>({ status: '', deviceType: '' });
  const currentDeviceRegistered = useSelector((state: RootState) => state.security.current_device.device) ?? [];

  const statusOptions = [
    { name: 'All', value: 'all' },
    { name: 'active', value: 'active' },
    { name: 'pending', value: 'pending' },
    { name: 'inactive', value: 'inactive' }
  ];
  const devicesType = [
    { name: 'All', value: 'all' },
    { name: 'computer', value: 'computer' },
    { name: 'phone', value: 'phone' },
    { name: 'table', value: 'tablet' }
  ];

  useEffect(() => {
    dispatch(getStaffByLocation(locationSelectedId != 'all-locations' ? locationSelectedId : undefined));
    getDevices();
    setIsRestrictByDeviceEnabled(getLocationRestrictByDevice() === 'active' ? true : false);
  }, [locationSelectedId]);

  const getLocationRestrictByDevice = () => {
    if (locationSelectedId != 'all-locations') {
      return locations.find((location) => location.id === locationSelectedId).restrict_by_device ?? null;
    }
    return null;
  };

  useEffect(() => {
    if (devicesState.status === 'loading') {
      return;
    }
    formatDeviceData(devicesState.list);
  }, [devicesState]);

  const formatDeviceData = (deviceList: DeviceI[]) => {
    const formatedData = deviceList.map((device) => ({
      id: device.id,
      device_name: capitalizeWords(device.name),
      ip_registered: device.registered_ip,
      type: device.type.toLowerCase(),
      operative_system: capitalizeWords(device.operative_system),
      all_users: device.all_users,
      browser: capitalizeWords(device.browser),
      status: device.status,
      created_at: device.created_at,
      users: device.users,
      device: device
    }));

    setDevicesData(formatedData);
  };

  const capitalizeWords = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const getDevices = async (
    statusFilter: string | null = null,
    typeFilter: string | null = null,
    name: string | null = null
  ) => {
    try {
      await dispatch(
        fetchDevices({
          locationId: locationSelectedId,
          status: statusFilter,
          type: typeFilter,
          name: name
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (newStatus: string, deviceId: string) => {
    try {
      setUpdatingDevices((prev) => new Set(prev).add(deviceId));

      setDevicesData((prevData) =>
        prevData.map((device) => (device.id === deviceId ? { ...device, status: newStatus } : device))
      );

      await dispatch(
        updateDeviceStatus({
          deviceId: deviceId,
          newStatus: newStatus
        })
      );

      setUpdatingDevices((prev) => {
        const newSet = new Set(prev);
        newSet.delete(deviceId);
        return newSet;
      });

      setDevicesData((prevData) =>
        prevData.map((device) => (device.id === deviceId ? { ...device, status: newStatus } : device))
      );
    } catch (error) {
      console.log(error);
      setUpdatingDevices((prev) => {
        const newSet = new Set(prev);
        newSet.delete(deviceId);
        return newSet;
      });
    }
  };

  const columnsDeviceTable = [
    {
      key: 'device_name',
      id: 'device_name',
      labelTranslationKey: 'security.deviceName',
      render: (row: any) => {
        return <div className="w-48 flex flex-raw items-center gap-2">
          {
            currentDeviceRegistered && 'id' in currentDeviceRegistered && row.id === currentDeviceRegistered.id && (
              <div className="bg-primary h-2 w-2 rounded-full"></div>
            )
          }
          {row.device_name}
        </div>;
      },
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'ip_registered',
      id: 'ip',
      labelTranslationKey: 'security.ip',
      value: 'ip_registered',
      visible: true,
      sortable: false
    },
    {
      key: 'type',
      id: 'type',
      labelTranslationKey: 'security.deviceType',
      render: (row: any) => t(`security.${row.type}`),
      classes: '',
      visible: true,
      sortable: true
    },
    {
      key: 'operative_system',
      id: 'operative_system',
      labelTranslationKey: 'security.operatingSystem',
      value: 'operative_system',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'browser',
      id: 'browser',
      labelTranslationKey: 'security.browser',
      value: 'browser',
      visible: true,
      sortable: true
    },
    {
      key: 'locations',
      id: 'locations',
      labelTranslationKey: 'security.facilities',
      render: (row: any) => {
        return <div className="w-48">{row.device && <LocationsBadge device={row.device} />}</div>;
      },
      visible: true,
      sortable: false
    },
    {
      key: 'users',
      id: 'users',
      labelTranslationKey: 'security.users',
      render: (row: any) => {
        const limit = 3;
        return (
          <div className="w-48 flex justify-start">
            {row.all_users ? t('security.allUserSelectedLocations') : <UsersPreview users={row.users} limit={limit} />}
          </div>
        );
      },
      visible: false,
      sortable: false
    },
    {
      key: 'created_at',
      id: 'created_at',
      labelTranslationKey: 'security.dateCreated',
      render: (row: any) => (
        <p>
          {formatDate(row.created_at, false)} <br /> {format(new Date(row.created_at), 'hh:mm a')}{' '}
        </p>
      ),
      sortable: true
    },
    {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'security.status',
      render: (row: any) => (
        <SelectStatus
          isLoading={updatingDevices.has(row.id)}
          currentValue={row.status}
          item={row}
          handleStatusChange={handleStatusChange}
        />
      ),
      sortable: false
    },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: 'security.actions',
      static: true,
      render: (row: any) => renderActionsColumn(row)
    }
  ];

  const renderActionsColumn = (row: any) => (
    <div className="flex gap-2 justify-center">
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          viewDeviceInfo(row.id);
        }}
      >
        <Eye className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          navigate(`edit/${row.id}`);
        }}
      >
        <Pencil className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        className="text-red-500"
        onClick={() => {
          handleDelete(row.id);
        }}
      >
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );

  const viewDeviceInfo = (deviceId: string) => {
    setIsOpenViewDevice(true);
    const device = devicesState.list.find((device) => device.id === deviceId) ?? null;
    setDeviceView(device);
  };

  const restrictByDeviceStatusHandler = () => {
    try {
      setIsRestrictByDeviceEnabled(!isRestrictByDeviceEnabled);
      // Update location info in the storage
      dispatch(
        updateLocationInfo({
          locationId: locationSelectedId,
          key: 'restrict_by_device',
          value: !isRestrictByDeviceEnabled ? 'active' : 'inactive'
        })
      );
      dispatch(
        updateLocationRestrictByDevice({
          location_id: locationSelectedId,
          status: !isRestrictByDeviceEnabled
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (deviceId: string) => {
    const ok = await confirm();

    if (ok) {
      try {
        setUpdatingDevices((prev) => new Set(prev).add(deviceId));

        setDevicesData((prevData) => prevData.filter((device) => device.id !== deviceId));

        await dispatch(
          updateDeviceStatus({
            deviceId: deviceId,
            newStatus: 'removed'
          })
        );

        toast({
          title: t('security.deviceDeletedSuccess'),
          className: 'bg-green-500 text-white'
        });

        setDevicesData((prevData) => prevData.filter((device) => device.id !== deviceId));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchFilteredData = useCallback(
    (newFilters: { status: string; deviceType: string }) => {
      getDevices(newFilters.status, newFilters.deviceType);
    },
    [dispatch]
  );

  const fetchSearchByName = (name: string) => {
    getDevices(filters.status, filters.deviceType, name);
  };

  const tableSorting = (columnKey: string, direction: string) => {
    setSort({ column: columnKey, direction });
    const sortedData = [...devicesData].sort((a: any, b: any) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setDevicesData(sortedData);
  };

  return (
    <>
      <ConfirmDialog />
      <LocationSelector quickView={true} />
      <CustomCard title={t('security.security')} description="">
        <div className="mt-0 px-2 md:px-4 lg:px-0 flex flex-col gap-3">
          {locationSelectedId == 'all-locations' && <Banner labelMessage="security.toEnableRestrictByDevice" labelTitle="doYouKnow" messageType="info" />}
          <CurrentDevice
            getDevices={() => {
              getDevices();
            }}
          />
          {locationSelectedId != 'all-locations' && (
            <div className="w-full grid grid-cols-1 md:grid-cols-1 gap-4 border rounded">
              <div className="flex flex-row items-center justify-between rounded-lg p-3">
                <div className="space-y-0.5">
                  <Label className="text-base text-primary font-semibold">{t('security.restrictByDevice')}</Label>
                  <p className="text-sm">{t('security.enablingRestricyByDevice')}</p>
                </div>
                <Switch
                  checked={isRestrictByDeviceEnabled}
                  disabled={devicesState.status === 'loading'}
                  onCheckedChange={restrictByDeviceStatusHandler}
                />
              </div>
            </div>
          )}
          <div>
            <TableCustomVirtuoso
              showSearchInput={true}
              tableId='1'
              columns={columnsDeviceTable}
              data={devicesData}
              isLoading={devicesState.status === 'loading'}
              renderCellContent={(index: number, column: any) =>
                column.render ? column.render(devicesData[index]) : devicesData[index][column.key]
              }
              additionalButtons={<></>}
              onSort={tableSorting}
              loadMore={() => {}}
              hasMore={false}
              onFilterChange={fetchSearchByName}
              filters={
                <FilterTable
                  statusOptions={statusOptions}
                  devicesTypes={devicesType}
                  filters={filters}
                  setFilters={setFilters}
                  fetchFilteredData={fetchFilteredData}
                />
              }
            />
          </div>
        </div>
      </CustomCard>
      {
        deviceView && <ViewDeviceDialog isOpen={isOpenViewDevice} setIsOpen={setIsOpenViewDevice} device={deviceView} />
      }
    </>
  );
};

export default SecurityPage;

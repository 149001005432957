
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getClient, updateClient, getClientByGivenId } from '@/modules/client/presentation/slices/ClientSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Card } from '@/common/presentation/components/ui/card';
import { FacilityInformation } from './FacilityInformation';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { CircleFadingPlus } from 'lucide-react';
import { DialogModalGoToMarket } from './DialogModalGoToMarket';
import { LocationI } from '@/modules/locations/infrastructure/interfaces/LocationInterface';
import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Switch } from "@/common/presentation/components/ui/switch";
import LocationService from '@/modules/locations/infrastructure/service/LocationService';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { useNavigate } from 'react-router-dom';


const ACCEPTED_FILE_TYPES = {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': [],
    'image/webp': []
};


export const getFormSchema = () => {
    const { t } = useTranslation();
    return z.object({
        name: z
            .string({
                required_error: t('facilities.companyNameRequired'),
            })
            .min(3, {
                message: t('facilities.companyNameMin'),
            }),
        tax_id: z.string().optional(),
        timezone: z
            .string({
                required_error: t('facilities.standardTimeZoneRequired'),
            })
            .min(3, {
                message: t('facilities.standardTimeZoneMin'),
            }),
        logo: z
            .array(
                z
                    .instanceof(File)
                    .refine(
                        (file) => ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(file.type),
                        { message: t("facilities.logoFileInvalid") }
                    )
            )
            .optional()
            .refine((files) => files?.length === 0 || files?.every((file) => file instanceof File), {
                message: t("facilities.logoFileInvalid"),
            }),


        point: z.string().optional(),
        phone: z.string().optional(),
        email: z.union([z.string().email(), z.literal('')]),
        restrictDeviceAccess: z.boolean().optional(),
        address: z.string().optional(),
        city: z.string().optional(),
        state: z
            .string({
                required_error: t('facilities.stateRequired'),
            })
            .min(2, {
                message: t('facilities.stateMin'),
            }),
        zip: z
            .string({
                required_error: t('facilities.zipRequired'),
            })
            .regex(/^\d+$/, {
                message: t('facilities.zipInvalid'),
            })
            .min(2, {
                message: t('facilities.zipMin'),
            }),
    });
};

type FormValues = z.infer<ReturnType<typeof getFormSchema>>;

type ClientData = {
    id: string;
    name: string;
    tax_id: string;
    configurations: {
        timezone: string;
        logo?: string | File;
    };
    state: string;
    zip: string;
    point?: string;
    phone?: string;
    email?: string;
    restrictDeviceAccess?: boolean;
    address?: string;
    city?: string;
    locations?: LocationI[];
};

export const CompanyInfoForm = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();
    const { hasAnyRole } = useRoleHelper();
    const formSchema = getFormSchema();
    const params = new URLSearchParams(location.search)
    const clientId = params.get('clientId');
    const [clientData, setCompanyInfo] = useState<ClientData | null>(null)
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const locations = useSelector((state: RootState) => state.locations.allLocations.locations);
    const [openModalMarket, setOpenModalMarket] = useState<boolean>(false);
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const navigate = useNavigate();

    const isAdmin = hasAnyRole(['Super Administrator']);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),

    });

    const timezones = [
        {
            label: t('facilities.pst'), // "Pacific Standard Time (PST)"
            value: "America/Los_Angeles"
        },
        {
            label: t('facilities.est'), // "Eastern Standard Time (EST)"
            value: "America/New_York"
        },
        {
            label: t('facilities.cst'), // "Central Standard Time (CST)"
            value: "America/Chicago"
        },
        {
            label: t('facilities.mst'), // "Mountain Standard Time (MST)"
            value: "America/Denver"
        },
        {
            label: t('facilities.ast'), // "Atlantic Standard Time (AST)"
            value: "America/Halifax"
        },
        {
            label: t('facilities.cot'), // "Colombia Time (COT)"
            value: "America/Bogota"
        }
    ]

    const [preview, setPreview] = useState<string | null>(null);

    const handleLogoChange = (files: File[]) => {
        setValue('logo', files);

        if (files.length > 0) {
            const file = files[0];
            setPreview(URL.createObjectURL(file));
        } else {
            setPreview(null);
        }
    };

    const dataClient = async () => {
        let response;
        if (!clientId) {
            response = await dispatch(getClient());
            if (response.payload) {

                setCompanyInfo(response.payload[0]);
            }
        } else {
            response = await dispatch(getClientByGivenId(clientId));
            if (response.payload) {
                setCompanyInfo(response.payload);
            }
        }
    }

    const onSubmit = async (data: FormValues) => {
        setIsSubmit(true);
        try {
            if (clientData) {

                const dataWithId = { ...data, client_id: clientData.id };
                const response = await dispatch(updateClient(dataWithId))
                if (response.payload) {
                    toast({
                        description: t('facilities.dataSaving'),
                        className: 'bg-green-500 text-white'
                    });
                } else {
                    toast({
                        description: t('facilities.errorSaving'),
                        variant: 'destructive'
                    });
                }
            }

        } catch (error) {
            console.log(error);
            toast({
                description: t('facilities.errorSaving'),
                variant: 'destructive'
            });
        }
        setIsSubmit(false);
    }

    useEffect(() => {
        dataClient();
    }, []);

    useEffect(() => {
        if (!isAdmin && clientId) {
            navigate('/dashboard');
        }
    }, []);


    useEffect(() => {
        if (clientData?.configurations?.logo) {
            if (typeof clientData.configurations.logo === 'string') {
                setPreview(clientData.configurations.logo);
            } else if (clientData.configurations.logo instanceof File) {
                setPreview(URL.createObjectURL(clientData.configurations.logo));
            }
        }

        if (clientData) {
            const formData: FormValues = {
                name: clientData.name || '',
                tax_id: clientData.tax_id || '',
                timezone: clientData.configurations?.timezone || '',
                logo: Array.isArray(clientData.configurations?.logo)
                    ? clientData.configurations.logo
                    : [],

                point: clientData.point || '',
                phone: clientData.phone || '',
                email: clientData.email || '',
                address: clientData.address || '',
                city: clientData.city || '',
                state: clientData.state || '',
                zip: clientData?.zip || '',
            };
            reset(formData);
        }
    }, [clientData]);

    const onClose = () => {
        setOpenModalMarket(false)
    }

    const handleGoMarket = () => {
        window.open('/market-place', '_blank');
    };


    return (
        <div className=" p-5 mt-4">
            <div className="flex justify-between">
                <div className="font-semibold text-2xl text-primary">{t('facilities.updateCompanyInfo')}</div>
                <Button onClick={handleGoMarket}>
                    <CircleFadingPlus className="h-4 w-4 flex-1 mr-2" />
                    {t('facilities.newFacility')}
                </Button>

                <DialogModalGoToMarket
                    openModalMarket={openModalMarket}
                    onClose={onClose}
                />
            </div>

            <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        <div>
                            <Label htmlFor="companyName">{t('facilities.companyName')} <span className="text-destructive">*</span></Label>
                            <Input id="name" {...register('name')} />
                            {errors.name?.message && (
                                <span className="text-red-500">{errors.name.message}</span>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="tax_id">{t('facilities.taxId')} #</Label>
                            <Input id="tax_id" {...register('tax_id')} />
                        </div>

                        <div>
                            <Label htmlFor="timeZone">{t('facilities.standardTimeZone')} <span className="text-destructive">*</span></Label>
                            <Select
                                value={watch('timezone')}
                                onValueChange={(value) => setValue('timezone', value)}
                            >
                                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                    <SelectValue>
                                        {watch('timezone')
                                            ? timezones.find((time) => time.value === watch('timezone'))?.label
                                            : 'Select a timezone'}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {timezones.map((time) => (
                                        <SelectItem key={time.value} value={time.value}>
                                            {time.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            {errors.timezone?.message && (
                                <span className="text-red-500">{errors.timezone?.message}</span>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="contactName">{t('facilities.companyPointOfContact')}</Label>
                            <Input id="point" {...register('point')} />
                        </div>

                        <div>
                            <Label htmlFor="contactPhone">{t('facilities.companyContactPhoneNumber')}</Label>
                            <Input id="phone" {...register('phone')} />
                        </div>

                        <div>
                            <Label htmlFor="contactEmail">{t('facilities.companyContactEmail')}</Label>
                            <Input id="email" {...register('email')} />
                            {errors.email?.message && (
                                <span className="text-red-500">{errors.email?.message}</span>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="address">{t('facilities.companyAddress')}</Label>
                            <Input id="address" {...register('address')} />
                        </div>

                        <div>
                            <Label htmlFor="city">{t('facilities.city')}</Label>
                            <Input id="city" {...register('city')} />
                        </div>

                        <div>
                            <Label htmlFor="state">{t('facilities.state')} <span className="text-destructive">*</span></Label>
                            <Input id="state" {...register('state')} />
                            {errors.state?.message && (
                                <span className="text-red-500">{errors.state.message}</span>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="zip">{t('facilities.zip')} <span className="text-destructive">*</span></Label>
                            <Input id="zip" {...register('zip')} />
                            {errors.zip?.message && (
                                <span className="text-red-500">{errors.zip.message}</span>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="logo"> Logo </Label>
                            <MultiFileDropzone
                                className="w-auto outline-none min-h-[40px] min-w-[200px]"
                                value={Array.isArray(watch("logo")) ? watch("logo") : []}
                                onChange={handleLogoChange}
                                dropzoneOptions={{
                                    accept: ACCEPTED_FILE_TYPES,
                                    maxFiles: 1,
                                }}
                            />
                            {errors.logo?.message && (
                                <span className="text-red-500">{errors.logo.message}</span>
                            )}
                        </div>
                    </div>

                    {preview && (
                        <div className='mt-4'>
                            <Label htmlFor="logo"> {t('facilities.companyLogo')} </Label>
                            <img src={preview} alt="Logo Preview" style={{ width: 100, height: 100 }} />
                        </div>
                    )}

                    <div className="flex justify-end mt-5">
                        <Button type="submit" className="w-[346px]" disabled={isSubmit}>
                            {t('medications.update')}
                        </Button>
                    </div>
                </form>
            </Card>

            <div className="mt-6">
                <Separator className="bg-primary mb-4" />
            </div>

            <div className="mt-6">
                {(clientId && clientData?.locations?.length) ? (
                    clientData.locations.map((location: LocationI, index: number) => {
                        if (location.status === 'inactive') {
                            return null;
                        }

                        const handleStatusChange = async () => {
                            try {
                                setIsDisable(true);
                                const data = {
                                    locationName: location.name,
                                    clientName: clientData?.name,
                                    contactName: clientData?.point,
                                    clientPhoneNumber: clientData?.phone,
                                }
                                const response = await LocationService.sendEmail(data)
                                if (response) {
                                    setOpenModalMarket(true);
                                }
                            } catch (error) {
                                console.error('Error en la petición:', error);
                            }
                            setIsDisable(false);
                        };

                        return (
                            <div className="mt-3" key={location.id}>
                                <CustomAccordion
                                    titleComponent={
                                        <div className="flex justify-between items-center w-full">
                                            <span className="text-xl text-primary font-semibold">
                                                {index + 1}. {location.name}
                                            </span>

                                            <div className="flex justify-between items-center">
                                                <span className="text-zinc-600 text-base font-semibold font-['Montserrat'] leading-normal hidden md:block">
                                                    {t('facilities.activeStatus')}
                                                </span>
                                                <Switch
                                                    className="ml-2"
                                                    id="status"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleStatusChange();
                                                    }}
                                                    checked={location.status === 'active'}
                                                    disabled={isDisable}
                                                />

                                                <div className="w-[2px] bg-primary h-6 mx-4 hidden md:block"></div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <FacilityInformation location={location} />
                                </CustomAccordion>
                            </div>
                        );
                    })
                ) : (
                    !clientId && locations.length > 0 ? (
                        locations
                            .filter(location => location.status === 'active')
                            .map((location: LocationI, index: number) => {

                                const handleStatusChange = async () => {
                                    try {
                                        setIsDisable(true);
                                        const data = {
                                            locationName: location.name,
                                            clientName: clientData?.name,
                                            contactName: clientData?.point,
                                            clientPhoneNumber: clientData?.phone,
                                        }
                                        const response = await LocationService.sendEmail(data)
                                        if (response) {
                                            setOpenModalMarket(true);
                                        }
                                    } catch (error) {
                                        console.error('Error en la petición:', error);
                                    }
                                    setIsDisable(false);
                                };

                                return (
                                    <div className="mt-3" key={location.id}>
                                        <CustomAccordion
                                            titleComponent={
                                                <div className="flex justify-between items-center w-full">
                                                    <span className="text-xl text-primary font-semibold">
                                                        {index + 1}. {location.name}
                                                    </span>

                                                    <div className="flex justify-between items-center">
                                                        <span className="text-zinc-600 text-base font-semibold font-['Montserrat'] leading-normal hidden md:block">
                                                            {t('facilities.activeStatus')}
                                                        </span>
                                                        <Switch
                                                            className="ml-2"
                                                            id="status"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleStatusChange();
                                                            }}
                                                            checked={location.status === 'active'}
                                                            disabled={isDisable}
                                                        />

                                                        <div className="w-[2px] bg-primary h-6 mx-4 hidden md:block"></div>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <FacilityInformation location={location} />
                                        </CustomAccordion>
                                    </div>
                                );
                            })
                    ) : (
                        <p>No locations available.</p>
                    )
                )}
            </div>

        </div>
    );
}
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import React, { createContext, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook.ts';
import { useLocation as useLocationId } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useDate } from '@/common/infrastructure/providers/DateEmarRoutineContext';

import { toast } from '@/components/ui/use-toast';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

interface PendingAction {
  id: string;
  type: string;
  medication: any;
  action: () => Promise<void>;
  retries?: number;
  lastAttempt?: number;
  priority?: number;
}

interface PendingActionsContextType {
  pendingActions: PendingAction[];
  addPendingAction: (action: PendingAction) => void;
  syncPendingActions: () => Promise<void>;
  isSyncing: boolean;
  errorLog: string[];
}

const PendingActionsContext = createContext<PendingActionsContextType | undefined>(undefined);

export const PendingActionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [pendingActions, setPendingActions] = useState<PendingAction[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [initialInterval, setInitalInterval] = useState(false);
  const [errorLog, setErrorLog] = useState<string[]>([]);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const [isExecuted, setIsExecuted] = useState(false); 
  const { locationSelectedId } = useLocationId();
  const { params } = useRouteParams();
  const { getMedicationsTimesByResident } = useMedicationStore();
  const { date } = useDate();

  const location = useLocation();
  
  const getStoredActions = useCallback((): PendingAction[] => {
    try {
      const storedActions = localStorage.getItem('pendingActions');
      return storedActions ? JSON.parse(storedActions) : [];
    } catch (error) {
      console.error('Error loading actions from localStorage:', error);
      return [];
    }
  }, []);

  const setStoredActions = useCallback((actions: PendingAction[]) => {
    try {
      if (actions.length === 0) {
        localStorage.removeItem('pendingActions');
      } else {
        localStorage.setItem('pendingActions', JSON.stringify(actions));
      }
    } catch (error) {
      console.error('Error storing actions in localStorage:', error);
    }
  }, []);

  const logError = useCallback((message: string) => {
    setErrorLog((prev) => [...prev.slice(-10), message]);
  }, []);

  const calculateBackoff = (retries: number): number => {
    return Math.min(1000 * Math.pow(2, retries), 30000);
  };

  const isAlreadyProcessedError = (error: any): boolean => {
    const errorMessage = error?.response?.data?.message || error?.message || '';
    return errorMessage === 'This medication is already witnessed' || errorMessage === 'Medication already dispensed';
  };

  const syncPendingActions = useCallback(async () => {
    if (isSyncing || pendingActions.length === 0) return;

    setIsSyncing(true);
    const now = Date.now();

    try {
      const batchSize = 10;
      const batches = Array.from({ length: Math.ceil(pendingActions.length / batchSize) }, (_, i) =>
        pendingActions.slice(i * batchSize, i * batchSize + batchSize)
      );

      for (const batch of batches) {
        const updatedBatch = [];

        for (const action of batch) {
          if (action.lastAttempt && now - action.lastAttempt < calculateBackoff(action.retries || 0)) {
            updatedBatch.push(action);
            continue;
          }

          try {
            await action.action();
            logError(`Action ${action.type} completed successfully for medication ${action.medication.id}`);
          } catch (error) {
            if (isAlreadyProcessedError(error)) {
              // Si la medicina ya está procesada, solo loggeamos y la eliminamos
              logError(`Skipping action ${action.type} - ${error.response?.data?.message || error.message}`);
              continue;
            }

            const retries = (action.retries || 0) + 1;
            if (retries <= 5) {
              const failedAction = {
                ...action,
                retries,
                lastAttempt: now
              };
              updatedBatch.push(failedAction);
              logError(`Action ${action.type} failed for medication ${action.medication.id}. Retry ${retries}/5`);
            } else {
              logError(`Action ${action.type} failed for medication ${action.medication.id} after maximum retries`);
            }
          }
        }

        setPendingActions((prev) => {
          const remainingActions = prev.filter((a) => !batch.includes(a));
          const newActions = [...remainingActions, ...updatedBatch];
          setStoredActions(newActions);
          return newActions;
        });
      }
    } catch (error) {
      logError(`Sync process failed: ${error.message}`);
    } finally {
      setIsSyncing(false);
    }
  }, [isSyncing, pendingActions, logError, setStoredActions]);

  const addPendingAction = useCallback(
    (action: PendingAction) => {
      const actionWithId = {
        ...action,
        id: action.id || `${action.type}_${action.medication.id}_${Date.now()}`,
        priority: action.priority || 0,
        retries: 0
      };

      const alreadyExists = pendingActions.some(
        (existingAction) => existingAction.medication.id === action.medication.id && existingAction.type === action.type
      );

      if (!alreadyExists) {
        const newActions = [...pendingActions, actionWithId];
        setPendingActions(newActions);
        setStoredActions(newActions);
        logError(`New action ${action.type} added for medication ${action.medication.id}`);
      } else {
        logError(`Action ${action.type} already exists for medication ${action.medication.id}`);
      }
    },
    [pendingActions, setStoredActions, logError]
  );

  useEffect(() => {
    setPendingActions(getStoredActions());
  }, [getStoredActions]);


  useEffect(() => {
    const fetchMedicationsAndUpdateState = async () => {
      try {
        if (!date) {
          return;
        }
        const executedFetch = localStorage.getItem('executedFetch') === 'true';
        if (pendingActions.length === 0 && !executedFetch) {
          
          const formattedDate = format(date, 'yyyy-MM-dd');
          const residentId = params.r || 'all-residents';
  
          await getMedicationsTimesByResident(
            locationSelectedId,
            residentId,
            formattedDate
          );
  
          setIsExecuted(true);
          localStorage.setItem('executedFetch', 'true');
        }
        else if (pendingActions.length === 0 && executedFetch) {
          localStorage.setItem('executedFetch', 'false');
          setIsExecuted(false);
        }
      } catch (error) {
        toast({
          description: 'Error fetching medications',
          variant: 'destructive',
        });
      }
    };
    if(location?.pathname?.includes('emar')){
      fetchMedicationsAndUpdateState();
    }
  }, [intervalId.current, pendingActions]);
 



  useEffect(() => {

    if (pendingActions.length === 0) {
      if (intervalId.current) { 
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      return;
    }

    if (!intervalId.current) {
      intervalId.current = setInterval(() => {
        syncPendingActions();
      }, 500);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;

      }
    };
  }, [pendingActions, syncPendingActions]);

  const contextValue = {
    pendingActions,
    addPendingAction,
    syncPendingActions,
    isSyncing,
    errorLog
  };


  return <PendingActionsContext.Provider value={contextValue}>{children}</PendingActionsContext.Provider>;
};

export const usePendingActions = (): PendingActionsContextType => {
  const context = useContext(PendingActionsContext);
  if (!context) {
    throw new Error('usePendingActions must be used within a PendingActionsProvider');
  }
  return context;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Contact, User, Mail, MapPin, Phone } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';
import { ContactI, PhoneContactsI } from '@/modules/residents/domain/models/interfaces/Residents.domain';

interface ResponsiblePartyContactProps {
  className?: string;
}

export const ResponsiblePartyContact = ({ className }: ResponsiblePartyContactProps) => {
  const { t } = useTranslation();
  const categoryContacts = useSelector((state: RootState) => state.residents.resident.contacts?.contacts);

  const responsiblePerson = React.useMemo(() => {
    if (!categoryContacts) return null;

    const contactList: ContactI[] | undefined = categoryContacts["responsible_person"];
    if (!contactList || contactList.length === 0) return null;

    const contact = contactList[0];

    const latestPhone = contact?.phones
    ? [...contact.phones]
        .sort((a: PhoneContactsI, b: PhoneContactsI) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )[0]
    : null;

    return {
      ...contact,
      phones: latestPhone ? [latestPhone] : [],
    };
  }, [categoryContacts]);

  const contentClass = "flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900 h-8 px-5 py-2"
  const textClass = "text-xs text-zinc-600 dark:text-zinc-50 font-medium"

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('residents.profile.responsiblePartyContactInformation')}
        headerIconLeft={Contact}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[130px] xl:max-h-[145px]">
          <div className="space-y-3.5">
              {responsiblePerson ? (
                <>
                  <div className={contentClass}>
                    <User className="w-6 h-6 text-primary" />
                    <p className={textClass}>
                      {responsiblePerson
                        ? `${responsiblePerson?.name || t('general.no_data_available')} ${responsiblePerson.lastName || ''}`
                        : t('general.no_data_available')}
                    </p>
                  </div>
                  <div className={contentClass}>
                    <Phone className="w-6 h-6 text-primary" />
                    {responsiblePerson.phones[0] && (
                      <p className={textClass}>
                        {responsiblePerson?.phones[0].number || t('general.no_data_available')}
                      </p>
                    )}
                  </div>
                  <div className={contentClass}>
                    <Mail className="w-6 h-6 text-primary" />
                    <p className={textClass}>
                      {responsiblePerson?.email || t('general.no_data_available')}
                    </p>
                  </div>
                  <div className={`${contentClass}  ${
                    typeof responsiblePerson?.address === 'string' && responsiblePerson?.address.length > 15
                    ? 'h-auto'
                    : 'h-8'
                  }`}>
                    <MapPin className="w-6 h-6 text-primary" />
                    <p className={textClass}>
                      {responsiblePerson?.address || t('general.no_data_available')}
                    </p>
                  </div>
                </>
              ) : (
                <div className={contentClass}>
                  <p className={textClass}>
                    {t('general.no_data_available')}
                  </p>
                </div>
              )
            }
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};

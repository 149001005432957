import { Button } from '@/common/presentation/components/ui/button';
import { useEffect, useState } from 'react';
import { getOtherInformation, saveOtherInformation } from '../slices/otherInformationSlice';
import { useDispatch, useSelector } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { OtherInformation, saveOtherInformationPayload } from '../../infrastructure/types/otherInformation';
import OtherRequiredInformationUnit from './OtherRequiredForm/OtherRequiredInformationUnit';
import { RootState } from '@/store/store';
import { t } from 'i18next';

export const OtherRquiredInformation = () => {
  const dispatch = useDispatch();
  const { params } = useRouteParams();
  const OtherInformationStore = useSelector((store: RootState) => store.otherInformationSlice);
  const [otherInformationToRequests, setOtherInformationToRequest] = useState<saveOtherInformationPayload[]>([]);
  const [OtherInformationData, setOtherInformationData] = useState<OtherInformation[]>([]);

  useEffect(() => {
    dispatch(
      getOtherInformation(
        {
          resident_id: params.r
        }
      )
    );
  }, []);

  useEffect(() => {
    if(OtherInformationStore.otherInformation.length) {
        setOtherInformationData(OtherInformationStore.otherInformation);
    }
  }, [OtherInformationStore.otherInformation]);

  const getPendingToRequest = () => {
    return otherInformationToRequests;
  }

  const setPendingToRequest = (newInformation: saveOtherInformationPayload[]) => {
    setOtherInformationToRequest(newInformation);
  }

  const submit = () => {
    otherInformationToRequests.forEach(
      (otherInformationToRequest) => {
        dispatch(
          saveOtherInformation(
            otherInformationToRequest
          )
        );
      }
    );
  }

  return (
    <>
      <div className="grid grid-cols-4 dark:bg-[#1d1d1d] mt-4 p-4 w-full gap-4">
        {
          OtherInformationData && OtherInformationData.length && OtherInformationData.map(
            (OtherInformation) => (
              <div className="col-span-1 xl:col-span-4 flex flex-col gap-4">
                <OtherRequiredInformationUnit data={OtherInformation} getPendingToRequest={getPendingToRequest} setPendingToRequest={setPendingToRequest} />
              </div>
            )
          )
        }

        <div>
          <Button type="submit" className="flex justify-center w-64" onClick={submit}>
            {t("otherRequiredInformation.saveButton")}
          </Button>
        </div>
      </div>
    </>
  );
};

import React, { useState, useRef, useEffect } from 'react';

const Tooltip = ({ content, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef();
  const tooltipRef = useRef();
  let hideTimeout = useRef(null);

  const showTooltip = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 12, // 8px de margen
        left: rect.left + rect.width / 2,
      });
    }
    setVisible(true);
    clearTimeout(hideTimeout.current); // Cancela cualquier intento de ocultar el tooltip
  };

  const hideTooltip = () => {
    hideTimeout.current = setTimeout(() => setVisible(false), 100); // Retraso de 100ms
  };

  const cancelHide = () => {
    clearTimeout(hideTimeout.current);
  };

  useEffect(() => {
    return () => clearTimeout(hideTimeout.current); // Limpia el timeout si el componente se desmonta
  }, []);

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="relative inline-block"
      >
        {children}
      </div>
      {visible && (
        <div
          ref={tooltipRef}
          onMouseEnter={cancelHide} // Evita que se oculte si el mouse está dentro del tooltip
          onMouseLeave={hideTooltip} // Oculta cuando el mouse sale del tooltip
          style={{
            position: 'fixed',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            transform: 'translate(-50%, -100%)',
            zIndex: 50,
          }}
          className="px-3 py-2 text-sm text-white font-normal bg-zinc-700 rounded-lg shadow-lg"
        >
          {content}
          <div
            style={{ transform: 'translateX(-50%)' }}
            className="absolute top-full left-1/2 border-8 border-transparent border-t-gray-900"
          ></div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
import { useLocation, useSearchParams } from 'react-router-dom';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/ui/separator';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { RootState } from '@/store/store';
import { format, isAfter, parseISO, startOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DateSelector } from './DateSelector';
import { EmarPrnSection } from './EmarPrnSection';
import { EmarRoutineSection } from './EmarRoutineSection';
import OverviewTable from './tables/OverviewTable';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTranslation } from 'react-i18next';
import { fetchReasons } from '../slices/emarRoutineSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';
import { timeUtils } from '../../domain/timeUtils';
import { useLocation as useProviderLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider.tsx';
import { useDate } from '@/common/infrastructure/providers/DateEmarRoutineContext';

export const EmarMedicationsCard = () => {
  const { params, setParam } = useRouteParams();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const l = queryParams.get('l');
  const [searchParams, setSearchParams] = useSearchParams();

  const { locationSelectedId } = useProviderLocation();
  const { onOpen } = useNewExceptionDialog();

  const effectiveLocationId = l ?? 'all-locations';
  // const [date, setDate] = useState<Date>(new Date());
  const { date, setDate } = useDate();

  const dispatch = useDispatch();
  const { getMedicationsTimesByResident, medications } = useMedicationStore();
  const { resident } = useSelector(
    (state: RootState) => state.residents.resident,
    shallowEqual
  );

  const formattedDate = useMemo(() => format(date, 'yyyy-MM-dd'), [date]);
  const residentId = params.r ?? 'all-residents';

  const routineMedications = useMemo(() => {
    return medications?.filter((m) => {
      if (residentId && residentId != 'all-residents') {
        return m.resident_id == residentId && m?.medication_type?.name === 'Routine';
      }
      return m?.medication_type?.name === 'Routine';
    }) ?? [];
  }, [medications, residentId]);


  const isDateFuture: boolean = useMemo(() => {
    return timeUtils.isDateInFuture(date);
  }, [date]);

  const residentMedicationsTimes: string[] = useMemo(() => {
    const allTimes = routineMedications.flatMap(
      medication => medication.time || []
    );
    const uniqueTimes = Array.from(
      new Set(allTimes.map(timeUtils.normalizeTime))
    );
    return uniqueTimes.filter(time => time && !time.includes(','));
  }, [medications]);


  const prnMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'PRN');
  }, [medications]);


  const accordionTitle = useMemo(() => {
    return `Medications for ${residentId === 'all-residents' ? 'All Residents' : resident?.first_name || ''}`;
  }, [residentId, resident]);


  const onOpenGeneralException = (): void => {
    onOpen(
      routineMedications,
      date,
      residentMedicationsTimes,
      locationSelectedId,
      residentId,
      true
    );
  }

  useEffect(() => {
    const fetchMedications = () => {
      getMedicationsTimesByResident(
        effectiveLocationId as string, 
        residentId, 
        formattedDate,
        '',
        true
      );
    };

    fetchMedications();
    dispatch(fetchReasons());
  }, [effectiveLocationId, residentId, formattedDate, getMedicationsTimesByResident]);


  useEffect(() => {
    const storedLocation = sessionStorage.getItem('location_selected');
    if (storedLocation && !searchParams.has('l')) {
      setSearchParams({ l: storedLocation });
    }
  }, [searchParams, setSearchParams]);

  const handleSubTabChange = (tab: string) => {
    setParam('s', tab);
  };

  const renderTabsContent = () => (
    <Tabs value={params.s} onValueChange={handleSubTabChange}>
      <TabsList>
        <TabsTrigger
          value="managerRoutine"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('emar-routine.manageRoutine')}
        </TabsTrigger>
        <TabsTrigger
          value="managerPRN"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('emar-prn.managePrn')}
        </TabsTrigger>
        <TabsTrigger
          value="exception"
          className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
        >
          {t('emar.exception')}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );


  const renderContent = () => {
    if (params.e === 'summary') {
      return (
        <>
          {renderTabsContent()}
          <OverviewTable />
        </>
      );
    }


    if (params.e === 'routine') {
      return (
        <>
          <div className='flex flex-col'>

            <div className='flex gap-4'>
              <DateSelector onSelectDate={setDate} defaultDate={date} />

              {((params.r && params.r !== 'all-residents' && !isDateFuture)
                && routineMedications.length > 0) && (
                  <Button className='bg-primary'
                    onClick={onOpenGeneralException}
                  >
                    General Exception
                  </Button>
                )}
            </div>
            <p className='mt-3'>
              Filter
              by date and time to view the medications that need to be administered.
            </p>

          </div>
          <Separator />
          <EmarRoutineSection
            medications={routineMedications}
            residentId={residentId}
            date={date}
            emar={params.e}
          />
        </>
      );
    }

    if (params.e === 'prn') {
      return <EmarPrnSection medications={prnMedications} residentId={residentId} date={date} emar={params.e} />;
    }

    return null;
  };


  return (
    <CustomAccordion title={accordionTitle} defaultOpen openOption={false}>
      <div className="flex flex-col gap-4">{renderContent()}</div>
    </CustomAccordion>
  );
};

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';

const ReportForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <FormField
            control={control}
            name="attending_physician"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.deathReportLicensee.attendingPhysician')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={140}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="mortician_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.deathReportLicensee.morticianName')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={130}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
        <div className="col-span-1 sm:col-span-3">
          <h5 className="font-semibold text-gray-800 dark:text-gray-200">
            {t('residents.forms.deathReportLicensee.reportSubmittedBy')}:
          </h5>
        </div>

        <div>
          <FormField
            control={control}
            name="submitted_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.nameAndTitle')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={130}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="submitted_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('common.date')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="date"
                    className="w-full dark:bg-zinc-800"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
        <div className="col-span-1 sm:col-span-3">
          <h5 className="font-semibold text-gray-800 dark:text-gray-200">
          {t('residents.forms.deathReportLicensee.reportReviewedBy')}:
          </h5>
        </div>

        <div>
          <FormField
            control={control}
            name="reviewed_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('residents.forms.nameAndTitle')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={130}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={control}
            name="reviewed_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">
                  {t('common.date')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="date"
                    className="w-full dark:bg-zinc-800"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ReportForm;

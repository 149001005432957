import { create } from 'zustand';
import EmarService from '@/modules/emar/infrastructure/services/EmarService';
import { format, isSameDay, isToday } from 'date-fns';

export interface IMedicationExeption {
  resident_medication_id: string,
  date_to_be_dispensed: string,
  times: string[],
  reason: string,
  details: string,
  discount_unit: boolean,
}

interface ILoadingStates {
  getMedicationsByResident: boolean;
}

type MedicationState = {
  medications: any[];
  status: 'idle' | 'loading' | 'failed';
  loadingStates: ILoadingStates;
  error: string | null;
  cardState: {
    visible: boolean;
    active: boolean;
  };
  getCardState: (
    specialDays: {
      text: string;
      type: string;
      special_day: string | string[];
    } | null,
    dateToCheck?: Date
  ) => { visible: boolean; active: boolean };

  getMedicationsTimesByResident: (
    locationId: string,
    residentId: string,
    date: string,
    medicationName?: string,
    isLoading?: boolean
  ) => any;
  dispenseMedication: (
    locationId: string,
    residentId: string,
    residentMedicationId: string,
    timeToBeDispensed: string,
    dateToBeDispensed: string
  ) => Promise<any>;
  witnessMedication: (
    locationId: string,
    residentId: string,
    emarMedicationId: string,
    timeToBeDispensed: string,
    dateToBeDispensed: string
  ) => Promise<any>;

  medicationException: (
    data: IMedicationExeption[], 
    locationId: string, 
    residentId: string,
    exceptionId: string,
    reasonId: string,
    reason: any,
    details: string,
    isGeneral?: boolean
  ) => Promise<any>;

  dispensePrnMedication: (
    timeGiven: string,
    followUp: string,
    levelOfPain: string,
    result: string,
    comments: string,
    residentId: string,
    locationId: string,
    residentMedicationId: string,
    dispenseId: string,
    reason: string,
    frequency: string,
    not_exceed: string
  ) => Promise<any>;
};

export const useMedicationStore = create<MedicationState>((set) => ({
  medications: [],
  status: 'idle',
  loadingStates: {
    getMedicationsByResident: false,
  },
  error: null,
  cardState: {
    visible: true,
    active: false
  },

  getCardState: (specialDays, dateToCheck) => {
    const date = dateToCheck ? new Date(dateToCheck) : new Date();
    const dateString = format(date, 'yyyy-MM-dd');
  // console.log('specialDays',specialDays);
  
    if (!specialDays || !specialDays.type) {
      return {
        visible: true,
        active: true
      };
    }
  
    if (specialDays.type === 'every_day') {
      return {
        visible: true,
        active: true
      };
    }
  
    if (specialDays.type === 'days_of_week' && Array.isArray(specialDays.special_day)) {
      const dayOfWeek = format(date, 'EEEE');
      const isDayAllowed = specialDays.special_day.includes(dayOfWeek);
      return {
        visible: true,
        active: isDayAllowed
      };
    }
  
    if (specialDays.type === 'alternate_days' && Array.isArray(specialDays.special_day)) {
      const isAlternateDay = specialDays.special_day.includes(dateString);
      return {
        visible: true,
        active: isAlternateDay
      };
    }
  
    if (specialDays.type === 'calendar' && Array.isArray(specialDays.special_day)) {
      const isSpecialDay = specialDays.special_day.includes(dateString);
      return {
        visible: true,
        active: isSpecialDay
      };
    }
  
    return {
      visible: true,
      active: false
    };
  },

  getMedicationsTimesByResident: async (
    locationId: string,
    residentId?: string,
    date: string | Date,
    medicationFilter: string = '',
    isLoading = false
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const rParam = urlParams.get('r');

    const effectiveResidentId = rParam || 'all-residents';

    set({ status: 'loading' });

    set({ loadingStates: {
      getMedicationsByResident: isLoading
    }});

    try {
      const response = await EmarService.getResidentMedicationsTimesByResident(
        locationId,
        effectiveResidentId,
        date,
        medicationFilter
      );

      if (!response?.medications || response.medications.length === 0) {
        set({ medications: [], status: 'idle' });
        set({
          loadingStates: {
            getMedicationsByResident: false
          }
        });

      } else {
        set({ medications: response.medications, status: 'idle' });
        set({
          loadingStates: {
            getMedicationsByResident: false
          }
        });
      }

      return response.data;
    } catch (error: any) {
      set({ medications: [], status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  dispenseMedication: async (locationId, residentId, residentMedicationId, timeToBeDispensed, dateToBeDispensed) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleDispenseMedication({
        location_id: locationId,
        resident_id: residentId,
        resident_medication_id: residentMedicationId,
        time_to_be_dispensed: timeToBeDispensed,
        date_to_be_dispensed: dateToBeDispensed
      });

      // Solo actualiza si es necesario
      set((state) => {
        const updatedMedications = [...state.medications]; // Copia de las medicaciones actuales
        const index = updatedMedications.findIndex((med) => med.id === residentMedicationId);
        if (index !== -1) {
          updatedMedications[index] = { ...updatedMedications[index], dispensed: true }; // Actualizar el medicamento
        }
        return { medications: updatedMedications, status: 'idle' };
      });

      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  witnessMedication: async (locationId, residentId, emarMedicationId, timeToBeDispensed, dateToBeDispensed) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleWitnessMedication({
        location_id: locationId,
        resident_id: residentId,
        emar_medication_id: emarMedicationId,
        time_to_be_witnessed: timeToBeDispensed,
        date_to_be_witnessed: dateToBeDispensed
      });

      // Solo actualiza si es necesario
      set((state) => {
        const updatedMedications = [...state.medications];
        const updatedMedicationIndex = updatedMedications.findIndex((med) => med.id === emarMedicationId);
        if (updatedMedicationIndex > -1) {
          updatedMedications[updatedMedicationIndex] = {
            ...updatedMedications[updatedMedicationIndex],
            witnessed: true
          };
        }
        return { medications: updatedMedications, status: 'idle' };
      });

      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  medicationException: async (
    data: IMedicationExeption[], 
    locationId: string, 
    residentId: string,
    exceptionId: string,
    reasonId: string,
    reason: any,
    details: string,
    isGeneral: boolean = false
  ) => {
    set({ status: 'loading' });
    try {
      
      const response = await EmarService.handleExceptionMedication(
        { 
          data,
          residentId,
          exceptionId,
          reasonId,
          reason,
          details,
          isGeneral
        }, 
        locationId, 
        residentId
      );
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  dispensePrnMedication: async (
    timeGiven,
    followUp,
    reason,
    levelOfPain,
    result,
    comments,
    residentId,
    locationId,
    residentMedicationId,
    dispenseId,
    frequency,
    not_exceed
  ) => {
    set({ status: 'loading' });
    try {
      const response = await EmarService.handleDispensePrnMedication({
        time_given: timeGiven,
        follow_up: followUp,
        reason,
        level_of_pain: levelOfPain,
        result,
        comments,
        resident_id: residentId,
        location_id: locationId,
        resident_medication_id: residentMedicationId,
        dispense_id: dispenseId,
        frequency: frequency,
        not_exceed: not_exceed
      });
      set({ medications: response?.medications, status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));

import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DiagnosisI } from '@/common/domain/Residents';
import { AppDispatch, RootState } from '@/store/store';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';

export const useResidentData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get('r');

  useEffect(() => {
    if (residentId) {
      dispatch(residentActions.resetResident());
      dispatch(residentActions.getResidentById(residentId));
    }
  }, [dispatch, residentId]);

  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const filteredDiagnoses = useMemo(() => {
    if (!resident?.medical_information?.diagnosis) return [];
    const order: { [key: string]: number } = { primary: 1, secondary: 2 };
    return resident.medical_information.diagnosis
        .filter((item: DiagnosisI) => ['primary', 'secondary'].includes(item.type))
        .sort((a: DiagnosisI, b: DiagnosisI) => order[a.type] - order[b.type]);
  }, [resident]);

  const firstAllergy = useMemo(
    () => resident?.medical_information?.allergies?.[0] || null,
    [resident]
  );

  const mentalCondition = useMemo(() => {
    if (!resident?.health_information) return undefined;

    const {
      mental_condition_dementia_type: dementiaType,
    } = resident.health_information;

    return { dementiaType };
  }, [resident]);

  return { residentId, status, resident, filteredDiagnoses, firstAllergy, mentalCondition };
};

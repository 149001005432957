import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Textarea } from "@/components/ui/textarea";
import { Separator } from '@/components/ui/separator';

const BedStatusForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <h4 className="font-semibold text-base mb-4">
        {t('residents.forms.residentAppraisal.bedStatus')}
      </h4>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <FormField
          control={control}
          name="bed_status"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <RadioGroup
                  value={field.value}
                  onValueChange={field.onChange}
                  className="flex flex-col space-y-2 mt-2"
                >
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="out_of_bed" />
                    <span className="text-sm">{t('residents.forms.residentAppraisal.outOfBedAllDay')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="in_bed_most" />
                    <span className="text-sm">{t('residents.forms.residentAppraisal.inBedMostOfTheTime')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <RadioGroupItem value="in_bed_part" />
                    <span className="text-sm">{t('residents.forms.residentAppraisal.inBedPartOfTheTime')}</span>
                  </label>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="bed_status_comment"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">{t('general.comment')}</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="dark:bg-zinc-800"
                  maxLength={280}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </>
  );
};

export default BedStatusForm;

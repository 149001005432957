import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';


const FaceSheetService = {
    exportReport: async ({ residentId, locationId }: { residentId: string; locationId: string; }) => {
        const url = `/facesheet/exportReport`;
        const params = {
            resident_id: residentId,
            location_id: locationId,
        };
    
        try {
            const response = await axios.get(url, { params, responseType: 'blob' });
    
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error exporting report PDF");
        }
    },
}

export default FaceSheetService;
import { ResidentI } from "@/common/domain/Residents";

export const personalRightsRCFEDefaults = (resident: ResidentI) => ({
    ombudsman_phone: '',
    facility_name: resident?.locations?.[0]?.name || '',
    resident_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
    resident_representative: '',
    facility_address: `${resident?.locations?.[0]?.address ?? ''} ${resident?.locations?.[0]?.city ?? ''} ${resident?.locations?.[0]?.state ?? ''} ${resident?.locations?.[0]?.zip ?? ''}`,
    resident_signature: '',
    resident_date: '',
    representative_signature: '',
    representative_date: ''
});

import { Button } from "@/common/presentation/components/ui/button";
import { t } from "i18next";
import { RefObject, useEffect, useRef, useState } from "react";

interface TrackerSkinCanvasProps {
    backgroundImageUrl: string;
    canvasRef: RefObject<HTMLCanvasElement>;
    points: () => any[];
    setCanvasPoints: (newPoint: any) => void;
    deleteCoordinates: () => void;
};

const TrackerSkinCanvas = ({ backgroundImageUrl, canvasRef, points, setCanvasPoints, deleteCoordinates }: TrackerSkinCanvasProps) => {
    const [clicks, setClicks] = useState(0);
    const pointSize = 3;
    const timeout = 300;

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const ctx = canvas.getContext('2d');
            
            if(ctx) {
                changeSizeCanvasResponsive(ctx, canvas);

                const handleResize = () => {
                    changeSizeCanvasResponsive(ctx, canvas);
                };

                window.addEventListener('resize', handleResize);

                return () => {
                    window.removeEventListener('resize', handleResize);
                };
            }
        }
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const ctx = canvas.getContext('2d');
            
            if(ctx) {
                const image = new window.Image();

                image.src = backgroundImageUrl;

                image.onload = () => {
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                };
            }
        }
    }, [backgroundImageUrl]);

    const changeSizeCanvasResponsive = (ctx, canvas) => {
        let cw = (canvas.width = 500);
        let ch = (canvas.height = 500);
        
        ctx.clearRect(0, 0, cw, ch);

        if (window.innerWidth <= 650) {
            cw = (canvas.width = 250);
            ch = (canvas.height = 250);
            canvas.setAttribute('width', 250);
            canvas.setAttribute('height', 250);
            ctx.clearRect(0, 0, cw, ch);
        } else {
            cw = (canvas.width = 500);
            ch = (canvas.height = 500);
            canvas.setAttribute('width', 500);
            canvas.setAttribute('height', 500);
            ctx.clearRect(0, 0, cw, ch);
        }

        return [cw, ch];
    };

    const drawCoordinates = (point, r, ctx) => {
        ctx.fillStyle = "#ff2626";
        ctx.beginPath();
        ctx.arc(point.x, point.y, r, 0, Math.PI * 2, true);
        ctx.fill();
    };

    const getPosition = (event, canvas) => {
        var rect = canvas.getBoundingClientRect();
        var x = event.clientX - rect.left;
        var y = event.clientY - rect.top;
        return { x, y };
    };

    const handleCanvasClick = (e) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const m = getPosition(e, canvas);

        drawCoordinates(m, pointSize, ctx);

        setClicks((prevClicks) => {
            const newClicks = prevClicks + 1;

            setTimeout(() => {
                if (newClicks === 1) {
                    setCanvasPoints((prevPoints) => [...prevPoints, m]);
                } else {
                    let updatedPoints = [...points()];

                    for (let i = 0; i < updatedPoints.length; i++) {
                        ctx.beginPath();
                        ctx.arc(updatedPoints[i].x, updatedPoints[i].y, pointSize, 0, Math.PI * 2, true);
                        if (ctx.isPointInPath(m.x, m.y)) {
                            updatedPoints.splice(i, 1);
                            break;
                        }
                    }

                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    const image = new window.Image();

                    image.src = backgroundImageUrl;

                    image.onload = () => {
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                    };

                    updatedPoints.forEach(p => drawCoordinates(p, pointSize, ctx));
                    setCanvasPoints(updatedPoints);
                }

                setClicks(0);
            }, timeout);
            return newClicks;
        });
    };

    return (
        <div className="justify-items-center">
            <canvas
                className="d-none border canvas-image"
                id="canvas"
                width="500"
                height="500"
                ref={canvasRef}
                onClick={handleCanvasClick}
                style={{
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'crosshair'
                }}
            ></canvas>

            <div className="w-100 mt-4">
                <div className="flex justify-center">
                    <Button onClick={deleteCoordinates} type="button" className="bg-primary text-white px-4 py-2 rounded">
                        {t('trackers.Reset red dots')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TrackerSkinCanvas;

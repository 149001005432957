import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { format, parse } from 'date-fns';
import { FileClock, Plus, Pencil } from 'lucide-react';
import { RefObject, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEmarPrnHistory } from '../../infrastructure/hooks/UseEmarPrnHistory';
import { usePrnDialog } from '../../infrastructure/hooks/UsePrnDialog';
import { useTranslation } from 'react-i18next';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/common/presentation/components/ui/hover-card';
import { timeUtils } from '../../domain/timeUtils';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
}

export const EmarPrnCard = ({ className, data, setRef }: MedicationCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { onOpen } = usePrnDialog();
  const { onOpen: onOpenEmarPrnHistory } = useEmarPrnHistory();
  const { locationId } = useParams();
  const displayFormat = t('dateFormats.dateTimeFormat');

  useEffect(() => {
    if (cardRef.current) {
      setRef?.(cardRef);
    }
  }, [cardRef]);

  const handleHistoryClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onOpenEmarPrnHistory(data);
  };

  const handleCreatePrn = (e: React.MouseEvent, data) => {
    e.stopPropagation();
    onOpen(data, locationId!);
  };

  const handleEditPrn = (prnData: any) => (e: React.MouseEvent) => {
    e.stopPropagation();
    const editData = {
      ...data,
      time_given: prnData.dispensed_at,
      follow_up: prnData.follow_up,
      reason: prnData.reason,
      result: prnData.result,
      comments: prnData.comments,
      level_of_pain: prnData.level_of_pain,
      resident_id: prnData.resident_id,
      id: prnData.resident_medication_id,
      dispense_id: prnData.id
    };
    onOpen(editData, locationId!);
  };

  const safeToString = (value: any) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') {
      if (value.name) return value.name.toString();
      return JSON.stringify(value);
    }
    return value.toString();
  };

  const resultLabels: Record<string, string> = {
    helpful: 'Helpful',
    somehow_helpful: 'Somehow Helpful',
    not_helpful: 'Not Helpful'
  };

  return (
    <Card className={cn('flex flex-col', className)} ref={cardRef}>
      <CardContent className="space-y-2">
        <CardHeader className="px-0">
          <div className="flex gap-2">
            {data?.emar_medication_prns?.length > 2 && (
              <HoverCard>
                <HoverCardTrigger asChild>
                  <Button variant="ghost" size="icon" className="size-8" onClick={handleHistoryClick}>
                    <FileClock className="size-6" />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="space-y-2">
                    <h4 className="text-sm font-semibold">View Complete PRN History</h4>
                    <p className="text-sm">
                      Click to see all previous PRN administrations for this medication. Total records:{' '}
                      {data?.emar_medication_prns?.length || 0}
                    </p>
                  </div>
                </HoverCardContent>
              </HoverCard>
            )}
            <HoverCard>
              <HoverCardTrigger asChild>
                <Button variant="ghost" size="icon" className="size-8" onClick={(e) => handleCreatePrn(e, data)}>
                  <Plus className="size-6" />
                </Button>
              </HoverCardTrigger>
              <HoverCardContent className="w-80">
                <div className="space-y-2">
                  <h4 className="text-sm font-semibold">Create New PRN</h4>
                  <p className="text-sm">
                    Register a new PRN administration for {safeToString(data?.medication?.name)?.toLowerCase()}.
                  </p>
                </div>
              </HoverCardContent>
            </HoverCard>
          </div>

          <div className="flex justify-between items-center w-full">
            <div className="flex flex-wrap gap-2 w-full max-w-[calc(100%-2rem)]">
              <CardTitle className="text-xl font-bold text-primary">
                {safeToString(data?.medication?.name)?.toUpperCase()}{' '}
                {data?.medication?.strength
                  ? `${safeToString(data?.strength)} ${safeToString(data?.form)}`
                  : safeToString(data?.form)}
              </CardTitle>
            </div>

            {data?.responsible_of_administration?.length > 0 && (
              <span className="text-2xl font-bold text-red-500 ml-2 whitespace-nowrap">C</span>
            )}
          </div>
        </CardHeader>
        <Separator />
        <div className="text-md">
          <p>
            RX #: <span className="font-bold">{safeToString(data?.chart[0]?.rx)}</span>
          </p>
          <p>
            Start Date:{' '}
            <span className="font-bold">
              {data?.chart[0].cycle_start_date
                ? format(parse(data?.chart[0]?.cycle_start_date, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                : ''}
            </span>
          </p>
          <p>
            Physician: <span className="font-bold">{safeToString(data?.prescribing_physician?.name)}</span>
          </p>
          <p>
            Frequency: <span className="font-bold">{safeToString(data?.frequency)}</span>
          </p>
          <p>
            Not to Exceed: <span className="font-bold">{safeToString(data?.not_exceed)}</span>
          </p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Instructions</h3>
          <p className="text-md">{safeToString(data?.instruction)}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Special Instructions</h3>
          <p className="text-md">{safeToString(data?.special_instructions)}</p>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="bg-white p-4">
        <div className="w-full space-y-2">
          <h3 className="text-sm font-semibold text-gray-700">Recent PRN History</h3>
          {data?.emar_medication_prns
            .sort((a, b) => {
              const dateA = new Date(a?.dispensed_at).getTime();
              const dateB = new Date(b?.dispensed_at).getTime();
              return dateB - dateA;
            })
            .slice(0, 2)
            .map((prn: any, index: number) => (
              <div key={index} className="bg-gray-50 p-3 rounded-md">
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-600">
                      {prn?.dispensed_at && timeUtils.formatDateUTC(prn?.dispensed_at, displayFormat)}
                    </p>
                    <p className="text-sm">
                      <span className="text-gray-500">Reason:</span> <span className="font-medium">{prn?.reason}</span>
                    </p>
                    <p className="text-sm">
                      <span className="text-gray-500">Result:</span>{' '}
                      <span className="font-medium">{resultLabels[prn?.result || ''] || prn?.result}</span>
                    </p>
                  </div>
                  <Button variant="ghost" size="sm" className="hover:bg-gray-200" onClick={handleEditPrn(prn)}>
                    <Pencil className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </CardFooter>
    </Card>
  );
};

export default EmarPrnCard;

import { cn } from '@/lib/utils';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { ChevronsLeft } from 'lucide-react';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import Navbar from '../navbar/Navbar';
import { Logo } from './logo';
import { NavigationList } from './navigation-list';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useLocation } from 'react-router-dom';
import { useSystemStyle } from '../hooks/useSystemStyle';

const Sidebar = () => {
  const { clientInfo } = useConfigurationsProvider();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const isResizingRef = useRef(false);
  const sidebarRef = useRef<ElementRef<'aside'>>(null);
  const navbarRef = useRef<ElementRef<'div'>>(null);
  const [isResetting, setIsResetting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const auth = useSelector((state: RootState) => state.auth.user);
  const { setCollapseSidebar, collapseSidebar } = useSystemStyle();
  const location = useLocation();

  const allowedRoles = ['Super Administrator', 'Executive'];
  const allowedPaths = ['/tracker/update-bedroom-qr/'];

  const hasAccess = !allowedRoles.includes(auth?.roles[0]?.name) && allowedPaths.includes(location.pathname);

  useEffect(() => {
    if (hasAccess || isMobile) {
      collapse();
    } else {
      resetWidth();
    }
  }, [isMobile]);

  useEffect(() => {
    if (collapseSidebar) {
      collapse();
      setCollapseSidebar(false);
    }
  }, [collapseSidebar])
  

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    isResizingRef.current = true;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isResizingRef.current) return;
    let newWidth = event.clientX;

    if (newWidth < 240) newWidth = 240;
    if (newWidth > 480) newWidth = 480;

    if (sidebarRef.current && navbarRef.current) {
      sidebarRef.current.style.width = `${newWidth}px`;
      navbarRef.current.style.setProperty('left', `${newWidth}px`);
      navbarRef.current.style.setProperty('width', `calc(100% - ${newWidth}px)`);
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const resetWidth = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(false);
      setIsResetting(true);

      sidebarRef.current.style.width = isMobile ? '100%' : '240px';
      navbarRef.current.style.setProperty('width', isMobile ? '0' : 'calc(100% - 240px)');
      navbarRef.current.style.setProperty('left', isMobile ? '100%' : '240px');
      setTimeout(() => setIsResetting(false), 300);
    }
  };

  const collapse = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(true);
      setIsResetting(true);

      sidebarRef.current.style.width = '0';
      navbarRef.current.style.setProperty('width', '100%');
      navbarRef.current.style.setProperty('left', '0');
      setTimeout(() => setIsResetting(false), 300);
    }
  };

  const mobileCollapse = () => {
    collapse();
  };

  return (
    <>
        <aside
          ref={sidebarRef}
          className={cn(
            'group/sidebar h-full bg-background overflow-y-auto relative flex w-60 flex-col z-[50]',
            isMobile && 'w-0',
            isResetting && 'transition-all ease-in-out duration-300',        
          )}
        >
          <div
            onClick={collapse}
            role="button"
            className={cn(
              'h-6 w-6 text-muted-foreground rounded-sm hover:bg-secondary dark:hover:bg-secondary absolute top-3 right-2 opacity-0 group-hover/sidebar:opacity-100 transition',
              isMobile && 'opacity-100'
            )}
          >
            <ChevronsLeft className="w-6 h-6" />
          </div>
          <div>
            <div className="mx-4 mt-8 mb-4">
              <Logo path={clientInfo?.logo || ''} clientName={clientInfo?.name || ''} />
            </div>
          </div>
          <div className="mt-4">
            <NavigationList isMobile={isMobile} mobileCollapse={mobileCollapse} />
          </div>
          <div
            onMouseDown={handleMouseDown}
            onDoubleClick={resetWidth}
            className="opacity-0 group-hover/sidebar:opacity-100 transition cursor-ew-resize absolute h-full w-1 bg-primary/10 right-0 top-0"
          />
        </aside>
      <div
        ref={navbarRef}
        className={cn(
          'absolute top-0 z-[20] left-60 w-[calc(100%-240px)]',
          isResetting && 'transition-all ease-in-out duration-300',
          isMobile && 'left-0 w-full',
        )}
        style={{ zIndex: "100" }}
      >
        <Navbar isCollapsed={hasAccess ? false : isCollapsed} onResetWidth={resetWidth} />
      </div>
    </>
  );
};

export default Sidebar;

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useForm } from 'react-hook-form';
import { Button } from '@/common/presentation/components/ui/button';
import { useEffect, useState } from 'react';
import { getLocationTypes, updateLocation } from '../../slices/locationsSlice';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { LocationDocumentsDialog } from '../Documents/LocationDocumentsDialog';
import { useTranslation } from 'react-i18next';
import { LocationI } from '@/modules/locations/infrastructure/interfaces/LocationInterface';

export const getFormSchema = () => {
    const { t } = useTranslation();
    return z.object({
        name: z
            .string({
                required_error: t('facilities.facilityNameRequired'),
            })
            .min(3, {
                message: t('facilities.facilityNameMin'),
            }),
        admin: z.string().optional(),
        phone_lic: z
            .string({
                required_error: t('facilities.facilityPhoneNumberRequired'),
            })
            .min(10, {
                message: t('facilities.facilityPhoneNumberMin'),
            }),
        licensing: z.string().optional(),
        vendor: z.string().optional().nullable(),
        phone: z.string().optional(),
        admin_email: z.union([z.string().email(), z.literal('')]),
        max_beds: z.preprocess((value) => (value !== '' ? Number(value) : undefined), z.number().optional()),
        category: z.string().optional(),
        address: z
            .string({
                required_error: t('facilities.addressRequired'),
            })
            .min(5, {
                message: t('facilities.addressMin'),
            }),
        city: z
            .string({
                required_error: t('facilities.cityRequired'),
            })
            .min(3, {
                message: t('facilities.cityMin'),
            }),
        state: z
            .string({
                required_error: t('facilities.stateRequired'),
            })
            .min(2, {
                message: t('facilities.stateMin'),
            }),
        zip: z.string().optional(),
        status: z.string().optional(),
        email: 
            z.union([z.string().email({
                message: t('facilities.invalidEmail'),
            }), z.literal('')])
    });
};

type FormValues = z.infer<ReturnType<typeof getFormSchema>>;

interface LocationType {
    id: string;
    type: string;
}

interface LocationFormProps {
    location: LocationI;
}


export const FacilityInformation = ({ location }: LocationFormProps) => {

    const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
    const formSchema = getFormSchema();
    const dispatch = useDispatch<AppDispatch>();
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...location,
            email: location.emails?.[0]?.email || '',
        },
    });

    const onClose = () => {
        setIsOpen(false)
    }

    const dataLocationTypes = async () => {
        try {
            const response = await dispatch(getLocationTypes());
            if (response.payload) {
                setLocationTypes(response.payload);
            }
        } catch (error) {
            console.error('Error fetching location types:', error);
        }
    };

    useEffect(() => {
        dataLocationTypes();
    }, []);

    useEffect(() => {
        if (location) {
            reset(location);
        }
    }, [location, reset]);

    const onSubmit = async (data: FormValues) => {
        setIsSubmit(true);
        const locationId = location.id;
        try {
            const response = await dispatch(updateLocation({
                locationId,
                data
            }))

            if (response.payload) {
                toast({
                    description: t('facilities.dataSaving'),
                    className: 'bg-green-500 text-white'
                });
            } else {
                toast({
                    description: t('facilities.errorSaving'),
                    variant: 'destructive'
                });
            }


        } catch (error) {
            console.log(error);
            toast({
                description: t('facilities.errorSaving'),
                variant: 'destructive'
            });
        }
        setIsSubmit(false);

    };

    return (
        <div className="p-1 mt-4">
            <div className="flex flex-items justify-end">
                <Button onClick={() => setIsOpen(true)} className="mt-1">
                    {t('facilities.documents')}
                </Button>

                <LocationDocumentsDialog
                    isOpen={isOpen}
                    onClose={onClose}
                    locationId={location?.id}
                    location={location}
                />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 text-black">
                    {/* Facility Name */}
                    <div>
                        <Label htmlFor="facility_name">{t('facilities.facilityName')} <span className="text-destructive">*</span></Label>
                        <Input id="name" {...register('name')} />
                        {errors.name?.message && (
                            <span className="text-red-500">{errors.name.message}</span>
                        )}
                    </div>

                    {/* Facility Administrator */}
                    <div>
                        <Label htmlFor="facility_administrator">{t('facilities.facilityAdministrator')}</Label>
                        <Input id="admin" {...register('admin')} />
                        {errors.admin?.message && (
                            <span className="text-red-500">{errors.admin.message}</span>
                        )}
                    </div>

                    {/* Facility Phone */}
                    <div>
                        <Label htmlFor="facility_phone">{t('facilities.facilityPhoneNumber')} <span className="text-destructive">*</span></Label>
                        <Input id="phone" {...register('phone')} />
                        {errors.phone?.message && (
                            <span className="text-red-500">{errors.phone.message}</span>
                        )}
                    </div>

                    {/* Licensing Number */}
                    <div>
                        <Label htmlFor="licensing_number">{t('facilities.licensingNumber')}</Label>
                        <Input id="licensing" {...register('licensing')} />
                        {errors.licensing?.message && (
                            <span className="text-red-500">{errors.licensing.message}</span>
                        )}
                    </div>

                    {/* Vendor Number */}
                    <div>
                        <Label htmlFor="vendor">{t('facilities.vendorNumber')}</Label>
                        <Input id="vendor" {...register('vendor')} />
                        {errors.vendor?.message && (
                            <span className="text-red-500">{errors.vendor.message}</span>
                        )}
                    </div>

                    {/* Administrator Phone */}
                    <div>
                        <Label htmlFor="admin_phone">{t('facilities.administratorPhoneNumber')}</Label>
                        <Input id="phone_lic" {...register('phone_lic')} />
                        {errors.phone_lic?.message && (
                            <span className="text-red-500">{errors.phone_lic.message}</span>
                        )}
                    </div>

                    <div>
                        <Label htmlFor="email">{t('facilities.restrictByDeviceEmailAccess')}</Label>
                        <Input
                            id="email"
                            {...register('email')}
                            defaultValue={location?.emails?.[0]?.email || ''}
                        />
                        {errors.email &&
                            <span className="text-red-500">{errors.email.message}</span>}
                    </div>

                    {/* Administrator Email */}
                    <div>
                        <Label htmlFor="admin_email">{t('facilities.administratorEmail')}</Label>
                        <Input id="admin_email" {...register('admin_email')} />
                        {errors.admin_email?.message && (
                            <span className="text-red-500">{errors.admin_email.message}</span>
                        )}
                    </div>


                    {/* Max Occupancy */}
                    <div>
                        <Label htmlFor="max_occupancy">{t('facilities.maxOccupancyBeds')}</Label>
                        <Input id="max_beds" {...register('max_beds')} disabled/>
                        {errors.max_beds?.message && (
                            <span className="text-red-500">{errors.max_beds.message}</span>
                        )}
                    </div>

                    {/* Facility Type */}
                    <div>
                        <Label htmlFor="facility_type">{t('facilities.facilityType')}</Label>
                        <Select
                            value={watch('category')}
                            onValueChange={(value) => setValue('category', value)}
                        >
                            <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                <SelectValue>
                                    {watch('category')
                                        ? locationTypes.find((type) => type.id === watch('category'))?.type
                                        : 'Select a facility type'}
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                                {locationTypes.map((type) => (
                                    <SelectItem key={type.id} value={type.id}>
                                        {type.type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        {errors.category?.message && (
                            <span className="text-red-500">{errors.category.message}</span>
                        )}
                    </div>

                    {/* Address */}
                    <div>
                        <Label htmlFor="address">{t('facilities.address')} <span className="text-destructive">*</span></Label>
                        <Input id="address" {...register('address')} />
                        {errors.address?.message && (
                            <span className="text-red-500">{errors.address.message}</span>
                        )}
                    </div>

                    {/* City */}
                    <div>
                        <Label htmlFor="city">{t('facilities.city')} <span className="text-destructive">*</span></Label>
                        <Input id="city" {...register('city')} />
                        {errors.city?.message && (
                            <span className="text-red-500">{errors.city.message}</span>
                        )}
                    </div>

                    {/* State */}
                    <div>
                        <Label htmlFor="state">{t('facilities.state')} <span className="text-destructive">*</span></Label>
                        <Input id="state" {...register('state')} />
                        {errors.state?.message && (
                            <span className="text-red-500">{errors.state.message}</span>
                        )}
                    </div>

                    {/* Zip Code */}
                    <div>
                        <Label htmlFor="zip">{t('facilities.zipCode')}</Label>
                        <Input id="zip" {...register('zip')} />
                        {errors.zip?.message && (
                            <span className="text-red-500">{errors.zip.message}</span>
                        )}
                    </div>

                    {/* Active Status */}
                    {/* <div className="flex items-center gap-2">
                        <Label htmlFor="active_status">{t('facilities.activeStatus')}</Label>
                        <Switch
                            id="status"
                            checked={status === 'active'}
                            onCheckedChange={handleSwitchChange}
                        />
                        {errors.status?.message && (
                            <span className="text-red-500">{errors.status.message}</span>
                        )}
                    </div> */}

                </div>

                <div className='flex flex-items justify-end'>
                    <Button type="submit" className="w-[346px] mt-5" disabled={isSubmit}>
                        {t('medications.update')}
                    </Button>
                </div>
            </form>
        </div>
    );
}
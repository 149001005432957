import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { AppDispatch, RootState } from "@/store/store";

import useRouteParams from "@/common/hooks/RouteParamsHook";
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';

import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from "@/common/presentation/components/navigations/BackNavigationBar"
import { CustomCard } from '../shared/CustomCard';
import FormActionButtons from "../shared/FormActionButtons";
import { lic601EmergencyInfoDefaults } from "../formDefaults/lic601EmergencyInfoDefaults";
import IdentificationEmergencyInfo from "./components/IdentificationEmergencyInfo";
import { PersonsResponsibleForm } from "./components/PersonsResponsibleForm";
import EmergencyContactsForm from "./components/EmergencyContactsForm";
import EmergencyHospitalizationForm from "./components/EmergencyHospitalizationForm";
import OtherRequiredInformationForm from "./components/OtherRequiredInformationForm";
import { useFormSubmission } from "@/modules/residents/domain/hooks/forms/useFormSubmission";
import { getContactsByResident } from "@/modules/contacts/presentation/slices/contactsSlice";
import { useFetchAllContacts } from "@/modules/residents/infrastructure/hooks/forms/useFetchAllContacts";

export const Lic601EmergencyInfoForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const { contacts: allContacts, isLoading } = useFetchAllContacts(params.r, getContactsByResident);

   const methods = useForm({
      defaultValues: lic601EmergencyInfoDefaults(resident, allContacts),
    });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(lic601EmergencyInfoDefaults(resident, allContacts));
    }
  }, [resident, allContacts, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic601_id_and_emergency_info',
  });

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();
    const cleanedData = rawData;
    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident || isLoading) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
        <BackNavigationBar
          title={title}
          path={`/residents/forms?r=${params.r}`}
        />
        <FormProvider {...methods}>
              <form>
                <IdentificationEmergencyInfo />
                <PersonsResponsibleForm />
                <EmergencyContactsForm />
                <EmergencyHospitalizationForm />
                <CustomCard>
                  <OtherRequiredInformationForm />
                  <FormActionButtons
                    handleFormSubmit={handleFormSubmit}
                    formsStatus={formsStatus}
                  />
                </CustomCard>
              </form>
        </FormProvider>
      </div>
    </div>
  )

}
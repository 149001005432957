import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { z } from 'zod';
import { cn } from '@/lib/utils';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/ui/separator';
import { updateRiskAssessment } from '../../slices/residentsForm';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const formSchema = z.object({
  elopement_risk: z.string().optional(),
  fall_risk: z.string().optional(),
  pressure_sore_risk: z.string().optional()
});
type FormValues = z.infer<typeof formSchema>;

export const RiskAssessmentForm = ({ className }: Props) => {
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });
  const { t } = useTranslation();

  const onSubmit = async (data: FormValues) => {

    try {
      const sanitizedData = {
        resident_id: resident?.id as string,
        elopement_risk: data.elopement_risk || '',
        fall_risk: data.fall_risk || '',
        pressure_sore_risk: data.pressure_sore_risk || '',
      };

      const response = await dispatch(updateRiskAssessment(sanitizedData));

      if (response.payload) {
        toast({
          description: t('residents.riskAssessment.updatedRisk'),
          className: 'bg-green-500 text-white'
        });
      }
    } catch (error) {
      console.error('Error submitting risk assessment:', error);
      toast({
        description: 'Error updating risk assessment.',
      });
    }

  };

  useEffect(() => {
    if (resident) {
      const riskData = Array.isArray(resident.risk_assessment) && resident.risk_assessment.length > 0
        ? resident.risk_assessment[0]
        : null;

      setDefaultValues({
        elopement_risk: riskData?.elopement_risk || '',
        fall_risk: riskData?.fall_risk || '',
        pressure_sore_risk: riskData?.pressure_sore_risk || ''
      });
    }
  }, [resident]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title={t('residents.riskAssessment.riskAssessment')} defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <FormField
                      control={form.control}
                      name="elopement_risk"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.riskAssessment.elopementRisk')}</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={residentFormStatus === 'loading'}
                              value={field.value || ''} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="fall_risk"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.riskAssessment.fallRisk')}</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={residentFormStatus === 'loading'} value={field.value || ''} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="pressure_sore_risk"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.riskAssessment.pressureSoreRisk')}</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={residentFormStatus === 'loading'} value={field.value || ''} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <Separator className="col-span-full" />
                  <Button className="w-64 mt-4" type="submit">{t('common.update')}</Button>
                </div>
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

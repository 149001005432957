import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Pencil, Trash } from "lucide-react";
import { deleteQuestion, getQuestions, questionsLoading, updateOrderData } from "@/modules/settings/presentation/components/menus/Questions/slices/QuestionsSlice";
import DeleteDialog from "@/common/presentation/components/DeleteDialog/DeleteDialog";
import { DialogCreateOrUpdate } from "./DialogCreateOrUpdate";
import { FiltersTable } from "./FiltersTable";
import { IQuestion, UpdateItemParams } from "../../domain/questions.domain";


export const Questions = () => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [data, setData] = useState<any>([]);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.settings.questions);

  const [dataUpdate, setDataUpdate] = useState<IQuestion>({
    id: '',
    title: '',
    description: '',
    answer_type: 'yes_no',
    order: 0,
    is_required: 'yes',
    status: 'active'
  });

  const renderActionsColumn = (rowData: any) => {
    return (
      <div className="flex gap-2 justify-end z-[9999999999]">
        <Button variant="outline" size="icon"
          onClick={() => {
            setIsUpdate(true);
            setOpenDialog(true);
            setDataUpdate(rowData);
          }}
        >
          <Pencil className="h-4 w-4" />
        </Button>
        {rowData?.availability == 'single_client' &&
          <Button variant="outline" size="icon" onClick={() => {
            setIdDelete(rowData.id);
            setOpenDeleteAlert(true);
          }}>
            <Trash className="h-4 w-4" />
          </Button>}

      </div>
    );

  };

  const columns = [
    { key: "title", labelTranslationKey: "settings.visitor.questions.title", width: "65%" },
    { key: "type", labelTranslationKey: "settings.visitor.questions.type", width: "15%" },
    { key: "status", labelTranslationKey: "settings.status", width: "10%" },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: '',
      render: renderActionsColumn,
      width: '10%',
      static: true
    }
  ];

  const callbackLoadMore = async () => {
    if (!hasMoreRows) return;
    try {
      const nextPage = currentPage + 1;
      const params = {
        currentPage: nextPage,
        perPage: rowsPerPage
      };
      dispatch(questionsLoading());
      const response = await dispatch(getQuestions(params));
      const items = response?.payload?.items ?? {};
      const hasMore = response?.payload?.pagination?.hasMorePages;

      if (items) {
        const parseData = Object.values(items);
        setData((prevData: IQuestion[]) => [...prevData, ...parseData] );
        setCurrentPage(nextPage);
        setHasMoreRows(hasMore);
      }

    } catch (error) {
      throw new Error(`Error get data: ${error}`);
    }

  };

  const answerType: any = {
    yes_no: t('settings.visitor.questions.yesNo'),
    multiple_choice: t('settings.visitor.questions.multipleChoice'),
    text: t('settings.visitor.questions.text'),
    numeric: t('settings.visitor.questions.numeric'),
    scale: t('settings.visitor.questions.scale')
  };

  const callbackRenderContent = (index: any, column: any, data: any) => {
    const row = data[index];
    const type = row?.answer_type;
    const title = (row.availability === 'all_clients') ? t(`settings.visitor.questions.${row?.title}`) : row?.title;

    switch (column.key) {
      case 'title':
        return title
      case 'type':
        return (type) ? answerType[type] : '';
      case 'status':
        return row?.status == 'active' ? t('settings.active') : t('settings.inactive');
    }
  }

  const handleDelete = () => {
    if (idDelete) {
      dispatch(questionsLoading());
      dispatch(deleteQuestion(idDelete)).then(() => {
        setData((prevData: IQuestion[]) => prevData.filter(d => d.id !== idDelete))
      })
    }
  }

  const handleFilterChange = async (value: string) => {
    setFilterValue(value);
    if (value.length >= 1) {
      dispatch(questionsLoading());
      const response = await dispatch(getQuestions({
        currentPage: 1,
        perPage: rowsPerPage,
        search: value
      }));

      if (response?.payload?.items) {
        setData(response.payload.items);
        setHasMoreRows(response.payload.pagination.hasMorePages);
      }
    } else if (value === '') {
      getData(1);
    }
  };

  const handleClearFilter = () => {
    setFilterValue('');
    getData(1);
  };

  const getData = async (cPage: number = 1) => {
    dispatch(questionsLoading());
    const response = await dispatch(getQuestions({ cPage, perPage: rowsPerPage }));
    const payload = response?.payload;
    if (payload?.items) {
      setData(payload.items);
      setHasMoreRows(payload.pagination.hasMorePages)
      setCurrentPage(cPage);
    }
  };

  const callbackRowOrder = async(orderedData: IQuestion[]) => {
    const response =  await dispatch(updateOrderData(orderedData));
    const result = response?.payload?.result;
    if(result == 'ok'){
       setData(orderedData);
    }
  }

  useEffect(() => {
    getData();
  }, [dispatch]);



  return <>

    <TableCustomVirtuoso
     tableId="v-questions"
      styles={{ height: 'calc(100vh - 26rem)' }}
      additionalButtons={
        <Button
          variant="outline"
          size="sm"
          className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
          onClick={() => setOpenDialog(true)}
        ><span>{t('settings.notes.quickNotes.new')}</span></Button>
      }
      data={data}
      columns={columns}
      renderCellContent={callbackRenderContent}
      onSort={()=>{}}
      loadMore={callbackLoadMore}
      hasMore={hasMoreRows}
      isLoading={isLoading}
      sortableRows={true}
      updateRowOrder={callbackRowOrder}
      disabledDragCells={[3]}
      filters={
        <FiltersTable
          onFilterChange={handleFilterChange}
          filterValue={filterValue}
          onClear={handleClearFilter}
        />
      }
      showSearchInput={false}
    />

    <DialogCreateOrUpdate
      onChangeOpen={setOpenDialog}
      open={isOpenDialog}
      isUpdate={isUpdate}
      onChangeIsUpdate={setIsUpdate}
      onChangeGetData={getData}
      dataUpdate={dataUpdate}
    />

    <DeleteDialog
      isOpen={isOpenDeleteAlert}
      onOpenChange={setOpenDeleteAlert}
      handleDelete={handleDelete}
    />
  </>;



}


import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';

const ResidentAppraisal = () => {
  const { control } = useFormContext();
    const { t } = useTranslation();

  return (
    <div className="w-full space-y-6">
      <div className="space-y-2 text-center">
        <h2 className="text-xl font-bold mb-4">{t('residents.forms.residentAppraisal.residentAppraisal')}</h2>
        <p className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
        {t('residents.forms.residentAppraisal.residentCareFacilities')}
        </p>
      </div>

      <div className="space-y-2">
        <span className='text-sm font-semibold text-zinc-800 dark:text-zinc-200'>
          {t('common.note')}: {' '}
        </span>
        <span className="text-sm text-zinc-600 dark:text-zinc-400">
          {t('residents.forms.residentAppraisal.residentAppraisalDescription')}
        </span>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <FormField
          control={control}
          name="resident_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t("residents.forms.residentAppraisal.applicantsName")}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="resident_age"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t("residents.age")}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default ResidentAppraisal;

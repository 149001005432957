import { ResidentI } from "@/common/domain/Residents";
import { formatNumberWithPattern } from "@/modules/residents/domain/utils/Forms/formUtils";

export const residentPersonalPropertyDefaults = (resident: ResidentI) => ({
    resident_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
    social_security:  resident?.security_number
    ? formatNumberWithPattern(resident.security_number)
    : '',
    licensee: '',
    date_completed: '',
    valuables_entrusted: [],
    valuables_removed: [],
});

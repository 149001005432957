import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportByResidentService from "../../infrastructure/services/ReportByResidentService";

interface IState { 
    registersvitals: Array<any>;
    registers: Array<any>;
    isLoading: boolean;
    categories: Array<{ label: string; value: string | number }>; // Expected format for the MultiSelect
    categoriesvitals: Array<{ label: string; value: string | number }>;
}
  
const initialState: IState = {
    registersvitals: [],
    registers: [],
    isLoading: false,
    categories: [],
    categoriesvitals: [],
};

export const getCombinedReport = createAsyncThunk(
  'reportbyresident/getCombinedReport',
  async ({
      residentId,
      locationId,
      startDate,
      endDate,
      trackers,
      categoriesNotes,
      categoriesVitals,
  }: {
      residentId: string;
      locationId: string;
      startDate: string;
      endDate: string;
      trackers: string[];
      categoriesNotes?: string[]; // optional filter
      categoriesVitals?: string[]; // optional filter
  }) => {
      try {
          const response = await ReportByResidentService.getCombinedReport({
              // Filters passed to the service
              residentId,
              locationId,
              startDate,
              endDate,
              trackers,
              categoriesNotes,
              categoriesVitals, 
          });
          return response.payload;
      } catch (error) {
          throw error;
      }
  }
);

// Action to export the PDF report
export const exportVitalsReport = createAsyncThunk(
  'reportbyresident/exportVitalsReport',
  async ({ residentId, locationId, startDate, endDate, trackers, categoriesNotes, categoriesVitals }: { residentId: string; locationId: string; startDate: string; endDate: string; trackers: string[], categoriesNotes: string[], categoriesVitals: string[] }) => {
    try {
      const response = await ReportByResidentService.exportReport({ residentId, locationId, startDate, endDate, trackers, categoriesNotes, categoriesVitals });

      // Create a blob from the response
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create URL from blob
      const url = window.URL.createObjectURL(blob);

      // Open the PDF in a new tab
      const newTab = window.open();
      if (newTab) {
        newTab.location.href = url; // Set the location of the PDF
      } else {
        //console.error('El navegador bloqueó la ventana emergente.');
      }

      return response;
    } catch (error) {
      //console.error('Error al exportar el PDF:', error);
      throw error;
    }
  }
);

// AsyncThunk to get the note categories
export const fetchNotesCategories = createAsyncThunk(
  'reportbyresident/fetchNotesCategories',
  async () => {
      try {
          const response = await ReportByResidentService.getNotesCategories();
          return response; // It is assumed that the service returns an array with objects { label, value }
      } catch (error) {
          throw error;
      }
  }
);

export const fetchVitalsCategories = createAsyncThunk(
  'reportbyresident/fetchVitalsCategories',
  async () => {
      try {
          const response = await ReportByResidentService.getVitalsCategories();
          return response;
      } catch (error) {
          throw error;
      }
  }
);

export const ReportByResidentSlice = createSlice({
    name: 'reportbyresident',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getCombinedReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCombinedReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registers = action.payload; // Combine the trackers
      })
      .addCase(getCombinedReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(exportVitalsReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportVitalsReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(exportVitalsReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchNotesCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotesCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload; // Assign the obtained categories
      })
      .addCase(fetchNotesCategories.rejected, (state) => {
          state.isLoading = false;
      })
      .addCase(fetchVitalsCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVitalsCategories.fulfilled, (state, action) => {
          state.isLoading = false;
          state.categoriesvitals = action.payload;
      })
      .addCase(fetchVitalsCategories.rejected, (state) => {
          state.isLoading = false;
      });
    }
});

export default ReportByResidentSlice.reducer;

import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { CrudState, FormData as FormDataModel, NoteI } from '@/modules/notes/domain/note.domain';
import { HousesSelector } from './HousesSelector';
import { ImgageGrid } from '../common/imageGrid';
import { Loader, Save } from 'lucide-react';
import { NoteCategoryI, SelectNoteCategory } from '../common/SelectNoteCategory';
import { NoteTranslator } from '../common/noteTranslator';
import { OptionNoteTo, SelectNoteToBetweenStaff } from './elements/SelectNoteToBetweenStaff';
import { QuillJS } from '../common/quilljs';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';
import React, { useCallback, useEffect, useState } from 'react';

interface CreateNoteProps {
    crudState: CrudState;
    setCrudState: (crudState: CrudState) => void;
    note: NoteI | null;
}

export interface MedicalAppoinment {
    doctor: string;
    specialty: string;
    date: string;
}

export function CreateNoteBetweenStaff({ crudState, setCrudState, note }: CreateNoteProps) {

    // Redux
    const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);
    const drafts = useSelector((state: RootState) => state.notes.drafts.data);
    const { locationSelectedId } = useLocation();

    // Init Values
    const { t } = useTranslation();
    const defaultFormData: FormDataModel = {
        content: note?.content || '',
        html_content: note?.html_content || '',
        status: 'active',
        user_id: note?.private_user_ids || [],
        category_id: note?.category_id || '',
        resident_id: null,
        staff_id: [],
        location_id: crudState.isUpdatingBeetweenStaffNotes ? note?.scope_location_ids ?? [] : [],
        department_id: note?.department_ids || [],
        reference: 'notePage',
        images: note?.images || null,
        client_id: clientId,
        draft_id: null,
        urgent: false,
        specialty: null,
        doctor: null,
        medical_appoinment_date: null,
    };

    // Hooks
    const [isLoading, setIsLoading] = useState(false);
    const [draftContent, setDraftContent] = useState('');
    const [formData, setFormData] = useState<FormDataModel>(defaultFormData);

    useEffect(() => {
        if (crudState.isFinishingDraft) {
            let draft = drafts.filter((item: any) => item.id == crudState.itemId);
            if (draft.length > 0) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ['draft_id']: draft[0].id
                }));

                setDraftContent(draft[0].content);
            }
        }
    }, [crudState]);

    // Behaviors
    const onResidentLocation = (locations: string[]) => {
        if (locations.length === 1 && locations[0] === 'all-houses') {
            setFormData({
                ...formData,
                location_id: []
            });
        } else {
            setFormData({
                ...formData,
                location_id: locations
            });
        }
    };

    const handleSelectNoteTo = (options: OptionNoteTo[]) => {
        const recipients: string[] = options.map(it => it.value);

        const staffItems = recipients.filter((item) => item.endsWith('-staff')).map((item) => item.replace('-staff', ''));

        const departmentsItems = recipients
            .filter((item) => item.endsWith('-department'))
            .map((item) => item.replace('-department', ''));

        setFormData({
            ...formData,
            ['user_id']: staffItems,
            ['department_id']: departmentsItems
        });
    };

    const handleSelectCategory = (category: NoteCategoryI | undefined) => {
        if (category) {
            setFormData({
                ...formData,
                category_id: category.id,
            });
        }
    };

    const handleQuillJs = (values: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ...values
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.content || formData.content.trim().length < 2) {
            setFormData({
                ...formData,
                ['content']: "<br/>"
            })
        }

        setCrudState(NoteHelperService.defineState({ ...crudState, action: () => { } }));

        setIsLoading(true);
    };

    const useNotificationSound = () => {
        const audio = new Audio(`/sounds/message_sent.mp3`);

        const playSound = useCallback(() => {
            audio.currentTime = 0;
            audio.play().catch(error => console.log('Error reproduciendo sonido:', error));
        }, []);

        return playSound;
    };

    const playSound = useNotificationSound();

    useEffect(() => {
        if (isLoading) {
            const performSecondStateUpdate = async () => {

                let hasNoteTo = formData.location_id.length || formData.department_id.length || formData.user_id.length;

                if (!hasNoteTo && locationSelectedId !== 'all-locations') {
                    toast({
                        description: t('notes.noteToNotFound'),
                    });
                    setIsLoading(false);
                    return
                }

                if (!formData.category_id) {
                    toast({
                        description: t('notes.categoryNotFound'),
                    });
                    setIsLoading(false);
                    return

                }

                if ((!formData.content || formData.content.trim().length < 2) && !formData.images?.length) {
                    toast({
                        description: t('notes.contentNoteLength'),
                    });
                    setIsLoading(false);
                    return;
                }

                let images = formData.images;
                try {
                    formData.images = [];
                    formData.location_selected = locationSelectedId;

                    console.log('formData', formData);

                    if (crudState.isUpdatingBeetweenStaffNotes) {
                        let data = {
                            ...formData,
                            id: crudState.itemId,
                            typeNote: 'betweenstaff'
                        }

                        await NoteService.updateNote(data);

                        if (images?.length) {
                            let form = new FormData();
                            form.append('id', crudState.itemId);
                            form.append('type', 'note');
                            images.forEach(img => form.append('images[]', img))
                            await NoteService.uploadImges(form);
                        }
                    } else {
                        let response = await NoteService.createNote({
                            ...formData,
                            typeNote: 'betweenstaff'
                        });

                        if (images?.length) {
                            let form = new FormData();
                            form.append('id', response.payload.id);
                            form.append('type', 'note');
                            images.forEach(img => form.append('images[]', img))
                            await NoteService.uploadImges(form);
                        }
                    }

                    playSound();
                    setCrudState(NoteHelperService.defineState({ showingBeetweenStaffNotes: true }));
                    setIsLoading(false);
                } catch (error) {
                    toast({
                        description: t('trackers.trackerFall.error'),
                        variant: 'destructive'
                    });
                    setIsLoading(false);
                }

            };

            performSecondStateUpdate();
        }
    }, [isLoading]);

    const removeImage = (value: { id: string, image: string }) => {
        let copyDeleImages = [...formData.deleteImages || []]
        let findImage = copyDeleImages.findIndex(image => image == value.id);
        if (findImage >= 0) {
            copyDeleImages.splice(findImage, 1);
            setFormData({
                ...formData,
                deleteImages: copyDeleImages
            });
        } else {
            setFormData({
                ...formData,
                deleteImages: (formData.deleteImages || [])?.concat([value.id])
            });
        }
    }

    const getContent = () => {
        if (crudState.isUpdatingBeetweenStaffNotes && note) {
            return note.html_content;
        }
        return draftContent;
    };

    return (
        <Card className="col-span-12 md:col-span-7 border-t-4 border-t-primary/80">
            <CardContent>
                <HousesSelector
                    className="mt-3"
                    onSelect={onResidentLocation}
                    setCrudState={setCrudState}
                    staffId={formData.location_id.length ? formData.location_id : ['all-houses']}
                    note={note}
                />
                <ScrollArea className='h-[calc(100vh-450px)]'>
                    <form onSubmit={handleSubmit}>
                        <div className='grid grid-cols-12 gap-4 py-4'>
                            <div className='col-span-12 md:col-span-6'>
                                <SelectNoteToBetweenStaff
                                    handleSelectNoteTo={handleSelectNoteTo}
                                    locationsSelectedUser={formData.location_id}
                                    note={note}
                                />
                            </div>
                            <div className='col-span-12 md:col-span-6'>
                                <SelectNoteCategory
                                    target='betweenstaff'
                                    decodeHTMLEntities={NoteHelperService.decodeHTMLEntities}
                                    handleSelectCategory={handleSelectCategory}
                                    isPharmacySelected={false}
                                    value={crudState.isUpdatingBeetweenStaffNotes && note?.category_id ? note.category_id : ''}
                                />
                            </div>
                        </div>
                        <Separator className='bg-primary mb-4' />
                        <div className="w-full">
                            <QuillJS onChange={handleQuillJs} value={getContent()} crudState={crudState} translator={true} />
                        </div>
                        {note?.images && note.images.length > 0 && <ImgageGrid images={note.images} removeImage={removeImage} imagesDelete={formData.deleteImages} />}
                        <div className="flex ml-auto">
                            <NoteTranslator text={formData.content} setTranslation={setDraftContent} className="hidden" />
                        </div>
                        <div className="pt-4 flex justify-between">
                            <Button type="submit" disabled={isLoading} size="sm" className="ml-auto">
                                {isLoading ? (
                                    <Loader className="mr-2 h-4 w-4 animate-spin" />
                                ) : (
                                    <>
                                        <Save className="mr-2 h-4 w-4" />
                                        {t('notes.send')}
                                    </>
                                )}
                            </Button>
                        </div>
                    </form>
                </ScrollArea>
            </CardContent>
        </Card>
    );
}

const columnsDestructionMedications: Column[] = [
  {
    key: 'medication_id',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.medication_name',
    numeric: false,
    visible: true,
    static: true,
    sortable: true,
    render: (data) => {
      const medicationName = data.medication_name;
      return medicationName.charAt(0).toUpperCase() + medicationName.slice(1).toLowerCase();
    }
  },

  {
    key: 'quantity',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.qtyReceived',
    numeric: true,
    visible: true,
    sortable: true,
    editable: true
  },
  {
    key: 'filleDate',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.filledDate',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    type: 'calendar'
  },
  {
    key: 'rx',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.rx',
    numeric: true,
    visible: true,
    sortable: true,
    editable: true
  },
  {
    key: 'disposal_date',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.disposalDate',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true,
    type: 'calendar'
  },
  {
    key: 'name_pharmacy',
    labelTranslationKey: 'medications.DestructionMedications.columnsDestructionMedications.nameOfPharmacy',
    numeric: false,
    visible: true,
    sortable: true,
    editable: true
  }
];

export default columnsDestructionMedications;

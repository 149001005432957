import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { TableCell, TableRow } from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '@/modules/residents/infrastructure/types/documents';
import { AppDispatch } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { File as FileIconLucide, Loader2, Save } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveLocationDocument } from '@/modules/locations/presentation/slices/locationsSlice';
import { z } from 'zod';
import { MainDocument } from '@/modules/locations/infrastructure/interfaces/LocationInterface';

interface Props {
  document: MainDocument;
  location_id: string;
  locationDocumentsCount: number;
  dataFolders: () => void;
}

interface FormI {
  comments?: string;
  files: File[];
  hard_copy?: boolean;
}

const formSchema = z.object({
  comments: z.string().optional(),
  hard_copy: z.boolean().optional(),
  files: z
    .array(
      z
        .instanceof(File)
        .refine((file) => Object.keys(ACCEPTED_FILE_TYPES).includes(file.type), {
          message: 'Invalid file type.'
        })
        .refine((file) => file.size <= MAX_FILE_SIZE, {
          message: 'Max file size is 2MB.'
        })
    )
    .min(1, { message: 'File is required.' })
});

type FormValues = z.infer<typeof formSchema>;

export const LocationCategoryItem = ({ document, location_id, locationDocumentsCount, dataFolders }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onOpen, isOpen } = useHistoryDocuments();

  useEffect(() => {
    if (!isOpen) {
      dataFolders();
    }
  }, [isOpen]);

  const versions = useMemo(() => {
    const documents = (document?.documents || []).filter(doc => doc.location_id === location_id);

    const parentDocument = documents.find(
      doc => !doc.parent_document_id && !doc.deleted_at
    );

    const parentVersions = documents.length > 0
      ? (documents[0]?.parent_documents || [])
        .filter(parent => parent.location_id === location_id && !parent.deleted_at)
      : [];


    const combinedVersions = [
      ...(parentDocument ? [parentDocument] : []),
      ...parentVersions,
    ];

    return combinedVersions.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }, [document, location_id]);


  const form = useForm<FormValues>({
    defaultValues: {
      comments: versions?.[versions.length - 1]?.comments ?? '',
      hard_copy: versions?.[versions.length - 1]?.other_information?.hard_copy ?? false,
      files: []
    },
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (formValues: FormI) => {
    const filteredDocuments = (document?.documents || []).filter(doc => doc.location_id === location_id);
    const parentId = filteredDocuments?.[0]?.id ?? null;
    setIsLoading(true);
    const data = {
      key: document.key,
      label: document.name,
      comments: formValues.comments,
      category: document.category,
      parent_document_id: parentId || null,
      location_id,
      isEdit: true,
      other_information: {
        hard_copy: formValues.hard_copy,
        digital_signature_on_file: false
      }
    };

    const stringifiedData = JSON.stringify(data);

    const payload = {
      data: stringifiedData,
      files: formValues.files
    };

    const response = await dispatch(saveLocationDocument(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      form.reset();
      dataFolders();
    }
    setIsLoading(false);
  };

  const handleViewDocument = () => {
    const url = versions?.[versions.length - 1]?.url;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      console.error('No URL available to open');
    }
  };

  return (
    <Form {...form}>
      <TableRow>
        <TableCell>{document.name}</TableCell>

        <TableCell>
          <FormField
            control={form.control}
            name="comments"
            render={({ field }) => (
              <FormItem>
                <Textarea {...field} className="h-10 min-h-10" />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>
          <FormField
            control={form.control}
            name="hard_copy"
            render={({ field }) => (
              <FormItem>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>
          <Button
            className="flex items-center space-x-2"
            variant="ghost"
            onClick={() => {
              onOpen(versions);
            }}
            disabled={versions?.length === 0}
          >
            {versions?.length || 0} Version{versions?.length === 1 ? '' : 's'}
          </Button>
        </TableCell>

        <TableCell>
          <div className="flex gap-2 w-full items-center">
            <Button
              size="icon"
              className={cn(
                versions?.length !== 0
                  ? 'border-green-500 text-green-500 hover:bg-green-500 hover:text-white'
                  : 'border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
              )}
              variant="outline"
              onClick={handleViewDocument}
              disabled={!document?.documents?.some(doc => doc.location_id === location_id && doc.url)
                || versions?.length === 0
              }
            >
              <FileIconLucide className="size-4" />
            </Button>
            <FormField
              control={form.control}
              name="files"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <MultiFileDropzone
                      className={`w-auto outline-none min-h-[40px] min-w-[250px] ${isLoading ? 'bg-gray-200 cursor-not-allowed opacity-50' : ''
                        }`}
                      value={field.value}
                      onChange={field.onChange}
                      dropzoneOptions={{
                        accept: ACCEPTED_FILE_TYPES,
                        maxSize: MAX_FILE_SIZE,
                        maxFiles: locationDocumentsCount === 0 ? 1 : undefined
                      }}
                      width={300}
                      disabled={isLoading}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.formState.isDirty && form.watch('files').length > 0 && (
              <Button
                variant="default"
                size="icon"
                className="bg-background border border-green-600 hover:bg-background text-green-600"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {isLoading ? <Loader2 className="size-4 animate-spin" /> : <Save className="size-4" />}
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    </Form>
  );
};

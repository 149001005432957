import { Button } from '@/common/presentation/components/ui/button';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { useNewMar } from '@/modules/medications/infrastructure/hooks/use-new-mar';
import * as medicationActions from '@/modules/medications/presentation/slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, set } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { ResidentSelector } from './ResidentSelector';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useEffect } from 'react';
import { YearAndMonthSelect } from '@/common/presentation/components/CustomCalendar/YearAndMonthSelect';

const FormSchema = z.object({
  resident_id: z
    .string({
      required_error: 'A resident is required.'
    })
    .min(1, 'A resident is required.'),
  date_range: z.date({
    required_error: 'A date is required.'
  }),
  notes: z.enum(['none', 'end', 'each_page'], {
    required_error: 'A notes is required.'
  }),
  sort_by: z.enum(['medication', 'hour'], {
    required_error: 'A sort by is required.'
  })
});

type FormValues = z.infer<typeof FormSchema>;

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);

const defaultValues: Partial<FormValues> = {
  resident_id: '',
  date_range: firstDayOfCurrentMonth,
  notes: 'none',
  sort_by: 'medication'
};

export const MarDocumentDialog = () => {
  const { params, setParam } = useRouteParams();

  const dispatch = useDispatch<AppDispatch>();

  const { isOpen, onClose, locationId } = useNewMar();

  const { status } = useSelector((state: RootState) => state.medications);

  useEffect(() => {
    if (isOpen) {
      form.resetField('date_range', { defaultValue: defaultValues.date_range });
      form.resetField('notes', { defaultValue: defaultValues.notes });
      form.resetField('sort_by', { defaultValue: defaultValues.sort_by });
    }
  }, [isOpen]);

  useEffect(() => {
    if (params.r) {
      form.setValue('resident_id', params.r);
    }
  }, [params.r]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (values: FormValues) => {
    const response = await dispatch(
      medicationActions.GenerateMedicationPDF({
        residentId: values.resident_id,
        type: 'emar',
        data: values
      })
    );

    if (response.meta.requestStatus === 'rejected') {
      toast({
        title: 'Failed to generate PDF',
        description: 'An error occurred while generating the PDF',
        variant: 'destructive'
      });
      return;
    }

    const blob = new Blob([response.payload], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const pdfWindow = window.open(url);

    if (pdfWindow) {
      pdfWindow.onbeforeunload = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1000);
      };
    } else {
      toast({
        title: 'Warning',
        description: 'Please allow pop-ups to view the PDF',
        variant: 'warning'
      });
    }
  };

  const handleMonthYearSelect = ({ month, year }: { month: number; year: number }) => {
    const selectedDate = set(new Date(), { year, month: month - 1, date: 1 });
    form.setValue('date_range', selectedDate, { shouldValidate: true });
  };

  const handleNotesChanges =
    (name: string, onChange: (value: 'none' | 'end' | 'each_page') => void) =>
    (value: 'none' | 'end' | 'each_page') => {
      onChange(value);
      console.log(`${name} selection:`, value);
      form.setValue('notes', value, { shouldValidate: true });
    };

  return (
    <>
      <CustomDialog isOpen={isOpen} onClose={onClose} title="MAR Details" className="md:max-w-[425px] lg:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="resident_id"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Resident</FormLabel>
                  <FormControl>
                    <ResidentSelector
                      locationId={locationId}
                      residentId={params.r}
                      onChange={(id) => {
                        field.onChange(id);
                        setParam('r', id);
                      }}
                      placeholder="Select a resident"
                      disabled={status === 'loading'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="date_range"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Month</FormLabel>
                  <FormControl>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={'outline'}
                          className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                          disabled={status === 'loading'}
                        >
                          {field.value ? format(field.value, 'MM/yyyy') : <span>MAR date</span>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <YearAndMonthSelect onMonthYearSelect={handleMonthYearSelect} />
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pages of Notes</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={handleNotesChanges('notes', field.onChange)}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="none" />
                        </FormControl>
                        <FormLabel className="font-normal">Do not include Medication Notes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="end" />
                        </FormControl>
                        <FormLabel className="font-normal">Include Medication Notes at the end of the MAR</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="each_page" />
                        </FormControl>
                        <FormLabel className="font-normal">Collate Medication Notes after each MAR page</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="sort_by"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Sort By</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                      disabled={status === 'loading'}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="medication" />
                        </FormControl>
                        <FormLabel className="font-normal">Medication</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="hour" />
                        </FormControl>
                        <FormLabel className="font-normal">Hour</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" variant={'default'} disabled={status === 'loading'} className="w-full">
              {status === 'loading' && <Loader className="mr-2 h-4 w-4 animate-spin" />}
              Generate
            </Button>
          </form>
        </Form>
      </CustomDialog>
    </>
  );
};

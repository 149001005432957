import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent';

interface Props {
  className?: string;
}

export const getFormSchema = () => {
  const { t } = useTranslation();

  return z.object({
    first_name: z
      .string({
        required_error: t('residents.firstNameRequiredError')
      })
      .min(3, {
        message: t('residents.firstNameMin')
      })
      .max(30, {
        message: t('residents.firstNameMax')
      }),
    middle_name: z.string().optional(),
    last_name: z
      .string({
        required_error: t('residents.lastNameRequiredError')
      })
      .min(3, {
        message: t('residents.lastNameMin')
      })
      .max(30, {
        message: t('residents.lastNameMax')
      }),
    preferred_name: z.string().optional(),
    dob: z.date({
      required_error: t('residents.dobRequiredError')
    }),
    place_of_birth: z
      .string({
        required_error: t('residents.placeOfBirthRequiredError')
      })
      .optional(),
    sex: z.string({
      required_error: t('residents.sexRequiredError')
    }),

    gender_identity: z
      .string({
        required_error: t('residents.genderIdentityRequiredError')
      })
      .optional(),
    weight: z.string().optional(),
    height: z.string().optional(),
    eye_color: z.string().optional(),
    hair_color: z.string().optional(),

    spoken_languages: z.string().optional(),

    responsible_for_signing: z.enum(['responsible_party', 'resident'], {
      required_error: 'You need to select a responsible for signing.'
    }),

    phone: z.string().optional(),
    email: z.string().optional()
  });
};

type FormValues = z.infer<ReturnType<typeof getFormSchema>>;

export const PersonalInformationForm = ({ className }: Props) => {
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});
  const { t } = useTranslation();
  const formSchema = getFormSchema();
  const { formatDate } = useDateHelpers();

  const dispatch = useDispatch<AppDispatch>();

  const [age, setAge] = useState(0);


  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const calculateAge = (dob: Date) => {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      residentFormActions.updatePersonalInformationForm({
        resident_id: resident?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: t('residents.updatedInformation')
      });

      form.reset(data);
    }
  };

  useEffect(() => {
    if (form.watch('dob')) {
      setAge(calculateAge(form.watch('dob')));
    }
  }, [form.watch('dob')]);

  useEffect(() => {
    setDefaultValues({
      first_name: resident?.first_name || '',
      middle_name: resident?.middle_name || '',
      last_name: resident?.last_name || '',
      preferred_name: resident?.preferred_name || '',
      dob: new Date(`${resident?.dob} 05:00:00`) || undefined,
      place_of_birth: resident?.place_of_birth || '',
      sex: resident?.sex || undefined,
      gender_identity: resident?.gender_identity || '',
      weight: resident?.weight || '',
      height: resident?.height || '',
      eye_color: resident?.eye_color || '',
      hair_color: resident?.hair_color || '',
      spoken_languages: resident?.spoken_languages || '',
      phone: resident?.other_information?.phone || '',
      email: resident?.other_information?.email || '',
      responsible_for_signing: resident?.other_information?.responsible_for_signing || 'responsible_party'
    });
  }, [resident]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title={t('contacts.personal_information')} openOption={false} defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)} className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('signUp.firstName')} <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="middle_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('residents.middleName')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('signUp.lastName')} <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="preferred_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('residents.likesCalled')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="grid grid-cols-12 gap-2 items-end ">
                  <div className="col-span-9">
                    <FormField
                      control={form.control}
                      name="dob"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('common.dateOfBirth')} <span className="text-destructive">(*)</span>
                          </FormLabel>

                          <CalendarComponent
                            value={field.value}
                            onChange={(dateSelected) => {
                              field.onChange(dateSelected);
                              setAge(calculateAge(dateSelected!));
                            }}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-3 flex flex-col gap-3">
                    <Label>{t('residents.age')}</Label>
                    <Button variant={'outline'} className="w-full" disabled>
                      {age}
                    </Button>
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="place_of_birth"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('residents.placeBirth')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="sex"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('general.sex')} <span className="text-destructive">(*)</span>
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value || undefined}
                        defaultValue={field.value}
                        disabled={residentFormStatus === 'loading'}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a sex" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="male">{t('residents.male')}</SelectItem>
                          <SelectItem value="female">{t('residents.female')}</SelectItem>
                          <SelectItem value="other">{t('calendar.listCategories.other')}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="gender_identity"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('residents.genderIdentity')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={field.value || ''}
                          autoComplete="off"
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="spoken_languages"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('residents.language')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={field.value || ''}
                          autoComplete="off"
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="col-span-full grid grid-cols-1 lg:grid-cols-4 gap-4">
                  <FormField
                    control={form.control}
                    name="eye_color"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('residents.eyeColor')}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={residentFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="hair_color"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('residents.hairColor')}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            value={field.value || ''}
                            disabled={residentFormStatus === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="weight"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('general.weight')}</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ''} disabled={residentFormStatus === 'loading'} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="height"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('general.height')}</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ''} disabled={residentFormStatus === 'loading'} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <Separator className="col-span-full" />

                <div className="col-span-full flex flex-col gap-4">
                  <Label>{t('residents.whoResponsible')}</Label>
                  <div className="grid grid-cols-3 gap-4 items-center">
                    <FormField
                      control={form.control}
                      name="responsible_for_signing"
                      render={({ field }) => (
                        <FormItem className="col-span-3 lg:col-span-1">
                          <FormControl>
                            <RadioGroup
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                              value={field.value || 'responsible_party'}
                              className="grid grid-cols-2 lg:grid-cols-1 gap-4"
                              disabled={residentFormStatus === 'loading'}
                            >
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="responsible_party" />
                                </FormControl>
                                <FormLabel className="font-normal">{t('residents.responsibleParty')}</FormLabel>
                              </FormItem>
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="resident" />
                                </FormControl>
                                <FormLabel className="font-normal">{t('notes.resident')}</FormLabel>
                              </FormItem>
                            </RadioGroup>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="phone"
                      render={({ field }) => (
                        <FormItem className="col-span-3 lg:col-span-1">
                          <FormLabel>{t('contacts.phone')}</FormLabel>
                          <FormControl>
                            <PhoneInput
                              {...field}
                              value={(field.value as any) || ''}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem className="col-span-3 lg:col-span-1">
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input
                              placeholder=""
                              {...field}
                              value={field.value || ''}
                              type="email"
                              disabled={residentFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {form.formState.isDirty && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                      {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      {t('common.update')}
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { IQuestion } from '../../domain/questions.domain';

const Questions = {
  saveOrUpdateQuestion: async (formData: any)=>{
    try {
      const response = await axios.post(`questions/saveOrUpdateQuestion`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error save or update question: ${error.message}`);
    }
  },
  updateOrderData: async (formData: IQuestion[])=>{
    try {
      const response = await axios.post(`questions/updateOrderData`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error save or update question: ${error.message}`);
    }
  },
  getQuestions: async (formData: any) => {
    try {
      const { data } = await axios.post(`questions/getQuestions`, formData);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get questions: ${error.message}`);
    }
  },
  getPublicQuestions: async (formData: any) => {
    try {
      const { data } = await axios.post(`publicQuestions`, formData);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get questions: ${error.message}`);
    }
  },
  deleteQuestion: async (id: string) => {
    try {
      const { data } = await axios.delete(`questions/deleteQuestion/${id}`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error delete question: ${error.message}`);
    }
  }

}

export default Questions;
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { setAlertStatus } from '@/modules/alerts/presentation/slices/AlertSlice';
import { fetchCustomAlerts, fetchStaffAlerts } from '@/modules/staff/presentation/slices/staffMemberSlice';

export const useFetchStaffAlerts = (tabSelected: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const staffId = params.s;
  const staff = staffId && staffId.length > 0 ? [staffId] : null;

  const getAlerts = useCallback(async () => {
    if (!staffId) return;

    try {
      await dispatch(
        fetchCustomAlerts({
          user_id: staff,
          type: 'user'
        })
      ).unwrap();
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
      dispatch(setAlertStatus('idle'));
    },
    [dispatch, staffId]
  );

  const getStaffAlerts = useCallback(
    async () => {
    if (!staffId) return;
      try {
        await dispatch(
          fetchStaffAlerts({
            user_id: staff
          })
        )
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
      dispatch(setAlertStatus('idle'));
    },
    [dispatch, staffId]
  );

  useEffect(() => {
    if (staffId !== null &&  tabSelected === 'dashboard') {
      getAlerts();
      getStaffAlerts();
    }
  }, [dispatch, staffId, tabSelected]);
};

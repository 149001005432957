import { useParams } from 'react-router-dom';
import LicensingRoute from '@/modules/residents/infrastructure/routes/Forms/LicensingRoute';
import { NavigationI } from '@/modules/residents/domain/models/interfaces/Forms/Navigation.domain';
import { useTranslation } from 'react-i18next';

export const ResidentFormPage = () => {
  const { formKey } = useParams<{ formKey: string }>();
  const { t } = useTranslation();

  const formRoute = LicensingRoute.find((form) => form.path === formKey) as NavigationI | undefined;

  if (!formRoute) {
    return <div>{t('forms.formNotFound')}</div>;
  }

  const formConfig = formRoute.sections?.forms;

  if (formConfig?.action === 'navigate' && formConfig.component) {
    const Component = formConfig.component;

    if (formConfig.openInNewTab) {
      window.open(window.location.href, '_blank', 'noopener,noreferrer');
      return null;
    }

    return <Component />;
  }

  return <div>{t('forms.noActionAvailable')}</div>;
};

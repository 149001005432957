interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  profile_url?: string
}

interface IResident {
  id: string;
  first_name: string;
  last_name: string;
  image_url?: string;
}

export const UserHelper = {
  getStaffName: (userId: string, userList: IUser[]): string | null => {
    const user = userList.find((user: IUser) => user.id === userId) ?? null;
    if (user === null) return null;
    return `${user.first_name} ${user.last_name}`;
  },
  getStaffInformation: (userId: string, userList: IUser[]): IUser | null => {
    const user = userList.find((user: IUser) => user.id === userId) ?? null;
    if (user === null) return null;
    return user;
  },
  getResidentName: (residentId: string, residentList: IResident[]) => {
    const resident = residentList.find((user: IUser) => user.id === residentId) ?? null;
    if (resident === null) return null;
    return `${resident.first_name} ${resident.last_name}`;
  },
  getResidentInformation: (residentId: string, residentList: IResident[]) => {
    const resident = residentList.find((user: IUser) => user.id === residentId) ?? null;
    if (resident === null) return null;
    return resident;
  }
};

import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';

interface facilityInformationI {
    openModalMarket: boolean;
    onClose: () => void;
}

export const DialogModalGoToMarket = ({ openModalMarket, onClose }: facilityInformationI) => {
    const { t } = useTranslation();

    return (
        <CustomDialog
            newDialog={true}
            title={''}
            isOpen={openModalMarket}
            onClose={onClose}
        >

            {t('facilities.successEmail')}

            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    className="bg-primary text-white px-4 py-2 rounded"
                    onClick={onClose}
                >
                    Ok
                </Button>
            </div>

        </CustomDialog>
    )
}
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { useState, useEffect } from 'react';
import { ContactsCategoriesCreate } from '../components/PersonalContacts/ContactsCategoriesCreate';
import { getContactsCategoriesPersonal, getContactsCategoriesMedical, getContactsByResident, getResidentById } from '../slices/contactsSlice';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import { Button } from '@/common/presentation/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";

interface ContactInfo {
    [key: string]: {
        category: string;
        company: string;
        name: string;
        last_name: string;
        email: string;
        address: string;
        zipcode: string;
        country: string;
        city: string;
        linkto: string;
    };
}

export const ContactsCreateDetail = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [contactInfo, setContactInfo] = useState<ContactInfo>({});
    const [currentCategoryType, setCurrentCategoryType] = useState<number | null>(null);
    const categories = useSelector((state: RootState) => state.contacts.categories);
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const residentId = searchParams.get('r');
    //console.log('resident:', residentId);
    //console.log('locationPath:', locationPath.state);

    // get resident name based on url id
    useEffect(() => {
        if (residentId) {
            //console.log('hi');
            dispatch(getResidentById(residentId))
                .unwrap()
                .then((resident) => {
                    //console.log('Fetched contact:', resident);
                    // Assuming the response contains the name of the resident
                    setContactInfo(prevState => ({
                        ...prevState,
                        residentName: `${resident.first_name} ${resident.last_name}`
                    }));
                })
                .catch(error => {
                    //console.error('Error fetching resident details:', error);
                });
        }
    }, [dispatch, residentId]);

    useEffect(() => {
        //dispatch(getContactsCategoriesPersonal());
        //dispatch(getContactsCategoriesMedical());
    }, [dispatch]);

    const handleCategorySelect = (type: number) => {
        setCurrentCategoryType(type);
    };

    const handleBackClick = () => {
        // We check the `from` status to decide the redirection
        if (locationPath.state?.from === '/contacts') {
            navigate('/contacts');
        } else if (locationPath.state?.from.startsWith('/residents')) {
            navigate(`/residents/update?r=${residentId}&t=contacts`);
        } else {
            // Default behavior if there is no clear origin
            navigate('/contacts');
        }
    };

    const renderTabs = () => {
        return (
            <>
            <NavigationBar>
                <Button variant="ghost" onClick={handleBackClick}>
                <>
                    <ArrowLeft className="mr-2 h-4 w-4" />
                    {t('contacts.back')}
                </>
                </Button>
            </NavigationBar>
            <Card className="mt-2 mb-2 py-4 px-6 border-t-4 border-t-primary/80">
                <Tabs defaultValue="primary" className="w-full">
                    <TabsList className="w-full h-full grid grid-cols-3 md:grid-cols-3 gap-2">
                        <TabsTrigger value="primary" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white">
                            {t('contacts.personal_legal_contacts')}
                        </TabsTrigger>
                        <TabsTrigger value="other" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white overflow-clip">
                            {t('contacts.medical_dental_contacts')}
                        </TabsTrigger>
                        <TabsTrigger value="medical" className="w-full data-[state=active]:bg-primary data-[state=active]:text-white">
                            {t('contacts.program_employer_social')}
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="primary" className="h-full">
                        <ContactsCategoriesCreate type={1} contactInfo={contactInfo} setContactInfo={setContactInfo} />
                    </TabsContent>
                    <TabsContent value="other" className="h-full">
                        <ContactsCategoriesCreate type={2} contactInfo={contactInfo} setContactInfo={setContactInfo} />
                    </TabsContent>
                    <TabsContent value="medical" className="h-full">
                        <ContactsCategoriesCreate type={3} contactInfo={contactInfo} setContactInfo={setContactInfo} />
                    </TabsContent>
                </Tabs>
            </Card>
            </>
        );
    };

    return (
        <div className="py-4 flex flex-col gap-6">
            <div>
                <h1 className="text-2xl font-semibold">{t('contacts.create_contact')}</h1>
                {contactInfo.residentName && <h2 className="text-xl">{t('contacts.resident')} {contactInfo.residentName}</h2>}
            </div>
            <div>
                {renderTabs()}
            </div>
        </div>
    );
};

import { CrudState } from '@/modules/notes/domain/note.domain';
import { FloatButton } from './floatButton';
import { removeNoteFilter, setNoteFilters } from '../../slices/NoteSlice';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/registry/new-york/ui/tabs';
import { useDispatch } from 'react-redux';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import Title from '@/common/presentation/components/Title/Title';

interface NavigationProps {
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
}

export function Navigation({ setCrudState, crudState }: NavigationProps) {
  // Init values
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasAnyRole, hasRole } = useRoleHelper();
  const { locationSelectedId } = useLocation();

  // Hooks
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (crudState.isCreating || crudState.isUpdating) {
      setSelectedValue(t('notes.notes'));
    } else if (crudState.isCreatingStaffNote || crudState.isUpdatingStaffNote || crudState.showingStaffNotes) {
      setSelectedValue(t('notes.staffNotes'));
    } else if (crudState.isCreatingBeetweenStaffNotes || crudState.isUpdatingBeetweenStaffNotes || crudState.showingBeetweenStaffNotes) {
      setSelectedValue(t('notes.staffBetweenNotes'));
    } else {
      setSelectedValue(t('notes.notes'));
    }
  }, [crudState]);

  useEffect(() => {
    setSelectedValue(t('notes.notes'));
    handleSelectChange(t('notes.notes'))
  }, [locationSelectedId])

  // Behavior
  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
    dispatch(setNoteFilters(['nextPage', 1]));
    if (value === t('notes.notes')) {
      setCrudState(NoteHelperService.defineState({}));
      dispatch(removeNoteFilter('target_type'));
    } else if (value === t('notes.staffNotes')) {
      setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
      dispatch(setNoteFilters(['target_type', 'staff']));
    } else if (value === t('notes.staffBetweenNotes')) {
      setCrudState(NoteHelperService.defineState({ showingBeetweenStaffNotes: true }));
      dispatch(setNoteFilters(['target_type', 'betweenstaff']));
    }
  };

  return (
    <div className='grid grid-cols-12 gap-3 mb-3'>
      <div className='col-span-8 md:col-span-5'>
        <div className="flex justify-between">
          <Title className='text-primary'>{t('notes.notes')}</Title>
          <Tabs value={selectedValue} onValueChange={(e) => handleSelectChange(e)} defaultValue={t('notes.notes')}>
            <TabsList>
              <TabsTrigger
                value={t('notes.notes')}
                className="text-zinc-600 dark:text-zinc-200 data-[state=active]:bg-primary data-[state=active]:text-white"
              >
                {t('notes.residents')}
              </TabsTrigger>
              {!hasRole('Pharmacy') && (
                <TabsTrigger
                  value={t('notes.staffBetweenNotes')}
                  className="text-zinc-600 dark:text-zinc-200 data-[state=active]:bg-primary data-[state=active]:text-white"
                >
                  {t('notes.staffBetweenNotes')}
                </TabsTrigger>
              )}
              {hasAnyRole(['Super Administrator', 'Executive', 'House Manager']) && (
                <TabsTrigger
                  value={t('notes.staffNotes')}
                  className="text-zinc-600 dark:text-zinc-200 data-[state=active]:bg-primary data-[state=active]:text-white"
                >
                  {t('notes.staffNotes')}
                </TabsTrigger>
              )}
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className='col-span-4 md:col-span-7 flex justify-end'>
        {!crudState.isCreating &&
          !crudState.isUpdating &&
          !crudState.isFinishingDraft &&
          !crudState.isUpdatingStaffNote &&
          !crudState.isCreatingStaffNote &&
          !crudState.isCreatingBeetweenStaffNotes &&
          !crudState.isUpdatingBeetweenStaffNotes && (
            <FloatButton selectedValue={selectedValue} setCrudState={setCrudState} crudState={crudState} />
          )
        }
      </div>
    </div>
  );
}

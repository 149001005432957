import { ResidentI } from "@/common/domain/Residents";

export const incidentReportFormDefaults = (resident: ResidentI) => ({
    facility_name: resident?.locations?.[0]?.name || '',
    facility_file_number: resident?.locations?.[0]?.licensing || '',
    facility_phone_number: resident?.locations?.[0]?.phone || '',
    facility_address: resident?.locations?.[0]?.address || '',
    facility_address_details: `${resident?.locations?.[0]?.city ?? ''} ${resident?.locations?.[0]?.state ?? ''} ${resident?.locations?.[0]?.zip ?? ''}`,
    clients_residents: [
      {
        client: '',
        date_occurred: '',
        age: '',
        sex: '',
        date_of_admission: ''
      }
    ],
    incident_types: {},
    incident_description: '',
    observed_by: '',
    immediate_action: '',
    medical_treatment_necessary: '',
    treatment_nature: '',
    administered_where: '',
    administered_by: '',
    follow_up_treatment: '',
    action_taken: '',
    supervisor_comments: '',
    attending_physician_name: '',
    report_submitted_name: '',
    report_submitted_date: '',
    report_approved_name: '',
    report_approved_date: '',
    licensing: '',
    long_term_care: '',
    law_enforcement: '',
    adult_child_protective: '',
    parent_guardian: '',
    placement_agency: '',
});

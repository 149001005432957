import React, { useEffect, useMemo } from 'react';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle
} from '@/common/presentation/components/ui/navigation-menu';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Link as LinkIcon } from 'lucide-react';

interface NavigationBarProps {
  children?: React.ReactNode;
}

export const NavigationBar = ({ children }: NavigationBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { menus = [] } = useSelector((state: RootState) => state?.configurations?.configurations || {});

  const pathItems = useMemo(() => {
    const path = location.pathname;
    const paths = path.split('/').filter((p) => p !== '');

    if (!menus) return null;

    const pathMenu = menus.find((menu) => {
      if (!menu.path) return false;
      const menuPath = menu.path.split('/').filter((p) => p !== '');
      return menuPath[0] === paths[0];
    });    

    return pathMenu?.submenus || null;
  }, [location.pathname, menus]);

  const onMenuClick = (href: string) => {
    navigate(href);
  };

  return (
    <div className="grid gap-6 overflow-auto">
      <NavigationMenu>
        <NavigationMenuList>
          {children}

          {pathItems?.map((menu, index) => (
            <NavigationMenuItem key={index}>
              <NavigationMenuLink
                className={cn(navigationMenuTriggerStyle(), 'hover:cursor-pointer hover:bg-primary/10 border')}
                onClick={() => onMenuClick(menu.path)}
              >
                <LinkIcon className="h-4 w-4" />
                <span className="ml-2">{menu.name}</span>
              </NavigationMenuLink>
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};


//import { RootState } from '@/store/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesTrainingOnSite } from '@/modules/staff/presentation/slices/staffMemberFormSlice.ts';
import { Category } from '@/modules/staff/domain/Training.ts';
import { CategoryCard } from '@/modules/staff/presentation/components/training/CategoryCard.tsx';
import { DialogInService } from '@/modules/staff/presentation/components/training/Dialogs/DialogInService.tsx';
import { AppDispatch } from '@/store/store.ts';

interface IProps {
  staffId: string;
}

export const CEUsForm: React.FC<IProps> = ({ staffId }) => {

  const dispatch = useDispatch<AppDispatch>();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = useCallback(async () => {
    const response = await dispatch(getCategoriesTrainingOnSite());    
    const categories = response.payload;
    setCategories(categories);
  }, []);

  return (
    <>
      {categories.length > 0 && (
        <div className="flex flex-col gap-4">
          {categories
            .map((category) => (
              <CategoryCard key={category.name} category={category} type={'CEUs'} user_id={staffId} />
            ))}
        </div>
      )}
      <DialogInService
        staffId={staffId}
        type={'CEUs'}
      />
    </>
  );
};

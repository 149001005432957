import { fetchPaginateClients } from "@/modules/client/presentation/slices/ClientSlice";
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Pencil } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/common/presentation/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';

interface ClientsI {
    id: string;
    name: string;
    number_beds: number;
    status: 'active' | 'inactive';
}


export const Clients = () => {
    const { t } = useTranslation();
    const [clients, setClients] = useState<ClientsI[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }
    const navigate = useNavigate();
    const { hasAnyRole } = useRoleHelper();

    const isAdmin = hasAnyRole(['Super Administrator']);


    useEffect(() => {
        if (!isAdmin) {
            navigate('/dashboard');
        }
    }, []);

    const dataClients = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true)
        const response = await dispatch(fetchPaginateClients({
            search: searchTerm,
            per_page: perPage,
            page
        }))

        const { clients }: {
            clients: { data: ClientsI[], current_page: number, last_page: number, total: number }
        } = response.payload;

        const data = clients.data
        if (resetData) {
            setClients(data);
        } else {
            setClients(prevData => [...prevData, ...data]);
        }

        if (clients.current_page >= clients.last_page ||
            clients.total < data.length) {
            setHasMoreRows(false);
        } else {
            setHasMoreRows(true);
        }

        setIsLoading(false);
    }

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataClients(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataClients]);

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const renderActions = (row: ClientsI) => {


        const handleEdit = () => {
            navigate(`/facility/client-manage?clientId=${row.id}`);
        };

        return (
            <div className="flex space-x-2">
                <span className="cursor-pointer" onClick={handleEdit}>
                    <Pencil className="mr-2 h-4 w-4" />
                </span>
            </div>
        );
    };

    const columnsreport = [
        { key: 'name', labelTranslationKey: t('trackers.customtype.Name'), value: 'name' },
        { key: 'number_house', labelTranslationKey: t('facilities.numberHouses'), value: 'number_house' },
        {
            key: 'status', labelTranslationKey: t('medications.filterTable.status'),
            value: 'status'
        },
        {
            key: 'actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];


    useEffect(() => {
        setClients([]);
        setCurrentPage(1);
        setHasMoreRows(true);
        dataClients(1, rowsPerPage, true);
    }, [searchTerm]);

    return (
        <div className="p-5">
            <div className="font-semibold text-2xl text-primary">{t('facilities.clients')}</div>
            <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                <TableCustomVirtuoso
                    data={clients}
                    columns={columnsreport}
                    renderCellContent={(index, column, data) => {
                        const row = data[index];
                        switch (column.key) {
                            case 'name':
                                return `${capitalizeWords(row.name)}`;

                            case 'number_house':
                                return row.number_facilities ? `${capitalizeWords(row.number_facilities)}` : '';


                            case 'status':
                                return row.status
                                    ? row.status === 'active'
                                        ? t('medications.discontinuedActive')
                                        : row.status === 'inactive'
                                            ? t('medications.discontinued')
                                            : capitalizeWords(row.status)
                                    : t('medications.discontinuedActive');


                            default:
                                return row[column.key];
                        }
                    }}

                    isLoading={isLoading}
                    filters={
                        <div className="w-1/2">
                            <FiltersTable
                                onFilterChange={handleSearchChange}
                                filterValue={searchTerm}
                                onClear={handleClearFilter}
                            />
                        </div>
                    }
                    loadMore={loadMore}
                    hasMore={hasMoreRows}
                    showSearchInput={false}
                />
            </Card>
        </div>
    )
}
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AllContactsI } from '@/modules/residents/domain/models/Contacts';

export const useFetchAllContacts = (residentId: string | undefined, fetchAction: Function) => {
  const [contacts, setContacts] = useState<AllContactsI[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAllContacts = async () => {
      const allContacts: AllContactsI[] = [];
      let currentPage = 1;
      let hasMore = true;

      try {
        while (hasMore) {
          const response = await dispatch(
            fetchAction({
              residentId,
              page: currentPage,
              per_page: 20,
            })
          ).unwrap();

          const contactsData = response?.payload?.data;
          const total = response?.payload?.total;

          if (Array.isArray(contactsData)) {
            allContacts.push(...contactsData);
            hasMore = allContacts.length < total;
            currentPage += 1;
          } else {
            throw new Error('Unexpected response format');
          }
        }

        setContacts(allContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (residentId) {
      fetchAllContacts();
    }
  }, [residentId, dispatch, fetchAction]);

  return { contacts, isLoading };
};
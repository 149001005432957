import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Button } from '@/components/ui/button';
import AlertCreationDialog from '@/modules/alerts/presentation/components/AlertCreationDialog';
import AlertsList from '@/modules/alerts/presentation/components/AlertsList';
import { CircleFadingPlus } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  staffId: string;
}

const CustomAlertsStaff: React.FC<IProps> = ({ staffId }) => {
  const { t } = useTranslation();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  return (
    <CustomCard
      title={t('customAlerts.customAlerts')}
      description=""
      button={
        <Button
          className="flex flex-row gap-2"
          onClick={() => {
            setOpenCreate(true);
          }}
        >
          <CircleFadingPlus className="h- w-4" />
          {t('customAlerts.create')}
        </Button>
      }
    >
      <AlertCreationDialog
        isOpen={openCreate}
        onClose={() => {
          setOpenCreate(false);
        }}
        relatedTo="user"
      />
      <AlertsList userId={staffId} relatedTo="user" />
    </CustomCard>
  );
};

export default CustomAlertsStaff;

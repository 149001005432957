import { useTranslation } from "react-i18next";

export const NoteSection = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col sm:flex-row sm:items-start sm:gap-4 mb-6">
      <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
        {t('common.note')}:
      </h3>
      <ol className="list-decimal list-inside space-y-2 text-sm text-gray-700 dark:text-gray-300">
        <li>
          {t('residents.forms.releaseMedicalInfo.listNotes.note1')}
        </li>
        <li>
          {t('residents.forms.releaseMedicalInfo.listNotes.note2')}
        </li>
        <li>
          {t('residents.forms.releaseMedicalInfo.listNotes.note3')}
        </li>
        <li>
          {t('residents.forms.releaseMedicalInfo.listNotes.note4')}
        </li>
      </ol>
    </div>
  );
};

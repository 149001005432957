import { cn } from '@/lib/utils';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


interface SelectNoteToBetweenStaffPops {
  handleSelectNoteTo: (e: OptionNoteTo[]) => void;
  note?: NoteI | null;
  locationsSelectedUser: string[];
}

export interface OptionNoteTo {
  value: string;
  label: string;
}

export function SelectNoteToBetweenStaff({
  handleSelectNoteTo,
  note,
  locationsSelectedUser
}: SelectNoteToBetweenStaffPops) {

  const { t } = useTranslation();

  // Tools
  const { locationSelectedId } = useLocation();

  console.log('note', note);

  // Redux
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const departments = useSelector((state: RootState) => state.departments.departments);
  const userId = useSelector((state: RootState) => state.auth.user?.id);

  // Hooks
  const [noteToOptions, setNoteToOptions] = useState<OptionNoteTo[]>([]);
  const [defaultNoteToOptions, setDefaultNoteToOptions] = useState<OptionNoteTo[]>([]);

  // Recipients options
  useEffect(() => {
    const options: OptionNoteTo[] = [];
    if (!locationsSelectedUser.length) {
      setStaff(options);
      setDepartments(options);
    } else {
      setStaff(options, true);
    }

    if (JSON.stringify(noteToOptions) !== JSON.stringify(options)) {
      setNoteToOptions(options);
    }
  }, [locationSelectedId, locations, defaultNoteToOptions, noteToOptions, locationsSelectedUser]);

  useEffect(() => {
    const defaultOptions: OptionNoteTo[] = [];
    // Build default options
    if (note) {

      let staffs: string[] = [];
      if (note.private_user_ids) {
        staffs = note.private_user_ids
      }

      noteToOptions.forEach((option: OptionNoteTo) => {
        let value = option.value.replace('-staff', '').replace('-department', '');

        if (staffs.includes(value)) {
          defaultOptions.push({ value: value + '-staff', label: option.label });
        }

        if (note.department_ids.includes(value)) {
          defaultOptions.push({ value: value + '-department', label: option.label });
        }
      });
    } else {
      defaultOptions.push({
        label: t('notes.allStaff'),
        value: 'all-stafff'
      });
    }

    if (JSON.stringify(defaultNoteToOptions) !== JSON.stringify(defaultOptions)) {
      setDefaultNoteToOptions(defaultOptions);
    }
  }, [noteToOptions]);

  const setDepartments = (options: OptionNoteTo[]) => {
    departments.forEach((element) => {
      let item = {
        value: element.id + '-department',
        label: element.department
      };
      options.push(item);
    });
  };

  const setStaff = (options: OptionNoteTo[], appliedLocation: boolean = false) => {
    options.push({
      label: t('notes.allStaff'),
      value: 'all-stafff'
    });
    staff.forEach((element) => {
      if (userId != element.id) {
        if (appliedLocation) {
          let locationsUser = element.locations.map((locati: any) => locati.id);
          let indexFind = locationsSelectedUser.findIndex(opt => locationsUser.includes(opt))
          if (indexFind >= 0) {
            let item = {
              value: element.id + '-staff',
              label: element.first_name + ' ' + element.last_name
            };
            options.push(item);
          }
        } else {
          let item = {
            value: element.id + '-staff',
            label: element.first_name + ' ' + element.last_name
          };
          options.push(item);
        }
      }
    });
  };

  const customClassNames = {
    control: () =>
      cn(
        '!bg-background !border !border-gray-300 dark:!border-gray-700 !rounded-md focus:!outline-none !shadow-none !min-h-10',
        'max-h-20 overflow-y-auto'
      ),
    menu: () => cn('!bg-background !border !border-gray !z-[999999]'),
    option: ({ isSelected, isFocused }: any) =>
      cn(
        'dark:!text-white dark:hover:!bg-primary/60 hover:!bg-primary/60',
        isSelected ? '!bg-primary text-white' : isFocused ? '!bg-primary/10' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white !rounded flex-shrink-0'),
    multiValueLabel: () => cn('!text-white'),
    multiValueRemove: () => cn('hover:!bg-gray-400 hover:!text-white'),
    valueContainer: () => cn('!flex !flex-row !flex-wrap !gap-1 !overflow-x-auto !py-1 max-h-20'),
    input: () => cn('!text-sm'),
    container: () => cn('!min-h-10')
  };

  const onchange = (selectedOptions: any) => {
    let mutableOptions = [...selectedOptions] as OptionNoteTo[];
    if (mutableOptions.length > 1) {
      let index = mutableOptions.findIndex(it => it.value == 'all-stafff');
      if (index > -1) {
        mutableOptions.splice(index, 1);
      }
    }
    setDefaultNoteToOptions(mutableOptions);
    handleSelectNoteTo(selectedOptions);
  };

  return (
    <Select
      isMulti
      name="colors"
      className="basic-multi-select"
      options={noteToOptions}
      classNamePrefix="select"
      placeholder={t('notes.noteTo')}
      classNames={customClassNames}
      value={defaultNoteToOptions}
      onChange={(selectedOptions, actionMeta) => {
        if (actionMeta.action === 'clear' || (actionMeta.action === 'remove-value' && !selectedOptions.length)) {
          onchange([{
            label: t('notes.allStaff'),
            value: 'all-stafff'
          }]);
        } else if (actionMeta.action === 'select-option' && actionMeta.option?.value === 'all-stafff') {
          onchange([actionMeta.option]);
        } else {
          onchange(selectedOptions);
        }
      }}
    />
  );
}

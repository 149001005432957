import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const FacilityInfo = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="col-span-full sm:col-span-1">
        <FormField
          control={control}
          name="facility_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.nameOfFacility')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='col-span-full sm:col-span-1'>
        <FormField
          control={control}
          name="facility_file_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.facilityFileNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='col-span-full sm:col-span-1'>
        <FormField
          control={control}
          name="facility_phone_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.telephoneNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-full sm:col-span-2">
        <FormField
          control={control}
          name="facility_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('contacts.address')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-full sm:col-span-1">
        <FormField
          control={control}
          name="facility_address_details"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.incidentReport.facilityAddressDetails')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="col-span-full sm:col-span-3">
        <FormField
          control={control}
          name="client_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.clientName')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='col-span-full sm:col-span-1'>
        <FormField
          control={control}
          name="dob"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.dob')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='col-span-full sm:col-span-1'>
        <FormField
          control={control}
          name="sex"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('general.sex')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className='col-span-full sm:col-span-1'>
        <FormField
          control={control}
          name="date_of_admission"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.deathReportLicensee.dateOfAdmission')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default FacilityInfo;

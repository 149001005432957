import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { CertificateAddingCreateI } from '../../domain/training.domain';

const TrainingService = {
  getEnrolledCourses: async () => {
    let url = `/integrations/training/enrollments`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  goToCourse: async (course: string) => {
    let url = `/integrations/training/go-to-course?course_id=${course}`;
    try {
      const response = await axios.get(url);
      return response.data;
    }catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      }else if (error.request) {
        throw new Error('No response received from the server');
      }else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getCategoryTrainingOnSite: async () => {
    let url = `/integrations/training/categories`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  createMultipleTrainingOnSite: async (data: CertificateAddingCreateI) => {
    let url = `/integrations/training/multiple-training`;
    try {
      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  }
};

export default TrainingService;

import { useOutletContext } from 'react-router-dom';
import CoursesList from '../components/CoursesList';
import { useSystemStyle } from '@/common/presentation/components/layout/hooks/useSystemStyle';
import { useEffect } from 'react';
import CourseCompletionRequirements from '../components/CourseCompletionRequirements';
import { useTranslation } from 'react-i18next';

interface OutletContext {
  module: string | null;
}

const TrainingPage = () => {
  const { module } = useOutletContext<OutletContext>();
  const { setLayoutPadding, setCollapseSidebar } = useSystemStyle();
  const { t } = useTranslation();

  useEffect(() => {
    setLayoutPadding(false);
    setCollapseSidebar(true);
    return () => {
      setLayoutPadding(true);
      setCollapseSidebar(false);
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div
        className="bg-cover bg-center py-6 min-h-96 w-full relative 
        bg-primary/15 backdrop-brightness-75 backdrop-filter"
        style={{ backgroundImage: `url('/src/assets/img/training.svg')` }}
      >
        <div className="absolute inset-0 bg-primary/10"></div>
        <div className="px-5 md:px-28 h-full relative z-10 flex flex-col gap-5">
          <div className="flex flex-col justify-start gap-4">
            <p className="text-white font-semibold text-xl md:text-2xl">{t('training.welcome_to_your')}</p>
            <div className="flex items-center">
              <p className="text-white text-3xl md:text-4xl font-bold">{t('training.rcfe_orientation_training')}</p>
            </div>
          </div>
          <div className="mt-2">
            <CourseCompletionRequirements />
          </div>
        </div>
      </div>
      <div className="px-5 md:px-28">
        <CoursesList />
      </div>
    </div>
  );
};

export default TrainingPage;

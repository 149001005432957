import { toast } from '@/common/presentation/components/ui/use-toast';
import { FormDataT } from '@/modules/residents/domain/models/interfaces/Forms/Licensing.domain';
import { generatePDF } from '@/modules/residents/domain/utils/pdfUtils';
import { GeneratePdfRequest, FormResponseBase } from '../../models/interfaces/Forms/forms.domain';

const handleDownloadPDF = async ({
  documentKey,
  generatePdf,
  pdfData,
}: {
  documentKey: string;
  generatePdf: (payload: GeneratePdfRequest) => Promise<FormResponseBase>;
  pdfData: FormDataT;
}) => {
  try {
    const payload = {
      documentKey,
      pdfData
    };

    const { pdf_data } = await generatePdf(payload);

    if (pdf_data) {
      const base64Data = pdf_data.replace(/^data:application\/pdf;base64,/, '');
      generatePDF(base64Data);
    }

    toast({ description: 'PDF generated successfully!', variant: 'default' });
  } catch (error: any) {
    toast({
      description: `Error generating PDF: ${error.message}`,
      variant: 'destructive',
    });
    console.error("Error generating PDF:", error);
  }
};

export default handleDownloadPDF;

import { FileTextIcon, UploadCloudIcon, X } from 'lucide-react';
import * as React from 'react';
import { useDropzone, type DropzoneOptions } from 'react-dropzone';
import { FaRegFileExcel, FaRegFilePdf, FaRegFileWord } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';

const variants = {
  base: 'relative rounded-md flex flex-wrap justify-center items-center cursor-pointer min-h-[150px] min-w-[200px] border border-dashed border-gray-400 dark:border-gray-300 transition-colors duration-200 ease-in-out',
  image: 'border-0 p-0 min-h-0 min-w-0 relative shadow-md bg-slate-200 dark:bg-slate-900 rounded-md',
  active: 'border-2',
  disabled: 'bg-gray-200 border-gray-300 cursor-default pointer-events-none bg-opacity-30 dark:bg-gray-700',
  accept: 'border border-blue-500 bg-blue-500 bg-opacity-10',
  reject: 'border border-red-700 bg-red-700 bg-opacity-10'
};

type InputProps = {
  width?: number;
  height?: number;
  className?: string;
  value?: File[];
  onChange?: (files?: File[]) => void | Promise<void>;
  disabled?: boolean;
  dropzoneOptions?: Omit<DropzoneOptions, 'disabled'>;
};

const ERROR_MESSAGES = {
  fileTooLarge(maxSize: number) {
    return `The file is too large. Max size is ${formatFileSize(maxSize)}.`;
  },
  fileInvalidType() {
    return 'Invalid file type.';
  },
  tooManyFiles(maxFiles: number) {
    return `You can only add ${maxFiles} file(s).`;
  },
  fileNotSupported() {
    return 'The file is not supported.';
  }
};

// const PreviewIcon = ({ file }: { file: File }) => {
//   if (file.type.startsWith('image/')) {
//     return <img className="object-cover w-full h-full rounded-md" src={URL.createObjectURL(file)} alt={file.name} />;
//   }
//   if (file.type === 'application/pdf') {
//     return <PDFThumbnail file={file} />;
//   }
//   if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
//     return <WordThumbnail file={file} />;
//   }
//   if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//     return <ExcelThumbnail file={file} />;
//   }
//   return <FileTextIcon className="text-gray-500 w-full h-full" />;
// };

const PreviewIcon = ({ file }: { file: File }) => {
  if (file.type.startsWith('image/')) {
    return <img className="object-cover w-auto h-12 rounded-md" src={URL.createObjectURL(file)} alt={file.name} />;
  }
  if (file.type === 'application/pdf') {
    return <FaRegFilePdf className="text-gray-500 w-auto h-12" />;
  }
  if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <FaRegFileWord className="text-gray-500 w-auto h-12" />;
  }

  if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <FaRegFileExcel className="text-gray-500 w-auto h-12" />;
  }

  return <FileTextIcon className="text-gray-500 w-auto h-12" />;
};

const MultiFileDropzone = React.forwardRef<HTMLInputElement, InputProps>(
  ({ dropzoneOptions, width, height, value, className, disabled, onChange }, ref) => {
    const onRemove = (file: File) => {
      const newFiles = value?.filter((f) => f !== file);
      void onChange?.(newFiles);
    };

    // dropzone configuration
    const { getRootProps, getInputProps, acceptedFiles, fileRejections, isFocused, isDragAccept, isDragReject } =
      useDropzone({
        accept: {
          'image/jpeg': [],
          'image/jpg': [],
          'image/png': [],
          'image/webp': [],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
        },
        multiple: true,
        disabled,
        onDrop: (acceptedFiles) => {
          const newFiles = [...(value || []), ...acceptedFiles];
          void onChange?.(newFiles);
        },
        ...dropzoneOptions
      });

    // styling
    const dropZoneClassName = React.useMemo(
      () =>
        twMerge(
          variants.base,
          isFocused && variants.active,
          disabled && variants.disabled,
          (isDragReject ?? fileRejections[0]) && variants.reject,
          isDragAccept && variants.accept,
          className
        ).trim(),
      [isFocused, fileRejections, isDragAccept, isDragReject, disabled, className]
    );

    // error validation messages
    const errorMessage = React.useMemo(() => {
      if (fileRejections[0]) {
        const { errors } = fileRejections[0];
        if (errors[0]?.code === 'file-too-large') {
          return ERROR_MESSAGES.fileTooLarge(dropzoneOptions?.maxSize ?? 0);
        } else if (errors[0]?.code === 'file-invalid-type') {
          return ERROR_MESSAGES.fileInvalidType();
        } else if (errors[0]?.code === 'too-many-files') {
          return ERROR_MESSAGES.tooManyFiles(dropzoneOptions?.maxFiles ?? 0);
        } else {
          return ERROR_MESSAGES.fileNotSupported();
        }
      }
      return undefined;
    }, [fileRejections, dropzoneOptions]);

    return (
      <div className="relative">
        {disabled && (
          <div className="absolute inset-y-0 flex items-center justify-center h-full w-full bg-background/80 z-50">
            <Spinner size={'lg'} />
          </div>
        )}
        <div
          {...getRootProps({
            className: dropZoneClassName,
            style: {
              width,
              height
            }
          })}
        >
          {/* Main File Input */}
          <input ref={ref} {...getInputProps()} />

          {value && value.length > 0 ? (
            <div className="flex flex-wrap gap-2 ">
              {value.map((file, index) => (
                <div key={index} className="relative">
                  <div
                    className="inset-0 flex items-center justify-center rounded-full bg-gray-100 shadow-sm"
                    style={{ width: '25px', height: '25px', padding: '2px' }}
                  >
                    <PreviewIcon file={file} />
                  </div>
                  <div
                    className="absolute -top-2 -right-2 p-1 cursor-pointer bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(file);
                    }}
                  >
                    <X className="text-gray-500" width={12} height={12} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Upload Icon
            <div className="flex flex-row items-center justify-center text-xs text-gray-400 ">
              <UploadCloudIcon className="size-5 mr-2" />
              <div className="text-gray-400">Click or drag files to this area to upload</div>
            </div>
          )}

          {/* Error Text */}
          <div className="mt-1 text-xs text-red-500">{errorMessage}</div>
        </div>
      </div>
    );
  }
);
MultiFileDropzone.displayName = 'MultiFileDropzone';

function formatFileSize(bytes?: number) {
  if (!bytes) {
    return '0 Bytes';
  }
  bytes = Number(bytes);
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export { MultiFileDropzone };

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { fetchCourseAdditional, getGroupsTraining } from '@/modules/staff/presentation/slices/staffSlice.ts';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect.tsx';

interface Props {
  className?: string;
}

const formSchema = z.object({
  group_training_id: z.string().optional(),
  additional_training: z
    .array(
      z.object({
        label: z.string(),
        value: z.string()
      })
    )
    .optional()
});

type FormValues = z.infer<typeof formSchema>;

const defaultValues: Partial<FormValues> = {
  group_training_id: '',
  additional_training: []
};

export const TrainingForm = ({ className }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);

  const [groups, setGroups] = useState<any[]>([]);
  const [coursesAdditional, setCoursesAdditional] = useState<any[]>([]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  useEffect(() => {
    getGroups();
    getCourseAdditional();
  }, []);

  useEffect(() => {
    if (staffMember) {
      form.reset({});
    }
  }, [staffMember]);

  const getGroups = useCallback(async () => {
    const response = await dispatch(getGroupsTraining(staffMember?.id)).unwrap();
    if (response) {
      setGroups(response);
    }
  }, [staffMember]);

  const getCourseAdditional = useCallback(async () => {
    const response = await dispatch(fetchCourseAdditional(staffMember?.id)).unwrap();
    if (response) {
      setCoursesAdditional(
        response.map((course: any) => ({
          label: course.name || '',
          value: course.id || ''
        }))
      );
    }
  }, [staffMember]);

  const onSubmitForm = async (data: FormValues) => {
    try {
      await dispatch(
        staffMemberFormActions.updateStaffTrainingForm({
          user_id: staffMember?.id,
          ...data
        })
      ).unwrap();
      toast({
        description: 'Staff training updated successfully',
        className: 'bg-green-500 text-white'
      });
    } catch (error) {
      toast({
        description: 'Error updating training information',
        className: 'text-white',
        variant: 'destructive'
      }); 
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Traning Form">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div>
                    <FormField
                      control={form.control}
                      name="group_training_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Training Group</FormLabel>
                          <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a group" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {groups.map((group) => (
                                <SelectItem key={group.id} value={group.id}>
                                  {group.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="additional_training"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Additional Training Content</FormLabel>
                          <FormControl>
                            <MultiSelect
                              name="additional_training"
                              options={coursesAdditional}
                              selectedOptions={field.value || []}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              placeHolder="Select additional course"
                              className="w-full mt-1"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                    <>
                      <Separator className="col-span-full" />
                      <div className="col-span-full flex justify-end">
                        <Button
                          type="submit"
                          className="text-sm px-4 py-2 w-auto"
                          disabled={staffFormStatus === 'loading'}
                        >
                          {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                          Update
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

import { useTranslation } from "react-i18next";
import { CardContent, CardHeader } from "@/components/ui/card";
import { CustomCard } from "../../shared/CustomCard";
import FormHeader from "../../shared/FormHeader";
import { IdentificationForm } from "./IdentificationForm";

const IdentificationEmergencyInfo = () => {
  const { t } = useTranslation();

  return (
    <CustomCard>
      <CardHeader className="p-0">
        <FormHeader
          leftContent={
            <>
               <p className='uppercase'>
                {t("residents.forms.formHeader.stateOfCalifornia")}
              </p>
              <p className='uppercase'>
                {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
              </p>
            </>
          }
          rightContent={
            <>
              <p className='uppercase'>
                {t("residents.forms.formHeader.californiaDeptSocialServices")}
              </p>
              <p className='uppercase'>
                {t("residents.forms.formHeader.communityCareLicensingDivision")}
              </p>
            </>
          }
        />
      </CardHeader>
      <CardContent className="p-0 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
          <div className="flex flex-col justify-start text-left">
            <h2 className="text-xl font-bold uppercase">
              {t("residents.forms.lic601EmergencyInfo.title")}
            </h2>
          </div>
            <p className="text-sm text-zinc-700 text-justify dark:text-white leading-relaxed italic">
              {t("residents.forms.lic601EmergencyInfo.description")}
            </p>
          </div>
          <IdentificationForm />
      </CardContent>
    </CustomCard>
  );
};

export default IdentificationEmergencyInfo;

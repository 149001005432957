import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ContactsService from "../../infrastructure/services/ContactsService";
import ContactsCategoriesService from "../../infrastructure/services/ConstactsCategoriesService";
import { IAccount, ICountry, IService } from "../../../wizard/domain/signup.domain";
import { IContactsResumeData }  from "../../presentation/components/PersonalContacts/ContactsSchema";

interface IState {
  countries: Array<ICountry>;
  services: IService | null;
  contacts: Array<any>;
  allContacts: Array<any>;
  categories: Array<any>;
  phones: Record<string, any>; 
  totalContacts: number;
  isLoading: boolean;
  relationship: Array<IRelationship>;
  validateName: string;
  specialty: Array<ISpecialty>;
}

const initialState: IState = {
  contacts: [],
  allContacts: [],
  categories: [],
  phones: {},
  countries: [],   
  services: null,
  totalContacts: 0,
  isLoading: false,
  relationship: [],
  validateName: '',
  specialty: [],
};
interface IRelationship {
  id: string;
  value: string;
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
}

interface ISpecialty {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async ({ page, per_page, filters }: { page: number, per_page: number, filters?: IContactsResumeData }) => {
    try {
      const response = await ContactsService.getContacts(page, per_page, filters);
      return response; // Devuelve la respuesta de la API
    } catch (error) {
      throw error;
    }
  }
);

export const getContactsByResident = createAsyncThunk(
  'contacts/getContactsByResident',
  async ({ residentId, page, per_page, filters }: { residentId: string, page: number, per_page: number, filters?: IContactsResumeData  }) => {
    try {
      const response = await ContactsService.getContactsByResident(residentId, page, per_page, filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getResidentById = createAsyncThunk('contacts/getResidentById', async (residentId: string) => {
  try {
    const response = await ContactsService.getResidentById(residentId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getListPhonesById = createAsyncThunk('contacts/getListPhonesById', async (contactId: string) => {
  try {
    const response = await ContactsService.getListPhonesById(contactId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const getContactsCategoriesPersonal = createAsyncThunk('contacts/getContactsCategoriesPersonal', async () => {
  try {
    const response = await ContactsCategoriesService.getContactsCategoriesPersonal();
    return response.payload;
  } catch (error) {
    throw error;
  }
});

export const getListPhonesItems = createAsyncThunk('contacts/getListPhonesItems', async () => {
  try {
    const response = await ContactsService.getListPhonesItems();
    //console.log('API response phone items:', response);
    return response.payload;

  } catch (error) {
    throw error;
  }
});

export const getContactsCategoriesMedical = createAsyncThunk('contacts/getContactsCategoriesMedical', async () => {
  try {
    const response = await ContactsCategoriesService.getContactsCategoriesMedical();
    return response.payload;
  } catch (error) {
    throw error;
  }
});

export const createContact = createAsyncThunk('contacts/createContact', async (data: any) => {
  try {
    const response = await ContactsCategoriesService.createContact(data);
    return response;
  } catch (error: any) {
    const message = error.response?.data?.message;
    throw new Error(message !== undefined ? message : 'Error creating contact: ' + error.message);
  }
});

export const createPhones = createAsyncThunk('contacts/createPhones', async (data: any) => {
  try {
    const response = await ContactsService.createPhones(data);
    return response.data;  // Asegúrate de devolver la respuesta correcta
  } catch (error: any) {
    const message = error.response?.data?.message;
    throw new Error(message !== undefined ? message : 'Error creating phones: ' + error.message);
  }
});

export const updateContact = createAsyncThunk('contacts/updateContact', async ({ id, data }) => {
  try {
    const response = await ContactsCategoriesService.updateContact(id, data);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error updating contact');
  }
});

export const getContactById = createAsyncThunk('contacts/getContactById', async (id: string) => {
  try {
    const response = await ContactsService.getContactById(id);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching contact by ID');
  }
});

export const updatePhones = createAsyncThunk('contacts/updatePhones', async (data: any) => {
  try {
    const response = await ContactsService.updatePhones(data);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error updating phones');
  }
});

export const deleteContact = createAsyncThunk('contacts/deleteContact', async (contactId: string) => {
  try {
    const response = await ContactsService.deleteContact(contactId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const deletePhone = createAsyncThunk('contacts/deletePhone', async (phoneId: string) => {
  try {
    const response = await ContactsService.deletePhone(phoneId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const deletePhonesByContactId = createAsyncThunk('contacts/deletePhonesByContactId', async (contactId: string) => {
  try {
    const response = await ContactsService.deletePhonesByContactId(contactId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchInitialData = createAsyncThunk('contacts/getInitialData', async (country: string) => {
  const response = await ContactsService.getInitialData(country);
  //console.log('API response:', response);  // Verifica que el response contenga los países
  return response;
});

export const fetchGeoLocation = createAsyncThunk('contacts/getGeoLocation', async () => {
  const response = await ContactsService.getGeoLocationClient();
  return response;
});

export const getRelationship = createAsyncThunk('contacts/getRelationship', async () => {
  try {
    const response = await ContactsService.getRelationship();
    //console.log('API response relationship items:', response);
    return response.payload;

  } catch (error) {
    throw error;
  }
});

export const getPhysicianSpecialty = createAsyncThunk('contacts/getPhysicianSpecialty', async () => {
  try {
    const response = await ContactsService.getPhysicianSpecialty();
    //console.log('API response Specialty items:', response);
    return response.payload;

  } catch (error) {
    throw error;
  }
});

export const validateName = createAsyncThunk(
  'contacts/validateName',
  async ({ name, type }: { name: string; type: string }) => {
      try {
          const response = await ContactsService.validateName(name, type);
          return response;
      } catch (error) {
          throw error;
      }
  }
);

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
      .addCase(getContacts.fulfilled, (state, action) => {
        if (action.payload.result === 'ok') {
          state.contacts = action.payload.payload.data;
          state.allContacts = action.payload.payload.data; // Store all contacts permanently
          state.totalContacts = action.payload.payload.total;
        }
      })
      .addCase(getContactsByResident.pending, (state) => {
        state.isLoading = true; // Displays charging status
      })
      .addCase(getContactsByResident.fulfilled, (state, action) => {
        if (action.payload.result === 'ok') {
          state.contacts = action.payload.payload.data;
          state.totalContacts = action.payload.payload.total;
          state.isLoading = false; // Charging status completed
        } else {
          state.contacts = [];
          state.isLoading = false;
        }
      })
      .addCase(getContactsByResident.rejected, (state) => {
        state.isLoading = false; // En caso de error, detener el estado de carga
      })
      .addCase(getResidentById.fulfilled, (state, action) => {
        if (action.payload.result === 'ok') {
          state.contacts = action.payload.payload;
        }
      })
      .addCase(getListPhonesById.fulfilled, (state, action) => {
        state.phones[action.meta.arg] = Array.isArray(action.payload) ? action.payload : []; // Almacena los teléfonos en el estado
      })
      .addCase(getContactsCategoriesPersonal.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getListPhonesItems.fulfilled, (state, action) => {
        state.categories = action.payload;
        console.log('Contact phones items successfully:', action.payload);
      })
      .addCase(getContactsCategoriesMedical.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        //console.log('Contact created successfully:', action.payload);
      })
      .addCase(createContact.rejected, (state, action) => {
        //console.error('Failed to create contact:', action.error.message);
      })
      .addCase(createPhones.fulfilled, (state, action) => {
        const contactId = action.meta.arg.contact_id;
        if (state.phones[contactId]) {
          state.phones[contactId].push(action.payload);
        } else {
          state.phones[contactId] = [action.payload];
        }
      })
      .addCase(createPhones.rejected, (state, action) => {
        //console.error('Failed to create Phones:', action.error.message);
      })
      .addCase(updateContact.fulfilled, (state, action) => {
      })
      .addCase(updateContact.rejected, (state, action) => {
        //console.error('Failed to update contact:', action.error.message);
      })
      .addCase(getContactById.fulfilled, (state, action) => {
      })
      .addCase(updatePhones.fulfilled, (state, action) => {
        // Actualizar el estado de los teléfonos si es necesario
      })
      .addCase(updatePhones.rejected, (state, action) => {
        //console.error('Failed to update phones:', action.error.message);
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.contacts = state.contacts.filter(contact => contact.id !== action.meta.arg);
      })
      .addCase(deletePhone.fulfilled, (state, action) => {
        const contactId = action.meta.arg.contactId;
        const phoneId = action.meta.arg.phoneId;
        if (state.phones[contactId]) {
          state.phones[contactId] = state.phones[contactId].filter(phone => phone.id !== phoneId);
        }
      })
      .addCase(deletePhonesByContactId.fulfilled, (state, action) => {
        const contactId = action.meta.arg;
        state.phones[contactId] = [];
      })
      .addCase(fetchInitialData.fulfilled, (state, action) => {
        state.countries = action.payload.countries;
      })
      .addCase(getRelationship.fulfilled, (state, action) => {
        state.relationship = action.payload;
        //console.log('Contact relationship items successfully:', action.payload);
      })
      .addCase(getPhysicianSpecialty.fulfilled, (state, action) => {
        state.specialty = action.payload;
        //console.log('Contact Specialties items successfully:', action.payload);
      })
      .addCase(validateName.fulfilled, (state, action) => {
          state.isLoading = false;
          state.validateName = action.payload.message; // We use the API message
      })
      .addCase(fetchGeoLocation.fulfilled, (state, action) => {});
  },
});

export default contactsSlice.reducer;
import React from 'react';
import { Loader2, Save, FileDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

interface FormActionButtonsProps {
  handleFormSubmit: (generatePdf: boolean) => void;
  formsStatus: 'idle' | 'loading' | 'failed';
}

const FormActionButtons: React.FC<FormActionButtonsProps> = ({ handleFormSubmit, formsStatus }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:justify-center md:space-y-0 md:space-x-4 mt-4">
      <Button
        onClick={() => handleFormSubmit(false)}
        variant="default"
        className="w-auto px-6 py-2 dark:text-white"
        disabled={formsStatus === 'loading'}
      >
        {formsStatus === 'loading' ? (
          <Loader2 className="mr-2 size-4 animate-spin" />
        ) : (
          <Save className="size-4 mr-2" />
        )}
        {t('residents.forms.saveForm')}
      </Button>
      <Button
        onClick={() => handleFormSubmit(true)}
        variant="default"
        className="w-auto px-6 py-2 dark:text-white"
        disabled={formsStatus === 'loading'}
      >
        {formsStatus === 'loading' ? (
          <Loader2 className="mr-2 size-4 animate-spin" />
        ) : (
          <FileDown className="size-4 mr-2" />
        )}
        {t('residents.forms.saveAndGeneratePdf')}
      </Button>
    </div>
  );
};

export default FormActionButtons;

import { t } from "i18next";
import TrackerVisitorsRenderActionsColumn from "./TrackerVisitorsRenderActionsColumn";
import { format } from "date-fns";

const TrackerVisitorsReportColumns = (fetchSummary: (page: number, resetData: boolean) => void) => {
  const HygineTypeColumns = [
    {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'common.date',
        render: (row) => format(new Date(JSON.parse(row.data)[0].date), "dd/MM/yyyy hh:mm a"),
        value: 'date',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'regardingTo',
        id: 'regardingTo',
        labelTranslationKey: 'visitors.regardingTo',
        render: (row) => JSON.parse(row.data)[0].regardingTo,
        value: 'regardingTo',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'visitor',
        id: 'visitor',
        labelTranslationKey: 'visitors.visitor',
        render: (row) => JSON.parse(row.data)[0].visitor,
        value: 'visitor',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
        key: 'temperature',
        id: 'temperature',
        labelTranslationKey: 'visitors.temperature',
        render: (row) => JSON.parse(row.data)[0].temperature,
        value: 'temperature',
        visible: true,
        sortable: true,
        width: '20%'
    },
    {
      key: 'approved',
      id: 'approved',
      labelTranslationKey: 'common.approved',
      render: (row) => JSON.parse(row.data)[0].approved ? t("common.yes") : t("common.no"),
      value: 'approved',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: (row) => TrackerVisitorsRenderActionsColumn(row, fetchSummary),
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(HygineTypeColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = HygineTypeColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default TrackerVisitorsReportColumns;

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import QuickTracker from '@/common/presentation/components/QuickTracker/QuickTracker';
import { useTranslation } from 'react-i18next';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Card } from '@/common/presentation/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { CircleFadingPlus } from "lucide-react";
import { TrackerSleepDetail } from '../components/Sleep/TrackerSleepDetail';
import { TrackerSleepQuickSummary } from '../components/Sleep/TrackerSleepQuickSummary';
import { TrackerSleepNapSummary } from '../components/Sleep/TrackerSleepNapSummary';
import { getTrackerNapSleep, getTrackerSleep, getDayTrackerSleep, saveTrackerSleep, updateTrackerSleep } from "../slices/TrackerSleepSlice";
import QuickTrackerItem from '@/common/presentation/components/QuickTracker/interfaces/QuickTrackerItemInterface';
import { TrackerI, TrackerSleepI } from '../../infrastructure/interfaces/SleepInterface';
import { toast } from '@/common/presentation/components/ui/use-toast';

interface SleepTypeI {
    id: string;
    type_name?: string;
    name: string;
}


export default function TrackerSleep() {

    const { t } = useTranslation();

    const { locationSelectedId } = useLocation();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedTab, setSelectedTab] = useState<string>("tracker");
    const [selectedReport, setSelectedReport] = useState<string>("sleep");
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [dataNapReport, setDataNapReport] = useState<TrackerI[]>([]);
    const [dataReport, setDataReport] = useState<TrackerSleepI[]>([]);
    const [dataDayReport, setDataDayReport] = useState<TrackerSleepI[]>([]);
    const [selectedOption, setSelectedOption] = useState<string>(params.e || 'form');
    const onSelectOption = (e: string) => {
        setParam('e', e);
        setSelectedOption(e);
    };
    const [isNapLoading, setIsNapLoading] = useState<boolean>(false);
    const [isSleepLoading, setIsSleepLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { residents } = useSelector((state: RootState) => state.residents.allResidents);
    const [sleepTypes, setSleepTypes] = useState<SleepTypeI[]>([]);


    const onResidentSelect = (resident: string) => {
        setParam('r', resident);
    };

    const onSelectTab = (e: string) => {

        setSelectedTab(e);
    };

    const onSelectReport = (e: string) => {

        setSelectedReport(e);
    };

    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load


    const dataSleepNapReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsNapLoading(true);
        const response = await dispatch(getTrackerNapSleep({
            location_id: locationSelectedId,
            resident_id: params.r,
            per_page: perPage,
            page
        }));

        if (response.payload) {
            try {
                const { trackerNaps }: {
                    trackerNaps: { data: TrackerI[], current_page: number, last_page: number, total: number }
                } = response.payload;

                const data = trackerNaps.data;
                if (resetData) {
                    setDataNapReport(data);
                    setDataOriginal(data);
                } else {
                    setDataNapReport(prevData => [...prevData, ...data]);
                    setDataOriginal(prevData => [...prevData, ...data]);
                }

                setHasMoreRows(trackerNaps.current_page < trackerNaps.last_page && trackerNaps.total >= data.length)

            } catch (error: any) {
                console.log(error);
            } finally {
                setIsNapLoading(false);
            }
        }

    }

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isNapLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataSleepNapReport(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataSleepNapReport]);

    const [dataOriginal, setDataOriginal] = useState<TrackerI[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [dataSleepOriginal, setDataSleepOriginal] = useState<TrackerSleepI[]>([]);
    const [isSleepInitialized, setIsSleepInitialized] = useState<boolean>(false);

    useEffect(() => {

        if (selectedOption !== "report") {
            return;
        }

        if (selectedReport === "sleep") {
            if (!isSleepInitialized && dataReport.length > 0) {
                setDataSleepOriginal(dataReport);
                setIsSleepInitialized(true);
            }

            if (dataSleepOriginal.length > 0 || dataReport.length > 0) {
                let updatedData = dataSleepOriginal.length > 0 ? dataSleepOriginal : dataReport;

                if (params.r === "all-residents") {
                    if (locationSelectedId !== "all-locations") {
                        updatedData = updatedData.filter((item: TrackerSleepI) => item.data.location_id === locationSelectedId);
                    }
                } else {
                    updatedData = updatedData.filter((item: TrackerSleepI) => {
                        if (locationSelectedId === "all-locations") {
                            return item.resident_id === params.r;
                        }
                        return item.resident_id === params.r && item.data.location_id === locationSelectedId;
                    });
                }

                if (JSON.stringify(updatedData) !== JSON.stringify(dataReport)) {
                    setDataReport(updatedData);
                }
            }
        }

        if (selectedReport === "nap") {
            // Handle initialization and filtering for nap
            if (!isInitialized && dataNapReport.length > 0) {
                setDataOriginal(dataNapReport); // Save a copy of all data initially
                setIsInitialized(true);
            }

            // Applies filtering to nap data when it is already loaded
            if (dataOriginal.length > 0 || dataNapReport.length > 0) {
                let updatedData = dataOriginal.length > 0 ? dataOriginal : dataNapReport;

                if (params.r === "all-residents") {
                    if (locationSelectedId !== "all-locations") {
                        updatedData = updatedData.filter((item: TrackerI) => item.location_id === locationSelectedId);
                    }
                } else {
                    updatedData = updatedData.filter((item: TrackerI) => {
                        if (locationSelectedId === "all-locations") {
                            return item.data.resident.id === params.r;
                        }
                        return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                    });
                }

                // Only update if data changed
                if (JSON.stringify(updatedData) !== JSON.stringify(dataNapReport)) {
                    setDataNapReport(updatedData);
                }
            }
        }

    }, [params.r, locationSelectedId, dataSleepOriginal, dataReport, dataOriginal, dataNapReport, selectedOption, selectedReport]);

    useEffect(() => {

        if(selectedReport === 'nap') {
            setDataNapReport([]);
            setDataOriginal([]);
            setCurrentPage(1);
            setHasMoreRows(true);
            dataSleepNapReport(1, rowsPerPage, true);
        } else {
            setDataReport([]);
            setDataSleepOriginal([]);
            setSleepCurrentPage(1);
            setSleepHasMoreRows(true);
            dataSleepReport(1, rowsSleepPerPage, true);
        }

       
    }, [locationSelectedId, params.r, selectedOption, selectedReport]);


    //Sleep report

    const [currentSleepPage, setSleepCurrentPage] = useState(1); // Current page
    const [rowsSleepPerPage, setSleepRowsPerPage] = useState(20); // Data per page
    const [hasSleepMoreRows, setSleepHasMoreRows] = useState(true); // Indicates if there are more rows to load

    const dataSleepReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsSleepLoading(true);
        const response = await dispatch(getTrackerSleep({
            location_id: locationSelectedId,
            resident_id: params.r,
            per_page: perPage,
            page
        }));

        if (response.payload) {
            try {
                const { trackerSleeps }: {
                    trackerSleeps: { data: TrackerSleepI[], current_page: number, last_page: number, total: number }
                } = response.payload;

                const data = trackerSleeps.data;
                if (resetData) {
                    setDataReport(data);
                    setDataSleepOriginal(data);
                } else {
                    setDataReport(prevData => [...prevData, ...data]);
                    setDataSleepOriginal(prevData => [...prevData, ...data]);
                }

                setHasMoreRows(trackerSleeps.current_page < trackerSleeps.last_page && trackerSleeps.total >= data.length)


            } catch (error: any) {
                console.log(error);
            }
        }
        setIsSleepLoading(false);
    }

    const loadSleepMore = useCallback(() => {
        if (!hasSleepMoreRows || isSleepLoading) return;
        setSleepCurrentPage(currentSleepPage + 1);
        const nextPage = currentSleepPage + 1;
        dataSleepNapReport(nextPage, rowsSleepPerPage);
    }, [hasSleepMoreRows, currentSleepPage, dataSleepReport]);


    const desiredOrder = [
        "Asleep",
        "Awake",
        "Start Up Nap",
        "End Up Nap",
        "Awake during night start",
        "Awake during night end"
    ];

    const dataDaySleepReport = async () => {
        const response = await dispatch(getDayTrackerSleep({
            location_id: locationSelectedId,
            resident_id: params.r,
        }));

        if (response.payload) {
            try {
                const { trackerDaySleeps, sleepTypes }: {
                    sleepTypes: SleepTypeI[],
                    trackerDaySleeps: { data: TrackerSleepI[] }
                } = response.payload;

                //This is to sort the types in the order they should be 
                const updatedTrackerSleeps = sleepTypes
                    .map((tracker) => {
                        const { type_name, ...rest } = tracker;
                        return {
                            ...rest,
                            name: type_name?.trim() || ''
                        };
                    })
                    .sort((a, b) => {
                        const indexA = desiredOrder.findIndex(
                            (name) => name.toLowerCase() === a.name.toLowerCase()
                        );
                        const indexB = desiredOrder.findIndex(
                            (name) => name.toLowerCase() === b.name.toLowerCase()
                        );
                        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
                    });

                const data = trackerDaySleeps;

                setDataDayReport(data.map(
                    (value) => (
                        {
                            id: value.id,
                            id_data: value.data.id,
                            action: value.data.value,
                            resident: value.data.resident,
                            type: value.data.type
                        }
                    )
                ));

                setSleepTypes(updatedTrackerSleeps);

            } catch (error: any) {
                console.log(error);
            }
        }

    }

    const submitQuickTracker = async (newItems: QuickTrackerItem[]) => {
        const saveQueries = newItems
            .filter((value) => !value.exist)
            .map(
                (value) => (
                    {
                        type: value.type.id,
                        value: value.action,
                        resident_id: value.resident.id,
                        detail: "",
                        location_id: locationSelectedId,
                        isNap: false
                    }
                )
            );

        const updateQueries = newItems
            .filter((value) => value.exist)
            .map(
                (value) => (
                    {
                        id: value.id,
                        id_data: value.id_data,
                        isNap: false,
                        data: {
                            type: value.type.id,
                            value: value.action,
                            resident_id: value.resident.id,
                            detail: "",
                            location_id: locationSelectedId,
                        }
                    }
                )
            );

        setLoading(true);

        try {
            if (saveQueries.length) await dispatch(saveTrackerSleep(saveQueries));
            if (updateQueries.length) await dispatch(updateTrackerSleep(updateQueries));

            setLoading(false);

            dataDaySleepReport();
            dataSleepReport(1, rowsSleepPerPage, true);

            toast(
                {
                    description: t("common.quickTrackerSuccessfully")
                }
            );
        } catch(error) {
            toast(
                {
                    description: t("common.quickTrackerError")
                }
            );
        }
    };

    useEffect(() => {
        dataDaySleepReport();
    }, []);
    

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl text-primary">{t('trackers.trackerSleep.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerSleep.description')}</div>
                        </div>
                        <Tabs defaultValue={selectedOption} onValueChange={(e) => { onSelectOption(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('cleaning.create.area.newButton')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>

                    </div>
                )
            }

            <div hidden={selectedTab === 'tracker' && selectedOption !== 'report'}>
                <ResidentSelector
                    onSelect={onResidentSelect}
                    locationId={location || undefined}
                    residentId={params.r}
                    showAllResidents={selectedOption === 'report'}
                />
            </div>

            {selectedOption === 'form' && (
                < Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                    <div className={`my-4 flex items-center ${selectedTab === 'tracker' ? 'justify-end' : 'justify-between'}`}>

                        {selectedTab === 'details' && (
                            <div className="flex flex-col gap-2">
                                <div className="font-semibold text-2xl text-primary">{t('trackers.trackerSleep.titleFormNap')}</div>

                            </div>
                        )}

                        <Tabs defaultValue={selectedTab} onValueChange={(e) => { onSelectTab(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="tracker" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t("cleaning.form.quick.label")}
                                </TabsTrigger>
                                <TabsTrigger value="details" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('cleaning.form.detailed.label')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                    {selectedTab === 'tracker' && (
                        <div>
                            {
                                locationSelectedId && (
                                    <div className="flex flex-col gap-4">

                                        <QuickTracker
                                            types={sleepTypes}
                                            residents={residents}
                                            loading={loading}
                                            submit={submitQuickTracker}
                                            data={dataDayReport}
                                            input={"date"}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    )}

                    {
                        selectedTab === 'details' && (
                            <div>

                                <TrackerSleepDetail
                                    residentId={params.r}
                                    locationId={locationSelectedId}
                                    dataSleepNapReport={dataSleepNapReport}
                                    rowsPerPage={rowsPerPage}
                                />
                            </div>
                        )
                    }

                </Card>
            )}

            {
                selectedOption === 'report' && (
                    <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                        <div className='flex items-center justify-between'>
                            <div className="flex flex-col gap-2">
                                <div className="font-semibold text-2xl text-primary">
                                    {selectedReport === 'sleep' ? t('trackers.Report') : t('trackers.trackerSleep.naps')}
                                </div>

                            </div>
                            <Tabs defaultValue={selectedReport} onValueChange={(e) => { onSelectReport(e); }}
                                className="w-auto">
                                <TabsList>
                                    <TabsTrigger value="sleep" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        <CircleFadingPlus className="h-4 w-4 mr-1" />
                                        {t('trackers.report')}
                                    </TabsTrigger>
                                    <TabsTrigger value="nap" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        {t('trackers.trackerSleep.naps')}
                                    </TabsTrigger>
                                </TabsList>

                            </Tabs>
                        </div>
                        {
                            selectedReport === 'sleep' && (
                                <TrackerSleepQuickSummary
                                    dataReport={dataReport}
                                    isLoading={isSleepLoading}
                                    loadMore={loadSleepMore}
                                    hasMore={hasMoreRows}
                                    resident={params.r}
                                />
                            )

                        }

                        {
                            selectedReport === 'nap' && (
                                <TrackerSleepNapSummary
                                    dataNapReport={dataNapReport}
                                    isNapLoading={isNapLoading}
                                    loadMore={loadMore}
                                    hasMore={hasMoreRows}
                                    dataSleepNapReport={dataSleepNapReport}
                                    resident={params.r}
                                    rowsPerPage={rowsPerPage}
                                />
                            )
                        }
                    </Card>
                )
            }
        </div >
    )

}
import { useEffect, useState, useCallback, useMemo } from 'react';
import { getContacts, getContactsCategoriesPersonal, updateContact, updatePhones, getListPhonesById, getContactById, createPhones, deletePhone, deletePhonesByContactId, fetchInitialData, fetchGeoLocation, getRelationship, validateName, getPhysicianSpecialty } from '../../slices/contactsSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContactsPhonesCreate from './ContactsPhonesCreate';
import { Building, User, Users, Mail, Phone, MapPin, Map, Flag, Archive, Globe, Plus } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Button } from '@/common/presentation/components/ui/button';
import { ICountry } from '../../../../wizard/domain/signup.domain';
import CountrySelector from '../../../../wizard/presentation/components/CountrySelector/CountrySelector';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
interface Phone {
    id?: string;
    category: string;
    number: string;
    ext: string;
}

interface ContactInfo {
    [key: string]: {
        category: string;
        company: string;
        name: string;
        last_name: string;
        npi: string;
        email: string;
        relationship: string;
        physician_type_id: string;
        address: string;
        zipcode: string;
        country: string;
        state: string;
        city: string;
        same_as: string;
        linkto: string;
        phones: Phone[];
    };
}

export const ContactsCategoriesEdit = ({ type, contactInfo, setContactInfo, currentCategory }: { type: number, contactInfo: ContactInfo, setContactInfo: Function, currentCategory: string }) => {
    const { t } = useTranslation();
    const { contactId } = useParams<{ contactId: string }>();
    const categories = useSelector((state: RootState) => state.contacts.categories);
    const phones = useSelector((state: RootState) => state.contacts.phones);
    const contacts = useSelector((state: RootState) => state.contacts.contacts); // Last state of contacts - Getcontacts or GetContactbyresident
    const allContacts = useSelector((state: RootState) => state.contacts.allContacts); //All contacts
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [contactPhones, setContactPhones] = useState<Phone[]>([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const countries = useSelector((state: RootState) => state.contacts.countries);
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [searchParams] = useSearchParams();
    const locationPath = useLocation();
    //console.log('locationPath:', locationPath.state);
    const residentId = searchParams.get('r');
    const validateMessage = useSelector((state: RootState) => state.contacts.validateName); // We select the message
    const [alertMessage, setAlertMessage] = useState("");

    const relationshipOptions = useSelector((state: RootState) => 
        state.contacts.relationship.map((item) => ({
          label: item.name, // The "name" field will be used as the visible label
          value: item.value, // The "value" field will be the selected value
        }))
    );

    const specialtyOptions = useSelector((state: RootState) => 
        state.contacts.specialty.map((item) => ({
          label: item.name, // The "name" field will be used as the visible label
          value: item.id, // The "id" field will be the selected value
        }))
    );

    useEffect(() => {
        getInitialData();
    }, []);

    useEffect(() => {
        dispatch(getContactsCategoriesPersonal());
        //dispatch(getContacts()); // Obtener contactos al cargar el componente
    }, [dispatch]);

    useEffect(() => {
        if (contactId) {
            dispatch(getListPhonesById(contactId));
        }
    }, [dispatch, contactId]);

    useEffect(() => {
        if (contactId && phones[contactId]) {
            setContactPhones(phones[contactId]);
        }
    }, [phones, contactId]);

    useEffect(() => {
        dispatch(getRelationship());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPhysicianSpecialty());
    }, [dispatch]);

    const handleInputChange = (category: string, field: string, value: string) => {
        setContactInfo((prevState: ContactInfo) => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [field]: value,
                category: category,
            },
        }));

        // Validate name only if field is "name"
        if (field === "name") {
            handleNameChange(value, category);
        }
    };

    const handlePhoneInputChange = (category: string, contactIndex: number, phoneIndex: number, field: string, value: string) => {
        setContactPhones(prevPhones => {
            const updatedPhones = [...prevPhones];
            updatedPhones[phoneIndex] = {
                ...updatedPhones[phoneIndex],
                [field]: value,
            };
            return updatedPhones;
        });
    };

    const addPhone = (category: string, contactIndex: number) => {
        setContactPhones(prevPhones => [
            ...prevPhones,
            { category: category, number: '', ext: '' },
        ]);
    };

    const removePhone = (category: string, contactIndex: number, phoneIndex: number, phoneId: string) => {
        dispatch(deletePhone(phoneId));
        setContactPhones(prevPhones => {
            const updatedPhones = [...prevPhones];
            updatedPhones.splice(phoneIndex, 1);
            return updatedPhones;
        });
    };

    useEffect(() => {
        if (contactId) {
            dispatch(getContactById(contactId));
            dispatch(getListPhonesById(contactId));
        }
    }, [dispatch, contactId]);

    useEffect(() => {
        if (contactId && Array.isArray(phones[contactId])) {
            setContactPhones(phones[contactId]);
        }
    }, [phones, contactId]);
    
    // Función para limpiar los datos de los teléfonos
    const sanitizePhones = (phones: any[]) => {
        return phones.map(phone => ({
            category: phone.category,
            number: phone.number,
            ext: phone.ext
        }));
    };
    
    const handleContactSelect = async (selectedOption: any) => {
        setSelectedContact(selectedOption);
        if (selectedOption) {
            const contact = allContacts.find((c: any) => c.id === selectedOption.value);
            if (contact) {
                const contactPhones = (contact.phones || []).filter((phone: any) => phone.delete === 0); // Filtrar teléfonos activos
                //console.log(contactPhones);
                setContactInfo({
                    ...contactInfo,
                    [currentCategory]: {
                        category: currentCategory,
                        company: contact.company,
                        name: contact.name,
                        last_name: contact.last_name,
                        npi: contact.npi,
                        email: contact.email,
                        relationship: contact.relationship,
                        physician_type_id: contact.physician_type_id,
                        address: contact.address,
                        zipcode: contact.zipcode,
                        country: contact.country,
                        state: contact.state,
                        city: contact.city,
                        linkto: contact.linkto,
                        phones: contactPhones,
                    }
                });
                dispatch(getListPhonesById(selectedOption.value));
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            for (const categoryId of Object.keys(contactInfo)) {
                // Obtener teléfonos existentes del estado contactInfo
                const existingPhones = contactInfo[categoryId].phones || [];
    
                // Limpiar y combinar los teléfonos existentes y nuevos
                const sanitizedPhones = sanitizePhones([...existingPhones, ...contactPhones]);
    
                const contactData = {
                    ...contactInfo[categoryId],
                    phones: sanitizedPhones, // Utilizar teléfonos saneados
                };
                //console.log('Submitting data for update:', contactData);
    
                // Elimina todos los teléfonos existentes del contacto
                await dispatch(deletePhonesByContactId(contactId!)).unwrap();
    
                // Crea nuevos teléfonos
                if (sanitizedPhones.length > 0) {
                    const phonePromises = sanitizedPhones.map(phone =>
                        dispatch(createPhones({ ...phone, contact_id: contactId })).catch(error => {
                            //console.error('Failed to create phone:', error);
                        })
                    );
                    await Promise.all(phonePromises);
                }
    
                // Actualiza el contacto después de manejar los teléfonos
                await dispatch(updateContact({ id: contactId!, data: contactData })).unwrap();
            }
            if (locationPath.state?.from?.startsWith('/residents')) {
                navigate(`/residents/update?r=${residentId}&t=contacts`);
            } else if (locationPath.state?.from === '/contacts') {
                navigate('/contacts');
            } else {
                navigate('/contacts');
            }
            
        } catch (error: any) {
            //console.error('Failed to submit contact info:', error.message, error.response?.data);
        }
    };         

    const filteredCategories = categories.filter(category => category.type === type && category.id === currentCategory);

    // Crea las opciones para el dropdown
    const contactOptions = allContacts
    .filter((contact: any) => contact.category?.id === currentCategory) // Filtrar contactos por categoría
    .reduce((uniqueContacts: any[], contact: any) => {
        if (!uniqueContacts.some((c) => c.id === contact.id)) {
            uniqueContacts.push(contact); // Agregar solo si no está en la lista
        }
        return uniqueContacts;
    }, [])
    .map((contact: any) => {
        const name = contact.name || ''; // Nombre del contacto
        const lastName = contact.last_name ? ` ${contact.last_name}` : ''; // Apellido
        const company = contact.company ? ` - ${contact.company}` : ''; // Compañía

        return {
            value: contact.id,
            label: `${name}${lastName}${company}`.trim(), // Formatear etiqueta
        };
    });

    const getInitialData = useCallback(async () => {
        const country = await getClientLocation();
        const response = await dispatch<any>(fetchInitialData(country));
    
        if (response.error) {
          return;
        }
    
        const { client_country, countries }: { client_country: string; countries: ICountry[] } = response.payload;
        const countryInfo = countries.find((country: ICountry) => country.code === client_country);
    
    }, []);

    const getClientLocation = async () => {
        try {
          let country = 'us';
          const response = await dispatch<any>(fetchGeoLocation());
    
          if (!response.error) {
            const { payload } = response;
            country = payload.country.toLocaleLowerCase();
          }
    
          return country;
        } catch (error) {
          return 'us';
        }
    };

    const countriesCode: CountryCode[] = useMemo(() => {
        return countries.map((country: ICountry) => country.code.toUpperCase());
    }, [countries]);

    const handleCountryChange = (value: string) => {
        setContactInfo(prevState => {
            const updatedContactInfo = { ...prevState };
            Object.keys(updatedContactInfo).forEach(categoryId => {
                if (updatedContactInfo[categoryId]?.contacts) {
                    updatedContactInfo[categoryId].contacts.forEach(contactData => {
                        contactData.country = value.toUpperCase(); // Actualiza el país en cada contacto
                    });
                }
            });
            return updatedContactInfo;
        });
    };    

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleNameChange = async (name: string, categoryId: string) => {
        try {
            const response = await dispatch(validateName(name)).unwrap(); // Desempaquetar directamente la respuesta
            if (response.message === "Contact name already exists.") {
                setAlertMessage("Alert! We found a similar contact, please review the contact list for this category.");
            } else {
                setAlertMessage("");
            }
        } catch (error) {
            //console.error("Error validating name:", error);
            setAlertMessage(""); // Limpia la alerta si ocurre un error
        }
    };    

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-3">
                <label className="block text-sm font-bold mb-2" htmlFor="contact-search">
                    {t('contacts.search_contact')}
                </label>
                <Select
                    id="contact-search"
                    options={contactOptions}
                    value={selectedContact}
                    onChange={handleContactSelect}
                    placeholder={t('contacts.search_select_contact') + '...'}
                />
            </div>

            {filteredCategories.map((category) => (
                <Card key={category.id} className="border-t-4 border-t-primary/80">
                    <CardHeader>
                        <CardTitle
                            style={{ color: accentColor }}
                            className="flex flex-row items-center text-lg font-bold"
                        >
                            {category.name}
                        </CardTitle>
                        <hr className="mt-2 border-gray-300" />
                    </CardHeader>
                    <CardContent>
                        {/* 1ra fila */}
                        {alertMessage && (
                            <div className="mb-3 w-full p-4 bg-yellow-100 text-yellow-800 border-b-4 border-yellow-500 z-50 grid grid-cols-1">
                                {alertMessage}
                            </div>
                        )}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {[
                                { label: t('contacts.name'), name: 'name', icon: <User className="absolute left-3 text-gray-400" /> },
                                { label: t('contacts.last_name'), name: 'last_name', icon: <User className="absolute left-3 text-gray-400" /> },
                                { label: t('contacts.email'), name: 'email', icon: <Mail className="absolute left-3 text-gray-400" /> },
                            ].map((field) => (
                                <div key={field.name} className="relative mb-4">
                                    <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}`}>
                                        {field.label}
                                    </label>
                                    <div className="flex items-center">
                                        {field.icon}
                                        <input
                                            type="text"
                                            name={field.name}
                                            id={`${field.name}-${category.id}`}
                                            value={contactInfo[category.id]?.[field.name] || ''}
                                            onChange={(e) => handleInputChange(category.id, field.name, e.target.value)}
                                            className="pl-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* 2da fila */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {(category.type === 2
                                ? [
                                    {
                                        label: t('contacts.specialty'),
                                        name: 'physician_type_id',
                                        icon: <Users className="absolute left-3 text-gray-400" />
                                    },
                                    {
                                        label: t('contacts.npi'),
                                        name: 'npi',
                                        icon: <Archive className="absolute left-3 text-gray-400" />
                                    },
                                ]
                                : [
                                    {
                                        label: t('contacts.company'),
                                        name: 'company',
                                        icon: <Building className="absolute left-3 text-gray-400" />
                                    },
                                    {
                                        label: t('contacts.relationship'),
                                        name: 'relationship',
                                        icon: <Users className="absolute left-3 text-gray-400" />
                                    },
                                ]
                            ).map((field) => (
                                <div key={field.name} className="relative mb-4">
                                    <label
                                        className="block text-gray-400 text-sm font-bold mb-2"
                                        htmlFor={`${field.name}-${category.id}`}
                                    >
                                        {field.label}
                                    </label>

                                    {field.icon}
                                    {field.name === 'relationship' && category.type !== 2 ? (
                                        <Select
                                            name={field.name}
                                            id={`${field.name}-${category.id}`}
                                            value={
                                                contactInfo[category.id]?.[field.name]
                                                    ? relationshipOptions.find(
                                                        (opt) => opt.value === contactInfo[category.id][field.name]
                                                    )
                                                    : null
                                            }
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    handleInputChange(category.id, field.name, selectedOption.value);
                                                }
                                            }}
                                            options={relationshipOptions}
                                            placeholder={t('contacts.search_select_contact') + '...'}
                                            isClearable
                                        />
                                    ) : field.name === 'physician_type_id' && category.type === 2 ? (
                                        <Select
                                            name={field.name}
                                            id={`${field.name}-${category.id}`}
                                            value={
                                                (() => {
                                                    const selectedOption = specialtyOptions.find(
                                                        (opt) => opt.value === contactInfo[currentCategory]?.physician_type_id
                                                    );
                                                    //console.log('Selected Value:', contactInfo[category.id]?.[field.name]);
                                                    //console.log('Matched Option:', selectedOption);
                                                    return selectedOption || null;
                                                })()
                                            }
                                            onChange={(selectedOption) => {
                                                //console.log('Selected Relationship Option on Change:', selectedOption);
                                                if (selectedOption) {
                                                    handleInputChange(category.id, field.name, selectedOption.value);
                                                }
                                            }}
                                            options={specialtyOptions}
                                            placeholder={t('contacts.search_select_specialty') + '...'}
                                            isClearable
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            name={field.name}
                                            id={`${field.name}-${category.id}`}
                                            value={contactInfo[category.id]?.[field.name] || ''}
                                            onChange={(e) => handleInputChange(category.id, field.name, e.target.value)}
                                            className="pl-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* 3ra fila */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {[
                                { label: t('contacts.address'), name: 'address', icon: <MapPin className="absolute left-3 text-gray-400" /> },
                                { label: t('contacts.city'), name: 'city', icon: <Map className="absolute left-3 text-gray-400" /> },
                                { label: t('contacts.state'), name: 'state', icon: <Flag className="absolute left-3 text-gray-400" /> },
                            ].map((field) => (
                                <div key={field.name} className="relative mb-4">
                                    <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}`}>
                                        {field.label}
                                    </label>
                                    <div className="flex items-center">
                                        {field.icon}
                                        <input
                                            type="text"
                                            name={field.name}
                                            id={`${field.name}-${category.id}`}
                                            value={contactInfo[category.id]?.[field.name] || ''}
                                            onChange={(e) => handleInputChange(category.id, field.name, e.target.value)}
                                            className="pl-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* 4ta fila */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {[
                                { label: t('contacts.zip_code'), name: 'zipcode', icon: <Archive className="absolute left-3 text-gray-400" /> },
                                { label: t('contacts.country'), name: 'country', icon: <Globe className="absolute left-3 text-gray-400" /> },
                            ].map((field) => (
                                <div key={field.name} className="relative mb-4">
                                    <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}`}>
                                        {field.label}
                                    </label>
                                    <div className="flex items-center">
                                        {field.icon}
                                        {field.name === 'country' ? (
                                            <CountrySelector
                                                defaultCountry={contactInfo[category.id]?.country || ''}
                                                country={contactInfo[category.id]?.country || ''}
                                                name="country"
                                                onChange={(e) => handleCountryChange(e.value)}
                                                countries={countriesCode}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={field.name}
                                                id={`${field.name}-${category.id}`}
                                                value={contactInfo[category.id]?.[field.name] || ''}
                                                onChange={(e) => handleInputChange(category.id, field.name, e.target.value)}
                                                className="pl-10 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Sección de teléfonos */}
                        <div className="w-full mb-4">
                            <ContactsPhonesCreate
                                category={category.id}
                                contactIndex={0}
                                phones={contactPhones}
                                handlePhoneInputChange={handlePhoneInputChange}
                                addPhone={addPhone}
                                removePhone={removePhone}
                            />
                        </div>
                    </CardContent>
                </Card>
            ))}


            <div className="flex justify-end mt-6">
                <Button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {t('contacts.update_contact')}
                </Button>
            </div>
            {/* Botón flotante */}
            <button 
                type="submit" 
                className="fixed right-5 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg focus:outline-none focus:shadow-outline"
            >
                {t('contacts.save')}
            </button>
        </form>
    );
};

ContactsCategoriesEdit.propTypes = {
    type: PropTypes.number.isRequired,
    contactInfo: PropTypes.object.isRequired,
    setContactInfo: PropTypes.func.isRequired,
    currentCategory: PropTypes.string.isRequired,
};

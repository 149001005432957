import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { HistoryTableColumns } from './HistoryTableColumns';
import { FormsHistoryProps } from './FormsHistory.types';

export const FormsHistory = ({ formKey }: FormsHistoryProps) => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { getFormHistory, forms } = useFormsStore();
  const [isLoading, setIsLoading] = useState(false);

  const fetchFormHistory = async (residentId: string, key: string) => {
    if (!residentId || !key) return;
    setIsLoading(true);
    await getFormHistory(residentId, key);
    setIsLoading(false);
  };

  useEffect(() => {
    if (resident?.id) {
      fetchFormHistory(resident.id, formKey);
    }
  }, [resident?.id, formKey]);

  const sortedForms = useMemo(() => {
    return forms.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [forms]);

  return (
    <div className="p-4">
        <SingleTable
          data={isLoading ? [] : sortedForms}
          columns={HistoryTableColumns()}
          onDelete={() => {}}
          disable={false}
          filter={false}
        />
    </div>
  );
};

import { FC, useEffect } from 'react';


declare global {
  interface Window {
    smartsupp: any;
    _smartsupp: any;
  }
}

const SmartSupp: FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      var _smartsupp = _smartsupp || {};
      _smartsupp.key = '${import.meta.env.VITE_SMARTSUPP_KEY}';
      window.smartsupp||(function(d) {
        var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
        s=d.getElementsByTagName('script')[0];c=d.createElement('script');
        c.type='text/javascript';c.charset='utf-8';c.async=true;
        c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
      })(document);
    `;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.smartsupp;
      delete window._smartsupp;
    };
  }, []);

  return null;
};

export default SmartSupp;
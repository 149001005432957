export const accordionSections = [
  {
    id: "residents",
    title: "Residents",
    icon: "UserRound",
    visible: true,
    submenu: [
      { id: "by_resident" },
      { id: "plan_of_care" },
      { id: "face_sheets" },
      { id: "emergency_sheets" },
      { id: "quarterly_report_pdf" },
      { id: "food_and_nutrition" },
      { id: "hospitalizations" },
      { id: "pdf_historical" },
      { id: "primary_care_physician_home_health_hospice" },
      { id: "inventory_report" },
      { id: "physician_reports" },
      { id: "leave_unassisted_lic_602" },
      { id: "direct_service_dsp" },
      { id: "provider_reports" }
    ]
  },
  {
    id: "staff",
    title: "Staff",
    icon: "UserCog",
    visible: false,
    submenu: [
      { id: "shift_report" },
      { id: "staff_certificate_report" }
    ]
  },
  {
    id: "facility",
    title: "Facility",
    icon:"House",
    visible: true,
    submenu: [
      { id: "room_directory" },
      { id: "shared_notes" },
      { id: "client_login_history" },
    ]
  },
  {
    id: "other",
    title: "Other",
    icon:"Ellipsis",
    visible: false,
    submenu: [
      { id: "medications_historical" },

      { id: "alerts" },

    ]
  },
]
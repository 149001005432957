import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { Card, CardContent } from '@/components/ui/card';

const CourseCardSkeleton: React.FC = () => {
  return (
    <Card className="w-full max-w-xs hover:shadow transition-all duration-300 ease-in-out cursor-pointer">
      <CardContent className="p-1 space-y-1">
        <div className="bg-primary/10 dark:bg-primary/15 p-3 rounded-md h-20 flex items-center">
          <Skeleton className="h-4 w-full" />
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center gap-2">
            <Skeleton className="h-2 w-2 rounded-full" />
            <Skeleton className="h-4 w-20" />
          </div>
          <Skeleton className="h-4 w-12" />
        </div>
      </CardContent>
    </Card>
  );
};

export default CourseCardSkeleton;
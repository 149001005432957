//renderActionsColumns.tsx
import React, { useState } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Eye, Trash } from 'lucide-react';
import { Row } from "@tanstack/react-table";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEyeClick: (id: number) => void;
  handleEditClick: (id: number, linkto: string) => void;
  openDeleteDialog: (id: number) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ rowData, handleEyeClick, handleEditClick, openDeleteDialog }) => {
  const isPhysician = rowData.category === 'Primary Physician'; // Physician Category.
  const isPharmacy = rowData.category === 'Pharmacy'; // Pharmacy Category.
  
  return (
    <div className="flex gap-2 justify-end">
      <Button
        size="icon"
        variant="outline"
        className="gap-2"
        onClick={() => handleEyeClick(rowData)}
      >
        <Eye className="h-4 w-4" />
      </Button>
      {!isPharmacy && (
        <Button
          size="icon"
          variant="outline"
          className="gap-2"
          onClick={() => handleEditClick(rowData.id, rowData.linkto)}
        >
          <Pencil className="h-4 w-4" />
        </Button>
      )}
      {!isPharmacy && !isPhysician && (
        <Button
          size="icon"
          variant="outline"
          className="gap-2"
          onClick={() => openDeleteDialog(rowData)}
        >
          <Trash className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default RenderActionsColumn;

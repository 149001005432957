
import { calculateAge } from "@/modules/residents/domain/utils/Forms/formUtils";
import { servicesNeeded } from "../constants/residentAppraisal.constants";
import { ResidentI } from "@/common/domain/Residents";
import { format, parseISO } from 'date-fns';

export const residentAppraisalDefaults = (resident: ResidentI) => ({
  resident_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
  resident_age: calculateAge(resident?.dob) || "",
  health: resident?.health_information?.health || "",
  physical_disabilities: "",
  mental_condition: resident?.health_information?.mental_condition_description || "",
  health_history: resident?.health_information?.history || "",
  social_factors: "",
  bed_status: "",
  bed_status_comment: "",
  tb_history_family: resident?.health_information?.family_tuberculosis_history === 1
    ? "yes"
    : resident?.health_information?.family_tuberculosis_history === 0
    ? "no"
    : "",
  tb_test_date: resident?.health_information?.tuberculosis_test_date
    ? format(parseISO(resident.health_information.tuberculosis_test_date), 'MM/dd/yyyy')
    : '',
  tb_test_result: "",
  recent_tb_exposure: resident?.health_information?.recent_contact_with_tb === 1
  ? "yes"
  : resident?.health_information?.recent_contact_with_tb === 0
  ? "no"
  : "",
  tb_action_taken: resident?.health_information?.action_taken,
  tb_details: "",
  ambulatory_status: "",
  ambulatory_criteria: {
    walk_assistance: "",
    follow_signals: "",
    use_routes: "",
    evacuate_quickly: "",
  },
  functional_capabilities: {
    no_personal_help: "",
    difficulty_climbing: "",
    uses_brace_or_crutch: "",
    frail_or_slow: "",
    uses_walker: "",
    uses_walker_unassisted: "",
    uses_wheelchair: "",
    uses_wheelchair_unassisted: "",
    requires_grab_bars: "",
    other: "",
    other_description: ""
  },
  services_needed: servicesNeeded.reduce<Record<string, { status: string; comment: string }>>((acc, service) => {
    acc[service.name] = { status: "", comment: "" };
    return acc;
  }, {}),
  additional_info: { status: "", comment: "" },
});

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Button } from "@/common/presentation/components/ui/button";
import { Pencil, Bed } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { Card } from '@/common/presentation/components/ui/card';
import { useTranslation } from 'react-i18next';

export const Locations = () => {

    const { t } = useTranslation();
    const locations = useSelector((state: RootState) => state.locations.allLocations.locations);
    const navigate = useNavigate();
    return (
        <div className="p-5">
            <div className="flex flex-col gap-2">
                <div className="font-semibold text-2xl text-primary">{t('locationsSelector.title')}</div>
            </div>
            < Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                <Table className="mt-4">
                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                            <TableHead className="text-white font-semibold">{t('trackers.trackerFall.location')}</TableHead>
                            <TableHead className="text-white font-semibold">{t('facilities.client')}</TableHead>
                            <TableHead className="text-white font-semibold">{t('residents.medicalInformation.diagnosis.description')}</TableHead>
                            <TableHead className="text-white font-semibold">{t('facilities.activeStatus')}</TableHead>
                            <TableHead className="text-white w-5 font-semibold">{t('trackers.Actions')}</TableHead>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {
                            locations.length && locations.map(
                                element =>
                                    <TableRow key={element.id}>
                                        <TableCell>{element.name}</TableCell>
                                        <TableCell>{element.licensing_name}</TableCell>
                                        <TableCell>{element.location_description}</TableCell>
                                        <TableCell>{element.status}</TableCell>
                                        <TableCell className="flex">
                                            <Button variant="outline" size="icon" className='mr-2'
                                                onClick={() => navigate(`../update-location?id=${element.id}`, { relative: 'path' })}>
                                                <Pencil className="h-4 w-4" />
                                            </Button>

                                            <Button variant="outline" size="icon" className='mr-2'
                                            onClick={() => navigate(`../rooms?id=${element.id}`, { relative: 'path' })}>
                                                <Bed className="h-4 w-64" />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Textarea } from "@/components/ui/textarea";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { healthFields } from "../../constants/residentAppraisal.constants";

const HealthDetailsForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      {healthFields.map((field) => (
        <FormField
          key={field.name}
          control={control}
          name={field.name}
          render={({ field: formField }) => (
            <FormItem>
              <FormLabel className="font-semibold">
                {t(`residents.forms.residentAppraisal.healthFieldsName.${field.name}`)} {" "}
                <span className="text-sm font-normal text-zinc-500">
                  ({t(`residents.forms.residentAppraisal.healthFieldsDescription.${field.description}`)})
                </span>
              </FormLabel>
              <FormControl>
                <Textarea
                  {...formField}
                  className="dark:bg-zinc-800 w-full mt-2"
                  maxLength={field.maxLength}
                />
              </FormControl>
            </FormItem>
          )}
        />
      ))}
    </div>
  );
};

export default HealthDetailsForm;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@/store/store';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import RenderActionsColumn from './renderActionsColumn';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { getDestroyedMedications } from '../../../slices/medicationsSlice';
import columnsReportMedications from './columnsReportMedications';

interface SortingState {
  key: string;
  direction: 'ascending' | 'descending';
}

const ReportDestruction: React.FC = () => {
  const [sorting, setSorting] = useState<SortingState>({ key: '', direction: 'ascending' });
  const [filterValue, setFilterValue] = useState('');

  const dispatch = useDispatch();
  const { destroyedMedications } = useSelector((state: RootState) => state.medications);
  const { params } = useRouteParams();

  const columns = useMemo(
    () =>
      columnsReportMedications.map((column) =>
        column.key === 'actions'
          ? {
              ...column,
              render: (rowData: any) => <RenderActionsColumn residentId={rowData.residentId} month={rowData.month} />
            }
          : column
      ),
    []
  );

  useEffect(() => {
    if (params.l) {
      dispatch(
        getDestroyedMedications({
          locationId: params.l,
          residentId: params.r || 'all-residents'
        })
      );
    }
  }, [dispatch, params.l, params.r]);

  const handleSort = (columnKey: string, direction: 'ascending' | 'descending') => {
    setSorting({ key: columnKey, direction });
  };

  const handleFilterChange = (value: string) => {
    setFilterValue(value.toLowerCase());
  };

  const handleFilterClear = () => {
    setFilterValue('');
  };

  const filteredData = useMemo(() => {
    if (!filterValue || !destroyedMedications) return destroyedMedications;

    return destroyedMedications.filter((item) =>
      columns.some((column) => {
        const cellValue = item[column.key];
        return cellValue != null && String(cellValue).toLowerCase().includes(filterValue);
      })
    );
  }, [destroyedMedications, filterValue, columns]);

  const sortedData = useMemo(() => {
    if (!sorting.key || !filteredData) return filteredData;

    return [...filteredData].sort((a, b) => {
      const aValue = a[sorting.key];
      const bValue = b[sorting.key];
      const comparison = sorting.direction === 'ascending' ? 1 : -1;

      return aValue > bValue ? comparison : -comparison;
    });
  }, [filteredData, sorting.key, sorting.direction]);

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        tableId="report-destruction-table"
        data={sortedData}
        columns={columns}
        renderCellContent={(index, column, data) => data[index][column.key]}
        additionalButtons={[]}
        onSort={handleSort}
        onFilterChange={handleFilterChange}
        onFilterClear={handleFilterClear}
        showSearchInput
        showAdvancedFilters={false}
        isLoading={false}
        hasMore={false}
        loadMore={() => {}}
      />
    </div>
  );
};

export default ReportDestruction;

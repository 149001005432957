import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from '@/common/presentation/components/ui/input';
import { Textarea } from '@/common/presentation/components/ui/textarea';

const OtherRequiredInformationForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-10 mb-4">
        <h4 className="font-semibold text-base">
          10. {t('residents.forms.lic601EmergencyInfo.otherRequiredInfo')}
        </h4>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="space-y-4">
        <FormField
          control={control}
          name="required_info.ambulatory_status"
          render={({ field }) => (
            <FormItem className="flex flex-col md:flex-row items-start md:items-center md:gap-10">
              <FormLabel className="font-normal md:text-nowrap">
                {t('residents.forms.ambulatoryStatus')}
              </FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800 resize-none"
                  rows={1}
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
          <FormField
            control={control}
            name="required_info.religious_preference"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  {t('residents.forms.lic601EmergencyInfo.religiousPreference')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={50}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="required_info.clergyman_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  {t('residents.forms.lic601EmergencyInfo.nameClergyman')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={100}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="required_info.clergyman_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  {t('residents.forms.lic601EmergencyInfo.addressClergyman')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={100}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="required_info.telephone"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  {t('common.telephone')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                    maxLength={20}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={control}
          name="required_info.comments"
          render={({ field }) => (
            <FormItem className="flex flex-col md:flex-row items-start md:items-center md:gap-10">
              <FormLabel className="font-normal">{t('common.comments')}</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  rows={3}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </>
  );
};

export default OtherRequiredInformationForm;

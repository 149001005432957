import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/store/store';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { LicensingForms } from '@/modules/residents/presentation/components/Forms/Licensing/LicensingForms';
import { FacilityForms } from '@/modules/residents/presentation/components/Forms/Facility/FacilityForms';

export const ResidentFormsPage = () => {
  const { t } = useTranslation();
  const { resident } = useSelector((state: RootState) => state.residents.resident || {});

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''} -`;
  const title = `${residentName} ${t('common.forms')}`;

  const tabs = [
    { label: t('residents.forms.licensingForms'), value: 'licensing_forms' },
    // Pending: Create a new module
    // { label: t('residents.forms.fillableForms'), value: 'facility_forms' }
  ];

  return (
    <>
      <div className="flex flex-col gap-4">
        <BackNavigationBar
          title={title}
          path={`/residents/profile?r=${resident?.id}`}
        />
        <Tabs defaultValue={tabs[0].value} className="w-full pb-4 px-2">
          <TabsList className=" flex flex-wrap w-1/2 lg:w-1/4 lg:max-w-lg items-center p-0 justify-start h-auto">
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="flex items-center justify-center gap-2 px-1.5 py-3 text-sm font-semibold leading-4 rounded-sm flex-1
                data-[state=active]:bg-primary data-[state=active]:text-white whitespace-normal break-words overflow-hidden sm:min-h-[50px]"
              >
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent value="licensing_forms">
            <LicensingForms />
          </TabsContent>
          <TabsContent value="facility_forms">
            <FacilityForms />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};

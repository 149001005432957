import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { getContacts, getContactsByResident, getListPhonesById, deleteContact } from '../../../../contacts/presentation/slices/contactsSlice';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ArrowLeft, Trash, CircleFadingPlus, Mail, Phone, Pencil, Eye, CircleUserRound, Building2, StretchHorizontal, UtilityPole, SquareChevronRight, Waypoints, SquareStack, BookA } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { cn } from '@/lib/utils';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useContactsColumns from '../../../../contacts/presentation/components/PersonalContacts/ContactsColumns';
import renderActionsColumn from '../../../../contacts/presentation/components/PersonalContacts/renderActionsColumn';
import { useTranslation } from 'react-i18next';
import FilterForm from '../../../../contacts/presentation/components/PersonalContacts/ContactsFilterForm';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSchema, IContactsResumeData } from '../../../../contacts/presentation/components/PersonalContacts/ContactsSchema';
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";

const DialogContactInfo = ({ contact, isOpen, onClose, phones }) => {
    const { t } = useTranslation();
    //console.log('Phones received:', phones);
    return (
    <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
            <DialogHeader>
                <DialogTitle className="text-primary text-2xl font-bold">{t('contacts.contact_information')}</DialogTitle>
            </DialogHeader>
            <hr className="border-primary w-full my-2" />
            <div>
                <p className="flex justify-center"><CircleUserRound size={50} className="w-56" /></p>
                <p className="text-center text-xl"><span>{contact.full_name}</span></p>
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.contact_details')}.
                    </DialogDescription>
                </p>
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.phones')}:
                    </DialogDescription>
                </p>
                {/* Mostrar lista de teléfonos */}
                {phones.length > 0 ? (
                    <ul className="list-disc pl-5">
                        {phones.map((phone, index) => (
                            <li key={index}>
                                {phone.category 
                                    ? `${phone.category.charAt(0).toUpperCase()}${phone.category.slice(1)} ` 
                                    : `${t('contacts.phone')} `}
                                {phone.number} 
                                {phone.ext && ` ${t('contacts.ext')}: ${phone.ext}`}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-center">{t('contacts.no_phones')}</p>
                )}
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.personal_information')}:
                    </DialogDescription>
                </p>
                <p className="flex mt-3"><Building2 className="mr-2" /><strong className="mr-1">{t('contacts.company')}:</strong> {contact.company}</p>
                <p className="flex mt-3"><Mail className="mr-2" /><strong className="mr-1">{t('contacts.email')}:</strong> {contact.email}</p>
                <p className="flex mt-3"><StretchHorizontal className="mr-2" /><strong className="mr-1">{t('contacts.address')}:</strong> {contact.address}</p>
                <p className="flex mt-3"><UtilityPole className="mr-2" /><strong className="mr-1">{t('contacts.city')}:</strong> {contact.city}</p>
                <p className="flex mt-3"><Waypoints className="mr-2" /><strong className="mr-1">{t('contacts.state')}:</strong> {contact.state}</p>
                <p className="flex mt-3"><SquareChevronRight className="mr-2" /><strong className="mr-1">{t('contacts.zip_code')}:</strong> {contact.zipcode}</p>
                <p className="flex mt-3"><SquareStack className="mr-2" /><strong className="mr-1">{t('contacts.country')}:</strong> {contact.country}</p>
            </div>
            <DialogFooter>
                <DialogClose asChild>
                    <Button>{t('contacts.close')}</Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
    );
};

const DialogDeleteInfo = ({ contact, isOpen, onClose, confirm }) => {
    const { t } = useTranslation();
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-primary text-2xl font-bold">{t('contacts.delete_action')}: {contact.full_name} ({contact.category})?</DialogTitle>
          </DialogHeader>
          <hr className="border-primary w-full my-2" />
          <p>{t('contacts.this_action')}</p>
          <DialogFooter>
            <Button variant="secondary" onClick={onClose}>
                {t('contacts.cancel')}
            </Button>
            <Button variant="destructive" className="bg-primary" onClick={confirm}>
                {t('contacts.confirm')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
};

const Contacts = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const contacts = useSelector((state: RootState) => state.contacts.contacts) || [];
    const phones = useSelector((state: RootState) => state.contacts.phones) || {};
    const [data, setData] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const { params, setParam } = useRouteParams();
    const [filter, setFilter] = useState<string>('');

    const [selectedContact, setSelectedContact] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedContactForDelete, setSelectedContactForDelete] = useState(null);
    const residentId = searchParams.get('r');
    const locationId = searchParams.get('l');
    const ContactsColumns = useContactsColumns();
    const [sort, setSort] = useState<{ column: string; direction: string }>({ column: '', direction: '' });
    const [filteredContacts, setFilteredContacts] = useState([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const locationSelected = sessionStorage.getItem("location_selected");
    const locationPath = useLocation();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema)
    });
    
    const lastFilters: IContactsResumeData = {
        resident_id: params.r,
        location_id: params.l,
        per_page: rowsPerPage,
        page: currentPage,
        title: data.title || ''
    };

    // Extract the residentId and locationId to use in navigation
    //const contact = contacts.find((contact) => contact.resident?.id === residentId);
    //const locationId = contact?.resident_location?.location_id;

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }
    
        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }
    
        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);
    
        if (!params.r && params.l) {
            setParam('r', 'all-residents');
        }
    }, [params, locations]);

    const onResidentSelect = (residentId: string) => {
        setParam('r', residentId);
        //console.log(residentId);
    };

    useEffect(() => {
        setCurrentPage(1);
        setData([]);
        setHasMoreRows(true);
        setIsLoading(false);
    
        if (!params.r || params.r === 'all-residents') {
            fetchData(1, rowsPerPage, undefined, false); // Todos los contactos
            //console.log('Fetching all contacts');
        } else {
            fetchData(1, rowsPerPage, undefined, true); // Solo contactos del residente
            //console.log('Fetching contacts by resident');
        }
    }, [dispatch, params.r, rowsPerPage]);
    
    /*
    useEffect(() => {
        // Fetch phone numbers for each contact
        contacts.forEach((contact) => {
            dispatch(getListPhonesById(contact.id));
        });
    }, [contacts, dispatch]);
    */

    // useEffect to handle contacts
    useEffect(() => {
        if (contacts.length) {
            const formattedData = contacts.map((contact) => ({
                id: contact.id || 'N/A',
                category: contact.category?.name || 'N/A',
                linkto: contact.resident ? `${contact.resident.first_name} ${contact.resident.last_name}` : 'All Residents',
                resident_id: contact.linkto || 'N/A',
                company: contact.company || 'N/A',
                name: contact.name || 'N/A',
                last_name: contact.last_name || 'N/A',
                relationship: contact.relationship || 'N/A',
                email: contact.email || 'N/A',
                full_name:  `${contact.name}${contact.last_name ? ` ${contact.last_name}` : ''}`,
                address: contact.address || 'N/A',
                phone: phones[contact.id] && phones[contact.id].length > 0 ? phones[contact.id][0].number : 'N/A',
                city: contact.city || 'N/A',
                state: contact.state || 'N/A',
                zipcode: contact.zipcode || 'N/A',
                country: contact.country || 'N/A',
                same_as: contact.same_as || 'N/A'
            }));

            // Only update if `data` is empty to avoid duplicates
            if (data.length === 0) {
                setData(formattedData);
            }
        } else {
            setData([]); // Resetear si no hay contactos
        }
    }, [contacts, phones]);

    // Function to get contacts
    const fetchData = async (page: number, perPage: number, filters?: IContactsResumeData, byResident = false) => {
        setIsLoading(true);
    
        // Si no hay filtros proporcionados, usa los últimos aplicados
        const filterData = filters || lastFilters;
    
        // Verificar si el ID del residente es válido
        const isResidentValid = params.r && params.r !== '';
    
        // Decidir qué acción usar (getContactsByResident o getContacts)
        const response = isResidentValid || byResident
            ? await dispatch(getContactsByResident({
                residentId: params.r,
                page,
                per_page: perPage,
                filters: filterData,
            }))
            : await dispatch(getContacts({
                page,
                per_page: perPage,
                filters: filterData,
            }));
    
        // Si la respuesta no es válida, detener la carga
        if (!response.payload || response.payload.result !== 'ok') {
            setIsLoading(false);
            return;
        }
    
        // Extraer datos y total de contactos de la respuesta
        const { data: newData, total } = response.payload.payload;
    
        // Crear un objeto para almacenar datos de residentes por ID
        const residents = {};
        newData.forEach(contact => {
            if (contact.resident) {
                residents[contact.linkto] = contact.resident; // Asociar 'linkto' con datos del residente
            }
        });
    
        // Transforming contact data
        const newContacts = newData.map(contact => {
            const resident = residents[contact.linkto]; // Obtain resident data
            //console.log("fetch contact: " + JSON.stringify(contact, null, 2));
            const residentFullName = 
            contact.linkto === 'all-residents' 
                ? 'All Residents' 
                : residents[contact.linkto] 
                    ? `${residents[contact.linkto].first_name} ${residents[contact.linkto].last_name}` 
                    : 'N/A'; // Full name or 'N/A'
            return {
                id: contact.id || 'N/A',
                category: contact.category?.name || 'N/A',
                linkto: residentFullName || 'N/A', // Full name of the resident
                resident_id: contact.linkto || 'N/A',
                company: contact.company || 'N/A',
                name: contact.name || 'N/A',
                last_name: contact.last_name || 'N/A',
                relationship: contact.relationship || 'N/A',
                email: contact.email || 'N/A',
                full_name: `${contact.name}${contact.last_name ? ` ${contact.last_name}` : ''}`,
                address: contact.address || 'N/A',
                phones: contact.phones?.filter(phone => phone.delete === 0) || [],
                phone: contact.phones?.find(phone => phone.delete === 0)?.number || 'N/A',
                phone_category: contact.phones?.[0]?.category || 'N/A',
                phone_ext: contact.phones?.[0]?.ext || 'N/A', 
                city: contact.city || 'N/A',
                state: contact.state || 'N/A',
                zipcode: contact.zipcode || 'N/A',
                country: contact.country || 'N/A',
                same_as: contact.same_as || 'N/A',
                resident_full_name: residentFullName,
            };
        });
    
        // Actualizar el estado de los datos
        setData(prevData => page === 1 ? newContacts : [...prevData, ...newContacts]);
    
        // Determinar si hay más filas para paginación
        setHasMoreRows(total > (page * perPage));
    
        // Finalizar la carga
        setIsLoading(false);
    };
    
    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return; // Prevent multiple API calls
    
        const nextPage = currentPage + 1; // calculate the next page
        setCurrentPage(nextPage); // refresh the current page
        fetchData(nextPage, rowsPerPage); // Call fetchData with the following page
    }, [hasMoreRows, isLoading, currentPage]);

    useEffect(() => {
        fetchData(currentPage, rowsPerPage); // load initial data
    }, [currentPage, rowsPerPage]);

    const handleEyeClick = (contact) => {
        setSelectedContact(contact);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedContact(null);
    };

    const handleEditClick = (id, linkto) => {
        // Redirects to the contact editing page
        navigate(`/contacts/edit/${id}?r=${residentId}`, {
            state: { from: locationPath.pathname },
        });
    };

    const handleNewContactClick = () => {
        if (residentId) {
            navigate(`/contacts/new/?r=${residentId}`, {
                state: { from: locationPath.pathname },
            });
        }
    };

    const handleBackClick = () => {
        navigate(`/residents/update?r=${residentId}&t=contacts`);
    };

    const openDeleteDialog = (contact) => {
        //console.log("contact delete: " + JSON.stringify(contact, null, 2));
        setSelectedContactForDelete(contact); // Save the selected contact
        setIsDeleteDialogOpen(true); // Dialog open
    };
      
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false); // Close Dialog
        setSelectedContactForDelete(null); // Clean the selected contact
    };
    
    const handleConfirmDelete = () => {
    if (selectedContactForDelete) {
        handleDeleteClick(selectedContactForDelete.id); // Execute the delete action
    }
    closeDeleteDialog(); // Close the dialog after the action
    };

    const handleDeleteClick = async (contactId: string) => {
        try {
            // Remove contact from backend
            await dispatch(deleteContact(contactId));
    
            // Filter deleted contact from local data before refreshing
            setData((prevData) => prevData.filter(contact => contact.id !== contactId));
    
            // Reload data with current filters
            await fetchData(1, rowsPerPage, lastFilters);
        } catch (error) {
            //console.error('Error deleting contact:', error);
        }
    };
    
    const columnsWithActions = ContactsColumns.map((column) => {
        if (column.key === 'actions') {
          return {
            ...column,
            render: (rowData: any) => renderActionsColumn({ rowData, handleEyeClick, handleEditClick, openDeleteDialog }),
          };
        }
        return column;
    });
    
    const [columns, setColumns] = useState(columnsWithActions);
    const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });

    const handleSort = (columnKey, direction) => {
        setSorting({ key: columnKey, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setData(sortedData);
    };
    //console.log("Data before rendering table:", data); // Verificar data antes de renderizar

    useEffect(() => {
        if (residentId) {
          filterContactsByResident(residentId);
        } else {
          setFilteredContacts(contacts); // If there is no resident, show all contacts
        }
    }, [residentId, contacts]);
    
    // Function to filter contacts based on resident ID
    const filterContactsByResident = (residentId) => {
    const filtered = contacts.filter(contact => contact.resident_id === residentId);
    setFilteredContacts(filtered);
    };

    const filterContact = async (data: z.infer<typeof FormSchema>) => {
        try {
            const { title } = data;
            const filterData: IContactsResumeData = {
                ...lastFilters,
                title
            };
            //console.log('Filter data:', filterData);
            setCurrentPage(1);  // Restart pagination
            await fetchData(1, rowsPerPage, filterData);  // Send updated filters
        } catch (error) {
            //console.error('Error filtering', error);
        }
    };

    const cleanFilter = async () => {
        // Reset the form (make sure to clear all fields that may be active)
        form.reset({
            title: '',        // Clear the title field (if it is part of the filters)
        });
    
        // Reset filters to default state
        const resetFilters: IContactsResumeData = {
            resident_id: '',   // Do not apply resident filter
            location_id: '',   // Do not apply location filter
            page: 1,           // Reset pagination to first page
            per_page: rowsPerPage,  // Maintain the number of results per page
            title: '',         // Clear the title filter if it exists
        };
    
        setCurrentPage(1); // Restart pagination
        await fetchData(1, rowsPerPage, resetFilters);  // Calling fetchData with empty filters
    };

    const handleNewAllContactClick = () => {
        navigate(`/contacts/new/?r=all-residents`);
    };

    return (
        <div className=" ">
            {params.r && (
                <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>

                    <div className="Table">
                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                            <TableCustomVirtuoso
                            filters={
                                <FilterForm
                                    form={form}
                                    onFilter={filterContact}
                                    onCleanFilter={cleanFilter}
                                />
                            }
                            data={data}
                            columns={columns}
                            renderCellContent={(index, column, data) => data[index][column.key]}
                            onSort={handleSort}
                            loadMore={loadMore} // llama la función de scroll infinito
                            hasMore={hasMoreRows} // filas por cargar
                            isLoading={isLoading}
                            showSearchInput={false}
                            additionalButtons={
                                <>
                                    <div className="flex flex-row items-center gap-1">
                                        <Button onClick={handleNewContactClick} className="flex flex-row items-center gap-2 hover:bg-[#B6B6B6]" size="sm">
                                            <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                                            {t('contacts.new')}
                                        </Button>
                                    </div>
                                </>
                            }
                            />
                            {selectedContact && (
                                <DialogContactInfo
                                    contact={selectedContact}
                                    isOpen={isDialogOpen}
                                    onClose={handleCloseDialog}
                                    phones={selectedContact.phones} 
                                />
                            )}
                            {selectedContactForDelete && (
                                <DialogDeleteInfo
                                    contact={selectedContactForDelete} // Contact selected
                                    isOpen={isDeleteDialogOpen} // Estate of dialog
                                    onClose={closeDeleteDialog} // Function to close the dialog
                                    confirm={handleConfirmDelete} // Confirmation action
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Contacts;
import {
  UploadCertificateInServiceDialog
} from '@/modules/staff/presentation/components/training/Dialogs/UploadCertificateInServiceDialog.tsx';
import React from 'react';

interface IProps {
  staffId: string;
  type: string;
}

export const DialogInService: React.FC<IProps> = ({ staffId,type }) => {
  return (
    <>
      <UploadCertificateInServiceDialog
        staffId={staffId}
        type={type}
      />
    </>
  );
};

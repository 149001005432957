import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/common/presentation/components/ui/hover-card';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { cn } from '@/lib/utils';
import { BadgeCheck, BadgeX } from 'lucide-react';
import { useMedicationStore } from '../../domain/stores/useMedicationStore';
import { useMemo } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook';

interface Medication {
  medication_type?: {
    name?: string;
  };
  resident_id?: string;
  time?: string[];
}

interface TimerSelectorProps {
  data?: any;
  onSelect?: (time: string) => void;
  children?: React.ReactNode;
  completedTimes?: string[];
}

export const TimerSelector = ({ onSelect, completedTimes = [], children }: TimerSelectorProps) => {
  const { params } = useRouteParams();
  const residentId = params.r ?? 'all-residents';
  const { medications } = useMedicationStore();

  const routineMedications: Medication[] = useMemo(() => {
    return (medications || []).filter((m: Medication) =>{
      if (residentId && residentId != 'all-residents') {
        return m.resident_id == residentId && m?.medication_type?.name === 'Routine';
      }
      return m?.medication_type?.name === 'Routine';
    }
  );
  }, [medications]);

  const normalizeTimeFormat = (timeString: string): string => {
    const cleaned = timeString.trim().toUpperCase();
    const [time, period] = cleaned.split(' ');
    let [hours, minutes] = time.split(':').map((num) => parseInt(num, 10));

    if (isNaN(hours) || isNaN(minutes)) return '00:00';

    const formattedMinutes = minutes.toString().padStart(2, '0');

    if (!period) {
      return `${hours.toString().padStart(2, '0')}:${formattedMinutes}`;
    }

    if (hours === 12) {
      hours = period === 'AM' ? 0 : 12;
    } else if (period === 'PM' && hours < 12) {
      hours += 12;
    }

    return `${hours.toString().padStart(2, '0')}:${formattedMinutes}`;
  };

  const parseTimeString = (timeString: string): string => {
    if (!timeString || typeof timeString !== 'string') return '00:00';
    return normalizeTimeFormat(timeString);
  };

  const createTimeDate = (time: string): Date | null => {
    const parsedTime = parseTimeString(time);
    if (parsedTime === '00:00' && !time.includes('12:00 AM')) {
      return null;
    }
    const date = new Date(`1970-01-01T${parsedTime}`);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date;
  };

  const formatTimeForDisplay = (date: Date): string => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convertir a formato 12 horas
    if (hours === 0) {
      hours = 12; // Medianoche
    } else if (hours > 12) {
      hours -= 12;
    }

    // Asegurarse de que los minutos tengan dos dígitos
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Para 12 AM, asegurarse de que se muestre como "12:00 AM"
    if (hours === 12 && period === 'AM') {
      return `12:${formattedMinutes} AM`;
    }

    return `${hours}:${formattedMinutes} ${period}`;
  };

  const sortedTimes: string[] = useMemo(() => {
    const times: Date[] = routineMedications.flatMap((medication) => {
      return (medication.time || []).map((time) => createTimeDate(time)).filter((date): date is Date => date !== null);
    });

    const uniqueTimes = Array.from(new Set(times.map(formatTimeForDisplay)));

    return uniqueTimes.sort((a, b) => {
      const dateA = createTimeDate(a);
      const dateB = createTimeDate(b);
      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      }
      return 0;
    });
  }, [routineMedications]);

  const isTimeCompleted = (time: string): boolean => completedTimes.includes(time);

  return (
    <Tabs className="w-full">
      <TabsList className="w-full h-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 bg-transparent gap-2 p-0">
        {sortedTimes.map((time, index) => (
          <TabsTrigger
            key={index}
            value={time}
            className="text-center py-2 rounded-md border-2 data-[state=active]:border-primary relative"
            onClick={() => onSelect?.(time)}
          >
            {time}
            <HoverCard>
              <HoverCardTrigger asChild>
                <div
                  className={cn(
                    'size-4 rounded-full absolute right-3',
                    isTimeCompleted(time) ? 'bg-green-500' : 'bg-red-500'
                  )}
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-auto">
                <div className="flex justify-center">
                  {isTimeCompleted(time) ? (
                    <div className="flex items-center gap-2">
                      <BadgeCheck className="h-6 w-6 text-green-500" />
                      <p>Todas las medicaciones administradas</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <BadgeX className="h-6 w-6 text-red-500" />
                      <p>Algunas medicaciones no administradas</p>
                    </div>
                  )}
                </div>
              </HoverCardContent>
            </HoverCard>
          </TabsTrigger>
        ))}
      </TabsList>
      {children}
    </Tabs>
  );
};

import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Building, CheckIcon, Loader } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  company: z.string().optional(),
  locations: z.array(z.string()).nonempty('You have to select at least one location'),
  preferred_location: z.string().optional(),
  position_title: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

// const rolesToValidate = ['Super Administrator', 'Executive'];

export const CompanyForm = ({ className }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { configurations} = useSelector((state: RootState) => state.configurations);
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const { user } = useSelector((state: RootState) => state.auth);
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const { hasAnyRole } = useRoleHelper();

  const isAdmin = useMemo(() => {
    return hasAnyRole(['Executive', 'Super Administrator']);
  }, [user]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company: '',
      locations: [],
      preferred_location: '',
      position_title: ''
    },
    mode: 'onChange'
  });

  useEffect(() => {
    if (staffMember) {
      form.reset({
        company: configurations?.name  || '',
        locations: staffMember.locations?.map((location: any) => location.id) || [],
        preferred_location: staffMember.preferred_location || '',
        position_title: staffMember.position_title || ''
      });
    }
  }, [staffMember, form]);

  useEffect(() => {
    if (locations.length === 0) {
      dispatch(locationsActions.getLocations());
    }
  }, [locations, dispatch]);

  const locationOptions = useMemo(() => {
    return locations.map((location: any) => ({
      value: location.id,
      label: location.name
    }));
  }, [locations]);

  const onSubmitForm = async (data: FormValues) => {
    const response = await dispatch(
      staffMemberFormActions.updateStaffcompanyForm({
        staff_member_id: staffMember?.id,
        ...data
      })
    );

    if (response.payload) {
      toast({
        description: 'Company staff member updated successfully'
      });
      form.reset(data);
    } else {
      toast({
        description: 'Failed to update company staff member',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Company">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="col-span-2 md:col-span-1">
                      <FormField
                        control={form.control}
                        name="company"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Company</FormLabel>
                            <FormControl>
                              <Input placeholder="" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <div>
                      <FormField
                        control={form.control}
                        name="locations"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Locations <span className="text-primary">*</span>
                            </FormLabel>
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant={'outline'}
                                    className={cn(
                                      'w-full flex items-center justify-start font-normal',
                                      !field.value.length && 'text-muted-foreground'
                                    )}
                                    disabled={staffFormStatus === 'loading' || !isAdmin}
                                  >
                                    <Building className="mr-2 size-4 text-muted-foreground" />
                                    <span>Locations</span>
                                    {field.value?.length > 0 && (
                                      <>
                                        <Separator orientation="vertical" className="mx-2 h-4" />
                                        <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                                          {field.value.length}
                                        </Badge>
                                        <div className="hidden space-x-1 lg:flex">
                                          {field.value.length > 1 ? (
                                            <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                              {field.value.length} selected
                                            </Badge>
                                          ) : (
                                            field.value.map((location) => (
                                              <Badge
                                                variant="secondary"
                                                className="rounded-sm px-1 font-normal"
                                                key={location}
                                              >
                                                {locationOptions.find((t) => t.value === location)?.label}
                                              </Badge>
                                            ))
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className={cn('p-1', !isAdmin && 'pointer-events-none')}>
                                <Command className="w-full">
                                  <CommandInput placeholder="Search location" />
                                  <CommandList>
                                    <CommandEmpty>No results found.</CommandEmpty>
                                    <CommandGroup>
                                      {locationOptions.map((location: any) => {
                                        const isSelected = field.value.includes(location.value);
                                        return (
                                          <CommandItem
                                            value={location.value}
                                            key={location.value}
                                            onSelect={() =>{
                                              console.log([field.value, 'Hola']);
                                              
                                              field.onChange(
                                                isSelected
                                                  ? field.value.filter((t) => t !== location.value)
                                                  : [...field.value, location.value]
                                              )}
                                            }
                                          >
                                            <div
                                              className={cn(
                                                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                                isSelected
                                                  ? 'bg-primary text-primary-foreground'
                                                  : 'opacity-50 [&_svg]:invisible'
                                              )}
                                            >
                                              <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            {location.label}
                                          </CommandItem>
                                        );
                                      })}
                                    </CommandGroup>
                                    {field.value?.length > 0 && (
                                      <>
                                        <CommandSeparator className="mb-1" />
                                        <CommandItem
                                          onSelect={() => {
                                            field.onChange([]);
                                          }}
                                          className="justify-center text-center"
                                        >
                                          Clear locations
                                        </CommandItem>
                                      </>
                                    )}
                                  </CommandList>
                                </Command>
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <div>
                      <FormField
                        control={form.control}
                        name="preferred_location"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Preferred Location for Dashboard</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              value={field.value || ''}
                              defaultValue={field.value || ''}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a resident house" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {locationOptions.map((location: any, index: number) => (
                                  <SelectItem value={location.value} key={index}>
                                    {location.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      control={form.control}
                      name="position_title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Position Title</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading' || !isAdmin} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {form.formState.isDirty && (
                  <div className="mt-6 flex justify-end space-x-2">
                    <Button type="submit" disabled={staffFormStatus === 'loading'}>
                      {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

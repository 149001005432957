import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/store/store';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/presentation/components/ui/accordion";
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { getResidentNavigation } from '@/modules/residents/application/services/getResidentNavigation';
import NavItem from './QuickLinks/NavItem';

export const ResidentNavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const residentNavigationTabs = getResidentNavigation(t)

  return (
    <>
      <div className="hidden lg:block">
        <Card
          className={'w-full p-4 lg:pt-5 border-t-4 border-t-primary/80 dark:bg-zinc-950 col-span-4 h-full'}
        >
          <div className="grid gap-2 rounded-lg bg-card w-full mx-auto dark:bg-zinc-950
            grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 justify-items-start items-center">
            {residentNavigationTabs
            .map((item, index) => (
              <button
                key={index}
                className={`group flex flex-col xl:flex-row items-center justify-start w-full h-full min-h-20 xl:min-h-12 lg:py-3
                  px-4 gap-2 rounded-sm text-sm hover:bg-primary hover:text-white hover:shadow-md cursor-pointer text-zinc-600 dark:text-white`}
                onClick={() => navigate(`/residents/update?r=${resident?.id}&t=${item.value}`)}
              >
                <div className="w-5 h-5 flex-shrink-0">
                  {item.icon}
                </div>

                <span className="truncate text-center break-words whitespace-normal leading-tight">
                  {item.title}
                </span>
              </button>
            ))}
          </div>
        </Card>
      </div>
      <div className="block lg:hidden">
        <Card className="w-full p-4 border-t-4 border-t-primary/80 dark:bg-zinc-950 col-span-4 h-full">
          <CardContent className="p-0 flex-1">
            <Accordion type="single" collapsible>
              <AccordionItem value="quickLinks" className="border-b-0">
                <AccordionTrigger className="p-0 no-underline focus:outline-none focus:ring-0 hover:no-underline">
                  <CardTitle className="text-primary leading-normal text-base font-semibold">
                    {t('residents.profile.profileOptions')}
                  </CardTitle>
                </AccordionTrigger>
                <AccordionContent className="pb-0 mt-4">
                  {residentNavigationTabs
                    .map((item, index) => (
                      <div key={index} className="pb-2 last:pb-0">
                        <NavItem
                          label={item.title}
                          onClick={() => navigate(`/residents/update?r=${resident?.id}&t=${item.value}`)}
                        />
                      </div>
                    ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { format, isAfter, parse, startOfDay } from 'date-fns';
import { Loader, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMedicationStore } from '../../domain/stores/useMedicationStore';
import { usePendingActions } from '@/common/infrastructure/providers/PendingActionsContext';
import { useNewExceptionDialog } from '../../infrastructure/hooks/UseNewException';
import { timeUtils } from '../../domain/timeUtils';
import ConfirmationDialog from './dialogs/ConfirmationDialog';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: React.RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
  selectedTime: string;
  locationId?: string;
  residentId?: string;
}

export interface CachedState {
  dispensed: boolean;
  witnessed: boolean;
  exception: boolean;
  apiDispensed: boolean;
  apiWitnessed: boolean;
}

export const EmarRoutineCard = ({
  className,
  data,
  selectedDate,
  selectedTime,
  setRef,
  locationId,
  residentId
}: MedicationCardProps) => {
  const getCardState = useMedicationStore((state) => state.getCardState);
  const { visible, active } = getCardState(data.special_days, selectedDate);

  const isDateFuture: boolean = useMemo(() => {
    return timeUtils.isDateInFuture(selectedDate);
  }, [selectedDate]);

  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { dispenseMedication, witnessMedication } = useMedicationStore();
  const { addPendingAction } = usePendingActions();
  const [cachedActions, setCachedActions] = useState<{ [key: string]: CachedState }>(() => {
    const stored = localStorage.getItem('cachedActions');
    return stored ? JSON.parse(stored) : {};
  });

  const [isDispenseLoading, setIsDispenseLoading] = useState(false);
  const [isWitnessLoading, setIsWitnessLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const cacheKey = `${selectedDate.toISOString()}_${selectedTime}_${data?.id}`;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const l = queryParams.get('l');
  const effectiveLocationId = l || 'all-locations';
  const formattedDate = useMemo(() => format(selectedDate, 'yyyy-MM-dd'), [selectedDate]);
  const { getMedicationsTimesByResident, medications } = useMedicationStore();
  const medicationsRef = useRef(medications);
  const { onOpen } = useNewExceptionDialog();
// console.log('render from emar routine card') ;

  const { isDispensed, isWitnessed, isException } = (() => {
    const medication = timeUtils.findMedicationByDateTime(data?.emar_routine_medications, selectedDate, selectedTime);
    const exception = timeUtils.findMedicationByDateTime(data?.emar_medication_exceptions, selectedDate, selectedTime);
    const cachedState = cachedActions[cacheKey] || {
      dispensed: false,
      witnessed: false,
      exception: false,
      apiDispensed: false,
      apiWitnessed: false
    };

    return {
      isDispensed: cachedState.dispensed || medication?.dispensed === 1,
      isWitnessed: cachedState.witnessed || medication?.witnessed === 1,
      isException: cachedState.exception || exception?.status === 'active'
    };
  })();

  const handleDispenseClick = (data: any) => {
    if (!data?.chart[0]?.cycle_start_date) {
      setIsDialogOpen(true);
    } else {
      onHandleDispense(data);
    }
  };

  const onHandleDispense = async (medicationData: any) => {
    const residentId = medicationData?.resident_id || queryParams.get('r') || 'all-residents';
    if (cachedActions[cacheKey]?.dispensed || isDispensed) {
      return;
    }

    setIsDispenseLoading(true);

   setCachedActions((prev) => {
      const newCachedActions = {
        ...prev,
        [cacheKey]: {
          ...prev[cacheKey],
          dispensed: true,
          apiDispensed: false
        }
      };
      localStorage.setItem('cachedActions', JSON.stringify(newCachedActions));
      return newCachedActions;
    });


    const dispenseAction = {
      type: 'dispense',
      medication: medicationData,
      action: async () => {
        try {
          await dispenseMedication(
            locationId!,
            residentId!,
            medicationData.id,
            selectedTime,
            format(selectedDate, 'yyyy-MM-dd')
          );

          setTimeout(() => {
            setIsDispenseLoading(false);
          }, 1000);

          setCachedActions((prev) => {
            const newCachedActions = {
              ...prev,
              [cacheKey]: {
                ...prev[cacheKey],
                apiDispensed: true
              }
            };
            localStorage.setItem('cachedActions', JSON.stringify(newCachedActions));
            return newCachedActions;
          });
        } catch (error) {
          console.error('Error dispensing medication:', error);
          throw error;
        }
      }
    };

      addPendingAction(dispenseAction);

  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDialog = () => {
    onHandleDispense(data);
    setIsDialogOpen(false);
  };

  const onHandleWitness = async (medicationDataList: any | any[]) => {
    const dataToIterate = Array.isArray(medicationDataList) ? medicationDataList : [medicationDataList];

    for (const medicationData of dataToIterate) {
      const residentId = medicationData?.resident_id || queryParams.get('r') || 'all-residents';
      const currentCacheKey = `${selectedDate.toISOString()}_${selectedTime}_${medicationData?.id}`;

      if (cachedActions[currentCacheKey]?.witnessed || isWitnessed) return;

      setIsWitnessLoading(true);

      setCachedActions((prev) => {
        const newCachedActions = {
          ...prev,
          [currentCacheKey]: {
            ...prev[currentCacheKey],
            witnessed: true,
            apiWitnessed: false
          }
        };
        localStorage.setItem('cachedActions', JSON.stringify(newCachedActions));
        return newCachedActions;
      });

      const witnessAction = {
        type: 'witness',
        medication: medicationData,
        action: async () => {
          try {
            let medication = medicationsRef.current.find((med: any) => med.id === medicationData.id);

            if (!medication) return;

            if (medication.witnessed) {
              return;
            }
         
            await witnessMedication(
              locationId!,
              residentId!,
              medicationData.id,
              selectedTime,
              format(selectedDate, 'yyyy-MM-dd')
            );
            setTimeout(() => {
              setIsWitnessLoading(false);
            }, 1000);
            // fetchMedications(residentId);

            setCachedActions((prev) => {
              const newCachedActions = {
                ...prev,
                [currentCacheKey]: {
                  ...prev[currentCacheKey],
                  apiWitnessed: true
                }
              };
              localStorage.setItem('cachedActions', JSON.stringify(newCachedActions));
              return newCachedActions;
            });
          } catch (error) {
            console.error('Error witnessing medication:', error);
            throw error;
          }
        }
      };

      addPendingAction(witnessAction);
      // addPendingAction(witnessAction);
    }
  };

  return (
    <>
      <Card
        className={cn(
          'flex flex-col h-full min-h-[500px]',
          !active && 'opacity-50 pointer-events-none cursor-not-allowed',
          className
        )}
        ref={cardRef}
      >
        <CardContent className="space-y-2 flex-grow flex flex-col">
          <CardHeader className="px-0">
            {!active && (
              <p className="mt-2 text-sm font-bold text-red-500 w-full text-center">
                {t('medication_disabled_message', 'Medication is disabled for special day instructions.')}
              </p>
            )}
            <div className="flex flex-wrap gap-2 items-center justify-between">
              {active && (
                <>
                  {!isWitnessed && !isException && (
                    <div className="flex flex-wrap gap-2">
                      <Badge variant="outline" className="text-md font-bold border-red-500 text-red-500">
                        Not Completed
                      </Badge>
                    </div>
                  )}

                  {isDispensed && !isWitnessed && !isException && (
                    <div className="flex flex-wrap gap-2">
                      <Badge variant="outline" className="text-md font-bold border-blue-500 text-blue-500">
                        Dispensed
                      </Badge>
                    </div>
                  )}

                  {((isDispensed && isWitnessed) || isException) && (
                    <div className="flex flex-wrap gap-2">
                      <Badge variant="outline" className="text-md font-bold border-green-500 text-green-500">
                        Completed
                      </Badge>
                    </div>
                  )}

                  {isException && (
                    <div className="flex flex-wrap gap-2">
                      <Badge variant="outline" className="text-md font-bold border-gray-500 text-gray-500">
                        Exception
                      </Badge>
                    </div>
                  )}
                </>
              )}
            </div>
            <CardTitle className="text-xl font-bold">
              {data?.medication?.name?.toUpperCase()}{' '}
              {data?.strength && data?.form ? `${data.strength} ${data.form}` : data?.form}
              {data?.responsible_of_administration?.length > 0 && (
                <span className="ml-2 text-2xl font-bold text-red-500">C</span>
              )}
            </CardTitle>
          </CardHeader>
          <Separator />
          <div className="text-md">
            <p>
              RX #: <span className="font-bold">{data?.chart[0]?.rx || ''}</span>
            </p>
            <p>
              Start Date:{' '}
              <span className="font-bold">
                {data?.chart[0].cycle_start_date
                  ? format(
                      parse(data?.chart[0]?.cycle_start_date, 'yyyy-MM-dd', new Date()),
                      t('dateFormats.dateFormat')
                    )
                  : ''}
              </span>
            </p>
            <p>
              Units per dose: <span className="font-bold">{data?.units_per_dose || ''}</span>
            </p>
            <p>
              Physician: <span className="font-bold">{data?.prescribing_physician?.name || ''}</span>
            </p>
          </div>
          <Separator />
          <div className="space-y-2">
            <h3 className="text-lg font-bold">Instructions</h3>
            <p className="text-md">{data?.instruction || ''}</p>
          </div>
          <Separator />
          <div className="space-y-2">
            <h3 className="text-lg font-bold">Special Instructions</h3>
            <p className="text-md">{data?.special_instructions || ''}</p>
          </div>
        </CardContent>
        <CardFooter className="mt-auto w-full flex flex-col gap-2">
          <div className="w-full grid grid-cols-2 space-x-2">
            {!isDispensed && (
              <Button
                variant={'outline'}
                size={'sm'}
                className="text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                onClick={() => handleDispenseClick(data)}
                disabled={isDispenseLoading || isException || cachedActions[cacheKey]?.dispensed}
              >
                <span className="font-bold">Dispensed</span>
              </Button>
            )}

            {isDispensed && (
              <Button
                variant={'outline'}
                size={'sm'}
                className="text-green-500 border-green-500 hover:bg-green-500 hover:text-white"
                onClick={() => onHandleWitness(data)}
                disabled={
                  isWitnessLoading || isException || isWitnessed || cachedActions[cacheKey]?.witnessed || isDateFuture
                }
              >
                <span className="font-bold">Witnessed</span>
              </Button>
            )}

            <Button
              variant={'outline'}
              size={'sm'}
              className="text-gray-400 border-gray-400 hover:bg-gray-400 hover:text-white"
              disabled={isException || isWitnessed || isDateFuture}
              onClick={() => {
                onOpen(data, selectedDate, [selectedTime], locationId, residentId);
              }}
            >
              <span className="font-bold">Exception</span>
            </Button>
          </div>
        </CardFooter>
      </Card>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        startDate={selectedDate}
      />
    </>
  );
};
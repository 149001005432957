import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMedicationsByClientId,
  fetchMedicationAudits,
  copyMedication,
  GenerateTimesPDF,
  deleteMedication
} from '../slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { ArrowLeft } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import useColumnsResidentMedications from './columnsResidentMedications';
import RenderActionsColumn from './renderActionsColumn';
import ConfirmationModal from './ConfirmationModal';
import DialogAudit from '../components/Historial/DialogAudit';
import useColumnsAudit from '../components/Historial/columnsAudit';
import { useNewListMedication } from '../../infrastructure/hooks/use-new-list-medications';
import FilterTable from './FilterTable';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import CreateMedication from '../components/CreateMedications/CreateMedication';
import { format, isValid, parse, parseISO } from 'date-fns';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { NavigationItems } from '../components/NavigationItems';
import { useCustomToast } from '@/common/presentation/components/CustomToast/CustomToast';
import ImageViewer from '../components/VisorImages/ImageViewer';
import { convertToSpacedWords, formatValues } from '@/utils/helpers/medications.helper';
import { useLocation as useLocationSelectedId } from '@/modules/locations/infrastructure/providers/LocationContextProvider.tsx';
const MedicationsPage: React.FC = () => {
  const { onOpen } = useNewListMedication();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { location: locationSelector } = useSelector((state: RootState) => state.locations.location);
  const { medicationsByClientId, medicationstatus, totalItems } = useSelector((state: RootState) => state.medications);
  const columnsAudit = useColumnsAudit();
  const locationPage = useLocation();
  const [shouldOpenCopy, setShouldOpenCopy] = useState(false);
  const [shouldOpeAudit, setShouldOpeAudit] = useState(false);
  const [audits, setAudits] = useState<any[]>([]);
  const [filters, setFilters] = useState({ status: 'true' });
  
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const [medications, setMedications] = useState<any[]>([]);
  const [selectedMedicationId, setSelectedMedicationId] = useState<string | undefined>(undefined);
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const [showForm, setShowForm] = useState(false);
  const columnsResidentMedications = useColumnsResidentMedications(filters.status);
  const dateFormat = t('dateFormats.dateTimeFormat');
  const { showToast } = useCustomToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [selectedImages, setSelectedImages] = useState<Array<{ id: string; image: string }>>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchMedication, setSearchMedication] = useState<string>('');
  const { locationSelectedId } = useLocationSelectedId();


  
  const typeOptions = [
    { name: 'Routine', value: 'routine' },
    { name: 'PRN', value: 'prn' }
  ];

  const statusOptions = [
    { name: 'Active', value: 'true' },
    { name: 'Inactive', value: 'false' },
    { name: 'ALL', value: 'ALL' }
  ];
  useEffect(() => {
    const storedLocation = sessionStorage.getItem('location_selected');

    if (storedLocation && !searchParams.has('l')) {
      setSearchParams({ l: storedLocation });
    }
  }, [searchParams, setSearchParams]);

  const fetchData = useCallback(
    async ({ page = 1, newFilters = {}, reset = false } = {}) => {
      setLoading(true);

      const mergedFilters: any = reset 
      ? newFilters 
      : { ...filters, ...newFilters }
      ;
      
      const filterType = mergedFilters.type && mergedFilters.type !== 'ALL' ? mergedFilters.type : '';
      const filterStatus =
        mergedFilters.status === 'true' || mergedFilters.status === 'false' || mergedFilters.status === 'ALL'
          ? mergedFilters.status
          : '';

      const locationId = searchParams.get('l') || params.l;
      const residentId = searchParams.get('r') || params.r;
      const filter = searchMedication || mergedFilters.filter || '';
      const status = mergedFilters.status || '';

      try {
        const response = await dispatch(
          getAllMedicationsByClientId({
            locationId,
            residentId,
            filter,
            type: filterType,
            status: filterStatus,
            page,
            limit: pageSize
          })
        ).unwrap();

        if (reset) {
          setMedications(response.data);
          setCurrentPage(1);
          setHasMore(true);
        } else {
          setMedications(response.data);
          setCurrentPage(page);
          setHasMore(response.data.length === pageSize);
        }
        
        setFilters(Object.keys(mergedFilters).length == 0 ? { status: 'true' } :  mergedFilters);

      } catch (error) {
        showToast('error', t('errors.dataFetchFailed'), t('errors.tryAgain'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, searchParams, searchMedication, params, filters, pageSize, t, showToast]
  );

  useEffect(() => {
    fetchData();
  }, [dispatch, searchMedication]);

  useEffect(() => {
    const locationId = searchParams.get('l') || params.l;
    const residentId = searchParams.get('r') || params.r;

    if (locationId && residentId) {
      fetchData({ reset: true });
    }
  }, [searchParams, params]);

  useEffect(() => {
    const locationParam = searchParams.get('l');

    if (locationParam && locationParam !== 'all-locations' && searchParams.has('r')) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('r');
      setSearchParams(newSearchParams);

      fetchData({ reset: true });
    } else if (locationParam) {
      fetchData({ reset: true });
    }
  }, [searchParams.get('l')]);

  const transformedMedications = useMemo(() => {
    return medicationsByClientId.map((medication) => {
      const filleDate = medication?.filleDate
        ? isValid(parse(medication.filleDate, 'yyyy-MM-dd', new Date()))
          ? format(parse(medication.filleDate, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
          : ''
        : '';

      return {
        ...medication,
        time: medication.time ? [...new Set(medication.time)].join(', ') : '',
        filleDate,
        status: medication.status
      };
    });
  }, [medicationsByClientId, t]);

  const handleEdit = useCallback(
    (medicationId: number) => {
      navigate(`/medications/edit/${medicationId}?l=${params.l || ''}`);
    },
    [navigate, params.l]
  );

  const handleCopy = (medicationId: string) => {
    setSelectedMedicationId(medicationId);
    setShouldOpenCopy(true);
  };

  const handleAudit = useCallback(
    async (medicationId: string) => {
      setShouldOpeAudit(true);
      try {
        const response = await dispatch(fetchMedicationAudits(medicationId)).unwrap();
        const formatDate = (dateString: string) => {
          const parsedDate = parseISO(dateString);
          if (isValid(parsedDate)) {
            return format(parsedDate, dateFormat);
          }
          return '';
        };

        const formattedAudits = (response || []).map((audit: any) => {
          if (audit.changed_at) {
            const auditCopy = { ...audit };

            const parsedDate = parseISO(auditCopy.changed_at);

            if (auditCopy.old_value && !isNaN(Date.parse(auditCopy.old_value))) {
              auditCopy.old_value = formatDate(auditCopy.old_value);
            }

            if (auditCopy.new_value && !isNaN(Date.parse(auditCopy.new_value))) {
              auditCopy.new_value = formatDate(auditCopy.new_value);
            }

            if (auditCopy.changed_at && !isNaN(Date.parse(auditCopy.changed_at))) {
              auditCopy.changed_at = formatDate(auditCopy.changed_at);
            }

            if (isValid(parsedDate)) {
              const formattedDate = format(parsedDate, dateFormat);

              auditCopy.changed_at = formattedDate;
            } else {
              auditCopy.changed_at = 'Fecha inválida';
            }

            return {
              ...auditCopy,
              field: convertToSpacedWords(auditCopy.field),
              old_value: formatValues(auditCopy.field, auditCopy.old_value),
              new_value: formatValues(auditCopy.field, auditCopy.new_value)
            };
          }
          return audit;
        });

        setAudits(formattedAudits);
      } catch (error) {
        console.error('Error fetching medication audits:', error);
        setAudits([]);
      }
    },
    [dispatch, dateFormat]
  );

  const handleImage = useCallback(
    (rowData: any) => {
      const medication = medications.find((med) => med.id === rowData);

      if (medication) {
        setSelectedImages(medication.images || []);
      } else {
        console.warn('No medication found for the given rowData');
        setSelectedImages([]);
      }

      setShowImageDialog(true);
    },
    [medications]
  );

  const handleDelete = useCallback(
    (rowData: any) => {
      const medication = medications?.data.find((med) => med.id === rowData);

      if (medication) {
        setSelectedMedication(medication);
        setShowConfirmationDialog(true);
      } else {
        console.warn('No se encontró una medicina para el ID proporcionado.');
      }
    },
    [medications]
  );

  const handleGoBack = () => {
    navigate(locationPage.key === 'default' ? '/medications' : -1);
  };

  const handleConfirmCopy = async () => {
    if (selectedMedicationId) {
      try {
        await dispatch(copyMedication({ medicationId: selectedMedicationId })).unwrap();
        setShouldOpenCopy(false);
        await fetchData();
        showToast('success', t('medications.copy.success'), t('medications.copy.copied'));
      } catch (error) {
        console.error('Error copying medication:', error);
      }
    }
  };

  const handleSort = useCallback(
    (columnKey, direction) => {
      setSorting({ key: columnKey, direction });

      const sortedData = [...medications].sort((a, b) => {
        if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
        if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
        return 0;
      });

      setMedications(sortedData);
    },
    [medications]
  );

  const handleTimesPdf = async () => {
    try {
      const locationId = params.l;
      const resultAction = await dispatch(GenerateTimesPDF({ locationId }));
      if (GenerateTimesPDF.fulfilled.match(resultAction)) {
        const pdfBlob = resultAction.payload;

        const url = URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));

        window.open(url, '_blank');
      } else {
        console.error('Failed to generate PDF:', resultAction.error.message);
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handlecentralized = () => {
    navigate('/medications/centralized');
  };

  const handledestruction = () => {
    navigate('/medications/destruction');
  };

  const handleMedicationCreated = () => {
    fetchData();
    setShowForm(false);
  };

  return (
    <div>
      <div>
        <LocationSelector quickView={true} />
        <div className="flex items-center justify-between space-x-4 mb-4 my-5">
          <div className="flex items-center space-x-4">
            <Button variant="ghost" onClick={handleGoBack}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('medications.back')}
            </Button>
            <div className="font-semibold text-2xl">
              {t('medications.title')}{' '}
              <span className="font-bold">
                {params.l === 'all-locations' ? t('common.allLocations') : locationSelector?.name}
              </span>
            </div>
          </div>
          {
            (locationSelectedId && locationSelectedId != 'all-locations') &&
            (
              <Tabs className="ml-auto">
                <TabsList>
                  <TabsTrigger
                    value="centralized"
                    onClick={handlecentralized}
                    className="data-[state=active]:bg-primary data-[state=active]:text-white"
                  >
                    {t('medications.centralized')}
                  </TabsTrigger>
                  <TabsTrigger
                    value="destruction"
                    onClick={handledestruction}
                    className="data-[state=active]:bg-primary data-[state=active]:text-white"
                  >
                    {t('medications.destruction')}
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            )
          }

        </div>

        <ResidentSelector
          locationId={params.l || 'all-locations'}
          residentId={params?.r || 'all-residents'}
          setLocationParams={false}
          showAllResidents={false}
        />

        <div className="flex flex-col gap-4 my-4">
          <TableCustomVirtuoso
            tableId="medication-table"
            data={transformedMedications}
            totalRecords={totalItems}
            columns={columnsResidentMedications.map((col) =>
              col.key === 'actions'
                ? {
                    ...col,
                    render: (rowData) => (
                      <RenderActionsColumn
                        rowData={rowData}
                        handleEdit={handleEdit}
                        handleCopy={handleCopy}
                        handleAudit={handleAudit}
                        handleImage={handleImage}
                        handleDelete={handleDelete}
                      />
                    )
                  }
                : col
            )}
            onFilterChange={(val: string) => setSearchMedication(val)}
            renderCellContent={(index, column, data) => data[index][column.key]}
            onSort={handleSort}
            loadMore={() => fetchData({ page: currentPage + 1 })}
            hasMore={hasMore}
            isLoading={medicationstatus === 'loading'}
            rowClickPath={(rowData) => `/medications/edit/${rowData.id}`}
            onFilterClear={() => {
              fetchData({ reset: true, newFilters: {} });
              setSearchMedication('');
            }}
            additionalButtons={
              <>
                <NavigationItems />
                <Button
                  onClick={() => onOpen(params.l || undefined, params.r || undefined)}
                  size="sm"
                  className="flex items-center"
                >
                  <img src="/svg/pdf_icon.svg" alt="Icono de PDF" className="mr-1 w-4 h-4" />
                  <span className="text-xs">{t('medications.listMedications')}</span>
                </Button>
                {params.l && params.l !== 'all-locations' && (
                  <Button className="flex flex-row items-center gap-2" onClick={handleTimesPdf} size="sm">
                    <img src="/svg/pdf_icon.svg" alt="Icono de PDF" className="size-5" />
                    {t('medications.times')}
                  </Button>
                )}
                {params.r && (
                  <Button onClick={() => setShowForm(true)} size="sm">
                    {t('medications.newMedication')}
                  </Button>
                )}
              </>
            }
            filters={
              <FilterTable
                typeOptions={typeOptions}
                statusOptions={statusOptions}
                filters={filters}
                setFilters={setFilters}
                fetchFilteredData={(newFilters) => fetchData({ reset: true, newFilters })}
              />
            }
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={shouldOpenCopy}
        onClose={() => setShouldOpenCopy(false)}
        onConfirm={handleConfirmCopy}
        message={t('medications.confirmationModal.copyMessage')}
      />

      <DialogAudit
        isOpen={shouldOpeAudit}
        onClose={() => setShouldOpeAudit(false)}
        audits={audits}
        columns={columnsAudit}
        locationId={''}
      />

      <CustomDialog
        isOpen={showForm}
        onClose={() => setShowForm(false)}
        title={t('medications.formNewMedication')}
        className="md:max-w-[725px] lg:max-w-[725px]"
      >
        <CreateMedication onClose={handleMedicationCreated} />
      </CustomDialog>

      <CustomDialog
        isOpen={showImageDialog}
        onClose={() => setShowImageDialog(false)}
        title={t('medications.images')}
        className="md:max-w-[625px] lg:max-w-[625px]"
      >
        <ImageViewer images={selectedImages} onClose={() => setShowImageDialog(false)} maxZoom={4} minZoom={0.25} />
      </CustomDialog>
      <ConfirmationModal
        isOpen={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={async () => {
          try {
            if (selectedMedication) {
              await dispatch(deleteMedication(selectedMedication.id)).unwrap();
              showToast('success', 'Medication Deleted', 'The medication was successfully deleted');
              fetchData();
            }
          } catch (error) {
            console.error('Error deleting medication:', error);
            showToast('error', 'Error Deleting', 'The medication could not be deleted');
          } finally {
            setShowConfirmationDialog(false);
          }
        }}
        message={`Are you sure you want to delete the medication ${
          selectedMedication?.medication?.name?.charAt(0).toUpperCase() + selectedMedication?.medication?.name?.slice(1)
        }?`}
      />
    </div>
  );
};

export default MedicationsPage;

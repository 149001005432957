import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EmarService from '@/modules/emar/infrastructure/services/EmarService';

interface EmarRoutineState {
  medications: any[];
  reasons: any[];
  status: 'idle' | 'loading' | 'failed';
  medicationCardStatus: 'idle' | 'loading' | 'failed';
  error: string | null;
}

const initialState: EmarRoutineState = {
  medications: [],
  reasons: [],
  status: 'idle',
  medicationCardStatus: 'idle',
  error: null
};

export const getResidentMedicationsTimesByResident = createAsyncThunk(
  'emar/getResidentMedicationsTimesByResident',
  async (data: { locationId: string; residentId: string; date?: string }) => {
    const response = await EmarService.getResidentMedicationsTimesByResident(
      data.locationId,
      data.residentId,
      data.date
    );
    return response;
  }
);

export const handleDispenseMedication = createAsyncThunk('emar/handleDispenseMedication', async (data: any) => {
  const response = await EmarService.handleDispenseMedication(data);
  return response;
});

export const handleWitnessMedication = createAsyncThunk('emar/handleWitnessMedication', async (data: any) => {
  const response = await EmarService.handleWitnessMedication(data);
  return response;
});

export const handleExceptionMedication = createAsyncThunk('emar/handleExceptionMedication', async (data: any) => {
  const response = await EmarService.handleExceptionMedication(data);
  return response;
});

export const handleDispensePrnMedication = createAsyncThunk('emar/handleDispensePrnMedication', async (data: any) => {
  const response = await EmarService.handleDispensePrnMedication(data);
  return response;
});

export const fetchReasons = createAsyncThunk(
  'reasons/fetchReasons',
  async () => {
    const response = await EmarService.getAllReasons();
    return response;
  }
);

const emarSlice = createSlice({
  name: 'emar',
  initialState,
  reducers: {
    setMedications: (state, action) => {
      state.medications = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    
      .addCase(getResidentMedicationsTimesByResident.pending, (state) => {
        state.status = 'loading';
        state.medications = [];
        state.error = null;
      })
      .addCase(getResidentMedicationsTimesByResident.fulfilled, (state, action) => {
        state.status = 'idle';
        state.medications = action.payload;
        state.error = null;
      })
      .addCase(getResidentMedicationsTimesByResident.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
        state.medications = [];
      });

    builder
      .addCase(handleDispenseMedication.pending, (state) => {
        state.medicationCardStatus = 'loading';
        state.error = null;
      })
      .addCase(handleDispenseMedication.fulfilled, (state, action) => {
        state.medicationCardStatus = 'idle';
        state.medications = action.payload;
        state.error = null;
      })
      .addCase(handleDispenseMedication.rejected, (state, action) => {
        state.medicationCardStatus = 'failed';
        state.error = action.error.message || null;
      });

      builder
      .addCase(fetchReasons.pending, (state) => {
        state.reasons = [];
        state.error = null;
      })
      .addCase(fetchReasons.fulfilled, (state, action) => {
        state.reasons = action.payload;
        state.error = null;
      })
      .addCase(fetchReasons.rejected, (state, action) => {
        state.medicationCardStatus = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(handleWitnessMedication.pending, (state) => {
        state.medicationCardStatus = 'loading';
        state.error = null;
      })
      .addCase(handleWitnessMedication.fulfilled, (state, action) => {
        state.medicationCardStatus = 'idle';
        state.medications = action.payload;
        state.error = null;
      })
      .addCase(handleWitnessMedication.rejected, (state, action) => {
        state.medicationCardStatus = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(handleExceptionMedication.pending, (state) => {
        state.medicationCardStatus = 'loading';
        state.error = null;
      })
      .addCase(handleExceptionMedication.fulfilled, (state, action) => {
        state.medicationCardStatus = 'idle';
        state.medications = action.payload;
        state.error = null;
      })
      .addCase(handleExceptionMedication.rejected, (state, action) => {
        state.medicationCardStatus = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(handleDispensePrnMedication.pending, (state) => {
        state.medicationCardStatus = 'loading';
        state.error = null;
      })
      .addCase(handleDispensePrnMedication.fulfilled, (state, action) => {
        state.medicationCardStatus = 'idle';
        state.medications = action.payload;
        state.error = null;
      })
      .addCase(handleDispensePrnMedication.rejected, (state, action) => {
        state.medicationCardStatus = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export const { setMedications } = emarSlice.actions;
export default emarSlice.reducer;

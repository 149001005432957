import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { servicesNeeded } from "../../constants/residentAppraisal.constants";

const ServicesNeededForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center md:justify-left gap-2 md:gap-4 mb-4">
        <h4 className="font-semibold text-base">
          {t('residents.forms.residentAppraisal.serviceNeeded')}
        </h4>
        <p className="text-sm font-normal">
          ({t('residents.forms.residentAppraisal.checkItemsAndExplain')})
        </p>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="space-y-4">
        {servicesNeeded.map((service) => (
          <div key={service.name} className="grid grid-cols-1 lg:grid-cols-[1fr,1fr,2fr] gap-4 items-start">
            <FormField
              control={control}
              name={`services_needed.${service.name}.status`}
              render={({ field }) => (
                <FormItem>
                  <RadioGroup
                    value={field.value}
                    onValueChange={field.onChange}
                    className="flex items-center space-x-4"
                  >
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem value="yes" id={`service-needed-yes-${service.name}`} />
                      <span className="text-sm">{t("common.yes")}</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem value="no" id={`service-needed-no-${service.name}`} />
                      <span className="text-sm">{t("common.no")}</span>
                    </label>
                  </RadioGroup>
                </FormItem>
              )}
            />

            <FormLabel className="font-normal">{t(`residents.forms.residentAppraisal.servicesNeededList.${service.name}`)}</FormLabel>

            <FormField
              control={control}
              name={`services_needed.${service.name}.comment`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      {...field}
                      className="dark:bg-zinc-800 w-full"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        ))}
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 lg:grid-cols-[1fr,1fr,2fr] gap-4 items-start">
        <FormField
          control={control}
          name="additional_info.status"
          render={({ field }) => (
            <FormItem>
              <RadioGroup
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
                className="flex items-center space-x-4"
              >
                <label className="flex items-center space-x-2">
                  <RadioGroupItem value="yes" id="additional-info-yes" />
                  <span className="text-sm">{t("common.yes")}</span>
                </label>
                <label className="flex items-center space-x-2">
                  <RadioGroupItem value="no" id="additional-info-no" />
                  <span className="text-sm">{t("common.no")}</span>
                </label>
              </RadioGroup>
            </FormItem>
          )}
        />

        <FormLabel className="font-normal">
          {t('residents.forms.residentAppraisal.serviceNeededInfo')}
        </FormLabel>

        <FormField
          control={control}
          name="additional_info.comment"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  className="dark:bg-zinc-800 w-full"
                  maxLength={120}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </>
  );
};

export default ServicesNeededForm;

import { Dialog, DialogContent, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import { Calendar as CalendarIcon } from "lucide-react";
import { Label } from '@/common/presentation/components/ui/label';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { updateSleepingHours } from '../../slices/residentsForm';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { toast } from '@/common/presentation/components/ui/use-toast';

interface SleepingHoursPropsI {
    isOpen: boolean;
    onClose: () => void;
}

export function SleepingHours({ isOpen, onClose }: SleepingHoursPropsI) {
    const residentName = useSelector((state: RootState) => {
        const resident = state.residents.resident.resident;
        return `${resident.first_name} ${resident.last_name}`;
    });
    const { t } = useTranslation();
    const resident = useSelector((state: RootState) => state.residents.resident.resident);
    const [asleep, setAsleep] = useState<Date | null>(
        resident?.sleeping_hours[0]?.asleep ? new Date(resident.sleeping_hours[0].asleep) : null
    );

    const [awake, setAwake] = useState<Date | null>(
        resident?.sleeping_hours[0]?.awake ? new Date(resident.sleeping_hours[0].awake) : null
    );
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();


    const handleAsleepChange = (date: Date | null) => {
        setAsleep(date);
    };

    const handleAwakeChange = (date: Date | null) => {
        setAwake(date);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmit(true);
        const payload = {
            resident_id: resident?.id as string,
            asleep: asleep?.toISOString() || null,
            awake: awake?.toISOString() || null,
        };

        try {
            await dispatch(updateSleepingHours(payload))
            setIsSubmit(false);
            toast({
                description: t('residents.sleepingSaved'),
                className: 'bg-green-500 text-white'
            });
            onClose();

        } catch (error) {
            console.log(error);

        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose} key={'sleeping'}>
            <DialogContent className="max-w-[92%] min-h-[50vh] flex flex-col gap-6 md:max-w-[45%] rounded-lg p-6 border-t-4 border-t-primary/80">

                <DialogTitle> <p className="text-primary">{residentName} {t('residents.sleep')}</p></DialogTitle>
                <Separator className="bg-primary" />

                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col w-full p-2">
                            <Label htmlFor="tracker_sleep_date" className="control-label mb-3">
                            {t('residents.asleep')}
                            </Label>
                            <div className="input-group">
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {asleep ? asleep.toLocaleString() : "Select a date"}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            selectedDate={asleep}
                                            onChange={handleAsleepChange}
                                            MonthAndYearPicker={false}
                                            timePicker={true}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>

                        <div className="flex flex-col w-full p-2">
                            <Label htmlFor="tracker_bowel_date" className="control-label mb-3">
                            {t('residents.awake')}
                            </Label>
                            <div className="input-group">
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {awake ? awake.toLocaleString() : t('trackers.trackerBedroom.selectDate')}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            selectedDate={awake}
                                            onChange={handleAwakeChange}
                                            MonthAndYearPicker={false}
                                            timePicker={true}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>
                        <div className="w-full flex justify-center mt-4">
                            <Button type="submit"
                                className="bg-primary text-white px-4 py-2 rounded"
                                disabled={isSubmit}
                            >
                                {t('trackers.Save')}
                            </Button>
                        </div>
                    </form>
                </div>


            </DialogContent>

        </Dialog >
    )
}
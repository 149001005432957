import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { IEmarExceptionForm } from '@/modules/emar/domain/interfaces/types';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';
import { ExceptionForm } from '@/modules/emar/presentation/components/forms/NewExceptionForm';
import { RootState } from '@/store/store';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { CachedState } from '../EmarRoutineCard';
import { timeUtils } from '@/modules/emar/domain/timeUtils';
import { object } from 'zod';

export const NewExceptionDialog = () => {
  const { isOpen, onClose, data, date, time, locationId, isGeneral } = useNewExceptionDialog();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { reasons } = useSelector((state: RootState) => state.emar.emarRoutine);
  const { medicationException, status: medicationCardStatus } = useMedicationStore();
  const getCardState = useMedicationStore((state) => state.getCardState);

  const onSubmit = async (values: IEmarExceptionForm) => {
    if (!date) return;

   const residentId = resident?.id ?? data?.resident_id;
    try {
      const exceptionData = isGeneral 
        ? getArrayMedicationExceptions(values)
        : [{
            resident_medication_id: data?.id,
            date_to_be_dispensed: format(date, 'yyyy-MM-dd'),
            times: timeUtils.removeLeadingZeroFromTimes(values.time),
            reason: values.reason ?? '',
            discount_unit: values.discount_unit,
          }];

      if(exceptionData.length === 0) {
       onClose();
       return;
      }

      await medicationException(
        exceptionData,
        locationId!,
        residentId,
        data?.emar_medication_exception?.id,
        values.reason_id,
        values.reason ?? '',
        values.details,
        isGeneral
      );
  
      onClose();
      toast({ description: 'Exception created successfully' });
    } catch(err) {
      console.log(err);      
      toast({ 
        description: 'Failed to create exception',
        variant: 'destructive'
      });
    }
  };

  const getArrayMedicationExceptions = (
    values: IEmarExceptionForm
  ) => {

    return data.
    filter((medication: any) => {
      const { active: hasNoSpecialDay } = getCardState(medication.special_days, date);
      return hasNoSpecialDay
    })
    .map((medication: any) => {
        const times = timeUtils.removeLeadingZeroFromTimes(
          (values.time.includes('all-day') ? medication.time : values.time)
            ?.filter((time: string) => {
              const { isException, isWitnessed } = getMedicationTimeStatus(medication, time);
              return medication.time.includes(time) && !isException && !isWitnessed;
            }) ?? []
        );
      if (times.length === 0) return null;
      return {
        resident_medication_id: medication.id,
        date_to_be_dispensed: format(date!, 'yyyy-MM-dd'),
        times,
        reason: values.reason ?? '',
        discount_unit: false,
      };

    })?.filter((item: any) => item != null);
   
  }

  const getMedicationTimeStatus = (medication: any, time: string) => {

    const cachedActions: { [key: string]: CachedState } = (() => {
      const stored = localStorage.getItem('cachedActions');
      return stored ? JSON.parse(stored) : {};
    })();

    const cacheKey = `${date!.toISOString()}_${time}_${medication.id}`;
    const cachedState = cachedActions[cacheKey] || {
      dispensed: false,
      witnessed: false,
      exception: false,
      apiDispensed: false,
      apiWitnessed: false
    };

    return (() => {
      const medicationResult = timeUtils.findMedicationByDateTime(
        medication.emar_routine_medications,
        date!,
        time
      );
      const exception = timeUtils.findMedicationByDateTime(
        medication.emar_medication_exceptions,
        date!,
        time
      );

      return {
        isDispensed: cachedState.dispensed || medicationResult?.dispensed === 1,
        isWitnessed: cachedState.witnessed || medicationResult?.witnessed === 1,
        isException: cachedState.exception || exception?.status === 'active'
      };
    })();

  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl">
              {isGeneral ? 'General exception for ' : 'Exception for '}
              {resident?.first_name || data?.resident?.first_name}{' '}
              {resident?.last_name || data?.resident?.last_name}
            </DialogTitle>
          </DialogHeader>
          <ExceptionForm
            onSubmit={onSubmit}
            defaultValues={{
              date: date,
              time,
              reason_id: data?.emar_medication_exception?.reason_id || '',
              details: data?.emar_medication_exception?.details || '',
              discount_unit: data?.emar_medication_exception?.discount_unit || false
            }}
            disabled={medicationCardStatus === 'loading'}
            reasons={reasons}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

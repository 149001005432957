import { RootState } from '@/store/store';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';

export interface NoteCategoryI {
  id: string;
  category: string;
  type: string;
}

interface SelectNoteCategoryProps {
  handleSelectCategory: (category: NoteCategoryI | undefined) => void;
  decodeHTMLEntities: (text: string) => string;
  value?: string;
  target?: 'resident' | 'staff' | 'betweenstaff';
  isPharmacySelected: boolean;
}

export function SelectNoteCategory({
  handleSelectCategory,
  target = 'resident',
  value: propValue,
  decodeHTMLEntities,
  isPharmacySelected
}: SelectNoteCategoryProps) {
  const { t, i18n } = useTranslation();
  const { hasRole } = useRoleHelper();

  const categories = useSelector((state: RootState) =>
    state.notes.categories.filter((category) => category.type === target && (/de enfermer/i.test(category.category) ? i18n.language == 'es' : true))
  );

  const isPharmacyRole = hasRole('Pharmacy');

  const pharmacyCategory = useMemo(
    () => categories.find((category) => category?.category === 'Pharmacy'),
    [categories]
  );

  const defaultValue = useMemo(
    () => (isPharmacyRole && pharmacyCategory ? pharmacyCategory.id : undefined),
    [isPharmacyRole, pharmacyCategory]
  );

  const [value, setValue] = useState(propValue || defaultValue || '');

  // Effect to handle initial pharmacy selection
  useEffect(() => {
    if ((isPharmacyRole || isPharmacySelected) && pharmacyCategory && !propValue) {
      setValue(pharmacyCategory.id);
      handleSelectCategory(pharmacyCategory);
    }
  }, [isPharmacyRole, pharmacyCategory, propValue, handleSelectCategory, isPharmacySelected]);

  const handleChange = (value: string) => {
    setValue(value);
    const categoryFind = categories.find((category) => category.id === value);
    handleSelectCategory(categoryFind);
  };

  return (
    <Select value={value} onValueChange={handleChange} disabled={isPharmacyRole || isPharmacySelected}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder={t('notes.category')} />
      </SelectTrigger>
      <SelectContent>
        {categories.map((item: NoteCategoryI) => (
          <SelectItem key={item.id} value={item.id}>
            {decodeHTMLEntities(item.category)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

import { useMemo, useState } from "react";
import moment from "moment";
import { AlertI, AlertType, HiddenAlertsI, UserI } from "@/common/domain/Alerts";


export const useStaffAlerts = (
  licenseAlerts: { status: string; data: AlertI[] },
  staffCustomAlerts: { status: string; data: AlertI[] },
) => {
  const [hiddenAlerts, setHiddenAlerts] = useState<HiddenAlertsI>({});

  const alerts: AlertType[] = useMemo(() => {
    if (
      licenseAlerts.status === 'loading' ||
      staffCustomAlerts.status === 'loading'
    ) {
      return [];
    }

    const currentDate = moment().format('YYYY-MM-DD');

    const customAlertsStaff: AlertType[] = staffCustomAlerts?.data.map((alert) => ({
      ...alert,
      type: 'custom',
      date: currentDate,
      users: alert.users.filter((user) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));

    const customLicenseAlerts: AlertType[] = licenseAlerts?.data.map((alert) => ({
      ...alert,
      type: 'license',
      date: alert.start_date,
      users: alert.users.filter((user: UserI) => !hiddenAlerts[alert.id]?.includes(user.id))
    }));

    return [...customAlertsStaff, ...customLicenseAlerts].sort((a, b) =>
      moment(a.date).isBefore(b.date) ? -1 : 1
    );
  }, [staffCustomAlerts, licenseAlerts, hiddenAlerts]);

    const totalAlertsCount = useMemo(() => {
      return alerts.reduce((total, alert) => {
        if (alert.type === 'default') {
          return total + 1;
        } else {
          return total + (alert as AlertI).users.length;
        }
      }, 0);
    }, [alerts]);

    return { alerts, totalAlertsCount, hiddenAlerts, setHiddenAlerts };
}
import { Separator } from "@/common/presentation/components/ui/separator";
import TrackerVisitorsFormFrame from "./TrackerVisitorsFormFrame";
import { RadioGroup, RadioGroupItem } from "@/common/presentation/components/ui/radio-group";
import { Label } from "@/common/presentation/components/ui/label";
import { Input } from "@/common/presentation/components/ui/input";
import { RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { SetStateAction, useEffect, useState } from "react";
import { getPublicQuestions, getQuestions } from "@/modules/settings/presentation/components/menus/Questions/slices/QuestionsSlice";
import { t } from "i18next";
import SettingVisitorQuestion from "@/modules/tracker/infrastructure/interfaces/Visitors/SettingVisitorQuestion";
import TrackerVisitorsQuestion from "./TrackerVisitorsQuestion";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { useToast } from "@/common/presentation/components/ui/use-toast";

interface TrackerVisitorsQuestionsProps {
    progress: number;
    setProgress: (prev: SetStateAction<number>) => void;
    setMode: (newValue: string) => void;
    setTemperature: (newValue: SetStateAction<string>) => void;
    setApproved: (newValue: SetStateAction<boolean | null>) => void;
    totalPerQuestion: number;
};

const TrackerVisitorsQuestions = ({ progress, setProgress, setMode, setTemperature, setApproved, totalPerQuestion }: TrackerVisitorsQuestionsProps) => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const QuestionsStore = useSelector((state: RootState) => state.settings.questions);
    const [questions, setQuestions] = useState<SettingVisitorQuestion[]>([]);
    const [questionText, setQuestionText] = useState("");
    const [pendingQuestions, setPendingQuestions] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState<TrackerVisitorsQuestion[]>([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        if(QuestionsStore.questions && QuestionsStore.questions.length) {
            setQuestions(QuestionsStore.questions);
        }
    }, [QuestionsStore]);

    const nextQuestion = (question: SettingVisitorQuestion) => {    
        if(questionText) {
            const newQuestion = pendingQuestions + 1;
            let approved = false;

            if(question.answer_type === "yes_no") {
                if(question.desired_answer_yes_no) {
                    approved = (questionText == question.desired_answer_yes_no);
                }
            } else if(question.answer_type === "numeric") {
                if(question.desired_numeric_answer_type === "single") {
                    approved = (questionText == question.desired_numeric_answer_single);
                } else {
                    if(question.title === "defaultQuestions.temperature_today") {
                        setTemperature(questionText);
                    }
        
                    approved = (questionText >= question.desired_numeric_answer_min) && (questionText <= question.desired_numeric_answer_max);
                }
            }

            if(answeredQuestions.find((currentQuestion) => currentQuestion.id === question.id)) {
                const newAnsweredQuestions = answeredQuestions.map(
                    (currentQuestion) => {
                        const newCurrentQuestion = Object.assign({}, currentQuestion);

                        if(currentQuestion.id === question.id) newCurrentQuestion.approved = approved;

                        return newCurrentQuestion;
                    }
                );

                setAnsweredQuestions(newAnsweredQuestions);
            } else {
                setAnsweredQuestions(
                    (prev) => (
                        [
                            ...prev,
                            {
                                id: question.id,
                                approved
                            } 
                        ]
                    )
                );
            }

            if(newQuestion < questions.length) {
                setPendingQuestions(newQuestion);
                setProgress((prev: number) => prev + totalPerQuestion);
            } else {
                const answeredQuestionsQuantity = answeredQuestions.length;
                const answeredQuestionsApproved = answeredQuestions.filter(
                    (answeredQuestion) => answeredQuestion.approved
                ).length;

                if(answeredQuestionsQuantity != answeredQuestionsApproved) {
                    setApproved(false);
                } else {
                    setApproved(true);
                }

                setMode("result");
            }

            cleanInputs();
        } else {
            toast(
                {
                    description: "The answer is required"
                }
            );
        }
    }

    const previousQuestion = () => {
        const newQuestion = pendingQuestions - 1;

        if(newQuestion >= 0) {
            setPendingQuestions(newQuestion);
            setProgress((prev) => prev - totalPerQuestion);
        }
    }

    const fetchQuestions = () => {
        dispatch(
            getPublicQuestions(
                {
                    token: decodeURIComponent(params.token)
                }
            )
        );
    }

    const cleanInputs = () => {
        setQuestionText("");
    }

    return (
        questions && questions.length ? (
            <TrackerVisitorsFormFrame 
                next={() => nextQuestion(questions[pendingQuestions])} 
                back={() => previousQuestion()}
                percentage={progress}
            >
                <p style={{ fontSize: "14px" }}>
                    {
                        questions[pendingQuestions] && questions[pendingQuestions].title ? (
                            <div className="flex flex-col justify-center items-center">
                                <div>
                                    {
                                        questions[pendingQuestions].availability === "all_clients" ? (
                                            <span>{t("settings.visitor.questions." + questions[pendingQuestions].title)}</span>
                                        ) : (
                                            <span>{questions[pendingQuestions].title}</span>
                                        )
                                    }
                                </div>
                            </div>
                        ): null
                    }
                </p>

                <Separator className="bg-primary/50 mb-4 mt-4" />

                {
                    questions[pendingQuestions] && questions[pendingQuestions].answer_type === "yes_no" ? (
                        <RadioGroup defaultValue={questionText} value={questionText} onValueChange={(value) => setQuestionText(value)}>
                            <div className="flex items-center space-x-2 mt-1 mb-1">
                                <RadioGroupItem value="yes" id="r1" />
                                <Label htmlFor="r1">Yes</Label>
                            </div>

                            <div className="flex items-center space-x-2 mt-1 mb-1">
                                <RadioGroupItem value="no" id="r2" />
                                <Label htmlFor="r2">No</Label>
                            </div>
                        </RadioGroup>
                    ): null
                }

                {
                    questions[pendingQuestions] && (questions[pendingQuestions].answer_type === "text" || questions[pendingQuestions].answer_type === "numeric") ? (
                        <Input 
                            value={questionText} 
                            onChange={(event) => setQuestionText(event.target.value)}
                        ></Input>
                    ): null
                }
            </TrackerVisitorsFormFrame>
        ): null
    );
};

export default TrackerVisitorsQuestions;

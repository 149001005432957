type ColumnConfig = {
  key: string;
  labelTranslationKey: string;
  visible: boolean;
  sortable: boolean;
  editable: boolean;
  type: 'text' | 'number' | 'calendar' | 'checkbox' | 'expiration_date';
  numeric?: boolean;
};

const useColumnsCentralizedMedications = (): ColumnConfig[] => {
  const columnsCentralizedMedications: ColumnConfig[] = [
    {
      key: 'checkbox',
      labelTranslationKey: 'medications.columnsCentralizedMedications.copy',
      visible: true,
      sortable: false,
      editable: true,
      type: 'checkbox'
    },
    {
      key: 'medication_name',
      labelTranslationKey: 'medications.columnsCentralizedMedications.medications',
      visible: true,
      sortable: true,
      editable: false,
      type: 'text'
    },
    {
      key: 'type_medication_name',
      labelTranslationKey: 'medications.columnsCentralizedMedications.type',
      visible: true,
      sortable: true,
      editable: false,
      type: 'text'
    },
    {
      key: 'rx',
      labelTranslationKey: 'medications.columnsCentralizedMedications.rx',
      visible: true,
      sortable: true,
      editable: true,
      type: 'number',
      numeric: true
    },
    {
      key: 'filleDate',
      labelTranslationKey: 'medications.columnsCentralizedMedications.filleDate',
      visible: true,
      sortable: true,
      editable: true,
      type: 'calendar'
    },
    {
      key: 'qtyReceived',
      labelTranslationKey: 'medications.columnsCentralizedMedications.qtyReceived',
      visible: true,
      sortable: true,
      editable: true,
      type: 'number',
      numeric: true
    },
    {
      key: 'cycle_start_date',
      labelTranslationKey: 'medications.columnsCentralizedMedications.cycle_start_date',
      visible: true,
      sortable: true,
      editable: true,
      type: 'calendar'
    },
    {
      key: 'cycle_end_date',
      labelTranslationKey: 'medications.columnsCentralizedMedications.cycle_end_date',
      visible: true,
      sortable: true,
      editable: true,
      type: 'calendar'
    },
    {
      key: 'expiration_date',
      labelTranslationKey: 'medications.columnsCentralizedMedications.expiration_date',
      visible: true,
      sortable: true,
      editable: true,
      type: 'expiration_date'
    },
    {
      key: 'refills',
      labelTranslationKey: 'medications.columnsCentralizedMedications.refills',
      visible: true,
      sortable: true,
      editable: true,
      type: 'number',
      numeric: true
    }
  ];

  return columnsCentralizedMedications;
};

export default useColumnsCentralizedMedications;

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useForm } from 'react-hook-form';
import { Button } from '@/common/presentation/components/ui/button';
import { Card } from '@/common/presentation/components/ui/card';
import { Textarea } from "@/common/presentation/components/ui/textarea";
import { useEffect, useState } from 'react';
import { getLocationTypes, updateLocation } from '../../slices/locationsSlice';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { useNavigate } from 'react-router-dom';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

export const getFormSchema = () => {
    const { t } = useTranslation();
    return z.object({
        name: z
            .string({
                required_error: t('facilities.facilityNameRequired'),
            })
            .min(3, {
                message: t('facilities.facilityNameRequired'),
            }),
        admin: z.string().optional(),
        phone_lic: z
            .string({
                required_error: t('facilities.facilityPhoneNumberRequired'),
            })
            .min(10, {
                message: t('facilities.facilityPhoneNumberMin'),
            }),
        licensing: z.string().optional(),
        phone: z.string().optional(),
        admin_email: z.string().optional(),
        npi: z.string().nullable().optional(),
        max_beds: z
            .union([
                z.string().regex(/^\d+$/, {
                    message: t('facilities.bedsInvalid'),
                }),
                z.number(),
            ])
            .optional()
            .nullable()
            .or(z.literal("")),
        category: z.string().nullable().optional(),
        address: z
            .string({
                required_error: t('facilities.addressRequired'),
            })
            .min(5, {
                message: t('facilities.addressMin'),
            }),
        city: z
            .string({
                required_error: t('facilities.cityRequired'),
            })
            .min(3, {
                message: t('facilities.cityMin'),
            }),
        state: z
            .string({
                required_error: t('facilities.stateRequired'),
            })
            .min(2, {
                message: t('facilities.stateMin'),
            }),
        location_description: z.string().nullable().optional(),
    });
}

type FormValues = z.infer<ReturnType<typeof getFormSchema>>;

interface LocationType {
    id: string;
    type: string;
}

export const LocationForm = () => {
    const params = new URLSearchParams(location.search)
    const formSchema = getFormSchema();
    const locationId = params.get('id');
    const locations = useSelector((state: RootState) => state.locations.allLocations.locations);
    const selectedLocation = locations.find(location => location.id === locationId);
    const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: selectedLocation,
    });

    const dataLocationTypes = async () => {
        try {
            const response = await dispatch(getLocationTypes());
            if (response.payload) {
                setLocationTypes(response.payload);
            }
        } catch (error) {
            console.error('Error fetching location types:', error);
        }
    };

    useEffect(() => {
        if (!locationId) {
            navigate('/dashboard')
            return
        }
    }, [locationId]);

    useEffect(() => {
        dataLocationTypes();
    }, []);

    const onSubmit = async (data: FormValues) => {
        setIsSubmit(true);
        if (!locationId) {
            console.error("Location ID is null or undefined");
            return;
        }

        try {
            const response = await dispatch(updateLocation({
                locationId,
                data
            }))

            if (response.payload) {
                toast({
                    description: t('facilities.dataSaving'),
                    className: 'bg-green-500 text-white'
                });
            } else {
                toast({
                    description: t('facilities.errorSaving'),
                    variant: 'destructive'
                });
            }

        } catch (error) {
            console.log(error);
            toast({
                description: 'There was an error saving the data',
                variant: 'destructive'
            });
        }
        setIsSubmit(false);

    };

    return (
        <div className=" p-2 mt-4">
            <div className="flex flex-col gap-2 mt-4">
                <div className="font-semibold text-2xl text-primary">{t('facilities.updateLocations')}</div>
            </div>
            < Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-4">
                        {/* Facility Name */}
                        <div>
                            <Label htmlFor="facility_name">{t('facilities.facilityName')} (*)</Label>
                            <Input id="name" {...register('name')} />
                            {errors.name?.message && (
                                <span className="text-red-500">{errors.name.message}</span>
                            )}
                        </div>

                        {/* Facility Administrator */}
                        <div>
                            <Label htmlFor="facility_administrator">{t('facilities.facilityAdministrator')}</Label>
                            <Input id="admin" {...register('admin')} />
                            {errors.admin?.message && (
                                <span className="text-red-500">{errors.admin.message}</span>
                            )}
                        </div>

                        {/* Facility Phone */}
                        <div>
                            <Label htmlFor="facility_phone">{t('facilities.facilityPhoneNumber')} (*)</Label>
                            <Input id="phone" {...register('phone')} />
                            {errors.phone?.message && (
                                <span className="text-red-500">{errors.phone.message}</span>
                            )}
                        </div>

                        {/* Licensing Number */}
                        <div>
                            <Label htmlFor="licensing_number">{t('facilities.licensingNumber')}</Label>
                            <Input id="licensing" {...register('licensing')} />
                            {errors.licensing?.message && (
                                <span className="text-red-500">{errors.licensing.message}</span>
                            )}
                        </div>

                        {/* Administrator Phone */}
                        <div>
                            <Label htmlFor="admin_phone">{t('facilities.administratorPhoneNumber')}</Label>
                            <Input id="phone_lic" {...register('phone_lic')} />
                            {errors.phone_lic?.message && (
                                <span className="text-red-500">{errors.phone_lic.message}</span>
                            )}
                        </div>

                        {/* Administrator Email */}
                        <div>
                            <Label htmlFor="admin_email">{t('facilities.administratorEmail')}</Label>
                            <Input id="admin_email" {...register('admin_email')} />
                            {errors.admin_email?.message && (
                                <span className="text-red-500">{errors.admin_email.message}</span>
                            )}
                        </div>

                        {/* Facility NPI */}
                        <div>
                            <Label htmlFor="facility_npi">{t('facilities.facilityNPI')}</Label>
                            <Input id="npi" {...register('npi')} />
                            {errors.npi?.message && (
                                <span className="text-red-500">{errors.npi.message}</span>
                            )}
                        </div>

                        {/* Max Occupancy */}
                        <div>
                            <Label htmlFor="max_occupancy">{t('facilities.maxOccupancyBeds')}</Label>
                            <Input id="max_beds" {...register('max_beds')} disabled/>
                            {errors.max_beds?.message && (
                                <span className="text-red-500">{errors.max_beds.message}</span>
                            )}
                        </div>

                        {/* Facility Type */}
                        <div>
                            <Label htmlFor="facility_type">{t('facilities.facilityType')}</Label>
                            <Select
                                value={watch('category')} // Usa `watch` para obtener el valor del formulario
                                onValueChange={(value) => setValue('category', value)} // Actualiza el valor en el formulario
                            >
                                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                    <SelectValue>
                                        {watch('category')
                                            ? locationTypes.find((type) => type.id === watch('category'))?.type
                                            : 'Select a facility type'}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {locationTypes.map((type) => (
                                        <SelectItem key={type.id} value={type.id}>
                                            {type.type}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            {errors.category?.message && (
                                <span className="text-red-500">{errors.category.message}</span>
                            )}
                        </div>

                        {/* Address */}
                        <div>
                            <Label htmlFor="address">{t('facilities.address')} (*)</Label>
                            <Input id="address" {...register('address')} />
                            {errors.address?.message && (
                                <span className="text-red-500">{errors.address.message}</span>
                            )}
                        </div>

                        {/* City */}
                        <div>
                            <Label htmlFor="city">{t('facilities.city')} (*)</Label>
                            <Input id="city" {...register('city')} />
                            {errors.city?.message && (
                                <span className="text-red-500">{errors.city.message}</span>
                            )}
                        </div>

                        {/* State */}
                        <div>
                            <Label htmlFor="state">{t('facilities.state')} (*)</Label>
                            <Input id="state" {...register('state')} />
                            {errors.state?.message && (
                                <span className="text-red-500">{errors.state.message}</span>
                            )}
                        </div>

                        {/* Description */}
                        <div className="col-span-2">
                            <Label htmlFor="description">{t('residents.medicalInformation.diagnosis.description')}</Label>
                            <Textarea
                                id="description"
                                className="form-textarea w-full bg-gray-100"
                                {...register('location_description')}
                            />
                            {errors.location_description?.message && (
                                <span className="text-red-500">{errors.location_description.message}</span>
                            )}
                        </div>
                    </div>

                    <div className='flex flex-items justify-end'>
                        <Button type="submit" className="mt-5" disabled={isSubmit}>
                            {t('medications.update')}
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
}
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { CardContent} from '@/components/ui/card';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import { CustomCard } from '../shared/CustomCard';
import FormHeader from '../shared/FormHeader';
import FormActionButtons from '../shared/FormActionButtons';
import { releaseMedicalInfoDefaults } from '../formDefaults/releaseMedicalInfoDefaults';
import { AuthorizationHeaderForm } from './components/AuthorizationHeaderForm';
import { AuthorizationDetailsForm } from './components/AuthorizationDetailsForm';
import { NoteSection } from './components/NoteSection';

export const ReleaseMedicalInfoForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const methods = useForm({
    defaultValues: releaseMedicalInfoDefaults(resident),
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(releaseMedicalInfoDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic605a_release_of_client_medical_info',
  });

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedData = {
      ...rawData,
      to_date: rawData.to_date ? format(parseISO(rawData.to_date), 'MM/dd/yyyy') : '',
      expiration_date: rawData.expiration_date ? format(parseISO(rawData.expiration_date), 'MM/dd/yyyy') : '',
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
          <BackNavigationBar
            title={title}
            path={`/residents/forms?r=${params.r}`}
          />
          <FormProvider {...methods}>
            <form>
              <CustomCard>
                <FormHeader
                  leftContent={
                    <>
                     <p className='uppercase'>
                        {t("residents.forms.formHeader.stateOfCalifornia")}
                      </p>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
                      </p>
                    </>
                  }
                  rightContent={
                    <>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.californiaDeptSocialServices")}
                      </p>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.communityCareLicensingDivision")}
                      </p>
                    </>
                  }
                />
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <h2 className="text-xl font-bold text-center mb-4 uppercase">
                      {t("residents.forms.releaseMedicalInfo.medicalInformation")}
                    </h2>
                    <AuthorizationHeaderForm />
                  </div>
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <AuthorizationDetailsForm />
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <NoteSection/>
                  <FormActionButtons
                    handleFormSubmit={handleFormSubmit}
                    formsStatus={formsStatus}
                  />
                  <p className="bottom-2 mt-2 left-2 text-xs text-gray-600 dark:text-gray-400">
                    LIC 605 A (5/00)
                  </p>
                </CardContent>
              </CustomCard>
            </form>
          </FormProvider>
      </div>
    </div>
  );
};

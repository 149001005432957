export const incidentTypes = [
  { name: 'unauthorized_absence'},
  { name: 'alleged_client_abuse'},
  { name: 'rape'},
  { name: 'injury_accident'},
  { name: 'medical_emergency'},
  { name: 'aggressive_act_self'},
  { name: 'sexual'},
  { name: 'pregnancy'},
  { name: 'injury_unknown_origin'},
  { name: 'other_sexual_incident'},
  { name: 'aggressive_act_another_client'},
  { name: 'physical'},
  { name: 'suicide_attempt'},
  { name: 'injury_from_another_client'},
  { name: 'theft'},
  { name: 'aggressive_act_staff'},
  { name: 'psychological'},
  { name: 'injury_from_behavior_episode'},
  { name: 'fire'},
  { name: 'aggressive_act_family_visitors'},
  { name: 'financial'},
  { name: 'epidemic_outbreak'},
  { name: 'property_damage'},
  { name: 'other_explain'},
  { name: 'alleged_violation_of_rights'},
  { name: 'neglect'},
  { name: 'hospitalization'},
  { name: 'other'},
];

export const agenciesNotified = [
  { name: "licensing" },
  { name: "adult_child_protective" },
  { name: "long_term_care" },
  { name: "parent_guardian" },
  { name: "law_enforcement" },
  { name: "placement_agency" },
];
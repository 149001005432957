import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ResidentService from '../../infrastructure/services/ResidentsService';

interface ResidentsFormState {
  status: 'idle' | 'loading' | 'failed';
  isValid: boolean;
  error: string | null;
}

const initialState: ResidentsFormState = {
  status: 'idle',
  isValid: false,
  error: null
};

export const createResidentForm = createAsyncThunk('residentsForm/createUserForm', async (data: any) => {
  try {
    const response = await ResidentService.createResident(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error creating user: ' + error);
  }
});

export const updatePersonalInformationForm = createAsyncThunk(
  'residentsForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await ResidentService.updatePersonalInformation(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const updateReligiousPreferencesForm = createAsyncThunk(
  'residentsForm/updateReligiousPreferencesForm',
  async (data: any) => {
    try {
      const response = await ResidentService.updateReligiousPreferences(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const updateCustomFieldsForm = createAsyncThunk('residentsForm/updateCustomFieldsForm', async (data: any) => {
  try {
    const response = await ResidentService.updateCustomFieldsForm(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

export const updateResidenceLevelOfCare = createAsyncThunk('residentsForm/updateResidenceLevelOfCareForm', async (data: any) => {
  try {
    const response = await ResidentService.updateResidenceLevelOfCare(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating data: ' + error);
  }
});

export const updateNotesForm = createAsyncThunk('residentsForm/updateResidentNotesForm', async (data: any) => {
  try {
    const response = await ResidentService.updateResidentNotes(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

export const updateDischargeForm = createAsyncThunk('residentsForm/updateResidentNotesForm', async (data: any) => {
  try {
    const response = await ResidentService.updateDischargeResident(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

export const updateRiskAssessment = createAsyncThunk('residentsForm/risk-assessment', async (data: {
  resident_id: string, elopement_risk: string,
  fall_risk: string, pressure_sore_risk: string
}) => {
  try {
    const response = await ResidentService.updateRiskAssessment(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

export const updateSleepingHours = createAsyncThunk('residentsForm/sleeping-hours', async (data: {
  resident_id: string, asleep: Date, awake: Date
}) => {
  try {
    const response = await ResidentService.updateSleepingHours(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating sleeping hours: ' + error);
  }
});

export const updateAdministrativeInformationForm = createAsyncThunk(
  'residentsForm/updateAdministrativeInformationForm',
  async (data: any) => {
    try {
      const response = await ResidentService.updateAdministrativeInformation(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const uploadProfileImage = createAsyncThunk('residentsForm/uploadProfileImage', async (data: any) => {
  try {
    const response = await ResidentService.uploadProfileImage(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error uploading profile Image: ' + error);
  }
});

export const deleteProfileImage = createAsyncThunk('residentsForm/deleteProfileImage', async (residentId: string) => {
  try {
    const response = await ResidentService.deleteProfileImage(residentId);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error deleting profile Image: ' + error);
  }
});

const residentsFormSlice = createSlice({
  name: 'residentsForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createResidentForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createResidentForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createResidentForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(uploadProfileImage.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadProfileImage.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(deleteProfileImage.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteProfileImage.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteProfileImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updatePersonalInformationForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updatePersonalInformationForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updatePersonalInformationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateRiskAssessment.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateRiskAssessment.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateRiskAssessment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateSleepingHours.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateSleepingHours.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateSleepingHours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateResidenceLevelOfCare.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateResidenceLevelOfCare.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateResidenceLevelOfCare.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateReligiousPreferencesForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateReligiousPreferencesForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateReligiousPreferencesForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateAdministrativeInformationForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateAdministrativeInformationForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateAdministrativeInformationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateNotesForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateNotesForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateNotesForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });

    builder
      .addCase(updateCustomFieldsForm.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateCustomFieldsForm.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateCustomFieldsForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export default residentsFormSlice.reducer;

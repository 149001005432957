import { FileText } from 'lucide-react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/common/presentation/components/ui/accordion';

const accordionSharedClasses = `w-full mb-4 px-6 py-4 border border-gray-300transition-all
  bg-white rounded-lg dark:bg-zinc-900 dark:border-zinc-600`;

export const StyledAccordionItem = ({ title, children, value }: { title: string; children: React.ReactNode; value: string }) => (
  <AccordionItem key={value} value={value} className={accordionSharedClasses}>
    <AccordionTrigger className="p-0 text-primary no-underline focus:outline-none focus:ring-0">
      <div className="flex items-center">
        <FileText className='h-6 w-6'/>
        <span className="text-base font-semibold leading-6 ml-2">{title}</span>
      </div>
    </AccordionTrigger>
    <AccordionContent className="flex flex-col items-start">
      {children}
    </AccordionContent>
  </AccordionItem>
);
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from "@/store/store";
import { CircleFadingPlus } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { GeneralOutingsTable } from "../components/GeneralOutingsTable";
import { GeneralOutingsCreateForm } from "../components/GeneralOutingsCreateForm";
import { HospitalizationOutingsCreateForm } from "../components/HospitalizationOutingsCreateForm";
import { MedicalOutingsTable } from "../components/MedicalOutingsTable";
import { HospitalizationOutingsTable } from "../components/HospitalizationOutingsTable";
import { MedicalOutingsCreateForm } from "../components/MedicalOutingsCreateForm";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { t } from "i18next";

function TrackerOutingsPage() {
    const { params, setParam } = useRouteParams();
    const [selectedDirect, setSelectedDirect] = useState<string>(params.e || 'form');
    const [selectedReport, setSelectedReport] = useState<string>(params.e || 'form');
    const [selectedCloud, setSelectedCloud] = useState<string>(params.e || 'form');
    const { locationSelectedId } = useLocation();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectReport = (e: string) => {
        setSelectedReport(e);
    };

    const onSelectCloud = (e: string) => {
        setSelectedCloud(e);
    };

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedDirect(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    return (
        <div>
            <LocationSelector quickView={true} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl">{t("outings.title")}</div>
                            <div className="font-semibold text-1xl">{t("outings.description")}</div>
                        </div>

                        <Tabs defaultValue={selectedDirect} onValueChange={(e) => { onSelectVitals(e); }} className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t("common.new")}
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t("common.report")}
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>

                )
            }

            {
                selectedDirect === 'form' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div className="flex flex-col gap-4">
                                    <ResidentSelector
                                        onSelect={onResidentSelect}
                                        residentId={params.r}
                                        showAllResidents={false}
                                    />
                                </div>
                            )
                        }

                        {
                            locationSelectedId && (
                                <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                                    <CardHeader>
                                        <div className="flex justify-between">
                                            {
                                                selectedCloud === "general" &&
                                                <div>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                                        {t("outings.general")}
                                                    </CardTitle>
                                                </div>
                                            }

                                            {
                                                selectedCloud === "medical" &&
                                                <div>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                                        {t("outings.medical")}
                                                    </CardTitle>
                                                </div>
                                            }

                                            {
                                                selectedCloud === "hospitalizations" &&
                                                <div>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                                        {t("outings.hospitalization")}
                                                    </CardTitle>
                                                </div>
                                            }

                                            <Tabs defaultValue={selectedCloud} onValueChange={(e) => { onSelectCloud(e); }} className="w-auto mb-2">
                                                <TabsList>
                                                    <TabsTrigger value="general" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white" autoFocus>
                                                        {t("outings.general")}
                                                    </TabsTrigger>

                                                    <TabsTrigger value="medical" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                        {t("outings.medical")}
                                                    </TabsTrigger>

                                                    <TabsTrigger value="hospitalizations" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                        {t("outings.hospitalization")}
                                                    </TabsTrigger>
                                                </TabsList>
                                            </Tabs>
                                        </div>
                                    </CardHeader>
        
                                    <CardContent>
                                        {
                                            selectedCloud === "general" && <GeneralOutingsCreateForm />
                                        }

                                        {
                                            selectedCloud === "medical" && <MedicalOutingsCreateForm />
                                        }

                                        {
                                            selectedCloud === "hospitalizations" && <HospitalizationOutingsCreateForm />
                                        }
                                    </CardContent>
                                </Card>
                            )
                        }
                    </div>
                )
            }

            {
                selectedDirect === 'report' && (
                    <Card className="border-t-4 border-t-primary/80">
                        <CardHeader>
                            <div className="flex justify-between">
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                    { 
                                        selectedReport === "general" && <span>{t("outings.general")}</span>
                                    }

                                    { 
                                        selectedReport === "medical" && <span>{t("outings.medical")}</span>
                                    }

                                    { 
                                        selectedReport === "hospitalizations" && <span>{t("outings.hospitalization")}</span>
                                    }
                                </CardTitle>

                                <Tabs defaultValue={selectedReport} onValueChange={(e) => { onSelectReport(e); }} className="w-auto">
                                    <TabsList>
                                        <TabsTrigger value="general" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white" autoFocus>
                                            {t("outings.general")}
                                        </TabsTrigger>

                                        <TabsTrigger value="medical" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t("outings.medical")}
                                        </TabsTrigger>

                                        <TabsTrigger value="hospitalizations" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t("outings.hospitalization")}
                                        </TabsTrigger>
                                    </TabsList>
                                </Tabs>
                            </div>
                        </CardHeader>

                        <CardContent>
                            { 
                                selectedReport === "general" && <GeneralOutingsTable />
                            }

                            { 
                                selectedReport === "medical" && <MedicalOutingsTable />
                            }

                            {
                                selectedReport === "hospitalizations" && <HospitalizationOutingsTable />
                            }
                        </CardContent>
                    </Card>
                )
            }
        </div>
    );
}

export default TrackerOutingsPage;

import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { saveRoom, updateRoom, deleteRoom } from '../../slices/locationsSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { AppDispatch } from '@/store/store';
import { useDispatch } from 'react-redux';

interface RoomsI {
    id: string | undefined;
    name: string;
    floor: number;
}

interface LocationRoomsProps {

    openModal: boolean;
    closeModal: () => void;
    dataRoomsLocation: (page: number, rowsPerPage: number, resetData: boolean) => void;
    locationId: string;
    action: 'edit' | 'delete' | '';
    selectedRow: RoomsI | null;
    rowsPerPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}


export function DialogCreateOrUpdateRoom({ openModal, closeModal, locationId, action, selectedRow, dataRoomsLocation,
    rowsPerPage, setCurrentPage }: LocationRoomsProps) {

    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formData, setFormData] = useState<RoomsI>({
        id: undefined,
        name: '',
        floor: 1,
    });
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (action === 'edit' && selectedRow) {
            setFormData({
                id: selectedRow.id,
                name: selectedRow.name || '',
                floor: selectedRow.floor || 1
            });
        } else {
            setFormData({
                id: undefined,
                name: '',
                floor: 1,
            });
        }
    }, [selectedRow, action]);

    useEffect(() => {
        if (error) {
            setError('');
        }
    }, [closeModal]);



    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (!locationId) {
            return
        }

        const payload = {
            id: action ? formData.id : undefined,
            location_id: locationId,
            name: formData.name,
            floor: formData.floor,
        };

        try {

            if (!payload.name) {
                setError("requiredName");
                setIsSubmitting(false);
                return
            }

            if (!payload.floor) {
                setError("requiredUnit");
                setIsSubmitting(false);
                return
            }

            if (action === 'edit') {
                await dispatch(updateRoom(payload))
                toast({
                    description: t('facilities.updatedRoom'),
                    className: 'bg-green-500 text-white'
                });
            } else {
                await dispatch(saveRoom(payload))
                toast({
                    description: t('facilities.savedRoom'),
                    className: 'bg-green-500 text-white'
                });

            }

            setCurrentPage(1);
            dataRoomsLocation(1, rowsPerPage, true);
            closeModal();
            setFormData({
                id: undefined,
                name: '',
                floor: 1,
            });


        } catch (error) {
            console.log(error);
        }

        setIsSubmitting(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDelete = async () => {

        if (!selectedRow || !selectedRow.id) {
            console.error('Selected row or its ID is undefined');
            return;
        }
        try {
            setIsSubmitting(true);
            await dispatch(deleteRoom(selectedRow!.id))
            setCurrentPage(1)
            dataRoomsLocation(1, rowsPerPage, true);
            closeModal();
            setIsSubmitting(false);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <CustomDialog
            newDialog={true}
            isOpen={openModal}
            title={
                action === 'edit'
                    ? t('notes.edit')
                    : action === 'delete'
                        ? t('trackers.Delete')
                        : t('medications.customCombobox.create')
            }
            onClose={closeModal}
        >
            <div>
                {action === 'delete' ? (
                    <div>
                        <div className='flex justify-center'>
                            <h2>{t('trackers.sureDelete')}</h2>
                        </div>

                        <div className="w-full flex justify-end mt-5">
                            <Button onClick={handleDelete} disabled={isSubmitting} className="bg-red-600 text-white px-4 py-2 rounded">
                                {t('trackers.Delete')}
                            </Button>
                            <Button onClick={closeModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                                {t('calendar.cancel')}
                            </Button>
                        </div>
                    </div>
                ) :
                    <form onSubmit={handleSubmit}>

                        <div className="grid gap-4">
                            <Label htmlFor="label" className="control-label mb-3 mt-4">
                                {t('trackers.customtype.Name')}
                            </Label>
                            <div className="input-group">
                                <Input
                                    className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}

                                />
                            </div>
                            {error === 'requiredName' && <p style={{ color: 'red' }}>{t('facilities.requiredName')}</p>}
                        </div>

                        <div className="grid gap-4">
                            <Label htmlFor="label" className="control-label mb-3 mt-4">
                                {t('facilities.unit')}
                            </Label>
                            <div className="input-group">
                                <Input
                                    className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                    id="floor"
                                    name="floor"
                                    type="text"
                                    value={formData.floor}
                                    onChange={handleChange}

                                />
                            </div>
                            {error === 'requiredUnit' && <p style={{ color: 'red' }}>{t('facilities.requiredUnit')}</p>}
                        </div>

                        <div className="w-full flex justify-end mt-4">
                            <Button
                                type="submit"
                                className="bg-primary text-white px-4 py-2 rounded"
                                disabled={isSubmitting}
                            >
                                {t('trackers.Save')}
                            </Button>
                        </div>
                    </form>}
            </div>
        </CustomDialog>
    )

}
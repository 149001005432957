import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export const FacilityInfo = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <FormField
          control={control}
          name="facility_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.incidentReport.nameOfFacility')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="facility_file_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.facilityFileNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="facility_phone_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.facilityPhoneNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
        <FormField
          control={control}
          name="facility_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('contacts.address')}
              </FormLabel>
              <FormControl>
                <Input
                {...field}
                className="w-full dark:bg-zinc-800"
                readOnly
              />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="facility_address_details"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.facilityAddressDetails')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
  </div>
  );
};

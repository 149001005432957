import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ClientService = {

  getClients: async () => {
    try {
      const response = await axios.get(`clients/listclients`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error list clients: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching clients: ' + error);
    }
  },

  getPaginateClients: async (search: string, per_page: number, page: number) => {
    try {
      const response = await axios.get('clients/list-paginate-clients', {
        params: { search, per_page, page },
      });
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error list clients: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching clients: ' + error);
    }
  },

  getClient: async () => {
    try {
      const response = await axios.get(`clients/get-client`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error get client: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching client: ' + error);
    }
  },

  getClientByGivenId: async (id: string) => {
    try {
      const response = await axios.get(`clients/get-client-id/${id}`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error get client: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching client: ' + error);
    }
  },

  updateClient: async (data: any) => {
    try {
      const formData = new FormData();

      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((item: any, index: number) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post('clients/update-client', formData, config);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update client: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error updating client: ' + error);
    }
  }

}

export default ClientService;
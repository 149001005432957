import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { CustomCard } from "../../shared/CustomCard";
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from '@/common/presentation/components/ui/input';

const EmergencyContactsForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const contacts = [
    "physician" ,
    "mental_health_provider",
    "dentist" ,
    "relatives" ,
    "friends" ,
  ];

  return (
    <CustomCard>
      <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-10 mb-4">
        <h4 className="font-semibold text-base">
          8. {t('residents.forms.lic601EmergencyInfo.emergencyContacts')}
        </h4>
      </div>

      <div className="space-y-8">
        {contacts.map(( key ) => (
          <div key={key} className="space-y-4">
            <Separator className='my-4 bg-primary'/>
            <h5 className="text-base text-center font-semibold text-gray-800">
              {t(`residents.forms.lic601EmergencyInfo.${key}`)}
            </h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.name`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal"> {t('common.name')} </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={50}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.email`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.email')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={50}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.telephone`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.telephone')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={20}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.fax`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.fax')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={20}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.address`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.address')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={100}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.city`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.city')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={30}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.state`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.state')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={20}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other_emergency_contacts.${key}.zip_code`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">{t('common.zipCode')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800"
                        maxLength={10}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
        ))}
      </div>
    </CustomCard>
  );
};

export default EmergencyContactsForm;

import { ResidentI } from "@/common/domain/Residents";
import { toast } from "@/components/ui/use-toast";
import { GetFormHistoryFn } from "@/modules/residents/domain/models/interfaces/Forms/Licensing.domain";
import { AllContactsI } from "../../models/Contacts";
import { getDefaultValues } from "./getDefaultValues";

const getFormData = async (
  resident: ResidentI,
  residentId: string,
  documentKey: string,
  getFormHistory: GetFormHistoryFn,
  contacts: AllContactsI[]
) => {
  const defaultData = {
    facility_name: resident.locations[0]?.admin || 'N/A',
    resident_name: resident.first_name + ' ' + resident.last_name,
  };

  try {
    const history =  await getFormHistory(residentId, documentKey);

    if (!history.data || history.data.length === 0) {
      return getDefaultValues(documentKey, resident, contacts);
    }

    const latestForm = history.data
    .filter((form) => form.data && Object.keys(form.data).length > 0)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

    return latestForm?.data || defaultData;
  } catch (error) {
    const errorMessage = (error as Error).message || 'An unknown error occurred';
    toast({
      description: `Error retrieving form data: ${errorMessage}`,
      variant: 'destructive',
    });
    console.error('Error in getFormData:', error);
    throw error;
  }
};

export default getFormData;

import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/ui/input';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch } from 'react-redux';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { t } from 'i18next';

export function GeneralOutingsCreateForm() {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { params } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const { formatDate } = useDateHelpers();
    
    const [values, setValues] = useState({
        date: new Date(),
        activity: "",
        duration: "",
        staff: "",
        notes: ""
    });

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    }

    const cleanInputs = () => {
        setValues({
            date: new Date(),
            activity: "",
            duration: "",
            staff: "",
            notes: ""
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if(params.r) {
            if(values.date && values.activity && values.duration && values.staff && values.notes) {
                const response = await dispatch(trackerActions.saveTrackerOutingsGeneral([
                        {
                            date: values.date.toISOString(),
                            outingActivity: values.activity,
                            duration: values.duration,
                            staff: values.staff,
                            notes: values.notes,
                            resident_id: params.r,
                            location_id: locationSelectedId
                        }
                    ]
                ))

                if(response.payload) {
                    toast(
                        {
                            description: t("outings.alert.generalOutingsSuccess")
                        }
                    );

                    cleanInputs();
                }
            } else {
                toast(
                    {
                        description: t("common.fieldsRequired")
                    }
                )
            }
        } else {
            toast(
                {
                    description: t("common.residentRequired")
                }
            );
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="general-outings-form grid grid-cols-2 gap-4">
                <div className="grid grid-cols-1 gap-1">
                    <Label htmlFor={"date"} className="text-right justify-self-start">{t("common.dateTime")}</Label>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                "w-[280px] justify-start text-left font-normal w-100",
                                !values.date && "text-muted-foreground"
                            )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? formatDate(values.date) : <span>mm/dd/yyyy - hh:mm aa</span>}
                            </Button>
                        </PopoverTrigger>

                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar timePicker={true} onChange={handleDateChange}/>
                        </PopoverContent>
                    </Popover>
                </div>
                
                <div className="grid grid-cols-1 self-start gap-1">
                    <Label htmlFor={"activity"} className="text-right justify-self-start">{t("outings.fields.outingActivity")}</Label>

                    <Input
                        id={"activity"}
                        name={"activity"}
                        type="text"
                        value={values.activity}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
                
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"duration"} className="text-right justify-self-start">{t("outings.fields.duration")}</Label>

                    <Input
                        id={"duration"}
                        name={"duration"}
                        type="text"
                        value={values.duration}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
                
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"staff"} className="text-right justify-self-start">{t("outings.fields.staffAccompanying")}</Label>

                    <Input
                        id={"staff"}
                        name={"staff"}
                        type="text"
                        value={values.staff}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1" style={{ gridColumn: "1 / span 2" }}>
                    <Label htmlFor={"notes"} className="text-right justify-self-start">{t("outings.fields.details")}</Label>

                    <Textarea
                        id={"notes"}
                        name={"notes"}
                        type="text"
                        value={values.notes}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="w-100 mt-3 flex flex-row justify-end">
                <Button type="submit" className="mr-2">{t("common.save")}</Button>
            </div>
        </form>
    );
} 

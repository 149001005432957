import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerService = {
  getCustomizations: async () => {
    try {
      return await axios.get(`customizationTable`);
    } catch (error) {
      throw new Error('Error fetching customizationTable: ' + error);
    }
  },
  getTemperatureType: async () => {
    try {
      return await axios.get(`temperature/getTemperatureType`);
    } catch (error) {
      throw new Error('Error fetching temperature type: ' + error);
    }
  },

  getTemperature: async () => {
    try {
      return await axios.get(`temperature/getTemperature`);
    } catch (error) {
      throw new Error('Error fetching Temperature: ' + error);
    }
  },

  saveTemperature: async (formData: any, locationId?: string) => {
    try {
      const url = `temperature/saveTemperature${locationId ? `?location_id=${locationId}` : ''}`;
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error) {
      throw new Error('Error creating temperature: ' + error.message);
    }
  },

  getTrackerHygieneType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/getTrackerHygieneType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Hygiene Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Hygiene Type: ' + error.message);
    }
  },
  getTrackerHygiene: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/getTrackerHygiene`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Hygiene: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Hygiene: ' + error.message);
    }
  },
  updateTrackerHygiene: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/updateTrackerHygiene`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker hygiene: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker hygiene: ' + error.message);
    }
  },
  destroyTrackerHygiene: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/destroyTrackerHygiene`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker hygiene: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker hygiene: ' + error.message);
    }
  },
  updateTrackerHygieneType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/updateTrackerHygieneType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker hygiene type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker hygiene type: ' + error.message);
    }
  },
  saveTrackerHygieneType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/saveTrackerHygieneType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker hygiene type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker hygiene type: ' + error.message);
    }
  },
  saveTrackerHygiene: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/saveTrackerHygiene`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker hygiene: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker hygiene: ' + error.message);
    }
  },
  ///TRACKER VITALS
  getTrackerVitalsType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/getTrackerVitalsType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Vitals Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Vitals Type: ' + error.message);
    }
  },
  updateTrackerVitalsType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/updateTrackerVitalsType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker vitals type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker vitals type: ' + error.message);
    }
  },
  saveTrackerVitalsType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/saveTrackerVitalsType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker vitals type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker vitals type: ' + error.message);
    }
  },//TRACKER VITALS
  getTrackerVitals: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/getTrackerVitals`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Vitals: ' + error.message);
    }
  },
  getDayTrackerVitals: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/getDayTrackerVitals`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Vitals: ' + error.message);
    }
  },
  saveTrackerVitals: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/saveTrackerVitals`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker vitals: ' + error.message);
    }
  },
  updateTrackerVitals: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/updateTrackerVitals`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker vitals: ' + error.message);
    }
  },
  destroyTrackerVitals: async (formData: any) => {
    try {
      const response = await axios.post(`trackerVitals/destroyTrackerVitals`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker vitals: ' + error.message);
    }
  },
  // TRACKER 24/7
  getTrackerHygieneNight: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/getTrackerHygieneNight`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker 24/7: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker 24/7: ' + error.message);
    }
  },
  saveTrackerHygieneNight: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/saveTrackerHygieneNight`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker 24/7: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker 24/7: ' + error.message);
    }
  },
  updateTrackerHygieneNight: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/updateTrackerHygieneNight`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker 24/7: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker 24/7: ' + error.message);
    }
  },
  destroyTrackerHygieneNight: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/destroyTrackerHygieneNight`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker 24/7: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker 24/7: ' + error.message);
    }
  },
  //TRACKER 24/7 TYPE
  getTrackerHygieneNightType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/getTrackerHygieneNightType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker 24/7 type: ' + error.message);
    }
  },
  saveTrackerHygieneNightType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/saveTrackerHygieneNightType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker 24/7 type: ' + error.message);
    }
  },
  updateTrackerHygieneNightType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/updateTrackerHygieneNightType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker 24/7 type: ' + error.message);
    }
  }, saveTrackerHygieneNightTypeSelect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/saveTrackerHygieneNightTypeSelect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker 24/7 type: ' + error.message);
    }
  },
  updateTrackerHygieneNightTypeSelect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygieneNigth/updateTrackerHygieneNightTypeSelect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker 24/7 type: ' + error.message);
    }
  },
  saveTrackerHygieneTypeSelect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/saveTrackerHygieneTypeSelect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker 24/7 type: ' + error.message);
    }
  },
  updateTrackerHygieneTypeSelect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerHygiene/updateTrackerHygieneTypeSelect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker 24/7 type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker 24/7 type: ' + error.message);
    }
  },
  ///TRACKER VITALS
  getTrackerDirectType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/getTrackerDirectType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Direct Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Direct Type: ' + error.message);
    }
  },
  updateTrackerDirectType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/updateTrackerDirectType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker direct type: ' + error.message);
    }
  },
  saveTrackerDirectType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/saveTrackerDirectType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker direct type: ' + error.message);
    }
  },
  // Tracker Outings General
  getTrackerOutingsGeneral: async (body: any) => {
    try {
      const response = await axios.post(`trackerOutings/getTrackerOutingsGeneralReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing General:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing General: ' + error.message);
    }
  },
  saveTrackerOutingsGeneral: async (body: any) => {
    try {
      const response = await axios.post(`trackerOutings/saveTrackerOutingsGeneralReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing General:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing General: ' + error.message);
    }
  },
  updateTrackerOutingsGeneral: async (body: any) => {
    try {
      const response = await axios.put(`trackerOutings/updateTrackerOutingsGeneralReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing General:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing General: ' + error.message);
    }
  },
  destroyTrackerOutingsGeneral: async (body: any) => {
    try {
      const response = await axios.delete(`trackerOutings/destroyTrackerOutingsGeneralReport`, {
        data: {
          ...body
        }
      });

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing General:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing General: ' + error.message);
    }
  },
  // Tracker Outings Medical
  getTrackerOutingsMedical: async () => {
    try {
      const response = await axios.get(`trackerOutings/listTrackerOutingsMedicalReport`);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Medical:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Medical: ' + error.message);
    }
  },
  saveTrackerOutingsMedical: async (formData: any) => {
    try {
      const response = await axios({
        method: "post",
        url: `trackerOutings/SaveTrackerOutingsMedicalReport`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Medical:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Medical: ' + error.message);
    }
  },
  updateTrackerOutingsMedical: async (body: any) => {
    try {
      const response = await axios.put(`trackerOutings/updateTrackerOutingsMedicalReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Medical:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Medical: ' + error.message);
    }
  },
  destroyTrackerOutingsMedical: async (body: any) => {
    try {
      const response = await axios.delete(`trackerOutings/destroyTrackerOutingsMedicalReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Medical:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Medical: ' + error.message);
    }
  },
  // Tracker Outings Hospitalization
  getTrackerOutingsHospitalization: async (body) => {
    try {
      const response = await axios.post(`trackerOutings/listTrackerOutingsHospitalizationReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Hospitalization:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Hospitalization: ' + error.message);
    }
  },
  saveTrackerOutingsHospitalization: async (body: any) => {
    try {
      const response = await axios.post(`trackerOutings/saveTrackerOutingsHospitalizationReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Hospitalization:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Hospitalization: ' + error.message);
    }
  },
  updateTrackerOutingsHospitalization: async (body: any) => {
    try {
      const response = await axios.put(`trackerOutings/updateTrackerOutingsHospitalizationReport`, body);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Hospitalization:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Hospitalization: ' + error.message);
    }
  },
  destroyTrackerOutingsHospitalization: async (body: any) => {
    try {
      const response = await axios.delete(`trackerOutings/destroyTrackerOutingsHospitalizationReport`, {
        data: {
          ...body
        }
      });

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Outing Hospitalization:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Outing Hospitalization: ' + error.message);
    }
  },
  //TRACKER VITALS
  getTrackerDirect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/getTrackerDirect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Direct: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Direct: ' + error.message);
    }
  },
  saveTrackerDirect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/saveTrackerDirect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Direct: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Direct: ' + error.message);
    }
  },
  updateTrackerDirect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/updateTrackerDirect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Direct: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Direct: ' + error.message);
    }
  },
  destroyTrackerDirect: async (formData: any) => {
    try {
      const response = await axios.post(`trackerDirect/destroyTrackerDirect`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker Direct: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker Direct: ' + error.message);
    }
  },
  ///TRACKER BOWEL
  getTrackerBowelType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerBowelType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel Type: ' + error.message);
    }
  },
  saveTrackerBowelType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerBowelType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker direct type: ' + error.message);
    }
  },
  //TRACKER Bowel
  getTrackerBowel: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerBowel`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel: ' + error.message);
    }
  },
  saveTrackerBowel: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerBowel`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Bowel: ' + error.message);
    }
  },
  exportReportBowel: async (formData: any, responseType) => {
    try {
      const response = await axios.post(
        `trackerIncontinence/exportReportBowel`, 
        formData,
        responseType // Asegúrate de establecer el responseType como 'blob'
      );
  
      if (response && response.data) {
        // Si la respuesta contiene los datos, se retornan.
        return response.data;
      } else {
        throw new Error('Error exporting tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error exporting tracker Bowel: ' + error.message);
    }
  },
  exportReportUrine: async (formData: any, responseType) => {
    try {
      const response = await axios.post(
        `trackerIncontinence/exportReportUrine`, 
        formData,
        responseType // Asegúrate de establecer el responseType como 'blob'
      );
  
      if (response && response.data) {
        // Si la respuesta contiene los datos, se retornan.
        return response.data;
      } else {
        throw new Error('Error exporting tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error exporting tracker Bowel: ' + error.message);
    }
  },
  exportReportBrief: async (formData: any, responseType) => {
    try {
      const response = await axios.post(
        `trackerIncontinence/exportReportBrief`, 
        formData,
        responseType // Asegúrate de establecer el responseType como 'blob'
      );
  
      if (response && response.data) {
        // Si la respuesta contiene los datos, se retornan.
        return response.data;
      } else {
        throw new Error('Error exporting tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error exporting tracker Bowel: ' + error.message);
    }
  },
  exportReportVitals: async (formData: any, responseType) => {
    try {
      const response = await axios.post(
        `trackerVitals/exportReportVitals`, 
        formData,
        responseType // Asegúrate de establecer el responseType como 'blob'
      );
  
      if (response && response.data) {
        // Si la respuesta contiene los datos, se retornan.
        return response.data;
      } else {
        throw new Error('Error exporting tracker Vitals: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error exporting tracker Vitals: ' + error.message);
    }
  },
  exportReportHygiene: async (formData: any, responseType) => {
    try {
      const response = await axios.post(
        `trackerHygiene/exportReportHygiene`, 
        formData,
        responseType // Asegúrate de establecer el responseType como 'blob'
      );
  
      if (response && response.data) {
        // Si la respuesta contiene los datos, se retornan.
        return response.data;
      } else {
        throw new Error('Error exporting tracker Hygiene: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error exporting tracker Hygiene: ' + error.message);
    }
  },
  updateTrackerBowel: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/updateTrackerBowel`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Bowel: ' + error.message);
    }
  },
  destroyTrackerBowel: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/destroyTrackerBowel`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker Bowel: ' + error.message);
    }
  },
  ///TRACKER URINE
  getTrackerUrineType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerUrineType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel Type: ' + error.message);
    }
  },
  saveTrackerUrineType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerUrineType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker direct type: ' + error.message);
    }
  },
  //TRACKER URINE
  getTrackerUrine: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerUrine`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel: ' + error.message);
    }
  },
  saveTrackerUrine: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerUrine`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Bowel: ' + error.message);
    }
  },
  updateTrackerUrine: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/updateTrackerUrine`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Bowel: ' + error.message);
    }
  },
  destroyTrackerUrine: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/destroyTrackerUrine`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker Bowel: ' + error.message);
    }
  },
  ///TRACKER BRIEF
  getTrackerBriefType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerBriefType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel Type:');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel Type: ' + error.message);
    }
  },
  saveTrackerBriefType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerBriefType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker direct type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker direct type: ' + error.message);
    }
  },
  //TRACKER URINE
  getTrackerBrief: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/getTrackerBrief`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Bowel: ' + error.message);
    }
  },
  saveTrackerBrief: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/saveTrackerBrief`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Bowel: ' + error.message);
    }
  },
  updateTrackerBrief: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/updateTrackerBrief`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Bowel: ' + error.message);
    }
  },
  destroyTrackerBrief: async (formData: any) => {
    try {
      const response = await axios.post(`trackerIncontinence/destroyTrackerBrief`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker Bowel: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error destroy tracker Bowel: ' + error.message);
    }
  },

  //TRACKER SKIN CHECK
  getTrackerSkinCheck: async (formData: any) => {
    try {
      const response = await axios.post(`trackerSkinCheck/getTrackerSkinCheck`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching Skin Check: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching Skin Check: ' + error.message);
    }
  },
  saveTrackerSkinCheck: async (formData: any) => {
    try {
      const response = await axios.post(`trackerSkinCheck/saveTrackerSkinCheck`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Skin Check: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Skin Check: ' + error.message);
    }
  },
  updateTrackerSkinCheck: async (formData: any) => {
    try {
      const response = await axios.post(`trackerSkinCheck/updateTrackerSkinCheck`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Skin Check: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Skin Check: ' + error.message);
    }
  },
  destroyTrackerSkinCheck: async (formData: any) => {
    try {
      const response = await axios.post(`trackerSkinCheck/destroyTrackerSkinCheck`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error destroy tracker Skin Check: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker food: ' + error.message);
    }
  },
  // TRACKER FOOD
  getTrackerFood: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/getTrackerFood`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker food: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker food: ' + error.message);
    }
  },
  saveTrackerFood: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/saveTrackerFood`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker food: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker food: ' + error.message);
    }
  },
  updateTrackerFood: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/updateTrackerFood`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker food type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker food type: ' + error.message);
    }
  },
  // TRACKER FOOD TYPE
  getTrackerFoodType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/getTrackerFoodType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker food type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker food type: ' + error.message);
    }
  },
  saveTrackerFoodType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/saveTrackerFoodType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker food type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker food type: ' + error.message);
    }
  },
  updateTrackerFoodType: async (formData: any) => {
    try {
      const response = await axios.post(`trackerFood/updateTrackerFoodType`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker food type: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker food type: ' + error.message);
    }
  },
  exportReport: async (formData: any) => {
    try {
      const response = await axios.post('trackerFood/exportReport', formData, {
        responseType: 'blob'
      });
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker food report: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker food report: ' + error.message);
    }
  },
  exportReportDirect: async (formData: any, token: string) => {
    try {
      return await axios.post('trackerDirect/exportReport', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
    } catch (error) {
      throw new Error('Error generating report: ' + error);
    }
  },
  // TRACKER CASH RESOURCE
  getTrackerCashResource: async (formData: any) => {
    try {
      const response = await axios.post(`trackerCashResource/getTrackerCashResource`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker Cash Resource: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker Cash Resource: ' + error.message);
    }
  },
  saveTrackerCashResource: async (formData: any) => {
    try {
      const response = await axios.post(`trackerCashResource/saveTrackerCashResource`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating tracker Skin Check: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating tracker Skin Check: ' + error.message);
    }
  },
  updateTrackerCashResource: async (formData: any) => {
    try {
      const response = await axios.post(`trackerCashResource/updateTrackerCashResource`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error update tracker Cash Resource: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error update tracker Cash Resource: ' + error.message);
    }
  },
  destroyTrackerCashResource: async (formData: any) => {
    try {
      const response = await axios.post('trackerCashResource/destroyTrackerCashResource', formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error Delete tracker Cash Resource report: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error Delete tracker Cash Resource report: ' + error.message);
    }
  },
  balanceResident: async (formData: any) => {
    try {
      const response = await axios.post(`trackerCashResource/balanceResident`, formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error fetching tracker Cash Resource: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error fetching tracker Cash Resource: ' + error.message);
    }
  },
};

export default TrackerService;

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { CustomAlertsParams } from '@/common/domain/Alerts';

const parseError = (error: any): string => {
  if (error.response) {
    return error.response.data.message || 'Error processing the request on the server';
  } else if (error.request) {
    return 'No response was received from the server';
  } else {
    return 'An error occurred while processing the request';
  }
};

const ResidentProfileService  = {
  getContactByResident: async (
    resident_id: string,
    category: string[],
  ) => {
    let url = `/residents/contacts`;

    try {
      const response = await axios.get(url, {
        params: {
          resident_id,
          'category[]': category,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(parseError(error));
    }
  },

  getPhysicianByResident: async (resident_id: string) => {
    let url = `/residents/physician`;

    try {
      const response = await axios.get(url, {
        params: { resident_id },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(parseError(error));
    }
  },

  getVitalResidentAlerts: async (
    location_id?: string  | null,
    resident_id?: string[] | null
  ) => {
    let url = `/alerts/resident?`;

    try {
      const params = new URLSearchParams();

      if (location_id) params.append('location_id', location_id);
      if (resident_id && Array.isArray(resident_id)) {
        resident_id.forEach((id) => {
          params.append('resident_id[]', id);
        });
      }

      const config = { params };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      throw new Error(parseError(error));
    }
  },

   getCustomAlerts: async (params: CustomAlertsParams) => {
      const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      try {
        const response = await axios.get('/custom-alerts', { params: filteredParams });
        return response.data;
      } catch (error: any) {
        throw new Error(parseError(error));
      }
    },
}
export default ResidentProfileService;
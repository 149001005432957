import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { File } from 'lucide-react';
import { toast } from '@/components/ui/use-toast';

import { NavigationI } from '@/modules/residents/domain/models/interfaces/Forms/Navigation.domain';
import { ResidentI } from '@/common/domain/Residents';
import { AllContactsI } from '@/modules/residents/domain/models/Contacts';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import handleDownloadPDF from '../../../../../domain/utils/Forms/handleDownloadPDF';
import getFormData from '../../../../../domain/utils/Forms/getFormData';

interface DownloadsSectionProps {
  downloads: NavigationI[];
  resident: ResidentI | null;
  contacts: AllContactsI[];
}

export const DownloadsSection = ({ downloads, resident, contacts }: DownloadsSectionProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { generatePdf, getFormHistory } = useFormsStore();

  const handleDownload = async (documentKey: string) => {
    if (!resident?.id) return;

    setIsLoading(true);

    try {
      const pdfData = await getFormData(resident, resident.id, documentKey, getFormHistory, contacts);

      await handleDownloadPDF({
        documentKey,
        generatePdf,
        pdfData,
      });
    } catch (error) {
      toast({
        description: t('residents.forms.errorGeneratingPdf'),
        variant: 'destructive',
      });
      console.error('Error handling download:', error);
    }  finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='text-sm w-full text-zinc-600 dark:text-white'>
      <hr className="w-full border-t-1 border-primary my-6" />
      <div className="flex flex-col w-full">
        {downloads.map((download, index) => (
          <button
            disabled={isLoading}
            key={download.path}
            onClick={() => {
              handleDownload(download.path)
            }}
            className={`items-center w-full  border-t border-primary text-left
            hover:bg-primary/10 hover:text-primary transition-all ${index === downloads.length - 1 ? 'border-b' : ''} `}
          >
            <div className="flex items-center gap-2 px-2 py-2">
                <File className="h-4 w-4 flex-shrink-0" />
                <span className='hover:underline'>
                  {t(`residents.forms.linkForms.${download.key}`)}
                </span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

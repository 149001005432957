import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';

import {
  FormValues,
  UploadDocumentForm
} from '@/modules/residents/presentation/components/Documents/Forms/UploadDocumentForm';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { saveLocationDocument } from '../../slices/locationsSlice';

interface facilityInformationI {
    isOpen: boolean;
    onClose: () => void;
    location: any;
    category: any;
    dataFolders: () => Promise<void>;
}

export const UploadDocumentFacility = ({isOpen, onClose, category, location, dataFolders}: facilityInformationI) => {

  const { status } = useSelector((state: RootState) => state.locations.allLocations);

  const dispatch = useDispatch<AppDispatch>();

  const saveDocument = async (values: FormValues) => {
    const { files, ...rest } = values;

    const data = {
      ...rest,
      key: values.label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
      category: category,
      location_id: location?.id,
      parent_document_id: null,
      isEdit: false,
      other_information: {
        digital_singature_on_file: false,
        hard_copy: false
      }
    };

    const stringifiedData = JSON.stringify(data);

    const payload = {
      files: files,
      data: stringifiedData
    };

    const response = await dispatch(saveLocationDocument(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      dataFolders();
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[500px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle className="text-xl">
              Upload Document for <span className="font-bold">{location?.name}</span>
            </DialogTitle>
          </DialogHeader>
          <UploadDocumentForm
            onSubmit={saveDocument}
            status={status}
            defaultValues={{
              label: '',
              comments: '',
              files: undefined
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomAccordion } from "@/common/presentation/components/CustomAccordion/CustomAccordion";
import { Label } from "@/common/presentation/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/common/presentation/components/ui/radio-group";
import { Textarea } from "@/common/presentation/components/ui/textarea";
import { OtherInformation, saveOtherInformationPayload } from "@/modules/residents/infrastructure/types/otherInformation";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface OtherRequiredInformationUnitProps {
    data: OtherInformation;
    getPendingToRequest: () => saveOtherInformationPayload[],
    setPendingToRequest: (newInformation: saveOtherInformationPayload[]) => void;
};

const OtherRequiredInformationUnit = ({ data, getPendingToRequest, setPendingToRequest }: OtherRequiredInformationUnitProps) => {
    const { params } = useRouteParams();
    const [values, setValues] = useState(
      {
        response: null,
        questions: {},
        comment: ""
      }
    );

    useEffect(() => {
        if(data) {
            setValues(
                {
                    ...values,
                    response: data.response,
                    comment: data?.detail ?? ""
                }
            );
        }
    }, [data]);

    useEffect(() => {
        if(data) {
            let currentInformations = getPendingToRequest();

            currentInformations = currentInformations.filter(
            (currentInformation) => currentInformation.information != data?.id
            );

            setPendingToRequest(
            [
                ...currentInformations,
                {
                information: data?.id,
                resident_id: params.r,
                response: values.response ?? "",
                detail: values.comment,
                questions: {
                    ...values.questions
                }
                }
            ]
            );
        }
    }, [values]);

    const onChangeForm = (value: string, field: string) => {
        setValues(
            {
            ...values,
            [field]: value
            }
        );
    }

    const onChangeQuestions = (value: string, field: string, option: string) => {
        const oldValue = values.questions[field] ?? {};

        setValues(
            {
            ...values,
            questions: {
                ...values.questions,
                [field]: {
                    ...oldValue,
                    [option]: value
                }
            }
            }
        );
    }

    return (
        <>
            {
                data ? (
                    <div className="{cn(className)}">
                        <CustomAccordion title={t(data.name)} openOption={false} defaultOpen={true}>
                            <form className="p-1 space-y-8">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-4">
                                        <h1>
                                            {t(data.description)}
                                        </h1>
                                    </div>

                                    {
                                        data.options && data.options.data.length && (
                                            <div className="col-span-4">
                                                <RadioGroup value={values.response} onValueChange={(newValue) => onChangeForm(newValue, "response")}>
                                                    <div className="flex flex-row gap-40 justify-center">
                                                        {
                                                            data.options.data.map(
                                                                (option) => (
                                                                    <div className='flex content-center items-center'>
                                                                        <RadioGroupItem value={option} id={option} className='mr-1' />
                                                                        <Label htmlFor={option}>{t(option)}</Label>
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="col-span-1 ">
                                    {
                                        data && data.questions.length ? (
                                            <div className='mb-1'>
                                                <span className='font-bold text-primary'>{t("common.yes")}</span>
                                                <span className='font-bold ml-3 text-primary'>{t("common.no")}</span>
                                            </div>
                                        ): null
                                    }

                                    {
                                        data.questions.map(
                                            (otherInformationQuestion) => (
                                                <div className="grid grid-cols-3 grid-rows-1 mb-2">
                                                    <div className='flex items-center'>
                                                        <RadioGroup defaultChecked={true} defaultValue={otherInformationQuestion.response} onValueChange={(newValue) => onChangeQuestions(newValue, otherInformationQuestion.id, "response")}>
                                                            <div className="flex  flex-row gap-4">
                                                                <div className="flex flex-col">
                                                                    <RadioGroupItem value="yes"/>
                                                                </div>
                                                                
                                                                <div className="flex flex-col">
                                                                    <RadioGroupItem value="no"/>
                                                                </div>
                                                            </div>
                                                        </RadioGroup>

                                                        <span className='ml-5 text-nowrap'>{t(otherInformationQuestion.title)}</span>
                                                    </div>

                                                    {
                                                        otherInformationQuestion.options && otherInformationQuestion.options.data.length ? (
                                                            <div className="flex content-center items-center">
                                                                <RadioGroup defaultValue={otherInformationQuestion.optionResponse} onValueChange={(newValue) => onChangeQuestions(newValue, otherInformationQuestion.id, "optionResponse")}>
                                                                    <div className="flex flex-wrap">
                                                                        {
                                                                            otherInformationQuestion.options.data.map(
                                                                                (option) => (
                                                                                    <div className='flex content-center items-center p-1'>
                                                                                        <RadioGroupItem value={option} id={option} className='mr-1' />
                                                                                        <Label htmlFor={option}>{t(option)}</Label>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                </RadioGroup>
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )
                                                    }
                                                    
                                                    {
                                                        otherInformationQuestion.hasDetail ? (
                                                            <Textarea 
                                                                defaultValue={otherInformationQuestion.detail}
                                                                className="w-80 min-h-5 justify-self-end"
                                                                onChange={(event) => onChangeQuestions(event.target.value, otherInformationQuestion.id, "detail")}
                                                            ></Textarea>
                                                        ): null
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                                
                                {
                                    data.hasDetail ? (
                                        <div>
                                            <span className='font-medium'>{t("common.comments")}</span>
                                            <Textarea value={values.comment} onChange={(event) => onChangeForm(event.target.value, "comment")} />
                                        </div>
                                    ): null
                                }
                            </form>
                        </CustomAccordion>
                    </div>
                ): null
            }
        </>
    );
};

export default OtherRequiredInformationUnit;

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useTranslation } from 'react-i18next';

interface ISelectStaffProps {

    selectedStaff: string;
    handleStaffChange: (id: string) => void;
}

export function SelectStaffBedroomQr({ selectedStaff, handleStaffChange }: ISelectStaffProps) {
    const { t } = useTranslation();
    const { staff } = useSelector((state: RootState) => state.staff.allStaff);

    const staffs = staff.find((st) => st.id === selectedStaff)
    return (

        <div className="w-1/4 flex items-center justify-between">
            <Select value={selectedStaff} onValueChange={handleStaffChange}>
                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                    {staffs ?
                        (<SelectValue>
                            {staffs.first_name + ' ' + staffs.last_name}
                        </SelectValue>) :
                        (selectedStaff == 'all' ? 'All' : t('trackers.Staff'))}
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    {staff.map((member) => (
                        <SelectItem key={member.id} value={member.id}>
                            {member.first_name} {member.last_name}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}
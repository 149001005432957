import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppDispatch, RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '@/common/presentation/components/ui/accordion';
import { CardContent} from '@/components/ui/card';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import ElderlyCareRightsText from './components/ElderlyCareRightsText';
import ResidentRepresentativeForm from './components/ResidentRepresentativeForm';
import LegalNoticeSectionForm from './components/LegalNoticeSectionForm';
import { CustomCard } from '../shared/CustomCard';
import FormActionButtons from '../shared/FormActionButtons';
import { personalRightsRCFEDefaults } from '../formDefaults/personalRightsRCFEDefaults';

export const PersonalRightsRCFEForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const methods = useForm({
    defaultValues: personalRightsRCFEDefaults(resident),
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(personalRightsRCFEDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic613c_personal_rights',
  });

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    await handleSubmit({
      formData: rawData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
          <BackNavigationBar
            title={title}
            path={`/residents/forms?r=${params.r}`}
          />
          <FormProvider {...methods}>
            <form>
              <LegalNoticeSectionForm />
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <Accordion type="single" collapsible>
                    <AccordionItem value="valuables_removed" className='border-b-0 w-full transition-all'>
                      <AccordionTrigger className='no-underline focus:outline-none focus:ring-0 p-0 hover:no-underline'>
                        <p className='font-normal'>
                          {t('residents.forms.personalRightsRcfe.residentPersonalRightsIntro')}:
                        </p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <hr className="w-full border-t-1 border-primary p-0 my-4" />
                        <ElderlyCareRightsText />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <ResidentRepresentativeForm />
                  <FormActionButtons
                    handleFormSubmit={handleFormSubmit}
                    formsStatus={formsStatus}
                  />
                </CardContent>
              </CustomCard>
            </form>
          </FormProvider>
      </div>
    </div>
  );
};

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { Button } from '@/common/presentation/components/ui/button';
import { Card } from '@/common/presentation/components/ui/card';
import { useEffect, useState, useCallback } from 'react';
import { getRoomsLocation } from '../../slices/locationsSlice';
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { FiltersTable } from '@/modules/settings/presentation/components/menus/BedroomQr/presentation/components/FiltersTable';
import { useTranslation } from 'react-i18next';
import { DialogCreateOrUpdateRoom } from './DialogCreateOrUpdateRoom';
import { Pencil, Trash } from 'lucide-react';

interface RoomsI {
    id: string;
    name: string;
    floor: number;
}

export const LocationRooms = () => {

    const params = new URLSearchParams(location.search)
    const locationId = params.get('id');
    const [rooms, setRooms] = useState<RoomsI[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<RoomsI | null>(null);
    const [action, setAction] = useState<"edit" | "delete" | ''>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true);

    const dataRoomsLocation = async (page: number, perPage: number, resetData: boolean = false) => {

        setIsLoading(true);
        if (!locationId) {
            console.error("Location ID is null or undefined");
            return;
        }
        const response = await dispatch(getRoomsLocation({
            locationId,
            search: searchTerm,
            per_page: perPage,
            page
        }))

        const { rooms }: {
            rooms: { data: RoomsI[], current_page: number, last_page: number, total: number }
        } = response.payload;

        const data = rooms.data
        if (resetData) {
            setRooms(data);
        } else {
            setRooms(prevData => [...prevData, ...data]);
        }

        if (rooms.current_page >= rooms.last_page ||
            rooms.total < data.length) {
            setHasMoreRows(false);
        } else {
            setHasMoreRows(true);
        }

        setIsLoading(false);
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setAction('');
    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const handleClearFilter = () => {
        setSearchTerm('');
    };

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataRoomsLocation(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataRoomsLocation]);

    const renderActions = (row: RoomsI) => (
        <div className="flex space-x-2">
            <span className="cursor-pointer">
                <Pencil className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'edit')} />
            </span>
            <span className="cursor-pointer">
                <Trash className="mr-2 h-4 w-4" onClick={() => handleOpenModal(row, 'delete')} />
            </span>
        </div>
    );

    const columnsreport = [
        { key: 'name', labelTranslationKey: t('facilities.name'), value: 'name', sortable: false },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    useEffect(() => {
        setRooms([]);
        setCurrentPage(1);
        setHasMoreRows(true);
        dataRoomsLocation(1, rowsPerPage, true);
    }, [searchTerm]);

    const handleOpenModal = (rowData: RoomsI, actionType: 'edit' | 'delete') => {
        setSelectedRow(rowData)
        setOpenModal(true)
        setAction(actionType)
    }

    return (
        <div className="p-5">
            <div className="flex flex-col gap-2 mt-4">
                <div className="font-semibold text-2xl text-primary">Rooms</div>
            </div>
            <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
                <TableCustomVirtuoso
                    data={rooms}
                    columns={columnsreport}
                    renderCellContent={(index, column, data) => {
                        const row = data[index];

                        return row[column.key];
                    }}
                    additionalButtons={<Button
                        variant="outline"
                        size="sm"
                        className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
                        onClick={() => setOpenModal(true)}
                    ><span>{t('common.new')}</span></Button>}

                    isLoading={isLoading}
                    filters={
                        <div className="w-1/2">
                            <FiltersTable
                                onFilterChange={handleSearchChange}
                                filterValue={searchTerm}
                                onClear={handleClearFilter}
                            />
                        </div>
                    }
                    loadMore={loadMore}
                    hasMore={hasMoreRows}
                    showSearchInput={false}

                />
            </Card>

            <DialogCreateOrUpdateRoom
                openModal={openModal}
                closeModal={handleCloseModal}
                locationId={locationId}
                selectedRow={selectedRow}
                action={action}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                dataRoomsLocation={dataRoomsLocation}

            />
        </div>
    )
}
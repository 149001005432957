import { ResidentI } from "@/common/domain/Residents";
import { format, parseISO } from 'date-fns';
import { calculateAge, formatNumberWithPattern } from "@/modules/residents/domain/utils/Forms/formUtils";
import { AllContactsI } from "@/modules/residents/domain/models/Contacts";
import { filterContactsByCategory } from "@/modules/residents/domain/utils/filterContactsByCategory";

export const lic601EmergencyInfoDefaults = (resident: ResidentI, contacts: AllContactsI[]) => {
  const categories = {
    dentist: "Dentist",
    friends: "Friends",
    mental_health_provider: "Mental Health Provider, If Any",
    hospital: "Name of Hospital to be Taken in an Emergency",
    relatives: "Nearest Relative",
    physician: "Primary Physician",
    responsiblePerson: "Responsible Person"
  };

  const filteredContacts = Object.entries(categories).reduce(
    (acc, [key, category]) => {
      const filteredContact = filterContactsByCategory(contacts, category);
      acc[key] = filteredContact || {};
      return acc;
    },
    {} as Record<string, any>
  );

  return {
    resident: {
      name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
      social_security_number: resident?.security_number
        ? formatNumberWithPattern(resident.security_number)
        : '',
      dob: resident?.dob ? format(new Date(resident.dob), 'MM/dd/yyyy') : '',
      age: resident?.dob ? calculateAge(resident.dob) : '',
      sex: resident?.sex || '',
    },
    responsible_person: {
      name: filteredContacts.responsiblePerson?.name || '',
      address: filteredContacts.responsiblePerson?.address || '',
      telephone: filteredContacts.responsiblePerson?.lastPhone || '',
    },
    nearest_relative: {
      name: filteredContacts.relatives?.name || '',
      relationship: filteredContacts.relatives?.relationship || '',
      address:  `${filteredContacts.relatives?.address || ''} ${filteredContacts.relatives?.city || ''} ${filteredContacts.relatives?.state || ''} ${filteredContacts.relatives?.zipCode || ''}`.trim(),
      telephone: filteredContacts.relatives?.lastPhone || ''
    },
    admitted_facility: {
      date: resident?.other_information?.admission_date
      ? format(parseISO(resident?.other_information?.admission_date), 'yyyy-MM-dd')
      : '',
      address: resident?.other_information?.address_of_prior_to_admision || ''
    },
    date_left: resident?.other_information?.date_of_left_prior_facility
    ? format(parseISO(resident?.other_information?.date_of_left_prior_facility), 'yyyy-MM-dd')
    : '',
    forwarding_address: filteredContacts.responsiblePerson?.address || '',
    reasons_leaving_facility: resident?.other_information?.reason_for_leaving_prior_facility || '',
    financial_responsible: [
      {
        name: filteredContacts.responsiblePerson?.name || '',
        address: filteredContacts.responsiblePerson?.address || '',
        telephone: filteredContacts.responsiblePerson?.lastPhone || '',
      },
      ...Array.from({ length: 2 }, () => ({
        name: '',
        address: '',
        telephone: '',
      })),
    ],
    other_emergency_contacts:{
      dentist: {
        name: filteredContacts.dentist?.name || '',
        email: filteredContacts.dentist?.email || '',
        telephone: filteredContacts.dentist?.lastPhone || '',
        fax: '',
        address: filteredContacts.dentist?.address || '',
        city: filteredContacts.dentist?.city || '',
        state: filteredContacts.dentist?.state || '',
        zip_code: filteredContacts.dentist?.zipCode || '',
      },
      friends: {
        name: filteredContacts.friends?.name || '',
        email: filteredContacts.friends?.email || '',
        telephone: filteredContacts.friends?.lastPhone || '',
        fax: '',
        address: filteredContacts.friends?.address || '',
        city: filteredContacts.friends?.city || '',
        state: filteredContacts.friends?.state || '',
        zip_code: filteredContacts.friends?.zipCode || '',
      },
      physician: {
        name: filteredContacts.physician?.name || '',
        email: filteredContacts.physician?.email || '',
        telephone: filteredContacts.physician?.lastPhone || '',
        fax: '',
        address: filteredContacts.physician?.address || '',
        city: filteredContacts.physician?.city || '',
        state: filteredContacts.physician?.state || '',
        zip_code: filteredContacts.physician?.zipCode || '',
      },
      relatives: {
        name: filteredContacts.relatives?.name || '',
        email: filteredContacts.relatives?.email || '',
        telephone: filteredContacts.relatives?.lastPhone || '',
        fax: '',
        address: filteredContacts.relatives?.address || '',
        city: filteredContacts.relatives?.city || '',
        state: filteredContacts.relatives?.state || '',
        zip_code: filteredContacts.relatives?.zipCode || '',
      },
      mental_health_provider: {
        name: filteredContacts.mental_health_provider?.name || '',
        email: filteredContacts.mental_health_provider?.email || '',
        telephone: filteredContacts.mental_health_provider?.lastPhone || '',
        fax: '',
        address: filteredContacts.mental_health_provider?.address || '',
        city: filteredContacts.mental_health_provider?.city || '',
        state: filteredContacts.mental_health_provider?.state || '',
        zip_code: filteredContacts.mental_health_provider?.zipCode || '',
      },
    },
    hospitalization_plan: {
      hospital_name: filteredContacts.hospital?.name || '',
      hospital_address: `${filteredContacts.hospital?.address ?? ''} ${filteredContacts.hospital?.city ?? ''} ${filteredContacts.hospital?.state ?? ''} ${filteredContacts.hospital?.zipCode ?? ''}`,
      medical_plan: '',
      medical_plan_id: '',
      dental_plan: '',
      dental_plan_number: ''
    },
    required_info: {
      ambulatory_status: '',
      religious_preference: resident?.religion_preferences?.name || '',
      clergyman_name: resident?.religion_preferences?.clergyman_name || '',
      clergyman_address: resident?.religion_preferences?.address || '',
      telephone: resident?.religion_preferences?.telephone || '',
      comments: ''
    }
  }
}

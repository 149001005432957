import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Pencil, Trash, Settings } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { debounce } from 'lodash';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { useTranslation } from 'react-i18next';
import TrackerVitalsType from './TrackerVitalsType';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { TrackerVitalsDetail } from '../components/Vitals/TrackerVitalsDetail';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import QuickTracker from '@/common/presentation/components/QuickTracker/QuickTracker';
import QuickTrackerItem from '@/common/presentation/components/QuickTracker/interfaces/QuickTrackerItemInterface';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch } from '@/store/store';
import { SelectStaffBedroomQr } from '../components/BedroomQr/SelectStaffBedroomQr';
import { SelectType } from '../components/Toileting/SelectType';
import { FileDown } from 'lucide-react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Loader2 } from 'lucide-react';

interface TypeI {
    id: string;
    name: string;
    status: 'active' | 'inactive';
}

interface TypesI {
    column_field: TypeI[];
}

interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    typeName?: string;
    detail: string | null;
    location_id: string;
    type: string;
    value: string;
    id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    who: {
        id: string;
        first_name: string;
        last_name: string;
    };
    date: string;
}

function TrackerVitals() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const [resident, setResident] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState<TypeI[]>([]);
    const [quickTypes, setQuickTypes] = useState<TypeI[]>([]);
    const [dataReport, setDatareport] = useState<TrackerI[]>([]);
    const [dataDayReport, setDataDayReport] = useState([]);
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedHygiene, setSelectedVitals] = useState<string>(params.e || 'form');
    const [selectedTab, setSelectedTab] = useState<string>("tracker");
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { locationSelectedId } = useLocation();
    const [selectedStaff, setSelectedStaff] = useState<string>('');
    const [typeId, setTypeId] = useState<string>('');
    const [startDateVitals, setStartDateVitals] = useState(undefined);
    const formattedStartDateVitals = startDateVitals ? startDateVitals.toISOString() : '';
    const [endDateVitals, setEndDateVitals] = useState(undefined);
    const formattedEndDateVitals = endDateVitals ? endDateVitals.toISOString() : '';
    const [submitReport, isSubmitReport] = useState<boolean>(false);

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedVitals(e);
    };

    useEffect(() => {
        if (locationSelectedId) {
            dispatch(staffSliceActions.getStaffByLocation(locationSelectedId === 'all-locations' ? undefined : locationSelectedId));
        } else {
            setParam('l', 'all-locations');
        }
    }, [locationSelectedId]);

    const onSelectTab = (e: string) => {

        setSelectedTab(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);


    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedVitals('form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const [dataOriginal, setDataOriginal] = useState<any[]>([]);

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (selectedHygiene !== "report") {
            return;
        }

        if (!isInitialized && dataReport.length > 0) {
            setDataOriginal(dataReport); // Saves a copy of all the data initially
            setIsInitialized(true); // Marks initialization as complete
        }

        if (dataOriginal.length > 0 || dataReport.length > 0) {
            let updatedData = dataOriginal.length > 0 ? dataOriginal : dataReport;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => item.location_id === locationSelectedId);
                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }
                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            // Only updates if dataReport needs to be changed
            if (JSON.stringify(updatedData) !== JSON.stringify(dataReport)) {
                setDatareport(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginal, dataReport, selectedHygiene]);

    const handleStaffChange = (id: string) => {
        setSelectedStaff(id);
    };

    const handleStartDateChangeVitals = (date) => {
        setStartDateVitals(date);
    };

    const handleEndDateChangeVitals = (date) => {
        setEndDateVitals(date);
    };

    const handleTypeChange = (id: string) => {
        setTypeId(id);

    };

    const exportReportVitals = async () => {
        isSubmitReport(true);
        const json = {
            location_id: locationSelectedId,
            resident_id: params.r,
            user_id: selectedStaff,
            type: typeId,
            startdate: startDateVitals,
            enddate: endDateVitals
        };

        try {
            const response = await TrackerService.exportReportVitals(json, {
                responseType: 'blob',
            });

            // Crear un blob a partir de la respuesta
            const blob = new Blob([response], { type: 'application/pdf' });

            // Crear una URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Abrir la URL en una nueva ventana o pestaña
            window.open(url);
        } catch (error) {
            console.error('Error al exportar el reporte:', error);
        }
        isSubmitReport(false);
    };

    const newColumns = () => {
        const firstObject = {
            label: t('trackers.Type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.id,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const renderActions = (row: any, type: TrackerType) => (
        <div className="flex space-x-2">

            <span className="cursor-pointer" onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row.id, row.data, '')}>
                <Pencil className="mr-2 h-4 w-4" />
            </span>

            <span className="cursor-pointer" onClick={() => handleButtonOpenDialog([], 'Delete', row.id, row.data, t('trackers.DeleteItem'))}>
                <Trash className="mr-2 h-4 w-4" />
            </span>

        </div>
    );

    const columnsreport = [
        { key: 'date', labelTranslationKey: t('trackers.Date/Time'), value: 'date', type: 'date', sortable: false },
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: false },
        { key: 'type_name', labelTranslationKey: t('trackers.Type'), value: 'type_name', sortable: false },
        { key: 'value', labelTranslationKey: t('trackers.Data'), value: 'value', sortable: false },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail', sortable: false },
        { key: 'who_name', labelTranslationKey: t('trackers.Staff'), value: 'who_name', sortable: false },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState(true); // Indicates if there are more rows to load
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const dataVitalsReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true);
        const json = {
            location_id: locationSelectedId, per_page: perPage, page, resident_id: params.r,
            user_id: selectedStaff,
            type: typeId,
        };
        const response = await TrackerService.getTrackerVitals(json);

        if (response) {
            try {
                const { types, trackerVitals }: { types: TypeI[], trackerVitals: { data: TrackerI[], current_page: number, last_page: number, total: number } } = response;
                setTypes(types);

                setQuickTypes(
                    types.map(
                        (value) => ({
                            id: value.id,
                            name: value.type_name,
                            status: value.status,
                            options: JSON.parse(value.options),
                            clientTrackerTypes: value.clientTrackerTypes
                        })
                    )
                );

                const data = trackerVitals.data;

                data.sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });

                if (resetData) {
                    setDatareport(data);
                    setDataOriginal(data);
                } else {
                    setDatareport(prevData => [...prevData, ...data]);
                    setDataOriginal(prevData => [...prevData, ...data]);
                }

                if (trackerVitals.current_page >= trackerVitals.last_page || trackerVitals.total < data.length) {
                    setHasMoreRows(false);
                } else {
                    setHasMoreRows(true);
                }

            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        }

        setIsLoading(false);
    };

    const dataDayVitalsReport = async () => {
        setIsLoading(true);
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getDayTrackerVitals(json);

        if (response) {
            try {
                const { trackerVitals }: { trackerVitals: TrackerI[] } = response;

                setDataDayReport(
                    trackerVitals.map(
                        (value) => (
                            {
                                id: value.id,
                                id_data: value.data.id,
                                action: value.data.value,
                                resident: value.data.resident,
                                type: value.data.type
                            }
                        )
                    )
                );

            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        dataDayVitalsReport();
    }, []);

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataVitalsReport(nextPage, rowsPerPage);
    }, [hasMoreRows, currentPage, dataVitalsReport]);

    const [pendingSubmit, setPendingSubmit] = useState(null);
    const handleInputChange = (e, typeId, residentId) => {
        const { name, value } = e.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        if (pendingSubmit) {
            pendingSubmit.cancel();
        }
        const debouncedSubmit = debounce(() => {
            buttonSumbit({
                typeId: typeId,
                residentId: residentId,
                value: value
            });
        }, 2500);
        setPendingSubmit(() => debouncedSubmit);
        debouncedSubmit();
    }

    const Fields = {
        'Edit': [
            // { fieldName: 'resident_name', label: 'Resident', type: 'hidden' },
            { fieldName: 'field', label: t('trackers.Type'), type: 'select' },
            { fieldName: 'value', label: t('trackers.Value'), type: 'text' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, idTracker: string, data: TrackerDataI, description: string,) => {
        const filteredFields = fields.filter(field => field.fieldName !== "Tipo" && field.fieldName !== "Type" &&
            field.fieldName !== "Uri");
        const combinedData = { ...data, idTracker };
        setShowEditDialog(true);
        setFormValue(combinedData);
        setDialogField(filteredFields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        if (action === 'Edit') {

            const data = {
                "id": formData.idTracker,
                "id_data": formData.id,
                "type": formData.type_id,
                "detail": formData.detail,
                "value": formData.value,
                "resident_id": formData.resident_id,
                "date": formData.date
            }



            await TrackerService.updateTrackerVitals([data]);

            setShowEditDialog(false);
            dataVitalsReport(1, rowsPerPage, true);

            await dataDayVitalsReport();
        } else {
            const body = {
                id: formData.idTracker,
                id_data: formData.id
            };

            await TrackerService.destroyTrackerVitals(body);

            setShowEditDialog(false);
            dataVitalsReport(1, rowsPerPage, true);

            await dataDayVitalsReport();
        }
    };

    useEffect(() => {
        if (selectedHygiene !== "form" || selectedTab !== "tracker") {
            return;
        }

        setParam("r", "all-residents")
    }, [selectedHygiene, selectedTab]);

    useEffect(() => {
        if (selectedTab !== "details") {
            return;
        }
        setParam("r", "all-residents")
    }, [locationSelectedId]);

    useEffect(() => {
        setDatareport([]);
        setDataOriginal([]);
        setCurrentPage(1);
        setHasMoreRows(true);

        dataVitalsReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r, selectedHygiene, selectedStaff, typeId]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, value }: { typeId: string; residentId: string; value: string; }) => { };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {

        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type_id} onValueChange={(value) => handleSelectChange('type_id', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.find((option) => option.id === formData.type_id)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types
                                .filter((option) => option.status === 'active') // Filtrar opciones activas
                                .map((option) => (
                                    <SelectItem key={option.id} value={option.id}>
                                        {option.type_name}
                                    </SelectItem>
                                ))}
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const { formatDate } = useDateHelpers();
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const submitQuickTracker = async (newItems: QuickTrackerItem[]) => {
        const saveQueries = newItems
            .filter((value) => !value.exist)
            .map(
                (value) => (
                    {
                        type: value.type.id,
                        value: value.action,
                        resident_id: value.resident.id,
                        detail: "",
                        location_id: locationSelectedId,
                    }
                )
            );

        const updateQueries = newItems
            .filter((value) => value.exist)
            .map(
                (value) => (
                    {

                        id: value.id,
                        id_data: value.id_data,
                        type: value.type.id,
                        value: value.action,
                        resident_id: value.resident.id,
                        detail: "",
                        location_id: locationSelectedId,

                    }
                )
            );

        setIsLoading(true);

        try {
            if (saveQueries.length) await TrackerService.saveTrackerVitals(saveQueries);
            if (updateQueries.length) await TrackerService.updateTrackerVitals(updateQueries);

            setIsLoading(false);

            dataVitalsReport(1, rowsPerPage, true);
            dataDayVitalsReport();

            toast(
                {
                    description: t("common.quickTrackerSuccessfully")
                }
            );
        } catch (error) {
            toast(
                {
                    description: t("common.quickTrackerError")
                }
            );
        }
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackervitals.title')}</div>
                            <div className="font-semibold text-1xl"></div>
                        </div>

                        <Tabs defaultValue={selectedHygiene} onValueChange={(e) => { onSelectVitals(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('cleaning.form.label')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <Settings className='w-5 h-5' />
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {
                selectedHygiene === 'form' && (
                    <>
                        <div hidden={selectedTab === 'tracker'}>
                            <ResidentSelector
                                onSelect={onResidentSelect}
                                locationId={location}
                                residentId={params.r}
                                showAllResidents={false}

                            />
                        </div>

                        <Card className="border-t-4 border-t-primary/80 mt-8">
                            <CardHeader>
                                <div className='flex justify-between'>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                        {
                                            selectedTab === "tracker" ? (
                                                <span>
                                                    {t('trackers.trackervitals.quickDescription')}
                                                </span>
                                            ) : (
                                                <span>
                                                    {t('trackers.trackervitals.detailedDescription')}
                                                </span>
                                            )
                                        }
                                    </CardTitle>

                                    <Tabs defaultValue={selectedTab} onValueChange={(e) => { onSelectTab(e); }} className="w-auto">
                                        <TabsList>
                                            <TabsTrigger value="tracker" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                <CircleFadingPlus className="h-4 w-4 mr-1" />
                                                {t("cleaning.form.quick.label")}
                                            </TabsTrigger>
                                            <TabsTrigger value="details" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {t('cleaning.form.detailed.label')}
                                            </TabsTrigger>
                                        </TabsList>
                                    </Tabs>
                                </div>
                            </CardHeader>

                            {
                                selectedTab === 'tracker' && (
                                    <>
                                        {
                                            locationSelectedId && types && types.length ? (
                                                <CardContent style={{ padding: 0 }}>
                                                    <QuickTracker
                                                        types={quickTypes}
                                                        residents={residents}
                                                        loading={isLoading}
                                                        submit={submitQuickTracker}
                                                        data={dataDayReport}
                                                        input={"text"}
                                                    />
                                                </CardContent>
                                            ) : null
                                        }
                                    </>
                                )
                            }

                            {
                                selectedTab === 'details' && (
                                    <CardContent>
                                        <TrackerVitalsDetail
                                            types={types}
                                            residentId={params.r}
                                            locationId={locationSelectedId}
                                            dataDayVitalsReport={dataDayVitalsReport}
                                        />
                                    </CardContent>
                                )
                            }
                        </Card>
                    </>
                )
            }

            <CustomDialog
                width="30rem"
                newDialog={true}
                isOpen={showEditDialog}
                onClose={handleCloseEditDialog}
                title={dialogTitle}
                onSubmit={handleSubmit}
                description={dialogDescription}
            >
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div className="grid gap-4">
                        {fields && fields.map((field, index) => (
                            <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                {renderInputForColumn(field, index)}
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-row justify-end mt-4">
                        <Button type="submit" className="bg-primary">
                            {action === "Delete" ? "Delete" : "Edit"}
                        </Button>
                    </div>
                </form>
            </CustomDialog>

            {
                selectedHygiene === 'report' && (
                    <>
                        <div className="mt-4 mb-4">
                            <ResidentSelector
                                showKeepInEye={true}
                                onSelect={onResidentSelect}
                                residentId={params.r}
                                locationId={location}
                                description={t('calendar.selectAResident')}
                            />
                        </div>

                        <div>
                            <Card className="border-t-4 border-t-primary/50">
                                <CardHeader>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                        {t('trackers.trackervitals.report')}
                                    </CardTitle>
                                </CardHeader>

                                <CardContent>
                                    <TableCustomVirtuoso
                                        data={dataReport}
                                        columns={columnsreport}
                                        showSearchInput={false}
                                        renderCellContent={(index, column, data) => {
                                            const row = data[index];
                                            switch (column.key) {
                                                case 'date':

                                                    return (
                                                        <>
                                                            {formatDate(row.data.date, false)}
                                                            <br />
                                                            {formatDate(row.data.date, true, true)}
                                                        </>
                                                    );
                                                case 'resident_name':
                                                    return row.data.resident
                                                        ? `${capitalizeWords(row.data.resident.first_name || '')} ${capitalizeWords(row.data.resident.last_name || '')}`
                                                        : '';
                                                case 'value':
                                                    return row.data.value ? row.data.value : '';
                                                case 'type_name':
                                                    return row.data.type.type_name !== undefined ? (
                                                        <>
                                                            {capitalizeWords(row.data.type.type_name)}
                                                        </>
                                                    ) : null;
                                                case 'detail':
                                                    return row.data.detail ? capitalizeWords(row.data.detail) : '';
                                                case 'who_name':
                                                    return row.data.created_by
                                                        ? `${capitalizeWords(row.data.created_by.first_name || '')} ${capitalizeWords(row.data.created_by.last_name || '')}`
                                                        : '';


                                                default:
                                                    return row[column.key];
                                            }
                                        }}
                                        additionalButtons={<></>}
                                        onSort={(columnKey: string, direction: string) => {
                                            setSortConfig({ column: columnKey, direction });

                                            const sortedVitals = [...sortedData].sort((a: any, b: any) => {
                                                const getValue = (item: any, key: string) => {
                                                    return item[key] || '';
                                                };

                                                const aValue = getValue(a, columnKey);
                                                const bValue = getValue(b, columnKey);

                                                if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
                                                if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
                                                return 0;
                                            });

                                            setDatareport(sortedVitals);
                                        }}
                                        filters={
                                            <div className="flex items-center gap-4">
                                                <SelectType
                                                    typeId={typeId}
                                                    handleFieldChange={handleTypeChange}
                                                    dataType={types}
                                                />

                                                <SelectStaffBedroomQr
                                                    selectedStaff={selectedStaff}
                                                    handleStaffChange={handleStaffChange}
                                                />

                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button variant="outline" className="w-auto justify-start text-left font-normal">
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {formattedStartDateVitals ? formatDate(formattedStartDateVitals) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                                        </Button>
                                                    </PopoverTrigger>

                                                    <PopoverContent className="w-auto p-0">
                                                        <CustomCalendar
                                                            selectedDate={startDateVitals}
                                                            onChange={handleStartDateChangeVitals}
                                                            MonthAndYearPicker={true}
                                                            timePicker={true}
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                                -
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button variant="outline" className="w-auto justify-start text-left font-normal">
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {formattedEndDateVitals ? formatDate(formattedEndDateVitals) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                                        </Button>
                                                    </PopoverTrigger>

                                                    <PopoverContent className="w-auto p-0">
                                                        <CustomCalendar
                                                            selectedDate={endDateVitals}
                                                            onChange={handleEndDateChangeVitals}
                                                            MonthAndYearPicker={true}
                                                            timePicker={true}
                                                        />
                                                    </PopoverContent>
                                                </Popover>

                                                <FileDown
                                                    onClick={!submitReport && params.r !== 'all-residents' ? exportReportVitals : undefined}
                                                    style={{
                                                        cursor: submitReport || params.r === 'all-residents' ? 'not-allowed' : 'pointer',
                                                        opacity: submitReport || params.r === 'all-residents' ? 0.5 : 1,
                                                    }}
                                                />
                                                {submitReport && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                                            </div>
                                        }
                                        loadMore={loadMore}
                                        hasMore={hasMoreRows}
                                        key={params.r}
                                        isLoading={isLoading}
                                    />
                                </CardContent>
                            </Card>
                        </div>
                    </>
                )
            }

            {
                selectedHygiene === 'type' && (
                    <div>
                        <TrackerVitalsType types={types} dataVitalsReport={dataVitalsReport} />
                    </div>

                )
            }

        </div >
    )
}

export default TrackerVitals
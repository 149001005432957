import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ElderlyCareRightsText = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { t } = useTranslation();

  return (
    <div className=" bg-zinc-50 border border-zinc-300 rounded-lg  p-4 overflow-y-auto max-h-96 text-justify dark:bg-zinc-800 dark:rounded-lg">
      <div className=" text-sm leading-6 text-zinc-700 space-y-2 dark:text-white">
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list1')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list2')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list3')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list4')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list5')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list6')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list7')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list8')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list9')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list10')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list11')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list12')}</li>
        </ul>
        <hr className=" w-full border-t-1 border-primary"/>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list13')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list14')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list15')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list16')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list17')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list18')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list19')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list20')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list21')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list22')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list23')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list24')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list25')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list26')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list27')}</li>
        </ul>
         <hr className=" w-full border-t-1 border-primary"/>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list28')}</li>
            <ul className="list-disc pl-5 list-inside">
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list29')}</li>
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list30')}</li>
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list31')}</li>
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list32')}</li>
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list33')}</li>
            </ul>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list34')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list35')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list36')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list37')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list38')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list39')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list40')}</li>
        </ul>
         <hr className=" w-full border-t-1 border-primary"/>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list41')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list42')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list43')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list44')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list45')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list46')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list47')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list48')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list49')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list50')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list51')}</li>
        </ul>
        <p>
          {t('residents.forms.personalRightsRcfe.residentProtectionNotice')}:
        </p>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list52')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list53')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list54')}</li>
        </ul>
         <hr className=" w-full border-t-1 border-primary"/>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list55')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list56')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list57')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list58')}</li>
            <ul className="list-disc pl-5">
              <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list59')}</li>
            </ul>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list60')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list61')}</li>
        </ul>
        <p>
        {t('residents.forms.personalRightsRcfe.residentProtectionNotice')}:
        </p>
        <ul className="list-disc pl-5">
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list62')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list63')}</li>
          <li>{t('residents.forms.personalRightsRcfe.elderlyCareRightsList.list64')}</li>
        </ul>
        <p className="uppercase">
         {t('residents.forms.personalRightsRcfe.nondiscriminationNoticeTitle')}
        </p>
        <p className="italic">
          ({t('residents.forms.incidentReport.nameOfFacility')}) <u>{resident?.locations?.[0]?.name || ''}</u>  {t('residents.forms.personalRightsRcfe.antiDiscriminationPolicyNotice')}
        </p>
      </div>
    </div>
  );
};

export default ElderlyCareRightsText;

import LocationService from '@/modules/locations/infrastructure/service/LocationService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface LocationType {
  id: string;
  type: string;
}

interface LocationsState {
  locations: any[];
  location_types: LocationType[];
  rooms_location: any[];
  folders: FoldersI[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
}

interface UpdatedLocationData {
  name: string;
  admin: string;
  phone_lic: string;
  licensing: string;
  phone: string;
  admin_email: string;
  npi?: string;
  max_beds: number;
  category: string;
  address: string;
  city: string;
  state: string;
  status: boolean;
  location_description?: string;
}

interface FoldersI {
  id: string;
  name: string;
  key: string;
  category: string;
  status: 'active' | 'inactive';
  admin_only: 'yes' | 'no';
}

interface RoomI {
  id: string;
  floor: number;
  name: string;
}

const initialState: LocationsState = {
  locations: [],
  location_types: [],
  rooms_location: [],
  folders: [],
  status: 'idle',
  error: null
};

export const getLocations = createAsyncThunk('location/getLocations', async () => {
  const response = await LocationService.getAllLocations();
  const data = response.data.map((location: any) => {
    return location.location;
  });
  return data;
});

export const getLocationTypes = createAsyncThunk('location/location-types', async () => {
  const response = await LocationService.getLocationsType();
  const data = response.data.map((location: LocationType) => {
    return location;
  });
  return data;
});

export const getFolders = createAsyncThunk('location/document-folders', async (locationId: string) => {
  const response = await LocationService.getFolders(locationId);
  const data = response.data.map((folder: FoldersI) => {
    return folder;
  });
  return data;
});

export const getRoomsLocation = createAsyncThunk('location/rooms', async (params: { locationId: string; search: string; per_page: number; page: number }) => {
  const response = await LocationService.getRoomsByLocation(
    params.locationId,
    params.search,
    params.per_page,
    params.page
  );
  const data = response.data
  return data
 
});

export const getUsersByLocation = createAsyncThunk('location/getUsersByLocation', async (locationId: string) => {
  const response = await LocationService.getUsersByLocation(locationId);
  return response.data;
});

export const updateLocation = createAsyncThunk(
  'location/updateLocation',
  async ({ locationId, data }: { locationId: string; data: UpdatedLocationData }) => {
    const response = await LocationService.updateLocation(locationId, data);
    return response.location;
  }
);

export const saveRoom = createAsyncThunk(
  'location/save-room',
  async (data: RoomI) => {
    const response = await LocationService.saveRoom(data);
    return response.room;
  }
);

export const updateRoom = createAsyncThunk(
  'location/update-room',
  async (data: RoomI) => {
    const response = await LocationService.updateRoom(data);
    return response.room;
  }
);

export const deleteRoom = createAsyncThunk(
  'location/delete-room',
  async (id: string) => {
    const response = await LocationService.deleteRoom(id);
    return response;
  }
);

export const saveLocationDocument = createAsyncThunk('location/saveLocationDocument', async (data: any) => {
  try {
    const response = await LocationService.saveLocationDocument(data);
    return response.data;
  } catch (error) {
    throw new Error('Error saving resident document: ' + error);
  }
});

export const updateLocationDocument = createAsyncThunk(
  'locations/updateLocationDocument',
  async ({ documentId, data }: { documentId: string; data: any }) => {
    try {
      const response = await LocationService.updateDocument(documentId, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating resident document: ' + error);
    }
  }
);

export const deleteLocationDocument = createAsyncThunk(
  'locations/deleteLocationDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await LocationService.deleteResidentDocument(documentId);
      return response.data;
    } catch (error) {
      return rejectWithValue('Error deleting resident document: ');
    }
  }
);


const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    addLocation: (state, action) => {
      state.locations.push(action.payload);
    },
    removeLocation: (state, action) => {
      state.locations = state.locations.filter((location) => location.id !== action.payload);
    },
    updateLocationInfo: (state, action) => {
      state.locations = state.locations.map((location) => {
        if (location.id === action.payload.locationId) {
          return {
            ...location,
            [action.payload.key]: action.payload.value
          };
        }
        return location;
      });
    },
    unSetLocations: (state) => {
      state.locations = [];
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.status = 'idle';
      state.locations = action.payload;
      state.error = null;
    });

    builder.addCase(getLocations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(updateLocationDocument.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    builder.addCase(updateLocationDocument.fulfilled, (state) => {
      state.status = 'idle';
      state.error = null;
    })
    builder.addCase(updateLocationDocument.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.status = 'failed';
    });

    builder.addCase(deleteLocationDocument.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    builder.addCase(deleteLocationDocument.fulfilled, (state) => {
      state.status = 'idle';
      state.error = null;
    })
    builder.addCase(deleteLocationDocument.rejected, (state, action) => {
      state.error = (action.payload as string) || action.error.message || null;
      state.status = 'failed';
    });

    builder.addCase(getLocationTypes.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(getLocationTypes.fulfilled, (state, action) => {
      state.status = 'idle';
      state.location_types = action.payload;
      state.error = null;
    });

    builder.addCase(getLocationTypes.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getFolders.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(getFolders.fulfilled, (state, action) => {
      state.status = 'idle';
      state.folders = action.payload;
      state.error = null;
    });

    builder.addCase(getFolders.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getRoomsLocation.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(saveLocationDocument.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    builder.addCase(saveLocationDocument.fulfilled, (state) => {
      state.status = 'idle';
      state.error = null;
    })
    builder.addCase(saveLocationDocument.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.status = 'failed';
    });

    builder.addCase(getRoomsLocation.fulfilled, (state, action) => {
      state.status = 'idle';
      state.rooms_location = action.payload;
      state.error = null;
    });

    builder.addCase(getRoomsLocation.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(updateLocation.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.status = 'idle';
      const updatedLocation = action.payload;
      state.locations = state.locations.map(location =>
        location.id === updatedLocation.id ? updatedLocation : location
      );
      state.error = null;
    });

    builder.addCase(updateLocation.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(saveRoom.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(saveRoom.fulfilled, (state, action) => {
      state.status = 'idle';
      state.rooms_location = action.payload;
      state.error = null;
    });

    builder.addCase(saveRoom.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(updateRoom.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });

    builder.addCase(updateRoom.fulfilled, (state, action) => {
      state.status = 'idle';
      const updatedRoom = action.payload;
      state.rooms_location = updatedRoom;
      state.error = null;
    });

    builder.addCase(updateRoom.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(deleteRoom.fulfilled, (state) => {
      state.status = 'idle';
    });

  }
});

export const { setLocations, addLocation, removeLocation, unSetLocations, updateLocationInfo } = locationsSlice.actions;
export default locationsSlice.reducer;

import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/common/presentation/components/ui/select";
import { Textarea } from "@/common/presentation/components/ui/textarea";
import { toast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import NoteService from "@/modules/notes/infrastructure/services/NoteService";
import { fetchGetNotesCategories } from "@/modules/notes/presentation/slices/NoteSlice";
import TrackerService from "@/modules/tracker/infrastructure/services/TrackerService";
import { RootState } from "@/store/store";
import { t } from "i18next";
import { CalendarIcon } from "lucide-react";
import { RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface TrackerSkinCheckCreateProps {
    dataSkinReport?: (page: number, rowsPerPage: number, reset: boolean) => void;
    canvasRef: RefObject<HTMLCanvasElement>;
    deleteCoordinates: () => void;
    resident?: string;
    callback?: () => void;
};

const TrackerSkinCheckCreate = ({ dataSkinReport, canvasRef, deleteCoordinates, resident, callback }: TrackerSkinCheckCreateProps) => {
    const dispatch = useDispatch();
    const noteCategories = useSelector((state: RootState) => state.notes.categories);
    const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);
    const { residents } = useSelector((state: RootState) => state.residents.allResidents);
    const { locationSelectedId } = useLocation();
    const { params } = useRouteParams();
    const [detail, setDetail] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedTemperature, setSelectedTemperature] = useState('');
    const [selectedMoisture, setSelectedMoisture] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [imageData, setImageData] = useState('');
    const [file, setFile] = useState<File>();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [skin, setSkin] = useState('');
    const formattedDate = selectedDate ? selectedDate.toLocaleString() : '';
    const fileInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        dispatch(fetchGetNotesCategories());
    }, []);

    const onChange = async (file?: File) => {
        if (file) {
            setFile(file.target.files[0]);
        }
    };

    const handleSelectChangeSkin = (value) => {
        setSkin(value);
    };

    const handleChangeDetail = (e) => {
        setDetail(e.target.value);
    };

    const handleDateChangeSkin = (date) => {
        setSelectedDate(date);
    };

    const handleStatusClick = (value) => {
        setSelectedStatus(value);
    };

    const handleMoistureClick = (value) => {
        setSelectedMoisture(value);
    };

    const drawBorderTemperature = (value) => {
        setSelectedTemperature(value);
    };

    const drawBorderColor = (value) => {
        setSelectedColor(value);
    };

    const handleSubmitSkin = async (event) => {
        event.preventDefault();

        if(!resident) {
            if (params.r === 'all-residents' || !params.r) {
                toast(
                    {
                        description: t('trackers.trackerincontinence.selectResident'),
                    }
                );
    
                return;
            }
        }

        if (!detail) {
            toast(
                {
                    description: t('Plase, indicate the detail'),
                }
            );

            return;
        }

        if (!skin) {
            toast(
                {
                    description: t('Please select an option condition.'),
                }
            );

            return;
        }

        if (!selectedStatus) {
            toast(
                {
                    description: t('Please select an option Status.'),
                }
            );

            return;
        }

        const canvas = canvasRef.current;

        if (canvas) {
            const blob = await new Promise<Blob | null>((resolve) => {
                canvas.toBlob((blob) => resolve(blob), 'image/png');
            });

            const isoDate = new Date(selectedDate).toISOString();
            const json = new FormData();

            json.append('resident_id', resident ? resident : params.r);
            json.append('date', isoDate);
            json.append('detail', detail);
            json.append('status', selectedStatus);
            json.append('moisure', selectedMoisture);
            json.append('temperature', selectedTemperature);
            json.append('color', selectedColor);
            json.append('type', skin);
            json.append('image', file);
            json.append('location_id', locationSelectedId);

            if (blob) {
                const fileName = `skincheck_${Date.now()}.png`;
                json.append('url', new File([blob], fileName, { type: 'image/png' }));
            }

            try {
                await TrackerService.saveTrackerSkinCheck(json);

                setDetail('');
                setSelectedStatus('');
                setSelectedMoisture('');
                setSelectedColor('');
                setSelectedTemperature('');
                setSkin('');

                if(fileInputRef.current) fileInputRef.current.value = "";

                deleteCoordinates();

                if(dataSkinReport) dataSkinReport(1, 20, true);

                if(callback) callback();
                
                toast({
                    title: 'SkinCheck',
                    description: 'SkinCheck saved successfully',
                    className: 'bg-green-500 text-white'
                });

            } catch (error) {
                console.error('Error al guardar:', error);
                
                toast({
                    description: 'There was an error saving the SkinCheck',
                    variant: 'destructive'
                });
            }
            try {
                let categoryId = "";
                const residentLocation = residents.find(
                    (resident) => resident ? resident : params.r
                ).location.id;

                if(noteCategories && noteCategories.length) {
                    categoryId = noteCategories.find(
                        (category) => category.category === "Resident Care"
                    ).id;
                }
                
                const skinCheckNote = {
                    html_content: `<p>Skin Check</p><p>Type: ${skin}; ${selectedStatus}</p><p>Details: ${detail}</p><p>${selectedMoisture ? "Moisture: " + (selectedMoisture + ";") : ""} ${selectedTemperature ? "Temperature: " + (selectedTemperature + ";") : ""} ${selectedColor ? "Color: " + selectedColor : ""}</p>`,
                    content: `Skin Check\nType: ${skin}; ${selectedStatus}\nDetails: ${detail}\n${selectedMoisture ? "Moisture: " + (selectedMoisture + ";") : null} ${selectedTemperature ? "Temperature: " + (selectedTemperature + ";") : null} ${selectedColor ? "Color: " + selectedColor : null}`,
                    category_id: categoryId,
                    client_id: clientId,
                    department_id: [],
                    location_id: [residentLocation],
                    reference: "Tracker Skin Check",
                    resident_id: resident ? resident : params.r,
                    staff_id: [],
                    status: "active",
                    urgent: false,
                    user_id: []
                }

                const note = await NoteService.createNote(skinCheckNote);
                const noteId = note.payload.id;

                const formImagesNotes = new FormData();

                if (blob) {
                    const fileName = `skincheck_${Date.now()}.png`;

                    formImagesNotes.append('id', noteId);
                    formImagesNotes.append('type', "note");
                    formImagesNotes.append('images[]', new File([blob], fileName, { type: 'image/png' }));
                    formImagesNotes.append('images[]', file);

                    await NoteService.uploadImges(formImagesNotes);
                }
            } catch(error) {
                console.error('Error al guardar:', error);
                
                toast({
                    description: 'There was an error saving the SkinCheck Note',
                    variant: 'destructive'
                });
            }
        }
    };

    const codeEnOptions = [
        t('trackers.None'), t('trackers.Abrasion'), t('trackers.Bruise'),
        t('trackers.Burn'), t('trackers.Rash'), t('trackers.HX of Pressure Sore'),
        t('trackers.Incision'), t('trackers.Laceration'), t('trackers.Lesion'),
        t('trackers.Mottled'), t('trackers.Pressure Sore'), t('trackers.Reddened Area'),
        t('trackers.Scar'), t('trackers.Skin Tear'), t('trackers.Skin Discoloration'), t('trackers.Statis Ulcer')
    ];

    return (
        <form onSubmit={handleSubmitSkin} encType="multipart/form-data">
            <div className="flex flex-col w-full">
                <div className="flex flex-col w-full p-2">
                    <Label htmlFor="tracker_image" className="control-label mb-3">
                        {t('trackers.Upload Image')}
                    </Label>
                    
                    <Input
                        type="file"
                        id="tracker_image"
                        name="tracker_image"
                        className="w-full"
                        ref={fileInputRef}
                        onChange={onChange}
                        multiple
                    />
                </div>

                <div className="flex flex-col w-full p-2">
                    <Label htmlFor="tracker_brief_date" className="control-label mb-3">
                        {t('trackers.Date/Time')}
                    </Label>

                    <div className="input-group">
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    <span>{ formattedDate }</span>
                                </Button>
                            </PopoverTrigger>
                            
                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                    id="tracker_brief"
                                    name="tracker_brief"
                                    selectedDate={selectedDate}
                                    onChange={handleDateChangeSkin}
                                    MonthAndYearPicker={true}
                                    timePicker={true}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>

                <div className="flex flex-col w-full p-2">
                    <Label htmlFor="tracker_detail" className="control-label mb-3">
                        {t('trackers.Detail')}
                    </Label>
                    
                    <Textarea
                        id="tracker_detail"
                        name="tracker_detail"
                        className="w-full"
                        value={detail}
                        onChange={handleChangeDetail}
                    />
                </div>

                <div className="flex flex-col w-full p-2">
                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                        {t('trackers.Condition')}
                    </Label>
                    
                    <Select value={skin} onValueChange={handleSelectChangeSkin} required>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder="Select an option"></SelectValue>
                        </SelectTrigger>

                        <SelectContent>
                            {
                                codeEnOptions.map(
                                    (option) => (
                                        <SelectItem key={option} value={option}>
                                            {option}
                                        </SelectItem>
                                    )
                                )
                            }
                        </SelectContent>
                    </Select>
                </div>

                <div className="flex flex-col p-2">
                    <Label htmlFor="tracker_condition" className="control-label mb-2">
                        {t('trackers.Status')}
                    </Label>

                    <div className="flex gap-2">
                        <Button
                            type="button"
                            onClick={() => handleStatusClick('Improved')}
                            className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedStatus === 'Improved' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                        >
                            {t('trackers.Improved')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => handleStatusClick('The same')}
                            className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedStatus === 'The same' ? 'text-white bg-gray-700 border-gray-700 hover:bg-gray-700' : 'text-gray-700 border-gray-500 hover:bg-gray-100 bg-white-100 hover:border-gray-700 hover:text-gray-700'}`}
                        >
                            {t('trackers.Same')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => handleStatusClick('Worsen')}
                            className={`flex-1 pt-0 pb-0 p-2 ${selectedStatus === 'Worsen' ? 'text-white bg-red-700 border-red-700 hover:bg-red-700' : 'text-red-700 border-red-500 hover:bg-red-100 bg-white-100 hover:border-red-700 hover:text-red-700'}`}
                        >
                            {t('trackers.Worsen')}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col p-2">
                    <Label htmlFor="tracker_condition" className="control-label mb-2">
                        {t('trackers.Moisture')}
                    </Label>

                    <div className="flex gap-2">
                        <Button
                            type="button"
                            onClick={() => handleMoistureClick('Dry Flaking')}
                            className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedMoisture === 'Dry Flaking' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                        >
                            {t('trackers.Dry Flaking')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => handleMoistureClick('Oily')}
                            className={`flex-1 pt-0 pb-0 mb-2 p-2 ${selectedMoisture === 'Oily' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                        >
                            {t('trackers.Oily')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => handleMoistureClick('Clammy')}
                            className={`flex-1 pt-0 pb-0 p-2 ${selectedMoisture === 'Clammy' ? 'text-white bg-green-700 border-green-700 hover:bg-green-700' : 'text-green-700 border-green-500 hover:bg-green-100 bg-white-100 hover:border-green-700 hover:text-green-700'}`}
                        >
                            {t('trackers.Clammy')}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col gap-4 p-2">
                    <Label htmlFor="tracker_condition" className="control-label mb-2">
                        {t('trackers.Temperature')}
                    </Label>

                    <div className="flex gap-4">
                        <Button
                            type="button"
                            onClick={() => drawBorderTemperature('Cool')}
                            className={`flex-1 pt-0 pb-0 ${selectedTemperature === 'Cool' ? 'text-white bg-blue-700 border-blue-700 hover:bg-blue-700' : 'text-blue-700 border-blue-500 hover:bg-blue-100 bg-white-100 hover:border-blue-700 hover:text-blue-700'}`}
                        >
                            {t('trackers.Cool')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => drawBorderTemperature('Warm')}
                            className={`flex-1 pt-0 pb-0 ${selectedTemperature === 'Warm' ? 'text-white bg-red-700 border-red-700 hover:bg-red-700' : 'text-red-700 border-red-500 hover:bg-red-100 bg-white-100 hover:border-red-700 hover:text-red-700'}`}
                        >
                            {t('trackers.Warm')}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col gap-4 p-2">
                    <Label htmlFor="tracker_condition" className="control-label mb-2">
                        {t('trackers.Color')}
                    </Label>

                    <div className="flex gap-4">
                        <Button
                            type="button"
                            onClick={() => drawBorderColor('Pink')}
                            className={`flex-1 pt-0 pb-0 ${selectedColor === 'Pink' ? 'text-white bg-pink-700 border-pink-700 hover:bg-pink-700' : 'text-pink-700 border-pink-500 hover:bg-pink-100 bg-pink-100 hover:border-pink-700 hover:text-pink-700'}`}
                        >
                            {t('trackers.Pink')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => drawBorderColor('Pale')}
                            className={`flex-1 pt-0 pb-0 ${selectedColor === 'Pale'
                                ? 'text-black bg-[#F7D8CD] border-[#F7D8CD] hover:bg-[#F7D8CD]'
                                : 'text-black border-[#F7D8CD] hover:bg-[#F7D8CD] bg-[#F7D8CD] hover:border-[#F7D8CD] hover:text-[#F7D8CD]'
                                }`}
                        >
                            {t('trackers.Pale')}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => drawBorderColor('Flushed')}
                            className={`flex-1 pt-0 pb-0 ${selectedColor === 'Flushed'
                                ? 'text-white bg-[#b75858] border-[#b75858] hover:bg-[#b75858]'
                                : 'text-white border-[#b75858] hover:bg-[#b75858] bg-[#ef8989e4] hover:border-[#b75858] hover:text-white'
                                }`}
                        >
                            {t('trackers.Flushed')}
                        </Button>
                    </div>
                </div>
            </div>

            <input type="hidden" name="tracker_skincheckurl" value={imageData} />

            <div className="flex flex-column justify-end mt-4 mr-2 mb-2">
                <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                    {t('trackers.Save')}
                </Button>
            </div>
        </form>
    );
};

export default TrackerSkinCheckCreate;

import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { DatePickerWithRange } from '@/common/presentation/components/DatePickerWithPresets/DatePickerWithRange';
import { DateRange } from 'react-day-picker';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/common/presentation/components/ui/dialog';
import { DialogDescription } from '@/components/ui/dialog';
import { fetchGetStaffNotes, getExportExcel } from '../../slices/NoteSlice';
import { FileDown, Loader, Plus } from 'lucide-react';
import { format } from 'date-fns';
import { InputsNoteFilters } from './inputsNoteFilters';
import { Label } from '@/common/presentation/components/ui/label';
import { RootState } from '@/store/store';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';

interface FloatButtonProps {
  setCrudState: (crudState: CrudState) => void;
  selectedValue: string;
  crudState: CrudState;
}

type ExportFormat = 'excel' | 'pdf';

export function FloatButton({ setCrudState, selectedValue, crudState }: FloatButtonProps) {
  // Init values
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasAnyRole } = useRoleHelper();
  const { locationSelectedId } = useLocation();
  const noteFilters = useSelector((state: RootState) => state.notes.noteFilters);

  const [openDialogExport, setOpenDialogExport] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>('excel');
  const [dataFiltersExport, setDataFiltersExport] = useState<[string, string][]>([]);
  const [dateRangeSelected, setDateRangeSelected] = useState<DateRange | null>({ from: new Date(), to: new Date() });

  useEffect(() => {
    let dateExport: DateRange | null = { from: new Date(), to: new Date() };
    const nuevaFecha = new Date();
    switch (selectedFilterNoteDefault('betweenTime')) {
      case 'last15days':
        nuevaFecha.setDate(new Date().getDate() - 15);
        dateExport.from = nuevaFecha;
        break;
      case 'last3Months':
        nuevaFecha.setMonth(new Date().getMonth() - 3);
        dateExport.from = nuevaFecha;
        break;
      case 'last6Months':
        nuevaFecha.setMonth(new Date().getMonth() - 6);
        dateExport.from = nuevaFecha;
        break;
      case 'lastYear':
        nuevaFecha.setFullYear(new Date().getFullYear() - 1);
        dateExport.from = nuevaFecha;
        break;
      default:
        dateExport = null;
        break;
    }
    setDateRangeSelected(dateExport);

    let dataFilterCopy = [...dataFiltersExport];
    dataFilterCopy = updateNoteFilters('resident', selectedFilterNoteDefault('resident'), dataFilterCopy)
    dataFilterCopy = updateNoteFilters('staff', selectedFilterNoteDefault('staff'), dataFilterCopy)
    dataFilterCopy = updateNoteFilters('category', selectedFilterNoteDefault('category'), dataFilterCopy)
    setDataFiltersExport(dataFilterCopy);
  }, [noteFilters])

  const selectedFilterNoteDefault = (type: string) => {
    let findStaff = noteFilters.find(filter => filter[0] == type);
    if (findStaff) {
      return findStaff[1]
    }
    return type == 'betweenTime' ? 'allTime' : 'all';
  }

  const exportNotes = async () => {
    setDownloadFile(true);
    let dataFilterCopy = [...dataFiltersExport];
    dataFilterCopy.push(['location', locationSelectedId]);
    dataFilterCopy.push(['formatTypeExport', exportFormat]);
    if (dateRangeSelected) {
      dataFilterCopy.push(['minDateExport', format(dateRangeSelected.from || new Date(), 'dd-MM-yyyy')]);
      dataFilterCopy.push(['maxDateExport', format(dateRangeSelected.to || new Date(), 'dd-MM-yyyy')]);
    }
    if (crudState.showingStaffNotes) {
      dataFilterCopy.push(['target_type', 'staff']);
    }
    let response = await dispatch<any>(getExportExcel(dataFilterCopy));

    const urlExport = window.URL.createObjectURL(response.payload);

    const link = document.createElement('a');
    link.href = urlExport;
    link.download = `Notes_${new Date().getDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(urlExport);
    setDownloadFile(false);
  }

  const setNoteFilters = (key: string, value: string, dataFilterCopy: [string, string][]) => {
    const existingFilter = dataFilterCopy.findIndex(filter => filter[0] === key);
    if (existingFilter >= 0) {
      dataFilterCopy[existingFilter][1] = value;
    } else {
      dataFilterCopy.push([key, value]);
    }
    return dataFilterCopy;
  }

  const removeNoteFilter = (key: string, dataFilterCopy: [string, string][]) => {
    return dataFilterCopy.filter(filter => filter[0] !== key);
  }

  const updateNoteFilters = (key: string, value: string, dataFilterCopy: [string, string][]) => {
    if (value == '' || value == 'all') {
      return removeNoteFilter(key, dataFilterCopy);
    } else {
      return setNoteFilters(key, value, dataFilterCopy);
    }
  };

  const selectedFilterExport = (type: string) => {
    let findStaff = dataFiltersExport.find(filter => filter[0] == type);
    if (findStaff) {
      return findStaff[1]
    }
    return type == 'betweenTime' ? 'allTime' : 'all';
  }

  return (
    <>
      {hasAnyRole(['Super Administrator', 'Executive', 'House Manager']) && (
        <Dialog
          open={openDialogExport}
          onOpenChange={() => setOpenDialogExport(!openDialogExport)}
        >
          <DialogTrigger>
            <div className='mr-3 bg-primary text-white flex py-1.5 px-3 rounded'>
              <FileDown className='mr-2' />
              <span className='hidden md:flex'>{t('notes.export')}</span>
            </div>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>{t('notes.exportTitle')}</DialogTitle>
            </DialogHeader>
            <DialogDescription className='font-bold text-xs'>
              {t('notes.exportDescription')}
            </DialogDescription>
            <div className="grid grid-cols-3 items-center gap-3">
              <Label htmlFor="resident">{t('notes.exportFormat')}</Label>
              <Select disabled value={exportFormat} onValueChange={(format: ExportFormat) => setExportFormat(format)}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="resident">
                  <SelectItem value="excel">Excel</SelectItem>
                  <SelectItem disabled value="pdf">Pdf</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <InputsNoteFilters
              crudState={crudState}
              handleCategoryFilter={(categoryId: string) => {
                let data = updateNoteFilters('category', categoryId != 'all' ? categoryId : '', [...dataFiltersExport]);
                setDataFiltersExport(data);
              }}
              handleResidentFilter={(residentId: string) => {
                let data = updateNoteFilters('resident', residentId != 'all' ? residentId : '', [...dataFiltersExport]);
                setDataFiltersExport(data);
              }}
              handleStaffFilter={(staffId: string) => {
                let data = updateNoteFilters('staff', staffId != 'all' ? staffId : '', [...dataFiltersExport]);
                setDataFiltersExport(data);
              }}
              selectedFilter={selectedFilterExport}
            />
            <DatePickerWithRange
              onUpdate={(selectedRange: DateRange | undefined) => {
                if (selectedRange) {
                  setDateRangeSelected(selectedRange)
                }
              }}
              selected={dateRangeSelected}
              maxDaysSelectable={90}
              untilCurrentDate={true}
            />
            <DialogFooter>
              {downloadFile ? (
                <Loader className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Button type="button" onClick={exportNotes}>
                  <FileDown className='mr-2' />
                  <span className='hidden md:flex'>{t('notes.export')}</span>
                </Button>
              )}
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}

      {selectedValue == t('notes.notes') && (
        <Button type="button" onClick={() => setCrudState(NoteHelperService.defineState({ isCreating: true }))}>
          <Plus className='mr-2' />
          <span className='hidden md:flex'>{t('notes.newNote')}</span>
        </Button>
      )}
      {selectedValue == t('notes.staffBetweenNotes') && (
        <Button type="button" onClick={() => setCrudState(NoteHelperService.defineState({ isCreatingBeetweenStaffNotes: true, showingBeetweenStaffNotes: true }))}>
          <Plus className='mr-2' />
          <span className='hidden md:flex'>{t('notes.newNote')}</span>
        </Button>
      )}
      {
        hasAnyRole(['Super Administrator', 'Executive', 'House Manager']) && selectedValue == t('notes.staffNotes') && (
          <Button type="button" onClick={() => {
            setCrudState(NoteHelperService.defineState({ showingStaffNotes: true, isCreatingStaffNote: true }));
            dispatch<any>(fetchGetStaffNotes());
          }}>
            <Plus className='mr-2' />
            <span className='hidden md:flex'>{t('notes.newStaffNote')}</span>
          </Button>
        )
      }
    </>
  );
}

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import {
  DefaultValues,
  DiagnosisCard
} from '@/modules/residents/presentation/components/MedicalInformationForm/components/DiagnosisCard';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EmptyIcon } from '../../EmptyIcon';
import { useTranslation } from 'react-i18next';
import { getResidentById } from '../../../slices/residentSlice';

export const DiagnosisForm = () => {
  const { resident }: { resident: Resident | null; status: string } = useSelector(
    (state: RootState) => state.residents.resident
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const initialDiagnosis = useMemo(
    () =>
      resident?.medical_information?.diagnosis
        ?.map((d) => ({
          id: d.id,
          description: d.description,
          codes: d.codes,
          created_at: d.created_at,
          type: d.type
        }))
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) || [],
    [resident]
  );

  const [diagnosis, setDiagnosis] = useState<DefaultValues[]>(initialDiagnosis);

  const {
    uppsertDiagnosisInformation,
    deleteDiagnosisInformation,
    status: medicalInformationFormStatus
  } = useMedicalInformationStore();

  const addDiagnosis = useCallback(() => {
    setDiagnosis((prev) => [{ diagnosis: '', description: '', codes: [], type: '' }, ...prev]);
  }, []);

  const createOrUpdateDiagnosis = useCallback(
    async (values: any) => {
      const payload = { resident_id: resident?.id, ...values };

      try {
        const response = await uppsertDiagnosisInformation(payload);

        if (response?.diagnosis) {
          setDiagnosis(
            response.diagnosis.map((d: any) => ({
              id: d.id,
              description: d.description,
              codes: d.codes,
              type: d.type,
              created_at: new Date(d.created_at)
            }))
          );

          if(resident?.id) {
            await dispatch(getResidentById(resident?.id));
          }

          toast({ description: t('residents.medicalInformation.diagnosis.diagnosisCreated') });
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        toast({ description: 'Failed to create/update diagnosis', variant: 'destructive' });
      }
    },
    [uppsertDiagnosisInformation, resident?.id]
  );

  const removeDiagnosis = useCallback(
    async (id: string | undefined) => {
      try {
        if (id) {
          const payload = { id };

          const response = await deleteDiagnosisInformation(payload);
          setDiagnosis((prev) => prev.filter((d) => d.id !== id));

          if (response) {
            toast({ description: t('residents.medicalInformation.diagnosis.diagnosisRemoved') });
          }
        } else {
          setDiagnosis((prev) => prev.filter((d) => d.id !== undefined));
        }
      } catch (error) {
        toast({ description: 'Failed to remove diagnosis', variant: 'destructive' });
      }
    },
    [deleteDiagnosisInformation, resident?.id]
  );

  return (
    <>
      <div>
        <CustomAccordion
          titleComponent={<h1 className="text-xl font-bold">{t('residents.medicalInformation.diagnosis.diagnosis')}</h1>}
          openOption={true}
          defaultOpen={true}
        >
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex items-center justify-between">
              <p className="text-sm text-gray-600">
                {t('residents.medicalInformation.diagnosis.aboutDiagnosis')}
              </p>

              <Button
                className="flex gap-2"
                variant={'default'}
                onClick={addDiagnosis}
                disabled={diagnosis?.some((diagnosis) => !diagnosis.id)}
              >
                <CircleFadingPlus className="h-4 w-4 flex-1" />
                {t('residents.medicalInformation.diagnosis.newDiagnosis')}
              </Button>
            </div>

            <Separator />

            {diagnosis.length === 0 && (
              <div className="w-full flex flex-col items-center py-12 gap-4">
                <EmptyIcon className="h-auto w-[8vw]" />
                <p>{t('residents.medicalInformation.diagnosis.noDiagnosis')}</p>
              </div>
            )}

            {diagnosis.map((d, index) => {

              return (
                <DiagnosisCard
                  defaultValues={d}
                  onSubmit={createOrUpdateDiagnosis}
                  onRemove={removeDiagnosis}
                  disabled={medicalInformationFormStatus === 'loading'}
                  key={d.id || index}
                />
              );
            })}

            <Separator />

            <p>
            {t('residents.medicalInformation.diagnosis.totalDiagnosis')}: <span className="font-bold">{diagnosis.length}</span>
            </p>
          </div>
        </CustomAccordion>
      </div>
    </>
  );
};

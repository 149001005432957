import useRouteParams from "@/common/hooks/RouteParamsHook";
import { RootState } from "@/store/store";
import { CircleAlert, Smile } from "lucide-react";
import { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { publicSaveVisitors, validateToken } from "../../slices/VisitorsSlice";
import TrackerVisitorsQuestions from "./TrackerVisitorsQuestions";
import TrackerVisitorsResult from "./TrackerVisitorsResult";
import TrackerVisitorsResidents from "./TrackerVisitorsResidents";
import TrackerVisitorsAlias from "./TrackerVisitorsAlias";
import TrackerVisitorsResidentInterface from "@/modules/tracker/infrastructure/interfaces/Visitors/TrackerVisitorsResidentInterface";

const TrackerVisitorsForm = () => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const visitorsStore = useSelector((state: RootState) => state.visitors);
    const QuestionsStore = useSelector((state: RootState) => state.settings.questions);
    const [progress, setProgress] = useState<number>(10);
    const [totalPerQuestion, setTotalPerQuestion] = useState<number>(0);
    const [mode, setMode] = useState<string>("alias");
    const [validatedToken, setValidatedToken] = useState<boolean | null>(true);
    const [visitor, setVisitor] = useState<string>("");
    const [temperature, setTemperature] = useState<string>("");
    const [approved, setApproved] = useState<boolean | null>(null);
    const [resident, setResident] = useState<TrackerVisitorsResidentInterface | null>(null);
    const [locationImage, setlocationImage] = useState<string>("");
    const [locationId, setLocationId] = useState<string>("");
    
    useEffect(() => {
        dispatch(
            validateToken(
                {
                    token: decodeURIComponent(params.token)
                }
            )
        )
    }, []);
    
    useEffect(() => {
        setlocationImage(visitorsStore.validatedToken?.image);
    }, [visitorsStore.validatedToken]);

    useEffect(() => {
        if(visitor && temperature && resident) {
            dispatch(
                publicSaveVisitors(
                        {
                            location_id: locationId,
                            resident_id: params.r === "all-residents" ? null : params.r,
                            date: new Date().toISOString(),
                            regardingTo: resident.first_name + " " + resident.last_name,
                            visitor: visitor,
                            temperature: temperature,
                            approved: approved,
                            token: decodeURIComponent(params.token)
                        }
                )
            );
        }
    }, [approved]);

    useEffect(() => {
        setValidatedToken(visitorsStore.validatedToken?.validate);
        setLocationId(visitorsStore.validatedToken?.location_id);
    }, [visitorsStore.validatedToken]);

    useEffect(() => {
        if(QuestionsStore.questions && QuestionsStore.questions.length) {
            const questionsQuantity = QuestionsStore.questions.length;
            setTotalPerQuestion(Math.round(100 / (2 + questionsQuantity)));
        }
    }, [QuestionsStore]);

    const updateVisitor = (newValue: SetStateAction<string>) => {
        setVisitor(newValue);
    }

    const updateResident = (newValue: SetStateAction<TrackerVisitorsResidentInterface | null>) => {
        setResident(newValue);
    }

    const updateTemperature = (newValue: SetStateAction<string>) => {
        setTemperature(newValue);
    }

    const updateApproved = (newValue: SetStateAction<boolean | null>) => {
        setApproved(newValue);
    }

    const updateProgress = (newValue: SetStateAction<number>) => {
        setProgress(newValue);
    }

    const updateMode = (newValue: string) => {
        setMode(newValue);
    }
    
    return (
        <div className="grid grid-cols-1 w-full h-full relative"
            style={
                {
                    gridTemplateRows: "auto 1fr"
                }
            }
        >
            <div className="flex flex-col justify-center items-center p-5">
                {
                    locationImage ? (
                        <img className="h-[60px]" src={locationImage} alt="sadas" />
                    ): null
                }
            </div>

            {
                validatedToken ? (
                    <>
                        {
                            mode === "alias" ? (
                                <TrackerVisitorsAlias 
                                    progress={progress} 
                                    setProgress={updateProgress} 
                                    setMode={updateMode} 
                                    setVisitor={updateVisitor}
                                    totalPerQuestion={totalPerQuestion} 
                                />
                            ): null
                        }

                        {
                            mode === "resident" ? (
                                <TrackerVisitorsResidents
                                    progress={progress} 
                                    setProgress={updateProgress} 
                                    setMode={updateMode} 
                                    setResident={updateResident}
                                    totalPerQuestion={totalPerQuestion} 
                                 />
                            ): null
                        }

                        {
                            mode === "questions" ? (
                                <TrackerVisitorsQuestions 
                                    progress={progress} 
                                    setProgress={updateProgress} 
                                    setMode={updateMode} 
                                    setApproved={updateApproved}
                                    setTemperature={updateTemperature}
                                    totalPerQuestion={totalPerQuestion} 
                                />
                            ): null
                        }

                        {
                            mode === "result" ? (
                                approved ? (
                                    <TrackerVisitorsResult 
                                        title="Approved" 
                                        description="Results submitted" 
                                        body="Please show this screen to the staff."
                                        backgroundColor="bg-[#16A34ACC]/[1]"
                                    >
                                        <Smile size={100} className="text-white w-full mb-5"></Smile>
                                    </TrackerVisitorsResult>
                                ) : (
                                    <TrackerVisitorsResult 
                                        title="Wait" 
                                        description="We’ve detected a potential issue with your access requirements" 
                                        body=" To proceed, please contact an administrator for further assistance."
                                        backgroundColor="bg-[#DC2626]/[1]"
                                    >
                                        <Smile size={100} className="text-white w-full mb-5"></Smile>
                                    </TrackerVisitorsResult>
                                )
                            ): null
                        }
                    </>
                ) : (
                    <TrackerVisitorsResult 
                        title="Expired QR" 
                        description="Please scan the new code" 
                        body="or contact an administrator for further assistance."
                        backgroundColor="bg-[#EAB308]/[1]"
                    >
                        <CircleAlert size={100} className="text-white w-full mb-5" />
                    </TrackerVisitorsResult>
                )
            }
        </div>
    );
};

export default TrackerVisitorsForm;

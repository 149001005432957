import { Label } from '@/common/presentation/components/ui/label';
import { IFacilitiesInfo } from '@/modules/subscription/domain/makerPlace.domain';
import { localization } from '@/utils/helpers/localization.helper';
import { FC, useMemo } from 'react';

interface IProps {
  price: number;
  currency: string;
  currentFacilities: number;
  facilities: IFacilitiesInfo[];
}

const Total: FC<IProps> = ({ price, currency, currentFacilities, facilities }) => {
  const newFacilities = facilities.filter((facility) => facility.id.length === 0);
  const extraBeds = useMemo(() => {
    return newFacilities.reduce((sum, facility) => sum + (facility.beds_number > 10 && facility.id.length == 0 ? facility.beds_number - 10 : 0), 0);
  }, [newFacilities]);
  return (
    <div className="flex flex-col gap-1">
      <Label className="text-primary text-sm">Total</Label>
      <div className="flex flex-col">
        <Label className="text-sm">
          {currentFacilities} Current Facilities
          {newFacilities.length > 0 && ` + ${newFacilities.length} New Facilities`}
          {
            newFacilities.length > 0 && extraBeds > 0 && (
                ` + ${extraBeds} Extra Beds`
            )
          }
        </Label>
        <div>
          <Label className="font-bold text-xl">{localization.formatPrice(price, currency)}</Label> /Monthly
        </div>
      </div>
    </div>
  );
};

export default Total;

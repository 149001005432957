import { useTranslation } from 'react-i18next';
import { SquareActivity } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { AllergyI, DiagnosisI } from '@/common/domain/Residents';
import { MentalConditionI } from '@/modules/residents/domain/models/QuickMenu.domain';
import { InfoCard } from '../../../../../../common/presentation/components/Card/InfoCard';
import InfoRow from './InfoRow';

interface DiagnosisCardProps {
  className?: string;
  diagnoses: DiagnosisI[];
  allergy?: AllergyI;
  mentalCondition?: MentalConditionI;
  residentId?: string;
}

export const DiagnosisCard = ({ className, diagnoses, allergy, mentalCondition }: DiagnosisCardProps) => {
  const { t } = useTranslation();
  const hasDiagnoses = diagnoses && diagnoses.length === 0;

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('general.diagnosis')}
        headerIconLeft={SquareActivity}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[166px]">
          <div className="space-y-2">
            {(hasDiagnoses && !allergy && !mentalCondition)  && (
              <div className="flex items-center gap-6 rounded-md bg-zinc-100 dark:bg-zinc-900 h-8 px-5 py-2">
                <p className="text-xs text-zinc-600 dark:text-zinc-50 font-medium">
                  {t('general.no_data_available')}
                </p>
              </div>
            )}
            {diagnoses?.map((diagnosis) => (
                <InfoRow
                  key={ diagnosis?.id}
                  label={
                    diagnosis?.type === 'primary'
                      ? t('residents.profile.diagnosis.primaryDiagnosis')
                      : t('residents.profile.diagnosis.secondaryDiagnosis')
                  }
                  value={diagnosis?.codes?.map((code) => code?.name).join(', ') ||  diagnosis?.description }
                />
            ))}
            {allergy && (
              <InfoRow
                label={t('residents.profile.diagnosis.allergies')}
                value={allergy?.allergy}
              />
            )}
            {mentalCondition?.dementiaType && (
              <InfoRow
                label={t('residents.profile.diagnosis.mentalStatus')}
                value={t(`residents.profile.medicationInformation.${mentalCondition?.dementiaType}`) || t('general.no_data_available')}
              />
            )}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { CalendarIcon, X } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { es } from 'date-fns/locale';
import moment from 'moment-timezone';
import dateFormats from '@/utils/dateFormats';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { cn } from '@/lib/utils';

interface IProps {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  isDisabled?: boolean;
  includePastYears?: boolean;
  yearRange?: number;
}

const CalendarComponent: React.FC<IProps> = ({ value, onChange, isDisabled = false, includePastYears = true }) => {
  const { formatDate } = useDateHelpers();
  const [date, setDate] = useState<Date>(value || new Date());
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t, i18n } = useTranslation();
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const commonInputClasses =
    'focus:border-2 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none focus-visible:ring-0 focus-visible:ring-primary focus-visible:ring-offset-0';

  const currentYear = new Date().getFullYear();

  const years = React.useMemo(() => {
    const startYear = 1900;
    const endYear = currentYear + 100;
    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
  }, [currentYear]);

  const locale = i18n.language === 'es' ? es : undefined;

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];

  useEffect(() => {
    if (value && !isTyping) {
      setDate(value);
      setInputValue(formatDate(value.toString(), false, false, false));
    }
  }, [value, isTyping]);

  useEffect(() => {
    if (cursorPosition !== null && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      setCursorPosition(null);
    }
  }, [cursorPosition, inputValue]);

  const formatters = React.useMemo(
    () => ({
      formatMonthCaption: (date: Date) => t(`calendar.month_${date.getMonth() + 1}`)
    }),
    [t]
  );

  const formatInputValue = (input: string): string => {
    const numbersOnly = input.replace(/\D/g, '');

    if (numbersOnly.length <= 2) {
      return numbersOnly;
    } else if (numbersOnly.length <= 4) {
      return `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(2)}`;
    } else {
      return `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(2, 4)}/${numbersOnly.slice(4, 8)}`;
    }
  };

  const handleInputFocus = () => {
    setIsTyping(true);
    setInputValue('');
  };

  const handleMonthChange = (monthValue: string) => {
    const newDate = new Date(date);
    const newMonth = parseInt(monthValue);
    const currentMonth = date.getMonth();

    if (currentMonth === 0 && newMonth === 11) {
      newDate.setFullYear(date.getFullYear() - 1);
    } else if (currentMonth === 11 && newMonth === 0) {
      newDate.setFullYear(date.getFullYear() + 1);
    }

    newDate.setMonth(newMonth);
    setDate(newDate);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    if (input.length < inputValue.length) {
      setInputValue(input);
      return;
    }

    const formattedValue = formatInputValue(input);
    setInputValue(formattedValue);

    const numbersOnly = input.replace(/\D/g, '');
    if (numbersOnly.length === 8) {
      const day = numbersOnly.slice(0, 2);
      const month = numbersOnly.slice(2, 4);
      const year = numbersOnly.slice(4, 8);

      const dateString = `${day}/${month}/${year}`;
      const currentFormat = dateFormats[i18n.language]?.dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY');

      const parsedDate = moment(dateString, currentFormat, true);

      if (parsedDate.isValid()) {
        setDate(parsedDate.toDate());
        onChange(parsedDate.toDate());
      }
    }
  };

  const handleBlur = () => {
    setIsTyping(false);
    if (!inputValue || inputValue.length < 10) {
      if (value) {
        setInputValue(formatDate(value.toString(), false, false, false));
      } else {
        setInputValue('');
      }
    } else {
      const currentFormat = dateFormats[i18n.language]?.dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY');

      const parsedDate = moment(inputValue, currentFormat, true);

      if (parsedDate.isValid()) {
        const formatted = formatDate(parsedDate.toDate().toString(), false, false, false);
        setInputValue(formatted);
      } else {
        if (value) {
          setInputValue(formatDate(value.toString(), false, false, false));
        } else {
          setInputValue('');
        }
      }
    }
  };

  const handleClear = () => {
    setInputValue('');
    onChange(undefined);
    setDate(new Date());
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <div className="flex w-full gap-2">
        <div className="relative w-full">
          <Input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleBlur}
            placeholder={dateFormats[i18n.language]?.dateFormat?.toLowerCase()}
            className={cn('w-full', commonInputClasses)}
            disabled={isDisabled}
          />
          {inputValue && (
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 p-0"
              onClick={handleClear}
              type="button"
              disabled={isDisabled}
            >
              <X className="h-3 w-3" />
            </Button>
          )}
        </div>
        <PopoverTrigger asChild disabled={isDisabled}>
          <Button variant="outline" size="icon" type="button" onClick={() => setIsOpen(true)} disabled={isDisabled}>
            <CalendarIcon className="h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent align="start" className="w-full max-w-[95vw] p-2 sm:w-auto z-[99999]">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row gap-2">
            <Select
              value={date.getFullYear().toString()}
              onValueChange={(value) => {
                const newDate = new Date(date);
                newDate.setFullYear(parseInt(value));
                setDate(newDate);
              }}
            >
              <SelectTrigger className={commonInputClasses}>
                <SelectValue placeholder="Year" />
              </SelectTrigger>
              <SelectContent position="popper" className="max-h-[200px] z-[999999]">
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select value={date.getMonth().toString()} onValueChange={handleMonthChange}>
              <SelectTrigger className={commonInputClasses}>
                <SelectValue placeholder="Month" />
              </SelectTrigger>
              <SelectContent position="popper" className="max-h-[200px] z-[999999]">
                {months.map((month, index) => (
                  <SelectItem key={month} value={index.toString()}>
                    {t(`calendar.customCalendar.${month}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="rounded-md border">
            <Calendar
              mode="single"
              selected={value}
              onSelect={(date) => {
                onChange(date);
                if (date) {
                  setInputValue(formatDate(date.toString(), false, false, false));
                }
                setIsOpen(false);
              }}
              month={date}
              locale={locale}
              formatters={formatters}
              onMonthChange={setDate}
              initialFocus
              disabled={(date) => !includePastYears && date < new Date(new Date().setDate(new Date().getDate() - 1))}
              className="w-full"
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CalendarComponent;

const FormHeader = ({ leftContent, rightContent }: { leftContent: React.ReactNode; rightContent: React.ReactNode }) => (
  <div className="flex flex-col md:flex-row md:justify-between text-xs text-gray-600 mb-4 dark:text-zinc-300">
    <div className="w-full md:w-auto text-left mb-2 md:mb-0">
      {leftContent}
    </div>
    <div className="w-full md:w-auto text-right">
      {rightContent}
    </div>
  </div>
);

export default FormHeader;
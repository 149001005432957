import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { CardHeader, CardContent} from '@/components/ui/card';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import ConsentForm from './components/ConsentForm';
import { Separator } from '@/components/ui/separator';
import EmergencyInfoForm from './components/EmergencyInfoForm';
import { CustomCard } from '../shared/CustomCard';
import FormHeader from '../shared/FormHeader';
import FormActionButtons from '../shared/FormActionButtons';
import { emergencyMedicalConsentDefaults } from '../formDefaults/emergencyMedicalConsentDefaults';
import { useFetchAllContacts } from '@/modules/residents/infrastructure/hooks/forms/useFetchAllContacts';
import { getContactsByResident } from '@/modules/contacts/presentation/slices/contactsSlice';

export const EmergencyMedicalConsentForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const { contacts: allContacts, isLoading } = useFetchAllContacts(params.r, getContactsByResident);

  const methods = useForm({
    defaultValues: emergencyMedicalConsentDefaults(resident, allContacts)
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(emergencyMedicalConsentDefaults(resident, allContacts));
    }
  }, [resident, allContacts, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic627c_consent_emergency_medical_treatment',
  });

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedData = {
      ...rawData,
      date: rawData.date ? format(parseISO(rawData.date), 'MM/dd/yyyy') : '',      
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident || isLoading) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
          <BackNavigationBar
            title={title}
            path={`/residents/forms?r=${params.r}`}
          />
          <FormProvider {...methods}>
            <form>
              <CustomCard>
                <CardHeader className='p-0 '>
                  <FormHeader
                    leftContent={
                      <>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.stateOfCalifornia")}
                        </p>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
                        </p>
                      </>
                    }
                    rightContent={
                      <>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.californiaDeptSocialServices")}
                        </p>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.communityCareLicensingDivision")}
                        </p>
                      </>
                    }
                  />
                </CardHeader>
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <h2 className="text-xl font-bold text-center mb-4">
                      {t('residents.forms.emergencyMedicalConsentForm.title')}
                    </h2>
                    <ConsentForm />
                    <Separator className='my-4 bg-primary'/>
                    <EmergencyInfoForm />
                    <FormActionButtons
                      handleFormSubmit={handleFormSubmit}
                      formsStatus={formsStatus}
                    />
                  </div>
                </CardContent>
              </CustomCard>
            </form>
          </FormProvider>
      </div>
    </div>
  );
};

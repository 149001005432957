import { IFilters } from '../training.domain';

export const trainingFilters: IFilters[] = [
  {
    label: 'all',
    key: 'all'
  },
  {
    label: 'not_started',
    key: 'not_started'
  },
  {
    label: 'in_progress',
    key: 'in_progress'
  },
  {
    label: 'completed',
    key: 'completed'
  }
];

export const MAX_FILE_SIZE = 2000000;

export const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/webp': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'text/plain': []
};

export const COMMON_INPUT_CLASSES =
    'focus:border-2 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none focus-visible:ring-0 focus-visible:ring-primary focus-visible:ring-offset-0';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Questions from "../infrastructure/services/Questions";
import { IQuestion } from '../domain/questions.domain';

interface IPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  hasMorePages: boolean
}

interface IQuestionsData {
  items: IQuestion[];
  pagination: IPagination;
}

export interface IQuestionsState {
  questions: IQuestionsData;
  isLoading: boolean;
  error: string | null;
  message: string;
}

const initialState: IQuestionsState = {
  questions: {
    items: [],
    pagination: {
      total: 0,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      hasMorePages: false
    }
  },
  message: '',
  isLoading: false,
  error: null
};


export const getQuestions = createAsyncThunk('questions/getQuestions',
 async (formData: any) => {
    const response = await Questions.getQuestions(formData);
    return response;
  }
);

export const getPublicQuestions = createAsyncThunk('publicQuestions/getQuestions',
  async (formData: any) => {
     const response = await Questions.getPublicQuestions(formData);
     return response;
   }
 );

export const saveOrUpdateQuestion = createAsyncThunk('questions/saveOrUpdateQuestion',
  async (formData: any ) => {
     const response = await Questions.saveOrUpdateQuestion(formData);
     return response;
   }
 );

export const deleteQuestion = createAsyncThunk('questions/deleteQuestion',
  async (id: string ) => {
     const response = await Questions.deleteQuestion(id);
     return response;
   }
 );

 export const updateOrderData = createAsyncThunk('questions/updateOrderData',
  async (orderedData: IQuestion[] ) => {
     const response = await Questions.updateOrderData(orderedData);
     return response;
   }
 );

const QuestionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    questionsLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    },
    updateQuestions: (state, action) => {
      state.questions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
      state.isLoading = false;
    })
    .addCase(updateOrderData.fulfilled, (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    })
    .addCase(deleteQuestion.fulfilled, (state) => {
      state.isLoading = false;
    }),
    builder.
    addCase(getPublicQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
      state.isLoading = false;
    }),
    builder.addCase(saveOrUpdateQuestion.fulfilled,(state)=>{
      state.isLoading = false;
    })
  }
});

export const { questionsLoading, updateQuestions } = QuestionsSlice.actions;
export default QuestionsSlice.reducer;
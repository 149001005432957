import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { AppDispatch } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleFadingPlus, File as FileIconLucide } from 'lucide-react';
import { certficatesCategory } from '@/modules/staff/domain/Training.ts';
import { useUploadCertificate } from '@/modules/staff/infrastructure/hooks/training/useUploadCertificate.ts';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso.tsx';
import { getCertificatesTrainingOnSite } from '@/modules/staff/presentation/slices/staffMemberFormSlice.ts';
import { Skeleton } from '@/common/presentation/components/ui/skeleton.tsx';
import { cn } from '@/lib/utils.ts';
import { useTranslation } from 'react-i18next';

interface CategoryCardProps {
  category: certficatesCategory;
  type: string;
  user_id: string;
}

export const CategoryCard = ({ category, type, user_id }: CategoryCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [certificatesData, setCertificatesData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { onOpen: onUploadDocumentOpen, onUpdate, setOnUpdate } = useUploadCertificate();

  const columnsByType: Record<
    string,
    Array<{
      key: string;
      id: string;
      labelTranslationKey: string;
      value?: string;
      visible?: boolean;
      static?: boolean;
      sortable?: boolean;
      render?: (row: any) => JSX.Element;
    }>
  > = {
    CEUs: [
      {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'Date',
        value: 'date',
        visible: true,
        static: true,

      },
      {
        key: 'title',
        id: 'title',
        labelTranslationKey: 'Title',
        value: 'title',
        visible: true,
        static: true,

      },
      {
        key: 'vendor',
        id: 'vendor',
        labelTranslationKey: 'Vendor',
        value: 'vendor',
        visible: true,
        static: true,

      },
      {
        key: 'actions',
        id: 'actions',
        labelTranslationKey: 'Actions',
        static: true,
        render: (row: any) => renderActionsColumn(row)
      }
    ],
    default: [
      {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'Date',
        value: 'date',
        visible: true,
        static: true,
      },
      {
        key: 'description',
        id: 'description',
        labelTranslationKey: 'Description',
        value: 'description',
        visible: true,
        static: true,
      },
      {
        key: 'hours',
        id: 'hours',
        labelTranslationKey: 'Hours',
        value: 'hours',
        visible: true,
        static: true,
      },
      {
        key: 'actions',
        id: 'actions',
        labelTranslationKey: 'Actions',
        static: true,
        render: (row: any) => renderActionsColumn(row)
      }
    ]
  };

  const columnsCertificates = useMemo(() => columnsByType[type] || columnsByType.default, [type]);

  useEffect(() => {
    getCertificates();
  }, [type, category]);

  const getCertificates = async () => {
    const category_id = category.id;
    const response = await dispatch(getCertificatesTrainingOnSite({ type, category_id, user_id }));
    setCertificatesData(response.payload);
    setLoading(false);
  };

  const handleViewDocument = (file:any) => {
    window.open(file, '_blank');
  };


  const renderActionsColumn = (row: any) => {
    if (type === 'Shadowing') {
      return (
        <Button
          size={'icon'}
          className={cn(
            row.file !== null
              ? 'border-green-500  text-green-500 hover:bg-green-500 hover:text-white'
              : 'border-red-500  text-red-500 hover:bg-red-500 hover:text-white'
          )}
          variant={'outline'}
          onClick={() => handleViewDocument(row.file)}
          disabled={row?.file === null}
        >
          <FileIconLucide className="size-4" />
        </Button>
      );
    }

    return (
      <>
        <div className="flex gap-2 justify-center">
          <Button
            size={'icon'}
            className={cn(
              row.file !== null
                ? 'border-green-500  text-green-500 hover:bg-green-500 hover:text-white'
                : 'border-red-500  text-red-500 hover:bg-red-500 hover:text-white'
            )}
            variant={'outline'}
            onClick={() => handleViewDocument(row.file)}
            disabled={row?.file === null}
          >
            <FileIconLucide className="size-4" />
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (onUpdate == category.id) {
      getCertificates();
      setOnUpdate(null);
    }
  }, [onUpdate]);
  
  const additionalActions = useMemo(
    () => (
      <Button
        onClick={() =>
          onUploadDocumentOpen({
            category: category.id,
            type: type
          })
        }
        className="flex ml-auto w-auto gap-2 bg-primary"
      >
        <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
        New
      </Button>
    ),
    [onUploadDocumentOpen, category.id, type]
  );

  return (
    <>

      {loading ? (
        <div>
          <Skeleton className="h-12 w-full" />

          <Skeleton className="h-20 w-full mt-1" />
        </div>
      ):(
        <CustomAccordion title={t(`training.${category.name}`)}>
          <div className="flex gap-4 px-1 py-2"></div>
          <TableCustomVirtuoso
            tableId={category.id}
            showSearchInput={false}
            columns={columnsCertificates}
            data={certificatesData}
            isLoading={false}
            disableAllFilters={true}
            renderCellContent={(index: number, column: any) =>
              column.render ? column.render(certificatesData[index]) : certificatesData[index][column.key]
            }
            additionalButtons={additionalActions}
            onSort={() => {
            }}
            onFilterChange={() => {
            }}
            loadMore={() => {
            }}
            hasMore={false}
          />
        </CustomAccordion>
      )}

    </>
  );
};

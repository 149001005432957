import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {  TriangleAlert } from 'lucide-react';
import { RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { AlertI, UserI, CustomAlertResponse } from '@/common/domain/Alerts';
import { InfoCard } from '../../../../../../common/presentation/components/Card/InfoCard';
import { useStaffAlerts } from '../../../hooks/useStaffAlerts';
import AlertCard from './AlertCard';

interface StaffAlertsOverviewProps {
  className?: string;
}

export const StaffAlertsOverview = ({ className }: StaffAlertsOverviewProps) => {
  const { params } = useRouteParams();
  const { customAlerts, licenseAlerts} = useSelector((state: RootState) => state.staff.staffMember.alerts);
  const { t } = useTranslation();

  const staffCustomAlerts: CustomAlertResponse = {
    status: customAlerts?.status ?? 'idle',
    data:
      customAlerts?.data
        ?.filter((alert: AlertI) => {
          const filteredResidents = alert?.users?.filter((user: UserI) => user.id === params.s);
          return filteredResidents?.length > 0;
        })
        ?.map((alert: AlertI) => {
          const filteredResidents = alert.users.filter((user: UserI) => user.id === params.s);
          return {
            ...alert,
            users: filteredResidents
          };
        }) || []
  };

  const { totalAlertsCount } = useStaffAlerts(
     licenseAlerts,
     staffCustomAlerts
   );

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px] flex flex-col"
        headerTitle={t('residents.alerts')}
        headerIconLeft={TriangleAlert}
        numberOfAlerts={totalAlertsCount}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 max-h-[145px] overflow-y-hidden">
          <div className="space-y-3.5">
              <AlertCard
                licenseAlerts= {licenseAlerts}
                staffCustomAlerts= {staffCustomAlerts}
              />
            </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};
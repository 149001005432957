import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface SplashScreenProps {
  className?: string;
}

export const SplashScreen: React.FC<SplashScreenProps> = ({ className }) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center bg-zinc-100/60 dark:bg-zinc-950',
        'h-screen',
        className
      )}
    >
      <Loader2 className="animate-spin text-primary w-10 h-10" />
    </div>
  );
};

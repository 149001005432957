import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import useOverviewRoutineColumns from './OverviewRoutineColumns';
import useOverviewExceptionColumns from './OverviewExceptionColumns';
import useOverviewPrnColumns from './OverviewPrnColumns';
import { format, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { timeUtils } from '@/modules/emar/domain/timeUtils';
import { utils } from 'xlsx';

const OverviewTable = () => {
  const overviewRoutineColumns = useOverviewRoutineColumns();
  const overviewPrnColumns = useOverviewPrnColumns();
  const overviewExceptionColumns = useOverviewExceptionColumns();
  const { params, setParam } = useRouteParams();
  const { medications, getMedicationsTimesByResident, status: isLoading } = useMedicationStore();
  const [medicationFilter, setMedicationFilter] = useState<string>('');
  const { t } = useTranslation();


  useEffect(() => {
    if (!params.s) {
      setParam('s', 'managerRoutine');
    }
  }, [params.s, setParam]);

  const handleTabChange = useCallback(
    (tab: any) => {
      setParam('s', tab);
    },
    [setParam]
  );

  const validateStatus = (dispensed: any, witnessed: any) => {
    if (witnessed === 1) return 'witnessed';
    if (dispensed === 1) return 'dispensed';
    return 'none';
  };

  const formatDate = (value: any, formatType = 'dateFormat') => {
    try {
      if (!value) {
        return '';
      }
      const parsedDate = parseISO(value);
      if (!isValid(parsedDate)) {
        throw new Error('Fecha no válida');
      }
      return format(parsedDate, t(`dateFormats.${formatType}`));
    } catch (error) {
      console.error('Error formateando la fecha:', error);
      return value;
    }
  };

  const transformData = (medication: any, type: string) => {
    switch (type) {
      case 'managerRoutine':
        return medication.emar_routine_medications.map((routine: any) => ({

          ...routine,
          medication_name: `${medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
            } ${(medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
            (medication?.strength || medication?.form || '').slice(1)
            }`.trim(),
          dispenser_name: medication.responsible_of_administration,
          dispensed_at: formatDate(routine?.dispensed_at, 'dateTimeFormat'),
          witnessed_at: formatDate(routine?.witnessed_at, 'dateTimeFormat'),
          staff: routine.dispensed_by.first_name + ' ' + routine.dispensed_by.last_name,
          resident: medication?.resident?.first_name + ' ' + medication?.resident?.last_name,
          status:
            validateStatus(routine.dispensed, routine.witnessed)?.charAt(0).toUpperCase() +
            validateStatus(routine.dispensed, routine.witnessed)?.slice(1)
        }));
      case 'managerPRN':
        return medication.emar_medication_prns.map((prn: any) => ({
          ...prn,
          medication_name: `${medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
            } ${(medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
            (medication?.strength || medication?.form || '').slice(1)
            }`.trim(),
          strength: medication?.strength ? medication?.strength : medication?.form ? medication?.form : '',
          dispenser_name: medication?.responsible_of_administration || '',
          date: formatDate(prn?.dispensed_at, 'dateTimeFormat') || '',
          staff: prn?.dispensed_by ? prn.dispensed_by.first_name + ' ' + prn.dispensed_by.last_name : '',
          resident: medication?.resident ? medication.resident.first_name + ' ' + medication?.resident?.last_name : '',
          status:
            validateStatus(prn?.dispensed, prn?.witnessed)?.charAt(0).toUpperCase() +
            validateStatus(prn?.dispensed, prn?.witnessed)?.slice(1),
          instruction: medication.instruction,
          special_instructions: medication.special_instructions,
          frecuency: medication.frequency,
          not_exceed: medication.not_exceed,
          reason: medication?.emar_medication_prns[0]?.reason?.name
        }));
      case 'exception':
        const formartMedications =
          medication.emar_medication_exceptions.map((exception: any) => {
            return {
              medication_name: `${medication?.medication?.name?.charAt(0).toUpperCase() + medication?.medication?.name?.slice(1) || ''
                } ${(medication?.strength || medication?.form || '').charAt(0).toUpperCase() +
                (medication?.strength || medication?.form || '').slice(1)
                }`.trim(),
              exception_date: formatDate(exception?.created_at),
              created_at: formatDate(exception?.created_at, 'dateTimeFormat'),
              date_to_be_dispensed: exception?.date_to_be_dispensed || '',
              time_to_be_dispensed: exception?.time_to_be_dispensed || '',
              dispenser_name: medication.responsible_of_administration,
              resident: exception?.resident ? `${exception.resident.first_name} ${exception.resident.last_name}` : '',
              reason: exception?.reason?.name || '',
              details: exception?.details || '',
              staff: exception?.created_by ? exception.created_by.first_name + ' ' + exception.created_by.last_name : ''
            };
          });

        return formartMedications;
      default:
        return [];
    }
  };

  useEffect(() => {
    const fetchMedications = async () => {
      await getMedicationsTimesByResident(
        'all-locations',
        'all-residents',
        format(new Date(), 'yyyy-MM-dd'),
        medicationFilter
      );
    };
    fetchMedications();
  }, [getMedicationsTimesByResident, medicationFilter]);

  const filteredMedications = useMemo(() => {

    const sortingFunctions: any = {
      'managerRoutine': timeUtils?.sortRoutineMedications,
      'managerPRN': timeUtils?.sortPrnMedications,
      'exception': timeUtils?.sortMedicationsExceptions
    };

    const type = params.s && sortingFunctions?.[params.s] ? params.s : null;
    if (!type) return [];

    let formatMedications = medications?.flatMap((medication) => transformData(medication, type));
    if (sortingFunctions[type]) {
      formatMedications = sortingFunctions?.[type]?.(formatMedications);
    }

    return formatMedications;
  }, [medications, params.s]);
  
  const renderTabContent = () => {
    let columns: any = [];
    if (params.s === 'managerRoutine') {
      columns = overviewRoutineColumns;
    } else if (params.s === 'managerPRN') {
      columns = overviewPrnColumns;
    } else if (params.s === 'exception') {
      columns = overviewExceptionColumns;
    }

    const handleFilterMedication = (value: string) => {
      setMedicationFilter(value || '')
    }


    const callbackRenderContent = (index: number, column: any, data: any) => {
      const value = data[index][column.key];

      if (typeof value == 'object' && value !== null) {
        return value.name || ''
      }
      if (column.key == 'result') {
        return t(`emar-prn.${value}`);
      }
      if (params.s === 'managerRoutine' && ['dispensed_at', 'witnessed_at'].includes(column.key)) {

        const statusType = data[index]?.witnessed == 1 ? 'witnessed' : data[index]?.dispensed == 1 ? 'dispensed' : null;
        if (statusType) {
          const dateColumn = timeUtils.getDateColumn(statusType);
          const timeColumn = timeUtils.getTimeColumn(statusType);
          return timeUtils.formatISODateString(data[index][dateColumn], data[index][timeColumn]);
        }
      }

      if (params.s === 'exception' && column.key === 'created_at') {
        const dateToBeDispensed = data[index]?.date_to_be_dispensed;
        const timeToBeDispensed = data[index]?.time_to_be_dispensed;
        return timeUtils.formatISODateString(dateToBeDispensed, timeToBeDispensed);
      }

      return value;
    };

    return (
      <TableCustomVirtuoso
        data={filteredMedications}
        columns={columns}
        renderCellContent={callbackRenderContent}
        onSort={() => { }}
        loadMore={() => { }}
        hasMore={false}
        isLoading={isLoading == 'loading' ? true : false}
        showSearchInput={true}
        onFilterChange={handleFilterMedication}
      />
    );
  };

  return (
    <div className="w-full">
      <Tabs defaultValue="managerRoutine" value={params.s} onValueChange={handleTabChange}>
        <TabsContent value="managerRoutine">{params.s === 'managerRoutine' ? renderTabContent() : null}</TabsContent>
        <TabsContent value="managerPRN">{params.s === 'managerPRN' && renderTabContent()}</TabsContent>
        <TabsContent value="exception">{params.s === 'exception' && renderTabContent()}</TabsContent>
      </Tabs>
    </div>
  );
};

export default OverviewTable;


import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { useEmarPrnHistory } from '@/modules/emar/infrastructure/hooks/UseEmarPrnHistory';
import { usePrnColumns } from '@/modules/emar/presentation/components/tables/PrnColumns';
import { useMemo } from 'react';

export const EmarPrnHistoryDialog = () => {
  const { isOpen, onClose, data } = useEmarPrnHistory();
  const { medications } = useMedicationStore();
  const prnColumns = usePrnColumns();

  const emarPrnHistory = useMemo(() => {
    const medication = medications.find((med: any) => med.id === data?.id);

    if (!medication) return [];

    return medication.emar_medication_prns.map((prn: any) => ({
      ...prn,
      data
    }));
  }, [medications, data]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[50vw] flex flex-col gap-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">PRN History</DialogTitle>
          </DialogHeader>
          <SingleTable data={emarPrnHistory} columns={prnColumns} filterKey="name" filter={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};

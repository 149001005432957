import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { fetchCustomAlerts, fetchVitalResidentAlerts } from '@/modules/residents/presentation/slices/residentSlice';
import { setAlertStatus } from '@/modules/alerts/presentation/slices/AlertSlice';

export const useFetchResidentAlerts = (tabSelected: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const residentId = params.r;
  const resident = residentId && residentId.length > 0 ? [residentId] : null;

  const getAlerts = useCallback(async () => {
    if (!residentId) return;

    try {
      await dispatch(
        fetchCustomAlerts({
          resident_id: resident,
          type: 'resident'
        })
      ).unwrap();
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
    dispatch(setAlertStatus('idle'));
    },
    [dispatch, residentId]
  );

  const getResidentAlerts = useCallback(
    async () => {
    if (!residentId) return;
      try {
        await dispatch(
          fetchVitalResidentAlerts({
            resident_id: resident
          })
        )
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
      dispatch(setAlertStatus('idle'));
    },
    [dispatch, residentId]
  );

  useEffect(() => {
    if (residentId !== null && tabSelected === '') {
      getAlerts();
      getResidentAlerts();
    }
  }, [dispatch, residentId, tabSelected]);
};

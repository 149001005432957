import { create } from 'zustand';
import ResidentFormService from '@/modules/residents/infrastructure/services/FormsService';
import { BaseForm, FormsState, GeneratePdfRequest, FormResponseBase, SaveFormResponse } from '../../models/interfaces/Forms/forms.domain';

export const useFormsStore = create<FormsState>((set) => ({
  forms: [],
  formResource: null,
  status: 'idle',
  error: null,

  saveForm: async (data: BaseForm): Promise<SaveFormResponse> => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.saveForm(data);
      set({ status: 'idle' });
      return response;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  saveFormAndGeneratePdf: async (data: BaseForm): Promise<FormResponseBase> => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.saveFormAndGeneratePdf(data);
      set({ status: 'idle' });
      return response;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  generatePdf: async  (data: GeneratePdfRequest): Promise<FormResponseBase> => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.generatePdf(data);
      set({ status: 'idle' });
      return response;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  getFormHistory: async (residentId: string, key: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.getFormHistory(residentId, key);
      set({ status: 'idle', forms: response.data?.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  deleteForm: async (id, type) => {
    set({ status: 'loading' });
    try {
      let response;
      if (type === 'document') {
        response = await ResidentFormService.deleteDocument(id);
      } else {
        response = await ResidentFormService.deleteForm(id);
      }
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  generateHistoryPdf: async (form_id: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.generateHistoryPdf(form_id);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  getFormResources: async (form_id: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentFormService.getFormResources(form_id);
      set({ status: 'idle', formResource: response.data?.form_resource });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));

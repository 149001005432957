import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';


const ReportByResidentService = {
/*
    getCombinedReport: async (residentId: string, locationId: string, startDate: string, endDate: string) => {
        const url = `/reportbyresident/getreportbyresident`;
        const params = { resident_id: residentId, location_id: locationId, start_date: startDate, end_date: endDate };
        try {
            const response = await axios.get(url, { params });
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error fetching combined report data");
        }
    },
*/


    getCombinedReport: async (filters: {
        residentId: string;
        locationId: string;
        startDate: string;
        endDate: string;
        trackers: string[];
        categoriesNotes?: string[]; // optional
        categoriesVitals?: string[]; // optional
    }) => {
        const url = `/reportbyresident/getreportbyresident`;
        const params = {
            resident_id: filters.residentId,
            location_id: filters.locationId,
            start_date: filters.startDate,
            end_date: filters.endDate,
            trackers: filters.trackers,
            categoriesNotes: filters.categoriesNotes,
            categoriesVitals: filters.categoriesVitals,
        };

        try {
            const response = await axios.get(url, { params });
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error fetching combined report data");
        }
    },

    /*
    exportReport: async ({ residentId, locationId, startDate, endDate }: { residentId: string; locationId: string; startDate: string; endDate: string }) => {
        const url = `/reportbyresident/exportReport`;
        const params = { resident_id: residentId, location_id: locationId, datestart: startDate, datesend: endDate };
        try {
            const response = await axios.get(url, { params, responseType: 'blob' });
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error exporting report PDF");
        }
    }
    */

    exportReport: async ({ residentId, locationId, startDate, endDate, trackers, categoriesNotes, categoriesVitals }: { residentId: string; locationId: string; startDate: string; endDate: string; trackers: string[], categoriesNotes: string[], categoriesVitals: string[] }) => {
        const url = `/reportbyresident/exportReport`;
        const params = {
            resident_id: residentId,
            location_id: locationId,
            datestart: startDate,
            datesend: endDate,
            trackers: trackers.join(','), // Send trackers as a comma separated list
            categoriesNotes: categoriesNotes.join(','),
            categoriesVitals: categoriesVitals.join(','),
        };
    
        try {
            const response = await axios.get(url, { params, responseType: 'blob' });
    
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error exporting report PDF");
        }
    },

    getNotesCategories: async () => {
        const url = `/reportbyresident/getnotescategories`;
        try {
            const response = await axios.get(url);
            return response.data; // Backend returns the expected format
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error fetching notes categories");
        }
    },

    getVitalsCategories: async () => {
        const url = `/reportbyresident/getvitalscategories`;
        try {
            const response = await axios.get(url);
            return response.data; // Backend returns the expected format
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error fetching tracker vitals");
        }
    },

}

export default ReportByResidentService;
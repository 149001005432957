import { CrudState } from '@/modules/notes/domain/note.domain';
import { Label } from '@/common/presentation/components/ui/label';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '@/modules/notes/infrastructure/services/NoteHelperService';

interface InputsNoteFiltersProps {
    crudState: CrudState;
    handleResidentFilter: (residentId: string) => void;
    handleStaffFilter: (staffId: string) => void;
    handleCategoryFilter: (categoryId: string) => void;
    selectedFilter: (type: string) => string;
}

export function InputsNoteFilters({ crudState, handleResidentFilter, handleStaffFilter, handleCategoryFilter, selectedFilter }: InputsNoteFiltersProps) {
    // Initial 
    const { t, i18n } = useTranslation();

    let target = crudState.showingStaffNotes ? 'staff' : 'resident';

    // Redux
    const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
    const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
    const categoriesFilter = useSelector((state: RootState) => state.notes.categories.filter((category) => category.type === target && (/de enfermer/i.test(category.category) ? i18n.language == 'es' : true)));

    let categories = [];
    if (crudState.showingStaffNotes) {
        categories = categoriesFilter.filter(category => category.type == 'staff');
    } else {
        categories = categoriesFilter.filter(category => category.type == 'resident');
    }

    return (
        <>
            {!crudState.showingStaffNotes && (
                <div className="grid grid-cols-3 items-center gap-3">
                    <Label htmlFor="resident">{t('notes.resident')}</Label>
                    <Select value={selectedFilter('resident')} onValueChange={handleResidentFilter}>
                        <SelectTrigger className="col-span-2 h-8">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent id="resident">
                            <ScrollArea className='max-h-[200px] overflow-auto'>
                                <SelectItem value="all">{t('notes.all')}</SelectItem>
                                {residents?.map((resident) => (
                                    <SelectItem key={resident.id} value={resident.id}>
                                        {resident.first_name + ' ' + resident.last_name}
                                    </SelectItem>
                                ))}
                            </ScrollArea>
                        </SelectContent>
                    </Select>
                </div>
            )}
            <div className="grid grid-cols-3 items-center gap-3">
                <Label htmlFor="staff">{t('notes.titleStaffFilter')}</Label>
                <Select value={selectedFilter('staff')} onValueChange={handleStaffFilter}>
                    <SelectTrigger className="col-span-2 h-8">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent id="staff">
                        <ScrollArea className='max-h-[200px] overflow-auto'>
                            <SelectItem value="all">{t('notes.all')}</SelectItem>
                            {staff?.map((staff: any) => (
                                <SelectItem key={staff.id} value={staff.id}>
                                    {staff.first_name + ' ' + staff.last_name}
                                </SelectItem>
                            ))}
                        </ScrollArea>
                    </SelectContent>
                </Select>
            </div>
            <div className="grid grid-cols-3 items-center gap-3">
                <Label htmlFor="category">{t('notes.category')}</Label>
                <Select value={selectedFilter('category')} onValueChange={handleCategoryFilter}>
                    <SelectTrigger className="col-span-2 h-8">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent id="category">
                        <ScrollArea className='max-h-[200px] overflow-auto'>
                            <SelectItem value="all">{t('notes.all')}</SelectItem>
                            {categories?.map((category: any) => (
                                <SelectItem key={category.id} value={category.id}>
                                    {NoteHelperService.decodeHTMLEntities(category.category)}
                                </SelectItem>
                            ))}
                        </ScrollArea>
                    </SelectContent>
                </Select>
            </div>
        </>
    );
}

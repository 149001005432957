import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerHygieneService from '../../infrastructure/services/TrackerHygieneService';

interface ITrackerHygieneState {
    hygienes: any[];
    dayHygienes: any[];
    typesHygienes: any[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: ITrackerHygieneState = {
  hygienes: [],
  dayHygienes: [],
  typesHygienes: [],
  status: 'idle',
  error: null
};

export const getHygiene = createAsyncThunk("trackerHygiene/getHygiene", async (body: any) => {
    const response = await TrackerHygieneService.getTrackerHygiene(body);
    return response;
});

export const getDayHygiene = createAsyncThunk("trackerHygiene/getDayTrackerHygieneType", async (body: any) => {
  const response = await TrackerHygieneService.getDayTrackerHygiene(body);
  return response;
});

export const saveHygiene = createAsyncThunk("trackerHygiene/saveHygiene", async (body: any) => {
  const response = await TrackerHygieneService.saveTrackerHygiene(body);
  return response;
});

export const updateHygiene = createAsyncThunk("trackerHygiene/updateHygiene", async (body: any) => {
  const response = await TrackerHygieneService.updateTrackerHygiene(body);
  return response.data;
});

export const getTrackerHygieneType = createAsyncThunk("trackerHygiene/getTrackerHygieneType", async (body: any) => {
  const response = await TrackerHygieneService.getTrackerHygieneType(body);
  return response.data;
});

export const saveTrackerHygieneType = createAsyncThunk("trackerHygiene/saveTrackerHygieneType", async (body: any) => {
  const response = await TrackerHygieneService.saveTrackerHygieneType(body);
  return response.data;
});

export const updateTrackerHygieneType = createAsyncThunk("trackerHygiene/updateTrackerHygieneType", async (body: any) => {
  const response = await TrackerHygieneService.updateTrackerHygieneType(body);
  return response.data;
});

const trackerHygiene = createSlice({
  name: 'trackerHygiene',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getHygiene.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getHygiene.fulfilled, (state, action) => {
      state.status = 'idle';
      state.hygienes = action.payload.data;
    });

    builder.addCase(getHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getDayHygiene.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getDayHygiene.fulfilled, (state, action) => {
      state.status = 'idle';
      state.dayHygienes = action.payload.data.data;
    });

    builder.addCase(getDayHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(getTrackerHygieneType.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerHygieneType.fulfilled, (state, action) => {
      state.status = 'idle';
      state.typesHygienes = [...action.payload.defaultTypes, ...action.payload.types];
    });

    builder.addCase(getTrackerHygieneType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerHygiene.reducer;

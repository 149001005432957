import { useLocationSelectorDialog } from '@/auth/infrastructure/hooks/useLocationSelectorDialog';
import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose
} from '@/common/presentation/components/ui/dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/common/presentation/components/ui/tooltip';
import { LogOut } from 'lucide-react';
import { useContext, useState } from 'react';

export const LocationSelectorDialog = () => {
  const { isOpen, onClose } = useLocationSelectorDialog();
  const { setParam } = useRouteParams();
  const { setHasSelectedLocation } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleLogout = () => {
    setIsDisabled(true);
    logout();
  };

  const handleLocationChange = (locationId: string) => {
    localStorage.setItem('location_selected', locationId);
    setParam('l', locationId);
    setHasSelectedLocation(true);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogContent className="max-w-[80vw] w-[90%] md:w-auto max-h-[90vh] h-auto overflow-x-auto [&>button:not(.custom-close)]:hidden">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <DialogClose
                  onClick={handleLogout}
                  disabled={isDisabled}
                  className="custom-close absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100"
                >
                  <LogOut className="h-4 w-4" />
                </DialogClose>
              </TooltipTrigger>
              <TooltipContent side="left">
                <p>Logout</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <DialogHeader>
            <DialogTitle className="text-xl">Location Selector</DialogTitle>
            <DialogDescription>Select a location to proceed</DialogDescription>
          </DialogHeader>
          <LocationSelector allLocations={false} onSelect={(locationId: string) => {handleLocationChange(locationId)}} firstSelection={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, FormItem, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export const AuthorizationHeaderForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="w-full md:w-3/4">
          <FormField
            control={control}
            name="to_user"
            render={({ field }) => (
              <FormItem>
                <label className="block text-sm text-zinc-700 dark:text-zinc-200 mb-1">
                  {t('residents.forms.releaseMedicalInfo.to')}:
                </label>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800"
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.responsibleParty')})
                </p>
              </FormItem>
            )}
          />
        </div>

        <div className="w-full md:w-1/4">
          <FormField
            control={control}
            name="to_date"
            render={({ field }) => (
              <FormItem>
                <label className="block text-sm text-zinc-700 dark:text-zinc-200 mb-1">
                  {t('genecommonral.date')}:
                </label>
                <FormControl>
                  <Input
                    {...field}
                    type="date"
                    className="w-full dark:bg-zinc-800"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-6">
        <FormField
          control={control}
          name="to_address"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800 mt-2"
                />
              </FormControl>
              <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                ({t('contacts.address')})
              </p>
            </FormItem>
          )}
        />
      </div>

      <p className="mb-2 text-zinc-600 dark:text-zinc-300 text-sm">
        {t('residents.forms.releaseMedicalInfo.authorizationReleaseInfo')}:
      </p>

      <div className="mb-2">
          <FormField
            control={control}
            name="resident_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800 mt-2"
                    readOnly
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.namePerson')})
                </p>
              </FormItem>
            )}
          />
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/2">
          <FormField
            control={control}
            name="facility_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800 mt-2"
                    readOnly
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.nameFacility')})
                </p>
              </FormItem>
            )}
          />
        </div>
        <div className="w-full md:w-1/2">
          <FormField
            control={control}
            name="facility_address"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    className="w-full dark:bg-zinc-800 mt-2"
                    readOnly
                  />
                </FormControl>
                <p className="mt-1 text-xs text-zinc-500 dark:text-zinc-400 text-center uppercase">
                  ({t('residents.forms.releaseMedicalInfo.addressFacility')})
                </p>
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
};

import { Card, CardContent } from '../../../../../common/presentation/components/ui/card';
import { cn } from '@/lib/utils';
import { CrudState, NoteI } from '@/modules/notes/domain/note.domain';
import { ImageCard } from '../../../../../common/presentation/components/Selectors/ImageCard';
import { Input } from '../../../../../common/presentation/components/ui/input';
import { RootState } from '@/store/store';
import { ScrollArea, ScrollBar } from '../../../../../common/presentation/components/ui/scroll-area';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import NoteHelperService from '@/modules/notes/infrastructure/services/NoteHelperService';
import React, { useEffect, useState } from 'react';
import Title from '@/common/presentation/components/Title/Title';

interface HousesSelectorProps {
  onSelect: (id: string[]) => void;
  className?: React.ReactNode;
  staffId?: string[];
  setCrudState: (crudState: CrudState) => void;
  note?: NoteI | null;
}

export const HousesSelector = ({
  onSelect,
  className,
  staffId = [],
  setCrudState,
  note
}: HousesSelectorProps) => {

  const { t } = useTranslation();
  const { locationSelectedId } = useLocation();
  const { locations, status } = useSelector((state: RootState) => state.locations.allLocations);
  const [selectedUser, setSelectedUser] = useState(staffId || []);
  const [valueSearch, setValueSearch] = useState('');

  const dataOtherOptions = [{
    id: 'all-houses',
    name: t('notes.allHousesLocationSelector'),
    image: undefined
  }]

  let listLocations = [...dataOtherOptions, ...locations].filter(location => {
    if (locationSelectedId != 'all-locations') {
      return location.id === locationSelectedId
    }
    let valueLowerCase = valueSearch.toLowerCase();
    return location.name.toLowerCase().includes(valueLowerCase)
  });

  useEffect(() => {
    if (!note) {
      if (listLocations.length == 1) {
        setSelectedUser(listLocations.map(it => it.id))
      } else {
        setSelectedUser(['all-houses'])
      }
    }
  }, [locationSelectedId])

  const onSelectedUser = (id: string) => {
    if (['all-houses'].includes(id)) {
      setSelectedUser([id]);
      onSelect([id]);
    } else {
      const locstion = [...selectedUser.filter((it) => !['all-houses'].includes(it))];
      const index = locstion.indexOf(id);
      if (index !== -1) {
        locstion.splice(index, 1);
      } else {
        locstion.push(id);
      }
      setSelectedUser(locstion);
      onSelect(locstion);
    }
  };

  const closeForm = () => {
    setCrudState(NoteHelperService.defineState({ showingBeetweenStaffNotes: true }));
  }

  return (
    <div className={cn(className)}>
      <div className="flex flex-row items-center text-lg font-bold justify-between mb-3">
        <Title className='text-primary text-xl'>{t('notes.locationSelector')}</Title>
        <div className='flex justify-end items-center'>
          {locations.length > 0 && (
            <Input onChange={(e) => setValueSearch(e.target.value)} placeholder={t('notes.filterLocationSelector')} className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto" />
          )}
          <X className="h-5 w-5 flex-1 gap-4 cursor-pointer ml-3" onClick={closeForm} />
        </div>
      </div>

      <Card>
        <CardContent className='p-5 pb-0 bg-zinc-50 rounded-lg'>
          {status === 'loading' ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="h-20 w-20 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 mt-3 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {listLocations.length === 0 && (
                  <div className="w-full flex flex-col gap-2 items-center justify-center">
                    <p>{t('notes.notLocationsFound')}</p>
                  </div>
                )}

                {listLocations.length > 0 && (
                  <div className="flex gap-2">
                    {listLocations.map((loc, index) => (
                      <ImageCard
                        key={index}
                        item={{
                          id: loc.id,
                          firstName: loc.name,
                          lastName: '',
                          image: loc.image_path
                        }}
                        onSelect={() => onSelectedUser(loc.id)}
                        aspectRatio={'rounded'}
                        isSelected={selectedUser?.includes(loc.id)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/common/presentation/components/ui/table';
import { DocumentCategory } from '@/modules/residents/infrastructure/types/documents';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleFadingPlus } from 'lucide-react';
import { LocationCategoryItem } from './LocationCategoryItem';
import { useTranslation } from 'react-i18next';
import { UploadDocumentFacility } from './UploadDocumentFacility';
import { getFolders } from '../../slices/locationsSlice';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';
import { LocationI } from '@/modules/locations/infrastructure/interfaces/LocationInterface';

interface Column {
    key: string;
    label: string;
    type?: 'text' | 'checkbox' | 'action';
    width?: string;
}

interface CategoryCardProps {
    locationCategories: DocumentCategory;
    locationId: string;
    location: LocationI
}

interface FoldersI {
    category: string;
    key: string;
}

export const LocationCategoryCard = ({ locationCategories, locationId, location }: CategoryCardProps) => {

    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [currentCategory, setCurrentCategory] = useState<string | null>(null);
    const [folders, setFolders] = useState([]);
    const { t } = useTranslation();
    const locationDocuments = location?.documents || [];
    const { hasAnyRole } = useRoleHelper();

    const dataFolders = async () => {
        try {
            const response = await dispatch(getFolders(locationId));
            if (response.payload) {
                setFolders(response.payload);
            }
        } catch (error) {
            console.error('Error fetching location types:', error);
        }
    };


    useEffect(() => {
        dataFolders();
    }, []);

    const onClose = () => {
        setIsOpen(false);
        setCurrentCategory(null);
    }

    const handleNewDocument = () => {
        setCurrentCategory(locationCategories.value);
        setIsOpen(true);
    };

    const documents = useMemo(() => {
        const defaultValues = {
            category: locationCategories.value,
            comments: null,
            url: null,
            other_information: {
                hard_copy: false
            },
            hard_copy: false,
            parent_documents: []
        };

        const baseDocuments = locationCategories.documents || [];

        const docs = baseDocuments.map((doc) => {
            const locationDoc = folders.find(
                (d: any) => d.category === doc.value && d.category === locationCategories.value
            );
            return { ...defaultValues, ...doc, ...(locationDoc || {}), key: doc.value };
        });

        const additionalDocs = folders
            .filter(
                (doc: FoldersI) =>
                    doc.category === locationCategories.value &&
                    !baseDocuments.some((d) => d.value === doc.key)
            )
            .map((doc: FoldersI) => ({
                ...defaultValues,
                ...doc,
                key: doc.key
            }));

        return [...docs, ...additionalDocs];
    }, [folders, locationCategories]);


    // Filtrar los documentos según el filtro
    const filteredDocuments = useMemo(() => {
        if (!filter) {
            return [...documents].sort((a, b) => a.key.localeCompare(b.key));
        }

        return documents
            .filter((doc) => doc.label.toLowerCase().includes(filter.toLowerCase()))
            .sort((a, b) => a.key.localeCompare(b.key));
    }, [documents, filter]);


    const columns: Column[] = [
        {
            key: 'name',
            label: t('facilities.documentsName'),
            type: 'text',
            width: '30%'
        },
        {
            key: 'comments',
            label: t('facilities.comments'),
            type: 'text',
            width: '30%'
        },
        {
            key: 'hard_copy',
            label: t('facilities.hardCopy'),
            type: 'checkbox',
            width: '10%'
        },
        {
            key: 'version_history',
            label: t('facilities.versionHistory'),
            type: 'text',
            width: '10%'
        },
        {
            key: 'actions',
            label: t('trackers.Actions'),
            type: 'action',
            width: '20%'
        }
    ];


    return (
        <CustomAccordion title={locationCategories.label}>
            <div className="flex gap-4 px-1 py-2">
                <Input placeholder="Search" className="w-[300px]" value={filter} onChange={(e) => setFilter(e.target.value)} />
                <Button
                    onClick={handleNewDocument}
                    className="flex ml-auto w-auto gap-2 bg-primary"
                >
                    <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                    {t('trackers.trackerhygiene.new')}
                </Button>

                <UploadDocumentFacility
                    isOpen={isOpen}
                    onClose={onClose}
                    location={location}
                    category={currentCategory}
                    dataFolders={dataFolders}

                />

            </div>

            <Table className="w-full border-collapse rounded-t-lg overflow-hidden">
                <TableHeader>
                    <TableRow className="bg-primary text-white hover:bg-primary">
                        {columns.map((column) => (
                            <TableCell key={column.key} style={{ width: column.width }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {filteredDocuments.map((document: any, index: number) => {
                        const isAdminOnly = document.admin_only === 'yes';

                        const status = document.status;

                        const isAdmin = hasAnyRole(['Super Administrator', 'Executive']);

                        if (isAdminOnly && !isAdmin) {
                            return null;
                        }

                        if (status === 'inactive') {
                            return null;
                        }

                        return (
                            <LocationCategoryItem
                                location_id={locationId}
                                document={document}
                                dataFolders={dataFolders}
                                locationDocumentsCount={document?.documents.length}
                                key={index}
                            />

                        );
                    })}
                </TableBody>
            </Table>
        </CustomAccordion>
    )
}
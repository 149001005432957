import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SignUpService from '../../infrastructure/services/SignUpService';
import { IAccount, ICountry, IService } from '../../domain/signup.domain';


interface IState {
  countries: Array<ICountry>;
  services: IService;
}

const initialState: IState = {
  countries: [],
  services: {},
};

export const fetchWizardPrice = createAsyncThunk('wizard/getPrice', async (formData: any) => {
  try {
    const response = SignUpService.getPrice(formData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchInitialData = createAsyncThunk('signUp/getInitialData', async (country: string) => {
    const response = await SignUpService.getInitialData(country);
    return response;
});

export const fetchGeoLocation = createAsyncThunk('signUp/getGeoLocation', async () => {
  const response = await SignUpService.getGeoLocationClient();
  return response;
});

export const createClient = createAsyncThunk('signUp/createClient', async (formData: any) => {
  const response = await SignUpService.createClient(formData);
  return response;
});

export const fetchClientToken = createAsyncThunk('signUp/getClientToken', async (data: { client_id: string, user_id: string }) => {
  const response = await SignUpService.getClientToken(data);
  return response;
});

export const fetchUserNameExist = createAsyncThunk('signUp/userNameExist', async (userName: any) => {
  try {
    const response = await SignUpService.userNameExists(userName);
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateLoginInfo = createAsyncThunk('signUp/updateLoginInfo', async (data: IAccount) => {
  try {
    const response = await SignUpService.updateLogin(data);
    return response;
  } catch (error) {
    throw error;
  }
});

export const WizardSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInitialData.fulfilled, (state, action) => {
      state.countries = action.payload.countries;
      state.services = action.payload.services;
    });
    builder.addCase(fetchGeoLocation.fulfilled, (_) => {});
    builder.addCase(createClient.fulfilled, (_) => {});
  }
});

export const { } = WizardSlice.actions;
export default WizardSlice.reducer;

import React from 'react';;
import { AlertI, AlertType } from '@/common/domain/Alerts';
import AlertItemCard from '@/common/presentation/components/AlertItemCrad.tsx/AlertItemCard';
import ButtonMarkAsDone from '@/modules/dashboard/presentation/pages/components/ButonMarkAsDone/ButtonMarkAsDone';

interface AlertsListProps {
  alerts: AlertType[];
  hiddenAlerts: Record<string, any>;
  setHiddenAlerts: (alerts: Record<string, any>) => void;
  formatDate: (date: string, ...args: boolean[]) => string;
  t: (key: string, options?: Record<string, any>) => string;
}

const AlertsList: React.FC<AlertsListProps> = ({ alerts, hiddenAlerts, setHiddenAlerts, formatDate, t }) => {
  return (
    <div className="flex flex-col gap-2">
      {alerts.map((alert: AlertType, index) => {
        const customAlert = alert as AlertI & {
          type: 'default' | 'custom' | 'license' | 'resident_reports';
        };

        return (
          <div key={customAlert.id} className="flex flex-col gap-3">
            {['resident_reports', 'license'].includes(customAlert.type)
              ? customAlert.users.length > 0
                ? customAlert.users.map((user) => (
                    <AlertItemCard
                      key={`${index}_${user.id}`}
                      names={`${user.first_name} ${user.last_name}`}
                      title={t(`alerts.${customAlert.title}`, {
                        date: formatDate(customAlert?.date ?? '', false, false),
                      })}
                      date={customAlert.date}
                      image={user?.profile_url ?? ''}
                    />
                  ))
                : null
              : customAlert.users.map((user) => (
                  <AlertItemCard
                    key={`${index}_${user.id}`}
                    names={`${user.first_name} ${user.last_name}`}
                    title={customAlert.title}
                    date={customAlert.date}
                    image={user?.profile_url ?? ''}
                    Button={
                      <ButtonMarkAsDone
                        alertId={customAlert.id}
                        relatedId={user.id}
                        hiddenAlerts={hiddenAlerts}
                        setHiddenAlerts={setHiddenAlerts}
                      />
                    }
                  />
                ))}
          </div>
        );
      })}
    </div>
  );
};

export default AlertsList;

import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { fetchContactsByResident, fetchPhysicianByResident } from '@/modules/residents/presentation/slices/residentSlice';
import { setAlertStatus } from '@/modules/alerts/presentation/slices/AlertSlice';

export const useFetchContacts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const residentId = params.r;

  const getPhysicianByResident = useCallback(
    async () => {
    if (!residentId) return;
      try {
        await dispatch(
          fetchPhysicianByResident(residentId)
        )
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
      dispatch(setAlertStatus('idle'));
    },
    [dispatch, residentId]
  );


  const getContactByResident = useCallback(
    async () => {
    if (!residentId) return;
      try {
        await dispatch(
          fetchContactsByResident({
            resident_id: residentId,
            category:[ 'Responsible Person']
          })
        )
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
      dispatch(setAlertStatus('idle'));
    },
    [dispatch, residentId]
  );

  useEffect(() => {
    if (residentId !== null) {
      getContactByResident();
      getPhysicianByResident();
    }
  }, [dispatch, residentId]);
};

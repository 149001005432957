import { useTranslation } from 'react-i18next';
import AlertsSkeleton from '@/modules/dashboard/presentation/pages/components/skeleton';
import { useResidentAlerts } from '@/common/hooks/Alerts/useResidentAlerts';
import { ResidentI } from '@/common/domain/Residents';
import { AlertRenderer } from './AlertRenderer';
import { AlertI, AlertsResponseI, AlertType } from '@/common/domain/Alerts';



type ResidentAlertProps = {
  residents: ResidentI[];
  residentAlerts: { status: string; data: AlertsResponseI[] },
  residentCustomAlerts: { status: string; data: AlertI[] },
  customizedReports: { status: string; data: AlertI[] }
};

const ResidentAlert: React.FC<ResidentAlertProps> = ({
  residents,
  residentAlerts,
  residentCustomAlerts,
  customizedReports
}) => {
  const { t } = useTranslation();

  const { alerts, hiddenAlerts, setHiddenAlerts } = useResidentAlerts(
    residentAlerts,
    residentCustomAlerts,
    customizedReports
  );

  return (
    <>
     {residentAlerts.status === 'loading' || residentCustomAlerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : alerts.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {alerts.map((alert: AlertType, index) => (
                <AlertRenderer
                  key={index}
                  alert={alert}
                  residents={residents}
                  hiddenAlerts={hiddenAlerts}
                  setHiddenAlerts={setHiddenAlerts}
                />

            ))}
          </div>
        )}
    </>
  )
};

export default ResidentAlert;

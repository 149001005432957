import { Input } from '@/common/presentation/components/ui/input';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Minus, Plus } from 'lucide-react';

interface Props {
  defaultValue: number;
  value?: number;
  name: string;
  maxValue: number;
  minValue: number;
  onChange?: (value: number, action?: 'add' | 'remove') => void;
  step: number;
  allowRemoving?: boolean;
}

const Counter: React.FC<Props> = ({
  defaultValue,
  value,
  name,
  maxValue,
  minValue,
  onChange,
  step,
  allowRemoving = true
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  useEffect(() => {
    setInternalValue(value !== undefined ? value : 1);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);

    if (e.target.value === '') {
      setInternalValue(1);
      if (onChange) onChange(1);
      return;
    }

    if (isNaN(newValue) || (maxValue !== undefined && newValue > maxValue) || newValue < minValue) {
      return;
    }

    setInternalValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleAdd: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    const newValue = internalValue + step;
    if (maxValue !== undefined && newValue > maxValue) {
      return;
    }
    setInternalValue(newValue);
    if (onChange) onChange(newValue, 'add');
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!allowRemoving) {
      if (onChange) {
        onChange(internalValue, 'remove');
      }

      return;
    }
    const newValue = internalValue - step;
    if (newValue < minValue) {
      return;
    }
    setInternalValue(newValue);
    if (onChange) onChange(newValue, 'remove');
  };

  return (
    <div className={`flex flex-row gap-2 items-center`}>
      <button
        className={`font-extrabold h-6 w-6 text-lg bg-primary text-white border hover:text-white rounded flex justify-center text-center items-center`}
        onClick={handleRemove}
        disabled={internalValue === minValue}
      >
        <Minus className="text-white" size={18} strokeWidth={2.75} />
      </button>
      <Input
        className={`w-8 h-8 font-bold p-0 text-center`}
        type="number"
        name={name}
        value={internalValue}
        onChange={handleInputChange}
      />
      <motion.div
        className="bg-primary h-6 w-6 rounded flex items-center justify-center hover:cursor-pointer font-bold text-white"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 400, damping: 20 }}
        onClick={handleAdd}
      >
        <Plus className="text-white" size={18} strokeWidth={2.75} />
      </motion.div>
    </div>
  );
};

export default Counter;

import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { CircleAlert } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { MedicationDispensingDataI } from '@/modules/dashboard/domain/dashboard.domain';
import ResidentAlertContainer from './components/ResidentAlertContainer';
import { useTranslation } from 'react-i18next';
import { Label } from '@/components/ui/label';
import { useNavigate } from 'react-router-dom';

interface MedicationAlertI {
  resident_id: string;
  medications: MedicationDispensingDataI[];
}

const MedicationAlertsModal = () => {
  const alerts = useSelector((state: RootState) => state.dashboard.medicationDispensingAlerts);
  const [isOpened, setIsOpened] = useState(false);
  const [medicationAlerts, setMedicationAlerts] = useState<MedicationAlertI[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    formatData();
  }, [alerts]);

  const formatData = () => {
    if (alerts.status === 'idle') {
      const alertsData = alerts?.data ? Object.values(alerts.data) : [];
      setMedicationAlerts(alertsData);
      if (alertsData.length > 0) {
        setIsOpened(true);
      }
    }
  };

  const handleClick = (event: any) => {
    if (event.button === 0) {
      event.preventDefault();
      navigate('/dashboard/medication-alerts');
    }
  };

  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogContent className="flex flex-col h-[90vh] max-w-[97vw] max-h-[85vh] z-[9999] md:w-72 md:min-w-[700px] shadow-lg rounded-md border-t-4 border-primary p-0 gap-0">
        <DialogHeader className="p-6 pb-2 gap-2 flex-shrink-0">
          <DialogTitle className="text-primary text-xl font-semibold flex flex-row items-center gap-2">
            <CircleAlert strokeWidth={2.3} />
            {t('dashboard.medicationAlerts')}
          </DialogTitle>
          <hr className="border-primary w-full my-2" />
        </DialogHeader>
        <div className="px-6 my-2 text-sm">
          <Label>{t('dashboard.alertsForLast', { days: 7 })}</Label>
          <a
            href="/dashboard/medication-alerts"
            className="p-0 pl-1 text-base border-none outline-none focus:outline-none text-primary"
            onClick={handleClick}
            style={{ border: 'none', boxShadow: 'none', outline: 'none' }}
          >
            {t('dashboard.clickHere')}
          </a>
          <Label> {t('dashboard.seeAlertsPast')}</Label>
        </div>
        <div className="flex-grow overflow-y-auto px-6 py-2">
          {medicationAlerts.map((alertInfo, index) => (
            <ResidentAlertContainer key={index} alertInfo={alertInfo} index={index} />
          ))}
        </div>

        <DialogFooter className="p-6 pt-2 flex-shrink-0 gap-2">
          <Button
            className="bg-background text-primary hover:bg-primary/10 text-sm focus:border-none"
            onClick={() => setIsOpened(false)}
          >
            {t('dashboard.close')}
          </Button>
          <Button
            className="bg-primary text-sm"
            onClick={() => {
              navigate('/emar?e=routine');
            }}
          >
            {t('dashboard.medications')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MedicationAlertsModal;

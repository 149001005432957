import Counter from '@/common/presentation/components/Counter/Counter';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/common/presentation/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { IFacilitiesInfo } from '@/modules/subscription/domain/makerPlace.domain';
import { localization } from '@/utils/helpers/localization.helper';
import { useState, useEffect, useCallback, useMemo } from 'react';

interface IProps {
  facilities: IFacilitiesInfo[];
  extraBedPrice: string;
  onSave: (facilitiesList: IFacilitiesInfo[]) => void;
  extraBedPriceNumber: number;
  currency: string;
}

const BedsByFacilities: React.FC<IProps> = ({ facilities, extraBedPrice, onSave, extraBedPriceNumber, currency }) => {
  const [facilitiesAux, setFacilitiesAux] = useState<IFacilitiesInfo[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    setFacilitiesAux(facilities);
  }, [facilities]);

  useEffect(() => {
    const hasUnsavedChanges = facilitiesAux.some((facility, index) => {
      if (facility.id.length === 0) {
        const originalFacility = facilities[index];
        return facility?.beds_number !== originalFacility?.beds_number;
      }
      return false;
    });

    setHasChanges(hasUnsavedChanges);
  }, [facilitiesAux, facilities]);

  const totalAddedPrice = useMemo(() => {
    return facilitiesAux.reduce((sum, facility) => {
      if (facility.id.length === 0) {
        return sum + (facility.beds_number > 10 ? (facility.beds_number - 10) * extraBedPriceNumber : 0);
      }
      return sum;
    }, 0);
  }, [facilitiesAux, extraBedPriceNumber]);

  const handleCountChange = (index: number, value: number) => {
    setFacilitiesAux((prevFacilitiesAux) => {
      const updatedFacilities = [...prevFacilitiesAux];
      updatedFacilities[index] = {
        ...updatedFacilities[index],
        beds_number: value
      };
      return updatedFacilities;
    });
  };

  const saveChanges = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onSave(facilitiesAux);
      setHasChanges(false);
    },
    [facilitiesAux, onSave]
  );

  const resetChanges = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setFacilitiesAux(
        facilities.map((facility) => ({
          ...facility,
          beds_number: facility.id.length > 0 ? facility.beds_number : 10
        }))
      );
      setHasChanges(false);
    },
    [facilities]
  );

  if (facilitiesAux.length === 0) {
    return null;
  }

  return (
    <div className="w-full flex flex-col gap-2">
      <Label className="text-primary text-xs">
        Each facility increases your monthly rate. Manage these parameters according to your needs.
      </Label>
      <Accordion
        type="single"
        collapsible
        className="w-full border rounded"
        onValueChange={(value) => setIsAccordionOpen(value === 'beds')}
      >
        <AccordionItem value="beds">
          <AccordionTrigger className="px-4 py-2 hover:no-underline [&[data-state=open]>svg]:text-primary [&>svg]:text-primary text-base flex justify-between items-center">
            <span>Set up Facility Beds</span>
            {(totalAddedPrice > 0 && !isAccordionOpen && !hasChanges) && (
              <span className="text-primary text-sm font-medium ml-auto mr-2">
                {localization.formatPrice(totalAddedPrice, currency)} Added
              </span>
            )}
          </AccordionTrigger>
          <AccordionContent className="px-4 pb-4" key={JSON.stringify(facilitiesAux)}>
            <div className="text-sm text-gray-600 mb-4">
              Each facility includes up to 10 beds at no extra cost. For any additional beds beyond the initial 10, a
              fee of {extraBedPrice} per bed will be charged
            </div>
            {facilitiesAux.map(
              (facility, index) =>
                facility.id.length === 0 && (
                  <div key={index} className="flex items-center justify-between mb-4">
                    <span className="font-semibold">Number of Beds for {facility.name}</span>
                    <div className="flex items-center gap-2">
                      <Counter
                        defaultValue={facility.beds_number}
                        value={facility.beds_number}
                        minValue={1}
                        maxValue={20}
                        onChange={(value) => handleCountChange(index, value)}
                        step={1}
                        name={`beds-${facility.id}`}
                      />
                    </div>
                  </div>
                )
            )}
            <div className="flex justify-between items-center mt-6">
              <div className="text-lg font-semibold text-primary">
                {(totalAddedPrice > 0 && !hasChanges) && `${localization.formatPrice(totalAddedPrice, currency)} /Added`}
              </div>
              <div className="flex gap-2">
                <Button variant="outline" className="border-none" onClick={resetChanges}>
                  Cancel
                </Button>
                <Button className="bg-primary text-white" onClick={saveChanges} disabled={!hasChanges}>
                  Save Changes
                </Button>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default BedsByFacilities;

import { NavigationI } from '../../../domain/models/interfaces/Forms/Navigation.domain';
// Fillable Forms
import { Lic601EmergencyInfoForm } from '@/modules/residents/presentation/components/Forms/Licensing/Lic601EmergencyInfo/Lic601EmergencyInfoForm';
import { ResidentAppraisalForm } from '@/modules/residents/presentation/components/Forms/Licensing/ResidentAppraisal/ResidentAppraisalForm';
import { ReleaseMedicalInfoForm } from '@/modules/residents/presentation/components/Forms/Licensing/ReleaseMedicalInfo/ReleaseMedicalInfoForm';
import { PersonalRightsRCFEForm } from '@/modules/residents/presentation/components/Forms/Licensing/PersonalRightsRCFE/PersonalRightsRCFEForm';
import { ResidentPersonalPropertyForm } from '@/modules/residents/presentation/components/Forms/Licensing/ResidentPersonalProperty/ResidentPersonalPropertyForm';
import { IncidentReportForm } from '@/modules/residents/presentation/components/Forms/Licensing/IncidentReport/IncidentReportForm';
import { DeathReportLicenseeForm } from '@/modules/residents/presentation/components/Forms/Licensing/DeathReportLicensee/DeathReportLicenseeForm';
import { AppraisalNeedsAndServicesPlanPage } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/AppraisalNeedsAndServicesPlanPage';
import { EmergencyMedicalConsentForm } from '@/modules/residents/presentation/components/Forms/Licensing/EmergencyMedicalConsent/EmergencyMedicalConsentForm';

const LicensingRoute: NavigationI[] = [
  {
    key: 'lic_601_id_and_emergency_info',
    path: 'lic601_id_and_emergency_info',
    sections: {
      forms: {
        action: 'navigate',
        component: Lic601EmergencyInfoForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_603a_resident_appraisal',
    path: 'lic603a_pre_placement_appraisal',
    sections: {
      forms: {
        action: 'navigate',
        component: ResidentAppraisalForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_605a_release_of_client_resident_medical_information',
    path: 'lic605a_release_of_client_medical_info',
    sections: {
      forms: {
        action: 'navigate',
        component: ReleaseMedicalInfoForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_613c_personal_rights_of_residents_in_privately_operated_residential_care_facilities_for_the_elderly_rcfe',
    path: 'lic613c_personal_rights',
    sections: {
      forms: {
        action: 'navigate',
        component: PersonalRightsRCFEForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_621_client_resident_personal_property_and_valuables',
    path: 'lic621_property_record',
    sections: {
      forms: {
        action: 'navigate',
        component: ResidentPersonalPropertyForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_624_unusual_incident_injury_report',
    path: 'lic624_unusual_incident_injury_report',
    sections: {
      forms: {
        action: 'navigate',
        component: IncidentReportForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_624a_death_report_licensee',
    path: 'lic624a_death_report_licensee',
    sections: {
      forms: {
        action: 'navigate',
        component: DeathReportLicenseeForm,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_625_appraisal_needs_and_service_plan',
    path: 'lic625_appraisal_needs_and_service_plan',
    sections: {
      forms: {
        action: 'navigate',
        component: AppraisalNeedsAndServicesPlanPage,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_627c_consent_for_emergency_medical_treatment',
    path: 'lic627c_consent_emergency_medical_treatment',
    sections: {
      forms: {
        action: 'navigate',
        component: EmergencyMedicalConsentForm,
        openInNewTab: false,
      },
      downloads: {
        action: 'execute',
      },
    },
  },
  {
    key: 'lic_9158_telecommunications_device_notification',
    path: 'lic9158_telecommunication_device_notification',
    sections: {
      downloads: {
        action: 'execute',
      },
    },
  },
];

export default LicensingRoute;

import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '@/common/presentation/components/ui/accordion';
import { CardContent } from '@/components/ui/card';
import { CustomCard } from '../../shared/CustomCard';
import TableInputForm from './TableInputForm';

const PropertyTablesAccordion = () => {
  const { t } = useTranslation();

  return (
    <CustomCard>
       <CardContent className="p-0 flex-1">
        <Accordion type="single" collapsible>
          <AccordionItem value="valuables_entrusted">
            <AccordionTrigger>
              A. {t('residents.forms.residentPersonalProperty.personalPropertyValuablesEntrustedToFacility')}
            </AccordionTrigger>
            <AccordionContent>
              <TableInputForm name="valuables_entrusted" />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="valuables_removed">
            <AccordionTrigger>
              B. {t('residents.forms.residentPersonalProperty.personalPropertyValuablesRemoved')}
            </AccordionTrigger>
            <AccordionContent>
              <TableInputForm name="valuables_removed" />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
       </CardContent>
    </CustomCard>
  );
};

export default PropertyTablesAccordion;
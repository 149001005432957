import { useCallback } from 'react';
import { toast } from '@/components/ui/use-toast';
import { FormDataT } from '@/modules/residents/domain/models/interfaces/Forms/Licensing.domain';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { generatePDF } from '../../utils/pdfUtils';
import { preparePayload } from '../../utils/Forms/formUtils';

export const useFormSubmission = ({
  documentKey
}: {
  documentKey: string;
}) => {
  const { saveForm, saveFormAndGeneratePdf, getFormHistory, status: formsStatus } = useFormsStore();

  const handleSubmit = useCallback(
    async ({
      formData,
      residentId,
      pdf
    }: {
      formData: FormDataT;
      residentId: string ;
      pdf: boolean;
    }) => {
      try {
        const defaultResourceParamValues = {
          country: 'united_states',
          state: 'california',
          city: null,
          module: 'residents',
        };

        const payload = preparePayload({
          formData,
          residentId,
          documentKey,
          defaultResourceParamValues,
        });

        if (!pdf) {
          await saveFormAndGeneratePdf(payload);
          toast({ description: 'Form saved successfully!', variant: 'default' });
          await getFormHistory(residentId, documentKey);
          return;
        }

        const { form } = await saveFormAndGeneratePdf(payload);
        await getFormHistory(residentId, documentKey);

        if (form) {
          const base64Data = form.replace(/^data:application\/pdf;base64,/, '');
          generatePDF(base64Data);
          toast({ description: "Form saved and PDF generated!", variant: "default" });
        }
      } catch (error: any) {
        toast({
          description: `Error: ${error.message}`,
          variant: 'destructive',
        });
      }
    },
    [saveForm, getFormHistory, documentKey]
  );

  return { handleSubmit, formsStatus };
};

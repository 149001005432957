import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from '@/common/presentation/components/ui/input';
import { Textarea } from '@/common/presentation/components/ui/textarea';

export const IdentificationForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <Separator className='my-4 bg-primary'/>

      <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-10 mb-4">
        <h4 className="font-semibold text-base text-nowrap">
          a. {t('residents.forms.lic601EmergencyInfo.allFacilities')}
        </h4>
        <p className="text-sm font-semibold lg:ml-10">
          [{t('residents.forms.lic601EmergencyInfo.childCareException')}]
        </p>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-5 gap-4">
        <div className="col-span-1 sm:col-span-2 xl:col-span-1">
          <FormField
            control={control}
            name="resident.name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                1. {t('residents.forms.lic601EmergencyInfo.clientName')}
                </FormLabel>
                <FormControl className="flex-grow">
                  <Input {...field} className="w-full dark:bg-zinc-800" readOnly/>
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={control}
          name="resident.social_security_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('residents.forms.lic601EmergencyInfo.socialSecurityOptional')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" readOnly />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="resident.dob"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.dateOfBirth')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" readOnly/>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="resident.age"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.age')} 
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className=" w-full dark:bg-zinc-800" readOnly />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="resident.sex"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.sex')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" readOnly />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
        <div className="col-span-1 sm:col-span-2 xl:col-span-1">
          <FormField
            control={control}
            name="responsible_person.name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  2. {t('residents.forms.lic601EmergencyInfo.responsiblePerson')}
                </FormLabel>
                <FormControl className="flex-grow">
                  <Input {...field} className="w-full dark:bg-zinc-800" maxLength={50}/>
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={control}
          name="responsible_person.address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.address')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100}/>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="responsible_person.telephone"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.telephone')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={20} />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
        <div className="col-span-1 sm:col-span-3 xl:col-span-1">
          <FormField
            control={control}
            name="nearest_relative.name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                  3. {t('residents.forms.lic601EmergencyInfo.nameNearestRelativeOptional')}
                </FormLabel>
                <FormControl className="flex-grow">
                  <Input {...field} className="w-full dark:bg-zinc-800" maxLength={50} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={control}
          name="nearest_relative.relationship"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('residents.forms.lic601EmergencyInfo.relationship')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={30}/>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="nearest_relative.address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.address')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100}/>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="nearest_relative.telephone"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sm:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('common.telephone')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={20}/>
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <FormField
          control={control}
          name="admitted_facility.date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="md:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                4. {t('residents.forms.lic601EmergencyInfo.dateAdmittedToFacility')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" type="date" />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="admitted_facility.address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="md:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('residents.forms.lic601EmergencyInfo.addressPriorToAdmission')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100}/>
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <FormField
          control={control}
          name="date_left"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="md:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                5. {t('residents.forms.lic601EmergencyInfo.dateLeft')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" type="date" />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="forwarding_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="md:min-h-10 max-2xl:min-h-0 flex items-center font-normal">
                {t('residents.forms.lic601EmergencyInfo.forwardingAddress')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100} />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4">
        <FormField
          control={control}
          name="reasons_leaving_facility"
          render={({ field }) => (
            <FormItem className="flex flex-col md:flex-row items-start md:items-center md:gap-10">
              <FormLabel className="items-center font-normal md:text-nowrap">
                6. {t('residents.forms.lic601EmergencyInfo.reasonLeavingFacility')}
              </FormLabel>
              <FormControl className="flex-grow">
                <Textarea
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  rows={3}
                  maxLength={400}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </>
  );
};

import { useMemo, forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/common/presentation/components/ui/label';
import { InformationItem } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/InformationItem';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { ResidentI } from '@/common/domain/Residents';

const formSchema = z.object({
  referring_person_or_agency: z.string().optional(),
  needs_and_services_plan: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  defaultValues: Partial<FormValues>;
  resident: ResidentI;
}

export const FacilityInformation = forwardRef(({ defaultValues, resident }: Props, ref) => {
  const {
    address = 'Not Specified',
    name = 'Not Specified',
    licensing = 'Not Specified',
    city = '',
    state = '',
    zip = '',
  } = useMemo(() => resident?.locations?.[0] || {}, [resident]);


  const form = useForm<FormValues>({
      resolver: zodResolver(formSchema),
      mode: 'onChange',
      defaultValues,
      values: { ...defaultValues } as FormValues
    });
  
    useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        return new Promise((resolve) => {
          form.handleSubmit((data) => {
            resolve(data);
          })();
        });
      }
    }));

  const phone = useMemo(() => {
    const rootPhone = resident?.locations?.[0]?.phone?.replace(/[^\d]/g, '');

    if (rootPhone?.length === 10) {
      return `(${rootPhone.slice(0, 3)}) ${rootPhone.slice(3, 6)}-${rootPhone.slice(6)}`;
    }

    return resident?.locations?.[0]?.phone || 'Not Specified';
  }, [resident]);

  const formattedAddress = `${address}, ${city}, ${state} ${zip}`.replace(
    /, ,| ,/g, ''
  ).trim();

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">Facility Information</Label>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <InformationItem label="Name of Facility" value={name} />
        <InformationItem label="Address" value={formattedAddress || ''} />
        <InformationItem label="Facility License Number" value={licensing} />
        <InformationItem label="Telephone Number" value={phone} />
      </div>
      <div className="w-11/12 md:w-full mt-2 md:mt-0">
        <Form {...form}>
          <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="needs_and_services_plan"
                render={({ field }) => (
                  <FormItem className='mx-1'>
                    <FormLabel className="leading-relaxed md:min-h-12 max-2xl:min-h-0 flex items-center font-normal">
                      Check Type Of Needs And Services Plan
                    </FormLabel>
                    <FormControl className="flex-grow">
                      <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="referring_person_or_agency"
                render={({ field }) => (
                  <FormItem className='mx-1'>
                    <FormLabel className="leading-relaxed md:min-h-12 max-2xl:min-h-0 flex items-center font-normal">

                      Person(s) Or Agency(ies) Referring Client/Resident For Placement
                    </FormLabel>
                    <FormControl className="flex-grow">
                      <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100} />
                    </FormControl>
                  </FormItem>
                )}
              />
          </form>
        </Form>
      </div>
    </div>
  );
});

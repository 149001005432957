import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import React from 'react';

interface IProps {
  names: string;
  image?: string;
  date?: string;
  title: string;
  Button?: JSX.Element;
}

const AlertItemCard: React.FC<IProps> = ({ names, image, date, title, Button }) => {
  const { formatDate } = useDateHelpers();
  return (
    <div className="w-full mb-2">
    <Card className="w-full rounded-md p-2 sm:p-3 hover:bg-primary/5 transition-colors">
      <div className="flex justify-between gap-2">
        <div className="flex gap-2 min-w-0 flex-grow">
          <Avatar className="h-6 w-6 flex-shrink-0">
            <AvatarImage 
              src={image} 
              alt="User Image" 
              className="h-full w-full object-cover"
            />
            <AvatarFallback className="bg-secondary text-xs sm:text-sm">
              {names?.split(' ')[0]?.charAt(0)}
              {names?.split(' ')[1]?.charAt(0)}
            </AvatarFallback>
          </Avatar>

          <div className="min-w-0 flex-grow">
            <div className="flex flex-wrap gap-x-1 items-baseline">
              <p className="text-sm font-medium truncate">{names}</p>
              {date && (
                <p className="text-sm text-primary font-medium">
                  {formatDate(date, false)}
                </p>
              )}
            </div>
            <p className="font-normal text-sm break-words mt-1">{title}</p>
          </div>
        </div>

        {Button && (
          <div className="flex-shrink-0">
            {Button}
          </div>
        )}
      </div>
    </Card>
  </div>
  );
};

export default AlertItemCard;

import { useState } from 'react';
import { Loader, Languages } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { translateToEnglish } from '@/modules/notes/infrastructure/services/NoteIAService';

interface CreateNoteProps {
  text: string;
  setTranslation: (text: string) => void;
  className?: string;
}

export function NoteTranslator({ text, setTranslation, className = '' }: CreateNoteProps) {
  // Init Values
  const { t } = useTranslation();

  // Hooks
  const [isTranslating, setIsTranslating] = useState(false);

  // Behavior
  const translate = async () => {
    setIsTranslating(true);
    const result = await translateToEnglish(text);
    setTranslation(result);
    setIsTranslating(false);
  };

  return (
    <div id="translator-component" className={className}>
      {isTranslating && <Loader className="mr-2 h-4 w-4 animate-spin" />}
      <Tooltip>
        <TooltipTrigger asChild>
          <button type='button' id="btn-translate" onClick={() => translate()} className="h-5 w-5 cursor-pointer">
            <Languages />
          </button>
        </TooltipTrigger>
        <TooltipContent>{t('notes.translateToEnglish')}</TooltipContent>
      </Tooltip>
    </div>
  );
}

import { Button } from "@/common/presentation/components/ui/button";
import { Progress } from "@/common/presentation/components/ui/progress";
import UtilService from "@/common/services/UtilService";
import { cn } from "@/lib/utils";
import { RootState } from "@/store/store";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface TrackerVisitorsFormFrameProps {
    title?: string;
    description?: string;
    next?: () => void;
    back?: () => void;
    percentage?: number;
    children?: ReactNode;
    background?: string;
};

const TrackerVisitorsFormFrame = ({ title, description, next, back, percentage, children, background }: TrackerVisitorsFormFrameProps) => {
    const visitorsStore = useSelector((state: RootState) => state.visitors);
    const [locationColor, setlocationColor] = useState<string>("");
    
    useEffect(() => {
        setlocationColor(visitorsStore.validatedToken?.color);
    }, [visitorsStore.validatedToken]);

    return (
        <div 
            className={cn("w-[90vw] flex flex-col rounded-xl justify-center items-center justify-self-center self-start p-4", background ? background : null)}
            style={{ backgroundColor: locationColor && !background ? UtilService.hexToRgb(locationColor, "0.1") : "revert-layer" }}
        >
            {
                percentage ? (
                    <div className="flex flex-col justify-start items-start">
                        <Progress value={percentage} className="h-2 mb-2 bg-[#D4D4D8]" style={{ width: "60vw" }} />
                        <span className="font-semibold">{percentage}%</span>
                    </div>
                ): null
            }

            <div 
                className={cn("grid grid-cols-1 gap-x-10 justify-items-center", title && description ? "mt-8": "")}
                style={
                {
                    gridTemplateRows: "auto auto 1fr auto",
                }
            }>
                <div className="max-w-md">
                    <h1 className="text-[#3F3F46] text-center text-xl font-semibold">
                        { title }
                    </h1>

                    <p className="text-[#52525B] text-center text-sm font-normal justify-self-center pb-4">
                        { description }
                    </p>
                </div>

                <div className="w-[80vw] flex flex-col items-center">
                    <div 
                        className={cn("w-[80vw] max-w-[470px] rounded-xl p-4", background ? background : null)}
                        style={{ backgroundColor: locationColor && !background ? UtilService.hexToRgb(locationColor, "0.1") : "revert-layer" }}
                    >
                        <div className={cn("bg-white bordered-xl p-6", background ? background : null)}>
                            { children }
                        </div>
                    </div>

                    <div className={"grid " + (next && back ? "grid-cols-2" : "grid-cols-1") + " gap-x-2 w-full max-w-[470px] mt-8"}>
                        {
                            back ? (
                                <Button onClick={back} variant="outline">
                                    Back
                                </Button>
                            ) : null
                        }

                        {
                            next ? (
                                <Button onClick={next}>
                                    Continue
                                </Button>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackerVisitorsFormFrame;

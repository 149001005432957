import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
// import { Input } from '@/components/ui/input';
import { Input } from '@/common/presentation/components/ui/input';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent.tsx';

const MAX_FILE_SIZE = 3000000;

const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/webp': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'text/plain': []
};

const formSchema = z.object({
  date: z.date(),
  title: z.string(),
  code: z.string(),
  hours: z.string(),
  ceus: z.string(),
  vendor: z.string(),
  files: z.array(
    z
      .instanceof(File)
      .refine((file) => Object.keys(ACCEPTED_FILE_TYPES).includes(file.type), {
        message: 'Invalid file type.'
      })
      .refine((file) => file.size <= MAX_FILE_SIZE, {
        message: 'Max file size is 2MB.'
      })
  )
});

export type FormValues = z.infer<typeof formSchema>;

interface Props {
  status: string;
  onSubmit: (data: FormValues) => void;
  defaultValues?: Partial<FormValues>;
}

export const UploadCertificateCeusForm = ({ status, onSubmit, defaultValues }: Props) => {
  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
    mode: 'onChange'
  });

  const handleSubmit = (data: FormValues) => {
    onSubmit({ ...data });
    form.reset();
  };

  return (
    <>
      <ScrollArea className="h-[500px] w-auto overflow-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full">
                  <FormLabel>Date</FormLabel>
                  <FormControl >
                    <CalendarComponent
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <Input {...field} type="text" disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code</FormLabel>
                  <Input {...field} type="text" disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hours"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Hours</FormLabel>
                  <FormControl>
                    <Input {...field} type="number" disabled={status === 'loading'} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="ceus"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ceus</FormLabel>
                  <Input {...field} type="text" disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="vendor"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vendor</FormLabel>
                  <Input {...field} type="text" disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="files"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    File <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <MultiFileDropzone
                      className="w-auto outline-none min-h-[130px] min-w-[200px]"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={status === 'loading'}
                      dropzoneOptions={{
                        accept: ACCEPTED_FILE_TYPES,
                        maxSize: MAX_FILE_SIZE,
                        maxFiles: 1
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="col-span-full w-full"
              disabled={!form.formState.isValid || status === 'loading'}
            >
              {status === 'loading' && <Loader className="size-4 animate-spin mr-2" />}
              Save Document
            </Button>
          </form>
        </Form>
      </ScrollArea>
    </>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateClient } from '../../domain/client.domain';
import ClientService from '../../infrastructure/ClientService';
import { updateClientsMenus } from '@/common/presentation/slices/menuSlice';

const initialState: IStateClient = {
  clients: [],
  client: [],
  status: 'loading',
  error: null
};

export const fetchAllClients = createAsyncThunk('client/listClients', async () => {
  try {
    const response = await ClientService.getClients();
    return response.data;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});

export const fetchPaginateClients = createAsyncThunk('client/list-paginate-clients', async (params: { search: string; per_page: number; page: number }) => {
  try {
    const response = await ClientService.getPaginateClients(
      params.search,
      params.per_page,
      params.page
    );
    return response;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});

export const getClient = createAsyncThunk('client/get-client', async () => {
  try {
    const response = await ClientService.getClient();
    return response.client;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});

export const getClientByGivenId = createAsyncThunk('client/get-client-id', async (id: string) => {
  try {
    const response = await ClientService.getClientByGivenId(id);
    return response.client;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});


export const updateClient = createAsyncThunk('client/update-client', async (data: any) => {
  try {
    const response = await ClientService.updateClient(data);
    return response.client;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(fetchAllClients.pending, (state) => {
        state.status = 'loading';
        state.clients = [];
      })
      .addCase(fetchPaginateClients.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(fetchPaginateClients.pending, (state) => {
        state.status = 'loading';
        state.clients = [];
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.client = action.payload;
      })
      .addCase(getClient.pending, (state) => {
        state.status = 'loading';
        state.client = [];
      })
      .addCase(getClientByGivenId.fulfilled, (state, action) => {
        state.status = 'idle';
        state.client = action.payload;
      })
      .addCase(getClientByGivenId.pending, (state) => {
        state.status = 'loading';
        state.client = [];
      })
      .addCase(updateClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.client = action.payload;
        state.error = null;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export default clientSlice.reducer;
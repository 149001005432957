import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import QuickNotes from "../infrastructure/services/QuickNotes";

interface IPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  hasMorePages: boolean
}

interface IQuickNote {
  id: string;
  name: string;
  description: string;
  category: string;
  status: boolean;
}

interface INoteCategory {
  id: string;
  category: string;
}

interface IQuickNotesData {
  items: IQuickNote[];
  pagination: IPagination;
}

export interface IQuickNotesState {
  quickNotes: IQuickNotesData;
  isLoading: boolean;
  noteCategories: INoteCategory[];
  error: string | null;
}

const initialState: IQuickNotesState = {
  quickNotes: {
    items: [],
    pagination: {
      total: 0,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      hasMorePages: false
    }
  },
  noteCategories: [],
  isLoading: false,
  error: null
};


export const getNoteCategories = createAsyncThunk('quickNotes/getNoteCategories',
 async () => {
    const response = await QuickNotes.getNoteCategories();
    return response;
  }
);
export const getQuickNotes = createAsyncThunk('quickNotes/getQuickNotes',
 async (formData:any) => {
    const response = await QuickNotes.getQuickNotes(formData);
    return response;
  }
);

export const saveOrUpdateQuickNote = createAsyncThunk('quickNotes/saveOrUpdateQuickNote',
  async (formData: any ) => {
     const response = await QuickNotes.saveOrUpdateQuickNote(formData);
     return response;
   }
 );

export const deleteQuickNote = createAsyncThunk('quickNotes/deleteQuickNote',
  async (id: string ) => {
     const response = await QuickNotes.deleteQuickNote(id);
     return response;
   }
 );

const QuickNoteslice = createSlice({
  name: 'quickNotes',
  initialState,
  reducers: {
    quickNotesLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getNoteCategories.fulfilled, (state, action) => {
      state.noteCategories = action.payload;
      state.isLoading = false;
    })
    .addCase(getQuickNotes.fulfilled, (state, action) => {
      state.quickNotes = action.payload;
      state.isLoading = false;
    })
    .addCase(deleteQuickNote.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    .addCase(saveOrUpdateQuickNote.fulfilled, (state) => {
      state.isLoading = false;
    });
  }
});

export const { quickNotesLoading } = QuickNoteslice.actions;
export default QuickNoteslice.reducer;
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { GetPicklist } from '@/modules/residents/infrastructure/services/ResidentsService';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, parseISO, startOfDay } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { updateResidenceLevelOfCare } from '../../slices/residentsForm';
import { AppDispatch } from '@/store/store';
import { useDispatch } from 'react-redux';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { Separator } from '@/common/presentation/components/ui/separator';
import { useTranslation } from 'react-i18next';
import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

interface Props {
  className?: string;
}

export const getFormSchema = () => {
  const { t } = useTranslation();
  return z.object({
    admission_date: z.date({
      required_error: t('residents.levelOfCare.admissionDateRequired')
    }),
    original_move_in_date: z.date({
      required_error: t('residents.levelOfCare.moveInDateRequired')
    }),
    current_resident_house: z.string({
      required_error: t('residents.levelOfCare.currentHouse')
    }),
    resident_room: z.string(),
    level_of_care: z.string(),
    address_of_prior_to_admision: z.string().optional(),
    date_of_left_prior_facility: z.date().optional(),
    reason_for_leaving_prior_facility: z.string().optional()
  });
};

type FormValues = z.infer<ReturnType<typeof getFormSchema>>;

const defaultValues: Partial<FormValues> = {
  admission_date: undefined,
  original_move_in_date: undefined,
  current_resident_house: undefined,
  resident_room: '',
  level_of_care: '',
  address_of_prior_to_admision: '',
  date_of_left_prior_facility: undefined,
  reason_for_leaving_prior_facility: ''
};

export const LevelOfCareForm = ({ className }: Props) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [levelOfCareOptions, setLevelOfCareOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const { formatDate } = useDateHelpers();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const [locationsResidents, setLocationsResidents]: any = useState({
    ...defaultValues
  });
  const { t } = useTranslation();
  const formSchema = getFormSchema();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    values: { ...locationsResidents }
  });
  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = async (data: FormValues) => {
    const formData: any = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) {
        formData[key] = null;
      } else {
        formData[key] = value;
      }
    });

    formData.resident_id = resident.id;

    try {
      const response = await dispatch(updateResidenceLevelOfCare(formData));

      toast({
        description: t('residents.levelOfCare.dataUpdated'),
        className: 'bg-green-500 text-white'
      });

    } catch (error) {
      console.error("Error al actualizar los datos:", error);

    }

  };

  const getPicklist = async () => {
    try {
      const locationId = resident.locations[0].id
      const { data }: any = await GetPicklist(locationId);
      const { levelOfCarePickList, locations, rooms } = data;

      function compareLevels(a: any, b: any) {
        if (a.name === 'Full Care ') return 1;
        if (b.name === 'Full Care ') return -1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      levelOfCarePickList.sort(compareLevels);

      const locationOptions = locations.map((location: any) => ({
        label: location.location.name,
        value: location.location_id
      }));

      const levelOfCareOptions = levelOfCarePickList.map((levelOfCare: any) => ({
        label: levelOfCare.name,
        value: levelOfCare.id
      }));

      const roomOptions = rooms?.map((roomEntry: any) => ({
        label: roomEntry.room.name,
        value: roomEntry.room.id,
      }));

      setLocationOptions(locationOptions);
      setLevelOfCareOptions(levelOfCareOptions);
      setRoomOptions(roomOptions);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);

  useEffect(() => {
    setLocationsResidents({});
    form.reset(defaultValues);

    if (resident) {
      const locationResident = Array.isArray(resident.transfers) && resident.transfers.length > 0
        ? resident.transfers[0]
        : undefined;
      const roomResident = Array.isArray(resident.rooms) && resident.rooms.length > 0
        ? resident.rooms[0]
        : '';
      const residentLocation = resident.locations[0] ?? '';

      if (locationOptions.length > 0 && levelOfCareOptions.length > 0) {
        const updatedValues = {
          admission_date: resident?.other_information?.admission_date
            ? startOfDay(parseISO(resident?.other_information?.admission_date))
            : undefined,
          original_move_in_date: locationResident?.transfer_date
            ? parseISO(locationResident?.transfer_date)
            : undefined,
          current_resident_house: locationResident?.destination_location_id || residentLocation.id,
          resident_room: roomResident.room_id || '',
          level_of_care: resident.level_of_care_id || '',
          address_of_prior_to_admision: resident?.other_information?.address_of_prior_to_admision || '',
          date_of_left_prior_facility: resident?.other_information?.date_of_left_prior_facility
            ? startOfDay(parseISO(resident?.other_information?.date_of_left_prior_facility))
            : undefined,
          reason_for_leaving_prior_facility: resident?.other_information?.reason_for_leaving_prior_facility || ''
        };

        setLocationsResidents(updatedValues);
        form.reset(updatedValues);
      }
    }
  }, [resident, levelOfCareOptions, locationOptions]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title={t('residents.levelOfCare.residenceLevelCare')} defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div>
                    <FormField
                      control={form.control}
                      name="admission_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('residents.levelOfCare.admissionDate')} <span className="text-destructive">(*)</span>
                          </FormLabel>
                          <CalendarComponent
                            value={field.value}
                            onChange={field.onChange}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="original_move_in_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('residents.levelOfCare.originalMoveInDate')} <span className="text-destructive">(*)</span>
                          </FormLabel>
                          <CalendarComponent
                            value={field.value}
                            onChange={field.onChange}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="current_resident_house"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>
                              {t('residents.levelOfCare.currentResidentHouse')} <span className="text-destructive">(*)</span>
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                              }}
                              value={field.value}
                            >
                              <FormControl>
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder={t('residents.selectLocation')} />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {locationOptions.map((option) => (
                                  <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="resident_room"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('residents.levelOfCare.residentRoom')}
                          </FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder={t('residents.selectRoom')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {roomOptions?.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="level_of_care"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('residents.levelOfCare.levelOfCare')}
                          </FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder={t('residents.selectLocation')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {levelOfCareOptions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="address_of_prior_to_admision"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.levelOfCare.addressPriorToAdmission')}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="date_of_left_prior_facility"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.levelOfCare.dateLeftPriorFacility')}</FormLabel>
                          <CalendarComponent
                            value={field.value}
                            onChange={field.onChange}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="reason_for_leaving_prior_facility"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('residents.levelOfCare.reasonsForLeavingPriorFacility')}</FormLabel>
                          <FormControl>
                            <Textarea className="min-h-[40px] h-[40px]" placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className='mt-4'>
                  <Separator className="col-span-full" />
                  <Button className="w-64 mt-4" type="submit">{t('common.update')}</Button>
                </div>
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

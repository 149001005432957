import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';


import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormValues,
  UploadCertificateForm
} from '@/modules/staff/presentation/components/forms/UploadCertificateForm.tsx';
import { useUploadCertificate } from '@/modules/staff/infrastructure/hooks/training/useUploadCertificate.ts';
import React from 'react';
import { createTrainingOnSite } from '@/modules/staff/presentation/slices/staffMemberFormSlice.ts';
import { UploadCertificateCeusForm } from '@/modules/staff/presentation/components/forms/UploadCertificateCeusForm.tsx';
import {
  UploadCertificateShawdowingForm
} from '@/modules/staff/presentation/components/forms/UploadCertificateShawdowingForm.tsx';

interface IProps {
  staffId: string;
  type: string;
}
interface IFormConfig {
  [key: string]: {
    component: (props: any) => JSX.Element;
    defaultValues:{
      date: string | undefined;
      title?: string,
      code?: string,
      hours: string,
      ceus?:string,
      description?: string,
      vendor?: string,
      files?: File[] | undefined,
    }
  };
}
const formConfig: IFormConfig = {
  CEUs: {
    component: (props: any) => <UploadCertificateCeusForm {...props} />,
    defaultValues: {
      date: undefined,
      title: '',
      code: '',
      hours: '0',
      ceus:'',
      vendor: '',
      files: undefined,
    },
  },
  Shadowing: {
    component: (props: any) => <UploadCertificateShawdowingForm {...props} />,
    defaultValues: {
      date: undefined,
      description: '',
      hours: '0',
    },
  },

  default: {
    component: (props: any) => <UploadCertificateForm {...props} />,
    defaultValues: {
      date: undefined,
      description: '',
      hours: '0',
      files: undefined,
    },
  },
};

export const UploadCertificateInServiceDialog: React.FC<IProps> = ({ staffId, type }) => {
  const { isOpen, onClose, data: documentData, setOnUpdate } = useUploadCertificate();

  const { status } = useSelector((state: RootState) => state.residents.resident);

  const dispatch = useDispatch<AppDispatch>();

  const saveDocument = async (values: FormValues) => {
    const { files, ...rest } = values;

    const data = {
      ...rest,
      category: documentData?.category,
      type: documentData?.type,
      user_id: staffId ?? '',
    };

    const stringifyData = JSON.stringify(data);

    const payload = {
      files: files,
      data: stringifyData
    };

    const response = await dispatch(createTrainingOnSite(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      setOnUpdate(documentData?.category ?? null);
      onClose();
    }
  };

  const { component: FormComponent, defaultValues } = formConfig[type] || formConfig.default;


  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[500px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle className="text-xl">
              Upload Certificate
            </DialogTitle>
          </DialogHeader>
          {FormComponent({
            onSubmit: saveDocument,
            status,
            defaultValues,
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

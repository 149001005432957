import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleFadingPlus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { getUnpaginatedCategories } from '@/modules/settings/presentation/components/menus/Locations/LocationDocumentCategories/slices/LocationDocumentsCategory';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { LocationCategoryCard } from './LocationCategoryCard';
import { DocumentCategory } from '@/modules/residents/infrastructure/types/documents';
import { useTranslation } from 'react-i18next';
import { useNewCategory } from '@/modules/residents/infrastructure/hooks/documents/useNewCategory';

interface facilityInformationI {
    isOpen: boolean;
    onClose: () => void;
    locationId: string;
    location?: any
}

export const LocationDocumentsDialog = ({ isOpen, onClose, locationId, location }: facilityInformationI) => {
    const dispatch = useDispatch<AppDispatch>();
    const [documentCategories, setDocumentCategories] = useState<DocumentCategory[]>([]);
    const { t } = useTranslation();

    const dataDocumentsCategory = async () => {
        try {
            const response = await dispatch(getUnpaginatedCategories());
            setDocumentCategories(response.payload.categories || []);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };


    useEffect(() => {
        dataDocumentsCategory();
    }, []);

    return (
        <CustomDialog
            newDialog={true}
            title={t('facilities.documents')}
            isOpen={isOpen}
            onClose={onClose}
            className="md:max-w-[1300x] lg:max-w-[1300px]"
        >
            <div>
                {documentCategories.length > 0 && (
                    <div className="flex flex-col gap-4">
                        {documentCategories
                            .filter(category => category.status === 'active')
                            .map((category) => (
                                <LocationCategoryCard
                                    key={category.value}
                                    locationCategories={category}
                                    locationId={locationId}
                                    location={location}
                                />
                            ))}
                    </div>
                )}
            </div>
        </CustomDialog>
    )
}

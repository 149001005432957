interface InfoRowProps {
  label: string;
  value: string;
  highlight?: boolean;
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value }) => (
  <div
    className="grid grid-cols-2 items-center gap-4 rounded-md bg-zinc-100 dark:bg-zinc-900
                px-4 py-1.5 border border-gray-200 dark:border-zinc-700"
  >
    <span className="text-xs text-zinc-600 dark:text-zinc-50 font-semibold">{label}</span>
    <span className="text-xs text-zinc-600 dark:text-zinc-50 font-normal text-start break-words">
      {value}
    </span>
  </div>
);

export default InfoRow;
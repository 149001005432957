import { NotesStructureI } from '@/modules/notes/domain/note.domain';

export interface InitialStateI {
  urgentNotes: NotesStructureI;
  messagesForYou: NotesStructureI;
  notes: NotesStructureI;
  residentAlerts: AlertStateI;
  residentCustomAlerts: ResidentCustomAlertStateI;
  administrationAlerts: AlertStateI;
  userCustomAlerts: UserCustomAlertStateI;
  userLicenseCustomAlerts: UserCustomAlertStateI,
  residentReportsCustomAlerts: ResidentCustomAlertStateI;
  medicationAlerts: {
    status: 'idle' | 'loading' | 'fail';
    data: MedicationAlertStateI[] | null;
  };
  medicationDispensingAlerts: {
    status: 'idle' | 'loading' | 'fail';
    data: MedicationDispensingAlertI | null;
  };
}

export interface MedicationDispensingAlertI {
  [residentId: string]: {
    resident_id: string;
    medications: MedicationDispensingDataI[];
  };
}

export interface DateRangeI {
  from?: string;
  to?: string;
}

export interface MedicationDispensingDataI {
  medication_id: string;
  medication_name: string;
  not_given_dates: NotGivenDatesI[];
}

export interface NotGivenDatesI {
  date: string;
  times: string[];
}

export interface UserCustomAlertStateI {
  status: 'idle' | 'loading' | 'fail';
  data: CustomAlertI[];
}

export interface ResidentCustomAlertStateI {
  status: 'idle' | 'loading' | 'fail';
  data: CustomAlertI[];
}

export interface CustomAlertI {
  id: string;
  title: string;
  description: string;
  residents: ResidentI[];
  users: UserI[];
  date?: string;
  start_date?: string;
}

export interface UserI {
  id: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

export interface ResidentI {
  id: string;
  first_name: string;
  last_name: string;
}

export interface AlertStateI {
  status: 'idle' | 'loading' | 'fail';
  data: AlertsResponseI[];
}

export interface MedicationAlertStateI {
  message: string;
  resident_id: string;
  date: string;
  medication_info: MedicationI;
  expiration_date: string | null;
  prn_info?: {
    created_at: string;
  }
}

export interface MedicationI {
  id: string;
  name: string;
  purpose?: string | null;
  not_exceed?: string | null;
  frequency?: string | null;
  instruction: string;
  special_instructions?: string | null;
  date_prescribed: string;
  current_quantity: number;
  minimum_quantity?: number | null;
  units_per_dose?: number | null;
  rx: string;
  chart: ChartI[];
  status: 'active' | 'inactive';
  refills: string;
  filleDate: string;
  qtyReceived: string;
  cycle_end_date?: string | null;
  expiration_date: string;
  cycle_start_date: string;
  medication_id: string;
  location_type_id: string;
  responsible_administrations_id?: string | null;
  routes_medication_id: string;
  pharmacy_id: string;
  note_id?: string | null;
  resident_id: string;
  form: string;
  strength: string;
  generic_name: string;
  created_at: string;
  updated_at: string;
  type: string;
}

interface ChartI {
  rx: string;
  status: 'active' | 'inactive';
  refills: string;
  filleDate: string;
  qtyReceived: string;
  cycle_end_date?: string | null;
  expiration_date: string;
  cycle_start_date: string;
}

export interface AlertsResponseI {
  alert_key: string;
  date: string;
  evaluator: number;
  resident_id: string;
  value: number|string;
}

export enum HealthStatus {
  good = 'good',
  poor = 'poor',
  fair = 'fair'
}

export type HealthStatusI = HealthStatus.good | HealthStatus.poor | HealthStatus.fair;

export interface HiddenAlertsI {
  [key: string]: string[];
};
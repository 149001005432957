import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import TrackerVisitorsFormFrame from "./TrackerVisitorsFormFrame";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import TrackerVisitorsResidentInterface from "@/modules/tracker/infrastructure/interfaces/Visitors/TrackerVisitorsResidentInterface";
import { useToast } from "@/common/presentation/components/ui/use-toast";

interface TrackerVisitorsResidentsProps {
    progress: number;
    setProgress: (prev: SetStateAction<number>) => void;
    setMode: (newValue: string) => void;
    setResident: (newValue: SetStateAction<TrackerVisitorsResidentInterface | null>) => void;
    totalPerQuestion: number;
};

const TrackerVisitorsResidents = ({ progress, setProgress, setMode, setResident, totalPerQuestion }: TrackerVisitorsResidentsProps) => {
    const { params } = useRouteParams();
    const { toast } = useToast();
    const { residents } = useSelector((state: RootState) => state.residents.allResidents);
    const visitorsStore = useSelector((state: RootState) => state.visitors);
    const [locationId, setLocationId] = useState<string>("");

    useEffect(() => {
        setLocationId(visitorsStore.validatedToken?.location_id);
    }, [visitorsStore.validatedToken]);

    const aliasNext = () => {
        if(params.r) {
            if(params.r === "all-residents") {
                setResident(
                    {
                        id: "all-residents",
                        first_name: "General",
                        last_name: "Visit"
                    }
                );
            } else {
                setResident(residents.filter((resident) => resident.id === params.r)[0]);
            }

            setProgress((prev) => prev + totalPerQuestion);
            setMode("questions");
        } else {
            toast(
                {
                    description: "Resident is required"
                }
            );
        }
    }

    return (
        <>
            {
                locationId ? (
                    <TrackerVisitorsFormFrame 
                        title="Who will be visited?" 
                        description="Select the person you're visiting. If you're staff or a vendor, click General Visit."
                        next={aliasNext} 
                        percentage={progress}
                    >
                        <ResidentSelector
                            residentId={params.r}
                            locationId={locationId}
                            showAllResidents={false}
                            showGeneralVisit={true}
                            showInCard={false}
                            vertical={true} 
                            isPublic={true}
                        />
                    </TrackerVisitorsFormFrame>
                ): null
            }
        </>
    );
};

export default TrackerVisitorsResidents;

import { Input } from "@/common/presentation/components/ui/input";
import TrackerVisitorsFormFrame from "./TrackerVisitorsFormFrame";
import { SetStateAction, useState } from "react";
import { useToast } from "@/common/presentation/components/ui/use-toast";

interface TrackerVisitorsAliasProps {
    progress: number;
    setProgress: (prev: SetStateAction<number>) => void;
    setMode: (newValue: string) => void;
    setVisitor: (newValue: SetStateAction<string>) => void;
    totalPerQuestion: number;
};

const TrackerVisitorsAlias = ({ progress, setProgress, setMode, setVisitor, totalPerQuestion }: TrackerVisitorsAliasProps) => {
    const [name, setName] = useState<string>("");
    const { toast } = useToast();
    
    const aliasNext = () => {
        if(name) {
            setVisitor(name);
            setMode("resident");

            console.log("progress", progress);
            console.log("totalPerQuestion", totalPerQuestion);

            setProgress((prev) => prev + totalPerQuestion);
        } else {
            toast(
                {
                    description: "Name is required"
                }
            );
        }
    }

    return (
        <TrackerVisitorsFormFrame 
            title="Welcome! Start Your Visit" 
            description="Please enter your name and answer a few questions to proceed with your visit to the house."
            next={aliasNext} 
            percentage={progress}
        >
            <>
                <span>Full Name</span>
                <Input value={name} onChange={(event) => setName(event?.target.value)}></Input>
            </>
        </TrackerVisitorsFormFrame>
    );
};

export default TrackerVisitorsAlias;
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Minus, Plus } from 'lucide-react';

interface KeepInEyeProps {
  observedRef?: React.RefObject<HTMLDivElement>;
  text: string;
  className?: string;
  style?: React.CSSProperties;
  whileHover?: any;
  image?: string;
  showText?: boolean;
  showAlways?: boolean;
}

interface StoredPosition {
  x: number;
  y: number;
  timestamp: number;
}

const STORAGE_KEY = 'keepInEyePosition';
const EXPIRATION_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

const KeepInEye: React.FC<KeepInEyeProps> = React.memo(
  ({ observedRef, text, className, style, whileHover, image, showText = true, showAlways = false }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const localRef = useRef<HTMLDivElement | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [imageError, setImageError] = useState<boolean>(false);
    const [position, setPosition] = useState(() => {
      const storedData = localStorage.getItem(STORAGE_KEY);
      if (storedData) {
        const stored: StoredPosition = JSON.parse(storedData);
        const now = Date.now();
        if (now - stored.timestamp < EXPIRATION_TIME) {
          return { x: stored.x, y: stored.y };
        } else {
          localStorage.removeItem(STORAGE_KEY);
        }
      }
      return { x: 0, y: 0 };
    });
    const [isMinimized, setIsMinimized] = useState(false);

    const handleImageError = useCallback(() => {
      setImageError(true);
    }, []);

    useEffect(() => {
      setImageError(false);
    }, [text]);

    const handleIntersection = useCallback(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        setIsVisible(showAlways || !entry.isIntersecting);
      },
      [showAlways]
    );

    const observerOptions = useMemo(
      () => ({
        root: null,
        threshold: 0.1
      }),
      []
    );

    const setupObserver = useCallback(() => {
      if (typeof IntersectionObserver === 'undefined') {
        return;
      }

      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);

      const target = observedRef?.current ?? localRef.current;

      if (target) {
        observerRef.current.observe(target);
      }
    }, [handleIntersection, observedRef, observerOptions]);

    useEffect(() => {
      const storedData: StoredPosition = {
        x: position.x,
        y: position.y,
        timestamp: Date.now()
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storedData));
    }, [position]);

    useEffect(() => {
      setupObserver();

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, [setupObserver]);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' || showAlways) {
          setTimeout(setupObserver, 0);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [setupObserver, showAlways]);

    const contentProps = useMemo(() => ({ text, image }), [text, image]);

    return (
      <div ref={localRef}>
        <motion.div
          drag="y"
          dragConstraints={{
            top: 0,
            bottom: window.innerHeight - 200
          }}
          onDragEnd={(_, info) => {
            const newY = position.y + info.offset.y;
            // Aplicamos los límites al nuevo valor de Y
            const constrainedY = Math.max(0, Math.min(newY, window.innerHeight - 200));
            setPosition({
              x: 0,
              y: constrainedY
            });
          }}
          dragElastic={0.2}
          dragMomentum={false}
          whileDrag={{ scale: 1.02 }}
          initial={false}
          animate={{
            opacity: isVisible ? 1 : 0,
            y: position.y,
            x: position.x,
            pointerEvents: isVisible ? 'auto' : 'none'
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className={`
          fixed top-14 right-5 flex flex-col items-center border-2 border-primary rounded-lg 
          bg-white hover:bg-zinc-50 hover:!text-primary dark:bg-zinc-800 cursor-move 
          ${className}
        `}
          style={{
            ...style,
            visibility: isVisible ? 'visible' : 'hidden'
          }}
          whileHover={{ scale: 0.98 }}
        >
          {contentProps.image && contentProps.image.length > 0 && !imageError && (
            <button
              onClick={() => setIsMinimized(!isMinimized)}
              className="h-4 w-4 absolute -top-2 -right-2 bg-white dark:bg-zinc-950 dark:text-white text-black rounded-full 
                       transition-colors border border-black dark:border-white
                       flex items-center justify-center p-0"
            >
              {isMinimized ? <Plus size={10} className="relative" /> : <Minus size={10} className="relative" />}
            </button>
          )}
          {/* Card Content */}
          {!isMinimized ? (
            contentProps.image && contentProps.image.length > 0 && !imageError ? (
              <div className="flex flex-col items-center gap-1 p-1">
                <img
                  draggable={false}
                  className="h-10 w-10 object-cover rounded-full select-none"
                  src={contentProps.image}
                  onError={handleImageError}
                />
                <span className="text-xs text-center justify-center text-black dark:text-white">
                  <div className="font-semibold">{contentProps.text.split(' ')[0]}</div>
                  <div>{contentProps.text.split(' ')[1]}</div>
                </span>
              </div>
            ) : (
              <div className="text-xs text-black dark:text-white p-2 justify-center text-center">
                <div className="font-semibold">{contentProps.text.split(' ')[0]}</div>
                <div>{contentProps.text.split(' ')[1]}</div>
              </div>
            )
          ) : (
            <div className="text-xs text-black dark:text-white p-1 justify-center text-center">
              <div className="font-semibold">{contentProps.text.split(' ')[0]}</div>
              <div>{contentProps.text.split(' ')[1]}</div>
            </div>
          )}
        </motion.div>
      </div>
    );
  }
);

export default KeepInEye;

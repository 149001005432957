import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';

import { useFetchAllContacts } from '@/modules/residents/infrastructure/hooks/forms/useFetchAllContacts';
import LicensingRoute from '@/modules/residents/infrastructure/routes/Forms/LicensingRoute';
import { getContactsByResident } from '@/modules/contacts/presentation/slices/contactsSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import {
  Accordion
} from '@/common/presentation/components/ui/accordion';
import { Card, CardContent} from '@/components/ui/card';
import { FormsSection } from './components/FormsSection';
import { DownloadsSection } from './components/DownloadsSection';
import { StyledAccordionItem } from './components/StyledAccordionItem';

export const LicensingForms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();

  const FormComponents = LicensingRoute.filter((item) => item.sections?.forms);
  const downloadComponents = LicensingRoute.filter((item) => item.sections?.downloads);
  const { resident } = useSelector((state: RootState) => state.residents.resident || {});

  const { contacts } = useFetchAllContacts(params.r, getContactsByResident);

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  return (
    <div className="flex flex-col mt-6">
      <Card
        className="w-full p-6 border-t-4 border-t-primary/80 dark:bg-zinc-950"
      >
        <CardContent className='p-0 flex-1'>
          <Accordion type="single" collapsible>
            <StyledAccordionItem title={t('residents.forms.viewPdf')} value="primary">
              <DownloadsSection
                downloads={downloadComponents}
                resident={resident}
                contacts={contacts}
              />
            </StyledAccordionItem>
            <StyledAccordionItem title={t('residents.forms.fillableForms')} value="second">
            <FormsSection forms={FormComponents} />
            </StyledAccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
};

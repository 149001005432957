import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import React, { useEffect, useState } from 'react';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso.tsx';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard.tsx';
import { ShadowingForm } from '@/modules/staff/presentation/components/forms/ShadowingForm.tsx';
import { InServiceForm } from '@/modules/staff/presentation/components/forms/InServiceForm.tsx';
import { CEUsForm } from '@/modules/staff/presentation/components/forms/CEUsForm.tsx';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store.ts';
import { fetchDigitalTraining } from '@/modules/staff/presentation/slices/staffMemberFormSlice.ts';
import { DigitalLearningI } from '@/modules/staff/domain/Training.ts';
import { Button } from '@/common/presentation/components/ui/button.tsx';
import { File as FileIconLucide } from 'lucide-react';
import { cn } from '@/lib/utils.ts';

interface IProps {
  staffId: string;
}

const StaffTrainingPage: React.FC<IProps> = ({ staffId }) => {
  const [DigitalTrainingData, setDigitalTrainingData] = useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getDigitalTraining();
  }, []);

  const columnsCetificateTable = [
    {
      key: 'certificate',
      id: 'certificate',
      labelTranslationKey: 'Certificate',
      value: 'certificate',
      visible: true,
      static: true,
      sortable: false,
      width: '10px',
      render: (row: any) => renderActionsColumn(row)
    },
    {
      key: 'course',
      id: 'course',
      labelTranslationKey: 'Course',
      value: 'course',
      visible: true,
      static: true,
      sortable: true,
      width: '500px'
    },
    {
      key: 'hours',
      id: 'hours',
      labelTranslationKey: 'Hours',
      value: 'hours',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'progress',
      id: 'progress',
      labelTranslationKey: 'Progress',
      value: 'progress',
      visible: true,
      static: true,
      sortable: true
    },
  ];

  const columnsSummarytable = [
    {
      key: 'course',
      id: 'course',
      labelTranslationKey: '',
      value: '',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'digital_learning',
      id: 'digital_learning',
      labelTranslationKey: 'DIGITAL LEARNING',
      value: 'Digital learning',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'shadowing',
      id: 'shadowing',
      labelTranslationKey: 'Shadowing',
      value: 'shadowing',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'in-service',
      id: 'in-service',
      labelTranslationKey: 'In-Service',
      value: 'In-service',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'CEUs',
      id: 'CEUs',
      labelTranslationKey: 'CEUs',
      value: 'CEUs',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'total_hours',
      id: 'total_hours',
      labelTranslationKey: 'Total Hours',
      value: 'Total Hours',
      visible: true,
      static: true,
      sortable: true
    },
  ];

  const handleViewDocument = (file:any) => {
    window.open(file?.[file.length - 1]?.url, '_blank', 'noopener,noreferrer');
  };

  const renderActionsColumn = (row: any) => (
      <div className="flex gap-2 justify-center">
        <Button
          size={'icon'}
          className={cn(
            row.certificate !== null
              ? 'border-green-500  text-green-500 hover:bg-green-500 hover:text-white'
              : 'border-red-500  text-red-500 hover:bg-red-500 hover:text-white'
          )}
          variant={'outline'}
          onClick={() => handleViewDocument(row.certificate)}
          disabled={row?.certificate === null}
        >
          <FileIconLucide className="size-4" />
        </Button>
      </div>

  );

  const getDigitalTraining = async () => {
    const response = await dispatch(fetchDigitalTraining(staffId)) ;
    const digital = response.payload
    formatDataDigital(digital)
  }

  const formatDataDigital = (DigitalList: DigitalLearningI[]) => {
    const formatedData = DigitalList.map((digital) => ({
      id: digital.id,
      course: digital.course.name,
      certificate: digital.certificate,
      hours: digital.course.duration,
    }));
    setDigitalTrainingData(formatedData)
  };

  return (
    <>

      <Tabs defaultValue="Digital Learning" className="w-full">
        <TabsList>
          <TabsTrigger
            value="Digital Learning"
            className="data-[state=active]:bg-primary data-[state=active]:text-white">
            Digital Learning
          </TabsTrigger>
          <TabsTrigger
            value="Shadowing"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            Shadowing
          </TabsTrigger>
          <TabsTrigger
            value="In-service"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            In-service
          </TabsTrigger>
          <TabsTrigger
            value="CEUs"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            CEUs
          </TabsTrigger>
{/*          <TabsTrigger
            value="Custom certificates"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            Custom certificates
          </TabsTrigger>*/}
          <TabsTrigger
            value="Summary"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            Summary
          </TabsTrigger>
        </TabsList>
        <TabsContent value="Digital Learning">
          <CustomCard title="Digital learning" description=" ">
            <TableCustomVirtuoso
              tableId={'1'}
              showSearchInput={true}
              columns={columnsCetificateTable}
              data={DigitalTrainingData}
              isLoading={false}
              renderCellContent={(index: number, column: any) =>
                column.render ? column.render(DigitalTrainingData[index]) : DigitalTrainingData[index][column.key]
              }
              additionalButtons={<></>}
              onSort= {() =>{}}
              onFilterChange={() =>{}}
              loadMore={() =>{}}
              hasMore={false}
            />
          </CustomCard>
        </TabsContent>
        <TabsContent value="Shadowing">
          <CustomCard title="Shadowing" description=" ">
            <ShadowingForm
              staffId={staffId}
            />
          </CustomCard>
        </TabsContent>
        <TabsContent value="In-service">
          <CustomCard title="In-service" description=" ">
            <InServiceForm
              staffId = {staffId}
            />
          </CustomCard>
        </TabsContent>
        <TabsContent value="CEUs">
          <CustomCard title="CEUs" description=" ">
            <CEUsForm
              staffId = {staffId}/>
          </CustomCard>
        </TabsContent>
{/*        <TabsContent value="Custom certificates">
          <CustomCard title="Custom certificates" description=" ">
            <div></div>
          </CustomCard>
        </TabsContent>*/}
        <TabsContent value="Summary">
          <CustomCard title="Summary" description=" ">
            <TableCustomVirtuoso
              tableId={'2'}
              showSearchInput={true}
              columns={columnsSummarytable}
              data={[]}
              isLoading={false}
              renderCellContent={(index, column, data) => data[index][column.key]}
              additionalButtons={<></>}
              onSort= {() =>{}}
              onFilterChange={() =>{}}
              loadMore={() =>{}}
              hasMore={false}
            />
          </CustomCard>
        </TabsContent>

      </Tabs>


    </>

  );
};

export default StaffTrainingPage;

import React from 'react';
import { Card, CardContent } from '@/components/ui/card';

interface CustomCardProps {
  children: React.ReactNode;
  className?: string;
}

const CustomCard: React.FC<CustomCardProps> = ({ children, className = '' }) => {
  return (
    <Card
      className={`flex flex-col px-6 py-5 w-full border-t-4 border-t-primary/80 
        dark:bg-zinc-950 col-span-4 mb-6 ${className} min-h-[300px] lg:min-h-[500px]`}
    >
      <CardContent className="p-0 flex-1">
        {children}
      </CardContent>
    </Card>
  );
};

export default CustomCard;

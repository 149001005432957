import CalendarComponent from '@/common/presentation/components/Calendar/CalendarComponent';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Button } from '@/components/ui/button';
import { Form, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { AlertCreateFormI, AlertUpdateFormI } from '@/modules/alerts/domain/alerts.domain';
import { createAlert, fetchAlertList, updateAlert } from '@/modules/alerts/presentation/slices/AlertSlice';
import { CustomAlertI } from '@/modules/dashboard/domain/dashboard.domain';
import { AppDispatch } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { z } from 'zod';

const FormSchema = z.object({
  lic_602_physician_report_date: z
    .date({
      required_error: 'Must be a valid date'
    })
    .optional(),
  plan_of_care_date: z
    .date({
      required_error: 'Must be a valid date'
    })
    .optional()
});

interface AlertHandlerParams {
  type: AlertType;
  date: string;
  existingId?: string | null;
}

interface IProps {
  residentId: string;
}

type AlertType = 'lic_602_physician_report_date_alert' | 'plan_of_care_date_alert';
const ReportAlerts: React.FC<IProps> = ({ residentId }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lic602AlertId, setLic602AlertId] = useState<string | null>(null);
  const [planOfCareAlertId, setPlanOfCareAlertId] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const CUSTOM_ALERT_CATEGORY = 'resident_reports';

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await dispatch(
        fetchAlertList({
          resident_id: [residentId ?? ''],
          category: CUSTOM_ALERT_CATEGORY
        })
      ).unwrap();
      response.alerts.map((alert: CustomAlertI) => {
        if (alert.title === 'lic_602_physician_report_date_alert') {
          form.setValue('lic_602_physician_report_date', alert?.start_date ? new Date(alert.start_date) : undefined);
          setLic602AlertId(alert.id);
        } else if (alert.title === 'plan_of_care_date_alert') {
          form.setValue('plan_of_care_date', alert?.start_date ? new Date(alert.start_date) : undefined);
          setPlanOfCareAlertId(alert.id);
        }
      });
    } catch (error) {
      console.error('Failed to fetch alerts:', error);
    }
    setIsLoading(false);
  };

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    storeAlert(data);
  };

  const storeAlert = async (formData: z.infer<typeof FormSchema>) => {
    try {
        await handleAlertOperation({
            type: 'lic_602_physician_report_date_alert',
            date: formData.lic_602_physician_report_date?.toISOString() ?? '',
            existingId: lic602AlertId ?? null
          });
    
          await handleAlertOperation({
            type: 'plan_of_care_date_alert',
            date: formData.plan_of_care_date?.toISOString() ?? '',
            existingId: planOfCareAlertId ?? null
          });
          toast({
            title: t('customAlerts.toast.updateSuccess.title'),
            description: t('customAlerts.toast.updateSuccess.description'),
            className: 'bg-green-500 text-white'
          });
    } catch (error) {
      console.error('Error storing alerts:', error);
    }
  };

  const handleAlertOperation = async ({ type, date, existingId }: AlertHandlerParams) => {
    try {
      setIsLoading(true);      
      if (!existingId) {
        if (!date) {
          setIsLoading(false);
          return;
        };        
        const createData = buildAlertData(type, date);
        const data = await dispatch(createAlert(createData)).unwrap();
        if (type === 'lic_602_physician_report_date_alert') {
          setLic602AlertId(data.alert.id);
        }
        if (type === 'plan_of_care_date_alert') {
          setPlanOfCareAlertId(data.alert.id);
        }        
      } else {        
        const updateData = buildAlertDataForUpdate(existingId, date);
        await dispatch(updateAlert(updateData)).unwrap();
        if (!date) {
          if (type === 'lic_602_physician_report_date_alert') {
            setLic602AlertId(null);
          }
          if (type === 'plan_of_care_date_alert') {
            setPlanOfCareAlertId(null);
          }
          setIsLoading(false);
          return;
        };
      }
    } catch (error) {
      throw new Error(`Failed to ${existingId ? 'update' : 'create'} ${type} alert: ${error}`);
    }
    setIsLoading(false);
  };

  const buildAlertData = (title: AlertType, date: string): AlertCreateFormI => ({
    resident_ids: [residentId],
    title,
    description: title,
    start_date: date,
    frequency: 'once',
    end_date: '',
    days_of_week: [],
    category: CUSTOM_ALERT_CATEGORY,
    user_ids: [],
    related_to: 'resident',
    location_id: '',
    reminder_months: 1
  });

  const buildAlertDataForUpdate = (id: string, date: string): AlertUpdateFormI => ({
    id,
    resident_ids: [residentId],
    start_date: date
  });

  return (
    <CustomCard
      title={t('alerts.reports_alerts')}
      description=""
      button={
        <Button onClick={form.handleSubmit(onSubmit)} disabled={isLoading}>
          {t('alerts.save')}
        </Button>
      }
    >
      <Form {...form}>
        <div className="flex flex-col md:flex-row gap-4">
          <FormField
            control={form.control}
            name="lic_602_physician_report_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('alerts.lic_602_physician_report_date')}</FormLabel>
                <CalendarComponent value={field.value} onChange={field.onChange} isDisabled={isLoading} />
                <FormDescription className="text-xs">
                  {t('alerts.date_when_lic_602_physician_report_date')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="plan_of_care_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t('alerts.plan_of_care_date')}</FormLabel>
                <CalendarComponent value={field.value} onChange={field.onChange} isDisabled={isLoading} />
                <FormDescription className="text-xs">{t('alerts.date_when_plan_of_care_date')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </Form>
    </CustomCard>
  );
};

export default ReportAlerts;

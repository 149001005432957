import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isValid, parseISO, format } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';

import { CardHeader, CardContent} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import FormActionButtons from '../shared/FormActionButtons';
import FormHeader from '../shared/FormHeader';
import { CustomCard } from '../shared/CustomCard';
import { deathReportLicenseeDefaults } from '../formDefaults/deathReportLicenseeDefaults';
import DeathReport from './components/DeathReport';
import FacilityInfo from './components/FacilityInfo';
import DeathReportForm from './components/DeathReportForm';
import ReportForm from './components/ReportForm';
import AgenciesNotifiedForm from './components/AgenciesNotifiedForm';

export const DeathReportLicenseeForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const methods = useForm({
    defaultValues: deathReportLicenseeDefaults(resident)
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(deathReportLicenseeDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic624a_death_report_licensee',
  });

  const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return '';
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
  };

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedData = {
      ...rawData,
      date_of_admission: formatDate(rawData.date_of_admission),
      date_time_of_death: rawData.date_time_of_death
        ? format(new Date(rawData.date_time_of_death), 'MM/dd/yyyy hh:mm aa')
        : '',
      submitted_date: formatDate(rawData.submitted_date),
      reviewed_date: formatDate(rawData.reviewed_date),
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
          <BackNavigationBar
            title={title}
            path={`/residents/forms?r=${params.r}`}
          />
          <FormProvider {...methods}>
            <form>
              <CustomCard>
                <CardHeader className='p-0 '>
                  <FormHeader
                    leftContent={
                      <>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.stateOfCalifornia")} - {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
                        </p>
                      </>
                    }
                    rightContent={
                      <>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.californiaDeptSocialServices")}
                        </p>
                        <p className='uppercase'>
                          {t("residents.forms.formHeader.communityCareLicensingDivision")}
                        </p>
                      </>
                    }
                  />
                </CardHeader>
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <DeathReport />
                    <Separator className='my-4 bg-primary'/>
                    <FacilityInfo />
                  </div>
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <DeathReportForm />
                  </div>
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <ReportForm />
                  </div>
                </CardContent>
              </CustomCard>
              <CustomCard>
                <CardContent className='p-0 flex-1'>
                  <div className="mx-auto">
                    <AgenciesNotifiedForm />
                  </div>
                  <Separator className='my-4 bg-primary'/>
                  <FormActionButtons
                    handleFormSubmit={handleFormSubmit}
                    formsStatus={formsStatus}
                  />
                </CardContent>
              </CustomCard>
            </form>
          </FormProvider>
      </div>
    </div>
  );
};

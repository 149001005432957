import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { TableCell, TableRow } from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { File as FileIconLucide, Loader2, Save, Trash } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateLocationDocument, deleteLocationDocument } from '@/modules/locations/presentation/slices/locationsSlice';
import { z } from 'zod';
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { useTranslation } from 'react-i18next';

interface Props {
  resident_id: string;
  location_id?: string;
  document: any;
}

const formSchema = z.object({
  comments: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const HistoryTableItem = ({ document, resident_id, location_id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { onClose } = useHistoryDocuments();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    deleteDocument();
    handleCloseModal();
  };

  const form = useForm<FormValues>({
    defaultValues: {
      comments: document.comments
    },
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (formValues: any) => {
    setIsLoading(true);
    const data = {
      comments: formValues.comments
    };

    if (!location_id) {
      const response = await dispatch(residentActions.updateResidentDocument({ documentId: document.id, data }));

      if (response.meta.requestStatus === 'fulfilled') {
        form.reset();
        dispatch(residentActions.getResidentById(resident_id));
        onClose();
      }
    } else {
      const response = await dispatch(updateLocationDocument({ documentId: document.id, data }));

      if (response.meta.requestStatus === 'fulfilled') {
        form.reset();
        onClose();
      }
    }

    setIsLoading(false);
  };

  const deleteDocument = async () => {
    setIsLoading(true);

    if (!location_id) {
      const response = await dispatch(residentActions.deleteResidentDocument(document.id));

      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(residentActions.getResidentById(resident_id));
        onClose();
      }
    } else {
      const response = await dispatch(deleteLocationDocument(document.id));

      if (response.meta.requestStatus === 'fulfilled') {
        onClose();
      }
    }

    setIsLoading(false);
  };

  const handleViewDocument = () => {
    window.open(document?.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Form {...form}>
      <TableRow>
        <TableCell>
          <FormField
            control={form.control}
            name="comments"
            render={({ field }) => (
              <FormItem>
                <Textarea {...field} className="h-10 min-h-10" />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>{format(new Date(document.created_at), 'MM/dd/yyyy hh:mm a')}</TableCell>

        <TableCell>
          <div className="flex gap-2 w-full items-center">
            <Button size={'icon'} variant={'outline'} onClick={handleViewDocument} disabled={document?.url === null}>
              <FileIconLucide className="size-4" />
            </Button>

            
              <Button
                size={'icon'}
                variant={'outline'}
                onClick={() => handleOpenModal()}
                disabled={isLoading}
                className="border-red-500 hover:bg-red-600 text-red-500 hover:text-white"
              >
                {
                  isLoading ? (
                    <Loader2 className={`size-4 animate-spin`} />
                  ) : (
                    <Trash className="size-4" />
                  )
                }
              </Button>
       

            {form.formState.isDirty && (
              <Button
                variant="default"
                size="icon"
                className="bg-green-500 hover:bg-green-600 text-white"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {
                  isLoading ? (
                    <Loader2 className={`size-4 animate-spin`} />
                  ) : (
                    <Save className="size-4" />
                  )
                }
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isModalOpen && (
        <CustomDialog
          newDialog={true}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title="Confirm Deletion"
        >

          <div>

            <div>
              <div className='flex justify-center'>
                <h2>{t('trackers.sureDelete')}</h2>
              </div>

              <div className="w-full flex justify-end mt-5">
                <Button onClick={handleConfirmDelete} className="bg-red-600 text-white px-4 py-2 rounded">
                  {t('trackers.Delete')}
                </Button>
                <Button onClick={handleCloseModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                  {t('calendar.cancel')}
                </Button>
              </div>
            </div>


          </div>

        </CustomDialog>
      )}
    </Form>
  );
};

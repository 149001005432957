import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerBedroomQrService from '../../infrastructure/services/TrackerBedroomQrService';

interface IBedroomQrForm {
    residentId: string | undefined;
    bedrooms: Array<{
        bedroomId: string;
        detail: string | undefined;
        date: string;
    }>;
}

interface IBedroomQrData {
    bedroomId: string;
    detail: string;
    date: string;
}

interface IBedroomQrReport {
    residentId: string;
    bedrooms: IBedroomQrData[];
}

interface ISaveBedroomQrResponse {
    message: string;
    data: IBedroomQrReport[];
}

interface IUpdateTrackerBedroomQr {
    id: string;
    date: string;
    detail: string;
    bedroom_id: string | undefined;
}

export const getTrackerBedroomQr = createAsyncThunk("trackerBedroomQr/getTrackerBedroomQr",
    async (params: { search: string; location_id: string; resident_id: string; bedroom_id: string; user_id: string; per_page: number; page: number }) => {
        const response = await TrackerBedroomQrService.getTrackerBedroomQr(
            params.search,
            params.location_id,
            params.resident_id,
            params.bedroom_id,
            params.user_id,
            params.per_page,
            params.page);
        return response;
    });

export const getTrackerBedroomQrByDate = createAsyncThunk("trackerBedroomQr/filterBedroomByDate",
    async (params: { resident_id: string; date: string; }) => {
        const response = await TrackerBedroomQrService.getTrackerBedroomQrByDate(
            params.resident_id,
            params.date);

        return response;
    });


export const saveBedroomQr = createAsyncThunk("trackerBedroomQr/saveTrackerBedroomQr", async (body: IBedroomQrForm) => {
    const response = await TrackerBedroomQrService.saveTrackerBedroomQr(body);
    return response;
});

export const updateBedroomQr = createAsyncThunk("trackerBedroomQr/updateTrackerBedroomQr", async (body: IUpdateTrackerBedroomQr) => {
    const response = await TrackerBedroomQrService.updateTrackerBedroomQr(body);
    return response;
});

export const deleteTrackerBedroomQr = createAsyncThunk('trackerBedroomQr/deleteTrackerBedroomQr',
    async (id: string ) => {
       const response = await TrackerBedroomQrService.deleteTrackerBedroomQr(id);
       return response;
     }
   );
  


interface BedroomQrState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    bedroomQrReport: IBedroomQrReport[];
    listBedroomQrReport: any[];
    saveBedroomQr?: ISaveBedroomQrResponse
}

const initialState: BedroomQrState = {
    status: 'idle',
    error: null,
    bedroomQrReport: [],
    listBedroomQrReport: []
};


const trackerBedroomQr = createSlice({
    name: 'residentBedroomQr',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTrackerBedroomQr.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerBedroomQr.fulfilled, (state, action) => {
            state.status = 'idle';
            state.listBedroomQrReport = action.payload;
        });

        builder.addCase(getTrackerBedroomQr.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(getTrackerBedroomQrByDate.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerBedroomQrByDate.fulfilled, (state, action) => {
            state.status = 'idle';
            state.listBedroomQrReport = action.payload;
        });

        builder.addCase(getTrackerBedroomQrByDate.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        }); 

        builder.addCase(saveBedroomQr.fulfilled, (state, action) => {
            state.status = 'idle';
            state.bedroomQrReport = action.payload;
        });

        builder.addCase(saveBedroomQr.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateBedroomQr.fulfilled, (state, action) => {
            state.status = 'idle';
            state.bedroomQrReport = action.payload;
        });

        builder.addCase(updateBedroomQr.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(deleteTrackerBedroomQr.fulfilled, (state) => {
            state.status = 'idle';
          });

    }
});

export default trackerBedroomQr.reducer;
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Separator } from '@/common/presentation/components/ui/separator';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, CheckIcon, Loader, TimerIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useNewExceptionDialog } from '@/modules/emar/infrastructure/hooks/UseNewException';

import { useTranslation } from 'react-i18next';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { useMemo } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { timeUtils } from '@/modules/emar/domain/timeUtils';

interface Props {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
  reasons: any[];
}

const formSchema = z.object({
  date: z.date({
    required_error: 'Date is required'
  }),
  time: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: 'You have to select at least one time.'
  }),
  reason_id: z.string({
    required_error: 'Reason is required'
  }).min(1, {
    message:"The reason is required"
  }),
  details: z.string(),
  discount_unit: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const ExceptionForm = ({ defaultValues, onSubmit, disabled = false, reasons }: Props) => {
  const { t } = useTranslation();
  const { params } = useRouteParams();
  const residentId = params.r ?? 'all-residents';
  const { data, isGeneral, date } = useNewExceptionDialog();
  const { medications } = useMedicationStore();
  const getCardState = useMedicationStore((state) => state.getCardState);

  let times = data?.time || [];
  if (isGeneral) {
    times = data?.reduce((acc: string[], cValue: any) => {
      acc.push(...cValue.time ?? []);
      return acc;
    }, ['all-day']);
    times = [...new Set(times)];
  }

  times = timeUtils.removeLeadingZeroFromTimes(times || []);
  
  if(isGeneral && defaultValues){
    defaultValues!.time = ['all-day'];
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    
  });

  const handleSubmit = async (formData: FormValues) => {
    await onSubmit(formData);
  };

  const handleSelectTime = (isSelected: boolean, field: any, time: string) => {
    let value = isSelected
      ? field.value.filter((t: string) => t !== time)
      : [...field.value, time];

    if (isGeneral) {
      if (value.length > 1 && time != 'all-day') {
        value = value.filter((t: string) => t !== 'all-day');
      }
      if (value.length > 1 && time == 'all-day') {
        value = value.filter((t: string) => t == 'all-day');
      }
    }
    field.onChange(value);
  }


  const residentMedications: any[] = medications?.filter(
    medication =>
      medication.resident_id === residentId
      && medication?.medication_type?.name === 'Routine'
  ) ?? [];
  
  
  const hasAllMedicationExceptions: boolean = useMemo(() => {

    const filteredMedications = residentMedications.filter((medication: any) => {
      const { active: hasNoSpecialDay } = getCardState(medication.special_days, date);
      return hasNoSpecialDay
    }) ?? [];

    return filteredMedications.every((m: any) =>
      m.time.every((time: any) => {
        const exception = timeUtils.findMedicationByDateTime(
          m?.emar_medication_exceptions,
          date!,
          time
        );
        return exception !== null && exception !== undefined;
      })
    );
  }, [medications, date, isGeneral]);


  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
          <Separator className="col-span-full" />
          <div>
            <h3 className="text-lg font-semibold">Medication</h3>
            <p className='text-[14px] font-medium'>
              {`Date: `} 
              <span className='text-[13px]'>
                {form.getValues('date') 
                  ? format(form.getValues('date'), 'MM/dd/yyyy') 
                  : <span>Select a date of exception</span>}
                </span>
              </p>
            <p className="text-muted-foreground">
              {`${data?.medication?.name?.toUpperCase() || ''} ${data?.strength || ''} ${data?.form || ''}`}
            </p>
          </div>

          <FormField
            control={form.control}
            name="time"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>
                    Time <span className="text-destructive">(*)</span>
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn(
                            'w-full flex items-center justify-start font-normal',
                            !field.value && 'text-muted-foreground'
                          )}
                          disabled={disabled}
                        >
                          <TimerIcon className="mr-2 size-4 text-muted-foreground" />
                          <span>Times</span>
                          {field.value?.length > 0 && (
                            <>
                              <Separator orientation="vertical" className="mx-2 h-4" />
                              <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                                {field.value.length}
                              </Badge>
                              <div className="hidden space-x-1 lg:flex">
                                {field.value.length > 5 ? (
                                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                    {field.value.length} selected
                                  </Badge>
                                ) : (
                                  field.value.map((time) => (
                                    <Badge variant="secondary" className="rounded-sm px-1 font-normal" key={time}>
                                      {time == 'all-day' ? t('emar-routine.all-day') : time}
                                    </Badge>
                                  ))
                                )}
                              </div>
                            </>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="p-1">
                      <Command className="w-full">
                        <CommandInput placeholder="Search time" />
                        <CommandList>
                          <CommandEmpty>No results found.</CommandEmpty>
                          <CommandGroup>
                            {times.map((time: any, index: number) => {
                              const isSelected = field.value.includes(time);

                              return (
                                <CommandItem
                                  value={time}
                                  key={index}
                                  onSelect={() => handleSelectTime(isSelected, field, time)}
                                >
                                  <div
                                    className={cn(
                                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                      isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                                    )}
                                  >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                  </div>
                                  {time == 'all-day' ? t('emar-routine.all-day') : time}
                                </CommandItem>
                              );
                            })}
                          </CommandGroup>

                          {field.value.length > 0 && (
                            <>
                              <CommandSeparator className="mb-1" />

                              <CommandItem
                                onSelect={() => {
                                  field.onChange([]);
                                }}
                                className="justify-center text-center"
                              >
                                Clear times
                              </CommandItem>
                            </>
                          )}
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="reason_id"
            render={({ field }) => (
              <FormItem className="col-span-12 lg:col-span-6">
                <FormLabel>
                  Reason <span className="text-destructive">(*)</span>
                </FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  defaultValue={field.value}
                  disabled={disabled}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a reason" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {reasons?.map((reason) => (
                      <SelectItem key={reason.id} value={reason.id}>
                        {reason.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="details"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel>Details</FormLabel>
                <FormControl>
                  <Textarea {...field} onChange={(e) => field.onChange(e.target.value)} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {!isGeneral &&
            <FormField
              control={form.control}
              name="discount_unit"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} disabled={disabled} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Discount unit</FormLabel>
                    <FormDescription>
                      Enable this option if you want to discount the medication by one unit, If dispensed do not check
                      otherwise it will be discounted twice.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />}

          <Separator className="col-span-full" />
          { (!hasAllMedicationExceptions || !isGeneral)  && (
            <Button type="submit" className="col-span-full" disabled={disabled}>
              {disabled ? <Loader className="size-4 mr-2 animate-spin" /> : null}
              Submit
            </Button>
          )}
          {
            (hasAllMedicationExceptions && (params.r && params.r !== 'all-residents')) &&
            <p className='text-[13px] text-center'>All exceptions have been successfully signed</p>
          }
        </form>
      </Form>
    </div>
  );
};

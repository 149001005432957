import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { FileUser } from 'lucide-react';
import { LocationI } from '@/common/domain/Residents';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { InfoCard } from '../../../../../common/presentation/components/Card/InfoCard';

interface EmployerInfoProps {
  className?: string;
}

export const EmployerInfo = ({ className }: EmployerInfoProps) => {
  const { t } = useTranslation();
  const { configurations } = useSelector((state: RootState) => state.configurations);
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const contentClass = "items-center gap-4 rounded-md bg-zinc-100 dark:bg-zinc-900 px-4 py-1.5 border border-gray-200 dark:border-zinc-700"
  const textClass = "text-xs text-zinc-600 dark:text-zinc-50 font-medium"
  const companyName = configurations?.name  || ''
  const locations = staffMember?.locations?.map((data: LocationI) => data.name) ?? [];
  const formattedLocations = locations.length > 1
    ? locations.slice(0, -1).join(', ') + ' , ' + locations[locations.length - 1]
    : locations[0] || '';
  const positionName = staffMember?.position_title ?? ''

  return (
    <div className={className}>
      <InfoCard
        additionalClass="pr-0 h-[245px]"
        headerTitle={t('staff.profile.employerInformation')}
        headerIconLeft={FileUser}
      >
        <ScrollArea className="flex flex-col gap-2 flex-1 overflow-y-auto max-h-[160px]">
          <div className="space-y-3">
            <div className={`grid ${contentClass}`}>
              <span className="text-xs text-primary font-semibold min-h-4">
                {companyName}
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                Location:
              </span>
              <span className={`${textClass} font-normal`}>
                {formattedLocations}
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                  Position:
              </span>
              <span className={`${textClass} font-normal`}>
                {positionName}
              </span>
            </div>
            <div className={`grid grid-cols-2 ${contentClass}`}>
              <span className={textClass}>
                  Shift:
              </span>
              <span className={`${textClass} font-normal`}>
              </span>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </InfoCard>
    </div>
  );
};
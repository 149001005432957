import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { RootState } from "@/store/store";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getVisitors, saveVisitors } from "../../slices/VisitorsSlice";

const TrackerVisitorsDetailed = () => {
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const configurations = useSelector((state: RootState) => state.configurations);
    const { residents } = useSelector((state: RootState) => state.residents.allResidents);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    const { toast } = useToast();
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState({
        date: null,
        visitor: "",
        temperature: ""
    });
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.date && values.visitor && values.temperature) {
                const resident = residents.find((resident) => resident.id === params.r);

                dispatch(
                    saveVisitors(
                        [
                            {
                                location_id: locationSelectedId,
                                resident_id: params.r,
                                date: values.date.toISOString(),
                                regardingTo: resident.first_name + " " + resident.last_name,
                                visitor: values.visitor,
                                temperature: values.temperature,
                                approved: true
                            }
                        ]
                    )
                );

                toast(
                    {
                        description: t("seizure.form.toast.success"),
                        className: 'bg-green-500 text-white'
                    }
                );

                cleanInputs();
            } else {
                toast(
                    {
                        description: t("seizure.form.toast.required") 
                    }
                );
            }
        } else {
            toast(
                {
                    description: t("seizure.form.toast.residentRequired") 
                }
            );
        }
    }

    const cleanInputs = () => {
        setValues({
            date: null,
            visitor: "",
            temperature: ""
        });
    };

    return (
        <div>
            {
                locationSelectedId && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <ResidentSelector
                                locationId={locationSelectedId}
                                residentId={params.r}
                                showAllResidents={false}
                            />
                        </div>
                    </div>
                )
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("visitors.newVisitors")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <div className="general-outings-form grid grid-cols-3 gap-4 mt-5">
                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"date"} className="text-left">
                                    { t("seizure.form.form.date") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.date && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.date ? format(values.date, "MM/dd/yyyy - hh:mm a") : <span>mm/dd/yyyy - hh:mm</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.date}
                                            timePicker={true}
                                            onChange={handleDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"visitor"} className="text-right justify-self-start">
                                    Visitor
                                </Label>

                                <Input
                                    id={"visitor"}
                                    name={"visitor"}
                                    type="text"
                                    value={values.visitor}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="grid grid-cols-1 items-center gap-4">
                                <Label htmlFor={"temperature"} className="text-right justify-self-start">
                                    Temperature
                                </Label>

                                <Input
                                    id={"temperature"}
                                    name={"temperature"}
                                    type="text"
                                    value={values.temperature}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="w-100 mt-4 flex flex-row justify-end">
                            <Button type="submit" className="mr-2">
                                { t("seizure.form.form.submit") }
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default TrackerVisitorsDetailed;

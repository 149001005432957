import React, { useEffect, useRef } from 'react';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { PrnDialogForm } from '@/modules/emar/presentation/components/forms/PrnDialogForm';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import { timeUtils } from '@/modules/emar/domain/timeUtils';

export const NewPrnDialog = () => {
  const { isOpen, onClose, data, locationId } = usePrnDialog();
  const { residents } = useSelector((state: RootState) => state.residents.allResidents);
  const resident = residents.find((resident) => resident.id === data?.resident_id);
  const { dispensePrnMedication, status: medicationCardStatus } = useMedicationStore();
  const normalizedData = data?.data || data || {};

  useEffect(() => {
    if (normalizedData) {
    }
  }, [normalizedData]);

  const onSubmit = async (values: any) => {
    try {
      await dispensePrnMedication(
        values.time_given,
        values.follow_up,
        values.reason,
        values.level_of_pain,
        values.result,
        values.comments,
        resident?.id,
        locationId,
        data?.id,
        data?.dispense_id,
        values?.frecuency,
        values?.not_exceed
      );

      onClose();
      toast({
        description: 'Medication dispensed successfully'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to dispense medication',
        variant: 'destructive'
      });
    }
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title={`PRN Medication for ${resident?.first_name} ${resident?.last_name}`}
      description=""
      className="md:max-w-[625px] lg:max-w-[625px]"
      newDialog={true}
      overFlow={true}
    >
      <>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className="sm:col-span-2">
            <Label className="font-bold block">Medication</Label>
            <div className="mt-1 text-gray-700 break-words flex items-center">
              <span className="mr-2">
                {normalizedData?.medication?.name?.toUpperCase() || normalizedData?.medication_name || ''}
              </span>
              {(normalizedData?.strength || normalizedData?.form) && (
                <span className="text-s">
                  {normalizedData?.strength} {normalizedData?.form}
                </span>
              )}
            </div>
          </div>

          <div className="md:col-span-3 grid md:grid-cols-3 gap-6">
            <div>
              <Label className="font-bold block mb-1">Frequency (PRN) Hrs</Label>
              <p className="text-gray-700 truncate min-h-[1.5rem]">{normalizedData?.frequency || ''}</p>
            </div>

            <div>
              <Label className="font-bold block mb-1">Not to Exceed</Label>
              <p className="text-gray-700 truncate min-h-[1.5rem]">{normalizedData?.not_exceed || ''}</p>
            </div>

            <div>
              <Label className="font-bold block mb-1">Purpose</Label>
              <p className="text-gray-700 truncate min-h-[1.5rem]">{normalizedData?.purpose || ''}</p>
            </div>
          </div>

          <div className="md:col-span-2 grid md:grid-cols-2 gap-4">
            <div className="sm:col-span-1 flex flex-col justify-between">
              <Label className="font-bold block">Instructions</Label>
              <p className="mt-1 text-gray-700 break-words">{normalizedData?.instruction || ''}</p>
            </div>

            <div className="sm:col-span-1 flex flex-col justify-between">
              <Label className="font-bold block">Special Instructions</Label>
              <p className="mt-1 text-gray-700 break-words">{normalizedData?.special_instructions || ''}</p>
            </div>
          </div>
        </div>

        <Separator className="my-6" />

        <PrnDialogForm
          defaultValues={{
            time_given: data?.time_given || timeUtils.formatToUTC(new Date()),
            follow_up: data?.follow_up || '',
            reason: data?.reason || '',
            result: data?.result || '',
            comments: data?.comments || '',
            level_of_pain: data?.level_of_pain || 0,
            frecuency: data?.frecuency || '',
            not_exceed: data?.not_exceed || ''
          }}
          onSubmit={onSubmit}
          disabled={medicationCardStatus === 'loading'}
        />
      </>
    </CustomDialog>
  );
};

import { ResidentI } from "@/common/domain/Residents";

import { lic601EmergencyInfoDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/lic601EmergencyInfoDefaults";
import { residentAppraisalDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/residentAppraisalDefaults";
import { releaseMedicalInfoDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/releaseMedicalInfoDefaults";
import { personalRightsRCFEDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/personalRightsRCFEDefaults";
import { residentPersonalPropertyDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/residentPersonalPropertyDefaults";
import { incidentReportFormDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/incidentReportFormDefaults";
import { deathReportLicenseeDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/deathReportLicenseeDefaults";
import { emergencyMedicalConsentDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/emergencyMedicalConsentDefaults";
import { telecommunicationsDefaults } from "@/modules/residents/presentation/components/Forms/Licensing/formDefaults/telecommunicationsDefaults";
// Interface
import { AllContactsI } from "../../models/Contacts";

export const defaultValuesRegistry: Record<string, (resident: ResidentI, contacts: AllContactsI[]) => Record<string, any>> = {
  lic601_id_and_emergency_info: lic601EmergencyInfoDefaults,
  lic603a_pre_placement_appraisal: residentAppraisalDefaults,
  lic605a_release_of_client_medical_info: releaseMedicalInfoDefaults,
  lic613c_personal_rights: personalRightsRCFEDefaults,
  lic621_property_record: residentPersonalPropertyDefaults,
  lic624_unusual_incident_injury_report: incidentReportFormDefaults,
  lic624a_death_report_licensee: deathReportLicenseeDefaults,
  lic627c_consent_emergency_medical_treatment: emergencyMedicalConsentDefaults,
  lic9158_telecommunication_device_notification: telecommunicationsDefaults
};

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

const IncidentDetailsForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <FormField
        control={control}
        name="incident_description"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.incidentDetails.details1')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="observed_by"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details2')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="immediate_action"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details3')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <div>
        <FormField
          control={control}
          name="medical_treatment_necessary"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.incidentDetails.details4')}
              </FormLabel>
              <RadioGroup
                className="flex space-x-4 mt-2"
                onValueChange={(value) => field.onChange(value)}
                value={field.value || ''}
              >
                <label className="flex items-center space-x-2">
                  <RadioGroupItem value="yes" id="medical_treatment_yes" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">{t('common.yes')}</span>
                </label>
                <label className="flex items-center space-x-2">
                  <RadioGroupItem value="no" id="medical_treatment_no" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">{t('common.no')}</span>
                </label>
              </RadioGroup>
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={control}
        name="treatment_nature"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details5')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
              />
            </FormControl>
          </FormItem>
        )}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <FormField
          control={control}
          name="administered_where"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.incidentDetails.details6')}:
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="dark:bg-zinc-800 w-full"
                  maxLength={70}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="administered_by"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.incidentDetails.details7')}:
                </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="dark:bg-zinc-800 w-full"
                  maxLength={70}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={control}
        name="follow_up_treatment"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details8')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="action_taken"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details9')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="supervisor_comments"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
            {t('residents.forms.incidentReport.incidentDetails.details10')}:
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                className="dark:bg-zinc-800 w-full"
                maxLength={800}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

export default IncidentDetailsForm;

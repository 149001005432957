import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import { MedicationDispensingDataI } from '@/modules/dashboard/domain/dashboard.domain';
import { RootState } from '@/store/store';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

interface IProps {
  index: number;
  alertInfo: {
    resident_id: string;
    medications: MedicationDispensingDataI[];
  };
  maxItemsProp?: number;
}

type GroupedMedication = {
  medication_name: string;
  times: string[];
};

type GroupedAlerts = [string, GroupedMedication[]][];

const ResidentAlertContainer: React.FC<IProps> = ({ index, alertInfo, maxItemsProp = 5 }) => {
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { formatDate } = useDateHelpers();

  const getScrollAreaHeight = (medications: MedicationDispensingDataI[]) => {
    const itemHeight = 90;
    const maxItems = maxItemsProp;
    const totalItems = medications.reduce((acc, med) => acc + med.not_given_dates.length, 0);
    const itemCount = Math.min(totalItems, maxItems);
    return `${itemCount * itemHeight}px`;
  };

  const convertTo24Hour = (timeStr: string): number => {
    const [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    
    return hours * 60 + minutes;
  };

  const getLatestTime = (times: string[]): number => {
    return Math.max(...times.map(t => convertTo24Hour(t)));
  };

  const groupAlertsByDate = (): GroupedAlerts => {
    const groupedAlerts: Record<string, GroupedMedication[]> = {};

    alertInfo.medications.forEach((med) => {
      med.not_given_dates.forEach((dateInfo) => {
        if (dateInfo.date) {
          try {
            const formattedDate = formatDate(`${dateInfo.date} 05:00`, false);
            if (!groupedAlerts[formattedDate]) {
              groupedAlerts[formattedDate] = [];
            }
            
            const times = Array.isArray(dateInfo.times) ? [...dateInfo.times] : [dateInfo.times];
            times.sort((a, b) => convertTo24Hour(b) - convertTo24Hour(a)); // Sort times in descending order
            
            groupedAlerts[formattedDate].push({
              medication_name: med.medication_name,
              times
            });
          } catch (error) {
            console.error('Error processing date:', error);
          }
        }
      });
    });

    const convertToSortableDate = (dateString: string) => {
      try {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      } catch {
        return dateString;
      }
    };

    return Object.entries(groupedAlerts)
      .sort(([dateA], [dateB]) => {
        try {
          const sortableDateA = convertToSortableDate(dateA);
          const sortableDateB = convertToSortableDate(dateB);
          return sortableDateB.localeCompare(sortableDateA);
        } catch {
          return 0;
        }
      })
      .map(([date, medications]) => [
        date,
        [...medications].sort((a, b) => {
          const latestTimeA = getLatestTime(a.times);
          const latestTimeB = getLatestTime(b.times);
          return latestTimeB - latestTimeA; // Sort by latest time in descending order
        })
      ]);
  };

  const groupedAlerts = groupAlertsByDate();

  return (
    <div key={index} className="border rounded-lg p-4 shadow-sm w-full mb-4 dark:bg-black">
      <div
        className="sticky z-50 bg-white dark:bg-black py-2 flex flex-row items-center gap-3"
        style={{ top: '-9.5px' }}
      >
        <Avatar className="h-7 w-7 border-2 object-fill">
          <AvatarImage
            className="size-full object-cover transition-all hover:scale-105"
            src={UserHelper.getResidentInformation(alertInfo.resident_id, residents)?.image_url}
            alt={`Image`}
          />
          <AvatarFallback className="bg-secondary text-sm">
            {UserHelper.getResidentName(alertInfo.resident_id, residents) ??
              ''
                .split(' ')
                .map((n) => n[0])
                .join('')}
          </AvatarFallback>
        </Avatar>
        <Label className="text-base font-bold">
          {UserHelper.getResidentName(alertInfo.resident_id, residents) ?? ''}
        </Label>
      </div>
      <ScrollArea
        className="rounded-md border"
        style={{ height: getScrollAreaHeight(alertInfo.medications) }}
        type="always"
      >
        <div className="flex flex-col">
          {groupedAlerts.map(([date, medications]) =>
            medications.map((med, medIndex) => (
              <div key={medIndex}>
                <div key={date} className="bg-gray-100 dark:bg-zinc-900 p-2 m-2 rounded text-sm">
                  <Label className="font-bold text-primary mb-2 block">{date}</Label>
                  <div key={medIndex} className="flex flex-wrap items-center gap-2 mb-2">
                    <span className="font-semibold text-black dark:text-white">
                      {med.medication_name.charAt(0).toUpperCase() + med.medication_name.slice(1).toLowerCase()}
                    </span>
                    <div className="flex-grow"></div>
                    {med.times.map((time, timeIndex) => (
                      <Badge key={timeIndex} variant="outline" className="bg-primary/10 text-primary text-xs px-2 py-1">
                        {time}
                      </Badge>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

export default ResidentAlertContainer;
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import VisitorsReportInterface from "@/modules/tracker/infrastructure/interfaces/Visitors/VisitorsReportInterface";
import { RootState } from "@/store/store";
import { t } from "i18next";
import { Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVisitors, updateVisitors } from "../../slices/VisitorsSlice";

interface TrackerVisitorsReportUpdateProps {
    row: VisitorsReportInterface;
    fetchSummary: (page: number, resetData: boolean) => void;
}

const TrackerVisitorsReportUpdate = (props: TrackerVisitorsReportUpdateProps) => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const trackerDirect = useSelector((state: RootState) => state.trackerDirect);
    const { locationSelectedId } = useLocation();
    const { toast } = useToast();
    const [types, setTypes] = useState<any[]>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        visitor: "",
        temperature: ""
    });

    useEffect(() => {
        if(props.row && props.row.data) {
            setValues({
                visitor: JSON.parse(props.row.data)[0].visitor,
                temperature: JSON.parse(props.row.data)[0].temperature,
            });
        }
    }, [props.row]);

    useEffect(() => {
        if(trackerDirect.directTypes && trackerDirect.directTypes.length) {
            setTypes(
                trackerDirect.directTypes.filter(
                    (value) => value.status === "active"
                )
            );
        } else {
            setTypes([]);
        }
    }, [trackerDirect]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        if(values.visitor && values.temperature) {
            dispatch(
                updateVisitors(
                    [
                        {
                            id: props.row.id,
                            ...values
                        }
                    ]
                )
            );
            
            fetchData();
            setOpenEdit(false);

            toast(
                {
                    description: t('trackers.trackerdirect.successfullyUpdate')
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.report.summary.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

    const fetchData = async () => {
        props.fetchSummary(1, true);
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div className="mt-4">
                    <Label htmlFor="visitor" className="text-right">
                        Visitor
                    </Label>

                    <Input
                        id="visitor"
                        name="visitor"
                        className="col-span-3"
                        value={values.visitor}
                        onChange={handleInputChange}
                    />
                </div>
                
                <div className="mt-4">
                    <Label htmlFor="temperature" className="text-right">
                        Temperature
                    </Label>

                    <Input
                        id="temperature"
                        name="temperature"
                        className="col-span-3"
                        value={values.temperature}
                        onChange={handleInputChange}
                    />
                </div>

                <div className='flex justify-end mt-5'>
                    <Button type="submit" onClick={handleEdit}>
                        { t("trackers.trackercleaning.accept") }
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};

export default TrackerVisitorsReportUpdate;

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const ResidentRepresentativeForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="mx-auto">
      <p className='mb-2 uppercase'>
        {t('residents.forms.personalRightsRcfe.residentAndRepresentativeTitle')}:
      </p>
      <p>
        {t('residents.forms.personalRightsRcfe.personalRightsAcknowledgment')}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <FormField
          control={control}
          name="facility_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.printFacilityNameLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="facility_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.printFacilityAddressLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="resident_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.printResidentNameLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="resident_representative"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.printRepresentativeNameAndTitleLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="resident_signature"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.residentSignatureLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="resident_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('common.date')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="representative_signature"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('residents.forms.personalRightsRcfe.representativeSignatureLabel')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="representative_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='font-normal'>
                {t('common.date')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default ResidentRepresentativeForm;

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import VisitorsListInterface from '../interfaces/Visitors/VisitorsListInterface';
import VisitorsSaveInterface from '../interfaces/Visitors/VisitorsSaveInterface';
import VisitorsUpdateInterface from '../interfaces/Visitors/VisitorsUpdateInterface';
import VisitorsDeleteInterface from '../interfaces/Visitors/VisitorsDeleteInterface';
import VisitorsRegisterTokenInterface from '../interfaces/Visitors/VisitorsRegisterTokenInterface';

const TrackerVisitorsService = {
    getVisitors: async (body: VisitorsListInterface) => {
        try {
            const response = await axios.post(`visitors/getVisitors`, body);

            if (response && response.data) {
              return response.data.data;
            } else {
              throw new Error('Error fetching Visitor: No data returned from server');
            }
          } catch (error: any) {
            throw new Error('Error fetching Visitor: ' + error.message);
          }
    },
    saveVisitors: async (body: VisitorsSaveInterface) => {
        try {
            const response = await axios.post(`visitors/saveVisitors`, body);
            if (response && response.data) {
              return response.data;
            } else {
              throw new Error('Error fetching Visitor: No data returned from server');
            }
          } catch (error: any) {
            throw new Error('Error fetching Visitor: ' + error.message);
          }
    },
    publicSaveVisitors: async (body: VisitorsSaveInterface) => {
      try {
          const response = await axios.post(`publicSaveVisitors`, body);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error fetching Visitor: No data returned from server');
          }
        } catch (error: any) {
          throw new Error('Error fetching Visitor: ' + error.message);
        }
  },
    updateVisitors: async (body: VisitorsUpdateInterface) => {
        try {
            const response = await axios.put(`visitors/updateVisitors`, body);
            if (response && response.data) {
              return response.data;
            } else {
              throw new Error('Error fetching Visitor: No data returned from server');
            }
          } catch (error: any) {
            throw new Error('Error fetching Visitor: ' + error.message);
          }
    },
    deleteVisitors: async (body: VisitorsDeleteInterface) => {
        try {
            const response = await axios.post(`visitors/deleteVisitors`, body);
            if (response && response.data) {
              return response.data;
            } else {
              throw new Error('Error fetching Visitor: No data returned from server');
            }
          } catch (error: any) {
            throw new Error('Error fetching Visitor: ' + error.message);
          }
    },
    validateToken: async (body: VisitorsRegisterTokenInterface) => {
      try {
          const response = await axios.post(`validateToken`, body);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error fetching Visitor: No data returned from server');
          }
        } catch (error: any) {
          throw new Error('Error fetching Visitor: ' + error.message);
        }
    },
    registerToken: async (body: VisitorsRegisterTokenInterface) => {
      try {
          const response = await axios.post(`registerToken`, body);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error fetching Visitor: No data returned from server');
          }
        } catch (error: any) {
          throw new Error('Error fetching Visitor: ' + error.message);
        }
    }
};

export default TrackerVisitorsService;

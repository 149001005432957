import React, { useEffect } from "react";
import { useState } from 'react';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { Button } from '@/common/presentation/components/ui/button';
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Input } from '@/common/presentation/components/ui/input';
import { useTranslation } from 'react-i18next';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import TrackerService from "@/modules/tracker/infrastructure/services/TrackerService";
import { toast } from '@/common/presentation/components/ui/use-toast';
import UtilService from "@/common/services/UtilService";
import { useDateHelpers } from "@/utils/helpers/dates.helper";

interface TypeI {
    id: string;
    name: string;
    status: 'active' | 'inactive';
}

interface TypesI {
    column_field: TypeI[];
}

interface FormData {
    date: string;
    data: string;
    detail: string;
    type: string;
}

interface TrackerVitalsDetailProps {
    types: TypesI;
    residentId: string;
    locationId: string;
    dataDayVitalsReport: () => void;
}


export function TrackerVitalsDetail({ types, residentId, locationId, dataDayVitalsReport }: TrackerVitalsDetailProps) {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const formattedDate = selectedDate ? selectedDate.toISOString() : '';
    const { formatDate } = useDateHelpers();
    const [formData, setFormData] = useState<FormData>({
        date: selectedDate.toISOString(),
        data: '',
        detail: '',
        type: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (value: string) => {
        setFormData(prevState => ({
            ...prevState,
            type: value
        }));
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setFormData(prevState => ({
            ...prevState,
            date: date.toISOString()
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const json = {
            date: formData.date,
            value: formData.data,
            detail: formData.detail,
            type: formData.type,
            resident_id: residentId,
            location_id: locationId
        };

        if (residentId === 'all-residents' || !residentId) {
            toast({
                description: t('trackers.trackerincontinence.selectResident'),
            });
            return
        }

        if (!formData.type) {
            toast({
                description: t('trackers.validatedType'),
            });
            return
        }

        try {
            await TrackerService.saveTrackerVitals([json]);

            dataDayVitalsReport();

            toast({
                title: 'Success',
                description: t("trackers.trackervitals.successfullyCreated"),
                className: 'bg-green-500 text-white'
            });

            setFormData({
                date: selectedDate.toISOString(),
                data: '',
                detail: '',
                type: ''
            });
        } catch (error) {
            toast({
                description: t("trackers.trackervitals.errorCreated"),
                variant: 'destructive'
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2">
                <div className="p-2">
                    <Label htmlFor="tracker_bowel_date" className="control-label mb-3">
                        {t('trackers.Date/Time')}
                    </Label>

                    <div className="input-group">
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {formattedDate ? formatDate(formattedDate) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                </Button>
                            </PopoverTrigger>
                            
                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                    id="date"
                                    name="date"
                                    selectedDate={selectedDate}
                                    onChange={handleDateChange}
                                    MonthAndYearPicker={true}
                                    timePicker={true}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>

                <div className="p-2">
                    <Label htmlFor="tracker_data" className="control-label mb-3">
                        {t('trackers.Type')}
                    </Label>

                    <div className="input-group">
                        <Select value={formData.type} onValueChange={handleSelectChange}>
                            <SelectTrigger className="col-span-3 px-2 py-1 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                <SelectValue>
                                    {UtilService.sortObjectsAlphabetically(types, "type_name").find((option) => option.id === formData.type)?.type_name}
                                </SelectValue>
                            </SelectTrigger>

                            <SelectContent>
                                {UtilService.sortObjectsAlphabetically(types, "type_name")
                                    .filter((option) => option.status === 'active')
                                    .map((option) => (
                                        <SelectItem key={option.id} value={option.id}>
                                            {option.type_name}
                                        </SelectItem>
                                    ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className="p-2">
                    <Label htmlFor="data" className="control-label mb-3">
                        {t("trackers.trackerhygiene.state")}
                    </Label>

                    <div className="input-group">
                        <Input
                            className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                            id="data"
                            name="data"
                            type="text"
                            required
                            value={formData.data}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="p-2">
                    <Label htmlFor="detail" className="control-label mb-3">
                        {t('trackers.Detail')}
                    </Label>

                    <div className="input-group">
                        <Input
                            className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                            id="detail"
                            name="detail"
                            type="text"
                            value={formData.detail}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>

            <div className="flex justify-end mt-4">
                <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                    {t('trackers.Save')}
                </Button>
            </div>
        </form>
    )

}
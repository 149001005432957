import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicalInformationStore } from '@/modules/residents/domain/stores/medical-information/use-medical-information';
import { Resident } from '@/modules/residents/infrastructure/types/resident';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

const FormSchema = z.object({
  dnr: z.boolean(),
  polst: z.boolean(),
  hospice: z.boolean(),
  conserved: z.boolean()
});

type FormValues = z.infer<typeof FormSchema>;

export const MedicalActions = () => {
  const {
    resident
  }: {
    resident: Resident | null;
    status: string;
  } = useSelector((state: RootState) => state.residents.resident);

  const { createOrUpdateActionsInformation, status: medicalInformationFormStatus } = useMedicalInformationStore();
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>(
    resident?.other_information?.medical_information?.actions || {
      dnr: false,
      polst: false,
      hospice: false,
      conserved: false
    }
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      resident_id: resident?.id,
      ...values
    };

    try {
      const response = await createOrUpdateActionsInformation(payload);

      if (response) {
        setDefaultValues(values);
      }

      toast({
        description: t('residents.updatedMedicalActions')
      });
    } catch (error: any) {
      form.reset(defaultValues);

      toast({
        description: t('residents.failedMedicalActions'),
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <Form {...form}>
        <form onChange={form.handleSubmit(handleSubmit)} className="w-full flex items-center justify-around p-4">
          <FormField
            control={form.control}
            name="dnr"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col ">
                  <FormLabel className="text-base">DNR</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value as boolean}
                      onCheckedChange={field.onChange}
                      disabled={medicalInformationFormStatus === 'loading'}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="polst"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col ">
                  <FormLabel className="text-base">POLST</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value as boolean}
                      onCheckedChange={field.onChange}
                      disabled={medicalInformationFormStatus === 'loading'}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="hospice"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col ">
                  <FormLabel className="text-base">{t('residents.hospice')}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value as boolean}
                      onCheckedChange={field.onChange}
                      disabled={medicalInformationFormStatus === 'loading'}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="conserved"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col ">
                  <FormLabel className="text-base">{t('residents.conserved')}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value as boolean}
                      onCheckedChange={field.onChange}
                      disabled={medicalInformationFormStatus === 'loading'}
                      aria-readonly
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </form>
      </Form>
    </>
  );
};

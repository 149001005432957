import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import VisitorsReportInterface from "@/modules/tracker/infrastructure/interfaces/Visitors/VisitorsReportInterface";
import { Trash } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteVisitors } from "../../slices/VisitorsSlice";

interface TrackerVisitorsReportDeleteProps {
    row: VisitorsReportInterface;
    fetchSummary: (page: number, resetData: boolean) => void;
}

const TrackerVisitorsReportDelete = (props: TrackerVisitorsReportDeleteProps) => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [openDelete, setOpenDelete] = useState(false);

    const openDeleteDialog = () => {
        setOpenDelete(true);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = () => {
        dispatch(
            deleteVisitors(
                [
                    {
                        id: props.row.id
                    }
                ]
            )
        );

        fetchData();
        setOpenDelete(false);

        return true;
    }
    
    const fetchData = () => {
        props.fetchSummary(1, true);

    }

    return (
        <div>
            <Button onClick={() => openDeleteDialog()} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.delete.title")} 
                description={t("cleaning.report.summary.delete.description")} 
                width="30rem"
                newDialog={true}
                isOpen={openDelete}
                onClose={closeDeleteDialog}
            >
                <div className='flex justify-end'>
                    <Button type="submit" onClick={handleDelete}>
                        { t("trackers.trackercleaning.accept") }
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};

export default TrackerVisitorsReportDelete;

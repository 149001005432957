import RenderActionsColumn from './renderActionsColumn';

const columnsReportMedications: Column[] = [
  {
    key: 'month',
    labelTranslationKey: 'medications.DestructionMedications.reportDestructionMedications.reportDate',
    numeric: false,
    value: 'month',
    visible: true,
    static: true,
    sortable: true
  },
  {
    id: 'resident_name',
    key: 'resident_name',
    labelTranslationKey: 'medications.DestructionMedications.reportDestructionMedications.resident',
    type: 'resident_name',
    value: 'resident_name',
    visible: true,
    static: true,
    sortable: true,
    numeric: false,
    render: (data) => data.resident?.name || ''
  },
  {
    key: 'pdf',
    labelTranslationKey: 'Pdf',
    visible: true,
    sortable: true,
    editable: true,
    type: 'action',
    render: (row) => {
      const residentId = row.resident_id || row.resident?.id;

      return <RenderActionsColumn residentId={residentId} month={row.month} />;
    }
  }
];

export default columnsReportMedications;

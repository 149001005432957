import React from 'react';
import { Label } from '@radix-ui/react-label';
import { Lightbulb, AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface IProps {
  labelMessage: string;
  labelTitle?: string;
  messageType: 'info' | 'warning' | 'error' | 'success';
  messageVars?: Record<string, string | number>;
}

const Banner: React.FC<IProps> = ({ labelMessage, labelTitle, messageType, messageVars }) => {
  const { t } = useTranslation();

  const getIconConfig = () => {
    const configs = {
      info: { Icon: Lightbulb, color: 'text-primary', bgColor: 'bg-primary/5', borderColor: 'border-primary' },
      warning: { Icon: AlertCircle, color: 'text-yellow-500', bgColor: 'bg-yellow-50/5', borderColor: 'border-yellow-500' },
      error: { Icon: XCircle, color: 'text-red-500', bgColor: 'bg-red-50/5', borderColor: 'border-red-500' },
      success: { Icon: CheckCircle, color: 'text-green-500', bgColor: 'bg-green-50/5', borderColor: 'border-green-500' }
    };
    return configs[messageType];
  };

  const { Icon, color, bgColor, borderColor } = getIconConfig();

  return (
    <div className={`border rounded ${borderColor} p-2 sm:p-3 ${bgColor}`}>
      <div className="flex flex-row gap-2 sm:gap-4 items-start sm:items-center">
        <Icon className={`${color} align-middle w-5 h-5 sm:w-6 sm:h-6 mt-1 sm:mt-0 flex-shrink-0`} />
        <div className="flex flex-col gap-1 flex-grow">
          {labelTitle && <Label className={`justify-start font-bold ${color} text-sm sm:text-base`}>{t(labelTitle)}</Label>}
          <Label className="text-xs sm:text-sm font-semibold break-words">
            {t(labelMessage, messageVars)}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default Banner;
import { Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { useState } from 'react';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { t } from 'i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { Button } from '@/common/presentation/components/ui/button';

export function DeleteOutingsTrackerReportDialog(props: any) {
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const { locationSelectedId } = useLocation();
  
    const openDeleteDialog = () => {
        setOpenDelete(true);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = () => {
        dispatch(trackerActions.destroyTrackerOutingsGeneral(
            {
                id: props.id,
                id_data: props.id_data
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = async () => {
        await dispatch(trackerActions.getTrackerOutingsGeneral(
            {
              location_id: locationSelectedId
            }
          )
        );
      }

    return (
        <div>
            <Button onClick={() => openDeleteDialog()} variant="outline" size="icon" className='mr-1 ml-1'>
                <Trash className="h-4 w-4" />
            </Button>
            {
                <CustomDialog 
                    title={t("cleaning.report.summary.delete.title")} 
                    description={t("cleaning.report.summary.delete.description")} 
                    width="30rem"
                    newDialog={true}
                    isOpen={openDelete}
                    onSubmit={() => handleDelete()}
                    onClose={closeDeleteDialog}
                    children={null}
                ></CustomDialog>
            }
        </div>
    );
};

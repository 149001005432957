import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormControl } from '@/components/ui/form';
import { CardContent} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { CustomCard } from '../../shared/CustomCard';
import FormHeader from '../../shared/FormHeader';
import { useTranslation } from 'react-i18next';

const LegalNoticeSectionForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
    <CustomCard>
      <FormHeader
        leftContent={
          <>
            <p className='uppercase'>
              {t("residents.forms.formHeader.stateOfCalifornia")} - {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
            </p>
          </>
        }
        rightContent={
          <>
            <p className='uppercase'>
              {t("residents.forms.formHeader.californiaDeptSocialServices")}
            </p>
          </>
        }
      />
      <CardContent className='p-0 flex-1'>
        <div className="mx-auto">
          <h2 className="text-xl font-bold text-center mb-4 uppercase">
            {t('residents.forms.personalRightsRcfe.title1')} <br />
            {t('residents.forms.personalRightsRcfe.title2')}
          </h2>
          <div className='text-sm text-zinc-700 mb-4 text-justify dark:text-white'>
            <p>
              <strong className='uppercase'>{t('residents.forms.personalRightsRcfe.explanation.name')}:</strong> 
              {t('residents.forms.personalRightsRcfe.explanation.explanationDetail1')}
            </p>
            <p>
              {t('residents.forms.personalRightsRcfe.explanation.explanationDetail2')}
              {t('residents.forms.personalRightsRcfe.explanation.explanationDetail3')}
            </p>
            <p>
              {t('residents.forms.personalRightsRcfe.explanation.explanationDetail4')}
            </p>
            <p className='font-bold'>
              {t('residents.forms.personalRightsRcfe.explanation.explanationDetail5')}:
            </p>
            <div className="text-gray-800 dark:text-white space-y-1 my-4">
              <div className="flex">
                <span className="font-medium mr-2">{t('contacts.phone')}:</span>
                <span>1-844-LET-US-NO (1-844-538-8766)</span>
              </div>

              <div className="flex">
                <span className="font-medium mr-2">{t('residents.forms.personalRightsRcfe.email')}:</span>
                <p>
                  LetUsNo@dss.ca.gov
                </p>
              </div>
              <div className="flex items-start">
                <span className="font-medium block mb-1">{t('residents.forms.personalRightsRcfe.mail')}:</span>
                <address className="not-italic ml-5">
                  California Department of Social Services
                  <br />
                  Community Care Licensing Division
                  <br />
                  Centralized Complaint and Information Bureau
                  <br />
                  744 P Street
                  <br />
                  Sacramento, CA 95814
                </address>
              </div>
            </div>
            <p>
            {t('residents.forms.personalRightsRcfe.elderAbuseReportingInfo')}
            </p>
            <p>
              <br/> {t('residents.forms.personalRightsRcfe.localOmbudsmanContactInfo')}
            </p>
            <div className="w-1/2">
              <FormField
                control={control}
                name="ombudsman_phone"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className="w-full dark:bg-zinc-800 mt-2"
                        type='number'
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </CustomCard>
    </>
  );
};

export default LegalNoticeSectionForm;

import  ContactsPage  from '@/modules/facesheet/presentation/pages/FaceSheetPage';
import { Route, Routes } from 'react-router-dom';

const FaceSheetRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContactsPage />}></Route>
    </Routes>
  );
};

export default FaceSheetRouter;
import { format, parseISO } from 'date-fns';
import { ResidentI } from "@/common/domain/Residents";

export const deathReportLicenseeDefaults = (resident: ResidentI) => ({
  facility_name: resident?.locations?.[0]?.name || '',
  facility_file_number: resident?.locations?.[0]?.licensing || '',
  facility_phone_number: resident?.locations?.[0]?.phone || '',
  facility_address: resident?.locations?.[0]?.address || '',
  facility_address_details: `${resident?.locations?.[0]?.city ?? ''} ${resident?.locations?.[0]?.state ?? ''} ${resident?.locations?.[0]?.zip ?? ''}`,
  client_name: `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`.trim(),
  dob: resident?.dob ? format(parseISO(resident?.dob), 'MM/dd/yyyy') : '',
  sex: resident?.sex || '',
  date_of_admission: resident?.other_information?.admission_date
    ? format(parseISO(resident.other_information.admission_date), 'MM/dd/yyyy')
    : '',
  date_time_of_death: '',
  place_of_death: '',
  immediate_cause_of_death: '',
  conditions_prior_to_death: '',
  immediate_action_taken: '',
  medical_treatment: '',
  nature_of_treatment: '',
  attending_physician: '',
  mortician_name: '',
  submitted_name: '',
  submitted_date: '',
  reviewed_name: '',
  reviewed_date: '',
  licensing: {
    checkbox: false,
    value: '',
  },
  adult_child_protective: {
    checkbox: false,
    value: '',
  },
  long_term_care: {
    checkbox: false,
    value: '',
  },
  parent_guardian: {
    checkbox: false,
    value: '',
  },
  law_enforcement: {
    checkbox: false,
    value: '',
  },
  placement_agency: {
    checkbox: false,
    value: '',
  },
});

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const ReportSubmissionForm = () => {
   const { control } = useFormContext();
   const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <FormField
        control={control}
        name="attending_physician_name"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-normal">
              {t('residents.forms.incidentReport.attendingPhysicianName')}
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                className="w-full dark:bg-zinc-800"
                maxLength={140}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-end">
        <div className="col-span-1 sm:col-span-3">
          <FormLabel className="font-semibold">
           {t('residents.forms.incidentReport.reportSubmittedBy')}:
          </FormLabel>
        </div>
        <FormField
          control={control}
          name="report_submitted_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.nameAndTitle')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="dark:bg-zinc-800 w-full"
                  maxLength={130}
                 />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="report_submitted_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('common.date')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="date"
                  className="dark:bg-zinc-800 w-full"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-end">
        <div className="col-span-1 sm:col-span-3">
          <FormLabel className="font-semibold">
            {t('residents.forms.incidentReport.reportApprovedBy')}:
          </FormLabel>
        </div>
        <FormField
          control={control}
          name="report_approved_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('residents.forms.nameAndTitle')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="dark:bg-zinc-800 w-full"
                  maxLength={130}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="report_approved_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">
                {t('common.date')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="date"
                  className="dark:bg-zinc-800 w-full"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default ReportSubmissionForm;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OtherInformationService from '../../infrastructure/services/OtherInformationService';
import { getOtherInformationPayload, OtherServiceState, saveOtherInformationPayload } from '../../infrastructure/types/otherInformation';

const initialState: OtherServiceState = {
    otherInformation: [],
    status: 'idle',
    error: null,
};

export const getOtherInformation = createAsyncThunk('/otherInformation',
    async (data: getOtherInformationPayload) => {
        return await OtherInformationService.getOtherInformation(data);
    }
);

export const saveOtherInformation = createAsyncThunk('/otherInformation/save',
    async (data: saveOtherInformationPayload) => {
        return await OtherInformationService.saveOtherInformation(data);
    }
);

const otherInformationSlice = createSlice({
    name: 'otherInformationSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getOtherInformation.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getOtherInformation.fulfilled, (state, action) => {
            state.status = 'idle';
            state.otherInformation = action.payload.data.data.sort(
                (a, b) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                }
            );
        });

        builder.addCase(getOtherInformation.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });
    }
});

export default otherInformationSlice.reducer;

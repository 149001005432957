import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VisitorsReportInterface from '../../infrastructure/interfaces/Visitors/VisitorsReportInterface';
import TrackerVisitorsService from '../../infrastructure/services/TrackerVisitorsService';
import VisitorsListInterface from '../../infrastructure/interfaces/Visitors/VisitorsListInterface';
import VisitorsSaveInterface from '../../infrastructure/interfaces/Visitors/VisitorsSaveInterface';
import VisitorsUpdateInterface from '../../infrastructure/interfaces/Visitors/VisitorsUpdateInterface';
import VisitorsDeleteInterface from '../../infrastructure/interfaces/Visitors/VisitorsDeleteInterface';
import VisitorsRegisterTokenInterface from '../../infrastructure/interfaces/Visitors/VisitorsRegisterTokenInterface';
import VisitorsValidatedTokenResponse from '../../infrastructure/interfaces/Visitors/VisitorsValidatedTokenResponseInterface';

interface IVisitorsState {
    visitors: VisitorsReportInterface[];
    validatedToken: VisitorsValidatedTokenResponse | null;
    current_page: number;
    last_page: number;
    total: number;
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: IVisitorsState = {
    visitors: [],
    validatedToken: null,
    current_page: 1,
    last_page: 0,
    total: 0,
    status: 'idle',
    error: null
};

export const getVisitors = createAsyncThunk("visitors/getVisitors", async (body: VisitorsListInterface) => {
  return await TrackerVisitorsService.getVisitors(body);
});

export const saveVisitors = createAsyncThunk("visitors/saveVisitors", async (body: VisitorsSaveInterface) => {
    return await TrackerVisitorsService.saveVisitors(body);
});

export const publicSaveVisitors = createAsyncThunk("visitors/publicSaveVisitors", async (body: VisitorsSaveInterface) => {
  return await TrackerVisitorsService.publicSaveVisitors(body);
});
  
export const updateVisitors = createAsyncThunk("visitors/updateVisitors", async (body: VisitorsUpdateInterface) => {
    return await TrackerVisitorsService.updateVisitors(body);
});

export const deleteVisitors = createAsyncThunk("visitors/deleteVisitors", async (body: VisitorsDeleteInterface) => {
    return await TrackerVisitorsService.deleteVisitors(body);
});

export const validateToken = createAsyncThunk("visitors/validateToken", async (body: VisitorsRegisterTokenInterface) => {
  return await TrackerVisitorsService.validateToken(body);
});

export const registerToken = createAsyncThunk("visitors/registerToken", async (body: VisitorsRegisterTokenInterface) => {
  return await TrackerVisitorsService.registerToken(body);
});

const visitorsSlice = createSlice({
  name: 'visitors',
  initialState,
  reducers: (create) => ({
    cleanVisitors: create.reducer((state, action) => {
        state.visitors = [];
        state.current_page = 1;
        state.last_page = 0;
        state.total = 0;
    })
  }),
  extraReducers: (builder) => {
    builder.addCase(getVisitors.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getVisitors.fulfilled, (state, action) => {
      state.status = 'idle';
      state.visitors = action.payload.trackervisitor.data;
      state.current_page = action.payload.trackervisitor.current_page;
      state.last_page = action.payload.trackervisitor.last_page;
      state.total = action.payload.trackervisitor.total;
    });

    builder.addCase(getVisitors.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });

    builder.addCase(validateToken.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(validateToken.fulfilled, (state, action) => {
      state.status = 'idle';
      console.log("action.payload.result", action.payload.result);
      state.validatedToken = action.payload.data;
    });

    builder.addCase(validateToken.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export const { cleanVisitors } = visitorsSlice.actions;
export default visitorsSlice.reducer;

import React, { createContext, useState, useContext, ReactNode } from 'react';

interface DateContextType {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}
const DateContext = createContext<DateContextType | undefined>(undefined);

export const DateEmarRoutineProvider = ({ children }: { children: ReactNode }) => {
  const [date, setDate] = useState<Date>(new Date());

  return (
    <DateContext.Provider value={{ date, setDate }}>
      {children}
    </DateContext.Provider>
  );
};

export const useDate = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDate must be used within a DateProvider');
  }
  return context;
};

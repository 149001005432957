import * as QRCode from 'qrcode';
import { Separator } from "@/common/presentation/components/ui/separator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { registerToken } from "../../slices/VisitorsSlice";
import { useDispatch } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { encryptToLaravel } from '@/utils/helpers/encryption.helper';

const TrackerVisitorsQr = () => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
    const [locationName, setlocationName] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const reactUrl = import.meta.env.VITE_REACT_FRONT_URL;

    useEffect(() => {
        const generateQRCode = async () => {
            const tokenParams = decodeURIComponent(atob(params.params));
            const tokenParamsJSON = JSON.parse(tokenParams);

            setImageUrl(tokenParamsJSON.image);
            setlocationName(tokenParamsJSON.location_name);

            const token = encryptToLaravel(tokenParams);

            dispatch(
                registerToken(
                    [
                        {
                            token
                        }
                    ]
                )
            );

            const loginUrl = `${reactUrl}/visitors-form?token=${encodeURIComponent(token)}`;
            
            try {
                const qrCodeUrl = await QRCode.toDataURL(loginUrl);
                setQrCodeUrl(qrCodeUrl);
            } catch (error) {
                console.error('Error generando el código QR:', error);
            }
        };
        generateQRCode();
    }, []);

    useEffect(() => {
        if (!params.params) {
            navigate('/dashboard');
            return;
        }
    }, [params]);
    
    return (
        <div className="md:flex justify-center items-center min-h-screen max-w-screen max-h-screen p-2 md:p-0">
            <div 
                className="grid grid-cols-1 gap-y-2 bg-white border-solid border-4 border-primary shadow-md rounded-2xl text-center relative overflow-hidden md:p-1"
                style={{ gridTemplateRows: "auto auto auto auto 1fr auto" }}
            >
                <div className="flex items-center justify-center h-full">
                    <img src={imageUrl} className="h-20 my-auto rounded-lg" />
                </div>

                {
                    locationName ? (
                        <h2 className="text-2xl font-bold text-primary">{locationName}</h2>
                    ): null
                }

                {
                    !qrCodeUrl ? (
                        <p className="text-gray-500">{t('trackers.trackerBedroom.loadingQrCode')}...</p>
                    ) : (
                        <div className="flex justify-center">
                            <img src={qrCodeUrl} alt="QR Code" className="w-52 h-52 rounded-md border-solid border-8 border-primary" />
                        </div>
                    )
                }

                <Separator className="bg-primary" />

                <div className='sm:p-0 md:px-6'>
                    <p className="flex justify-start items-center text-sm text-left text-[#3F3F46] mb-3 bg-primary/5 p-1">
                        <span className='text-2xl text-primary font-semibold mr-3 ml-3'>1</span>
                        {t("visitors_qr.first_step")}
                    </p>

                    <p className="flex justify-start items-center text-sm text-left text-[#3F3F46] mb-3 bg-primary/5 p-1">
                        <span className='text-2xl text-primary font-semibold mr-3 ml-3'>2</span>
                        {t("visitors_qr.second_step")}
                    </p>

                    <p className="flex justify-start items-center text-sm text-left text-[#3F3F46] mb-3 bg-primary/5 p-1">
                        <span className='text-2xl text-primary font-semibold mr-3 ml-3'>3</span>
                        {t("visitors_qr.third_step")}
                    </p>

                    <p className="flex justify-start items-center text-sm text-left text-[#3F3F46] mb-3 bg-primary/5 p-1">
                        <span className='text-2xl text-primary font-semibold mr-3 ml-3'>4</span>
                        {t("visitors_qr.fourth_step")}
                    </p>

                    <p className="flex justify-start items-center text-sm text-left text-[#3F3F46] bg-primary/5 p-1">
                        <span className='text-2xl text-primary font-semibold mr-3 ml-3'>5</span>
                        {t("visitors_qr.fifth_step")}
                    </p>
                </div>

                <div className="text-center">
                    <p className="text-xs font-bold text-[#3F3F46] mt-2">Powered by Caring Data</p>
                </div>
            </div>
        </div>
    );
};

export default TrackerVisitorsQr;

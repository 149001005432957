import { Route, Routes } from 'react-router-dom';
import { Locations } from '@/modules/locations/presentation/components/Location/Locations';
import { LocationForm } from '@/modules/locations/presentation/components/Location/LocationForm';
import { LocationRooms } from '@/modules/locations/presentation/components/Location/LocationRooms';
import { CompanyInfoForm } from '@/modules/locations/presentation/components/CompanyInfo/CompanyInfoForm';
import { Clients } from '@/modules/locations/presentation/components/Clients/Clients';

const FacilityRouter = () => {
  return (
    <Routes>
      <Route path="/" element={''} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/update-location" element={<LocationForm />} />
      <Route path="/rooms" element={<LocationRooms />} />
      <Route path="/client-manage" element={<CompanyInfoForm />} />
      <Route path="/clients" element={<Clients />} />
    </Routes>
  );
};

export default FacilityRouter;
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TrackerVisitorsReportColumns from "./TrackerVisitorsReportColumns";
import { t } from "i18next";
import { cleanVisitors, getVisitors } from "../../slices/VisitorsSlice";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

const TrackerVisitorsReport = () => {
    const dispatch = useDispatch();
    const configurations = useSelector((state: RootState) => state.configurations);
    const visitorsStore = useSelector((state: RootState) => state.visitors);
    const [columns, setColumns] = useState();
    const [accentColor, setAccentColor] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const { locationSelectedId } = useLocation();
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreRows, setHasMoreRows] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [resetData, setResetData] = useState(false);

    useEffect(() => {
        reset();
        setColumns(TrackerVisitorsReportColumns(fetchSummary));

        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        if (visitorsStore.visitors && Array.isArray(visitorsStore.visitors) && visitorsStore.visitors.length > 0) {
            const newData = visitorsStore.visitors;

            if (resetData) {
                setDataSource(newData);
            } else {
                setDataSource(prevData => [...prevData, ...newData]);
            }

            if (visitorsStore.current_page >= visitorsStore.last_page || visitorsStore.total < newData.length) {
                setHasMoreRows(false);
            } else {
                setHasMoreRows(true);
            }
        } else {
            setDataSource([]);
        }
    }, [visitorsStore.visitors]);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const fetchSummary = async (page: number, resetData: boolean = false) => {
        setResetData(resetData);

        if(resetData) setCurrentPage(1);

        dispatch(
            getVisitors(
                { 
                    location_id: locationSelectedId,
                    per_page: 20,
                    page
                }
            )
        );
    }

    const loadMore = () => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        fetchSummary(nextPage);
    };

    const reset = () => {
        setCurrentPage(0);
        setDataSource([]);
        dispatch(cleanVisitors());
    };

    return (
        <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("visitors.reportTitle")}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    {
                        columns ? (
                            <TableCustomVirtuoso
                                data={dataSource}
                                columns={columns}
                                renderCellContent={(index, column, data) => data[index][column.key]}
                                showSearchInput={false}
                                loadMore={loadMore}
                                hasMore={hasMoreRows}
                            />
                        ): null
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default TrackerVisitorsReport;

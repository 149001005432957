import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Separator } from '@/components/ui/separator';
import { FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from '@/common/presentation/components/ui/input';
import { CustomCard } from "../../shared/CustomCard";

export const PersonsResponsibleForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const initialFields = Array.from({ length: 3 }, (_, index) => index);

  return (
    <CustomCard>
      <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-10 mb-4">
        <h4 className="font-semibold text-base">
          7. {t('residents.forms.lic601EmergencyInfo.personResponsible')}
        </h4>
      </div>

      <Separator className='my-4 bg-primary'/>

      {initialFields.map((item) => (
        <React.Fragment key={item}>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
            <div className="col-span-1 sm:col-span-2 xl:col-span-1">
              <FormField
                control={control}
                name={`financial_responsible[${item}].name`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-normal">
                      {t('common.name')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} className="w-full dark:bg-zinc-800" maxLength={50}/>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={control}
              name={`financial_responsible[${item}].address`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-normal">
                    {t('common.address')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} className="w-full dark:bg-zinc-800" maxLength={100}/>
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name={`financial_responsible[${item}].telephone`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-normal">
                    {t('common.telephone')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} className="w-full dark:bg-zinc-800" maxLength={20} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <Separator className='my-4 bg-primary'/>
        </React.Fragment>
      ))}
    </CustomCard>
  );
};

// contactscategoriescreate.tsx
import { useEffect, useState, useCallback, useMemo } from 'react';
import { getContactsCategoriesPersonal, createContact, getListPhonesById, createPhones, deletePhone, fetchInitialData, fetchGeoLocation, getRelationship, validateName, getPhysicianSpecialty } from '../../slices/contactsSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/common/presentation/components/ui/accordion';
import { useNavigate, useSearchParams, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContactsPhonesCreate from './ContactsPhonesCreate';
import { Building, User, Users, Mail, Phone,  MapPin, Map, Flag, Archive, Globe, Plus, Info } from 'lucide-react';
import Select from 'react-select';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Label } from '@/common/presentation/components/ui/label';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import { ICountry } from '../../../../wizard/domain/signup.domain';
import CountrySelector from '../../../../wizard/presentation/components/CountrySelector/CountrySelector';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
import { Input } from '@/common/presentation/components/ui/input';
//import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, } from '@/common/presentation/components/ui/select';
import { Checkbox } from "@/components/ui/checkbox";

interface Phone {
    category: string;
    number: string;
    ext: string;
}

interface Category {
    id: string;
    name: string;
    type: string;
}

interface Contact {
    client_id: string;
    category: Category[];
    company: string;
    name: string;
    last_name: string;
    npi: string;
    email: string;
    relationship: string;
    physician_type_id: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    same_as: string;
    linkto: string;
    phones: Phone[];
}

interface ContactInfo {
    [key: string]: {
        category: string;
        contacts: Contact[];
    };
}

interface ContactsCategoriesCreateProps {
    type: number;
    contactInfo: ContactInfo;
    setContactInfo: (info: ContactInfo) => void;
}

export const ContactsCategoriesCreate = ({ type, contactInfo, setContactInfo }: ContactsCategoriesCreateProps) => {
    const { t } = useTranslation();
    const contacts = useSelector((state: RootState) => state.contacts.contacts); // Last state of contacts - Getcontacts or GetContactbyresident
    const allContacts = useSelector((state: RootState) => state.contacts.allContacts); //All contacts
    //console.log(contacts);
    const categories = useSelector((state: RootState) => state.contacts.categories);
    ///console.log(categories);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const locationPath = useLocation();
    const residentId = searchParams.get('r');
    const locationId = searchParams.get('l');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [currentCategory, setCurrentCategory] = useState<string>('');
    const { contactId } = useParams<{ contactId: string }>();
    //const { countries } = useSelector((state: any) => state.contacts.countries);
    const countries = useSelector((state: RootState) => state.contacts.countries);
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const [phones, setPhones] = useState([{ category: '', number: '', ext: '' }]);
    const validateMessage = useSelector((state: RootState) => state.contacts.validateName); // We select the message
    const [alertMessage, setAlertMessage] = useState("");
    
    const relationshipOptions = useSelector((state: RootState) => 
        state.contacts.relationship.map((item) => ({
          label: item.name, // The "name" field will be used as the visible label
          value: item.value, // The "value" field will be the selected value
        }))
    );

    const specialtyOptions = useSelector((state: RootState) => 
        state.contacts.specialty.map((item) => ({
          label: item.name, // The "name" field will be used as the visible label
          value: item.id, // The "id" field will be the selected value
        }))
    );

    //console.log(residentId);

    useEffect(() => {
        getInitialData();
    }, []);

    useEffect(() => {
        //console.log(countries);
    }, [countries]);

    useEffect(() => {
        dispatch(getContactsCategoriesPersonal());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getRelationship());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPhysicianSpecialty());
    }, [dispatch]);

    useEffect(() => {
        //console.log("id:"+contactId);
        if (contactId) {
            dispatch(getListPhonesById(contactId));
        }
    }, [dispatch, contactId]);

    useEffect(() => {
        if (categories.length > 0) {
            categories.forEach(category => {
                if (!contactInfo[category.id]) {
                    setContactInfo(prevState => ({
                        ...prevState,
                        [category.id]: {
                            category: category.id,
                            contacts: [{
                                category: [],
                                company: '',
                                name: '',
                                last_name: '',
                                npi: '',
                                relationship: '',
                                physician_type_id: '',
                                email: '',
                                address: '',
                                city: '',
                                state: '',
                                zipcode: '',
                                country: '',
                                same_as: '',
                                linkto: residentId,
                                phones: [],
                            }],
                        },
                    }));
                }
            });
        }
    }, [categories, contactInfo, residentId, setContactInfo]);

    const handleInputChange = (category: string, index: number, field: string, value: string) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            updatedContacts[index] = {
                ...updatedContacts[index],
                [field]: value,
                category: category,
                linkto: residentId,
            };

            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };
    
    const handleCheckboxChange = (category: string) => {
        setCheckedCategories((prevState) =>
            prevState.includes(category)
                ? prevState.filter((cat) => cat !== category)
                : [...prevState, category]
        );
    };

    const addContact = (category: string) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            updatedContacts.push({
                category: [],
                company: '',
                name: '',
                last_name: '',
                npi: '',
                relationship: '',
                physician_type_id: '',
                email: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                country: '',  
                linkto: residentId,
                phones: [],
            });
    
            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };

    useEffect(() => {
        // Find the ID of the "Responsible Person" category
        const responsiblePersonCategory = categories.find(
            (category) => category.name === "Responsible Person"
        );
    
        const responsiblePersonId = responsiblePersonCategory?.id;
    
        if (!responsiblePersonId) {
            //console.warn("Responsible Person category not found");
            return;
        }
    
        // Get contact data using dynamic ID
        const responsiblePersonData = contactInfo[responsiblePersonId]?.contacts[0];
    
        if (responsiblePersonData) {
            checkedCategories.forEach((category) => {
                setContactInfo((prevState) => {
                    // Get the existing category data
                    const categoryData = prevState[category] || { contacts: [] };
    
                    // Check if there's already data in the main inputs (first index)
                    if (categoryData.contacts[0]) {
                        // Update the first contact directly
                        categoryData.contacts[0] = {
                            ...categoryData.contacts[0],
                            ...responsiblePersonData,
                            category: category,
                            linkto: residentId,
                        };
                    } else {
                        // If no main contact exists, create one
                        categoryData.contacts = [
                            {
                                ...responsiblePersonData,
                                category: category,
                                linkto: residentId,
                            },
                        ];
                    }
    
                    return {
                        ...prevState,
                        [category]: categoryData,
                    };
                });
            });
        }
    }, [checkedCategories, contactInfo, residentId, categories]);      
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        
        const promises = [];
        
        Object.keys(contactInfo).forEach(categoryId => {
            const contacts = contactInfo[categoryId]?.contacts;
            if (Array.isArray(contacts)) {
                contacts.forEach(contactData => {
                    const isValidContact = contactData.name || contactData.email || contactData.company || contactData.address || contactData.phones.length > 0;
    
                    if (isValidContact) {
                        const createContactPromise = dispatch(createContact(contactData))
                            .unwrap()
                            .then((newContact) => {
                                const phonePromises = contactData.phones.map(phone =>
                                    dispatch(createPhones({ ...phone, contact_id: newContact.id }))
                                        .catch((error) => {
                                            //console.error('Failed to create phone:', error);
                                        })
                                );
                                return Promise.all(phonePromises);
                            })
                            .catch((error) => {
                                //console.error('Failed to create contact:', error);
                            });
    
                        promises.push(createContactPromise);
                    }
                });
            } else {
                //console.warn(`No valid contacts found for categoryId: ${categoryId}`);
            }
        });
    
        Promise.all(promises)
            .then(() => {
                if (locationPath.state?.from?.startsWith('/residents')) {
                    navigate(`/residents/update?r=${residentId}&t=contacts`);
                } else if (locationPath.state?.from === '/contacts') {
                    navigate('/contacts');
                } else {
                    navigate('/contacts');
                }
            })
            .catch((error) => {
                //console.error('Failed to complete all operations:', error);
            });
    };
    
    const handleContactSelect = async (selectedOption) => {
        //console.log("Selected option:", selectedOption); // Verificar la opción seleccionada
        setSelectedContact(selectedOption);
    
        if (selectedOption && currentCategory) {
            const contact = allContacts.find((c) => c.id === selectedOption.value); // Buscar el contacto en la lista
    
            if (contact) {
                try {
                    // Obtener teléfonos asociados
                    const action = await dispatch(getListPhonesById(selectedOption.value));
                    const phones = action.payload || []; // Garantizar un array de teléfonos
    
                    // Actualizar el estado de contactInfo
                    setContactInfo((prevState) => {
                        // Verificar si existe la categoría actual en el estado
                        const currentCategoryInfo = prevState[currentCategory] || { category: currentCategory, contacts: [] };
    
                        // Verificar si el contacto ya existe en la lista
                        const updatedContacts = currentCategoryInfo.contacts.map((contactInfo) =>
                            contactInfo.linkto === residentId
                                ? {
                                      ...contactInfo,
                                      category: currentCategory,
                                      company: contact.company,
                                      name: contact.name,
                                      last_name: contact.last_name,
                                      npi: contact.npi,
                                      email: contact.email,
                                      relationship: contact.relationship,
                                      physician_type_id: contact.physician_type_id,
                                      address: contact.address,
                                      zipcode: contact.zipcode,
                                      country: contact.country,
                                      state: contact.state,
                                      city: contact.city,
                                      phones, // Actualizar teléfonos
                                  }
                                : contactInfo
                        );
    
                        // Si no existe el contacto, agregarlo
                        const isNewContact = !updatedContacts.some((c) => c.linkto === residentId);
                        if (isNewContact) {
                            updatedContacts.push({
                                ...contact,
                                phones, // Añadir teléfonos
                                category: currentCategory,
                                linkto: residentId, // Asociar el contacto con el residente
                            });
                        }
    
                        return {
                            ...prevState,
                            [currentCategory]: {
                                ...currentCategoryInfo,
                                contacts: updatedContacts,
                            },
                        };
                    });
    
                    //console.log("Contact info updated successfully", contact);
                } catch (error) {
                    //console.error("Error fetching phones or updating contact info:", error);
                }
            } else {
                //console.error("Contact not found for selected option.");
            }
        }
    };    

    const handlePhoneInputChange = (
        category: string,
        contactIndex: number,
        phoneIndex: number,
        field: string,
        value: string
    ) => {
        setContactInfo((prevState: ContactInfo) => {
            // We get the current contacts and phones
            const updatedContacts = prevState[category]?.contacts || [];
            const currentPhones = updatedContacts[contactIndex]?.phones || [];
    
            // We make sure there is at least one phone
            const updatedPhones = currentPhones.length > 0 ? currentPhones : [{ category: '', number: '', ext: '' }];
    
            // We updated the specific phone
            updatedPhones[phoneIndex] = {
                ...updatedPhones[phoneIndex],
                [field]: value,
            };
    
            // We updated the corresponding contact
            updatedContacts[contactIndex] = {
                ...updatedContacts[contactIndex],
                phones: updatedPhones,
            };
    
            // We return the new state
            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };    
    
    const addPhone = (category, contactIndex) => {
        setContactInfo((prevState) => {
            // Clonar el estado previo de manera segura
            const updatedCategory = { ...prevState[category] };
            const updatedContacts = [...(updatedCategory.contacts || [])];
            const contact = { ...updatedContacts[contactIndex] };
    
            // Asegurarse de que el contacto tenga una lista de teléfonos
            const updatedPhones = [...(contact.phones || [])];
    
            // Añadir un nuevo teléfono
            updatedPhones.push({
                category: '',
                number: '',
                ext: '',
            });
    
            // Actualizar el contacto y la categoría
            contact.phones = updatedPhones;
            updatedContacts[contactIndex] = contact;
            updatedCategory.contacts = updatedContacts;
    
            // Devolver el nuevo estado
            return {
                ...prevState,
                [category]: updatedCategory,
            };
        });
    };    

    const removePhone = (category: string, contactIndex: number, phoneIndex: number, phoneId: string) => {
        //console.log("id de phone " + phoneId);
        dispatch(deletePhone(phoneId));
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            const updatedPhones = updatedContacts[contactIndex]?.phones || [];
            updatedPhones.splice(phoneIndex, 1);
            updatedContacts[contactIndex] = {
                ...updatedContacts[contactIndex],
                phones: updatedPhones,
            };
    
            return {
                ...prevState,
                [category]: {
                    ...prevState[category],
                    contacts: updatedContacts,
                },
            };
        });
    };    

    const filteredCategories = categories.filter(category => category.type === type);

    const contactOptions = allContacts
    .filter((contact: any) => contact.category?.id === currentCategory) // Filtrar contactos por categoría
    .reduce((uniqueContacts: any[], contact: any) => {
        if (!uniqueContacts.some((c) => c.id === contact.id)) {
            uniqueContacts.push(contact); // Agregar solo si no está en la lista
        }
        return uniqueContacts;
    }, [])
    .map((contact: any) => {
        const name = contact.name || ''; // Nombre del contacto
        const lastName = contact.last_name ? ` ${contact.last_name}` : ''; // Apellido
        const company = contact.company ? ` - ${contact.company}` : ''; // Compañía

        return {
            value: contact.id,
            label: `${name}${lastName}${company}`.trim(), // Formatear etiqueta
        };
    });

    const getInitialData = useCallback(async () => {
        const country = await getClientLocation();
        const response = await dispatch<any>(fetchInitialData(country));
    
        if (response.error) {
          return;
        }
    
        const { client_country, countries }: { client_country: string; countries: ICountry[] } = response.payload;
        const countryInfo = countries.find((country: ICountry) => country.code === client_country);
    
    }, []);

    const getClientLocation = async () => {
        try {
          let country = 'us';
          const response = await dispatch<any>(fetchGeoLocation());
    
          if (!response.error) {
            const { payload } = response;
            country = payload.country.toLocaleLowerCase();
          }
    
          return country;
        } catch (error) {
          return 'us';
        }
    };

    const countriesCode: CountryCode[] = useMemo(() => {
        return countries.map((country: ICountry) => country.code.toUpperCase());
    }, [countries]);
    
    const handleCountryChange = (value: string) => {
        setContactInfo(prevState => {
            const updatedContactInfo = { ...prevState };
            Object.keys(updatedContactInfo).forEach(categoryId => {
                const category = updatedContactInfo[categoryId];
                if (category && Array.isArray(category.contacts)) {
                    category.contacts.forEach(contactData => {
                        contactData.country = value.toUpperCase();
                    });
                }
            });
            return updatedContactInfo;
        });
    };

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const handleNameChange = async (name: string, categoryId: string, contactIdx: number, type: string) => {
        handleInputChange(categoryId, contactIdx, 'name', name);
    
        try {
            const response = await dispatch(validateName({ name, type })).unwrap(); // Pasa el nombre y tipo
            if (response.message === "Contact name already exists in Contacts for the specified category." || response.message === "Contact name already exists in Physicians.") {
                setAlertMessage("Alert! We found a similar contact, please review the contact list for this category.");
            } else {
                setAlertMessage("");
            }
        } catch (error) {
            //console.error("Error validating name:", error);
            setAlertMessage(""); // Limpia la alerta si ocurre un error
        }
    };
    
    return (
        <form onSubmit={handleSubmit} className="space-y-6">
        <Accordion type="single" collapsible className="w-full">
            {filteredCategories.map((category) => (
                <AccordionItem key={category.id} value={category.id} className="border border-gray-300 rounded-md px-4 mb-5">
                    <AccordionTrigger style={{color: accentColor}} className="flex flex-row items-center text-lg font-bold" onClick={() => setCurrentCategory(category.id)}>{category.name}</AccordionTrigger>
                    <AccordionContent>
                        {contactInfo[category.id]?.contacts.map((contact, contactIndex) => (
                            <>
                                <hr style={{ borderTop: '1px solid', borderColor: accentColor }} className="mb-4" />

                                <div className="mb-4">
                                    <Label className="block text-sm font-bold mb-2 text-gray-400" htmlFor="contact-search">
                                        {t('contacts.search_contact')}
                                    </Label>
                                    <Select
                                        id="contact-search"
                                        options={contactOptions}
                                        value={selectedContact}
                                        onChange={(selectedOption) => handleContactSelect(selectedOption)}
                                        placeholder={t('contacts.search_select_contact') + '...'}
                                    />
                                </div>

                                {/* Si la categoría es "Name of Hospital to be Taken in an Emergency" */}
                                {category.name === "Name of Hospital to be Taken in an Emergency" ? (
                                    <>
                                        {/* 1ra fila */}
                                        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
                                            {[{ label: t('contacts.name'), name: 'name', icon: <User className="absolute left-3 text-gray-400" /> }].map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                        {field.label}
                                                    </Label>
                                                    <div className="flex items-center">
                                                        {field.icon}
                                                        <input
                                                            type="text"
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={contact[field.name]}
                                                            onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                            className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* 2da fila */}
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                                            {[
                                                { label: t('contacts.address'), name: 'address', icon: <MapPin className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.city'), name: 'city', icon: <Map className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.state'), name: 'state', icon: <Flag className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.zip_code'), name: 'zipcode', icon: <Archive className="absolute left-3 text-gray-400" /> },
                                            ].map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                        {field.label}
                                                    </Label>
                                                    <div className="flex items-center">
                                                        {field.icon}
                                                        <input
                                                            type="text"
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={contact[field.name]}
                                                            onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                            className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* 1ra fila */}
                                        {alertMessage && (
                                            <div className="mb-3 w-full p-4 bg-yellow-100 text-yellow-800 border-b-4 border-yellow-500 z-50 grid grid-cols-1">
                                                {alertMessage}
                                            </div>
                                        )}
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            {[
                                                { label: "Name", name: "name", icon: <User className="absolute left-3 text-gray-400" /> },
                                                { label: "Last Name", name: "last_name", icon: <User className="absolute left-3 text-gray-400" /> },
                                                { label: "Email", name: "email", icon: <Mail className="absolute left-3 text-gray-400" /> },
                                            ].map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <label
                                                        className="block text-sm font-bold mb-2 text-gray-400"
                                                        htmlFor={`${field.name}-${category.id}-${contactIndex}`}
                                                    >
                                                        {field.label}
                                                    </label>
                                                    <div className="flex items-center">
                                                        {field.icon}
                                                        <input
                                                            type="text"
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={contact[field.name]}
                                                            onChange={(e) => {
                                                                if (field.name === "name") {
                                                                    handleNameChange(e.target.value, category.id, contactIndex, category.name);
                                                                } else {
                                                                    handleInputChange(category.id, contactIndex, field.name, e.target.value);
                                                                }
                                                            }}
                                                            className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="grid grid-cols-2 gap-4">
                                            {(category.type === 2
                                                ? [
                                                    { 
                                                        label: t('contacts.specialty'), 
                                                        name: 'physician_type_id', 
                                                        isDropdown: true, 
                                                        options: specialtyOptions, 
                                                        icon: <Users className="absolute left-3 text-gray-400" /> 
                                                    },
                                                    { 
                                                        label: t('contacts.npi'), 
                                                        name: 'npi', 
                                                        icon: <Archive className="absolute left-3 text-gray-400" /> 
                                                    },
                                                ]
                                                : [
                                                    { 
                                                        label: t('contacts.company'), 
                                                        name: 'company', 
                                                        icon: <Building className="absolute left-3 text-gray-400" /> 
                                                    },
                                                    { 
                                                        label: t('contacts.relationship'), 
                                                        name: 'relationship', 
                                                        isDropdown: true, 
                                                        options: relationshipOptions, 
                                                        icon: <Users className="absolute left-3 text-gray-400" /> 
                                                    },
                                                ]).map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <Label className="block text-sm font-bold mb-2 text-gray-400" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                        {field.label}
                                                    </Label>
                                                    
                                                    {field.isDropdown ? (
                                                        <Select
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={field.options.find(option => option.value === contact[field.name]) || null}
                                                            onChange={(selectedOption) => {
                                                                if (selectedOption) {
                                                                    handleInputChange(category.id, contactIndex, field.name, selectedOption.value);
                                                                    if (field.name === "physician_type_id") {
                                                                        // console.log("Selected specialty:", selectedOption.value);
                                                                    }
                                                                }
                                                            }}
                                                            options={field.options}
                                                            placeholder={t('contacts.select_option') + '...'}
                                                        />
                                                    ) : (
                                                        <div className="flex items-center">
                                                            {field.icon}
                                                            <input
                                                                type="text"
                                                                name={field.name}
                                                                id={`${field.name}-${category.id}-${contactIndex}`}
                                                                value={contact[field.name]}
                                                                onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                                className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <hr style={{ borderTop: '1px solid', borderColor: accentColor }} className="mt-3 mb-3" />

                                        {/* 3ra fila */}
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            {[
                                                { label: t('contacts.address'), name: 'address', icon: <MapPin className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.city'), name: 'city', icon: <Map className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.state'), name: 'state', icon: <Flag className="absolute left-3 text-gray-400" /> },
                                            ].map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <Label className="block text-sm font-bold mb-2 text-gray-400" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                        {field.label}
                                                    </Label>
                                                    <div className="flex items-center">
                                                        {field.icon}
                                                        <input
                                                            type="text"
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={contact[field.name]}
                                                            onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                            className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* 4ta fila */}
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            {[
                                                { label: t('contacts.zip_code'), name: 'zipcode', icon: <Archive className="absolute left-3 text-gray-400" /> },
                                                { label: t('contacts.country'), name: 'country', icon: <Globe className="absolute left-3 text-gray-400" /> },
                                            ].map((field) => (
                                                <div key={field.name} className="relative mb-4">
                                                    <Label className="block text-sm font-bold mb-2 text-gray-400" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                        {field.label}
                                                    </Label>
                                                    <div className="">
                                                        {field.name === 'country' ? (
                                                            <CountrySelector
                                                                defaultCountry={contact.country?.code?.toUpperCase() || ''}
                                                                country={contact.country?.code?.toUpperCase() || ''}
                                                                name="country"
                                                                onChange={(e) => handleCountryChange(e.value.toLowerCase())}
                                                                countries={countriesCode}
                                                            />
                                                        ) : (
                                                            <div className="flex items-center">
                                                                {field.icon}
                                                                <input
                                                                    type="text"
                                                                    name={field.name}
                                                                    id={`${field.name}-${category.id}-${contactIndex}`}
                                                                    value={contact[field.name]}
                                                                    onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                                    className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <hr style={{ borderTop: '1px solid', borderColor: accentColor }} className="mt-3 mb-3" />
                                    </>
                                )}

                                {/* Section for phones */}
                                <div className="w-full mb-4">
                                    <ContactsPhonesCreate
                                        category={category.id}
                                        contactIndex={contactIndex}
                                        phones={contact.phones}
                                        handlePhoneInputChange={handlePhoneInputChange}
                                        addPhone={addPhone}
                                        removePhone={removePhone}
                                    />
                                </div>
                            </>
                        ))}

                        {/* Section for copying contacts by category (Responsible Person Only) */}
                        {category.name === 'Responsible Person' && (
                            <div className="mt-10">
                                <div className="flex items-center">
                                    <h3 className="text-lg font-semibold mb-2 text-gray-600">
                                        {t('contacts.same_as')}
                                    </h3>

                                    {/* Tooltip */}
                                    <div className="group relative flex ml-2 pb-1">
                                        <Info className="w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-700" />

                                        {/* Tooltip Content */}
                                        <div className="absolute text-center z-10 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[160px] px-3 py-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg">
                                            {t('contacts.tooltip_contacts')}
                                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-8 border-transparent border-t-gray-900"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-wrap">
                                    {categories
                                        .filter(cat => cat.type === 1 && cat.id !== category.id)
                                        .map(cat => {
                                            const isChecked = checkedCategories.includes(cat.id);
                                            return (
                                                <div
                                                    key={cat.id}
                                                    className={`mr-4 mb-2 flex items-center rounded-md p-2 transition-colors ${
                                                        isChecked
                                                            ? `bg-[${accentColor}] bg-opacity-10`
                                                            : ``
                                                    } hover:bg-gray-100`}
                                                >
                                                    <Checkbox
                                                        id={`checkbox-${cat.id}`}
                                                        name={cat.name}
                                                        checked={isChecked}
                                                        onCheckedChange={() => handleCheckboxChange(cat.id)}
                                                        className="mr-2 accent-primary"
                                                    />
                                                    <label
                                                        htmlFor={`checkbox-${cat.id}`}
                                                        className="text-gray-600"
                                                    >
                                                        {cat.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>


                        )}

                        <div className="w-full flex justify-end px-2 mb-4">
                            <Button
                                type="button"
                                onClick={() => addContact(category.id)}
                                className="flex items-center px-3 py-2 text-white rounded"
                            >
                                <Plus className="mr-2" />
                                {t('contacts.add_contact')}
                            </Button>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            ))}
        </Accordion>
        <div className="flex justify-end mt-6">
            <Button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                {t('contacts.create_contact')}
            </Button>
        </div>
        {/* Botón flotante */}
        <Button size="sm"
            type="submit" 
            className="fixed right-5 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg focus:outline-none focus:shadow-outline"
        >
            {t('contacts.save')}
        </Button>
    </form>
    );
};

ContactsCategoriesCreate.propTypes = {
    type: PropTypes.number.isRequired,
    contactInfo: PropTypes.object.isRequired,
    setContactInfo: PropTypes.func.isRequired,
};
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { NavigationI } from '@/modules/residents/domain/models/interfaces/Forms/Navigation.domain';
import { File } from 'lucide-react';

interface Props {
  forms: NavigationI[];
}

export const FormsSection = ({ forms }: Props) => {
  const { params } = useRouteParams();
  const { t } = useTranslation();

  return (
    <div className='text-sm w-full text-zinc-600 dark:text-white'>
      <hr className="w-full border-t-1 border-primary my-6" />
      <div className="flex flex-col w-full">
        {forms.map((form, index) => (
          <Link
            key={form.path}
            to={`/residents/forms/${form.path}?r=${params.r}`}
            className={`items-center w-full border-t border-primary text-left
              hover:bg-primary/10 hover:text-primary transition-all ${index === forms.length - 1 ? 'border-b' : ''} `}
            >
              <div className="flex items-center gap-2 px-2 py-2">
                <File className="h-4 w-4 flex-shrink-0" />
                {t(`residents.forms.linkForms.${form.key}`)}
              </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { AppDispatch, RootState } from '@/store/store';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import * as locationActions from '@/modules/locations/presentation/slices/locationSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import FormDestruction from './FormDestruction/FormDestruction';
import ReportDestruction from './ReportDestruction/ReportDestruction';

const DestructionsPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { location, status: locationStatus } = useSelector((state: RootState) => state.locations.location);
  const { params, setParam } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState<string>('form');

  useEffect(() => {
    const storedLocation = sessionStorage.getItem('location_selected');

    if (storedLocation && !searchParams.has('l')) {
      setSearchParams({ l: storedLocation });
    }

    const fetchInitialData = async () => {
      const locationId = searchParams.get('l') || storedLocation;

      if (locationId && locationId !== 'all-locations') {
        try {
          await dispatch(locationActions.getLocation(locationId)).unwrap();
        } catch (error) {
          console.error('Error fetching location:', error);
          return;
        }
      } else {
        dispatch(residentActions.resetResident());
        dispatch(locationActions.removeLocation());
      }
    };

    fetchInitialData();
  }, [searchParams, setSearchParams, dispatch]);

  const handleBackClick = () => navigate('/medications');

  const onSelectDestruction = (tab: string) => {
    setParam('t', tab);
    setSelectedTab(tab);
  };

  if (locationStatus === 'loading') return <SplashScreen />;

  return (
    <div className="gap-4 my-4">
      <div className="flex flex-col gap-4 my-4">
        <div className="flex items-center justify-between">
          <Button variant="ghost" onClick={handleBackClick}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back
          </Button>
          <h2 className="font-semibold text-2xl">Destruction Medication</h2>
          <Tabs
            defaultValue={selectedTab}
            onValueChange={(e) => {
              onSelectDestruction(e);
            }}
            className="ml-auto"
          >
            <TabsList>
              <TabsTrigger value="form" className="data-[state=active]:bg-primary data-[state=active]:text-white">
                Form
              </TabsTrigger>
              <TabsTrigger value="report" className="data-[state=active]:bg-primary data-[state=active]:text-white">
                Report
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <ResidentSelector
          onSelect={(residentId) => residentId === 'all-residents' && dispatch(residentActions.resetResident())}
          locationId={searchParams.get('l') || 'all-locations'}
          residentId={params.r}
          showAllResidents={selectedTab !== 'form'}
        />
        {selectedTab === 'form' ? <FormDestruction /> : <ReportDestruction />}
      </div>
    </div>
  );
};

export default DestructionsPage;

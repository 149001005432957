import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/common/presentation/components/ui/select";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import TrackerService from "@/modules/tracker/infrastructure/services/TrackerService";
import { Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHygiene, updateHygiene } from "../../slices/TrackerHygieneSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";

const TrackerHygieneReportUpdateDialog = (props: { row: any }) => {
    const dispatch = useDispatch();
    const trackerHygiene = useSelector((state: RootState) => state.trackerHygiene);
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [types, setTypes] = useState([]);
    const { toast } = useToast();
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        type: "",
        detail: ""
    });

    useEffect(() => {
        setTypes(
            trackerHygiene.typesHygienes
        );
    }, [trackerHygiene]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleTypeChange = (value: any) => {
        setValues({
             ...values,
             type: value
         });
     };

    const openEditDialog = () => {
        setOpenEdit(true);
        
        setValues({
            type: props.row.data.type,
            detail: props.row.data.detail
        });
    }

    const closeEditDialog = () => {
        setValues({
            type: "",
            detail: ""
        });

        setOpenEdit(false);
    }

    const handleEdit = async () => {

        const data = {
            "id": props.row.id,
            "id_data": props.row.data.id,
            "type": values.type,
            "detail": values.detail || "",
            "value": props.row.data.value,
            "resident_id": props.row.data.resident_id,
            "date": props.row.data.date
        };

        dispatch(updateHygiene([data]));

        toast(
            {
                description: t("cleaning.report.summary.edit.toast.success")
            }
        );
        
        setOpenEdit(false);
        fetchData();

        return true;
    }

    const fetchData = async () => {
        dispatch(getHygiene(
            {
                location_id: locationSelectedId
            }
        ));
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >                
                <div className="mb-5">
                    <Label htmlFor="detail" className="text-right">
                        {t("cleaning.report.summary.edit.form.detail")}
                    </Label>

                    <Input
                        id="detail"
                        name="detail"
                        className="col-span-3"
                        value={values.detail}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"type"} className="text-right justify-self-start">
                        {t('trackers.trackerhygiene.type')}
                    </Label>
                    
                    <Select onValueChange={handleTypeChange} defaultValue={values.type.id}>
                        <SelectTrigger>
                            <SelectValue placeholder={t("cleaning.report.summary.edit.form.areaPlaceholder")} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    types.length ? types.map(value => <SelectItem value={value.id}>{value.type_name}</SelectItem>) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className='flex justify-end mt-5'>
                    <Button type="submit" onClick={handleEdit}>
                        {t('trackers.trackerhygiene.accept')}
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
}

export default TrackerHygieneReportUpdateDialog;

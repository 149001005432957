const UtilService = {
    sortObjectsAlphabetically: (objectsArray: any[], field: string) => {
        const arrayForSort = [...objectsArray];

        return arrayForSort.sort(
            (first, second) => {
                if(first[field]) {
                    return first[field].localeCompare(second[field]);
                }
            }
        );
    },
    decodeHtmlEntities(html: string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },
    hexToRgb: (hex: string, opacity: string) => {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})` : null;
    }
};

export default UtilService;

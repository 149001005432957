import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { cleanFilters, removeNoteFilter, setNoteFilters } from '../../slices/NoteSlice';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Label } from '@/common/presentation/components/ui/label';
import { ListFilter, FilterX } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RootState } from '@/store/store';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputsNoteFilters } from './inputsNoteFilters';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

interface NoteFiltersProps {
  crudState: CrudState;
  setTabValue?: (value: string) => void;
}

export function NoteFilters({ crudState, setTabValue }: NoteFiltersProps) {
  // Initial 
  const { t } = useTranslation();
  const { locationSelectedId } = useLocation();

  // Redux
  const dispatch = useDispatch();
  const noteFilters = useSelector((state: RootState) => state.notes.noteFilters);
  const optionsTimeFilter = ["last15days", "last3Months", "last6Months", "lastYear", "allTime"];

  const selectedFilter = (type: string) => {
    let findStaff = noteFilters.find(filter => filter[0] == type);
    if (findStaff) {
      return findStaff[1]
    }
    return type == 'betweenTime' ? 'allTime' : 'all';
  }

  // Behavoir
  const updateNoteFilters = (key: string, value: string) => {
    dispatch(setNoteFilters(['nextPage', 1]));
    if (value == '') {
      dispatch(removeNoteFilter(key));
    } else {
      dispatch(setNoteFilters([key, value]));
    }
  };

  const handleTimeFilter = async (time: string) => {
    updateNoteFilters('betweenTime', time != 'allTime' ? time : '');
  };

  const goCleanFilters = async () => {
    dispatch(cleanFilters());
    dispatch(setNoteFilters(['nextPage', 1]));
    dispatch(setNoteFilters(['location', locationSelectedId]));
    dispatch(setNoteFilters(['betweenTime', "last15days"]));
    if (crudState.showingStaffNotes) {
      dispatch(setNoteFilters(['target_type', 'staff']));
    }
    setTabValue && setTabValue('all');
  };

  return (
    <Popover>
      <PopoverTrigger>
        <ListFilter className='text-primary'></ListFilter>
      </PopoverTrigger>
      <PopoverContent>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <InputsNoteFilters
              crudState={crudState}
              handleCategoryFilter={(categoryId: string) => {
                updateNoteFilters('category', categoryId != 'all' ? categoryId : '');
              }}
              handleResidentFilter={(residentId: string) => {
                updateNoteFilters('resident', residentId != 'all' ? residentId : '');
              }}
              handleStaffFilter={(staffId: string) => {
                updateNoteFilters('staff', staffId != 'all' ? staffId : '');
              }}
              selectedFilter={selectedFilter}
            />
            <div className="grid grid-cols-3 items-center gap-3">
              <Label htmlFor="betweenTime">{t('notes.timeRange')}</Label>
              <Select value={selectedFilter('betweenTime')} onValueChange={handleTimeFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="betweenTime">
                  {optionsTimeFilter?.map((optionTime: any) => (
                    <SelectItem key={optionTime} value={optionTime}>
                      {t(`notes.optionsTimeSelect.${optionTime}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button variant="default" type="button" onClick={goCleanFilters}>
              <FilterX className="mr-2 h-4 w-4" />
              {t('notes.clear')}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

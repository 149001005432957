
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { HistoryTableRow } from './FormsHistory.types';
import { useTranslation } from 'react-i18next';
import { HistoryTableActions } from './HistoryTableActions';

export const HistoryTableColumns = (): ColumnDef<HistoryTableRow>[] => {
  const { t } = useTranslation();

  return [
    {
      header: t('residents.forms.incidentReport.incidentTypesName'),
      cell: ({ row }) => {
        const incidentTypes = row.original.data?.incident_types || {};
        const activeIncidents = Object.keys(incidentTypes)
          .filter((key) => incidentTypes[key])
          .map((key) => t(`residents.forms.incidentReport.incidentTypes.${key}`))
          .join(', ');

        return (
          <div className="whitespace-normal break-words">
            {activeIncidents || t('general.not_specified')}
          </div>
        );
      },
    },
    {
      header: t('common.date'),
      cell: ({ row }) => (
        <div className="text-center whitespace-normal break-words lg:whitespace-nowrap ">
          {format(new Date(row.original.created_at), 'MM/dd/yyyy hh:mm a')}
        </div>
      ),
      meta: {
        className: 'text-center whitespace-normal break-words lg:whitespace-nowrap'
      }
    },
    {
      accessorKey: 'actions',
      header: () => {
        return <div className="w-12">Actions</div>;
      },
      cell: ({ row }) => (
        <HistoryTableActions
          document_id={row.original.id}
          data={row.original.data}
          form_id={row.original.form_id}
          url={row.original.url}
          formKey={row.original.key}
        />
      ),
      meta: {
        className: 'w-20 text-center'
      }
    }
  ]
};

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const LicenseeDateForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="mx-auto">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <FormField
          control={control}
          name="licensee"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('residents.forms.residentPersonalProperty.licenseeOrDesignatedRepresentative')}</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="w-full dark:bg-zinc-800 dark:text-white"
                  readOnly/>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="date_completed"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('residents.forms.residentPersonalProperty.dateCompleted')}</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="date"
                  className="w-full dark:bg-zinc-800"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default LicenseeDateForm;

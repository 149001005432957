import { useTranslation } from 'react-i18next';
import { OverviewPrnTableActions } from './OverviewPrnTableActions';

const useOverviewRoutineColumns = () => {
  const { t } = useTranslation();

  const overviewRoutineColumns = [
    {
      key: 'date',
      id: 'date',
      labelTranslationKey: 'emar-prn.column-prn.date',
      value: 'date',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'resident',
      id: 'resident',
      labelTranslationKey: 'emar-prn.column-prn.resident',
      value: 'resident',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'medication_name',
      id: 'medication_name',
      labelTranslationKey: 'emar-prn.column-prn.medication_name',
      value: 'medication_name',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'reason',
      id: 'reason',
      labelTranslationKey: 'emar-prn.column-prn.reason',
      value: 'reason',
      visible: true,
      sortable: true,
      width: '10%'
    },
    {
      key: 'result',
      id: 'result',
      labelTranslationKey: 'emar-prn.column-prn.result',
      value: 'result',
      visible: true,
      sortable: true,
      width: '10%'
    },
    {
      key: 'staff',
      id: 'staff',
      labelTranslationKey: 'emar-prn.column-prn.staff',
      value: 'staff',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'action',
      id: 'action',
      labelTranslationKey: 'emar-prn.column-prn.actions',
      value: 'action',
      visible: true,
      sortable: false,
      width: '20%',
      render: (data) => <OverviewPrnTableActions data={data} />
    }
  ];

  const columnsWithTranslation = overviewRoutineColumns.map((column) => ({
    ...column,
    label: t(column.labelTranslationKey)
  }));

  return columnsWithTranslation;
};

export default useOverviewRoutineColumns;

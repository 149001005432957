import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';

const ConsentForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="leading-relaxed">
        <p className="text-sm font-normal leading-10 space-y-2 mx-1">
          {t('residents.forms.emergencyMedicalConsentForm.consent.parte1')}

          <Input
            {...control.register("facility_name")}
            type="text"
            className={cn('w-auto dark:bg-zinc-800 inline-block border-b border-gray-400 py-1 mx-1 text-center')}
            readOnly
          />{' '}

          {t('residents.forms.emergencyMedicalConsentForm.consent.parte2')}

          <Input
            {...control.register("resident_name")}
            type="text"
            className={cn('w-auto dark:bg-zinc-800 inline-block border-b border-gray-400 py-1 mx-1 text-center')}
            readOnly
          />{' '}

          {t('residents.forms.emergencyMedicalConsentForm.consent.parte3')}
        </p>
      </div>
    </>
  );
};

export default ConsentForm;

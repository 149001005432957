import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { MonthAndYearSelector } from '@/common/presentation/components/CustomCalendar/MonthAndYearSelector';
import { TimePicker } from '@/common/presentation/components/CustomCalendar/TimePicker';

interface CustomCalendarProps {
  onChange?: (date: Date) => void;
  MonthAndYearPicker?: boolean;
  timePicker?: boolean;
  baseSelectorYear?: number;
  maxSelectorYear?: number;
  classMonthYearSelect?: string;
  selectedDate?: Date;
  highlightedDates?: Date[]; // Fechas a resaltar
  highlightedStyles?: React.CSSProperties;
}

export const CustomCalendar = ({
  onChange,
  MonthAndYearPicker = false,
  timePicker = false,
  baseSelectorYear,
  maxSelectorYear,
  classMonthYearSelect = '',
  selectedDate,
  highlightedDates = [],
  highlightedStyles = { backgroundColor: 'blue', color: 'white' }
}: CustomCalendarProps) => {
  const [date, setDate] = useState<Date>(selectedDate || new Date());
  const [currentMonth, setCurrentMonth] = useState<Date>(selectedDate || new Date());
  const [isMonthYearSelecting, setIsMonthYearSelecting] = useState(false);

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
      setCurrentMonth(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (selectedDate: Date | undefined) => {
    if (!selectedDate || isMonthYearSelecting) return;
    const updatedDate = new Date(date);
    updatedDate.setFullYear(selectedDate.getFullYear());
    updatedDate.setMonth(selectedDate.getMonth());
    updatedDate.setDate(selectedDate.getDate());
    setDate(updatedDate);
    setCurrentMonth(updatedDate);
    onChange?.(updatedDate);
  };

  const handleTimeChange = (newTime: string) => {
    const [hours, minutes] = newTime.split(':').map(Number);
    const updatedDate = new Date(date);
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    updatedDate.setSeconds(0);
    setDate(updatedDate);
    onChange?.(updatedDate);
  };

  const handleMonthYearChange = (newDate: Date) => {
    setIsMonthYearSelecting(true);
    const updatedDate = new Date(date);
    updatedDate.setFullYear(newDate.getFullYear());
    updatedDate.setMonth(newDate.getMonth());
    setCurrentMonth(newDate);

    // Usar setTimeout para asegurar que el calendario permanezca abierto
    setTimeout(() => {
      setIsMonthYearSelecting(false);
    }, 100);
  };

  const handleMonthChange = (month: Date) => {
    if (!isMonthYearSelecting) {
      setCurrentMonth(month);
    }
  };

  return (
    <div className="flex flex-col p-2 items-center w-full">
      {MonthAndYearPicker && (
        <MonthAndYearSelector
          date={currentMonth}
          onChange={handleMonthYearChange}
          baseSelectorYear={baseSelectorYear}
          maxSelectorYear={maxSelectorYear}
          className={classMonthYearSelect}
        />
      )}

      <div className="flex flex-col sm:flex-row justify-center items-center w-full">
        <div className="w-full sm:w-auto sm:max-w-md">
          <Calendar
            mode="single"
            selected={date}
            onDayClick={handleDateChange}
            month={currentMonth}
            onMonthChange={(month) => setCurrentMonth(month)}
            defaultMonth={currentMonth}
            initialFocus
            modifiers={{
              highlighted: highlightedDates
            }}
            modifiersStyles={{
              highlighted: highlightedStyles
            }}
          />
        </div>

        {timePicker && (
          <div className="w-full sm:w-auto px-2">
            <TimePicker onChange={(value: string) => handleTimeChange(value)} value={format(date, 'HH:mm')} />
          </div>
        )}
      </div>
    </div>
  );
};

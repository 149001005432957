import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { Button } from "@/common/presentation/components/ui/button";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon, RotateCcw } from "lucide-react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { createRef, useEffect, useState } from "react";

import { getUsersToNotify, getCategories, saveTaskEvents } from "../../../calendar/presentation/slices/CalendarSlice.ts";
import { ICategory } from "@/modules/calendar/domain/calendar.domain";
import { useTranslation } from "react-i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider.tsx";
import { Textarea } from "@/common/presentation/components/ui/textarea.tsx";
import { useDateHelpers } from "@/utils/helpers/dates.helper.ts";

export function MedicalOutingsCreateForm() {
    const { toast } = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const calendarCategories: ICategory[] = useTypedSelector((state) => state.calendar.categories);
    const usersToNotify: any = useSelector((state: RootState) => state.calendar.usersToNotify);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const { formatDate } = useDateHelpers();
    const pictureInput = createRef();
    const [values, setValues] = useState({
        date: new Date(),
        doctorName: "",
        specialty: "",
        notes: "",
        picture: new File([], "")
    });

    useEffect(() => {
        dispatch(getUsersToNotify({ locationId: 'all-locations' }));
        dispatch(getCategories());
    }, [])

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleFileChange = (file: File) => {
        setValues({
            ...values,
            file: file.target.files[0]
        });
    }

    const cleanInputs = () => {
        setValues({
            date: null,
            doctorName: "",
            specialty: "",
            notes: "",
            picture: new File([], "")
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("date", values?.date?.toISOString());
        formData.append("doctorName", values.doctorName);
        formData.append("specialty", values.specialty);
        formData.append("notes", values.notes);
        formData.append("picture", values.picture);

        saveEventMedicalAppInCalendar();
    }

    const saveEventMedicalAppInCalendar = async() => {
        const residentId: string = params.r;
        const locationId: string = locationSelectedId;
        const categoryMedicalApp: string = (calendarCategories?.find((c: any)=> c.name === 'Medical Appointment'))?.id ?? '';
        if(!categoryMedicalApp) return;
    
        if(!residentId){
            toast({
                description: t('calendar.seletedResident'),
                className: 'bg-red-500 text-white'
            });
            return;
        }

        const fetchData = {
            title: `${t('calendar.medicalApp.medApp')}, ${t('calendar.medicalApp.specialty')}: ${values.specialty}`,
            startDate: values.date,
            startTime: format(new Date(values.date ?? ''), "hh:mm a"),
            endDate: values.date,
            endTime: format(new Date(values.date ?? ''), "hh:mm a"),
            allDay: false,
            type: 'event',
            options: 'does_not_repeat',
            detail: `${t('calendar.medicalApp.doctorName')}: ${values.doctorName} ${values.notes ? ` \n${ t('calendar.medicalApp.note') }: `+ values.notes : ''} `,
            residentId: residentId,
            relatedTo: 'residents',
            locationId: locationId,
            category: categoryMedicalApp,
            usersToNotify: usersToNotify,
        }

        const response = await dispatch(saveTaskEvents(fetchData));

        if (response.payload.result === 'ok') {
          toast({
            description: t('calendar.medicalApp.dataSavedCorrectly'),
            className: 'bg-green-500 text-white'
          });
        }  
        
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="general-outings-form grid grid-cols-2 gap-4">                                            
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"appointmentDate"} className="text-right justify-self-start">{t("outings.fields.medicalDate")}</Label>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                "w-[280px] justify-start text-left font-normal w-100",
                                !values.date && "text-muted-foreground"
                            )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? formatDate(values.date) : <span>mm/dd/yyyy - hh:mm aa</span>}
                            </Button>
                        </PopoverTrigger>

                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                timePicker={true}
                                onChange={handleDateChange}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <div className="grid grid-cols-1 items-center align-self-start gap-1">
                    <Label htmlFor={"doctorName"} className="text-right justify-self-start">{t("outings.fields.DoctorName")}</Label>

                    <Input
                        id={"doctorName"}
                        name={"doctorName"}
                        type="text"
                        value={values.doctorName}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"specialty"} className="text-right justify-self-start">{t("outings.fields.doctorSpecialty")}</Label>

                    <Input
                        id={"specialty"}
                        name={"specialty"}
                        type="text"
                        value={values.specialty}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1" style={{ gridColumn: "1 / span 2" }}>
                    <Label htmlFor={"notes"} className="text-right justify-self-start">{t("outings.fields.details")}</Label>

                    <Textarea
                        id={"notes"}
                        name={"notes"}
                        type="text"
                        value={values.notes}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1" style={{ gridColumn: "1 / span 2" }}>
                    <Label htmlFor={"picture"} className="text-right justify-self-start">{t("outings.fields.picture")}</Label>

                    <div className="flex">
                        <Input
                            id={"picture"}
                            name={"picture"}
                            type="file"
                            ref={pictureInput}
                            style={{ outline: 'none' }}
                            className="col-span-3 px-2 py-2 rounded-md"
                            onChange={handleFileChange}
                        />

                        <Button type="button" variant="outline" size="icon" className="ml-1" onClick={() => pictureInput.current.value = ""}>
                            <RotateCcw className="w-4 h-4" />
                        </Button>
                    </div>
                </div>
            </div>

            <div className="w-100 mt-5 flex flex-row justify-end">
                <Button type="submit" className="mr-2">{t("common.save")}</Button>
            </div>
        </form>
    );
}

import { useMemo, useState } from 'react';
import moment from 'moment';
import { AlertI, AlertsResponseI, AlertType, HiddenAlertsI } from '@/common/domain/Alerts';

export const useResidentAlerts = (
  residentAlerts: { status: string; data: AlertsResponseI[] },
  residentCustomAlerts: { status: string; data: AlertI[] },
  customizedReports?: { status: string; data: AlertI[] }
) => {
  const [hiddenAlerts, setHiddenAlerts] = useState<HiddenAlertsI>({});

  const alerts: AlertType[] = useMemo(() => {
    if (
      residentAlerts.status === 'loading' ||
       residentCustomAlerts.status === 'loading' ||
       (customizedReports && customizedReports.status === "loading")
    ) {
      return [];
    }

    const currentDate = moment().format('YYYY-MM-DD');

    const defaultAlerts: AlertType[] = residentAlerts?.data?.map((alert) => ({
      ...alert,
      type: 'default'
     })) || [];

    let customAlertsArray: AlertI[] = [];
    if (Array.isArray(residentCustomAlerts.data)) {
      customAlertsArray = residentCustomAlerts.data;
    } else if (typeof residentCustomAlerts.data === 'object' && residentCustomAlerts.data !== null) {
      customAlertsArray = Object.values(residentCustomAlerts.data);
    }

    const customAlerts: AlertType[] = customAlertsArray.map((alert) => ({
      ...alert,
      type: 'custom',
      date: currentDate,
      residents: alert.residents.filter(
        (resident) => !hiddenAlerts[alert.id]?.includes(resident.id)
      ),
    }));

    const customResidentReportAlerts: AlertType[] = customizedReports && customizedReports.data.map((alert: AlertI) => ({
      ...alert,
      type: 'resident_reports',
      date: alert.start_date,
      users: alert.users.filter((user:any) => !hiddenAlerts[alert.id]?.includes(user.id))
    })) || [];

    const combinedAlerts = [...defaultAlerts, ...customAlerts, ...customResidentReportAlerts];
    return combinedAlerts.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
  }, [residentAlerts, residentCustomAlerts, hiddenAlerts]);

  const totalAlertsCount = useMemo(() => {
    return alerts.reduce((total, alert) => {
      if (alert.type === 'default') {
        return total + 1;
      } else {
        return total + (alert as AlertI).residents.length;
      }
    }, 0);
  }, [alerts]);

  return { alerts, totalAlertsCount, hiddenAlerts, setHiddenAlerts };
};

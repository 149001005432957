import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { AxiosError } from 'axios';

interface UpdatedLocationData {
  name: string;
  admin: string;
  phone_lic: string;
  licensing: string;
  phone: string;
  admin_email: string;
  npi?: string;
  max_beds: number;
  category: string;
  address: string;
  city: string;
  state: string;
  status: boolean;
  location_description?: string;
}

interface RoomI {
  id: string;
  floor: number;
  name: string;
}

interface DataEmailI {
  locationName: string;
  clientName: string | undefined;
  contactName: string | undefined;
  clientPhoneNumber: string | undefined;
}

const LocationService = {
  getAllLocations: async () => {
    try {
      return await axios.get('locations');
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching locations';
      throw new Error(message);
    }
  },

  getLocationsType: async () => {
    try {
      return await axios.get('locations/location-types');
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching location types';
      throw new Error(message);
    }
  },

  getFolders: async (locationId: string) => {
    try {
      return await axios.get(`locations/document-folders/${locationId}`);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching folders';
      throw new Error(message);
    }
  },

  getLocation: async (locationId: string) => {
    try {
      return await axios.get(`locations/${locationId}`);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error fetching location';
      throw new Error(message);
    }
  },


  saveLocationDocument: async (data: any) => {
    const formData = new FormData();
    formData.append('data', data.data);
    data.files.forEach((file: File, index: number) => {
      if (file instanceof File) {
        formData.append(`file_${index}`, file);
      } else {
        console.error(`file_${index} it is not an object file`, file);
      }
    });

    const response = await axios.post(`/locations/documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  },

  updateDocument: async (documentId: string, data: any) => {
    const response = await axios.put(`/locations/documents/${documentId}`, { ...data });
    return response;
  },

  deleteResidentDocument: async (documentId: string) => {
    try {
      const response = await axios.delete(`/locations/documents/${documentId}`);
      return response;
    } catch (error) {
      console.error('Error deleting document:');
      throw error;
    }
  },

  getUsersByLocation: async (locationId: string) => {
    try {
      return await axios.get(`user-locations/${locationId}`);
    } catch (error) {
      throw new Error('Error fetching userLocations: ' + error);
    }
  },

  getRoomsByLocation: async (locationId: string, search: string, per_page: number, page: number) => {
    try {
      return await axios.get(`locations/rooms/${locationId}`, {
        params: { search, per_page, page },
      });
    } catch (error) {
      throw new Error('Error fetching userLocations: ' + error);
    }
  },

  saveRoom: async (data: RoomI) => {
    try {
      const response = await axios.post('locations/save-room', data);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error saving room';
      throw new Error(message);
    }
  },

  updateRoom: async (data: RoomI,) => {
    try {
      const response = await axios.put(`locations/update-room/${data.id}`, data);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error updating room';
      throw new Error(message);
    }
  },

  deleteRoom: async (id: string) => {
    try {
      const response = await axios.delete(`locations/delete-room/${id}`);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error deleting room';
      throw new Error(message);
    }
  },


  updateLocation: async (locationId: string, updatedData: UpdatedLocationData) => {
    try {
      const response = await axios.put(`locations/${locationId}`, updatedData);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error updating location';
      throw new Error(message);
    }
  },

  sendEmail: async (data: DataEmailI) => {
    try {
      const response = await axios.post('locations/send-email', data);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as any;
      const message = data?.message || 'Error sending email';
      throw new Error(message);
    }
  },
};

export default LocationService;

//import axios from '@/common/services/axios';
import { axios, nestApi } from '@/common/infrastructure/providers/AxiosContextProvider';
import { IContactsResumeData }  from "../../presentation/components/PersonalContacts/ContactsSchema";

const ContactsService = {
    /*
    getContacts: async () => {
        let url = '/contacts';
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },
    */

    getContacts: async (page: number = 1, per_page: number = 20, filters: IContactsResumeData = {}) => {
        let url = `/contacts?page=${page}&per_page=${per_page}`;
    
        // Añadir los filtros si están presentes
        if (filters.title) {
            url += `&title=${encodeURIComponent(filters.title)}`;
        }
        if (filters.resident_id) {
            url += `&resident_id=${encodeURIComponent(filters.resident_id)}`;
        }
        if (filters.location_id) {
            url += `&location_id=${encodeURIComponent(filters.location_id)}`;
        }
    
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },    

    /*
    getContactsByResident: async (residentId: string) => {
        let url = `/contacts/${residentId}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },
    */

    getContactsByResident: async (residentId: string, page: number = 1, per_page: number = 20, filters: IContactsResumeData = {}) => {
        let url = `/contacts/${residentId}?page=${page}&per_page=${per_page}`;
        // Añadir los filtros si están presentes
        if (filters.title) {
            url += `&title=${encodeURIComponent(filters.title)}`;
        }
        if (filters.resident_id) {
            url += `&resident_id=${encodeURIComponent(filters.resident_id)}`;
        }
        if (filters.location_id) {
            url += `&location_id=${encodeURIComponent(filters.location_id)}`;
        }
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getResidentById: async (residentId: string) => {
        let url = `/contacts/detailresident/${residentId}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getContactById: async (id: string) => {
        let url = `/contacts/detail/${id}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    createPhones: async (data: any) => {
        let url = '/contacts/createphone';
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getListPhonesById: async (id: string) => {
        let url = `/contacts/listphones/${id}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    updatePhones: async (data: any) => {
        let url = '/contacts/updatephones';
        try {
            const response = await axios.post(url, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deleteContact: async (contactId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletecontact/${contactId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deletePhone: async (phoneId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletephone/${phoneId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    deletePhonesByContactId: async (contactId: string) => {
        try {
            const response = await axios.delete(`/contacts/deletePhonesByContactId/${contactId}`);
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message);
        }
    },

    getListPhonesItems: async () => {
        let url = '/contacts/listitemsphones';
        try {
          const response = await axios.post(url);
          return response.data;
        } catch (error: any) {
          throw new Error(error?.response?.data?.message);
        }
    },

    getInitialData: async (country: string) => {
        let url = '/contacts/data';
        try {
          const response = await axios.post(url, { country_code: country });
          return response.data;
        } catch (error) {
          throw new Error(`Error getting the information ${error}`);
        }
    },

    getGeoLocationClient: async () => {
        let url = '/localization/ip-info';
        try {
          const response = await nestApi.get(url);
          return response.data;
        } catch (error: any) {
          throw new Error(error);
        }
    },

    getRelationship: async () => {
        let url = '/contacts/getRelationship';
        try {
          const response = await axios.post(url);
          return response.data;
        } catch (error: any) {
          throw new Error(error?.response?.data?.message);
        }
    },

    validateName: async (name: string, type: string) => {
        const url = '/contacts/validateName';
        try {
            const response = await axios.post(url, { name, type }); // Incluye el tipo en el body
            return response.data;
        } catch (error: any) {
            throw new Error(error?.response?.data?.message || "Error validating name");
        }
    },

    getPhysicianSpecialty: async () => {
        let url = '/contacts/getPhysicianSpecialty';
        try {
          const response = await axios.post(url);
          return response.data;
        } catch (error: any) {
          throw new Error(error?.response?.data?.message);
        }
    },
}

export default ContactsService;
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import dateFormats from '../dateFormats';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';

export const useDateHelpers = () => {
    const { t, i18n } = useTranslation();
    const { configurations } = useConfigurationsProvider();

    const formatDate = (
        dateTime: string,
        hours: boolean = true,
        onlyHours: boolean = false,
        useTimezone: boolean = true
    ): string => {
        const currentLang = i18n.language;

        const parseDateTime = (dt: string) => {
            const format = hours
                ? dateFormats[currentLang]?.dateTimeFormat.replace('dd', 'DD').replace('yyyy', 'YYYY')
                : dateFormats[currentLang]?.dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY');

            const parsedMoment = moment(dt, format, true);

            return parsedMoment.isValid()
                ? parsedMoment
                : moment(new Date(dt));
        };

        const hasHours = dateTime.includes(':');

        let dateTimeString = dateTime;
        if (!hasHours) {
            dateTimeString = `${dateTime} 10:00:00`;
        }

        const laTime = useTimezone
            ? parseDateTime(dateTimeString).tz(configurations.timezone)
            : parseDateTime(dateTimeString);

        if (onlyHours) {
            return laTime.format('hh:mm a');
        }

        const formatString = hours
            ? dateFormats[currentLang]?.dateTimeFormat
                .replace('dd', 'DD')
                .replace('yyyy', 'YYYY')
            : dateFormats[currentLang]?.dateFormat
                .replace('dd', 'DD')
                .replace('yyyy', 'YYYY');

        return laTime.format(formatString);
    };

    return { formatDate, t };
};
import { useTranslation } from 'react-i18next';
import { CardHeader, CardContent} from '@/components/ui/card';
import { CustomCard } from '../../shared/CustomCard';
import FormHeader from '../../shared/FormHeader';
import ResidentDetailForm from "./ResidentDetailForm";

const ResidentDetailSection = () => {
   const { t } = useTranslation();

  return (
    <CustomCard>
      <CardHeader className="p-0">
        <FormHeader
          leftContent={
            <>
               <p className='uppercase'>
                {t("residents.forms.formHeader.stateOfCalifornia")}
              </p>
              <p className='uppercase'>
                {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
              </p>
            </>
          }
          rightContent={
            <>
              <p className='uppercase'>
                {t("residents.forms.formHeader.californiaDeptSocialServices")}
              </p>
              <p className='uppercase'>
                {t("residents.forms.formHeader.communityCareLicensingDivision")}
              </p>
            </>
          }
        />
      </CardHeader>
      <CardContent className="p-0 flex-1">
        <h2 className="text-xl font-bold text-center mb-4">
          {t("residents.forms.residentPersonalProperty.clientResidentPersonalPropertyAndValuables")}
        </h2>
        <p className="text-sm text-zinc-700 mb-4 text-justify dark:text-white">
          {t("residents.forms.residentPersonalProperty.facilitiesSafeguard.part1")} {' '}
          {t("residents.forms.residentPersonalProperty.facilitiesSafeguard.part2")} {' '}
          {t("residents.forms.residentPersonalProperty.facilitiesSafeguard.part3")} {' '}
          {t("residents.forms.residentPersonalProperty.facilitiesSafeguard.part4")} {' '}
          {t("residents.forms.residentPersonalProperty.facilitiesSafeguard.part5")} {' '}
        </p>
        <ResidentDetailForm />
      </CardContent>
    </CustomCard>
  );
};

export default ResidentDetailSection;

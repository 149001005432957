import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const ResidentDetailForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <FormField
          control={control}
          name="resident_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t('residents.forms.residentPersonalProperty.nameOfClientResident')} </FormLabel>
              <FormControl className='dark:bg-zinc-800'>
                <Input
                  {...field}
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="social_security"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('residents.forms.residentPersonalProperty.socialSecurityNo')}
              </FormLabel>
              <FormControl className='dark:bg-zinc-800'>
                <Input
                  {...field}
                  readOnly
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default ResidentDetailForm;

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/ui/input';

const TableInputForm = ({ name }: { name: string }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse border border-zinc-300 dark:border-zinc-600">
        <thead>
          <tr className="bg-zinc-100 dark:bg-zinc-900 text-center font-semibold">
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('common.number')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('common.description')}</th>
          <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('common.date')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('general.location')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 14 }).map((_, index) => (
            <tr key={index} className="odd:bg-white even:bg-zinc-50 dark:bg-zinc-900">
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`${name}.${index}.number`}
                  control={control}
                  render={({field}) => (
                      <Input
                        {...field}
                        type="number"
                        value={field.value ?? ''}
                        className="w-full dark:bg-zinc-800"
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          if (input.value.length > 15) {
                            input.value = input.value.slice(0, 15);
                          }
                          field.onChange(input.value);
                        }}
                      />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`${name}.${index}.description`}
                  control={control}
                  render={({ field }) => (
                      <Input
                        {...field}
                        maxLength={30}
                        value={field.value ?? ''}
                        className="w-full dark:bg-zinc-800"
                      />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`${name}.${index}.date`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      value={field.value ?? ''}
                      className="w-full dark:bg-zinc-800"
                    />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`${name}.${index}.location`}
                  control={control}
                  render={({ field }) => (
                      <Input
                        {...field}
                        maxLength={20}
                        value={field.value ?? ''}
                        className="w-full dark:bg-zinc-800"
                      />
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableInputForm;

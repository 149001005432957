import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useResidentStore } from '@/modules/residents/domain/stores/primary-information/residentStore';
import { RootState } from '@/store/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const SwitchStatus = () => {
  const { resident, status: residentStatus } = useSelector((state: RootState) => state.residents.resident);

  const { status: residentStatusReq, updateResidentStatus } = useResidentStore();
  const [activeStatus, setActiveStatus] = useState<boolean>(resident?.status === 'active');
  const { t } = useTranslation();

  const handleActiveStatus = async (checked: boolean) => {
    setActiveStatus(checked);

    try {
      await updateResidentStatus({ residentId: resident.id, status: checked ? 'active' : 'inactive' });
      toast({
        title: `Resident status has been updated to ${checked ? 'active' : 'inactive'}`,
        variant: 'default'
      });
    } catch (error: any) {
      console.error(error.message);
      toast({
        title: 'Error updating resident status',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 p-4">
        <div className="flex items-center space-x-2">
          <Switch
            id="active_status"
            checked={activeStatus}
            onCheckedChange={handleActiveStatus}
            disabled={residentStatusReq === 'loading' || residentStatus === 'loading'}
          />
          <Label htmlFor="airplane-mode">{t('residents.activeStatus')}</Label>
        </div>
        {/* <div className="flex items-center space-x-2">
          <Switch id="alert_medicine" />
          <Label htmlFor="airplane-mode">{t('residents.enableMedicine')}</Label>
        </div>
        <div className="flex items-center space-x-2 col-span-2">
          <Switch id="enable_bowel" />
          <Label htmlFor="airplane-mode">{t('residents.enableBowel')}</Label>
        </div> */}
      </div>
    </>
  );
};

import { create } from 'zustand';

type ExceptionState = {
  isOpen: boolean;
  data: any;
  date: Date | undefined;
  time: string[];
  locationId?: string;
  residentId?: string;
  isGeneral?: boolean;
  onOpen: (
    data: any,
    date: Date,
    time: string[],
    locationId?: string,
    residentId?: string,
    isGeneral?: boolean
  ) => void;
  onClose: () => void;
};

export const useNewExceptionDialog = create<ExceptionState>((set) => ({
  isOpen: false,
  data: null,
  date: undefined,
  time: [],
  onOpen: (data, date, time = [], locationId, residentId, isGeneral = false) =>
    set({ isOpen: true, data, date, time, locationId, residentId, isGeneral }
    ),
  onClose: () => set({ isOpen: false })
}));

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { BaseForm, GeneratePdfRequest, FormResponseBase, SaveFormResponse } from '../../domain/models/interfaces/Forms/forms.domain';

const FormsService = {
  getFormHistory: async (residentId: string, key: string) => {
    const response = await axios.get(`/residents/forms/${residentId}/${key}`);
    return response;
  },

  saveForm: async (data: BaseForm): Promise<SaveFormResponse> => {
    const response = await axios.post<SaveFormResponse>('/residents/forms', data);
    return response.data;
  },

  saveFormAndGeneratePdf:  async (data: BaseForm): Promise<FormResponseBase> => {
    const response = await axios.post<FormResponseBase>('/residents/forms/pdf', data);
    return response.data;
  },

  generatePdf: async (data: GeneratePdfRequest): Promise<FormResponseBase> => {
    const response = await axios.post<FormResponseBase>('/residents/forms/generate-pdf', data);
    return response.data;
  },

  // saveForm: async (data: any) => {
  //   const response = await nestApi.post('/forms/generate', data);
  //   return response;
  // },

  deleteForm: async (id: string) => {
    const response = await axios.delete(`/residents/forms/${id}`);
    return response;
  },

  deleteDocument: async (document_id: string) => {
    const response = await axios.delete(`/residents/documents/${document_id}`);
    return response;
  },

  generateHistoryPdf: async (form_id: string) => {
    const response = await axios.get(`/residents/forms/generate-history-pdf/${form_id}`, { responseType: 'blob' });
    return response;
  },

  getFormResources: async (form_id: string) => {
    const response = await axios.get(`/residents/forms-resources/${form_id}`);
    return response;
  }
};

export default FormsService;

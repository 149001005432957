import { t } from "i18next";
import { useDateHelpers } from "@/utils/helpers/dates.helper";
import RenderHygineReportActionsColumns from "./RenderHygineReportActionsColumns";

const useHygineReportColumns = () => {
    const { formatDate } = useDateHelpers();

  const CleaningColumns = [
    {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'trackers.Date/Time',
        render: (element: any) => formatDate(element.created_at),
        value: 'date',
        visible: true,
        width: '16%'
    },
    {
        key: 'resident',
        id: 'resident',
        labelTranslationKey: 'trackers.Resident',
        render: (element: any) => element.data.resident.first_name + " " + element.data.resident.last_name,
        value: 'resident',
        visible: true,
        width: '16%'
    },
    {
        key: 'type',
        id: 'type',
        labelTranslationKey: 'trackers.Type',
        render: (element: any) => element.data.type.type_name + " " + "(" + element.data.value + ")",
        value: 'type',
        visible: true,
        width: '16%'
    },
    {
        key: 'detail',
        id: 'detail',
        labelTranslationKey: 'trackers.Detail',
        render: (element: any) => element.data.detail,
        value: 'detail',
        visible: true,
        width: '16%'
    },
    {
        key: 'staff',
        id: 'staff',
        labelTranslationKey: 'trackers.Staff',
        render: (element: any) => element.data.created_by.first_name + ' ' + element.data.created_by.last_name,
        value: 'staff',
        visible: true,
        width: '16%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: RenderHygineReportActionsColumns,
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(CleaningColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = CleaningColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useHygineReportColumns;

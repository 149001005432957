import { create } from 'zustand';

type SystemStyle = {
  layoutPadding: boolean;
  setLayoutPadding: (value: boolean) => void;
  collapseSidebar: boolean;
  setCollapseSidebar: (value: boolean) => void;
};

export const useSystemStyle = create<SystemStyle>((set) => ({
  layoutPadding: true,
  setLayoutPadding: (value: boolean) => set({ layoutPadding: value }),
  collapseSidebar: false,
  setCollapseSidebar: (value: boolean) => set({ collapseSidebar: value })
}));

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { FormField, FormItem } from '@/components/ui/form';
import { incidentTypes } from '../../constants/IncidentReport.constants';

const IncidentTypeChecklist = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
    {incidentTypes.map((type) => (
      <div key={type.name}>
        <FormField
          control={control}
          name={`incident_types.${type.name}`}
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center space-x-2">
              <label className="flex items-center space-x-2">
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <span className='text-sm wrap'> {t(`residents.forms.incidentReport.incidentTypes.${type.name}`)} </span>
                </label>
              </div>
            </FormItem>
          )}
        />
      </div>
    ))}
  </div>
  );
};

export default IncidentTypeChecklist;

import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const MAX_FILE_SIZE = 100000000; // 100 MB

const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/webp': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'text/plain': []
};

const formSchema = z.object({
  label: z
    .string()
    .min(3, { message: 'Document name must be at least 3 characters.' })
    .max(30, { message: 'Document name must not be longer than 30 characters.' })
    .regex(/^[a-zA-Z0-9'_\- ]*$/, {
      message: "Only alphanumeric characters, apostrophes ('), hyphens (-), underscores (_), and spaces are allowed."
    }),
  comments: z.string().optional(),
  files: z.array(
    z
      .instanceof(File)
      .refine((file) => Object.keys(ACCEPTED_FILE_TYPES).includes(file.type), {
        message: 'Invalid file type.'
      })
      .refine((file) => file.size <= MAX_FILE_SIZE, {
        message: 'Max file size is 2MB.'
      })
  )
});

export type FormValues = z.infer<typeof formSchema>;

interface Props {
  status: string;
  onSubmit: (data: FormValues) => void;
  defaultValues?: Partial<FormValues>;
}

export const UploadDocumentForm = ({ status, onSubmit, defaultValues }: Props) => {
  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
    mode: 'onChange'
  });

  const handleSubmit = (data: FormValues) => {
    onSubmit({ ...data });
    form.reset();
  };

  return (
    <>
      <ScrollArea className="h-full w-full m-0 p-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 px-1">
            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Document Name</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" disabled={status === 'loading'} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="comments"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Comments</FormLabel>
                  <Textarea {...field} disabled={status === 'loading'} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="files"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    File <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <MultiFileDropzone
                      className="w-auto outline-none min-h-[130px] min-w-[200px]"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={status === 'loading'}
                      dropzoneOptions={{
                        accept: ACCEPTED_FILE_TYPES,
                        maxSize: MAX_FILE_SIZE,
                        maxFiles: 1
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="col-span-full w-full"
              disabled={!form.formState.isValid || status === 'loading'}
            >
              {status === 'loading' && <Loader className="size-4 animate-spin mr-2" />}
              Save Document
            </Button>
          </form>
        </Form>
      </ScrollArea>
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomAlertsParams } from '@/common/domain/Alerts';
import ResidentService from '../../infrastructure/services/ResidentsService';
import TrackerAlertService from '../../infrastructure/services/Alerts';
import { VitalsSettingFormI } from '../../infrastructure/types/trackerAkerts';
import ResidentProfileService from '../../infrastructure/services/ResidentInformationService';
import { ContactsResponse, PhysicianData } from '../../domain/models/interfaces/Residents.domain';


interface ResidentState {
  resident: any;
  residents: any[];
  status: 'idle' | 'loading' | 'failed';
  residentsStatus: 'idle' | 'loading' | 'failed';
  error: string | null;
  residentsError: string | null;
  alerts: any;
  contacts: ContactsResponse;
  physician: PhysicianData;
}

const initialState: ResidentState = {
  resident: null,
  residents: [],
  status: 'idle',
  residentsStatus: 'idle',
  error: null,
  residentsError: null,
  alerts: {
    vitals: {
      data: [],
      status: 'idle',
    },
    customAlerts: {
      data: [],
      status: 'idle',
    },
  },
  contacts: {
    result: "ok",
    contacts: {},
  },
  physician: {
    result: "ok",
    physician: {
      id: "",
      name: "",
      npi: "",
      phones: [],
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      email: "",
    },
  },
};

export const getResidentById = createAsyncThunk('resident/getResident', async (residentId: string) => {
  try {
    const response = await ResidentService.getResidentById(residentId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching resident: ' + error);
  }
});

export const getPublicResidentById = createAsyncThunk('resident/getPublicResident', async ({ residentId, token }: { residentId: string, token: string }) => {
  try {
    const response = await ResidentService.getPublicResidentById(residentId, true, token);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching resident: ' + error);
  }
});

export const saveResidentDocument = createAsyncThunk('resident/saveResidentDocument', async (data: any) => {
  try {
    const response = await ResidentService.saveResidentDocument(data);
    return response.data;
  } catch (error) {
    throw new Error('Error saving resident document: ' + error);
  }
});
export const updateResidentDocument = createAsyncThunk(
  'residents/updateResidentDocument',
  async ({ documentId, data }: { documentId: string; data: any }) => {
    try {
      const response = await ResidentService.updateDocument(documentId, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating resident document: ' + error);
    }
  }
);

export const deleteResidentDocument = createAsyncThunk(
  'resident/deleteResidentDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await ResidentService.deleteResidentDocument(documentId);
      return response.data;
    } catch (error) {
      return rejectWithValue('Error deleting resident document: ');
    }
  }
);
export const deleteResidentHiDocument = createAsyncThunk(
  'resident/deleteResidentDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await ResidentService.deleteResidentDocument(documentId);
      return response.data;
    } catch (error) {
      return rejectWithValue('Error deleting resident document: ');
    }
  }
);
export const updateResidentDocumentHardCopy = createAsyncThunk(
  'resident/updateResidentDocumentHardCopy',
  async ({ documentId, hard_copy }: { documentId: string; hard_copy: boolean }) => {
    try {
      const response = await ResidentService.updateHardCopyStatus(documentId, hard_copy);
      return response.data;
    } catch (error) {
      throw new Error('Error updating resident document hard copy status: ' + error);
    }
  }
);

export const getResidentsByLocation = createAsyncThunk(
  'resident/getResidentsByLocation',
  async (locationId: string, { getState }) => {
    const state: any = getState();
    if (state.resident.residents.length > 0 && state.resident.lastLocationId === locationId) {
      return state.resident.residents;
    }
    try {
      const response = await ResidentService.getResidentsByLocation(locationId);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching residents: ' + error);
    }
  }
);

export const postResidentVitalSetting = createAsyncThunk('resident/trackerAlerts', async (formData: VitalsSettingFormI) => {
  try {
    const response = await TrackerAlertService.postVitalSettings(formData);
    return response.data;
  } catch (error) {
    throw new Error('Error posting residents vital settings: ' + error);
  }
});

export const fetchResidentVitalSetting = createAsyncThunk('resident/getTrackerAlerts', async (residentId: string) => {
  try {
    const response = await TrackerAlertService.getVitalSettings(residentId);
    return response;
  } catch (error) {
    throw new Error('Error getting residents vital settings: ' + error);
  }
});

export const saveSpecialInformation = createAsyncThunk(
  'residents/saveSpecialInformation',
  async ({ resident_id, special_information }: { resident_id: string; special_information: string }) => {
    try {
      const response = await ResidentService.saveSpecialInformation({ resident_id, special_information });
      return response.data;
    } catch (error) {
      throw new Error('Error saving or updating resident special information: ' + error);
    }
  }
);

export const fetchContactsByResident = createAsyncThunk(
  'resident/fetchContactsByResident',
  async (
    { resident_id, category }: { resident_id: string; category: string[] },
    { rejectWithValue }
  ) => {
    try {
      const data = await ResidentProfileService.getContactByResident(resident_id, category);
      return data; 
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPhysicianByResident = createAsyncThunk(
  'resident/fetchPhysicianByResident',
  async (resident_id: string, { rejectWithValue }) => {
    try {
      const data = await ResidentProfileService.getPhysicianByResident(resident_id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchVitalResidentAlerts = createAsyncThunk(
  'alerts/vitalResidentAlerts',
  async (params : {location_id?: string  | null, resident_id?: string[] | null}) => {
  try {
    const response = await ResidentProfileService.getVitalResidentAlerts(params.location_id, params.resident_id);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchCustomAlerts = createAsyncThunk(
  'customAlerts',
  async (params: CustomAlertsParams) => {
  try {
    const response = await ResidentProfileService.getCustomAlerts(params);
    return response;
  } catch (error) {
    throw error;
  }
});


const residentSlice = createSlice({
  name: 'resident',
  initialState,
  reducers: {
    setResident: (state, action: PayloadAction<any>) => {
      state.resident = action.payload;
    },
    resetResident: (state) => {
      state.resident = null;
      state.contacts = [];
      state.physician = [];
      state.status = 'idle';
      state.error = null;
    },
    resetResidents: (state) => {
      state.residents = [];
      state.residentsStatus = 'idle';
      state.residentsError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResidentById.pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(getResidentById.fulfilled, (state, action) => {
        state.resident = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getResidentById.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
      builder
      .addCase(getPublicResidentById.pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(getPublicResidentById.fulfilled, (state, action) => {
        state.resident = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getPublicResidentById.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
    builder
      .addCase(updateResidentDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateResidentDocument.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateResidentDocument.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
      });
      builder
      .addCase(saveSpecialInformation.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(saveSpecialInformation.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(saveSpecialInformation.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
      });
    builder
      .addCase(saveResidentDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(saveResidentDocument.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(saveResidentDocument.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
      });
    builder
      .addCase(deleteResidentDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteResidentDocument.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteResidentDocument.rejected, (state, action) => {
        state.error = (action.payload as string) || action.error.message || null;
        state.status = 'failed';
      });
    builder
      .addCase(updateResidentDocumentHardCopy.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateResidentDocumentHardCopy.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateResidentDocumentHardCopy.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
      })
      .addCase(getResidentsByLocation.pending, (state) => {
        state.residentsStatus = 'loading';
        state.residentsError = null;
      })
      .addCase(getResidentsByLocation.fulfilled, (state, action) => {
        state.residents = action.payload;
        state.residentsStatus = 'idle';
        state.residentsError = null;
        state.lastLocationId = action.meta.arg;
      })
      .addCase(getResidentsByLocation.rejected, (state, action) => {
        state.residentsError = action.error.message || null;
        state.residentsStatus = 'failed';
      });
    builder
      .addCase(fetchVitalResidentAlerts.pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(fetchVitalResidentAlerts.fulfilled, (state, action) => {
        state.alerts.vitals = {
          data: action.payload.alerts,
          status: 'idle',
        };
        state.alerts.customizedReports = {
          data: action.payload.resident_reports_custom_alerts,
          status: 'idle',
        };
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchVitalResidentAlerts.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
    builder
      .addCase(fetchCustomAlerts.pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(fetchCustomAlerts.fulfilled, (state, action) => {
        state.alerts.customAlerts = {
          data: action.payload.alerts,
          status: 'idle',
        };
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchCustomAlerts.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
    builder
      .addCase(fetchContactsByResident.pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(fetchContactsByResident.fulfilled, (state, action) => {
        state.contacts = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchContactsByResident.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
    builder
      .addCase(fetchPhysicianByResident .pending, (state) => {
        state.status = 'loading';
        state.resident = null;
        state.error = null;
      })
      .addCase(fetchPhysicianByResident .fulfilled, (state, action) => {
        state.physician = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchPhysicianByResident .rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.resident = null;
      });
  }
});

export const { setResident, resetResident, resetResidents } = residentSlice.actions;
export default residentSlice.reducer;

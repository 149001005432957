import React from 'react';
import { Controller } from 'react-hook-form';
import { Label } from '@/common/presentation/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import TimeSelector from '../TimeSelector';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import TypeOptions from '../TypeOptions';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import { Info } from 'lucide-react';

interface MedicationFormSelectProps {
  control: any;
  setValue: (name: string, value: any) => void;
  handleSelect: (option: any) => void;
  types: any[];
  medicationTimes: any[];
  selectedTimes: string[];
  setSelectedTimes: (times: string[]) => void;
  handleSelectedTimesChange: (selectedTimes: string[]) => void;
  handleSelectedTypeChange: (type: any) => void;
  selectedListForm: any[];
  typeName: string;
  strength: string | any;
  medication: string;
  responsibleAdministrationsId: string;
  displayInstructions: string;
}

const MedicationFormSelect: React.FC<MedicationFormSelectProps> = ({
  control,
  types,
  setSelectedTimes,
  handleSelectedTimesChange,
  handleSelectedTypeChange,
  typeName,
  selectedListForm,
  medicationTimes,
  strength,
  responsibleAdministrationsId,
  displayInstructions
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = React.useState(null);

  const handleTypeChange = (value: string) => {
    const selectedType = types.find((type) => type.id === value);
    if (selectedType) {
      handleSelectedTypeChange(selectedType);
    }
  };

  return (
    <div className="p-2">
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="col-span-1">
            <Label htmlFor="medications">
              {t('medications.medications')} <span className="text-primary">*</span>
            </Label>
            <Controller
              name="medication"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  {...field}
                  value={field.value}
                  disabled={!!field.value}
                  className="p-2 w-full border border-gray-300 rounded-md focus:border-primary focus:ring-primary disabled:text-gray-500 disabled:opacity-100"
                />
              )}
            />
          </div>
          <div className="col-span-1">
            <Label htmlFor="form">
              {t('medications.form')} <span className="text-primary">*</span>
            </Label>
            <Controller
              name="form"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  value={Array.isArray(field.value) ? field.value[0] || '' : field.value}
                  disabled={selectedListForm.length === 0}
                >
                  <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none">
                    <SelectValue placeholder="Select Form" />
                  </SelectTrigger>
                  <SelectContent>
                    {selectedListForm.map((form, index) => (
                      <SelectItem key={index} value={form.name || form}>
                        {form.name || form}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          {strength ? (
            <div className="col-span-1">
              <Label htmlFor="strength">
                {t('medications.strength')} <span className="text-primary">*</span>
              </Label>
              <Controller
                name="strength"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className="focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                    placeholder={t('medications.medicationForm.searchPlaceholder')}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                )}
              />
            </div>
          ) : null}

          {!strength && (
            <div className="col-span-1">
              <Label htmlFor="type">
                {t('medications.type')} <span className="text-primary">*</span>
              </Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      handleTypeChange(value);
                      field.onChange(value);
                    }}
                    value={field.value}
                    disabled={types.length === 0}
                  >
                    <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          {strength && (
            <div className="col-span-1">
              <Label htmlFor="type">
              {t('medications.type')} <span className="text-primary">*</span>
              </Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      handleTypeChange(value);
                      field.onChange(value);
                    }}
                    value={field.value}
                    disabled={types.length === 0}
                  >
                    <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          )}
          <div className={`col-span-${strength ? '2' : '3'} w-full`}>
            <Label htmlFor="time">
              {t('medications.times')} <span className="text-primary">*</span>
            </Label>
            <Controller
              name="time"
              control={control}
              render={({ field }) => (
                <TimeSelector
                  onChange={(selectedTimes) => {
                    handleSelectedTimesChange(selectedTimes);
                    field.onChange(selectedTimes);
                  }}
                  medicationTimes={medicationTimes}
                  selectedTimes={field.value}
                  value={setSelectedTimes}
                  typeName={typeName}
                  className="w-full"
                />
              )}
            />
          </div>
          <div className={`col-span-3 w-full`}>
            {typeName === 'Routine' && (
              <Controller
                name="special_days"
                control={control}
                render={({ field }) => (
                  <TypeOptions
                    isNewMedication={false}
                    type={selectedType ?? ''}
                    onUpdateFormData={(updatedData) => {
                      field.onChange(updatedData);
                    }}
                    special_days={field.value}
                    className="w-full"
                  />
                )}
              />
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="col-span-1">
            {/* Fila del Label y el Icono de Información */}
            <div className="relative flex items-center mb-1">
              <Label htmlFor="instruction" className="text-sm mr-1">
                {t('medications.instructions')} <span className="text-primary">*</span>
              </Label>

              {/* Tooltip */}
              <div className="group relative flex">
                <Info className="w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-700" />

                {/* Tooltip Content */}
                <div className="absolute text-center z-10 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-[150px] px-3 py-2 font-normal text-white bg-zinc-700 rounded-lg shadow-lg">
                  {t('medications.tooltipsintructions')}
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-8 border-transparent border-t-gray-900"></div>
                </div>
              </div>
            </div>

            {/* Fila del Textarea */}
            <Controller
              name="instruction"
              control={control}
              render={({ field }) => (
                <Textarea
                  cols={2}
                  rows={2}
                  className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                  placeholder={t('medications.medicationForm.enterInstruction')}
                  {...field}
                />
              )}
            />
          </div>

          <div className="col-span-1">
            {/* Fila del Label y el Icono de Información */}
            <div className="relative flex items-center mb-1">
              <Label htmlFor="special" className="text-sm mr-1">
                {t('medications.medicationForm.specialInstructionsForMedication')} <span className="text-primary">*</span>
              </Label>

              {/* Tooltip */}
              <div className="group relative flex">
                <Info className="w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-700" />

                {/* Tooltip Content */}
                <div className="absolute text-center z-10 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-[150px] px-3 py-2 font-normal text-white bg-zinc-700 rounded-lg shadow-lg">
                  {t('medications.tooltipsspecialintructions')}
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-8 border-transparent border-t-gray-900"></div>
                </div>
              </div>
            </div>

            {/* Fila del Textarea */}
            <Controller
              name="special_instructions"
              control={control}
              render={({ field }) => (
                <div className="space-y-2">
                  <Textarea
                    cols={2}
                    rows={2}
                    className="resize-none text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                    placeholder={t('medications.medicationForm.enterInstruction')}
                    {...field}
                  />
                  {displayInstructions && displayInstructions !== field.value && (
                    <div className="p-2 bg-gray-50 rounded text-sm text-gray-600">{displayInstructions}</div>
                  )}
                </div>
              )}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default MedicationFormSelect;
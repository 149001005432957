import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import Banner from '@/common/presentation/components/Banner/Banner';
import { Button } from '@/common/presentation/components/ui/button';
import { FileDown } from 'lucide-react';
import { Card } from "@/common/presentation/components/ui/card";
import { exportVitalsReport } from "../slices/FaceSheetSlice";

const FacesheetPage = () => {
    const [location, setLocation] = useState<string | null>(null);
    const { params, setParam } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }
    }, [params]);

    const handleExportPdf = () => {
        dispatch(exportVitalsReport({
            residentId: params.r,
            locationId: params.l,
        }));
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} allLocations={false} quickView={true} />

            {locationSelectedId && (
                <>
                    <div className="text-2xl font-bold py-5" style={{color: accentColor}}>Face Sheet Resident</div>
                    <ResidentSelector
                        onSelect={onResidentSelect}
                        locationId={location}
                        residentId={params.r}
                        showAllResidents={false}
                        showKeepInEye={true}
                    />

                    <Card className="mt-2 mb-2 py-4 px-6 border-t-4 border-t-primary/80">
                        <div className="flex items-center justify-between">
                            <Banner labelMessage="This report is managed through the Settings module, any changes made will affect the data displayed." labelTitle="doYouKnow" messageType="info" />
                            <Button 
                                onClick={handleExportPdf} 
                                className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                                variant="default"
                            >
                                <FileDown className="h-4 w-4" />
                                Export PDF
                            </Button>
                        </div>
                    </Card>
                </>
            )}
        </div>
    );
};



export default FacesheetPage;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { AppDispatch, RootState } from '@/store/store';

import { CardContent, CardHeader} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import FormActionButtons from '../shared/FormActionButtons';
import { CustomCard } from '../shared/CustomCard';
import FormHeader from '../shared/FormHeader';
import ResidentAppraisal from './components/ResidentAppraisal';
import HealthDetailsForm from './components/HealthDetailsForm';
import BedStatusForm from './components/BedStatusForm';
import TuberculosisForm from './components/TuberculosisForm';
import ServicesNeededForm from './components/ServicesNeededForm';
import AmbulatoryStatusForm from './components/AmbulatoryStatusForm';
import FunctionalCapabilities from './components/FunctionalCapabilitiesForm';
import { residentAppraisalDefaults } from '../formDefaults/residentAppraisalDefaults';

export const ResidentAppraisalForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);

  const methods = useForm({
    defaultValues: residentAppraisalDefaults(resident),
  });

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(residentAppraisalDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic603a_pre_placement_appraisal',
  });

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedData = {
      ...rawData,
      tb_test_date: rawData.tb_test_date ? format(parseISO(rawData.tb_test_date), 'MM/dd/yyyy') : '',
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
        <BackNavigationBar
          title={title}
          path={`/residents/forms?r=${params.r}`}
        />
        <FormProvider {...methods}>
          <form>
            <CustomCard>
              <CardHeader className='p-0 '>
                <FormHeader
                  leftContent={
                    <>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.stateOfCalifornia")}
                      </p>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
                      </p>
                    </>
                  }
                  rightContent={
                    <>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.californiaDeptSocialServices")}
                      </p>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.communityCareLicensingDivision")}
                      </p>
                    </>
                  }
                />
              </CardHeader>
              <CardContent className="p-0 flex-1">
                <ResidentAppraisal />
              </CardContent>
            </CustomCard>
            <CustomCard>
              <HealthDetailsForm />
            </CustomCard>
            <CustomCard>
              <BedStatusForm />
            </CustomCard>
            <CustomCard>
              <TuberculosisForm />
            </CustomCard>
            <CustomCard>
              <AmbulatoryStatusForm />
            </CustomCard>
            <CustomCard>
              <FunctionalCapabilities />
            </CustomCard>
            <CustomCard>
              <ServicesNeededForm />
              <Separator className='my-4 bg-primary'/>
              <FormActionButtons
                handleFormSubmit={handleFormSubmit}
                formsStatus={formsStatus}
              />
            </CustomCard>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

import { Button } from '@/common/presentation/components/ui/button';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { Pencil } from 'lucide-react';

export const OverviewPrnTableActions = ({ data }: any) => {
  const { onOpen } = usePrnDialog();
  return (
    <Button
      size={'icon'}
      variant={'outline'}
      onClick={() =>
        onOpen(
          {
            ...data,
            ...data.resident_medication,
            time_given: data?.dispensed_at,
            follow_up: data?.follow_up,
            reason_id: data?.reason?.id,
            result: data?.result,
            comments: data?.comments,
            level_of_pain: data?.level_of_pain,
            resident_id: data?.resident_id,
            id: data?.resident_medication_id,
            dispense_id: data?.id,
            strength: data?.strength,
            instruction: data.instruction,
            special_instructions: data.special_instructions,
            frecuency: data?.resident_medication?.frecuency,
            not_exceed: data?.resident_medication?.not_exceed
          },
          'all-locations'
        )
      }
    >
      <Pencil className="size-4" />
    </Button>
  );
};

import { LocationI } from '@/common/domain/Residents';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { useState, FC, useMemo } from 'react';
import { CheckCircle2 } from 'lucide-react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  locations: any[];
  onConfirm?: (locationIds: string[]) => void;
}

const LocationInactivationModal: FC<IProps> = ({ onClose, isOpen, locations, onConfirm }) => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  
  const activeLocations = useMemo(
    () => locations.filter((location: LocationI) => location.status === 'active'),
    [locations]
  );

  const handleLocationClick = (locationId: string) => {
    setSelectedLocations(prev => {
      const isSelected = prev.includes(locationId);
      if (isSelected) {
        return prev.filter(id => id !== locationId);
      }
      if (prev.length >= activeLocations.length - 1) {
        return prev;
      }
      return [...prev, locationId];
    });
  };

  const handleConfirm = () => {
    if (selectedLocations.length > 0 && onConfirm) {
      onConfirm(selectedLocations);
      onClose();
    }
  };

  return (
    <CustomDialog
      title="Locations"
      description={`Select locations to inactivate (${selectedLocations.length}/${activeLocations.length - 1} max)`}
      isOpen={isOpen}
      onClose={onClose}
      newDialog={true}
    >
      <div className="space-y-4">
        {activeLocations.map((location: LocationI) => (
          <div
            key={location.id}
            className={`flex items-center justify-between p-3 rounded-lg cursor-pointer transition-all hover:bg-primary/10 ${
              selectedLocations.includes(location.id) ? 'bg-primary/20 border border-primary' : ''
            } ${selectedLocations.length >= activeLocations.length - 1 && !selectedLocations.includes(location.id) ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => handleLocationClick(location.id)}
          >
            <div className="flex items-center space-x-3">
              <Avatar className="h-10 w-10">
                <AvatarImage src={location.image_path} className="h-full w-full object-cover" />
                <AvatarFallback>
                  {location.name.split(' ').map((chunk: string) => chunk[0]).join('')}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col">
                <span className="font-medium">{location.name}</span>
                <span className="text-sm text-gray-500">
                  Max Beds: {location.max_beds || 'N/A'}
                </span>
              </div>
            </div>
            {selectedLocations.includes(location.id) && (
              <CheckCircle2 className="w-5 h-5 text-primary" />
            )}
          </div>
        ))}
        
        <div className="flex justify-end space-x-2 mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            disabled={selectedLocations.length === 0}
          >
            Confirm
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

export default LocationInactivationModal;
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

import { Input } from '@/components/ui/input';
import { calculateAge } from '@/modules/residents/domain/utils/Forms/formUtils';
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover.tsx";
import { Button } from "@/common/presentation/components/ui/button.tsx";
import { ResidentI } from '@/common/domain/Residents';


const ClientsResidentsTable = () => {
  const { t } = useTranslation();
  const { residents } = useSelector((state: RootState) => state.residents.allResidents);
  const { control, setValue } = useFormContext();
  const [openPopover, setOpenPopover] = useState<Record<number, boolean>>({});

  const handleSelectResident = (index: number, resident: ResidentI) => {
    const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;

    setValue(`clients_residents.${index}.client`, residentName);
    setValue(`clients_residents.${index}.age`, calculateAge(resident.dob));
    setValue(`clients_residents.${index}.sex`, resident.sex);
    setValue(
      `clients_residents.${index}.date_of_admission`,
      resident?.other_information?.admission_date
        ? new Date(resident.other_information.admission_date).toISOString().split("T")[0]
        : ""
    );

    setOpenPopover((prev) => ({ ...prev, [index]: false }));
  };

  const togglePopover = (index: number) => {
    setOpenPopover((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse border border-zinc-300 dark:border-zinc-600">
        <thead>
          <tr className="bg-zinc-100 dark:bg-zinc-900 text-center font-semibold">
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('residents.forms.incidentReport.residentsInvolved')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('residents.forms.incidentReport.dateOccurred')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('residents.age')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('common.sex')}</th>
            <th className="border border-zinc-300 p-2 dark:border-zinc-700">{t('residents.forms.incidentReport.dateAdmission')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 4 }).map((_, index) => (
            <tr key={index} className="odd:bg-white even:bg-zinc-50 dark:bg-zinc-900">
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`clients_residents.${index}.client`}
                  control={control}
                  render={({ field }) => (
                    <Popover open={openPopover[index] || false} onOpenChange={() => togglePopover(index)}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        className="w-full justify-between"
                      >
                        {field.value || ""}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-40 max-h-24 md:w-60 md:max-h-36 overflow-y-auto">
                      {residents.map((resident) => (
                        <div
                          key={resident?.id}
                          className="cursor-pointer px-2 py-1 hover:bg-gray-200"
                          onClick={() => handleSelectResident(index, resident)}
                        >
                           {`${resident?.first_name ?? ""} ${resident?.last_name ?? ""}`}
                        </div>
                      ))}
                    </PopoverContent>
                  </Popover>
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`clients_residents.${index}.date_occurred`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      className="w-full dark:bg-zinc-800"
                    />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2 text-center min-w-[80px] sm:min-w-[100px]">
                <Controller
                  name={`clients_residents.${index}.age`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      className="w-full dark:bg-zinc-800"
                    />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2  text-center min-w-[80px] sm:min-w-[100px]">
                <Controller
                  name={`clients_residents.${index}.sex`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="w-full dark:bg-zinc-800"
                    />
                  )}
                />
              </td>
              <td className="border border-zinc-300 dark:border-zinc-500 p-2">
                <Controller
                  name={`clients_residents.${index}.date_of_admission`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      className="w-full dark:bg-zinc-800"
                    />
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsResidentsTable;

import { format, parseISO, isValid, isAfter, startOfDay } from 'date-fns';
import { t } from 'i18next';

export const timeUtils = {
  normalizeTime: (time: string) => {
    const [hours, minutes] = time.split(':');
    return `${parseInt(hours, 10)}:${minutes}`;
  },

  findMedicationByDateTime: (
    medications: any[],
    selectedDate: Date,
    selectedTime: string
  ): any | undefined => {
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  
    return medications.find((med) => {
      const isWitnessed = med.witnessed === 1;
      
      if (isWitnessed) {
        const witnessDate = med.date_to_be_witnessed?.split('T')[0];
        return witnessDate === formattedDate && 
               med.time_to_be_witnessed === selectedTime;
      }
      
      const dispenseDate = med.date_to_be_dispensed?.split('T')[0];
      return dispenseDate === formattedDate && 
             med.time_to_be_dispensed === selectedTime;
    });
  },

  isDateInFuture: (date: Date): boolean => {
    const today = startOfDay(new Date());
    const selectedDay = startOfDay(date);
    return isAfter(selectedDay, today);
  },

  removeLeadingZeroFromTimes: (times: string[] | null | undefined): string[] => {
    return (
      times?.map((time: string) => {
        if(time == 'all-day') return time;
        const [hours, minutes] = time.split(':');
        return `${parseInt(hours, 10)}:${minutes}`;
      }) ?? []
    );
  },

  formatISODateString(isoDate: any, time: string | null = null, formatType: string = 'dateFormat') {
    try {
      if (!isoDate) return '';
      const formatDate = isoDate.split('T')[0];
      const dateObject = parseISO(formatDate);

      if (dateObject) {
        let newDate = format(dateObject, t(`dateFormats.${formatType}`));
        newDate = time ? newDate + ' ' + time : newDate;
        return newDate;
      }
    } catch (error) {
      console.error('Error format date:', error);
    }
  },

  formatToUTC: (date: Date | null): Date | null => {
    if (!date) return null;
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  },

  formatDateUTC: (date: string | Date | null, displayFormat: string): string | null => {
    if (!date) return null;

    const utcDate = new Date(date instanceof Date ? date.toISOString() : date);

    const formatters = {
      yyyy: () => utcDate.getUTCFullYear(),
      MM: () => String(utcDate.getUTCMonth() + 1).padStart(2, '0'),
      dd: () => String(utcDate.getUTCDate()).padStart(2, '0'),
      HH: () => String(utcDate.getUTCHours()).padStart(2, '0'),
      hh: () => {
        const hours = utcDate.getUTCHours();
        return String(hours % 12 || 12).padStart(2, '0');
      },
      mm: () => String(utcDate.getUTCMinutes()).padStart(2, '0'),
      a: () => (utcDate.getUTCHours() >= 12 ? 'PM' : 'AM')
    };

    const tokenRegex = new RegExp(Object.keys(formatters).join('|'), 'g');

    return displayFormat.replace(tokenRegex, (match) => formatters[match as keyof typeof formatters]().toString());
  },

  convertToDateTime: (dateStr: any, timeStr: any) => {
    const date = new Date(dateStr);
    if (timeStr) {
      const [time, period] = timeStr.split(' ');
      const [hours, minutes] = time.split(':').map(Number);
      const adjustedHours = period === 'PM' ? (hours === 12 ? 12 : hours + 12) : hours === 12 ? 0 : hours;
      date.setHours(adjustedHours, minutes);
    }
    return date;
  },

  getTimeColumn: (type: string | null) => (type === 'witnessed' ? 'time_to_be_witnessed' : 'time_to_be_dispensed'),

  getDateColumn: (type: string | null) => (type === 'witnessed' ? 'date_to_be_witnessed' : 'date_to_be_dispensed'),

  sortMedicationsExceptions: (formatMedications: any) => {
    return formatMedications.sort((a: any, b: any) => {
      const dateA = new Date(a.date_to_be_dispensed);
      const dateB = new Date(b.time_to_be_dispensed);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;

      const timeA = timeUtils.convertToDateTime(a.date_to_be_dispensed, a.time_to_be_dispensed);
      const timeB = timeUtils.convertToDateTime(b.date_to_be_dispensed, b.time_to_be_dispensed);

      if (timeA > timeB) return -1;
      if (timeA < timeB) return 1;

      return 0;
    });
  },

  sortRoutineMedications: (formatMedications: any) => {
    return formatMedications.sort((a: any, b: any) => {
      const typeA = a?.witnessed == 1 ? 'witnessed' : a?.dispensed == 1 ? 'dispensed' : null;
      const typeB = b?.witnessed == 1 ? 'witnessed' : b?.dispensed == 1 ? 'dispensed' : null;

      const dateColumnNameA = timeUtils.getDateColumn(typeA);
      const dateColumnNameB = timeUtils.getDateColumn(typeB);
      const timeColumnNameA = timeUtils.getTimeColumn(typeA);
      const timeColumnNameB = timeUtils.getTimeColumn(typeB);

      const dateA = new Date(a[dateColumnNameA]);
      const dateB = new Date(b[dateColumnNameB]);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;

      const timeA = timeUtils.convertToDateTime(a[dateColumnNameA], a[timeColumnNameA]);
      const timeB = timeUtils.convertToDateTime(b[dateColumnNameB], b[timeColumnNameB]);

      if (timeA > timeB) return -1;
      if (timeA < timeB) return 1;

      return 0;
    });
  },
  sortPrnMedications(formatMedications: any[]) {
    return formatMedications.sort((a: any, b: any) => {
      const dateA = new Date(a?.dispensed_at);
      const dateB = new Date(b?.dispensed_at);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  }
};

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Separator } from '@/components/ui/separator';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FormField, FormItem } from "@/components/ui/form";
import { Input } from '@/components/ui/input';
import { functionalCapabilities } from "../../constants/residentAppraisal.constants";

const FunctionalCapabilities = () => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();

  const otherRadioValue = watch("functional_capabilities.other");

  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center md:justify-left gap-2 md:gap-4 mb-4">
        <h4 className="font-semibold text-base">
          {t('residents.forms.residentAppraisal.functionalCapabilitiesTitle')}
        </h4>
        <p className="text-sm font-normal">
          ({t('residents.forms.residentAppraisal.checkAllItemsBelow')})
        </p>
      </div>

      <Separator className='my-4 bg-primary'/>

      <div className="space-y-4">
        {functionalCapabilities.map((capability) => (
          <FormField
            key={capability.name}
            control={control}
            name={`functional_capabilities.${capability.name}`}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-1 sm:grid-cols-[auto,1fr,1fr] gap-4 items-start">
                <RadioGroup
                    value={field.value}
                    onValueChange={field.onChange}
                    className="flex flex-row gap-4"
                  >
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="yes"
                        id={`functional-capability-yes-${capability.name}`}
                      />
                      <span className="text-sm">{t("common.yes")}</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="no"
                        id={`functional-capability-no-${capability.name}`}
                      />
                      <span className="text-sm">{t("common.no")}</span>
                    </label>
                  </RadioGroup>
                  <span className="text-sm text-zinc-700 dark:text-zinc-300">
                    {t(`residents.forms.residentAppraisal.functionalCapabilities.${capability.name}`)}
                  </span>
                  {capability.extraField && (
                    <>
                      <FormField
                        control={control}
                        name={`functional_capabilities.${capability.extraField}`}
                        render={({ field: extraField }) => (
                          <RadioGroup
                            value={extraField.value}
                            onValueChange={extraField.onChange}
                            className="flex items-center gap-4 justify-center"
                          >
                            <label className="flex items-center space-x-2">
                              <RadioGroupItem
                                value="yes"
                                id={`functional-capability-extra-yes-${capability.extraField}`}
                              />
                              <span className="text-sm">{t("common.yes")}</span>
                            </label>
                            <label className="flex items-center space-x-2">
                              <RadioGroupItem
                                value="no"
                                id={`functional-capability-extra-no-${capability.extraField}`}
                              />
                              <span className="text-sm">{t("common.no")}</span>
                            </label>
                          </RadioGroup>
                        )}
                      />
                    </>
                  )}

                </div>
                {capability.extraDescription && (
                  <FormField
                    control={control}
                    name={`functional_capabilities.${capability.extraDescription}`}
                    render={({ field }) => (
                      <FormItem className="mt-2">
                        <Input
                          type="text"
                          {...field}
                          disabled={capability.name === "other" && otherRadioValue !== "yes"}
                          className="dark:bg-zinc-800 lg:ml-28 lg:w-[80%]"
                        />
                      </FormItem>
                    )}
                  />
                )}
              </FormItem>
            )}
          />
        ))}
      </div>
    </>
  );
};

export default FunctionalCapabilities;

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FormField, FormItem, FormControl } from "@/components/ui/form";
import { Separator } from '@/components/ui/separator';
import { ambulatoryCriteria, ambulatoryStatusOptions } from "../../constants/residentAppraisal.constants";

const AmbulatoryStatusForm = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <h4 className="font-semibold text-base">
          {t('residents.forms.ambulatoryStatus')}
        </h4>
        <p className="font-normal text-sm">
          ({t('residents.forms.residentAppraisal.thisPersonIs')})
        </p>
        <FormField
          control={control}
          name="ambulatory_status"
          render={({ field }) => (
            <FormControl>
              <RadioGroup
                value={field.value}
                onValueChange={field.onChange}
                className="flex flex-wrap gap-4 items-center "
              >
                {ambulatoryStatusOptions.map(({ name }) => (
                  <label key={name} className="flex items-center space-x-2">
                    <RadioGroupItem value={name} />
                    <span className="text-sm">
                      {t(`residents.forms.residentAppraisal.${name}`)}
                    </span>
                  </label>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      </div>

      <Separator className='my-4 bg-primary'/>

      <p className="font-normal text-sm mb-4">
        {t('residents.forms.residentAppraisal.ambulatoryDefinition')}:
      </p>

      <div className="space-y-4">
        {ambulatoryCriteria.map(({ name }, index) => (
          <FormField
            key={index}
            control={control}
            name={`ambulatory_criteria.${name}`}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-1 sm:grid-cols-[auto,1fr] gap-4 items-start">
                  <RadioGroup
                    value={field.value}
                    onValueChange={field.onChange}
                    className="flex flex-row gap-4"
                  >
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem value="yes" id={`ambulatory-yes-${index}`} />
                      <span className="text-sm">{t("common.yes")}</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <RadioGroupItem value="no" id={`ambulatory-no-${index}`} />
                      <span className="text-sm">{t("common.no")}</span>
                    </label>
                  </RadioGroup>
                  <span className="text-sm text-zinc-700 dark:text-zinc-300">
                    {t(`residents.forms.residentAppraisal.${name}`)}
                  </span>
                </div>
              </FormItem>
            )}
          />
        ))}
      </div>
    </>
  );
};

export default AmbulatoryStatusForm;

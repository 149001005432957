import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Separator } from '@/common/presentation/components/ui/separator';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import * as residentFormActions from '@/modules/residents/presentation/slices/residentsForm';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';

interface Props {
  className?: string;
}

const formSchema = z.object({
  security_number: z.string().optional(),
  processing_fee: z
    .preprocess(
      (value) => (value === '' ? undefined : Number(value)),
      z.number({
        invalid_type_error: 'Processing fee must be a valid number.'
      }).optional()
    ),
  rates: z
    .preprocess(
      (value) => (value === '' ? undefined : Number(value)),
      z.number({
        invalid_type_error: 'Rates must be a valid number.'
      }).optional()
    ),
});

type FormValues = z.infer<typeof formSchema>;

const formatSecurityNumber = (value: string | undefined) => {
  if (value !== undefined && value !== null && value.length > 0) {
    const valueStr = value.replace(/\D/g, '');

    let formatNumber = '';

    if (valueStr.length > 5) {
      formatNumber = valueStr.slice(0, 3) + '-' + valueStr.slice(3, 5) + '-' + valueStr.slice(5, 9);
    } else if (valueStr.length > 3) {
      formatNumber = valueStr.slice(0, 3) + '-' + valueStr.slice(3, 5);
    } else if (valueStr.length > 0) {
      formatNumber = valueStr;
    }

    return formatNumber;
  }

  return '';
};

const formatCurrency = (value: number | string): string => {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(number)) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // Sin decimales
    maximumFractionDigits: 0, // Sin decimales
  }).format(number);
};

export const AdministrativeInformation = ({ className }: Props) => {
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    values: { ...defaultValues } as FormValues
  });

  const onSubmit = async (data: FormValues) => {
    const newData = {
      ...data,
      security_number: data?.security_number!.split('-').join('')
    };
    const response = dispatch(
      residentFormActions.updateAdministrativeInformationForm({ resident_id: resident?.id, ...newData })
    );

    if ((await response).payload) {
      toast({
        description: 'Administrative Information updated successfully'
      });
    }
  };

  useEffect(() => {
    setDefaultValues({
      security_number: formatSecurityNumber(`${resident?.security_number}`) || '',
      processing_fee: resident?.other_information?.processing_fee || '',
      rates: resident?.other_information?.rates || ''
    });
  }, [resident]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Administrative Information">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="security_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Social Security Number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value || ''}
                          onChange={(e) => {
                            field.onChange(formatSecurityNumber(e.target.value));
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="processing_fee"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Processing Fee</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value !== null && field.value !== undefined ? formatCurrency(field.value) : ''}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Solo números
                            field.onChange(value ? parseInt(value, 10) : ''); // Convertir a entero o vacío
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                    // disabled={residentFormStatus === 'loading'}
                  )}
                />

                <FormField
                  control={form.control}
                  name="rates"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Rates</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          value={field.value !== null && field.value !== undefined ? formatCurrency(field.value) : ''}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Solo números
                            field.onChange(value ? parseInt(value, 10) : ''); // Convertir a entero o vacío
                          }}
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {JSON.stringify(form.getValues()) !== JSON.stringify(defaultValues) && (
                  <>
                    <Separator className="col-span-full" />
                    <Button type="submit" className="w-64" disabled={residentFormStatus === 'loading'}>
                      {residentFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

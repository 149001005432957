/**
 * Normalizes a string by removing diacritics and converting to lowercase.
 * 
 * @param value - The string to normalize.
 * @returns The normalized string.
 */
const normalizeString = (value: string): string => {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
  };
  
  
  /**
   * Checks if a target string includes a search string after normalization.
   * 
   * @param target - The string to search within.
   * @param search - The string to search for.
   * @returns `true` if the normalized target includes the normalized search; otherwise, `false`.
   */

export const includesNormalized = (target: string, search: string): boolean => {
    const normalizedTarget = normalizeString(target);
    const normalizedSearch = normalizeString(search);
    return normalizedTarget.includes(normalizedSearch);
};
  
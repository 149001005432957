import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { AppDispatch, RootState } from "@/store/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Pencil, Trash } from "lucide-react";
import { DialogCreateOrUpdate } from "./DialogCreateOrUpdate";
import { deleteQuickNote, getQuickNotes, quickNotesLoading } from "@/modules/settings/presentation/components/menus/notes/slices/QuickNotesSlice";
import DeleteDialog from "@/common/presentation/components/DeleteDialog/DeleteDialog";
import { FiltersTable } from "./FiltersTable";

export interface IData {
    id?: string;
    title: string;
    description: string;
    category_id: string;
    category: string;
    status: boolean;
    idUpdate?: string | null;
}
export const QuickNotes = () => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const [filterValue, setFilterValue] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const { quickNotes, isLoading } = useSelector((state: RootState) => state.settings.quickNotes);


  const [dataUpdate, setDataUpdate] = useState({
    id: '',
    title: '',
    description: '',
    category_id: '',
    category: '',
    status: false,
  });

  const renderActionsColumn = (rowData: any) => (

    <div className="flex gap-2 justify-end z-[9999999999]">
      <Button variant="outline" size="icon" onClick={() => {
        setIsUpdate(true);
        setOpenDialog(true);        
        setDataUpdate(rowData);        
      }}>
        <Pencil className="h-4 w-4" />
      </Button>
      <Button variant="outline" size="icon" onClick={() => {
        setIdDelete(rowData.id);
        setOpenDeleteAlert(true);
      }}>
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );

  const columns = [
    { key: "title", labelTranslationKey: "settings.notes.quickNotes.customNote" },
    { key: "category", labelTranslationKey: "settings.notes.category" },
    { key: "status", labelTranslationKey: "settings.status" },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: '',
      render: renderActionsColumn,
      width: 'w-64',
      static: true
    }
  ];

  const loadMore = useCallback(async () => {
    if (!hasMoreRows) return;
    try {
      const nextPage = currentPage + 1;
      const params = {
        currentPage: nextPage,
        perPage: rowsPerPage
      };

      dispatch(quickNotesLoading());

      const response = await dispatch(getQuickNotes(params));
      if (response?.payload?.items) {
        const parseData = Object.values(response.payload.items);
        setData(prevData => [...prevData, ...parseData]);
        setCurrentPage(nextPage);
      }

    } catch (error) {
      throw new Error(`Error get data: ${error}`);
    }

  }, [hasMoreRows, currentPage]);


  const callbackRenderContent = (index: any, column: any, data: any) => {
    const row = data[index];
    switch (column.key) {
      case 'title':
        return <>{row?.title}</>
      case 'category':
        return <>
          {row?.category}
        </>;
      case 'status':
        return row?.status ? t('settings.notes.active') : t('settings.notes.inactive');
    }
  }

  const handleDelete = () => {
    if (idDelete) {
      dispatch(quickNotesLoading());
      dispatch(deleteQuickNote(idDelete))
      getData();
    }
  }

  const handleFilterChange = async (value: string) => {
    setFilterValue(value);
    
    if (value.length >= 1 ) {
      dispatch(quickNotesLoading());
      const response = await dispatch(getQuickNotes({ 
        currentPage: 1, 
        perPage: rowsPerPage,
        search: value
      }));
      
      if (response?.payload?.items) {
        setData(response.payload.items);
        setHasMoreRows(response.payload.pagination.hasMorePages);
      }
    } else if (value === '') {
      getData(1, '');
    }
  };

  const handleClearFilter = () => {
    setFilterValue('');
    getData(1);
  };

  const getData = async (cPage = currentPage, search?: string) => {

    dispatch(quickNotesLoading());
    const response = await dispatch(getQuickNotes({ cPage, perPage: rowsPerPage, search}));
    const data = response?.payload;
    if (data?.items) {
      setData(data.items);
      setHasMoreRows(data.pagination.hasMorePages)
      setCurrentPage(cPage);
    }
  };

  useEffect(() => {
    getData();
  }, [dispatch]);


  return <>

    <TableCustomVirtuoso
    tableId="settings-t-quick-notes"
      styles={{ height: 'calc(100vh - 23rem)', maxHeight: '370px' }}
      additionalButtons={
        <Button
          variant="outline"
          size="sm"
          className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
          onClick={() => setOpenDialog(true)}
        ><span>{t('settings.notes.quickNotes.new')}</span></Button>
      }
      data={data}
      columns={columns}
      renderCellContent={callbackRenderContent}
      onSort={()=>{}}
      loadMore={loadMore}
      hasMore={hasMoreRows}
      isLoading={isLoading}
      filters={
        <FiltersTable
          onFilterChange={handleFilterChange}
          filterValue={filterValue}
          onClear={handleClearFilter}
        />
      }
      showSearchInput={false}
    />

    <DialogCreateOrUpdate
      onChangeOpen={setOpenDialog}
      open={isOpenDialog}
      title={t('settings.notes.quickNotes.create')}
      isUpdate={isUpdate}
      onChangeIsUpdate={setIsUpdate}
      dataUpdate={dataUpdate}
      onChangeGetData={getData}
    />

    <DeleteDialog
      isOpen={isOpenDeleteAlert}
      onOpenChange={setOpenDeleteAlert}
      handleDelete={handleDelete}
    />

  </>;


}


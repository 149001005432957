import * as authActions from '@/auth/presentation/slices/authSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as configActions from '@/common/presentation/slices/configurationSlice';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationSelectorDialog } from '../hooks/useLocationSelectorDialog';
import { setNavigateFunction } from '../middleware/navigation';
import { useSessionExpiration } from './SessionExpirationProvider';
import { decrypt, encrypt, encryptToLaravel } from '@/utils/helpers/encryption.helper';
import { RoleHelperProvider } from './RoleHelperProvider';

// Type definitions
type LoginType = 'normal' | 'delegated_login';

interface UserInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface SessionInfo {
  login_type: LoginType;
  user_info?: UserInfo;
}

interface AuthContextValues {
  isAuthenticated: boolean;
  isLoading: boolean;
  roles: string[];
  sessionInfo: SessionInfo;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setHasSelectedLocation: (value: boolean) => void;
}

interface LoginDataParam {
  token: string;
  user_info: UserInfo;
  date: Date;
  login_type: LoginType;
}

interface AuthContextProviderProps {
  children: JSX.Element;
}

// Constants
const ALLOWED_ROLES = {
  STAFF: 'Staff',
  PHARMACY: 'Pharmacy'
} as const;

const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  NOTES: '/notes'
} as const;

const SESSION_STORAGE_KEYS = {
  SESSION_INFO: 'session_info',
  LOCATION_SELECTED: 'location_selected'
} as const;

const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  LOCATION_SELECTED: 'location_selected'
} as const;

// Context creation with default values
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  isLoading: true,
  roles: [],
  sessionInfo: { login_type: 'normal' },
  login: async () => {},
  logout: async () => {},
  setHasSelectedLocation: () => {}
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { isLoading: authLoading, isAuthenticated, roles } = useSelector((state: RootState) => state.auth);
  const [isVerifying, setIsVerifying] = useState(true);
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>({ login_type: 'normal' });
  const [hasSelectedLocation, setHasSelectedLocation] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isOpen, onOpen } = useLocationSelectorDialog();
  const { setParam, params } = useRouteParams();
  const { SessionExpirationDialog } = useSessionExpiration();

  const isUserActive = (user: any): boolean => user.status !== 'inactive';

  const clearAuthData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LOCATION_SELECTED);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.LOCATION_SELECTED);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SESSION_INFO);
    dispatch(authActions.unSetAuth());
    dispatch(locationsActions.unSetLocations());
    dispatch(configActions.unSetConfigurations());
  };

  const handleAuthSuccess = (payload: any) => {
    if (payload.access_token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, payload.access_token);
      const sessionData = encrypt(JSON.stringify({ login_type: 'normal' }));
      sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_INFO, sessionData);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.LOCATION_SELECTED, 'all-locations')
      setSessionInfo({ login_type: 'normal' });
    }
    
    dispatch(configActions.setConfigurations(payload.configuration));
    dispatch(locationsActions.setLocations(payload.user.locations));
    dispatch(residentsActions.setResidents(payload.user.residents));
    dispatch(authActions.setRoles(payload.user.roles));
  };

  const handleAuthFailure = (error?: { message: string }) => {
    clearAuthData();
    
    const path = location.pathname;
    const signUpPathRegex = /^\/sign-up(\/.*)?$/;

    if (!signUpPathRegex.test(path)) {
      navigate(ROUTES.LOGIN, { replace: true });
      if (error?.message) {
        toast({
          title: 'Authentication Error',
          description: error.message,
          variant: 'destructive'
        });
      }
    }
  };

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const loginEncrypted = encryptToLaravel({ username, password });
      const response: any = await dispatch(authActions.login(loginEncrypted));

      if (response.meta.requestStatus === 'fulfilled') {
        if (!isUserActive(response.payload.user)) {
          throw new Error('Tu cuenta está inactiva. Por favor, contacta al administrador.');
        }

        handleAuthSuccess(response.payload);

        if (redirectUrl) {
          navigate(redirectUrl);
        }

        const userRoles = response.payload.user.roles.map((role: any) => role.name);
        if (userRoles.some((role: string) => ['staff', 'house manager'].includes(role.toLowerCase()))) {
          onOpen();
        }
      } else {
        throw new Error(response.payload?.message || 'Error al iniciar sesión');
      }
    } catch (error: any) {
      handleAuthFailure({ message: error.message });
    }
  };

  const logout = async (): Promise<void> => {
    handleAuthFailure();
    try {
      await dispatch(authActions.logout());
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const validateSessionInfo = (sessionInfo: string): boolean => {
    try {
      const sessionObject: SessionInfo = JSON.parse(decrypt(sessionInfo));
      if (!sessionObject.login_type || !['normal', 'delegated_login'].includes(sessionObject.login_type)) {
        return false;
      }

      setSessionInfo({ login_type: sessionObject.login_type });
      return true;
    } catch {
      return false;
    }
  };

  const validateLoginParam = (data: string): LoginDataParam | false => {
    try {
      const loginInfo = JSON.parse(decrypt(decodeURIComponent(data)));
      if (!loginInfo) return false;

      const loginDate = new Date(loginInfo.date);
      const currentDate = new Date();
      const minutesDifference = (currentDate.getTime() - loginDate.getTime()) / (1000 * 60);
      
      return minutesDifference <= 2 ? loginInfo : false;
    } catch {
      return false;
    }
  };

  // Effect to set navigation function
  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  // Effect to verify session
  useEffect(() => {
    const verifySession = async () => {
      setIsVerifying(true);
      try {
        const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        const sessionInfoEncrypted = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_INFO);
        const path = location.pathname;
        const signUpPathRegex = /^\/sign-up(\/.*)?$/;

        if (
          (!accessToken || !sessionInfoEncrypted || !validateSessionInfo(sessionInfoEncrypted)) &&
          !signUpPathRegex.test(path)
        ) {
          clearAuthData();
          navigate(ROUTES.LOGIN, { replace: true });
          return;
        }

        const response: any = await dispatch(authActions.verifySession());
        if (response.meta.requestStatus === 'fulfilled') {
          if (!isUserActive(response.payload.user)) {
            throw new Error('Tu cuenta está inactiva. Por favor, contacta al administrador.');
          }
          handleAuthSuccess(response.payload);
        } else {
          throw new Error(response.error);
        }
      } catch (error: any) {
        handleAuthFailure({ message: error.message });
      } finally {
        setIsVerifying(false);
      }
    };

    verifySession();
  }, [location.pathname]);

  // Effect to handle role-based navigation
  useEffect(() => {
    const userRoles = roles.map((role: any) => role.name);

    if (isAuthenticated && userRoles.includes(ALLOWED_ROLES.STAFF)) {
      const locationSelected = sessionStorage.getItem(SESSION_STORAGE_KEYS.LOCATION_SELECTED);

      if (!locationSelected || locationSelected === 'all-locations') {
        if (!isOpen && hasSelectedLocation) {
          handleAuthFailure({ message: 'No se encontró una ubicación válida. Por favor, vuelva a iniciar sesión.' });
        } else if (!isOpen) {
          onOpen();
        }
      } else if (!params.l) {
        setParam('l', locationSelected);
      }
    }

    if (isAuthenticated && userRoles.includes(ALLOWED_ROLES.PHARMACY)) {
      const pharmacyAllowPathRegex = /^\/notes(\/.*)?$/;
      if (!pharmacyAllowPathRegex.test(location.pathname)) {
        navigate(ROUTES.NOTES, { replace: true });
      }
    }
  }, [isAuthenticated, roles, location.pathname, isOpen, hasSelectedLocation]);

  // Effect to handle redirects and login parameters
  useEffect(() => {
    if (isAuthenticated && redirectUrl) {
      navigate(redirectUrl);
      setRedirectUrl('');
    }

    const urlParams = new URLSearchParams(location.search);
    if (urlParams.size === 0) return;

    const encryptedRedirectUrl = urlParams.get('redirect');
    if (encryptedRedirectUrl) {
      const decodedRedirectUrl = decodeURIComponent(encryptedRedirectUrl);
      setRedirectUrl(decrypt(decodedRedirectUrl));
    }

    const loginParam = urlParams.get('login');
    if (loginParam) {
      const loginInfo = validateLoginParam(loginParam);
      if (loginInfo) {
        const sessionData = encrypt(
          JSON.stringify({
            user_info: loginInfo.user_info,
            login_type: loginInfo.login_type
          })
        );
        sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_INFO, sessionData);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, loginInfo.token);
        setSessionInfo({ login_type: loginInfo.login_type });
      }
    }
  }, [isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      isLoading: isVerifying || authLoading,
      roles,
      login,
      logout,
      setHasSelectedLocation,
      sessionInfo
    }),
    [isAuthenticated, isVerifying, authLoading, roles, sessionInfo]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <RoleHelperProvider>
        <>
          {children}
          <SessionExpirationDialog />
        </>
      </RoleHelperProvider>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
import React, { useState, useEffect, useMemo } from 'react';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { Popover, PopoverContent } from '@/common/presentation/components/ui/popover';
import { PopoverTrigger } from '@/common/presentation/components/registry/default/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { addMonths, format, isAfter, isBefore, isValid } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/store/store';

type DayOption = 'every_day' | 'days_of_week' | 'calendar' | 'alternate_days';
type WeekDay = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

interface TypeOptionsProps {
  isNewMedication: boolean;
  type: string;
  selectStartDate: Date | null;
  selectEndDate: Date | null;
  onUpdateFormData: (updatedData: { special_day?: string[] | Date[]; text?: string; type?: string }) => void;
  special_days: {
    type?: string;
    special_day?: string[] | Date[];
    text?: string;
  };
}

const TypeOptions: React.FC<TypeOptionsProps> = ({
  selectStartDate,
  selectEndDate,
  isNewMedication,
  onUpdateFormData,
  special_days
}) => {
  const { t } = useTranslation();
  const medicationsResident = useSelector((state: RootState) => state.medications.medicationsResident);
  const chartData = medicationsResident.chart || [];
  const [initialDateSelected, setInitialDateSelected] = useState<Date | null>(null);

  // State
  const [showOption, setShowOption] = useState<DayOption>((special_days.type as DayOption) || 'every_day');
  const [selectedDays, setSelectedDays] = useState<WeekDay[]>(
    Array.isArray(special_days.special_day) && special_days.type === 'days_of_week'
      ? (special_days.special_day as WeekDay[])
      : []
  );

  const [selectedDates, setSelectedDates] = useState<Date[]>(() => {
    if (!Array.isArray(special_days.special_day) || special_days.type !== 'calendar') {
      return [];
    }

    return special_days.special_day.map((date) => {
      const [year, month, day] = date.split('-').map(Number);
      return new Date(year, month - 1, day);
    });
  });

  const [text, setText] = useState(special_days.text || '');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [highlightedDates, setHighlightedDates] = useState<Date[]>([]);

  const formatDateToMidnight = (date: Date | string): Date => {
    const [year, month, day] = (typeof date === 'string' ? date : date?.toISOString().split('T')[0])
      .split('-')
      .map(Number);
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  };

  const handleInitialDateSelection = (date: Date | undefined) => {
    if (!date) return;

    setInitialDateSelected(date);
    if (showOption === 'alternate_days') {
      const end = selectEndDate || addMonths(date, 912);
      const alternateDays = generateAlternateDays(date, end);
      setHighlightedDates(alternateDays);
      onUpdateFormData({
        special_day: alternateDays,
        text,
        type: 'alternate_days'
      });
    }
  };

  const generateAlternateDays = (startDate: Date, endDate: Date): Date[] => {
    const dates: Date[] = [];

    // Ordenar los ciclos por fecha de inicio
    const sortedCycles = [...chartData]
      .filter((record) => record.cycle_start_date)
      .sort((a, b) => new Date(a.cycle_start_date).getTime() - new Date(b.cycle_start_date).getTime());

    if (isNewMedication) {
      let current = new Date(startDate);
      current.setHours(0, 0, 0, 0);
      const end = endDate ? new Date(endDate) : addMonths(current, 912);
      end.setHours(0, 0, 0, 0);

      while (isBefore(current, end) || format(current, 'yyyy-MM-dd') === format(end, 'yyyy-MM-dd')) {
        dates.push(new Date(current));
        current.setDate(current.getDate() + 2);
      }
      return dates.filter((date) => isBefore(date, end) || format(date, 'yyyy-MM-dd') === format(end, 'yyyy-MM-dd'));
    } else {
      sortedCycles.forEach((cycle, index) => {
        const cycleStart = formatDateToMidnight(new Date(cycle.cycle_start_date));
        // Si no hay fecha fin, agregar 912 meses a la fecha de inicio
        const cycleEnd = cycle.cycle_end_date
          ? formatDateToMidnight(new Date(cycle.cycle_end_date))
          : formatDateToMidnight(addMonths(cycleStart, 912));

        let current = new Date(cycleStart);

        while (current <= cycleEnd) {
          if (current >= cycleStart && current <= cycleEnd) {
            dates.push(new Date(current));
          }
          current.setDate(current.getDate() + 2);
        }
      });

      return dates.sort((a, b) => a.getTime() - b.getTime());
    }
  };

  const handleAlternateDaysSelection = (selectedDate: Date | null) => {
    if (!selectedDate) {
      setHighlightedDates([]);
      return;
    }

    if (isNewMedication) {
      const endDate = selectEndDate || addMonths(selectedDate, 912);

      if (selectEndDate && isAfter(selectedDate, selectEndDate)) {
        return;
      }

      const alternateDays = generateAlternateDays(selectedDate, endDate);
      setHighlightedDates(alternateDays);
      onUpdateFormData({
        special_day: alternateDays.map((date) => format(date, 'yyyy-MM-dd')), // Formatear las fechas
        text,
        type: 'alternate_days'
      });
    } else if (dateRange) {
      const alternateDays = generateAlternateDays(dateRange.start, dateRange.end);
      setHighlightedDates(alternateDays);
      onUpdateFormData({
        special_day: alternateDays.map((date) => format(date, 'yyyy-MM-dd')), // Formatear las fechas
        text,
        type: 'alternate_days'
      });
    }
  };

  const formatDateWithOut = (date: Date | string): Date => {
    const [year, month, day] = (typeof date === 'string' ? date : date.toISOString().split('T')[0])
      .split('-')
      .map(Number);
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  };

  const dateRange = useMemo(() => {
    if (isNewMedication) {
      const charDataStartDate =
        isNewMedication && selectStartDate
          ? formatDateWithOut(selectStartDate)
          : Array.isArray(chartData) && chartData?.[0]?.cycle_start_date
          ? formatDateWithOut(chartData[0].cycle_start_date)
          : null;

      if (!charDataStartDate) return null;

      const charDataEndDate =
        isNewMedication && selectEndDate
          ? formatDateWithOut(selectEndDate)
          : Array.isArray(chartData) && chartData?.[0]?.cycle_end_date
          ? formatDateWithOut(chartData[0].cycle_end_date)
          : formatDateWithOut(addMonths(charDataStartDate, 912));

      return {
        start: charDataStartDate,
        end: charDataEndDate
      };
    }

    if (!chartData.length) return null;

    const cycles = chartData
      .filter((record) => record.cycle_start_date)
      .map((record) => {
        const startDate = formatDateToMidnight(new Date(record.cycle_start_date));
        const endDate = record.cycle_end_date
          ? formatDateToMidnight(new Date(record.cycle_end_date))
          : addMonths(startDate, 912);

        return {
          startDate,
          endDate,
          status: record.status
        };
      });

    if (cycles.length === 0) {
      const today = new Date();
      return {
        start: formatDateToMidnight(today),
        end: formatDateToMidnight(addMonths(today, 912))
      };
    }

    const allStartDates = cycles.map((c) => c.startDate);
    const allEndDates = cycles.map((c) => c.endDate);

    return {
      start: new Date(Math.min(...allStartDates.map((d) => d.getTime()))),
      end: new Date(Math.max(...allEndDates.map((d) => d.getTime())))
    };
  }, [chartData, isNewMedication, selectStartDate, selectEndDate]);

  const handleOptionChange = (option: DayOption) => {
    setShowOption(option);
    setIsPopoverOpen(option === 'calendar' || option === 'alternate_days');

    if (option === 'alternate_days') {
      if (isNewMedication && !initialDateSelected && !selectStartDate) {
        setIsPopoverOpen(true);
        return;
      }

      if (dateRange) {
        handleAlternateDaysSelection(dateRange.start);
      }
    } else {
      setSelectedDates([]);
      onUpdateFormData({
        special_day: option === 'every_day' ? 'every_day' : [],
        text,
        type: option
      });
    }
  };

  const handleDayChange = (day: WeekDay) => {
    const updatedDays = selectedDays.includes(day) ? selectedDays.filter((d) => d !== day) : [...selectedDays, day];

    setSelectedDays(updatedDays);
    onUpdateFormData({
      special_day: updatedDays,
      text,
      type: showOption
    });
  };

  // Handle calendar date selection
  const handleCalendarChange = (dates: Date[] | { from?: Date; to?: Date } | Date | undefined) => {
    if (!dates || !Array.isArray(dates)) return;

    const validDates = dates.filter((date) => isValid(date));
    setSelectedDates(validDates);
    onUpdateFormData({
      special_day: validDates.map((date) => format(date, 'yyyy-MM-dd')),
      type: showOption,
      text
    });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setText(newText);
    onUpdateFormData({
      special_day:
        showOption === 'calendar'
          ? selectedDates.map((date) => format(date, 'yyyy-MM-dd'))
          : showOption === 'alternate_days'
          ? highlightedDates
          : selectedDays,
      text: newText,
      type: showOption
    });
  };

  useEffect(() => {
    if (!special_days.type) {
      onUpdateFormData({
        special_day: 'every_day',
        text,
        type: 'every_day'
      });
    }
  }, []);

  useEffect(() => {
    if (showOption === 'alternate_days' && dateRange) {
      handleAlternateDaysSelection(dateRange.start);
    }
  }, [showOption, dateRange]);

  const renderWeekDayButtons = () => {
    const weekDays: WeekDay[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const firstRow = weekDays.slice(0, 4);
    const secondRow = weekDays.slice(4);

    return (
      <div className="flex flex-col items-center w-full gap-2">
        <div className="flex gap-2 justify-center w-full">
          {firstRow.map((day) => (
            <WeekDayButton
              key={day}
              day={day}
              selected={selectedDays.includes(day)}
              onClick={() => handleDayChange(day)}
            />
          ))}
        </div>
        <div className="flex gap-2 justify-center w-full">
          {secondRow.map((day) => (
            <WeekDayButton
              key={day}
              day={day}
              selected={selectedDays.includes(day)}
              onClick={() => handleDayChange(day)}
            />
          ))}
        </div>
      </div>
    );
  };

  const getDisplayDateText = () => {
    if (showOption === 'alternate_days') {
      if (isNewMedication && initialDateSelected) {
        return `${format(initialDateSelected, 'LLL dd, y')} - ${format(
          selectEndDate || addMonths(initialDateSelected, 912),
          'LLL dd, y'
        )}`;
      } else if (highlightedDates.length > 0) {
        return `${format(highlightedDates[0], 'LLL dd, y')} - ${format(
          highlightedDates[highlightedDates.length - 1],
          'LLL dd, y'
        )}`;
      }
    } else if (showOption === 'calendar' && selectedDates.length > 0) {
      return selectedDates
        .sort((a, b) => a.getTime() - b.getTime())
        .map((date) => format(date, 'LLL dd, y'))
        .join(', ');
    }
    return t('medications.selectADate');
  };

  return (
    <div className="p-6 border border-gray-300 rounded-lg shadow-sm bg-gray-50 mb-4">
      <h2 className="text-lg font-semibold mb-4">{t('medications.medicationForm.selectTypeOfDays')}</h2>

      <div className="mb-6 flex flex-wrap gap-4">
        {[
          { id: 'every_day', label: t('medications.medicationForm.everyDay') },
          { id: 'alternate_days', label: t('medications.medicationForm.everyOtherDay') },
          { id: 'days_of_week', label: t('medications.medicationForm.daysOfWeek') },
          { id: 'calendar', label: t('medications.medicationForm.accordingToCalendar') }
        ].map(({ id, label }) => (
          <div key={id} className="flex items-center">
            <Input
              id={id}
              type="radio"
              name="days_option"
              value={id}
              checked={showOption === id}
              onChange={() => handleOptionChange(id as DayOption)}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor={id} className="ml-2 text-gray-700 cursor-pointer">
              {label}
            </Label>
          </div>
        ))}
      </div>

      {showOption === 'days_of_week' && renderWeekDayButtons()}

      {(showOption === 'alternate_days' || showOption === 'calendar') && (
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-full justify-start text-left font-normal">
              <CalendarIcon className="mr-2 h-4 w-4 flex-shrink-0" />
              <span className="truncate">{getDisplayDateText()}</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 max-w-[95vw] md:max-w-none" align="start">
            <Calendar
              mode={showOption === 'alternate_days' ? 'single' : 'multiple'}
              selected={
                showOption === 'alternate_days'
                  ? isNewMedication
                    ? initialDateSelected
                    : highlightedDates[0]
                  : selectedDates
              }
              onSelect={
                showOption === 'alternate_days' && isNewMedication
                  ? handleInitialDateSelection
                  : showOption === 'alternate_days'
                  ? (date) => date && handleAlternateDaysSelection(date)
                  : handleCalendarChange
              }
              numberOfMonths={showOption === 'calendar' ? (window.innerWidth > 768 ? 2 : 1) : 1}
              modifiers={
                showOption === 'alternate_days'
                  ? {
                      highlighted: highlightedDates
                    }
                  : undefined
              }
              modifiersStyles={{
                highlighted: { backgroundColor: 'blue', color: 'white' }
              }}
              disabled={
                isNewMedication
                  ? undefined
                  : {
                      before: new Date()
                    }
              }
              initialFocus
              className="max-w-full"
            />
          </PopoverContent>
        </Popover>
      )}

      <div className="mb-6 mt-4">
        <Label htmlFor="text">{t('medications.medicationForm.specialInstructionsForSpecialDays')}:</Label>
        <textarea
          id="text"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
          rows={3}
          value={text}
          onChange={handleTextChange}
          placeholder={t('medications.medicationForm.enterSpecialInstructions')}
        />
      </div>
    </div>
  );
};

const WeekDayButton: React.FC<{
  day: WeekDay;
  selected: boolean;
  onClick: () => void;
}> = ({ day, selected, onClick }) => (
  <Button
    className={`flex-1 py-1 px-2 rounded-md border text-xs ${
      selected ? 'bg-primary text-white' : 'bg-white text-gray-700 hover:bg-gray-100'
    }`}
    onClick={onClick}
    type="button"
  >
    {day}
  </Button>
);

export default TypeOptions;

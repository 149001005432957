import React from 'react';
import { Row } from "@tanstack/react-table";
import TrackerVisitorsReportUpdate from './TrackerVisitorsReportUpdate';
import TrackerVisitorsReportDelete from './TrackerVisitorsReportDelete';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  fetchSummary: (page: number, resetData: boolean) => void;
}

const TrackerVisitorsRenderActionsColumn: React.FC<RenderActionsColumnProps> = (rowData, fetchSummary) => {
    return (
        <div className='flex justify-center'>
            <TrackerVisitorsReportUpdate row={rowData} fetchSummary={fetchSummary}/>
            <TrackerVisitorsReportDelete row={rowData} fetchSummary={fetchSummary}/>
        </div>
    );
};

export default TrackerVisitorsRenderActionsColumn;

import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { CategoryAccumulator } from '../note';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { fetchRefreshNotes, setNoteFilters, setNoteId, setReaderNote, setSelectedNotes } from '../../slices/NoteSlice';
import { format } from 'date-fns';
import { Input } from '@/common/presentation/components/registry/new-york/ui/input';
import { Loader2, ShieldAlert, Image, Share2, MessageSquareText } from 'lucide-react';
import { NoteI } from '../../../domain/note.domain';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';

interface NoteListProps {
  items: NoteI[] | [];
  setCrudState: (crudState: CrudState) => void;
  setSize?: (sizes: number[]) => void;
  scroll?: boolean;
  categoriesNames: CategoryAccumulator;
  loadingNotes: boolean;
}

export function NoteList({ items, setCrudState, scroll = true, setSize, categoriesNames, loadingNotes }: NoteListProps) {
  // Initial
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const metaNote = useSelector((state: RootState) => state.notes.notes?.meta);
  const departments = useSelector((state: RootState) => state.departments.departments);

  // Hooks
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDialogShared, setOpenDialogShared] = useState(false);
  const [searchDialogShared, setSearchDialogShared] = useState('');

  useEffect(() => {
    if (metaNote && metaNote.page >= metaNote.lastPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [metaNote]);

  //Behaviors
  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const unreadNote = async (noteId: any) => {
    let noteFindIndex = items.findIndex(note => note.id == noteId);

    if (noteFindIndex != -1) {
      let dataReader = {
        id: `temp${items[noteFindIndex].readers.length + 1}`,
        status: 'read',
        userId: authUserId,
        index: noteFindIndex
      }
      dispatch(setReaderNote(dataReader));

      await NoteService.unreadNote({
        note_id: noteId,
        status: 'read'
      });
    }
    await dispatch<any>(fetchRefreshNotes());
  };

  const fectchMoreNotes = async () => {
    setLoading(true);
    dispatch(setNoteFilters(['nextPage', Number(metaNote.page || 0) + 1]));
    setLoading(false);
  };

  function verifyArrays(optionA: string[], optionB: string[]) {
    // Verificar que tengan la misma longitud
    if (optionA.length !== optionB.length) return false;

    return optionA.every(elemento => optionB.includes(elemento)) &&
      optionB.every(elemento => optionA.includes(elemento));
  }

  const handleCheckBoxNote = (noteId: string) => {
    let dataFilter = selectedNotes.filter(id => id != noteId);

    if (selectedNotes.length > 0 && dataFilter.length == selectedNotes.length) {
      let findNoteMain = items.find(note => note.id == selectedNotes[0]);

      let findNoteAdd = items.find(note => note.id == noteId);

      let isValidAdd = false;
      if (findNoteMain?.scope_location_ids.length && findNoteAdd?.scope_location_ids.length) {

        isValidAdd = verifyArrays(findNoteMain.scope_location_ids, findNoteAdd.scope_location_ids);

      } else {
        isValidAdd = (findNoteMain?.scope_location_ids.length === 0 && findNoteAdd?.scope_location_ids.length === 0);
      }
      if (!isValidAdd) {
        toast({
          description: t('notes.notAllowShareNotes'),
        });
        return;
      }
    }
    dispatch(setSelectedNotes(noteId));
  };

  const openEmailsShared = () => {
    let note = items.find(option => option.id == noteSelected);
    return <Dialog open={true} onOpenChange={() => setOpenDialogShared(false)}>
      <DialogContent className="min-h-[18vh] gap-6 flex flex-col shadow-lg rounded-md border-t-4 border-primary max-w-[40vw] max-h-[50vh]">
        <DialogHeader>
          <DialogTitle className='flex items-center justify-between mt-4'>
            {t('notes.titleSharedEmails')}
            <Input placeholder={t('notes.search')} className="pl-8 focus-visible:ring-primary w-30" onChange={(e) => setSearchDialogShared(e.target.value)} />
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <ScrollArea className="h-[150px]">
            <ul>
              {note?.shared.filter((share => {
                return share.email.includes(searchDialogShared) || share.created_at.includes(searchDialogShared)
              })).map((share) => (
                <>
                  <li key={share.id} className="my-2">
                    <span className='font-bold'>{(share.contact_id ? share.name + ' ' + share.last_name : '')}</span> ({share.email} - {formatDate(share.format_created_at, false)} - {format(new Date(share.format_created_at), 'hh:mm a')})
                  </li>
                  <Separator />
                </>
              ))}
            </ul>
          </ScrollArea>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={() => setOpenDialogShared(false)}>{t('notes.confirm')}</Button>
        </DialogFooter>
      </DialogContent >
    </Dialog >
  }

  if (loadingNotes) {
    return <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>
  }

  if (metaNote.page == 1 && !items.length) {
    return <div className='w-full flex justify-center'>{t("notes.notesNotFound")}</div>
  }

  if (!items) {
    return;
  }

  return (
    <ScrollArea className='h-[25vh] md:h-[calc(100vh-340px)]'>
      <div className="flex flex-col gap-3">
        {items?.map((item) => (
          <div
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all group hover:bg-accent cursor-pointer',
              noteSelected === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(NoteHelperService.defineState({}));
              setNote(item.id);
              if (setSize) {
                setSize([0, 655]);
              }
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex">
                <div className="gap-2">
                  <div className="font-semibold text-primary">
                    {item.resident_id ? item.resident_name : t('notes.everyone')}
                  </div>
                  <div className="text-xs text-muted-foreground">{`${t('notes.by') + ' '}${item.author}`}</div>
                  {item.category_id && (
                    <div className="text-xs text-muted-foreground ">{t('notes.category') + ': '} {categoriesNames[item.category_id]}</div>
                  )}
                  {item.specialty_name && (
                    <div className="text-xs text-muted-foreground ">{t('notes.specialtyNote') + ': '} {item.specialty_name}</div>
                  )}
                </div>
                <div className='ml-auto text-xs'>
                  <div className="flex justify-end">
                    <samp className="mr-1">{t('notes.share')}</samp>
                    <Checkbox checked={selectedNotes.includes(item.id)} onClick={() => handleCheckBoxNote(item.id)} />
                  </div>
                  <div
                    className={cn(
                      'ml-auto text-xs',
                      noteSelected === item.id ? 'text-foreground' : 'text-muted-foreground'
                    )}
                  >
                    {formatDate(item.format_created_at, false)} - {formatDate(item.format_created_at, true, true)}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-xs text-muted-foreground text-ellipsis line-clamp-3" title={item.content} dangerouslySetInnerHTML={{ __html: item.html_content }}></div>
            <div className="flex justify-between w-full flex-wrap">
              <div className="flex items-center">
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="text-xs p-1 bg-white dark:bg-zinc-800 shadow" variant={'secondary'}>
                      {item.visibility == 'department' ? (
                        item.department_ids.map(dptId => {
                          return departments.find(dpt => dpt.id === dptId)?.department
                        }).join(' - ')
                      ) : (
                        t('notes.' + item.visibility)
                      )}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent className='bg-white dark:bg-zinc-800 border-t-4 border-t-primary/80 text-dark shadow'>
                    {item.noteTo == '' ? t('locationsSelector.allLocations') : item.noteTo}
                  </TooltipContent>
                </Tooltip>
                {Boolean(item.urgent) && (
                  <Badge className="text-xs p-1 bg-primary/10 text-primary shadow ml-2" variant={'secondary'}>
                    <ShieldAlert className="w-4 h-4" />
                  </Badge>
                )}
                {item.answers && item.answers?.length > 0 && (
                  <Badge className={`text-xs p-1 bg-primary/10 text-primary shadow ml-2`} variant={'secondary'}>
                    {item.answers.length}<MessageSquareText className="w-4 h-4 ml-1" />
                  </Badge>
                )}
                {item.images && item.images?.length > 0 && (
                  <Badge className="text-xs p-1 bg-primary/10 text-primary shadow ml-2" variant={'secondary'}>
                    <Image className="w-4 h-4" />
                  </Badge>
                )}
                {item.shared && item.shared?.length > 0 && (
                  <Badge className="text-xs p-1 bg-primary/10 text-primary shadow ml-2" variant={'secondary'} onClick={() => setOpenDialogShared(true)}>
                    <Share2 className="w-4 h-4" />
                  </Badge>
                )}
              </div>
              {item.created_by == authUserId ? (
                <Badge variant="default">{t('notes.author')}</Badge>
              ) : item.readers.filter((item: any) => item.user_id == authUserId).length > 0 &&
                item.readers.filter((item: any) => item.user_id == authUserId)[0].status == 'read' ? (
                <Badge variant="outline" className='bg-green-100 text-green-600'>{t('notes.listReadinsStatus.read')}</Badge>
              ) : (
                <Badge variant="outline" className="bg-red-100 text-red-600" onClick={() => unreadNote(item.id)}>
                  {t('notes.listReadinsStatus.unread')}
                </Badge>
              )}
            </div>
          </div>
        ))}

        {scroll && (
          <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreNotes} threshold={0.5}>
            {hasMore && <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>}
          </InfiniteScroll>
        )}
      </div>

      {openDialogShared && openEmailsShared()}
    </ScrollArea>
  );
}

import { useEffect, useRef, useState } from "react";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import QuickTrackerResident from "./interfaces/QuickTrackerResidentInterface";
import QuickTrackerItem from "./interfaces/QuickTrackerItemInterface";
import QuickTrackerType from "./interfaces/QuickTrackerTypeInterface";
import QuickTrackerProps from "./interfaces/QuickTrackerPropsInterface";
import QuickTrackerService from "./services/QuickTrackerService";
import QuickTrackerTableHeader from "./components/QuickTrackerTableHeader";
import QuickTrackerInput from "./components/QuickTrackerInput";
import { Button } from "@/components/ui/button";
import UtilService from "@/common/services/UtilService";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Bell, Info } from "lucide-react";

const QuickTracker = ({ types, residents, loading, submit, data, input, volumes }: QuickTrackerProps) => {
    const [newItems, setNewItems] = useState<QuickTrackerItem[]>([]);
    const [sortedType, setSortedType] = useState<any[]>([]);
    const { t } = useTranslation();
    const saveButtonRef = useRef<HTMLButtonElement>();

    useEffect(() => {
        if(types.length) {
            setSortedType(
                UtilService.sortObjectsAlphabetically(types, "name")
            );
        }
    }, [types]);

    const save = () => {
        submit([...newItems]);
        setNewItems([]);
    };

    const callback = () => {
        if (saveButtonRef.current) saveButtonRef.current?.click();
    };

    const insertNewValue = (action: string, resident: QuickTrackerResident, type: QuickTrackerType, exist: boolean, id?: string, id_data?: string, volume?: any) => {
        setNewItems(
            QuickTrackerService.insertNewValue(newItems, action, resident, type, exist, volume, id, id_data)
        );
    };

    const typesAlertGenerator: any = {
        'Weight': true,
        'Temperature': true,
        'Pulse/Oxygen': true,
        'Pulse/Oxygen at night': true,
        'Pulse AM': true,
        'Pulse PM':true,
        'Blood Pressure AM': true,
        'Blood Pressure PM': true
    };

    return (
        <LoadingWrapper loading={loading}>
            <Table className="shadow-lg">
                <TableHeader style={{ position: "sticky", top: "0", zIndex: "50" }}>
                    <QuickTrackerTableHeader residents={residents} />
                </TableHeader>

                <TableBody className="bg-white dark:bg-background">
                    {
                        sortedType.length ? (
                            sortedType
                            .filter(type => type.status === 'active')
                            .map(
                                (type, index) => (
                                    <>
                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                        <TableCell 
                                            className="bg-white dark:bg-zinc-900" 
                                            style={{ 
                                                position: "sticky", 
                                                top: "auto", 
                                                left: 0, 
                                                zIndex: "10", 
                                                padding: "0.25rem", 
                                                borderRight: "#e5e7eb solid 1px" 
                                            }}
                                            >
                                            <div className="flex items-center ml-2">
                                                <span>{type.name}</span>
                                                {
                                                    typesAlertGenerator[type.name] && (
                                                        <Popover>
                                                        <PopoverTrigger>
                                                            <Bell className="w-4 h-4 ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 cursor-pointer" />
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-80">
                                                            <div className="space-y-2">
                                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                                { t(`alerts.tracker.${type.name}`)}
                                                            </p>
                                                            </div>
                                                        </PopoverContent>
                                                        </Popover>
                                                    )
                                                }
                                            </div>
                                            </TableCell>

                                            {
                                                residents.length ? (
                                                    residents.map(
                                                        (resident, index) => (
                                                            <TableCell 
                                                                key={index} 
                                                                style={{ padding: "0.25rem" }}
                                                            >
                                                                <div className="flex justify-center items-center">
                                                                    <QuickTrackerInput 
                                                                        currentItems={newItems}
                                                                        resident={resident} 
                                                                        type={type} 
                                                                        data={data} 
                                                                        onselectvalue={insertNewValue}
                                                                        input={input}
                                                                        volumes={volumes}
                                                                        save={callback}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        )
                                                    )
                                                ) : null
                                            }
                                        </TableRow>
                                    </>
                                )
                            )
                        ) : null
                    }

                    <TableRow>
                        <TableCell style={{ padding: "1.5rem" }}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Button 
                onClick={save} 
                className="mb-1 mr-1"
                style={{ position: "absolute", bottom: 0, right: 0 }}
                ref={saveButtonRef}
            >
                {t('trackers.Save Quick Tracker')}
            </Button>
        </LoadingWrapper>
    );
};

export default QuickTracker;

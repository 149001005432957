import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { AppDispatch, RootState } from '@/store/store';
import { format, parseISO } from 'date-fns';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { CardContent, CardHeader} from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { useFormSubmission } from '@/modules/residents/domain/hooks/forms/useFormSubmission';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { filterEmptyRows } from '@/modules/residents/domain/utils/Forms/formUtils';

import FormActionButtons from '../shared/FormActionButtons';
import { CustomCard } from '../shared/CustomCard';
import FormHeader from '../shared/FormHeader';
import { incidentReportFormDefaults } from '../formDefaults/incidentReportFormDefaults';
import { FormsHistory } from '../FormsHistory/FormsHistory';
import UnusualIncidentReport from './components/UnusualIncidentReport';
import { FacilityInfo } from './components/FacilityInfo';
import ClientsResidentsTable from './components/ClientsResidentsTable';
import IncidentTypeChecklist from './components/IncidentTypeChecklist';
import IncidentDetailsForm from './components/IncidentDetailsForm';
import ReportSubmissionForm from './components/ReportSubmissionForm';
import AgenciesNotifiedForm from './components/AgenciesNotifiedForm';


interface ValuableItem {
  client: string;
  date_occurred: string;
  age: string;
  sex: string;
  date_of_admission: string;
}

export const IncidentReportForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { t } = useTranslation();
  const { resident, status } = useSelector((state: RootState) => state.residents.resident);
  const { locationSelectedId } = useLocation();

  const methods = useForm({
    defaultValues: incidentReportFormDefaults(resident),
  });

  const getResidents = async () => {
    try{
      await dispatch(
        getResidentsByLocation(locationSelectedId == 'all-locations' ? undefined : locationSelectedId)
      );
    } catch {
      return;
    }
  };

  useEffect(() => {
    getResidents();
  }, [locationSelectedId]);

  const { reset } = methods;

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  useEffect(() => {
    if (resident) {
      reset(incidentReportFormDefaults(resident));
    }
  }, [resident, reset]);

  const residentName = `${resident?.first_name ?? ''} ${resident?.last_name ?? ''}`;
  const title = `${residentName} - ${t('common.forms')}`;

  const { handleSubmit, formsStatus } = useFormSubmission({
    documentKey: 'lic624_unusual_incident_injury_report',
  });

  const processValuablesEntrusted = (data: ValuableItem[]): ValuableItem[] => {
    return data.map((item) => ({
      ...item,
      date_occurred: item.date_occurred ? format(parseISO(item.date_occurred), 'MM/dd/yyyy') : '',
      date_of_admission: item.date_of_admission ? format(parseISO(item.date_of_admission), 'MM/dd/yyyy') : ''
    }));
  };

  const handleFormSubmit = async (pdf: boolean) => {
    const rawData = methods.getValues();

    const cleanedClientsResidents= processValuablesEntrusted(filterEmptyRows(rawData.clients_residents || []))

    const cleanedData = {
      ...rawData,
      clients_residents: cleanedClientsResidents.length > 0 ? cleanedClientsResidents : [],
      report_submitted_date: rawData.report_submitted_date ? format(parseISO(rawData.report_submitted_date), 'MM/dd/yyyy') : '',
      report_approved_date: rawData.report_approved_date ? format(parseISO(rawData.report_approved_date), 'MM/dd/yyyy') : '',
    };

    await handleSubmit({
      formData: cleanedData,
      residentId: resident?.id,
      pdf,
    });
  };

  if (status === 'loading' || !resident) {
    return <SplashScreen />;
  }

  return (
    <div className="flex justify-center pb-8">
      <div className="w-full flex flex-col">
        <BackNavigationBar
          title={title}
          path={`/residents/forms?r=${params.r}`}
        />
        <FormProvider {...methods}>
          <form>
            <CustomCard>
              <CardHeader className='p-0 '>
                <FormHeader
                  leftContent={
                    <>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.stateOfCalifornia")} - {t("residents.forms.formHeader.healthAndHumanServicesAgency")}
                      </p>
                    </>
                  }
                  rightContent={
                    <>
                       <p className='uppercase'>
                        {t("residents.forms.formHeader.californiaDeptSocialServices")}
                      </p>
                      <p className='uppercase'>
                        {t("residents.forms.formHeader.communityCareLicensingDivision")}
                      </p>
                    </>
                  }
                />
              </CardHeader>
              <CardContent className="p-0 flex-1">
                <UnusualIncidentReport />
                <Separator className='my-4 bg-primary'/>
                <FacilityInfo />
              </CardContent>
            </CustomCard>
            <CustomCard>
              <ClientsResidentsTable />
            </CustomCard>
            <CustomCard>
              <IncidentTypeChecklist />
            </CustomCard>
            <CustomCard>
              <IncidentDetailsForm />
            </CustomCard>
            <CustomCard>
              <ReportSubmissionForm />
            </CustomCard>
            <CustomCard>
              <AgenciesNotifiedForm />
              <Separator className='my-4 bg-primary'/>
              <FormActionButtons
                handleFormSubmit={handleFormSubmit}
                formsStatus={formsStatus}
              />
            </CustomCard>
          </form>
        </FormProvider>
        <FormsHistory formKey="lic624_unusual_incident_injury_report" />
      </div>
    </div>
  );
};

import { Socket, io } from "socket.io-client";
import { encryptToNest } from "./encryption.helper";

type SubscriptionMap = Map<string, Socket>;

export const websocket = {

  subscriptions: new Map<string, Socket>() as SubscriptionMap,

  subscribe: function (channel: string, callback: (data: any) => void) {       
    
    if(this.subscriptions.has(channel)) {
      return;
    }
    
    const websocketToken = {
      key: import.meta.env.VITE_WEB_NEST_CARING_DATA_KEY,
      dateTime: new Date().getTime()
    };
    
    const socket = io(`wss://${import.meta.env.VITE_NEST_APP_API_DOMAIN}`, {
      transports: ['websocket'],
      query: {
        token: encryptToNest(JSON.stringify(websocketToken))
      },
      rejectUnauthorized: false
    });
    socket.on(channel, callback);

    this.subscriptions.set(channel, socket);

    return socket;
  },
};

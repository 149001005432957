import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, startDate }) => {
  const { t } = useTranslation();
  const formattedStartDate = startDate
    ? format(new Date(startDate), t('dateFormats.dateFormat'))
    : t('general.noDateAvailable', 'No date available');

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="min-h-[20vh] gap-6 flex flex-col shadow-lg rounded-md border-t-4 border-primary max-w-[40vw] max-h-[40vh]">
        <DialogHeader className="mb-4">
          <DialogTitle>{t('medications.confirmationModal.confirmAction', 'Confirm Action')}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {t('emar-routine.confirmationModal.message', {
            startDate: formattedStartDate
          })}
        </DialogDescription>
        <DialogFooter className="mt-6">
          <Button variant="outline" onClick={onClose}>
            {t('medications.confirmationModal.cancel', 'Cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('medications.confirmationModal.confirm', 'Confirm')}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;

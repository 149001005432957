import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainMenu } from '@/common/domain/MainMenu';

const useFilteredTrackers = (subMenuTrackers: MainMenu[]) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLocaleLowerCase() ?? '');
  };

  const filteredTrackers = useMemo(() => {
    const normalizedSearch = search.toLowerCase();

    return subMenuTrackers
      .filter((item) => item.key !== 'outings')
      .filter((item) => {
        const translatedName = t(`menu.tracker.submenus.${item.key}`);
        return translatedName.toLowerCase().includes(normalizedSearch);
      })
      .sort((a, b) => {
        const translatedNameA = t(`menu.tracker.submenus.${a.key}`);
        const translatedNameB = t(`menu.tracker.submenus.${b.key}`);
        return translatedNameA.localeCompare(translatedNameB, undefined, { sensitivity: 'base' });
      });
  }, [subMenuTrackers, search, t]);

  return { search, handleSearch, filteredTrackers };
};

export default useFilteredTrackers;
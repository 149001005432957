import { useTranslation } from 'react-i18next';

const CourseCompletionRequirements = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white/80 dark:bg-zinc-800/80 rounded-lg shadow-md p-4 items-start">
      <div className="flex flex-col gap-2 md:flex-row items-start">
        <div className="items-start">
          <h3 className="text-sm md:text-base font-medium text-black dark:text-white">
            <span className="text-lg md:text-xl">20</span> {t('training.courses_of_training')}
          </h3>
          <p className="text-xs md:text-sm text-black dark:text-white w-11/12">
            {t('training.at_the_end_of_the_course')}
          </p>
        </div>
        <div className="items-start">
          <h3 className="text-sm md:text-base font-medium text-black dark:text-white">
            <span className="text-lg md:text-xl">70%</span> {t('training.minimum_score')}
          </h3>
          <p className="text-xs md:text-sm text-black dark:text-white w-11/12">{t('training.a_minumum_score')}</p>
        </div>
        <div className="items-start">
          <h3 className="text-sm md:text-base font-medium text-black dark:text-white">
            {t('training.course_completed')}
          </h3>
          <p className="text-xs md:text-sm text-black dark:text-white w-11/12">
            {t('training.site_administrator_receive')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCompletionRequirements;

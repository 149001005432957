import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CustomAlertsParams, StaffAlertI } from '@/common/domain/Alerts';
import StaffService from '../../infrastructure/services/StaffService';

interface StaffMemberState {
  staffMember: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
  alerts: StaffAlertI,
}

const initialState: StaffMemberState = {
  staffMember: null,
  status: 'idle',
  error: null,
  alerts: {
    licenseAlerts: {
      data: [],
      status: 'idle',
    },
    customAlerts: {
      data: [],
      status: 'idle',
    },
  },
};

export const getStaffMemberById = createAsyncThunk('staff/getStaffMember', async (staffMemberId: string) => {
  try {
    const response = await StaffService.getStaffMemberById(staffMemberId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching staff member: ' + error);
  }
});

export const fetchStaffAlerts = createAsyncThunk(
  'alerts/vitalResidentAlerts',
  async (params : {location_id?: string  | null, user_id?: string[] | null}) => {
  try {
    const response = await StaffService.getStaffAlerts(params.location_id, params.user_id);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchCustomAlerts = createAsyncThunk(
  'customAlerts',
  async (params: CustomAlertsParams) => {
  try {
    const response = await StaffService.getCustomAlerts(params);
    return response;
  } catch (error) {
    throw error;
  }
});

const staffMemberSlice = createSlice({
  name: 'staffMember',
  initialState,
  reducers: {
    setStaffMember: (state, action) => {
      state.staffMember = action.payload;
    },
    resetStaffMember: (state) => {
      state.staffMember = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffMemberById.pending, (state) => {
        state.status = 'loading';
        state.staffMember = null;
        state.error = null;
      })
      .addCase(getStaffMemberById.fulfilled, (state, action) => {
        state.staffMember = action.payload;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getStaffMemberById.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.staffMember = null;
      });
    builder
      .addCase(fetchCustomAlerts.pending, (state) => {
        state.status = 'loading';
        state.staffMember = null;
        state.error = null;
      })
      .addCase(fetchCustomAlerts.fulfilled, (state, action) => {
        state.alerts.customAlerts = {
          data: action.payload.alerts,
          status: 'idle',
        };
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchCustomAlerts.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.staffMember = null;
      });
    builder
      .addCase(fetchStaffAlerts.pending, (state) => {
        state.status = 'loading';
        state.staffMember = null;
        state.error = null;
      })
      .addCase(fetchStaffAlerts.fulfilled, (state, action) => {
        state.alerts.licenseAlerts =  {
          data: action.payload.license_alerts,
          status: 'idle',
        };
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchStaffAlerts.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.status = 'failed';
        state.staffMember = null;
      });
  }
});

export const { setStaffMember, resetStaffMember } = staffMemberSlice.actions;
export default staffMemberSlice.reducer;

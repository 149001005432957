import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { RootState } from '@/store/store';
import { Eye, Trash, Loader2 } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useState } from 'react';

interface Props {
  document_id: string;
  form_id: string;
  url: string;
  data: Record<string, unknown>;
  formKey: string;
}

export const HistoryTableActions = ({ document_id, form_id, url, data, formKey }: Props) => {
  const { deleteForm, getFormHistory } = useFormsStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await deleteForm(
        data !== null ? form_id : document_id,
        data !== null ? 'form' : 'document'
      );

      toast({
        description: 'Form deleted successfully!',
        variant: 'default'
      });

      if (response && resident?.id) {
        await getFormHistory(resident.id, formKey);
      }
    } catch (error) {
      toast({
        description: 'Error deleting form',
        variant: 'destructive'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleView = async () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex gap-2">
      <Button size={'icon'} variant={'outline'} onClick={handleView} disabled={loading}>
        <Eye className="h-4 w-4" />
      </Button>
      <Button size={'icon'} variant={'outline'} onClick={handleDelete} disabled={loading}>
        {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Trash className="h-4 w-4" />}
      </Button>
    </div>
  );
};

import { cn } from '@/lib/utils';
import { Card, CardContent } from '@/components/ui/card';
import { CourseI } from '../../domain/training.domain';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store.ts';
import { goToTraining } from '@/modules/training/presentation/slices/TrainingSlice.ts';

interface IProps {
  course: CourseI;
  viewMode?: 'grid' | 'list';
  status: 'completed' | 'in_progress' | 'not_started';
}

const CourseCard: React.FC<IProps> = ({ course, status, viewMode = 'grid' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const classesColorStatus: { [key: string]: string } = {
    completed: 'bg-green-500',
    in_progress: 'bg-yellow-500',
    not_started: 'bg-zinc-200'
  };

  const handleClick = async () => {
     const response = await dispatch(goToTraining(course.id));
     window.open(response.payload.data,'_blank');
  }

  if (viewMode === 'list') {
    return (
      <div className="bg-background w-full flex items-center justify-between p-4 border rounded-lg cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-700 transition-colors">
        <div className="flex items-center gap-4 flex-grow">
          <span className={cn('inline-block rounded-full h-3 w-3', classesColorStatus[status])} />
          <p className="text-sm font-medium line-clamp-2">{course.name}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-sm text-zinc-600 dark:text-zinc-300">{t(`training.${status}`)}</p>
        </div>
      </div>
    );
  }

  return (
    <Card className=" w-full max-w-xs hover:shadow transition-all duration-300 ease-in-out cursor-pointer">
      <CardContent className="p-1 space-y-1">
        <div className="bg-primary/10 dark:bg-primary/15 p-3 rounded-md h-20 flex items-center">
          <p className="text-primary text-sm font-medium line-clamp-3">{course.name}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className={cn('inline-block rounded-full h-2 w-2', classesColorStatus[status])} />
            <p className="text-sm text-zinc-600 dark:text-zinc-300">{t(`training.${status}`)}</p>
          </div>
            <p
              onClick={handleClick}
              className="text-sm text-primary hover:underline">{t('training.go')}</p>
        </div>
      </CardContent>
    </Card>
);
};

export default CourseCard;


import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { AppDispatch, RootState } from "@/store/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button"; 
import { Pencil, Trash } from "lucide-react";
import { DialogCreateOrUpdate } from "./DialogCreateOrUpdate";
import { deleteNoteCategory, getNoteCategories, noteCategoriesLoading } from "@/modules/settings/presentation/components/menus/notes/slices/NoteCategorySlice";
import DeleteDialog from "@/common/presentation/components/DeleteDialog/DeleteDialog";
import { FiltersTable } from "./FiltersTable";

export interface IData {
  id?: string;
  category: string;
  type: string;
  status: string | boolean;
  availability?: string;
  idUpdate?: string;
}

export const NoteCategory = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const [filters, setFilters] = useState({
    category: '',
    relatedTo: '',
    status: '',
  });

  const dispatch = useDispatch<AppDispatch>();
  const { categories, isLoading } = useSelector((state: RootState) => state.settings.noteCategory);

  const [dataUpdate, setDataUpdate] = useState({
    id: '',
    category: '',
    type: '',
    status: 'active',
    availability: ''
  });

  const renderActionsColumn = (rowData: any) => (
    <div className="flex gap-2 justify-end z-[9999999999]">
      <Button variant="outline" size="icon" onClick={() => {
        const availability = rowData.client_id == null ? 'all_clients' : 'single_client'; 
        const newRowData = {...rowData, availability};
        setDataUpdate(newRowData);
        setIsUpdate(true);
        setOpenDialog(true);

      }}>
        <Pencil className="h-4 w-4" />
      </Button>
      {rowData?.client_id !== null &&
        <Button variant="outline" size="icon" onClick={() => {
          setIdDelete(rowData.id);
          setOpenDeleteAlert(true);
        }}>
          <Trash className="h-4 w-4" />
      </Button>}
    </div>
  );

  const columns = [
    { key: "category", labelTranslationKey: "common.name" },
    { key: "type", labelTranslationKey: "settings.notes.relatedTo" },
    { key: "status", labelTranslationKey: "settings.status" },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: '',
      render: renderActionsColumn,
      width: 'w-64',
      static: true
    }
  ];

  const loadMore = useCallback(async () => {
    if (!hasMoreRows) return;
    try {
      const nextPage = currentPage + 1;
      const params = {
        currentPage: nextPage,
        perPage: rowsPerPage,
        search: filters.category, 
        relatedTo: filters.relatedTo,
        status: filters.status,
      };

      dispatch(noteCategoriesLoading());

      const response = await dispatch(getNoteCategories(params));
      if (response?.payload?.items) {
        const parseData = Object.values(response.payload.items);
        setData(prevData => [...prevData, ...parseData]);
        setCurrentPage(nextPage);
      }

    } catch (error) {
      throw new Error(`Error get data: ${error}`);
    }
  }, [hasMoreRows, currentPage, filters]);

  const callbackRenderContent = (index: any, column: any, data: any) => {
    const row = data[index];
    switch (column.key) {
      case 'category':
        return <>{row?.category}</>;
      case 'type':
        return <>{t(`common.${row?.type}`)}</>;
      case 'status':
        return row?.status === 'active' ? t('common.active') : t('common.inactive');
    }
  }

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
    getData(1, newFilters);
  };

  const handleClearFilter = () => {
    setFilters({ ...filters, category: '' });
    getData(1);
  };

  const getData = async (cPage = currentPage, filters: any = {}) => {
    dispatch(noteCategoriesLoading());
    const response = await dispatch(getNoteCategories({ 
      currentPage: cPage, 
      perPage: rowsPerPage, 
      search: filters.category, 
      relatedTo: filters.relatedTo, 
      status: filters.status 
    }));
    const data = response?.payload;
    if (data?.items) {
      setData(data.items);
      setHasMoreRows(data.pagination.hasMorePages);
      setCurrentPage(cPage);
    }
  };

  
  const handleDelete = () => {
    if (idDelete) {
      dispatch(noteCategoriesLoading());
      dispatch(deleteNoteCategory(idDelete))
      getData(1);
    }
  }

  useEffect(() => {
    getData(1);
  }, [dispatch]);

  return (
    <>
      <TableCustomVirtuoso
        tableId="settings-t-note-categories"
        styles={{ height: 'calc(100vh - 23rem)', maxHeight: '370px' }}
        additionalButtons={
          <Button
            variant="outline"
            size="sm"
            className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
            onClick={() => setOpenDialog(true)}
          ><span>{t('common.new')}</span></Button>
        }
        onSort={()=>{}}
        data={data}
        columns={columns}
        renderCellContent={callbackRenderContent}
        loadMore={loadMore}
        hasMore={hasMoreRows}
        isLoading={isLoading}
        filters={
          <FiltersTable
            filters={filters}
            onFilterChange={handleFilterChange}
            onClear={handleClearFilter}
          />
        }
        showSearchInput={false}
      />

      <DialogCreateOrUpdate
        onChangeOpen={setOpenDialog}
        open={isOpenDialog}
        isUpdate={isUpdate}
        onChangeIsUpdate={setIsUpdate}
        dataUpdate={dataUpdate}
        onChangeGetData={getData}
      />

      <DeleteDialog
        isOpen={isOpenDeleteAlert}
        onOpenChange={setOpenDeleteAlert}
        handleDelete={handleDelete}
      />
    </>
  );
}
